import React, { useContext } from "react";

import { AssetBlockContext } from "../CustomHooks/ContextApi";
import embLabels from "../../config/emb-labels-en.json";

const PowerMeterDetails = () => {
  const {
    architectureType,
    siteType,
    configData,
    handleCBConfigChange,
    assetEditStatusUpdateAssetPM,
    assetEditStatusUpdateEvPM,
    selectedValues,
    updatePowerMeterData,
  } = useContext(AssetBlockContext);

  const cbConfigChangeHandler = e => {
    handleCBConfigChange(e);
    if (selectedValues.selectedAssetPM !== "") {
      assetEditStatusUpdateAssetPM(e, true, selectedValues.selectedAssetPM);
    }
    if (selectedValues.selectedEvPM !== "") {
      assetEditStatusUpdateEvPM(e, true, selectedValues.selectedEvPM);
    }
  };

  return (
    <>
      <div className="col-md-12 mt-2 se-white-background align-middle">
        <div className="row">
          <label className="col-md-6 bold mt-2 mb-2 se-font-14px pl-3">
            Asset Settings
          </label>

          <div className="col-md-6 mt-1 text-right">
            <button
              type="button"
              className="btn btn-secondary btn-sm se-btn-primary"
              onClick={updatePowerMeterData}
            >
              <se-icon size="nano">action_save</se-icon>
              <span>&nbsp;</span>Save
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-12 se-grey-background">
        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">
            Name <span className="se-mandatory">*</span>
          </label>
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput1"
            placeholder="Name"
            name="name"
            maxLength="50"
            value={configData.cbConfigData.name}
            onChange={ cbConfigChangeHandler
            }
            readOnly={
              // architectureType === embLabels.PV_GENSET_ATS ||
              // architectureType === embLabels.PV_BESS_NR ||
              siteType === embLabels.GENSET_PVBESS_A2E ||
              false
            }
          />
          <div
            className={`invalid-feedback-custom ${!configData.cbConfigData.name ? "" : "hidden"
              }`}
          >
            {embLabels.nameRequired}
          </div>
          <div
            className="invalid-feedback-custom"
            style={configData.cbConfigData.name !==""  && !/^[a-zA-Z0-9-_]+$/.test(configData.cbConfigData.name )? {} : { display: "none" }}
          >
            {embLabels.notallowedAlphanumericValues}
          </div>
        </div>

        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">Description</label>
          <input
            type="text"
            autoComplete="none"
            className="form-control form-control-sm"
            id="exampleFormControlInput1"
            placeholder="Description"
            name="description"
            maxLength="100"
            value={configData.cbConfigData.description}
            onChange={cbConfigChangeHandler}
          />
        </div>
      </div>
    </>
  );
};

export default PowerMeterDetails;
