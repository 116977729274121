import React, { useState, useContext } from "react";
import _ from "lodash";

import isTouchDevice from "../SE-Component/Tooltip";
import { DesignProgramContext } from "../CustomHooks/ContextApi";
import Tooltip from "../../utils/Tooltip";
import { useSelector } from "react-redux";

const alphanumericRegWithHyphen = /^[-_0-9a-zA-Z]+$/;
const onlyNumber = /^(\d+(\.\d+)?|)$/;
const AutoGrid = () => {
  
  const [bessOverrideDerTab, setBessOverrideDerTab] = useState(false);
  const [bessOverrideDerTab1, setBessOverrideDerTab1] = useState(false);
  const [utilityOverrideDerTab, setUtilityOverrideDerTab] = useState(false);
  const [serviceOverrideDerTab, setServiceOverrideDerTab] = useState(false);
  const [pvOverrideDerTab1, setPvOverrideDerTab1] = useState(false);
  const [pvOverrideDerTab2, setPvOverrideDerTab2] = useState(false);

  const tooltipData = useSelector((state) => state?.tooltipData?.tooltipData);
  const { autogridOptimization: autogridDetails, autogridDetailsHandler,siteCreated,projectStatusData,UserScope } =
    useContext(DesignProgramContext);

   
  const SMART_METER_DER = autogridDetails?.resources?.find(
    (resource) => resource.type === "SMART_METER"
  );

  const BATTERY_DER = autogridDetails?.resources?.find(
    (resource) => resource.type === "BATTERY"
  );

  const bessOverrideDerTabHandler = () =>
    setBessOverrideDerTab(!bessOverrideDerTab);
  const bessOverrideDerTabHandler1 = () =>
    setBessOverrideDerTab1(!bessOverrideDerTab1);
  const pvOverrideDerTabHandler1 = () =>
    setPvOverrideDerTab1(!pvOverrideDerTab1);
  const pvOverrideDerTabHandler2 = () =>
    setPvOverrideDerTab2(!pvOverrideDerTab2);
  const utilityOverrideDerTabHandler = () =>
    setUtilityOverrideDerTab(!utilityOverrideDerTab);
  const serviceOverrideDerTabHandler = () =>
    setServiceOverrideDerTab(!serviceOverrideDerTab);

  const servicePointIdChangeHandler = (e) => {
    const autogridDetailsNew = _.cloneDeep(autogridDetails);
    const { value } = e.target;

    if (alphanumericRegWithHyphen.test(value)) {
      autogridDetailsNew.servicePoint = value;
      autogridDetailsHandler(autogridDetailsNew);
    }
    if (value === "") {
      autogridDetailsNew.servicePoint = "";
      autogridDetailsHandler(autogridDetailsNew);
    }
  };

  const idChangeHandler = (e, type) => {
    const autogridDetailsNew = _.cloneDeep(autogridDetails);
    const { value } = e.target;

    const typeIndex = autogridDetailsNew?.resources?.findIndex(
      (resource) => resource.name === type
    );

    if (alphanumericRegWithHyphen.test(value)) {
      autogridDetailsNew.resources[typeIndex].id = value;
      autogridDetailsHandler(autogridDetailsNew);
    }
    if (value === "") {
      autogridDetailsNew.resources[typeIndex].id = "";
      autogridDetailsHandler(autogridDetailsNew);
    }
  };

  const existAutogridChangeHandler = (e, type) => {
    const autogridDetailsNew = _.cloneDeep(autogridDetails);
    const { checked } = e.target;

    const typeIndex = autogridDetailsNew.resources.findIndex(
      (resource) => resource.name === type
    );

    autogridDetailsNew.resources[typeIndex].enabled = checked;
    if (!checked) {
      autogridDetailsNew.resources[typeIndex].id =
        autogridDetailsNew.resources[typeIndex].id;
    }
    autogridDetailsHandler(autogridDetailsNew);
  };

  const maxCapacityChangeHandler = (e, type) => {
    const autogridDetailsNew = _.cloneDeep(autogridDetails);
    const { value } = e.target;

    const typeIndex = autogridDetailsNew.resources.findIndex(
      (resource) => resource.name === type
    );

    if (onlyNumber.test(value)) {
      autogridDetailsNew.resources[typeIndex].capacity = value;
      autogridDetailsHandler(autogridDetailsNew);
    }
  };
  return (
    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background my-1">
      {false && <div className="dropdown-divider"></div>}

      <div className="row ">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 px-4">
         { false && <div className="form-group row mb-1">
            <label htmlFor="tenant" className="col-sm-3 col-form-label">
              Tenant<span className="se-mandatory">*</span>
              <Tooltip
                position="right"
                iconColor="secondary"
                iconSize="small"
                //information="AutoGrid tenant to connect the Service Point."
                information={tooltipData["tenant"]?.data}
              />
            </label>

            <div className="col-sm-9">
              <div className="col-md-9">
                <div className="form-group row">
                  <div className={`col-sm-8`}>
                    <select
                      className="custom-select"
                      name="tenantId"
                      disabled
                      defaultValue={autogridDetails.tenant}
                    >
                      <option>{autogridDetails.tenant}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
}
          <div className="form-group row mb-1">
            <label htmlFor="servicePoint" className="col-sm-3 col-form-label">
              Service Point Id<span className="se-mandatory">*</span>
              <Tooltip
                position="right"
                iconColor="secondary"
                iconSize="nano"
                information="Identifier for existing AutoGrid Service Point."
              />
            </label>

            <div className="col-sm-9">
              <div className="col-md-9 mt-2">
                <div className="form-group row">
                  <div className="col-sm-8">
                    <input
                      type="text"
                      autoComplete="none"
                      className="form-control form-control-sm"
                      name="servicePoint"
                      // disabled={!serviceOverrideDerTab}
                      placeholder="Service Point Id"
                      maxLength="50"
                      value={autogridDetails.servicePoint}
                      onChange={servicePointIdChangeHandler}
                    />
                  </div>
              { false &&   <div className=" custom-control custom-switch pointer mx-3">
                    <input
                      type="checkbox"
                      className="custom-control-input pointer asCompatible"
                      id="servicePointOverrideDerValue"
                      value={serviceOverrideDerTab}
                      checked={serviceOverrideDerTab}
                      onChange={serviceOverrideDerTabHandler}
                    />
                    <label
                      className="custom-control-label line-height-25 pointer"
                      htmlFor="servicePointOverrideDerValue"
                    ></label>
                  </div>}
                </div>
              </div>
            </div>
          </div>

          <div className="row is-flex d-flex justify-content-center">
            {SMART_METER_DER !== undefined && (
              <div className="col-md-6 mt-1 p-1">
                <div className="col-md-12 border-radius-5px p-1">
                  <div className="row h-100">
                    {/* <div className= "col-md-12 justify-content-center"> */}
                    <div className="col-md-12 mt-1 d-flex flex-column align-items-center">
                      <span className="bold">Smart Meter</span>
                      <span className="mt-1">{SMART_METER_DER.name}</span>
                    </div>
                    {/* <div className="col-md-12 mt-1  text-center">
                   
                    </div> */}
                    {/* </div> */}
                    <div className= "col-md-12">
                      <div className="col-md-12">
                      <div className="row">
                        <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 pr-2">
                          Id<span className="se-mandatory">*</span>
                          <Tooltip
                            position="right"
                            iconColor="secondary"
                            iconSize="nano"
                            information={tooltipData?.smartMeterId.data}
                          />
                        </div>
                        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                          {/* <div className="col-md-12"> */}
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder=""
                              maxLength="50"
                              // disabled={!utilityOverrideDerTab}
                              value={SMART_METER_DER.id}
                              onChange={(e) =>
                                idChangeHandler(e, SMART_METER_DER.name)
                              }
                            />
                          {/* </div> */}
                        </div>
                      </div>
                     { false && <div className="row mt-1">
                        <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 "></div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                          <div className="custom-control custom-switch pointer">
                            <input
                              type="checkbox"
                              className="custom-control-input pointer asCompatible"
                              id="utilityOverrideDerValue"
                              value={utilityOverrideDerTab}
                              checked={utilityOverrideDerTab}
                              onChange={utilityOverrideDerTabHandler}
                            />
                            <label
                              className="custom-control-label line-height-25 pointer"
                              htmlFor="utilityOverrideDerValue"
                            ></label>
                          </div>
                        </div>
                      </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {BATTERY_DER !== undefined && (
              <div className="col-md-6 mt-1 p-1">
                <div className="col-md-12 border-radius-5px p-1">
                  <div className="row ">
                    <div className="col-md-12 mt-1  text-center">
                      <span className="bold">Battery</span>
                    </div>
                    <div className="col-md-12 mt-1  text-center">
                      <span>{BATTERY_DER.name}</span>
                    </div>
                    <div className="col-md-12 mx-3  mt-1">
                      <div className="row ">
                        <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                          <label>
                            Exists for AutoGrid
                            <Tooltip
                              position="right"
                              iconColor="secondary"
                              iconSize="nano"
                              information="Toggle this switch to exclude the from AutoGrid optimization"
                            />
                          </label>
                        </div>
                        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                          <div className="custom-control custom-switch pointer mx-3">
                            <input
                              type="checkbox"
                              className={`custom-control-input pointer asCompatible `}
                              disabled={`${
                                siteCreated || projectStatusData?.projectStatus === "COMPLETED"
                                  ? "true"
                                  : !UserScope.access.saveProgram
                                  ? "true"
                                  : "false"
                              }`}
                              id="bessExistAutogrid"
                              checked={BATTERY_DER.enabled}
                              value={BATTERY_DER.enabled}
                              onChange={(e) =>
                                existAutogridChangeHandler(e, BATTERY_DER.name)
                              }
                            />
                            <label
                              className="custom-control-label line-height-25 pointer"
                              htmlFor="bessExistAutogrid"
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12  mx-3  mt-1">
                      <div className="row pr-3">
                        <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                          Id<span className="se-mandatory">*</span>
                          <Tooltip
                            position="right"
                            iconColor="secondary"
                            iconSize="nano"
                            information="Identifier for existing AutoGrid Battery."
                          />
                        </div>
                        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                          <div className="col-md-12">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder=""
                              maxLength="50"
                              //disabled={!BATTERY_DER.enabled || bessOverrideDerTab1}
                              // disabled={!bessOverrideDerTab1}
                              value={BATTERY_DER.id}
                              onChange={(e) =>
                                idChangeHandler(e, BATTERY_DER.name)
                              }
                            />
                          </div>
                        </div>
                      </div>
                   {  false && <div className="row mt-1">
                        <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 "></div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                          <div className="custom-control custom-switch pointer mx-3">
                            <input
                              type="checkbox"
                              className="custom-control-input pointer asCompatible"
                              id="bessOverrideDerValue1"
                              value={bessOverrideDerTab1}
                              checked={bessOverrideDerTab1}
                              onChange={bessOverrideDerTabHandler1}
                            />
                            <label
                              className="custom-control-label line-height-25 pointer"
                              htmlFor="bessOverrideDerValue1"
                            ></label>
                          </div>
                        </div>
                      </div>}
                    </div>
                    <div className="col-md-12 mt-1 mx-3">
                      <div className="row pt-2">
                        <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                          Maximum capacity (MWh)
                          <span className="se-mandatory">*</span>
                          <Tooltip
                            position="right"
                            iconColor="secondary"
                            iconSize="nano"
                            information={tooltipData?.maximumCapacity?.data}
                          />
                        </div>
                        <div className="col-sm-5 col-md-5 col-lg-5 col-xl-5 ">
                          <div className="col-md-12 pl-2">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder=""
                              maxLength="50"
                              // disabled={!bessOverrideDerTab}
                              value={BATTERY_DER.capacity}
                              onChange={(e) =>
                                maxCapacityChangeHandler(e, BATTERY_DER.name)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                 {  false && <div className="col-md-12 mx-3  mt-1">
                      <div className="row">
                        <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                          <label>
                            Override DER tab value
                            <Tooltip
                              position="right"
                              iconColor="secondary"
                              iconSize="nano"
                              information="Value is taken from DER tab by default, toggle
                            this switch to enter a custom capacity."
                            />{" "}
                            {"    "}
                          </label>
                        </div>
                        <div className="col-sm-5 col-md-5 col-lg-5 col-xl-5">
                          <div className="custom-control custom-switch pointer mx-3">
                            <input
                              type="checkbox"
                              className="custom-control-input pointer asCompatible"
                              id="overrideDerValue"
                              value={bessOverrideDerTab}
                              checked={bessOverrideDerTab}
                              onChange={bessOverrideDerTabHandler}
                            />
                            <label
                              className="custom-control-label line-height-25 pointer"
                              htmlFor="overrideDerValue"
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
            )}

            {autogridDetails.resources
              .filter((resource) => resource.type === "PV")
              .map((pvResource, idx) => (
                <div className={`mt-1 col-md-6 p-1`} key={pvResource.name}>
                  <div className="col-md-12 border-radius-5px p-1">
                    <div className="row pb-2">
                      <div className="col-md-12 mt-1 d-flex flex-column align-items-center ">
                        <span className="bold">PV</span>
                        <span>{pvResource.name}</span>
                      </div>
                      {/* <div className="col-md-12 mt-1 text-center">
                      </div> */}
                      <div className="col-md-12 mx-3  mt-1">
                        <div className="row">
                          <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                            <label>
                              Exists for AutoGrid
                              <Tooltip
                                position="right"
                                iconColor="secondary"
                                iconSize="nano"
                                information=" Toggle this switch to exclude the PV from
                            AutoGrid optimization"
                              />
                            </label>
                          </div>
                          <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                            <div className="custom-control custom-switch pointer mx-3">
                              <input
                                type="checkbox"
                                className="custom-control-input pointer asCompatible"
                                disabled={`${
                                  siteCreated || projectStatusData?.projectStatus === "COMPLETED"
                                    ? "true"
                                    : !UserScope.access.saveProgram
                                    ? "true"
                                    : "false"
                                }`}
                                id={`pvExistAutogrid+${idx}`}
                                value={pvResource.enabled}
                                checked={pvResource.enabled}
                                onChange={(e) =>
                                  existAutogridChangeHandler(e, pvResource.name)
                                }
                              />
                              <label
                                className="custom-control-label line-height-25 pointer"
                                htmlFor={`pvExistAutogrid+${idx}`}
                              ></label>
                            </div>
                          </div>
                        </div>
                        <div className="row pr-3">
                          <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                            Id<span className="se-mandatory">*</span>
                            <Tooltip
                              position="right"
                              iconColor="secondary"
                              iconSize="nano"
                              information={tooltipData?.pVId?.data}
                            />
                          </div>
                          {pvResource.name === "Photo Voltaic Branch 2" ? (
                            <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                              <div className="col-md-12">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  placeholder=""
                                  maxLength="50"
                                  // disabled={!pvResource.enabled || pvOverrideDerTab1}
                                  // disabled={!pvOverrideDerTab2}
                                  value={pvResource.id}
                                  onChange={(e) =>
                                    idChangeHandler(e, pvResource.name)
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                              <div className="col-md-12">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  placeholder=""
                                  maxLength="50"
                                  // disabled={!pvResource.enabled || pvOverrideDerTab1}
                                  // disabled={!pvOverrideDerTab1}
                                  value={pvResource.id}
                                  onChange={(e) =>
                                    idChangeHandler(e, pvResource.name)
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      { false && <div className="col-md-12  mx-3  mt-1">                       
                       <div className="row mt-1">
                          <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 "></div>
                          {pvResource.name === "Photo Voltaic Branch 2" ? (
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                              <div className="custom-control custom-switch pointer mx-3">
                                <input
                                  type="checkbox"
                                  className="custom-control-input pointer asCompatible"
                                  id={`pvOverrideDerValue2`}
                                  value={pvOverrideDerTab2}
                                  checked={pvOverrideDerTab2}
                                  onChange={pvOverrideDerTabHandler2}
                                />
                                <label
                                  className="custom-control-label line-height-25 pointer"
                                  htmlFor={`pvOverrideDerValue2`}
                                ></label>
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
                              <div className="custom-control custom-switch pointer mx-3">
                                <input
                                  type="checkbox"
                                  className="custom-control-input pointer asCompatible"
                                  id={`pvOverrideDerValue1`}
                                  value={pvOverrideDerTab1}
                                  checked={pvOverrideDerTab1}
                                  onChange={pvOverrideDerTabHandler1}
                                />
                                <label
                                  className="custom-control-label line-height-25 pointer"
                                  htmlFor={`pvOverrideDerValue1`}
                                ></label>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoGrid;
