import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import ApiSession from "../api/ApiSession";
import ProgressBarContainer from "../components/progressBar/progressBarContainer";
import SecureArchitectureHook from "./SecureArchitectureHook";
import SystemPage from "./SystemPageHook";
import embLabels from "../config/emb-labels-en.json";
import RoutingSettings from "./RoutingPages/RoutingSettingsHook";
import { useDispatch } from "react-redux";
import { getProjectId, getProjectTypeValue } from "../utils/project";
import TimeSync from "./SystemPages/TimeSync";

const apiSession = new ApiSession();
const Systems = (props) => {
  const [timeSyncData, setTimeSyncData] = useState();
  const [key, setKey] = useState("system");
  const [loading, setLoading] = useState(true);
  const [projectStatusData, setProjectStatusData] = useState();
  const [projectInformationData, setProjectInformationData] = useState();
  const [snackBar, setSnackBar] = useState({ type: "", message: "" });
  const [siteCreated, setSiteCreated] = useState(false);
  const [siteType, setSiteType] = useState("");
  const [clientType, setClientType] = useState("");
  const [architectureType, setArchitectureType] = useState("");
  let userSampleData = {
    name: "",
    role: "",
    access: "",
  };
  const dispatch = useDispatch();
  let userScope =
    JSON.parse(localStorage.getItem("scope")) !== null
      ? JSON.parse(localStorage.getItem("scope"))
      : userSampleData;
  const [UserScope] = useState(userScope);
  const getProjectDetailStatus = () => {
    try {
      apiSession
      .getProjectStatus(getProjectId())
      .then((response) => {
        if (response) {
          setLoading(false);
          setProjectStatusData(response);
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 412) {
            callSnackbar("error", `${error?.message}`);
          }
        }
      });
    } catch (error) {
      // callSnackbar("error", `${error}`);
    }
  
  };
  const callSnackbar = (type, message) => {
    setSnackBar({ type, message });
    document.getElementById("custom-systempage").open = true;
  };
  useEffect(() => {
    getProjectDetailStatus();
  }, [siteCreated]);
  useEffect(() => {
    if (getProjectId() === null) {
      let { history } = props;
      history.push({
        pathname: "/information",
      });
    } else {
     try {
      apiSession
      .getSystemInfo(getProjectId())
      .then((response) => {
        setSiteType(response.siteType);
        setClientType(response.clientType);
        setArchitectureType(response.architecture);
        setSiteCreated(response.siteCreated);
        setProjectInformationData(response)
      })
      .catch((error) => {
        setLoading(false);
      });
     } catch (error) {
      console.log("Error fetching system info:", error)
     }
 
    }
  }, []);
  return (
    <div className=" container-fluid h-100 container-margin">
      {loading ? (
        <div className="stickyProgressBar">
          <div className="d-flex justify-content-between">
          <se-skeleton  height="20px" style={{width:"33%"}}></se-skeleton>
            <se-skeleton  height="20px" style={{width:"33%"}}></se-skeleton>
            <se-skeleton  height="20px" style={{width:"33%"}}></se-skeleton>
          </div>
        </div>
      ) : (
        <ProgressBarContainer projectStatusData={projectStatusData} />
      )}
      
      <se-container option="centered">
        <div className="row">
          {UserScope.access.saveSystemInfo !== true && (
            <div className="col-sm-12 text-center mt-1">
              <span className="se-error-message-note text-center">
                {`Information: ${UserScope.role} is not authorized to perform action on this page`}
              </span>
            </div>
          )}         
          {projectStatusData?.projectStatus === "COMPLETED" && (
            <div className="col-sm-12 text-center mt-1">
              <span className="se-error-message-note text-center">
                {`Change the project state to in-progress to update the project`}
              </span>
            </div>
          )}
           {UserScope.access.saveSystemInfo && siteCreated && (
            <div className="col-sm-12 text-center mt-1">
              <span className="se-error-message-note text-center">
                {`Site already created for the project, no more modifications allowed`}
              </span>
            </div>
          )}
        </div>
        <div className="container mt-1 mb-1">
          <Tabs
            id="controlled-tab"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="system" title="System Setting">
              <SystemPage projectInformationData = {projectInformationData} projectStatusData={projectStatusData} siteCreated={siteCreated} setKey={setKey} key ={key} timeSyncData={timeSyncData}/>
            </Tab>
           {siteType === embLabels.GRID_CONNECTED_GSX||
           (siteType === embLabels.ISLANDABLE_GSX || getProjectTypeValue() ===  "MGaaS ANSI") 
           ||
           (siteType === embLabels.GRID_CONNECTED && getProjectTypeValue() ===  "MGaaS IEC")
           || (siteType === embLabels.ISLANDABLE && getProjectTypeValue() ===  "MGaaS IEC")
           ? <Tab eventKey="secure" title="Secure Architecture">
              <SecureArchitectureHook projectStatusData={projectStatusData} siteCreated={siteCreated} siteType={siteType} architectureType={architectureType}/>
          </Tab>:""}
            {siteType === embLabels.GRID_CONNECTED_GSX||
           (siteType === embLabels.ISLANDABLE_GSX|| getProjectTypeValue() ===  "MGaaS ANSI") 
           ||
           (siteType === embLabels.GRID_CONNECTED && getProjectTypeValue() ===  "MGaaS IEC")
           || (siteType === embLabels.ISLANDABLE && getProjectTypeValue() ===  "MGaaS IEC")
           ? <Tab eventKey="routing" title="Routing">
           <RoutingSettings projectStatusData={projectStatusData} siteCreated={siteCreated}/>
          </Tab>:""}
         {(siteType !== "GENSET_PVBESS_A2E" && getProjectTypeValue() !== "MGaaS AccessToEnergy")&& <Tab eventKey="time" title="Time Synchronization" >
            <TimeSync setTimeSyncData= {setTimeSyncData} projectStatusData={projectStatusData} siteCreated={siteCreated} siteType={siteType}/>
          </Tab>}
          </Tabs>
        </div>
      </se-container>
      <se-snackBar
        id="custom-systempage"
        type={snackBar.type}
        message={snackBar.message}
        icon="information_stroke"
        duration="3000"
      ></se-snackBar>
      </div>
    
  );
};

export default Systems;
