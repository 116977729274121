import React, { useCallback, useContext } from "react";
import { AssetBlockContext } from "../CustomHooks/ContextApi";
import embLabels from "../../config/emb-labels-en.json";
import ACTIONS from "../DesignAssets/Design_Asset_Actions.json"
import Tooltip from "../../utils/Tooltip";
import { useSelector } from "react-redux";
import { getProjectTypeValue } from "../../utils/project";
import CBContactorDetails from "./CBContactorDetails";

const CBConfigDetails = () => {
  const {
    CBAssetList,
    updateCbConfigData,
    configData,
    handleCBConfigChange,
    assetEditStatusUpdateAssetCB,
    selectedValues,
    siteType,
    detailsBlock,
    projectTypeValue,
    clientType,
    jsonData,
    isContactorEnabled,
    CBAssetListDispatch
  } = useContext(AssetBlockContext);
  const tooltipData = useSelector(state=>state?.tooltipData?.tooltipData)
  const cbConfigChangeHandler = e => { 
    handleCBConfigChange(e);
    assetEditStatusUpdateAssetCB(e, true, selectedValues?.selectedAssetCB);
  };
  const contactorChangeHandler = e => { 
    if(e.target.checked){
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CB_CONTACTOR_CONFIG,
        payload: {
          selectedAssetCB: selectedValues.selectedAssetCB,
          cbConfigData: configData.contactorConfigData,
          checked: true,
          },
          })
          }else{
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_CB_CONTACTOR_CONFIG,
          payload: {
            selectedAssetCB: selectedValues.selectedAssetCB,
            cbConfigData: configData.contactorConfigData,
            checked: false,
            },
        })
      }
    handleCBConfigChange(e);
    assetEditStatusUpdateAssetCB(e, true, selectedValues?.selectedAssetCB);
  };
  const projectType = getProjectTypeValue()
const assetInfo = CBAssetList[selectedValues?.selectedAssetCB]?.cbConfigData
?.cbAssetDetails[0]?.assetInfo
const assetType =CBAssetList[selectedValues?.selectedAssetCB]?.assetType
  const isMotorisedDisbaled =()=>{
    if(["Utility","Genset"].includes(assetType)){
      return !["Utility","Genset"].includes(assetType)
    }else{
      return (projectType === "MGaaS IEC" && assetInfo === "Hardwired");
    }
  }
const checked = useCallback(() =>{
  if(CBAssetList[selectedValues?.selectedAssetCB]?.uiData?.contactor){
    return true;
  }else{
    return false;
  }
},[CBAssetList])
  
  return ( 
    <>
      <div className="col-md-12 mt-2 se-white-background align-middle">
        <div className="row">
          <label className="col-md-6 bold mt-2 mb-2 se-font-14px pl-3">
            Asset Settings
          </label>

          <div className="col-md-6 mt-1 text-right">
            <button
              type="button"
              className="btn btn-secondary btn-sm se-btn-primary"
              onClick={updateCbConfigData}
            >
              <se-icon size="nano">action_save</se-icon>
              <span>&nbsp;</span>Save
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-12 se-grey-background">
        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">
            Name <span className="se-mandatory">*</span>
          </label>

          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput1"
            placeholder="Name"
            name="name"
            maxLength="50"
            value={configData?.cbConfigData?.name}
            onChange={cbConfigChangeHandler}
          />
          <div
            className={`invalid-feedback-custom ${
              !configData?.cbConfigData?.name ? "" : "hidden"
            }`}
          >
            {embLabels?.nameRequired}
          </div>
          <div
            className="invalid-feedback-custom"
            style={configData?.cbConfigData?.name !==""  && !/^[a-zA-Z0-9-_& ]+$/.test(configData?.cbConfigData?.name )? {} : { display: "none" }}
          >
            {embLabels.notallowedAlphanumericValues}
          </div>
        </div>
        <div className="form-group mb-1">
         
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input asCompatible"
              name="motorized"
              id="customControlValidation4"
              value={configData?.cbConfigData?.motorized}
              checked={configData?.cbConfigData?.motorized}
              onChange={cbConfigChangeHandler}
              disabled={!isMotorisedDisbaled()}

            />
            <label
              className="custom-control-label line-height-25"
              htmlFor="customControlValidation4"
            >
              Motorized Circuit Breaker
            </label>
          
            <Tooltip
                  position="left"
                  iconColor="secondary"
                  iconSize="small"
                  information={tooltipData?.motorized?.data}
                />
          </div>
        {isMotorisedDisbaled()&&
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input asCompatible"
              name="contactor"
              id="customControlValidation5"
              value={checked()}
              checked={checked()}
              onChange={contactorChangeHandler}
              // disabled={configData?.cbConfigData?.motorized}

            />
            <label
              className="custom-control-label line-height-25"
              htmlFor="customControlValidation5"
            >
              Circuit Breaker + Contactor
            </label>
          
            {/* <Tooltip
                  position="bottom"
                  iconColor="secondary"
                  iconSize="small"
                  information={tooltipData?.motorized?.data}
                /> */}
        </div>}
          </div>
         

        
        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">Description</label>
          <input
            type="text"
            autoComplete="none"
            className="form-control form-control-sm"
            id="exampleFormControlInput1"
            placeholder="Description"
            name="description"
            maxLength="100"
            value={configData?.cbConfigData?.description}
            onChange={cbConfigChangeHandler}
          />
        </div>
        {[ embLabels.GRID_CONNECTED,embLabels.ISLANDABLE].includes(siteType)&&
        clientType === embLabels.GENERIC_PROJECT &&
        projectTypeValue === "MGaaS IEC" &&
        (CBAssetList[selectedValues?.selectedAssetCB]?.uiData?.contactor) && (
          <CBContactorDetails detailsBlock={detailsBlock} />
        )}
      </div>
    </>
  );
};

export default CBConfigDetails;