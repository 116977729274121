import React, { useContext } from "react";
import useCBMouserOver from "../../pages/CustomHooks/useCBMouserOver";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";

const Load = ({ load, loadName, loadCbName, index, powerMeterData }) => {
  const {
    assetDetailsReset,
    UserScope,
    callSnackbar,
    CBLoadListDispatch,
    selectedValuesHandler, 
    loadConfigDataHandler,
    loadCbConfigDataHandler,
    projectKind,
    selectedValues,
    pmConfigDataHandler,
    coordinates: {busbarCordinates},
    loadpmConfigDataHandler,
  } = useContext(DesignAssetContext);

  const [mouseOverData, CBMouseHandler] = useCBMouserOver();

  const loadSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "loadDetailsBlock";
      const selectedAssetValue = "selectedLoad";
      CBLoadListDispatch({
        type: ACTIONS.LOAD_SELECT,
        payload: {
          index,
        },
      });
      selectedValuesHandler(index, selectedAssetValue, assetBlock);
      loadConfigDataHandler(index);
    } else {
      callSnackbar(
        "error",
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const loadCBSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "cbLoadDetailsBlock";
      const selectedAssetValue = "selectedLoadCB";
      CBLoadListDispatch({
        type: ACTIONS.LOAD_CB_SELECT,
        payload: {
          index,
        },
      });
      selectedValuesHandler(index, selectedAssetValue, assetBlock);
      loadCbConfigDataHandler(index);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const pmSelectHandler = () => {

    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      // const assetBlock = "newSBCbAssetDetailsBlock";

      const assetBlock = "pmAssetDetailsBlockGrid";
      const selectedAssetValue = "selectedLoad";
      CBLoadListDispatch({
        type: ACTIONS.LOAD_CB_SELECT,
        payload: {
          index,
        },
      });
      CBLoadListDispatch({
        type: ACTIONS.LOAD_ASSET_PM_SELECT,
        payload: {
          index,
        },
      });
      selectedValuesHandler(index, selectedAssetValue, assetBlock);
      // newSBCbConfigDataHandler(2);
      loadpmConfigDataHandler(index);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  return (
    <svg className="pointer" >
    {projectKind.isMgaasIecIsland&&!load?.cbRequired?
    <>
    <g id="CB1-load">
        <svg
          width="70px"
          height="88px"
          viewBox="0 0 10 149"
          x={index >= 5 ? (535 + (index-5) * 120) : ( 345 + (index+1) * 120)}
          y={load.yAxisCB}
          // onClick={loadCBSelectHandler}
        >
          <title>{loadCbName}</title>
          <g
            id="Circuit Breaker"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Circuit Breaker 1"
              transform="translate(-651.000000, -512.000000)"
            >
              <g
                id="Circuit Breaker - closed"
                transform="translate(604.000000, 514.000000)"
                // onMouseOver={CBMouseHandler}
                // onMouseOut={CBMouseHandler}
              >
                <line
                  x1="48.5"
                  y1="8"
                  x2="48.501111"
                  y2="145"
                  id="Line-2-Copy-15"
                  stroke={"#333333" }
                  strokeWidth="4"
                  strokeDasharray="8 10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
              </g>
            </g>
          </g>
        </svg>
      </g>
    </>:
    <>
      <g id="CB1-load">
        <svg
          width="70px"
          height="91px"
          viewBox="0 0 10 149"
          x={index >= 5 ? (535 + (index-5) * 120) : ( 345 + (index+1) * 120)}
          y={load.yAxisCB}
          onClick={loadCBSelectHandler}
        >
          <title>{loadCbName}</title>
          <g
            id="Circuit Breaker"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Circuit Breaker 1"
              transform="translate(-651.000000, -512.000000)"
            >
              <g
                id="Circuit Breaker - closed"
                transform="translate(604.000000, 514.000000)"
                onMouseOver={CBMouseHandler}
                onMouseOut={CBMouseHandler}
              >
                <g
                  id="Group-15"
                  strokeWidth="4"
                  fill={
                    load.CBSelected
                      ? "rgba(65, 181, 230, 0.35)"
                      : mouseOverData
                        ? "rgba(65, 181, 230, 0.35)"
                        : "none"
                  }
                  stroke={load.CBSelected ? "#42B4E6" : "none"}
                >
                  <rect
                    id="Rectangle-Copy-20"
                    x="0"
                    y="8"
                    width="96"
                    height="124"
                  ></rect>
                  <g
                    id="Group-2"
                    transform="translate(48.000000, 0.000000)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    stroke={load.CBConfigured ? "#333333" : "#E47F00"}
                    strokeWidth="4"
                  >
                    <line
                      x1="0.5"
                      y1="0"
                      x2="0.5"
                      y2="47.8298611"
                      id="Line-2"
                    ></line>
                  </g>
                </g>
                <line
                  x1="48.5"
                  y1="97.1701389"
                  x2="48.501111"
                  y2="145"
                  id="Line-2-Copy-15"
                  stroke={load.CBConfigured ? "#333333" : "#E47F00"}
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <path
                  d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                  id="Path-11"
                  stroke={load.CBConfigured ? "#333333" : "#E47F00"}
                  strokeWidth="4"
                  strokeLinecap="round"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </g>
    </>}
    {(projectKind.isMgaasIecIsland)&& <g id="power meter" style={load.pmRequired ? {} : { display: "none"}}>
        <svg
          x={index >= 5 ? (435 + (index-5) * 120) :  ( 245 + (index+1) * 120)}
          y={load.yAxisPM}
          width="300px"
          height="140px"
          viewBox="0 0 100 160"
          onClick={pmSelectHandler}
        >
          <title>{powerMeterData?.name}</title>
          <line
            x1="30"
            y1="127"
            x2="60"
            y2="127"
            stroke={load.pmConfiguredGrid ? "#333" : "#E47F00"}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            id="Pm outer"
            stroke="#42B4E6"
            strokeWidth="3"
            cx="80"
            cy="127"
            r="26"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="rgba(0, 135, 205, 0.2)"
            style={load.pmSelected ? {} : { display: "none" }}
          ></circle>
          <circle
            id="pm inner"
            stroke={load.pmConfiguredGrid ? "#333" : "#E47F00"}
            strokeWidth="3"
            cx="80"
            cy="127"
            r="18.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="#FFFFFF"
          ></circle>
          <text
            id="PM"
            fontSize="16"
            fontWeight="normal"
            stroke="#333"
            fontFamily="Nunito-Regular, Nunito"
            fill="#333"
          >
            <tspan x="67" y="132">
              PM
            </tspan>
          </text>
        </svg>
      </g>}
      <g id="load1">
        <svg
          x={index >= 5 ? (490 + (index-5) * 120) : ( 300 + (index+1) * 120)}
          y={load.yAxis}
          width="149px"
          height="475px"
          viewBox="0 0 90 550"
          onClick={loadSelectHandler}
        >
          <title>{loadName}</title>
          <g
            id="Design"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Design/Assets-_-00"
              transform="translate(-604.000000, -372.000000)"
            >
              <g
                id="SLD/Production/Uniline/Undefined"
                transform="translate(604.000000, 372.000000)"
              >
                <g id="SLD/Production/Uniline/Tobedefine">
                  <g id="Group-7">
                    <rect
                      id="Rectangle"
                      x="0"
                      y="95"
                      width="96"
                      height="148"
                    ></rect>

                    <line
                      x1="48.5"
                      y1={[377,460,550,570].includes(load.yAxis) ? "0" : "96"}
                      x2="48.5"
                      y2={(projectKind.isMgaasIecIsland&&[550,460].includes(load.yAxis))?"200":"144"}
                      id="Line-2"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      stroke={load.configured ? "#333333" : "#E47F00"}
                    ></line>

                    <circle
                      id="Oval"
                      stroke="#42B4E6"
                      strokeWidth="3"
                      cx="48"
                      // cy={(projectKind.isMgaasIecIsland&&[550].includes(load.yAxis))?"225":"193.5"}
                      cy={(projectKind.isMgaasIecIsland&&[550,460].includes(load.yAxis))?"230":"193.5"}
                      r="55"
                      style={load.selected ? {} : { display: "none" }}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="rgba(0, 135, 205, 0.2)"
                    ></circle>

                    <circle
                      id="Oval"
                      strokeWidth="3"
                      fill="#FFFFFF"
                      cx="48"
                      // cy={(projectKind.isMgaasIecIsland&&[550].includes(load.yAxis))?"225":"193.5"}
                      cy={(projectKind.isMgaasIecIsland&&[550,460].includes(load.yAxis))?"230":"193.5"}
                      r="46.5"
                      stroke={load.configured ? "#333333" : "#E47F00"}
                    ></circle>

                    <text
                      id="segment_energy_infra"
                      fontFamily="se-icon"
                      fontSize="56"
                      fontWeight="normal"
                      fill="#333333"
                    >
                      {/* <tspan x="20" y={(projectKind.isMgaasIecIsland&&[550].includes(load.yAxis))?"245":"210"}> */}
                    <tspan x="20" y={(projectKind.isMgaasIecIsland&&[550,460].includes(load.yAxis))?"245":"210"}>
                        segment_building_small
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </g>
    </svg>
  );
};

export default Load;
