import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";
import useCBMouserOver from "../../pages/CustomHooks/useCBMouserOver";
import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";

const Linebar = () => {
  const {
    CBAssetList: [
      { uiData },
      ,
      { uiData: existingUiData },
      { uiData: existingPvUiData },
    ],
    assetDetailsReset,
    UserScope,
    callSnackbar,
    CBAssetListDispatch,
    selectedValuesHandler,
    detailsBlock,
    loadSide,
    newSBCbConfigDataHandler,
    remoteMain,
  } = useContext(DesignAssetContext);
  const [mouseOverData, CBMouseHandler] = useCBMouserOver();

  const utilityCBSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      // const assetBlock = "cbAssetDetailsBlock";
      const assetBlock = "newSBCbAssetDetailsBlock";
      const selectedAssetValue = "selectedAssetCB";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_CB_SELECT,
        payload: {
          index: 0,
        },
      });
      selectedValuesHandler(0, selectedAssetValue, assetBlock);
      // cbConfigDataHandler(4);
      newSBCbConfigDataHandler(0);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  return (
    <>
      {loadSide === "LOAD_SIDE" ? (
        <svg>
          <g id="linebar">
            <g id="lines">
              {remoteMain ? (
                <>
                  <line
                    x1={"274"}
                    y1={"380.5"}
                    x2="477.5"
                    y2={"380"}
                    stroke="#333333"
                    strokeWidth="3"
                  />
                </>
              ) : (
                <>
                  <line
                    x1="300"
                    y1="332"
                    x2="300"
                    y2="280"
                    stroke="#333333"
                    strokeWidth="3"
                  />

                  <line
                    x1={"300"}
                    y1={"330"}
                    x2="475.5"
                    y2={"330"}
                    stroke="#333333"
                    strokeWidth="3"
                  />
                  <line
                    x1="475"
                    y1={"328"}
                    x2="475"
                    y2={"370"}
                    stroke="#333333"
                    stroke-width="3"
                  ></line>
                </>
              )}
              {uiData.cbRequired && (
                <>
                  <g id="CB3">
                    <svg
                      width="75px"
                      height="100px"
                      viewBox="0 0 10 149"
                      x={remoteMain ? "240" : "440"}
                      y={remoteMain ? "280" : "370"}
                      onClick={utilityCBSelectHandler}
                      className="pointer"
                    >
                      <g
                        id="Circuit Breaker"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          id="Circuit Breaker 1"
                          transform="translate(-651.000000, -512.000000)"
                        >
                          <g
                            id="Circuit Breaker - closed"
                            transform="translate(604.000000, 514.000000)"
                            onMouseOver={CBMouseHandler}
                            onMouseOut={CBMouseHandler}
                          >
                            <g
                              id="Group-15"
                              strokeWidth="4"
                              fillRule="evenodd"
                              fill={
                                uiData.CBSelected
                                  ? "rgba(65, 181, 230, 0.35)"
                                  : mouseOverData
                                  ? "rgba(65, 181, 230, 0.35)"
                                  : "none"
                              }
                              stroke={uiData.CBSelected ? "#42B4E6" : "none"}
                            >
                              <rect
                                id="Rectangle-Copy-20"
                                x="0"
                                y="12"
                                width="96"
                                height="112"
                              ></rect>
                              <g
                                id="Group-2"
                                transform="translate(48.000000, 0.000000)"
                                stroke={
                                  uiData.CBConfigured ? "#333333" : "#E47F00"
                                }
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="4"
                              >
                                <line
                                  x1="0.5"
                                  y1="0"
                                  x2="0.5"
                                  y2="47.8298611"
                                  id="Line-2"
                                ></line>
                              </g>
                            </g>
                            <line
                              x1="48.5"
                              y1="97.1701389"
                              x2="48.501111"
                              y2="145"
                              id="Line-2-Copy-15"
                              stroke={
                                uiData.CBConfigured ? "#333333" : "#E47F00"
                              }
                              strokeWidth="4"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></line>
                            <path
                              d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                              id="Path-11"
                              strokeWidth="4"
                              strokeLinecap="round"
                              stroke={
                                uiData.CBConfigured ? "#333333" : "#E47F00"
                              }
                            ></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </g>

                  {remoteMain && (
                    <g>
                      <line
                        x1="476"
                        y1={"378"}
                        x2="476"
                        y2={"419"}
                        stroke="#333333"
                        stroke-width="3"
                      ></line>
                      <g id="CB3">
                        <svg
                          width="75px"
                          height="100px"
                          viewBox="0 0 10 149"
                          x={"440"}
                          y={"420"}
                          className="pointer"
                        >
                          <g
                            id="Circuit Breaker"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <g
                              id="Circuit Breaker 1"
                              transform="translate(-651.000000, -512.000000)"
                            >
                              <g
                                id="Circuit Breaker - closed"
                                transform="translate(604.000000, 514.000000)"
                              >
                                <g
                                  id="Group-15"
                                  strokeWidth="4"
                                  fillRule="evenodd"
                                  fill={"none"}
                                  stroke={"none"}
                                >
                                  <rect
                                    id="Rectangle-Copy-20"
                                    x="0"
                                    y="12"
                                    width="96"
                                    height="112"
                                  ></rect>
                                  <g
                                    id="Group-2"
                                    transform="translate(48.000000, 0.000000)"
                                    stroke={"#333333"}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="4"
                                  >
                                    <line
                                      x1="0.5"
                                      y1="0"
                                      x2="0.5"
                                      y2="47.8298611"
                                      id="Line-2"
                                    ></line>
                                  </g>
                                </g>
                                <line
                                  x1="48.5"
                                  y1="97.1701389"
                                  x2="48.501111"
                                  y2="145"
                                  id="Line-2-Copy-15"
                                  stroke={"#333333"}
                                  strokeWidth="4"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></line>
                                <path
                                  d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                                  id="Path-11"
                                  strokeWidth="4"
                                  strokeLinecap="round"
                                  stroke={"#333333"}
                                ></path>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </g>
                    </g>
                  )}
                </>
              )}
            </g>
          </g>
        </svg>
      ) : (
        <svg>
          <g id="linebar">
            <g id="lines">
              <line
                x1={existingPvUiData.existingPmRequired ? "410" : "230"}
                y1={"215"}
                x2="474"
                y2={"215"}
                stroke="#333333"
                strokeWidth="3"
              />
              <line
                x1="473.5"
                y1={"214"}
                x2="473.5"
                y2={"330"}
                stroke="#333333"
                stroke-width="3"
              ></line>
              {uiData.cbRequired && !remoteMain ? (
                <g id="CB3">
                  <svg
                    width="75px"
                    height="100px"
                    viewBox="0 0 10 149"
                    x={"439"}
                    y={"330"}
                    onClick={utilityCBSelectHandler}
                    className="pointer"
                  >
                    <g
                      id="Circuit Breaker"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        id="Circuit Breaker 1"
                        transform="translate(-651.000000, -512.000000)"
                      >
                        <g
                          id="Circuit Breaker - closed"
                          transform="translate(604.000000, 514.000000)"
                          onMouseOver={CBMouseHandler}
                          onMouseOut={CBMouseHandler}
                        >
                          <g
                            id="Group-15"
                            strokeWidth="4"
                            fillRule="evenodd"
                            fill={
                              uiData.CBSelected
                                ? "rgba(65, 181, 230, 0.35)"
                                : mouseOverData
                                ? "rgba(65, 181, 230, 0.35)"
                                : "none"
                            }
                            stroke={uiData.CBSelected ? "#42B4E6" : "none"}
                          >
                            <rect
                              id="Rectangle-Copy-20"
                              x="0"
                              y="12"
                              width="96"
                              height="112"
                            ></rect>
                            <g
                              id="Group-2"
                              transform="translate(48.000000, 0.000000)"
                              stroke={
                                uiData.CBConfigured ? "#333333" : "#E47F00"
                              }
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="4"
                            >
                              <line
                                x1="0.5"
                                y1="0"
                                x2="0.5"
                                y2="47.8298611"
                                id="Line-2"
                              ></line>
                            </g>
                          </g>
                          <line
                            x1="48.5"
                            y1="97.1701389"
                            x2="48.501111"
                            y2="145"
                            id="Line-2-Copy-15"
                            stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                            strokeWidth="4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></line>
                          <path
                            d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                            id="Path-11"
                            strokeWidth="4"
                            strokeLinecap="round"
                            stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </g>
              ) : (
                <g id="CB31">
                  <svg
                    width="75px"
                    height="100px"
                    viewBox="0 0 10 149"
                    x={"438"}
                    y={"330"}
                    className="pointer"
                  >
                    <g
                      id="Circuit Breaker"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        id="Circuit Breaker 1"
                        transform="translate(-651.000000, -512.000000)"
                      >
                        <g
                          id="Circuit Breaker - closed"
                          transform="translate(604.000000, 514.000000)"
                        >
                          <g
                            id="Group-15"
                            strokeWidth="4"
                            fillRule="evenodd"
                            fill={"none"}
                            stroke={"none"}
                          >
                            <rect
                              id="Rectangle-Copy-20"
                              x="0"
                              y="12"
                              width="96"
                              height="112"
                            ></rect>
                            <g
                              id="Group-2"
                              transform="translate(48.000000, 0.000000)"
                              stroke={"#333333"}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="4"
                            >
                              <line
                                x1="0.5"
                                y1="0"
                                x2="0.5"
                                y2="47.8298611"
                                id="Line-2"
                              ></line>
                            </g>
                          </g>
                          <line
                            x1="48.5"
                            y1="97.1701389"
                            x2="48.501111"
                            y2="145"
                            id="Line-2-Copy-15"
                            stroke={"#333333"}
                            strokeWidth="4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></line>
                          <path
                            d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                            id="Path-11"
                            strokeWidth="4"
                            strokeLinecap="round"
                            stroke={"#333333"}
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </g>
              )}
            </g>
          </g>
        </svg>
      )}
      {loadSide === "LOAD_SIDE" ? (
        <svg>
          <g
            id="embedded meter"
            style={uiData.emSelected ? {} : { display: "none" }}
          >
            <svg
              x={remoteMain ?"149" : "349" }
              y={remoteMain ? "223 " : "310" }
              width="300px"
              height="140px"
              viewBox="0 0 100 160"
            >
              <title>{"Embedded Metering"}</title>
              <line
                x1="29"
                y1="127"
                x2="80"
                y2="127"
                stroke={uiData.EMConfigured ? "#333" : "#E47F00"}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle
                id="em outer"
                stroke="#none"
                strokeWidth="3"
                cx="100"
                cy="127"
                r="26"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                style={uiData.emSelected ? {} : { display: "none" }}
              ></circle>
              <circle
                id="em inner"
                stroke={uiData.EMConfigured ? "#333" : "#E47F00"}
                strokeWidth="3"
                cx="100"
                cy="127"
                r="18.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="#FFFFFF"
              ></circle>
              <text
                id="EM"
                fontSize="16"
                fontWeight="normal"
                stroke="#333"
                fontFamily="Nunito-Regular, Nunito"
                fill="#333"
              >
                <tspan x="88" y="132">
                  EM
                </tspan>
              </text>
            </svg>
          </g>
        </svg>
      ) : (
        <svg>
          <g
            id="embedded meter"
            style={uiData.emSelected ? {} : { display: "none" }}
          >
            <svg
              x={remoteMain ? "105" :"349" }
              y={ remoteMain ? "126" : "269"}
              width="300px"
              height="140px"
              viewBox="0 0 100 160"
            >
              <title>{"Embedded Metering"}</title>
              <line
                x1="29"
                y1="127"
                x2="80"
                y2="127"
                stroke={uiData.EMConfigured ? "#333" : "#E47F00"}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle
                id="em outer"
                stroke="#none"
                strokeWidth="3"
                cx="100"
                cy="127"
                r="26"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                style={uiData.emSelected ? {} : { display: "none" }}
              ></circle>
              <circle
                id="em inner"
                stroke={uiData.EMConfigured ? "#333" : "#E47F00"}
                strokeWidth="3"
                cx="100"
                cy="127"
                r="18.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="#FFFFFF"
              ></circle>
              <text
                id="EM"
                fontSize="16"
                fontWeight="normal"
                stroke="#333"
                fontFamily="Nunito-Regular, Nunito"
                fill="#333"
              >
                <tspan x="88" y="132">
                  EM
                </tspan>
              </text>
            </svg>
          </g>
        </svg>
      )}
    </>
  );
};

export default Linebar;
