import React, { useState, useRef, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";

import ProjectBackgroundImage from "../images/EMB.png";

import Contact from "../pages/contact";
import ChangePassword from "../components/component/ChangePassword";
import GenerateReport from "../components/component/GenerateReport";
import AdminRoles from "../components/component/AdminRoles";

import useMountEffect from "../pages/CustomHooks/useMountEffect";
import userScope from "../pages/CustomHooks/userScope";
import configLabel from "../config/emb-labels-en.json";
import { useSelector } from "react-redux";
import APISESSION from "../api/ApiSession";
import { getProjectId } from "../utils/project";
import { shellLayoutConst } from "../utils/constants";

const apiSession = new APISESSION();
const src = "url(" + ProjectBackgroundImage + ")";
const privacyPolicy = `https://www.se.com/ww/en/about-us/legal/data-privacy.jsp`;

const FileDownload = require("js-file-download");

const ShellLayout = props => {
  const UserScope = userScope();
  const projectTypeName = useSelector(state => state.projectType);

  const [status, setStatus] = useState({
    reportReadyStatus: false,
    navStatus: true,
    enableAssetPage: false,
    enableProgramPage: false,
    enableSystemPage: false,
    enableSecureArchitecturePage: false,
    enableBOMPage: false,
    enableDeployPage: false,
  });
  const [popUpStatus, setPopUpStatus] = useState({
    changePasswordPopUpStatus: false,
    successChangePassword: false,
    modifiedDataAlertPopupStatus: false,
    openReportPopUp: false,
    loadingPopupStatus: false,
    adminRolePopStatus: false,
    logoutPopUpStatus: false,
  });
  const [modifiedStatus, setModifiedStatus] = useState({
    modifiedDataAlertAssetPopupStatus: false,
    modifiedDERName: "",
  });
  const [alertStatus, setAlertStatus] = useState({
    alertMessagePopupStatus: false,
    alertMessage: "",
  });
  const [reportStatus, setReportStatus] = useState({
    selectAll: false,
    information: false,
    bom: false,
    deploy: false,
    sld: false,
    system: false,
    program: false,
    showSeletOption: false,
    secureAC: false,
  });
  const [snackBar, setSnackBar] = useState({ type: "", message: "" });
  const [clientType, seClientType] = useState("")

  const projectNavigationRef = useRef(null);
  const userNavigationRef = useRef(null);
  const libraryNavigationRef = useRef(null);
  const globalNavigationRef = useRef(null);
  const analyticsNavigationRef = useRef(null);
  const contactNavigationRef = useRef(null);
  const [projectStatusData, setProjectStatusData] = useState();
  useMountEffect(() => {
    localStorage.setItem("deploymentReady", true);
    localStorage.setItem("programPage", false);
    localStorage.setItem("optimizationModified", false);
   // localStorage.setItem("timeSyncValidate", true);
    setStatus(prevState => ({ ...prevState, reportReadyStatus: false }));

    projectNavigationRef.current.addEventListener("didClick", () =>
      navigateProject()
    );

    if (UserScope.access.getUsers === true) {
      userNavigationRef.current.addEventListener("didClick", () =>
        navigateUser()
      );
    }

    if (UserScope.access.saveAssets === true) {
      libraryNavigationRef.current.addEventListener("didClick", () =>
        navigateLibrary()
      );
    }

    if (UserScope.access.globalParams === true) {
      globalNavigationRef.current.addEventListener("didClick", () =>
        navigateGlobals()
      );
    }

    if (UserScope.access.audit === true) {
      analyticsNavigationRef.current.addEventListener("didClick", () =>
        navigateAnalytics()
      );
    }

    if (UserScope.access.globalParams === true) {
      contactNavigationRef.current.addEventListener("didClick", e =>
        navigateContactUs(e)
      );
    }
  }, []);

  useEffect(() => {
   
   
    if (
      props?.children?.props?.history?.location?.pathname === "/project" ||
      props?.children?.props?.history?.location?.pathname === "/userdetails"
    ) {
      setStatus(prevState => ({ ...prevState, navStatus: false }));
    } else {
      setStatus(prevState => ({ ...prevState, navStatus: true }));

      resetEnable();
      if (props.children.props.history.location.pathname === "/information") {
        // nothing
      } else if (props.children.props.history.location.pathname === "/assets") {
        setStatus(prevState => ({ ...prevState, enableAssetPage: true }));
      } else if (
        props.children.props.history.location.pathname === "/program"
      ) {
        setStatus(prevState => ({ ...prevState, enableProgramPage: true }));
      } else if (props.children.props.history.location.pathname === "/systems") {
        setStatus(prevState => ({ ...prevState, enableSystemPage: true }));
      } else if (props.children.props.history.location.pathname === "/secureArchitecture") {
        setStatus(prevState => ({ ...prevState, enableSecureArchitecturePage: true }));
      } else if (props.children.props.history.location.pathname === "/bom") {
        setStatus(prevState => ({ ...prevState, enableBOMPage: true }));
      } else if (props.children.props.history.location.pathname === "/deploy") {
        setStatus(prevState => ({ ...prevState, enableDeployPage: true }));
      }
    }
  }, [props.children.props.history.location.pathname]);

  useEffect(()=>{
    getProjectDetailStatus();
  },[])
  const getProjectDetailStatus = () => {

   try {
     if (getProjectId()) {
       apiSession
         .getProjectStatus(getProjectId())
         .then((response) => {
           if (response) {
             setProjectStatusData(response);
           }
         })

         .catch((error) => {
           if (error.response !== undefined) {
             if (error.response.status === 412) {
               callSnackbar("error", `${error?.message}`);  
             }
           }
         });
     }
   } catch (error) {
     console.log(error);
   }

  };
  const navigateProject = () => {
    localStorage.setItem("selectedtab", "projects");
    localStorage.setItem("pname", null);
    document.getElementById("app2-sidemenu").toggle();
    let {
      children: {
        props: { history },
      },
    } = props;

    history.push({
      pathname: "/project",
    });
  };

  const navigateUser = () => {
    localStorage.setItem("selectedtab", "user");
    localStorage.setItem("pname", null);
    document.getElementById("app2-sidemenu").toggle();
    let {
      children: {
        props: { history },
      },
    } = props;

    history.push({
      pathname: "/project",
    });
  };

  const navigateLibrary = () => {
    localStorage.setItem("selectedtab", "asset");
    localStorage.setItem("pname", null);
    document.getElementById("app2-sidemenu").toggle();

    let {
      children: {
        props: { history },
      },
    } = props;

    history.push({
      pathname: "/project",
    });
  };

  const navigateGlobals = () => {
    localStorage.setItem("selectedtab", "globalparam");
    localStorage.setItem("pname", null);
    document.getElementById("app2-sidemenu").toggle();
    let {
      children: {
        props: { history },
      },
    } = props;

    history.push({
      pathname: "/project",
    });
  };

  const navigateAnalytics = () => {
    localStorage.setItem("selectedtab", "analytics");
    localStorage.setItem("pname", null);
    document.getElementById("app2-sidemenu").toggle();
    let {
      children: {
        props: { history },
      },
    } = props;

    history.push({
      pathname: "/project",
    });
  };

  const navigateContactUs = (e) => {
  };

  const redirectToProfile = () => {
    let {
      children: {
        props: { history },
      },
    } = props;

    history.push({
      pathname: "/userdetails",
    });
  };

  const onChangePassword = () =>
    setPopUpStatus(prevState => ({
      ...prevState,
      changePasswordPopUpStatus: true,
    }));

  const closeChangePasswordPopUp = () =>
    setPopUpStatus(prevState => ({
      ...prevState,
      changePasswordPopUpStatus: false,
    }));

  const onButtonLogout = () => {
    let {
      children: {
        props: { history },
      },
    } = props;
    localStorage.clear();
    apiSession
      .logout()
      .then(response => {
        history.push({
          pathname: "/logout",
        });
      })
      .catch(error => {
        history.push({
          pathname: "/logout",
        });
      });
    history.push({
      pathname: "/logout",
    });
  };

  const navigateToInformation = () => {
    let blockStatus = localStorage.getItem("assetEditStatus")?.split(",");
    if (blockStatus[1] === "true") {
      blockStatusDisplay(true, blockStatus[0]);
      localStorage.setItem("page", "information");
    } else if (
      status.enableProgramPage === true &&
      localStorage.getItem("programDataModified") === "true"
    ) {
      setPopUpStatus(prevState => ({
        ...prevState,
        modifiedDataAlertPopupStatus: true,
      }));
      localStorage.setItem("page", "information");
    } else {
      if (localStorage.getItem("processLoading") === "true") {
      } else {
        let {
          children: {
            props: { history },
          },
        } = props;
        history.push({
          pathname: "/information",
        });
      }
    }
  };

  const navigateToAsset = () => {
    if(getProjectId() != null){
      if (
        status.enableProgramPage === true &&
        localStorage.getItem("programDataModified") === "true"
      ) {
        setPopUpStatus(prevState => ({
          ...prevState,
          modifiedDataAlertPopupStatus: true,
        }));
        localStorage.setItem("page", "assets");
      } else {
        let {
          children: {
            props: { history },
          },
        } = props;
        history.push({
          pathname: "/assets",
        });
      }
    }else{
      displayAlert(shellLayoutConst.PROJECT_ASSET_ALERT);
    }
  };

  const navigateToProgram = () => {
    if(getProjectId() != null){
      if (localStorage.getItem("deploymentReady") === "true") {
        let blockStatus = localStorage.getItem("assetEditStatus").split(",");
        if (blockStatus[1] === "true") {
          blockStatusDisplay(true, blockStatus[0]);
          localStorage.setItem("page", "program");
        } else {
          let {
            children: {
              props: { history },
            },
          } = props;
          history.push({
            pathname: "/program",
          });
        }
      } else {
        displayAlert(shellLayoutConst.VALIDATE_DER_ALERT);
      }
    }else{
      displayAlert(shellLayoutConst.PROJECT_USE_CASE_ALERT);
    }
    
  };

  const navigateToSystem = () => {
    if(projectStatusData?.projectStatus === "COMPLETED"){
      let {
        children: {
          props: { history },
        },
      } = props;
      history.push({
        pathname: "/systems",
      });
    }else{
      

      if(getProjectId() != null ){
        if (localStorage.getItem("deploymentReady") === "true") {
          let blockStatus = localStorage.getItem("assetEditStatus").split(",");
          if (blockStatus[1] === "true") {
            blockStatusDisplay(true, blockStatus[0]);
            localStorage.setItem("page", "systems");
          } else if (
            status.enableProgramPage === true &&
            localStorage.getItem("programDataModified") === "true"
          ) {
            setPopUpStatus(prevState => ({
              ...prevState,
              modifiedDataAlertPopupStatus: true,
            }));
            localStorage.setItem("page", "systems");
          }else if( localStorage.getItem("optimizationModified") === "true" || localStorage.getItem("programPage") === "true"){
            displayAlert(shellLayoutConst.VALIDATE_USE_CASE_ALERT);
          }else {
            let {
              children: {
                props: { history },
              },
            } = props;
            history.push({
              pathname: "/systems",
            });
          }
        }else {
          displayAlert(shellLayoutConst.VALIDATE_DER_ALERT);
        }
      }else{
        displayAlert(shellLayoutConst.PROJECT_SYSTEM_ALERT);
      }
    }
    
    
  };
  const navigateToBOM = () => {
    if(projectStatusData?.projectStatus === "COMPLETED"){
      let {
        children: {
          props: { history },
        },
      } = props;
      history.push({
        pathname: "/bom",
      });
    }else{
    if(getProjectId() !=null ){
     if (localStorage.getItem("deploymentReady") === "true") {
        let blockStatus = localStorage.getItem("assetEditStatus").split(",");
        if (blockStatus[1] === "true") {
          blockStatusDisplay(true, blockStatus[0]);
          localStorage.setItem("page", "bom");
        } else if (
          status.enableProgramPage === true &&
          localStorage.getItem("programDataModified") === "true"
        ) {
          setPopUpStatus(prevState => ({
            ...prevState,
            modifiedDataAlertPopupStatus: true,
          }));
          localStorage.setItem("page", "bom");
        } else {
          if(localStorage.getItem("optimizationModified") === "true" || localStorage.getItem("programPage") === "true" ){
            displayAlert(shellLayoutConst.VALIDATE_USE_CASE_ALERT);
          }else if (localStorage.getItem("communicationValidated") === "true" ) {
            
              displayAlert(
                shellLayoutConst.VALIDATE_SYSTEMS_ALERT
              );
                    
          }else if( localStorage.getItem("timeSyncValidate") === "false"){
            displayAlert(shellLayoutConst.VALIDATE_TIMESYNC_ALERT );           
          }else{
            let {
              children: {
                props: { history },
              },
            } = props;
            history.push({
              pathname: "/bom",
            });
          }
          
        }
      } else {
        displayAlert(shellLayoutConst.VALIDATE_DER_ALERT);
      }
    }else{
      displayAlert(shellLayoutConst.PROJECT_BOM_ALERT);
    }
    }
  };
  
  const navigateToDeploy = () => {
    if(projectStatusData?.projectStatus === "COMPLETED"){
      
      let {
        children: {
          props: { history },
        },
      } = props;
      history.push({
        pathname: "/deploy",
      });
    }else{
      if(getProjectId() !=null){
         if (localStorage.getItem("deploymentReady") === "true"  ) {
          let blockStatus = localStorage.getItem("assetEditStatus").split(",");
          if (blockStatus[1] === "true") {
            blockStatusDisplay(true, blockStatus[0]);
            localStorage.setItem("page", "deploy");
          } else if (
            status.enableProgramPage === true &&
            localStorage.getItem("programDataModified") === "true"
          ) {
            setPopUpStatus(prevState => ({
              ...prevState,
              modifiedDataAlertPopupStatus: true,
            }));
            localStorage.setItem("page", "deploy");
          } else {
            if(localStorage.getItem("programPage") === "true" || localStorage.getItem("optimizationModified") === "true"){
              displayAlert(shellLayoutConst.VALIDATE_USE_CASE_ALERT);
            }
            else if (localStorage.getItem("communicationValidated") === "true" ) {
              displayAlert(
                shellLayoutConst.VALIDATE_SYSTEMS_ALERT
              );
    
            } else if (localStorage.getItem("timeSyncValidate") === "false") {
              displayAlert(
                 shellLayoutConst.VALIDATE_TIMESYNC_ALERT
              );
    
            } else {
              let {
                children: {
                  props: { history },
                },
              } = props;
              history.push({
                pathname: "/deploy",
              });
            }
          }
        } else {
          displayAlert(shellLayoutConst.VALIDATE_DER_ALERT);
        }
      }else{
        displayAlert(shellLayoutConst.PROJECT_DEPLOY_ALERT);
      }
    }
    
  };

  const resetEnable = () => {
    setStatus(prevState => ({
      ...prevState,
      enableAssetPage: false,
      enableProgramPage: false,
      enableSystemPage: false,
      enableBOMPage: false,
      enableDeployPage: false,
      enableSecureArchitecturePage: false,
    }));
  };

  const blockStatusDisplay = (status, message) => {
    setModifiedStatus(prevState => ({
      ...prevState,
      modifiedDataAlertAssetPopupStatus: status,
      modifiedDERName: message,
    }));
  };

  const allowToSaveModifiedDataAsset = () => {
    setModifiedStatus(prevState => ({
      ...prevState,
      modifiedDataAlertAssetPopupStatus: false,
    }));
  };

  const closeDataModifiedAlertPopupAsset = () => {
    setModifiedStatus(prevState => ({
      ...prevState,
      modifiedDataAlertAssetPopupStatus: false,
    }));
    localStorage.removeItem("derHistory");
    localStorage.removeItem("pvHistory");
    localStorage.removeItem("cbEvHistory");
    localStorage.removeItem("cbLoadHistory");
    localStorage.removeItem("cbAssetHistory");
    localStorage.removeItem("loadHistory");
    localStorage.removeItem("derStatus");
    localStorage.setItem("assetEditStatus", "");

    let {
      children: {
        props: { history },
      },
    } = props;
    let pathNameDynamic = localStorage.getItem("page");
    history.push({
      pathname: pathNameDynamic,
    });
  };

  const allowToDeleteModifiedData = () => {
    localStorage.setItem("toBeSavedAsDraft", true);
    setPopUpStatus(prevState => ({
      ...prevState,
      modifiedDataAlertPopupStatus: false,
    }));

    setTimeout(() => {
      saveDraftData();
    }, 200);
    let {
      children: {
        props: { history },
      },
    } = props;
    let pathNameDynamic = localStorage.getItem("page");
    history.push({
      pathname: pathNameDynamic,
    });
  };

  const saveDraftData = () => {
    if (UserScope.access.saveProgram === true) {
      let jsonData = localStorage.getItem("draftSave");
      if (jsonData !== null) {
        apiSession
          .saveDraftProgram(JSON.parse(jsonData))
          .then(response => {
            localStorage.setItem("draftSave", null);
          })
          .catch(error => {
            localStorage.setItem("draftSave", null);
          });
      }
    } else {
      localStorage.setItem("draftSave", null);
    }
  };

  const closeDataModifiedAlertPopup = () => {
    setPopUpStatus(prevState => ({
      ...prevState,
      modifiedDataAlertPopupStatus: false,
    }));
    let {
      children: {
        props: { history },
      },
    } = props;
    let pathNameDynamic = localStorage.getItem("page");
    history.push({
      pathname: pathNameDynamic,
    });
  };

  const displayAlert = async message => {


    await apiSession
      .getProject(getProjectId())
      .then((json) => {

        seClientType(json.clientType)
      })
      .catch(() => {
        // setLoading(false);
      });

    setAlertStatus(prevState => ({
      ...prevState,
      alertMessage: message,
      alertMessagePopupStatus: true,
    }));
  };

  const closeAlertPopup = () => {
    setAlertStatus(prevState => ({
      ...prevState,
      alertMessage: "",
      alertMessagePopupStatus: false,
    }));

    //*for communication validation

    if (
      alertStatus.alertMessage ===
      shellLayoutConst.VALIDATE_SYSTEMS_ALERT || alertStatus.alertMessage === shellLayoutConst.VALIDATE_TIMESYNC_ALERT ) {


      let path = clientType === "A2E_PROJECT" ? "/program" : "/systems"
      let {
        children: {
          props: { history },
        },
      } = props;
      history.push({
        pathname: path,
      });
    }
    else if(  alertStatus.alertMessage === shellLayoutConst.VALIDATE_USE_CASE_ALERT){
      let {
        children: {
          props: { history },
        },
      } = props;
      history.push({
        pathname:"/program",
      });
    }
  };

  const openGenerateReportConfiguration = () => {
    if (getProjectId() !==null) {
      setPopUpStatus(prevState => ({ ...prevState, openReportPopUp: true }));

      setReportStatus(prevState => ({
        ...prevState,
        selectAll: false,
        information: false,
        bom: false,
        deploy: false,
        sld: false,
        system: false,
        program: false,
      }));
    } else {
      displayAlert("Project is not created.");
    }
  };

  const closeOpenReportPopUp = () => {
    setPopUpStatus(prevState => ({ ...prevState, openReportPopUp: false }));
    setReportStatus(prevState => ({
      ...prevState,
      selectAll: false,
      information: false,
      bom: false,
      deploy: false,
      sld: false,
      system: false,
      program: false,
      enableSecureAC: false,
      showSeletOption: false,
    }));
    setStatus(prevState => ({ ...prevState, reportReadyStatus: false }));
  };

  const handleCheckBoxChange = e => {
    const { name, checked } = e.target;

    setReportStatus(prevState => ({
      ...prevState,
      [name]: checked,
    }));
  };
  const handleCheckBoxChangeAll = e => {
    const { checked } = e.target;
    setReportStatus(prevState => ({
      ...prevState,
      selectAll: checked,
      information: checked,
      bom: checked,
      deploy: checked,
      sld: checked,
      system: checked,
      program: checked,
    }));
  };

  const uploadReport = () => {
    if (
      reportStatus.information === true ||
      reportStatus.sld === true ||
      reportStatus.program === true ||
      reportStatus.bom === true ||
      reportStatus.system === true ||
      reportStatus.deploy === true ||
      reportStatus.secureAC === true
    ) {
      setStatus(prevState => ({ ...prevState, reportReadyStatus: true }));
      setPopUpStatus(prevState => ({
        ...prevState,
        loadingPopupStatus: true,
      }));
    } else {
      setReportStatus(prevState => ({
        ...prevState,
        showSeletOption: true,
      }));
    }
  };

  const uploadSLDStatus = data => {
    generateReport();
  };

  const generateReport = () => {
    if (
      reportStatus.information === true ||
      reportStatus.sld === true ||
      reportStatus.program === true ||
      reportStatus.bom === true ||
      reportStatus.system === true ||
      reportStatus.deploy === true
    ) {
      if (getProjectId()) {
        setPopUpStatus(prevState => ({
          ...prevState,
          loadingPopupStatus: true,
        }));
        apiSession
          .generateReport({
            projectId: getProjectId(),
            information: reportStatus.information,
            sld: reportStatus.sld,
            program: reportStatus.program,
            bom: reportStatus.bom,
            system: reportStatus.system,
            deploy: reportStatus.deploy,
          })
          .then(response => {
            setPopUpStatus(prevState => ({
              ...prevState,
              loadingPopupStatus: false,
            }));
            closeOpenReportPopUp();
            FileDownload(
              response,
              "report.docx",
              "application/vnd.openxmlformats-officedocument.wordprocessing"
            );
            callSnackbar("success", "Report Downloaded successfully");
            setStatus(prevState => ({
              ...prevState,
              reportReadyStatus: false,
            }));
            setReportStatus(prevState => ({
              ...prevState,
              showSeletOption: false,
            }));
          })
          .catch(error => {
            setPopUpStatus(prevState => ({
              ...prevState,
              loadingPopupStatus: false,
            }));
            setStatus(prevState => ({
              ...prevState,
              reportReadyStatus: false,
            }));
            closeOpenReportPopUp();
            callSnackbar("error", "Error in report download");
            setReportStatus(prevState => ({
              ...prevState,
              showSeletOption: false,
            }));
          });
      }
    }
  };
/* istanbul ignore next */ 
  const changePasswordConfirmation = (
    passwordCheck,
    newPassword,
    confirmPassword
  ) => {
    if (
      passwordCheck === true &&
      confirmPassword.toLowerCase() === newPassword.toLowerCase()
    ) {
      let jsonBody = {
        oldPassword: "",
        newPassword: newPassword,
      };
      apiSession
        .changePassword(jsonBody)
        .then(() => {
          setPopUpStatus(prevState => ({
            ...prevState,
            changePasswordPopUpStatus: false,
            successChangePassword: true,
          }));
        })
        .catch(error => {

          if (error.response !== undefined) {
            let { data } = error.response;
            callSnackbar("error", data.message);
            // if (status === 403) {
            //   callSnackbar("error", data.message);
            // } else if (status === 400) {
            //   callSnackbar("error", data.message);
            // } else if (status === 500) {
            //   callSnackbar("error", data.message);
            // } else {
            //   callSnackbar("error", data.message);
            // }

          } else {
            callSnackbar("error", configLabel.layout.NetworkError);
          }
        });
    } else {
      callSnackbar("error", configLabel.layout.passwordPolicyError);
    }
  };
/* istanbul ignore next */ 
  const redirectTologin = () => {
    let {
      children: {
        props: { history },
      },
    } = props;
    setPopUpStatus(prevState => ({
      ...prevState,
      successChangePassword: false,
    }));

    history.push({
      pathname: "/",
    });
  };
/* istanbul ignore next */ 

  const adminRoleClickHandler = () => {
    setPopUpStatus(prevState => ({
      ...prevState,
      adminRolePopStatus: true,
    }));
  };
 /* istanbul ignore next */ 
  const closeAddSubrole = () =>
    setPopUpStatus(prevState => ({
      ...prevState,
      adminRolePopStatus: false,
    }));
 /* istanbul ignore next */ 
  const addSubRole = subRole => {
    closeAddSubrole();
    setPopUpStatus(prevState => ({
      ...prevState,
      logoutPopUpStatus: true,
    }));
     
    apiSession.changeTempRole(subRole).then(() => {
      setTimeout(() => {
      
        setPopUpStatus(prevState => ({
          ...prevState,
          logoutPopUpStatus: false,
        }));
        onButtonLogout();
      }, 1500);
    });
  };

  const callSnackbar = (type, message) => {
    setSnackBar({ type, message });
  };

  let pname = localStorage.getItem("pname");
  let pType = localStorage.getItem("pType");

  return (
    <se-container position="absolute" direction="column">
      <div style={{ position: "sticky", top: 0, zIndex: 109 }}>
        <se-header app-title="Microgrid Build">
          <se-sidemenu
            id="app2-sidemenu"
            style={
              UserScope.name !== "" ? { height: "100vh" } : { display: "none" }
            }
          >
            <se-sidemenu-item
              item="Projects"
              ref={projectNavigationRef}
            ></se-sidemenu-item>

            {UserScope.access.getUsers === true && (
              <se-sidemenu-item
                item="User Management"
                ref={userNavigationRef}
              ></se-sidemenu-item>
            )}

            {UserScope.access.saveAssets === true && (
              <se-sidemenu-item
                item="Library Management"
                ref={libraryNavigationRef}
              ></se-sidemenu-item>
            )}

            {UserScope.access.globalParams === true && (
              <se-sidemenu-item
                item="Global Parameters"
                ref={globalNavigationRef}
              ></se-sidemenu-item>
            )}

            {UserScope.access.audit === true && (
              <se-sidemenu-item
                item="Analytics"
                ref={analyticsNavigationRef}
              >AAA</se-sidemenu-item>
            )}
            <se-sidemenu-item item="Legal">
              <div className="col-md-12">
                <div className="border mt-2 p-2 legal-border">
                  <h4>Schneider Data Privacy Policy</h4>
                  <a
                    href={privacyPolicy}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Legal Policy"
                  >
                    <h5>{privacyPolicy}</h5>
                  </a>
                </div>
              </div>
            </se-sidemenu-item>
            <se-sidemenu-item item="Contact" ref={contactNavigationRef}>
              <Contact />
            </se-sidemenu-item>
          </se-sidemenu>

          <div className="internal__Rnd">{process.env.REACT_APP_R_D}</div>

          <div
            slot="end"
            style={UserScope.name !== "" ? {} : { display: "none" }}
          >
            <se-dropdown alignment="right">
              <se-button
                slot="trigger"
                option="inherit"
                icon="user_standard"
                icon-color="primary"
              >
                {UserScope.name}
              </se-button>
              <se-list option="dropdown">
                <se-list-item
                  item={"Role : " + UserScope.role}
                  onClick={
                    UserScope.access.changeTempRole
                      ? adminRoleClickHandler
                      : undefined
                  }
                ></se-list-item>
                <se-divider></se-divider>
                <se-list-item
                  onClick={redirectToProfile}
                  item="Profile"
                ></se-list-item>
                <se-divider></se-divider>
                <se-list-item
                  onClick={onChangePassword}
                  item="Change password"
                ></se-list-item>
                <se-divider></se-divider>

                <se-list-item
                  onClick={onButtonLogout}
                  item="Logout"
                ></se-list-item>
              </se-list>
            </se-dropdown>
          </div>
        </se-header>

        <se-tabbar color="primary">
          <nav style={status.navStatus ? {} : { display: "none" }}>
            <span
              className={`ml-3 ${
                window.location.pathname === "/information" ? "active" : ""
              }`}
              onClick={navigateToInformation}
            >
              <span className="se-subHeader-16px text-capitalize se-custom-nav-header">
                <span className="ml-1"> INFORMATION </span>
              </span>
            </span>

            <span className="mr-0 no-events">
              {" "}
              Design{" "}
              <se-icon className="icon_button icon_28 mr-1">
                arrow2_right
              </se-icon>
            </span>

            {status.enableAssetPage === true ? (
              <NavLink to="/assets" activeClassName="active" className="ml-0">
                <span className="se-subHeader text-capitalize">
                  <se-icon className="icon_button icon_20 " size="nano">
                    circuit_breaker
                  </se-icon>
                  <span className="ml-1"> Configure DERs </span>
                </span>
              </NavLink>
            ) : (
              <span className="ml-0" onClick={navigateToAsset}>
                <span className="se-subHeader text-capitalize">
                  <se-icon className="icon_button icon_20 " size="nano">
                    circuit_breaker
                  </se-icon>
                  <span className="ml-1"> Configure DERs </span>
                </span>
              </span>
            )}

            {status.enableProgramPage === true ? (
              <NavLink to="/program" activeClassName="active" className="ml-0">
                <span className="se-subHeader text-capitalize">
                  <se-icon className="icon_button icon_20 " size="nano">
                    sensors
                  </se-icon>
                  <span className="ml-1"> Configure Use Cases </span>
                </span>
              </NavLink>
            ) : (
              <span className="ml-0" onClick={navigateToProgram}>
                <span className="se-subHeader text-capitalize">
                  <se-icon className="icon_button icon_20 " size="nano">
                    sensors
                  </se-icon>
                  <span className="ml-1"> Configure Use Cases </span>
                </span>
              </span>
            )}

            {status.enableSystemPage === true ? (
              <NavLink to="/systems" activeClassName="active" className="ml-0">
                <span className="se-subHeader text-capitalize">
                  <se-icon className="icon_button icon_20 " size="nano">
                    connection_ethernet
                  </se-icon>
                  <span className="ml-1"> Systems </span>
                </span>
              </NavLink>
            ) : (
              <span className="ml-0" onClick={navigateToSystem}>
                <span className="se-subHeader text-capitalize">
                  <se-icon className="icon_button icon_20 " size="nano">
                    connection_ethernet
                  </se-icon>
                  <span className="ml-1"> Systems </span>
                </span>
              </span>
            )}
            {/* {status.enableSecureArchitecturePage === true ? (
            <NavLink
              to="/secureArchitecture"
              activeClassName="active"
              className="ml-0"
            >
              <span className="se-subHeader text-capitalize">

                Secure Architecture
              </span>

            </NavLink>
          ) : (
            <span className="ml-0" onClick={navigateToSecureArchitecture}>
              <span className="se-subHeader text-capitalize">
                Secure Architecture
              </span>
            </span>
          )} */}

            {status.enableBOMPage === true ? (
              <NavLink
                to="/bom"
                exact={true}
                activeClassName="active"
                className="ml-0"
              >
                B.O.M
              </NavLink>
            ) : (
              <span className="ml-0" onClick={navigateToBOM}>
                <span className="se-subHeader-14px  se-custom-nav-header">
                  <span className="ml-1"> B.O.M </span>
                </span>
              </span>
            )}

            {status.enableDeployPage === true ? (
              <NavLink
                to="/deploy"
                exact={true}
                activeClassName="active"
                className="ml-0"
              >
                DEPLOY
              </NavLink>
            ) : (
              <span className="ml-0" onClick={navigateToDeploy}>
                <span className="se-subHeader-14px text-capitalize se-custom-nav-header">
                  <span className="ml-1"> DEPLOY </span>
                </span>
              </span>
            )}

            <span className="ml-0" onClick={openGenerateReportConfiguration}>
              <span className="se-subHeader-14px text-capitalize se-custom-nav-header">
                <span className="ml-1"> GENERATE REPORT </span>
              </span>
            </span>
          </nav>
          <nav
            slot="edge"
            overflow="compact"
            style={
              pname === "null" || pname === null
                ? { display: "none" }
                : status.navStatus
                ? {}
                : { display: "none" }
          }
          className=" no-events se-subHeader-14px se-green-background se-custom-nav-header "
        >
          <div className="truncate-projectName-header d-flex flex-column" title={pname}>
           { projectTypeName?.projectName && <span> Project Name : {projectTypeName?.projectName}</span>}
           { projectTypeName?.projectType && <span><span className="mr-2">Project Type</span><span>: {projectTypeName?.projectType}</span></span>}
          </div>

        </nav>


      </se-tabbar>
      </div>
      {/* <div className="wrap" > 
        
       { !loading?<div style={status.navStatus ? {} : { display: "none" }}>
       <div className="d-flex justify-content-between" ><se-skeleton width="500px" height="40px" ></se-skeleton><se-skeleton width="500px" height="40px"></se-skeleton><se-skeleton width="500px" height="40px"></se-skeleton></div>

       </div>
       :<div className="row wrap" style={status.navStatus ? {} : { display: "none" }}>
          <div className="col-lg-4 createdOn">
          <ProgressBar createdOn={projectStatusData?.createdOn} isShow="createdOn"/>
          </div>
          <div className="col-lg-4">
            
               {projectStatusData?.projectStatus ==="IN_PROGRESS" && <ProgressBar data={projectStatusData?.projectStatus ==="IN_PROGRESS"? projectStatusData:{}}/>
            }
            
            { 
              projectStatusData?.projectStatus ==="COMPLETED" && <ProgressBar statusName="In progress" data={ projectStatusData}/>
           
           }
          </div>
          <div className="col-lg-4">
          <ProgressBar statusName="Completed" data={projectStatusData?.projectStatus === "COMPLETED"? projectStatusData:{}}/>
          </div>
        </div>}
       </div>       */}
      {props.children}

      {popUpStatus.changePasswordPopUpStatus && (
        <ChangePassword
          closeChangePasswordPopUp={closeChangePasswordPopUp}
          changePasswordConfirmation={changePasswordConfirmation}
        />
      )}

      {popUpStatus.openReportPopUp && (
        <GenerateReport
          status={status}
          reportStatus={reportStatus}
          closeOpenReportPopUp={closeOpenReportPopUp}
          handleCheckBoxChange={handleCheckBoxChange}
          handleCheckBoxChangeAll={handleCheckBoxChangeAll}
          uploadReport={uploadReport}
          uploadSLDStatus={uploadSLDStatus}
        />
      )}

      <se-dialog
        id="AlertDialog"
        open={modifiedStatus.modifiedDataAlertAssetPopupStatus}
        can-backdrop="false"
      >
        <se-dialog-header>Alert</se-dialog-header>
        <se-dialog-content
          icon="information_circle"
          color="primary"
          className="se-header-font-size-16px se-life-green"
        >
          Modified data is not saved in {modifiedStatus.modifiedDERName}. Do you
          want to save ? if No, Modified data will be lost.
        </se-dialog-content>
        <se-dialog-footer>
          <se-button onClick={allowToSaveModifiedDataAsset}>Yes</se-button>
          <se-button onClick={closeDataModifiedAlertPopupAsset}>No</se-button>
        </se-dialog-footer>
      </se-dialog>

      <se-dialog
        id="AlertDialog"
        open={popUpStatus.modifiedDataAlertPopupStatus}
        can-backdrop="false"
      >
        <se-dialog-header>Alert</se-dialog-header>
        <se-dialog-content
          icon="information_circle"
          color="primary"
          className="se-header-font-size-16px se-life-green"
        >
          Modified data is not saved. Do you want to save data? if yes, Modified
          data will be saved as draft.
        </se-dialog-content>
        <se-dialog-footer>
          <se-button onClick={allowToDeleteModifiedData}>Yes</se-button>
          <se-button onClick={closeDataModifiedAlertPopup}>No</se-button>
        </se-dialog-footer>
      </se-dialog>

      <se-dialog
        id="AlertDialog"
        open={alertStatus.alertMessagePopupStatus}
        can-backdrop="false"
      >
        <se-dialog-header>Alert</se-dialog-header>
        <se-dialog-content
          icon="information_circle"
          color="primary"
          className="se-header-font-size-16px se-life-green"
        >
          {alertStatus.alertMessage}
        </se-dialog-content>
        <se-dialog-footer>
          <se-button onClick={closeAlertPopup}>OK</se-button>
        </se-dialog-footer>
      </se-dialog>
      <se-dialog
        id="AlertDialog"
        open={popUpStatus.loadingPopupStatus}
        can-backdrop="false"
      >
        <se-dialog-content
          color="primary"
          className="se-header-font-size-16px se-life-green"
        >
          Please Wait Report is generating...
        </se-dialog-content>
      </se-dialog>

      <se-dialog
        id="AlertDialog"
        open={popUpStatus.logoutPopUpStatus}
        can-backdrop="false"
      >
        <se-dialog-content
          color="primary"
          className="se-header-font-size-16px se-life-green"
        >
          You will be logged out of the Application. Please Re-login.
        </se-dialog-content>
      </se-dialog>

      <se-dialog
        id="dialog-asset-select-alert"
        size="small"
        can-backdrop="false"
        open={popUpStatus.successChangePassword}
      >
        <se-dialog-content icon="information_stroke" icon-color="primary">
          Password changed Successfully, Please login with new password.
        </se-dialog-content>
        <se-dialog-footer>
          <se-button onClick={redirectTologin}>OK</se-button>
        </se-dialog-footer>
      </se-dialog>

      {popUpStatus.adminRolePopStatus && (
        <AdminRoles
          currentRole={UserScope.role}
          closeAddSubrole={closeAddSubrole}
          addSubRole={addSubRole}
        />
      )}

      <se-snackbar
        id="snackbar-shell"
        type={snackBar.snackbarType}
        icon="information_stroke"
        message={snackBar.snackbarMessage}
        duration="3000"
      ></se-snackbar>
    </se-container>
  );
};

export default withRouter(ShellLayout);
