/* istanbul ignore file */

import React, { useState, useEffect, useCallback, useRef } from "react";
import _ from "lodash";

import userScope from "./CustomHooks/userScope";
import Snackbar from "./SE-Component/Snackbar";
import PlcProtection from "./GlobalParamPages/PlcProtection";
import PlcPackage from "./GlobalParamPages/PlcPackage";
import A2EPackage from "./GlobalParamPages/A2EPackage";
import HmiPackage from "./GlobalParamPages/HmiPackage";
import PlcAndHmiPackage from "./GlobalParamPages/PlcAndHmiPackage"
import News from "./GlobalParamPages/News";
import ContactEmail from "./GlobalParamPages/ContactEmail";
import DcTemplate from "./GlobalParamPages/DcTemplate";

import APISESSION from "../api/ApiSession";
import UploadPages from "./GlobalParamPages/UploadPages";
const apiSession = new APISESSION();
const hexaReg = /^[a-fA-F0-9]+$/;

const GlobalParametersHook = () => {
  const UserScope  = userScope();
  const [loading, setLoading] = useState(true);
  const [selectedFilePLC, setSelectedFilePLC] = useState(null);
  const [selectedFilePLCA2E, setSelectedFilePLCA2E] = useState(null);
  const [selectedFileHMI, setSelectedFileHMI] = useState(null);
  const [protectionKeyList, setProtectionKeyList] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [allRegions, setAllRegions] = useState([]);
  const [templatesList, setTemplatesList] = useState([]);
  const [keyIds, setKeyIds] = useState({});
  const [tags, setTags] = useState({
    plcTag: "",
    hmiTag: "",
    plcTagA2E: "",
    plcTagNew: "",
    hmiTagNew: "",
    plcTagA2ENew: "",
  });
  const [plcData, setPlcData] = useState({});
  const [plcDataA2E, setPlcDataA2E] = useState({});
  const [hmiData, setHmiData] = useState({});
  const [fileVersion, setFileVersion] = useState({
    plcFileVersion: "",
    plcFileVersionA2E: "",
    hmiFileVersion: "",
  });

  const [snackBar, setSnackBar] = useState({ type: "", message: "" });

  const [plcEditModal, setPlcEditModal] = useState(false);
  const [plcA2EEditModal, setPlcA2EEditModal] = useState(false);
  const [hmiEditModal, setHmiEditModal] = useState(false);
 
  const [projectList, setProjectList] = useState([]);
  const snackBarRef = useRef(null);

  useEffect(() => {
    getProjectType();
    listKeys();
    getNews();
    getContactList();
    getDcTemplates();
    getAllDocuments();

  }, []);
  const getProjectType = () => {
   try {
    apiSession
    .getProjectTypes()
    .then((data) =>{
      const jsonCopy = Array.from(data)
      jsonCopy.map(json=>{
        json["Data as a Service (DaaS)"].forEach(data => {
          if (data.hasOwnProperty("projectTypeValue")) {
            setProjectList(prevList => prevList.concat(data.projectTypeValue));
          }
        });
        json["Microgrid as a Service (MGaaS)"].forEach(data => {
          if (data.hasOwnProperty("projectTypeValue")) {
            setProjectList(prevList => prevList.concat(data.projectTypeValue));
          }
        });
      })
    })
   } catch (error) {
    console.log(error)
   }
  }
  const listKeys = () => {
    setLoading(true);
   try {
    apiSession
    .listglobalParams()
    .then((response) => {
      setProtectionKeyList(response);
      response.body.forEach((data) => {
        setKeyIds((prevState) => ({ ...prevState, [data.id]: false }));

        if (data.attribute === "plcVersion") {
          setPlcData(data);
          setFileVersion((prevState) => ({
            ...prevState,
            plcFileVersion: data.value,
          }));
          setTags((prevState) => ({ ...prevState, plcTagNew: data.tag }));
        }
        if (data.attribute === "hmiVersion") {
          setHmiData(data);
          setFileVersion((prevState) => ({
            ...prevState,
            hmiFileVersion: data.value,
          }));
          setTags((prevState) => ({ ...prevState, hmiTagNew: data.tag }));
        }
        if (data.attribute === "a2ePlcVersion") {
          setPlcDataA2E(data);
          setFileVersion((prevState) => ({
            ...prevState,
            plcFileVersionA2E: data.value,
          }));
          setTags((prevState) => ({ ...prevState, plcTagA2ENew: data.tag }));
        }
      });
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
   } catch (error) {
    console.log(error)
   }
  };

  const getNews = () => {
    setLoading(true);
      try {
        apiSession
        .getNews()
        .then((response) => {
          setNewsList(response);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
      } catch (error) {
        console.log(error)
      }
  };
  const getAllDocuments = () => {
    setLoading(true);
    try {
      
    apiSession
    .listAllDocuments()
    .then((response) => {
      setDocumentList(response);
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
    } catch (error) {
      console.log(error)
    }
  };

  const getContactList = () => {
    setLoading(true);
    try {
      apiSession
      .getContacts()
      .then((response) => {
        setContactList(response.contacts);
        setAllRegions(response.regions);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    } catch (error) {
      console.log(error)
    }
     };

  const getDcTemplates = () => {
    setLoading(true);
  try {
    apiSession
    .getTemplate()
    .then((json) => {
      setTemplatesList(json);
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
  } catch (error) {
    console.log(error)
  }
  };

  const enableTextBox = (value) => {
    protectionKeyList.forEach((data) => {
      setKeyIds((prevState) => ({ ...prevState, [data.id]: false }));
    });
    setKeyIds((prevState) => ({ ...prevState, [value]: true }));
  };

  const disableTextBox = (value) =>
    setKeyIds((prevState) => ({ ...prevState, [value]: false }));

  const handleChange = (e, index) => {
    const { value } = e.target;
    const newKeyList = [...protectionKeyList];
    if (value !== "") {
      if (hexaReg.test(value)) {
        newKeyList[index].value = value;
      }
    } else {
      newKeyList[index].value = "";
    }
    setProtectionKeyList(newKeyList);
  };

  const savePlcProtectionKey = (index) => {
    if (
      protectionKeyList[index].value.length &&
      protectionKeyList[index].value.length <= 8 &&
      hexaReg.test(protectionKeyList[index].value)
    ) {
      saveGlobalParameter(protectionKeyList[index]);
    } else {
      callSnackbar("error", "Key should be in Hexa decimal, Maximum 8 digits");
    }
  };

  const saveGlobalParameter = (data) => {
    setLoading(true);

    apiSession
      .updateGlobalParams(data)
      .then(() => {
        setKeyIds((prevState) => ({ ...prevState, [data.id]: false }));
        setLoading(false);
        callSnackbar("success", "Updated successfully");
      })
      .catch(() => {
        setLoading(false);
        callSnackbar("error", "Error in updation");
      });
  };

  const fileChangedHandlerPLC = (event) => {
    const { files } = event.target;
    if (files.length) {
      if (files[0].size > 104857600) {
        callSnackbar("error", "PLC zip file should be less than 100MB");
        event.target.value = null;
        setSelectedFilePLC(null);
      } else if (files[0].type !== "application/x-zip-compressed") {
        callSnackbar("error", "Invalid file format, Please select .zip file");
        event.target.value = null;
        setSelectedFilePLC(null);
      } else {
        setSelectedFilePLC(files[0]);
        setTags((prevState) => ({
          ...prevState,
          plcTag: files[0].name,
        }));
        let reader = new FileReader();
        reader.onloadend = () => { };
        reader.readAsDataURL(files[0]);
      }
    }
  };

  const fileChangedHandlerPLCA2E = (event) => {
    const { files } = event.target;
    if (files.length) {
      if (files[0].size > 104857600) {
        callSnackbar("error", "PLC zip file should be less than 100MB");
        event.target.value = null;
        setSelectedFilePLCA2E(null);
      } else if (files[0].type !== "application/x-zip-compressed") {
        callSnackbar("error", "Invalid file format, Please select .zip file");
        event.target.value = null;
        setSelectedFilePLCA2E(null);
      } else {
        setSelectedFilePLCA2E(files[0]);
        setTags((prevState) => ({
          ...prevState,
          plcTagA2E: files[0].name,
        }));
        let reader = new FileReader();
        reader.onloadend = () => { };
        reader.readAsDataURL(files[0]);
      }
    }
  };

  const uploadPlcFile = (plcData) => {
    setLoading(true);
    if (selectedFilePLC) {
      let fd = new FormData();
      fd.append("file", selectedFilePLC);
      fd.append("type", "PLC");
      fd.append("version", plcData.version);
      fd.append("projectType", plcData.projectType);
      apiSession
        .uploadPLCPackage(fd)
        .then(() => {
          let data = _.cloneDeep(plcData);
          data.tag = tags.plcTag;
          saveHMIPLCKey(data);
          callSnackbar("success", "PLC package uploaded successfully");
          setLoading(false);
          closePlcEditModal();
        })
        .catch((error) => {
          if (error.response !== undefined) {
            if (error.response.status === 400) {
              callSnackbar("error", "Invalid package");
            } else {
              callSnackbar("error", "Error in uploading package");
            }
          } else {
            callSnackbar("error", "Error in uploading package");
          }

          setLoading(false);
          setSelectedFilePLC(null);
        });
    } else {
      setLoading(false);
    }
  };

  const uploadPlcFileA2E = (plcDataA2E) => {
    setLoading(true);
    if (selectedFilePLCA2E) {
      let fd = new FormData();
      fd.append("file", selectedFilePLCA2E);
      fd.append("type", "PLC_A2E");
      fd.append("version", plcDataA2E.version);
      fd.append("projectType", plcDataA2E.projectType);
      apiSession
        .uploadPLCPackage(fd)
        .then(() => {
          let data = _.cloneDeep(plcDataA2E);
          data.tag = tags.plcTagA2E;
          saveHMIPLCKey(data);
          callSnackbar("success", "A2E PLC package uploaded successfully");
          setLoading(false);
          closePlcA2EEditModal();
        })
        .catch((error) => {
          if (error.response !== undefined) {
            if (error.response.status === 400) {
              callSnackbar("error", "Invalid package");
            } else {
              callSnackbar("error", "Error in uploading package");
            }
          } else {
            callSnackbar("error", "Error in uploading package");
          }

          setLoading(false);
          setSelectedFilePLC(null);
        });
    } else {
      setLoading(false);
    }
  };

  const updateFileVersion = (e) => {
    const { name, value } = e.target;
    setFileVersion((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const versionUpdatePLC = () => {
    setLoading(true);
    let data = _.cloneDeep(plcData);
    data.value = fileVersion.plcFileVersion;
    saveHMIPLCKey(data);
  };
  const versionUpdatePLCA2E = () => {
    setLoading(true);
    let data = _.cloneDeep(plcDataA2E);
    data.value = fileVersion.plcFileVersionA2E;
    saveHMIPLCKey(data);
  };

  const fileChangedHandlerHMI = (event) => {
    const { files } = event.target;
    if (files.length) {
      if (files[0].size > 20000000) {
        callSnackbar("error", "PLC zip file should be less than 20MB");
        event.target.value = null;
      } else {
        setSelectedFileHMI(files[0]);
        setTags((prevState) => ({
          ...prevState,
          hmiTag: files[0].name,
        }));
        let reader = new FileReader();
        reader.onloadend = () => { };
        reader.readAsDataURL(files[0]);
      }
    }
  };

  const uploadHmiFile = (hmiData) => {
    setLoading(true);
    if (selectedFileHMI) {
      let fd = new FormData();
      fd.append("file", selectedFileHMI);
      fd.append("type", "HMI");
      fd.append("version", hmiData.version);
      fd.append("projectType", hmiData.projectType);
      apiSession
        .uploadHMIPackage(fd)
        .then(() => {
          let data = _.cloneDeep(hmiData);
          data.tag = tags.hmiTag;
          saveHMIPLCKey(data);
          callSnackbar("success", "HMI package uploaded successfully");
          setLoading(false);
          setSelectedFileHMI(null);
          closeHmiEditModal();
        })
        .catch((error) => {
          if (error.response !== undefined) {
            if (error.response.status === 400) {
              callSnackbar("error", "Invalid package");
            } else {
              callSnackbar("error", "Error in uploading package");
            }
          } else {
            callSnackbar("error", "Error in uploading package");
          }
          setLoading(false);
          setSelectedFileHMI(null);
        });
    } else {
      setLoading(false);
    }
  };

  const versionUpdateHMI = () => {
    let data = _.cloneDeep(hmiData);
    data.value = fileVersion.hmiFileVersion;
    saveHMIPLCKey(data);
  };

  const saveHMIPLCKey = (data) => {
    setLoading(true);
    apiSession
      .updateGlobalParams(data)
      .then(() => {
        listKeys();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const newAddNews = (newsDetails) => {
    setLoading(true);
    apiSession
      .updateNews({
        subject: newsDetails.newNewsSubject,
        body: newsDetails.newNewsBody,
        link: newsDetails.newNewsLink,
      })
      .then(() => {
        setLoading(false);
        getNews();
        callSnackbar("success", "News Added Successfully");
      })
      .catch(() => {
        setLoading(false);
        callSnackbar("error", "Error in News Add");
      });
  };

  const updateNews = (newsDetails) => {
    setLoading(true);
    apiSession
      .updateNews({
        id: newsDetails.newsId,
        subject: newsDetails.newsSubject,
        body: newsDetails.newsBody,
        link: newsDetails.newsLink,
      })
      .then(() => {
        setLoading(false);
        getNews();
        callSnackbar("success", "News updated successfully");
      })
      .catch(() => {
        setLoading(false);
        callSnackbar("error", "Error in news update");
      });
  };

  const deleteNews = (newsId) => {
    setLoading(true);
    apiSession
      .deleteNews(newsId)
      .then(() => {
        callSnackbar("success", "Deleted Successfully");
        getNews();
        setLoading(false);
      })
      .catch(() => {
        callSnackbar("error", "Error in deleteion");
        setLoading(false);
      });
  };

  const newAddContact = (contactDetails) => {
    setLoading(true);
    apiSession
      .updateContact({
        email: contactDetails.newContactEmail,
        region: contactDetails.newEmailRegion,
      })
      .then(() => {
        getContactList();
        callSnackbar("success", "Contact Email Added Successfully");
        localStorage.setItem("callGetRegion", true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        callSnackbar("error", "Error in Contact Email Add");
      });
  };

  const updateContact = (contactDetails, region) => {
    setLoading(true);
    apiSession
      .updateContact({
        id: contactDetails.contactId,
        email: contactDetails.contactEmail,
        region:
          contactDetails.editEmailRegion === ""
            ? region
            : contactDetails.editEmailRegion,
      })
      .then(() => {
        getContactList();
        callSnackbar("success", "Contact Email updated successfully");
        localStorage.setItem("callGetRegion", true);
        setLoading(false);
      })
      .catch(() => {
        callSnackbar("error", "Error in Contact Email update");
        setLoading(false);
      });
  };

  const deleteContactFromList = (contactId) => {
    setLoading(true);
    apiSession
      .deleteContact(contactId)
      .then(() => {
        callSnackbar("success", "Deleted Successfully");
        getContactList();
        localStorage.setItem("callGetRegion", true);
        setLoading(false);
      })
      .catch(() => {
        callSnackbar("error", "Error in deleteion");
        setLoading(false);
      });
  };

  const saveTemplate = (demandCharge) => {
    setLoading(true);

    apiSession
      .createTemplate(demandCharge)
      .then(() => {
        getDcTemplates();
        setLoading(false);
        callSnackbar("success", `Template created successfully`);
      })
      .catch(() => {
        setLoading(false);
        callSnackbar("error", `Error in saving the template`);
      });
  };

  const saveEditedTemplate = (demandCharge) => {
    setLoading(true);

    apiSession
      .updateTemplate(demandCharge)
      .then(() => {
        getDcTemplates();
        setLoading(false);
        callSnackbar("success", `Template Updated successfully`);
      })
      .catch(() => {
        setLoading(false);
        callSnackbar("error", `Error in saving the template`);
      });
  };

  const deleteTemplateFromList = (templateId) => {
    setLoading(true);
    apiSession
      .deleteTemplate(templateId)
      .then(() => {
        callSnackbar("success", "Deleted Successfully");
        getDcTemplates();
        setLoading(false);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 404) {
            callSnackbar("error", "Template not found");
          }
        } else {
          callSnackbar("error", "Error in deleteion");
        }
        setLoading(false);
      });
  };

  const callSnackbar = useCallback((type, message) => {
    try {
      setSnackBar({ type, message });
      snackBarRef.current.open = true
    } catch (error) {
      console.log("error in snack bar")
    }
  }, []);

  const openPlcEditModal = () => {
    setPlcEditModal(true);
  };

  const closePlcEditModal = () => {
    setPlcEditModal(false);
    setSelectedFilePLC(null);
  };

  const openPlcA2EEditModal = () => {
    setPlcA2EEditModal(true);
  };

  const closePlcA2EEditModal = () => {
    setSelectedFilePLCA2E(null);
    setPlcA2EEditModal(false);
  };

  const openHmiEditModal = () => {
    setHmiEditModal(true);
  };

  const closeHmiEditModal = () => {
    setSelectedFileHMI(null);
    setHmiEditModal(false);
  };
  return (
    <div className={loading ? "se-block-disabled" : ""}>
      <div className={loading ? "loader__center" : ""}>
        <se-loading loading={loading}></se-loading>
      </div>
      <div className="mt-2 col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background  custom-container p-0">
        <PlcProtection
          UserScope={UserScope}
          protectionKeyList={protectionKeyList}
          keyIds={keyIds}
          enableTextBox={enableTextBox}
          disableTextBox={disableTextBox}
          handleChange={handleChange}
          savePlcProtectionKey={savePlcProtectionKey}
        />
       {/* { false && <PlcPackage
          projectList={projectList}
          UserScope={UserScope}
          plcTagNew={tags.plcTagNew}
          plcTagNewA2E={tags.plcTagA2ENew}
          protectionKeyList={protectionKeyList}
          selectedFilePLC={selectedFilePLC}
          selectedFilePLCA2E={selectedFilePLCA2E}
          loading={loading}
          fileVersion={fileVersion}
          fileChangedHandlerPLC={fileChangedHandlerPLC}
          fileChangedHandlerPLCA2E={fileChangedHandlerPLCA2E}
          versionUpdatePLCA2E
          uploadPlcFileA2E={uploadPlcFileA2E}
          uploadPlcFile={uploadPlcFile}
          updatePLCVersion={updateFileVersion}
          versionUpdatePLC={versionUpdatePLC}
          openEditModal={plcEditModal}
          openEditModalA2E={plcA2EEditModal}
          openPlcEditModal={openPlcEditModal}
          closePlcEditModal={closePlcEditModal}
          openPlcA2EEditModal={openPlcA2EEditModal}
          closePlcA2EEditModal={closePlcA2EEditModal}
        />} */}
        {/* {false && <A2EPackage
          projectList={projectList}
          UserScope={UserScope}
          plcTagNew={tags.plcTagA2ENew}
          selectedFilePLCA2E={selectedFilePLCA2E}
          loading={loading}
          fileVersion={fileVersion}
          protectionKeyList={protectionKeyList}
          fileChangedHandlerPLCA2E={fileChangedHandlerPLCA2E}
          uploadPlcFileA2E={uploadPlcFileA2E}
          versionUpdatePLCA2E
          updatePLCVersion={updateFileVersion}
          versionUpdatePLC={versionUpdatePLCA2E}
          openEditModal={plcA2EEditModal}
          openPlcA2EEditModal={openPlcA2EEditModal}
          closePlcA2EEditModal={closePlcA2EEditModal}
        />} */}


        <PlcAndHmiPackage

          projectList={projectList}
          UserScope={UserScope}
          hmiTagNew={tags.hmiTagNew}
          plcTagNew={tags.plcTagNew}
          plcTagNewA2E={tags.plcTagA2ENew}
          protectionKeyList={protectionKeyList}
          selectedFileHMI={selectedFileHMI}
          selectedFilePLC={selectedFilePLC}
          selectedFilePLCA2E={selectedFilePLCA2E}
          loading={loading}
          fileVersion={fileVersion}
          fileChangedHandlerHMI={fileChangedHandlerHMI}
          fileChangedHandlerPLC={fileChangedHandlerPLC}
          fileChangedHandlerPLCA2E={fileChangedHandlerPLCA2E}
          versionUpdatePLCA2E
          uploadPlcFileA2E={uploadPlcFileA2E}
          uploadHmiFile={uploadHmiFile}
          uploadPlcFile={uploadPlcFile}
          updateHmiVersion={updateFileVersion}
          versionUpdateHMI={versionUpdateHMI}
          updatePLCVersion={updateFileVersion}
          versionUpdatePLC={versionUpdatePLC}
          openEditModalPlc={plcEditModal}
          openEditModalHmi={hmiEditModal}
          openEditModalA2E={plcA2EEditModal}
          openHmiEditModal={openHmiEditModal}
          openPlcEditModal={openPlcEditModal}
          closePlcEditModal={closePlcEditModal}
          closeHmiEditModal={closeHmiEditModal}
          openPlcA2EEditModal={openPlcA2EEditModal}
          closePlcA2EEditModal={closePlcA2EEditModal}
        />
        <UploadPages

          projectList={projectList}
          UserScope={UserScope}
          hmiTagNew={tags.hmiTagNew}
          plcTagNew={tags.plcTagNew}
          plcTagNewA2E={tags.plcTagA2ENew}
          documentList={documentList}
          selectedFileHMI={selectedFileHMI}
          selectedFilePLC={selectedFilePLC}
          selectedFilePLCA2E={selectedFilePLCA2E}
          loading={loading}
          fileVersion={fileVersion}
          fileChangedHandlerHMI={fileChangedHandlerHMI}
          fileChangedHandlerPLC={fileChangedHandlerPLC}
          fileChangedHandlerPLCA2E={fileChangedHandlerPLCA2E}
          versionUpdatePLCA2E
          uploadPlcFileA2E={uploadPlcFileA2E}
          uploadHmiFile={uploadHmiFile}
          uploadPlcFile={uploadPlcFile}
          updateHmiVersion={updateFileVersion}
          versionUpdateHMI={versionUpdateHMI}
          updatePLCVersion={updateFileVersion}
          versionUpdatePLC={versionUpdatePLC}
          openEditModalPlc={plcEditModal}
          openEditModalHmi={hmiEditModal}
          openEditModalA2E={plcA2EEditModal}
          openHmiEditModal={openHmiEditModal}
          openPlcEditModal={openPlcEditModal}
          closePlcEditModal={closePlcEditModal}
          closeHmiEditModal={closeHmiEditModal}
          openPlcA2EEditModal={openPlcA2EEditModal}
          closePlcA2EEditModal={closePlcA2EEditModal}
        />

       {/* { false && <HmiPackage
          projectList={projectList}
          UserScope={UserScope}
          hmiTagNew={tags.hmiTagNew}
          protectionKeyList={protectionKeyList}
          selectedFileHMI={selectedFileHMI}
          loading={loading}
          fileVersion={fileVersion}
          fileChangedHandlerHMI={fileChangedHandlerHMI}
          uploadHmiFile={uploadHmiFile}
          updateHmiVersion={updateFileVersion}
          versionUpdateHMI={versionUpdateHMI}
          openEditModal={hmiEditModal}
          openHmiEditModal={openHmiEditModal}
          closeHmiEditModal={closeHmiEditModal}
        />} */}
        <News
          UserScope={UserScope}
          newsList={newsList}
          loading={loading}
          newAddNews={newAddNews}
          updateNews={updateNews}
          deleteNews={deleteNews}
        />
        <ContactEmail
          UserScope={UserScope}
          contactList={contactList}
          allRegions={allRegions}
          loading={loading}
          newAddContact={newAddContact}
          updateContact={updateContact}
          deleteContactFromList={deleteContactFromList}
        />
        <DcTemplate
          UserScope={UserScope}
          templatesList={templatesList}
          loading={loading}
          callSnackbar={callSnackbar}
          saveTemplate={saveTemplate}
          saveEditedTemplate={saveEditedTemplate}
          deleteTemplateFromList={deleteTemplateFromList}
        />
      </div>
      <Snackbar snackBar={snackBar} ref={snackBarRef} />
    </div>
  );
};

export default GlobalParametersHook;
