import React, { useCallback, useContext } from "react";
import { AssetBlockContext } from "../CustomHooks/ContextApi";

import cbImage from "../../images/CB.png";
// import cbMTZImage from "../../images/CBMTZ.JPG";
import embLabels from "../../config/emb-labels-en.json";

import isTouchDevice from "../SE-Component/Tooltip";
import Tooltip from "../../utils/Tooltip";
import { useSelector } from "react-redux";
import CBContactorDetails from "./CBContactorDetails";

const LoadCB = ({detailsBlock}) => {
  const {
    siteType,
    updateLoadCbConfigData,
    configData,
    handleCBConfigChangeLoad, 
    assetEditStatusUpdateLoadCb,
    selectedValues,
    setAssetSelection,
    CBLoadList,
    assetDetailsDisplay,
    projectKind,
  } = useContext(AssetBlockContext);
  const tooltipData = useSelector(state => state?.tooltipData?.tooltipData);
  const loadCbConfigChangeHandler = e => {
    handleCBConfigChangeLoad(e);
    assetEditStatusUpdateLoadCb(e, true, selectedValues.selectedLoadCB);
  };
  const contactorChangeHandler = e => {
    handleCBConfigChangeLoad(e);
    assetEditStatusUpdateLoadCb(e, true, selectedValues.selectedLoadCB);
  };
  const setAssetHandler = () => setAssetSelection(embLabels.CIRCUIT_BREAKER);
  const checked = useCallback(() =>{
    if(CBLoadList[selectedValues?.selectedLoadCB]?.uiData?.contactor){
      return true;
    }else{
      return false;
    }
  },[CBLoadList])
  const assetInfo = CBLoadList[selectedValues?.selectedLoadCB]?.cbConfigData
?.cbAssetDetails[0]?.assetInfo
  const isMotorisedDisbaled =
  (assetInfo === "Communicating" || assetInfo === "Serial");

  return (
    <div name="CB" className="col-md-12 p-0">
      <div className="col-md-12 pt-2 se-white-background">
        <div className="row">
          <div className="col-md-8">
            <img
              src={
                CBLoadList[selectedValues?.selectedLoadCB]?.cbConfigData
                  .cbAssetDetails[0]?.imageLink === null
                  ? cbImage
                  : CBLoadList[selectedValues?.selectedLoadCB]?.cbConfigData
                      .cbAssetDetails[0]?.imageLink
              }
              className="asset-image-icon"
              alt="circuit breaker"
            />
            <span className="align-top line-height-30px bold">
              Circuit Breaker
            </span>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-sm-12 pt-2 se-grey-background se-border">
        <div className="row">
          <div className="col-md-3">
            <img
              src={
                CBLoadList[selectedValues.selectedLoadCB].cbConfigData
                  .cbAssetDetails[0]?.imageLink === null
                  ? cbImage
                  : CBLoadList[selectedValues.selectedLoadCB].cbConfigData
                      .cbAssetDetails[0]?.imageLink
              }
              //src={cbImage}
              // src={
              //   assetDetailsDisplay.communicationModel.includes("Masterpact") ||
              //   CBLoadList[
              //     selectedValues.selectedLoadCB
              //   ].cbConfigData.cbAssetDetails[0]?.communicationModel.includes(
              //     "Masterpact"
              //   )
              //     ? cbMTZImage
              //     : cbImage
              // }
              className="asset-image-display"
              alt="circuit breaker"
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12 bold text-uppercase">
                Schneider Electric
              </div>
            </div>
            <div className="row">
              <div className="col-md-9">Circuit Breaker</div>
              <div className="col-md-3 text-right">
                <Tooltip
                  position="left"
                  iconColor="secondary"
                  information={
                    assetDetailsDisplay.communicationModel ||
                    CBLoadList[selectedValues.selectedLoadCB].cbConfigData
                      .cbAssetDetails[0]?.communicationModel ||
                    "PowerPact NSX"
                  }
                />
              </div>
              <div className="col-md-12">
                {assetDetailsDisplay.communicationModel ||
                  CBLoadList[selectedValues.selectedLoadCB].cbConfigData
                    .cbAssetDetails[0]?.assetInfo ||
                  "PowerPact NSX"}
              </div>
              <div
                className="row"
                style={
                  siteType === embLabels.GENSET_PVBESS_A2E
                    ? { display: "none" }
                    : {}
                }
              >
                <div className=" col-md col-sm col-lg text-center">
                  <se-button
                    option="outline"
                    size="small"
                    onClick={setAssetHandler}
                  >
                    Asset Selection
                  </se-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 mt-2 se-white-background align-middle">
        <div className="row">
          <label className=" col-md-6 mt-2 bold se-font-14px pl-3">
            Asset Settings
          </label>

          <div className="col-md-6 mt-1 text-right">
            <button
              type="button"
              className="btn btn-secondary btn-sm se-btn-primary"
              onClick={updateLoadCbConfigData}
            >
              <se-icon size="nano">action_save</se-icon>
              <span>&nbsp;</span>Save
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-12 se-grey-background">
        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">
            Name <span className="se-mandatory">*</span>
          </label>
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput1"
            placeholder="Name"
            name="name"
            value={configData.cbConfigData.name}
            onChange={loadCbConfigChangeHandler}
          />

          <div
            className="invalid-feedback-custom"
            style={!configData.cbConfigData.name ? {} : { display: "none" }}
          >
            {embLabels.nameRequired}
          </div>
        </div>

        <div className="form-group mb-1">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input asCompatible"
              name="motorized"
              id="customControlValidation4"
              onChange={loadCbConfigChangeHandler}
              value={configData.cbConfigData.motorized}
              checked={configData.cbConfigData.motorized}
            />
            <label
              className="custom-control-label line-height-25"
              htmlFor="customControlValidation4"
            >
              Motorized Circuit Breaker
            </label>
            {configData.cbConfigData.motorized}
            <Tooltip
                  position="left"
                  iconColor="secondary"
                  iconSize="small"
                  information={tooltipData?.motorized?.data}
                />
           
          </div>
        </div>
        
        {!isMotorisedDisbaled&&
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input asCompatible"
              name="contactor"
              id="customControlValidation45"
              value={checked()}
              checked={checked()}
              onChange={contactorChangeHandler}
            />
            <label
              className="custom-control-label line-height-25"
              htmlFor="customControlValidation45"
            >
              Circuit Breaker + Contactor
            </label>
        </div>}

        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">Description</label>
          <input
            type="text"
            autoComplete="none"
            className="form-control form-control-sm"
            id="exampleFormControlInput1"
            placeholder="Description"
            name="description"
            value={configData.cbConfigData.description}
            onChange={loadCbConfigChangeHandler}
          />
        </div>
      </div>
      {CBLoadList[selectedValues?.selectedLoadCB]?.uiData?.contactor&&!isMotorisedDisbaled&&<CBContactorDetails detailsBlock={detailsBlock}/>}
    </div>
  );
};

export default LoadCB;
