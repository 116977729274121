import React, { useState } from 'react'
import { useEffect } from 'react'
import ApiSession from '../api/ApiSession';

const CheckApiHealth = () => {
    const [status, setStatus] = useState();
    const apiSession = new ApiSession();
    useEffect(()=>{
      try {
        apiSession.getHealthStatus()
        .then(reps=>{
            setStatus(reps.status)
        })
        .catch()
      } catch (error) {
        console.log(error)
      }
      
    },[])
  return (
    <div>{status}</div>
  )
}

export default CheckApiHealth