import React, { Component } from "react";
import APISESSION from "../api/ApiSession";
import ProgressBarContainer from "../components/progressBar/progressBarContainer";
import { connect } from "react-redux";
import { upDateProjectType } from "../redux/features/projectTypeSlice";
import SupportDocument from "./supportDocument.jsx";
import { getProjectId } from "../utils/project.js";
const apiSession = new APISESSION();
const mapDispatchToProps = (dispatch) => {
  return {
    updateProjectType: (projectType, projectName) =>
      dispatch(upDateProjectType({ projectType, projectName })),
  };
};

class BOM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bomList: [],
      dataTrafficList: [],
      loading: true,
      showDetailsLience: -1,
      showDetailsController: -1,
      projectStatusData: null,
      totalTags: 0,
      showSupportDocumentDialog: false,
      documentList: [],
    };
  }
  componentDidMount() {
    this.loadBOM();
    this.getProjectDetailStatus();
  }

  getProjectDetailStatus = () => {
    try {
      apiSession.getProjectStatus(getProjectId()).then((response) => {
        if (response) {
          this.setState({ projectStatusData: response });
          this.props.updateProjectType(
            response.projectType,
            response.projectName
          );
          localStorage.setItem("pType", response.projectType);
        }
      });
      apiSession
        .listAllBOMDocuments(getProjectId(), "bom")
        .then((response) =>
          this.setState({ documentList: response.documentBlobInfo })
        );
    } catch (error) {
      console.log(error);
    }
  };

  loadBOM() {
    apiSession
      .getBomList(getProjectId())
      .then((response) => {
        this.setState({ loading: true });
        if (response) {
          let bomListNew = Object.assign([], this.state.bomList);
          let dataTrafficListNew = Object.assign(
            [],
            this.state.dataTrafficList
          );
          bomListNew = response;
          dataTrafficListNew = response;
          this.setState({ bomList: bomListNew.bom });
          this.setState({ dataTrafficList: dataTrafficListNew.dataTraffic });

          this.state.dataTrafficList.map((dataTraffic) =>
            this.setState({
              totalTags: this.state.totalTags + dataTraffic.quantityOfTags,
            })
          );
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          //do nothing
          this.setState({ loading: false });
        }
      });
  }
  showLicenseMore(index) {
    this.setState({
      showDetailsLience: index,
    });
  }

  showControllerMore(index) {
    this.setState({
      showDetailsController: index,
    });
  }

  render() {
    return (
      <>

        <div className="container-fluid h-100 container-margin">
          <se-loading loading={this.state.loading}></se-loading>
          {this.state.loading ? (
            <div className="stickyProgressBar">
              <div className="d-flex justify-content-between">
                <se-skeleton
                  height="20px"
                  style={{ width: "33%" }}
                ></se-skeleton>
                <se-skeleton
                  height="20px"
                  style={{ width: "33%" }}
                ></se-skeleton>
                <se-skeleton
                  height="20px"
                  style={{ width: "33%" }}
                ></se-skeleton>
              </div>
            </div>
          ) : (
            <ProgressBarContainer
              projectStatusData={this.state.projectStatusData}
            />
          )}
          <se-container option="centered" className="mt-2">
            <div className="container se-white-background pb-2">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-1">
                <div className=" se-font-14px-bold se-life-green mb-2 mt-2">
                  <div
                    className="flex-10 se-font-14px-bold se-life-green mb-2 mt-2"
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                    }}
                  >
                    <span> Microgrid Controller Bill of materials </span>
                    {
                      <a
                        href=""
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          this.setState({ showSupportDocumentDialog: true });
                        }}
                      >
                        Support Document
                      </a>
                    }
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mb-0 se-font-14px">
                    <thead>
                      <tr>
                        <th>Commercial Reference</th>
                        <th>Description</th>
                        <th>Quantity</th>
                        {/* <th>Reference</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="3">Controller</td>
                      </tr>
                      {this.state.bomList.map((bomData, index) =>
                        bomData.type === "controller" &&
                        bomData.partNumber !== "TCSESB083F23F0" ? (
                          <tr>
                            <td> {bomData.partNumber}</td>
                            <td> {bomData.description}</td>
                            <td> {bomData.quantity}</td>
                            {/* <td className="text-center"><se-link url={bomData.link}>Link</se-link></td> */}
                          </tr>
                        ) : (
                          ""
                        )
                      )}

                      {this.state.bomList.findIndex(
                        (bom) => bom.type === "license"
                      ) > -1 && (
                        <tr>
                          <td colspan="3">License</td>
                        </tr>
                      )}

                      {this.state.bomList.map((bomData, index) =>
                        bomData.type === "license" ? (
                          <tr>
                            <td> {bomData.partNumber}</td>
                            <td> {bomData.description}</td>
                            <td> {bomData.quantity}</td>
                          </tr>
                        ) : (
                          ""
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {!this.state.loading && this.state.dataTrafficList && (
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-1 mt-4 mb-5">
                  <div className="se-font-14px-bold se-life-green mb-2 mt-2">
                    Data Traffic Calculation
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered mb-0 se-font-14px">
                      <thead>
                        <tr className="text-center">
                          <th>Resource</th>
                          <th>Quantity of PM</th>
                          <th>Quantity of CB</th>
                          <th>Quantity of DEVICES</th>
                          {/* <th>Quantity of RESOURCES</th> */}
                          <th>Quantity of TAGS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.dataTrafficList.map(
                          (dataTraffic, index) => (
                            <tr className="text-center">
                              <td> {dataTraffic.resource}</td>
                              <td>
                                {" "}
                                {dataTraffic.resource === "MICROGRID" &&
                                dataTraffic.quantityOfPowerMeters === 0
                                  ? "NA"
                                  : dataTraffic.quantityOfPowerMeters === 0
                                  ? "NA"
                                  : dataTraffic.quantityOfPowerMeters}
                              </td>
                              <td>
                                {" "}
                                {dataTraffic.resource === "MICROGRID" &&
                                dataTraffic.quantityOfCircuitBreakers === 0
                                  ? "NA"
                                  : dataTraffic.quantityOfCircuitBreakers === 0
                                  ? "NA"
                                  : dataTraffic.quantityOfCircuitBreakers}
                              </td>
                              <td>
                                {" "}
                                {["MICROGRID", "UTILITY", "LOADS"].includes(
                                  dataTraffic.resource
                                ) && dataTraffic.quantityOfDevices === 0
                                  ? "NA"
                                  : dataTraffic.quantityOfDevices}
                              </td>
                              {/* <td> { dataTraffic.resource === "MICROGRID" && dataTraffic.quantityOfResources === 0?"NA":dataTraffic.quantityOfResources}</td> */}
                              <td> {dataTraffic.quantityOfTags}</td>
                            </tr>
                          )
                        )}
                        <tr className="text-center">
                          <td className="bold">TOTAL</td>
                          <td>{""}</td>
                          <td>{""}</td>
                          <td>{""}</td>
                          {/* <td>{""}</td> */}
                          <td className="bold">{this.state.totalTags}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </se-container>
        </div>
        <SupportDocument
          onClose={() => this.setState({ showSupportDocumentDialog: false })}
          showSupportDocumentDialog={this.state.showSupportDocumentDialog}
          documentList={this.state.documentList}
        />
      </>
    );
  }
}
export default connect(null, mapDispatchToProps)(BOM);
