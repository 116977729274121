import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";
import embLabels from "../../config/emb-labels-en.json";
import MyErrorBoundary from "../../utils/ErrorBoundary";
import useCBMouserOver from "../../pages/CustomHooks/useCBMouserOver";

const Ev = ({ index, status }) => {
  const {
    CBEVList: [{ uiData,uiData:ev, configData, powerMeterData,cbRequired,cbConfigData }],
    UserScope,
    CBEVListDispatch,
    assetDetailsReset,
    selectedValuesHandler,
    selectedAssetHandler,
    evConfigDataHandler,
    evpmConfigDataHandler,
    evCbConfigDataHandler,
    callSnackbar,
    loadSide,
    switchboardType,
    projectTypeValue
  } = useContext(DesignAssetContext);

const [mouseOverData, CBMouseHandler] = useCBMouserOver();
  const evSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "evDetailsBlock";
      const selectedAssetValue = "selectedEv";
      CBEVListDispatch({
        type: ACTIONS.EV_SELECT,
        payload: {
          index,
        },
      });
      selectedValuesHandler(index, selectedAssetValue, assetBlock);
      selectedAssetHandler(embLabels.EV_CHARGING_STATION, index);
      evConfigDataHandler(index);
    } else {
      callSnackbar(
        "error",
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };
  const evCBSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "cbEvDetailsBlock";
      const selectedAssetValue = "selectedEvCB";
      CBEVListDispatch({
        type: ACTIONS.EV_CB_SELECT,
        payload: {
          index,
        },
      });

      selectedValuesHandler(index, selectedAssetValue, assetBlock);
      evCbConfigDataHandler(index);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const evPMSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "pmAssetDetailsBlockGrid";
      const selectedAssetValue = "selectedEvPM";
      CBEVListDispatch({
        type: ACTIONS.EV_PM_SELECT,
        payload: {
          index,
        },
      });
      selectedValuesHandler(index, selectedAssetValue, assetBlock);
      evpmConfigDataHandler(index);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  return (
    <MyErrorBoundary>
      
  
    <svg
      className={`pointer`}
      style={status ? {} : { display: "none", pointerEvents: "none" }}
    >
      <g id="ev">
        <svg
          x={switchboardType==='NEW_SWITCHBOARD'?Number(ev.xAxis) - 229.5 :ev.xAxis}
          y={switchboardType==='NEW_SWITCHBOARD'?Number(ev.yAxis) - 168:(loadSide ==='LOAD_SIDE'?ev.yAxis:projectTypeValue==='MGaaS IEC'?ev.yAxis:(Number(ev.yAxis)-40))}
          width="149px"
          height="475px"
          viewBox="0 0 90 550"
          onClick={evSelectHandler}
        >
          <title>{configData.name}</title>
          <g
            id="Design"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Design/Assets-_-00"
              transform="translate(-604.000000, -372.000000)"
            >
              <g
                id="SLD/Production/Uniline/Undefined"
                transform="translate(604.000000, 372.000000)"
              >
                <g id="SLD/Production/Uniline/Tobedefine">
                  <g id="Group-7">
                    <rect
                      id="Rectangle"
                      x="0"
                      y="95"
                      width="96"
                      height="148"
                    ></rect>
                    <line
                      x1="48.5"
                      y1="96"
                      x2="48.5"
                      y2="144"
                      id="Line-2"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      stroke={ev.configured ? "#333" : "#E47F00"}
                    ></line>
                    <circle
                      id="Oval"
                      stroke="#42B4E6"
                      strokeWidth="3"
                      cx="48"
                      cy="193.5"
                      r="55"
                      className={ev.selected ? "" : "hidden"}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="rgba(0, 135, 205, 0.2)"
                    ></circle>

                    <circle
                      id="Oval"
                      strokeWidth="3"
                      fill="#FFFFFF"
                      cx="48"
                      cy="193.5"
                      r="46.5"
                      stroke={ev.configured ? "#333" : "#E47F00"}
                    ></circle>

                    <text
                      id="segment_energy_infra"
                      fontFamily="se-icon"
                      fontSize="56"
                      fontWeight="normal"
                      fill="#333333"
                    >
                      <tspan x="20" y="210">
                        electric_vehicle_terminal
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </g>
      <g id="power meter" style={ev.pmRequired ? {} : { display: "none" }}>
        <svg
          x={switchboardType==='NEW_SWITCHBOARD'?Number(ev.xDerPM)-230:ev.xDerPM}
          y={switchboardType==='NEW_SWITCHBOARD'?Number(ev.yDerPM) - 170 :(loadSide ==='LOAD_SIDE'?ev.yDerPM:projectTypeValue==='MGaaS IEC'?ev.yDerPM:(Number(ev.yDerPM)-40))}
          width="300px"
          height="140px"
          viewBox="0 0 100 160"
          onClick={evPMSelectHandler}
        >
          <title>{powerMeterData.name}</title>

          <line
            x1="30"
            y1="127"
            x2="80"
            y2="127"
            stroke={ev.pmConfiguredGrid ? "#333" : "#E47F00"}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            id="Pm outer"
            stroke="#42B4E6"
            strokeWidth="3"
            cx="100"
            cy="127"
            r="26"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="rgba(0, 135, 205, 0.2)"
            style={ev.pmSelected ? {} : { display: "none" }}
          ></circle>
          <circle
            id="pm inner"
            stroke={ev.pmConfiguredGrid ? "#333" : "#E47F00"}
            strokeWidth="3"
            cx="100"
            cy="127"
            r="18.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="#FFFFFF"
          ></circle>
          <text
            id="PM"
            fontSize="16"
            fontWeight="normal"
            stroke="#333"
            fontFamily="Nunito-Regular, Nunito"
            fill="#333"
          >
            <tspan x="88" y="132">
              PM
            </tspan>
          </text>
        </svg>
      </g>
      {uiData.cbRequired ?
      <g id="CB60">
      <svg
        width="75px"
        height="100px"
        viewBox="0 0 10 149"
        x={projectTypeValue==='MGaaS IEC'?(switchboardType==='NEW_SWITCHBOARD'?520:640):(switchboardType==='NEW_SWITCHBOARD'?343:572)}
        y={projectTypeValue==='MGaaS IEC'?(switchboardType==='NEW_SWITCHBOARD'?370:470):(switchboardType==='NEW_SWITCHBOARD'?300:(loadSide==='LOAD_SIDE'?470:430))}
        onClick={evCBSelectHandler}
      >
        <title>{cbConfigData.name}</title>
        <g
          id="Circuit Breaker"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Circuit Breaker 1"
            transform="translate(-651.000000, -512.000000)"
          >
            <g
              id="Circuit Breaker - closed"
              transform="translate(604.000000, 514.000000)"
              onMouseOver={CBMouseHandler}
              onMouseOut={CBMouseHandler}
            >
              <g
                id="Group-15"
                strokeWidth="4"
                fillRule="evenodd"
                fill={
                  uiData.CBSelected
                    ? "rgba(65, 181, 230, 0.35)"
                    : mouseOverData
                      ? "rgba(65, 181, 230, 0.35)"
                      : "none"
                }
                stroke={uiData.CBSelected ? "#42B4E6" : "none"}
              >
                <rect
                  id="Rectangle-Copy-20"
                  x="0"
                  y="12"
                  width="96"
                  height="112"
                ></rect>
                <g
                  id="Group-2"
                  transform="translate(48.000000, 0.000000)"
                  stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="4"
                >
                  <line
                    x1="0.5"
                    y1="0"
                    x2="0.5"
                    y2="47.8298611"
                    id="Line-2"
                  ></line>
                </g>
              </g>
              <line
                x1="48.5"
                y1="97.1701389"
                x2="48.501111"
                y2="145"
                id="Line-2-Copy-15"
                stroke={ev.CBConfigured ? "#333333" : "#E47F00"}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></line>
              <path
                d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                id="Path-11"
                strokeWidth="4"
                strokeLinecap="round"
                stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </g>:
      <g id="CB60">
      <line
        x1={ev.x1DerEVCB}
        y1={switchboardType==='NEW_SWITCHBOARD'?ev.y1DerEVCB:(loadSide ==='LOAD_SIDE'?ev.y1DerEVCB:projectTypeValue==='MGaaS IEC'?ev.y1DerEVCB:(Number(ev.y1DerEVCB)-40))}
        x2={ev.x2DerEVCB}
        y2={switchboardType==='NEW_SWITCHBOARD'?ev.y2DerEVCB:(loadSide ==='LOAD_SIDE'?ev.y2DerEVCB:projectTypeValue==='MGaaS IEC'?ev.y2DerEVCB:(Number(ev.y2DerEVCB)-40))}
        id="Line-2"
        strokeWidth="3"
        strokeDasharray="4 8"
        strokeLinecap="round"
        stroke="#333333"
      ></line>
    </g>}
    </svg>
</MyErrorBoundary>
  );
};

export default Ev;
