import React, { useContext, useEffect, useState } from "react";

import TransitionType from "./TransitionType";
import isTouchDevice from "../SE-Component/Tooltip";
import embLabels from "../../config/emb-labels-en.json";
import { InformationPageContext } from "../CustomHooks/ContextApi";
import Tooltip from "../../utils/Tooltip";
import MyErrorBoundary from "../../utils/ErrorBoundary";
import { getProjectTypeValue } from "../../utils/project";

const SelectUseCase = () => {
  const [organizationValue, setOrganizationValue] = useState("");
  const {
    projectType,
    siteType,
    informationToolTIP,
    disabledFeatures,
    optimizationEngine,
    otherOptimizationStrategy,
    gridSettings,
    handleOptimizationChange,
    handleOtherOptimizationChange,
    handleOnGridFeatureChange,
    handleModeChange,
    architectureType,
    organization,
    handleOrganizationChange,
    handleOnGridFeatureChangeGSX,
    selectedOrganization,
    useCaseHMIData,
  } = useContext(InformationPageContext);
  const projectTypeValue = getProjectTypeValue()
  const [useCases, setUseCases] = useState({});
  const handleOrganizationValueChange = (event) => {
    const {
      target: { value },
    } = event;
    setOrganizationValue(value);
    handleOrganizationChange(event);
  };
  useEffect(() => {
    if (useCaseHMIData && Object.keys(useCaseHMIData)?.length) {
      setUseCases({ ...useCaseHMIData });
    }
  }, [useCaseHMIData]);
  useEffect(() => {
    setOrganizationValue(selectedOrganization?.organizationKey);
  }, [selectedOrganization]);
  const isOnGridFeaturesEnabled = () => {
    const status =
      optimizationEngine === "EMA" &&
      gridSettings.remoteMonitoringControl &&
      siteType !== embLabels.PV_HYBRIDIZATION
        ? "enabled"
        : "disabled";

    return status === "enabled" ? true : false;
  };

  return (
    <MyErrorBoundary>
      <div className="row mt-2">
        {[embLabels.GRID_CONNECTED, embLabels.ISLANDABLE].includes(siteType) &&
          projectTypeValue === "MGaaS ANSI" && (
            <div className="col-md-12 my-2">
              <span className="labelCircle mr-2 ">3</span>{" "}
              <span>Optimization Engine</span>
              <Tooltip
                position="right"
                iconColor="secondary"
                information="Select your Optimization Engine"
              />
            </div>
          )}
        <div className="col-md-12">
          <div className="col-md-12 border-radius-5px ">
            <div className="row">
              {[
                embLabels.ISLANDABLE,
                embLabels.GRID_CONNECTED,
                embLabels.PV_HYBRIDIZATION,
                embLabels.ISLANDABLE_GSX,
              ].includes(siteType) && (
                <div
                  className={
                    siteType === embLabels.ISLANDABLE
                      ? disabledFeatures.disableCloud
                        ? "col-sm-4 col-md-4 col-lg-4 col-xl-4 se-block-disabled"
                        : "col-sm-4 col-md-4 col-lg-4 col-xl-4 "
                      : "col-md-4 "
                  }
                >
                  {[
                    embLabels.ISLANDABLE,
                    embLabels.GRID_CONNECTED,
                    embLabels.ISLANDABLE_GSX,
                  ].includes(siteType) &&
                    gridSettings.remoteMonitoringControl && (
                      <div className="row">
                        <div className="col-md-12 mt-1">
                          <span>Optimization Engine</span>
                        </div>
                      </div>
                    )}
                  {[
                    embLabels.ISLANDABLE,
                    embLabels.GRID_CONNECTED,
                    embLabels.ISLANDABLE_GSX,
                  ].includes(siteType) &&
                    gridSettings.remoteMonitoringControl && (
                      <div className="row">
                        <div className="col-md-12">
                          {!["DaaS ANSI", "DaaS IEC"].includes(
                            projectTypeValue
                          ) && (
                            <div className="row">
                              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    className="custom-control-input islandableRadio asCompatible"
                                    name="optimizationEngine"
                                    id="emaOptimization"
                                    value="EMA"
                                    checked={optimizationEngine === "EMA"}
                                    onChange={handleOptimizationChange}
                                  />
                                  <label
                                    className="custom-control-label line-height-25"
                                    htmlFor="emaOptimization"
                                  >
                                    Microgrid Advisor
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                          {projectTypeValue !== "MGaaS IEC" &&
                            !(
                              siteType === "GRID_CONNECTED" ||
                              siteType === "ISLANDABLE"
                            ) && (
                              <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1 ">
                                  <div className="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      className="custom-control-input islandableRadio asCompatible"
                                      name="optimizationEngine"
                                      id="otherOptimization"
                                      value="OTHER"
                                      checked={optimizationEngine === "OTHER"}
                                      onChange={handleOptimizationChange}
                                    />
                                    <label
                                      className="custom-control-label line-height-25"
                                      htmlFor="otherOptimization"
                                    >
                                      Other optimization
                                    </label>
                                  </div>
                                </div>
                                {optimizationEngine === "OTHER" && (
                                  <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ml-5 mt-1  mb-2 ">
                                      <div className="custom-control custom-radio">
                                        <input
                                          type="radio"
                                          className="custom-control-input islandableRadio asCompatible"
                                          name="otherOptimizationStrategy"
                                          id="autogridOptimization"
                                          value="AUTOGRID"
                                          checked={
                                            otherOptimizationStrategy ===
                                            "AUTOGRID"
                                          }
                                          onChange={
                                            handleOtherOptimizationChange
                                          }
                                        />
                                        <label
                                          className="custom-control-label line-height-25"
                                          htmlFor="autogridOptimization"
                                        >
                                          Autogrid Flex &trade;
                                        </label>
                                        <Tooltip
                                          position="right"
                                          iconColor="secondary"
                                          iconSize="small"
                                          //       information="To change the default Microgrid Advisor
                                          // server to autogrid."
                                          information={
                                            informationToolTIP?.autogridflexTooltipData
                                          }
                                        />
                                      </div>
                                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ml-5 mt-1  mb-2 ">
                                        <div className="form-group row ">
                                          <label
                                            htmlFor="inputOrganization"
                                            className="col-sm-2 col-form-label text-right mr-4"
                                          >
                                            Organization
                                          </label>
                                          <div className="col-sm-5 ">
                                            <input
                                              type="password"
                                              name="organization"
                                              className="form-control form-control-md"
                                              autoComplete="new-password"
                                              value={organizationValue}
                                              onChange={
                                                handleOrganizationValueChange
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                        </div>
                      </div>
                    )}

                  {(optimizationEngine === "EMA" ||
                    (optimizationEngine === "OTHER" &&
                      siteType === embLabels.GRID_CONNECTED) ||
                    siteType === embLabels.ISLANDABLE) && (
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-1">
                        <span>Optimization Features </span>
                      </div>
                    </div>
                  )}
                  {isOnGridFeaturesEnabled() && (
                    <>
                      {useCases?.useCases?.["Optimization Features"]?.includes(
                        "Time of Use (Electricity Tariff based Optimization)"
                      ) && (
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input asCompatible"
                                name="tariffManagement"
                                id="customControlValidation2"
                                value={gridSettings.tariffManagement}
                                checked={gridSettings.tariffManagement}
                                onChange={handleOnGridFeatureChange}
                              />
                              <label
                                className="custom-control-label line-height-25"
                                htmlFor="customControlValidation2"
                              >
                                {`Time of Use (Electricity Tariff based Optimization)`}
                              </label>
                              <Tooltip
                                position="right"
                                iconColor="secondary"
                                iconSize="small"
                                information={
                                  informationToolTIP.tariffManagementTooltipData
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {useCases?.useCases?.["Optimization Features"]?.includes(
                        "Demand Charge Optimization"
                      ) && (
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input asCompatible"
                                name="demandCharge"
                                id="customControlValidation1"
                                value={gridSettings.demandCharge}
                                checked={gridSettings.demandCharge}
                                onChange={handleOnGridFeatureChange}
                              />
                              <label
                                className="custom-control-label line-height-25"
                                htmlFor="customControlValidation1"
                              >
                                Demand Charge Optimization
                              </label>
                              <Tooltip
                                position="right"
                                iconColor="secondary"
                                iconSize="small"
                                information={
                                  informationToolTIP.demandChargeTooltipData
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {useCases?.useCases?.["Optimization Features"]?.includes(
                        "Self Consumption"
                      ) && (
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input asCompatible"
                                name="selfConsumption"
                                id="customControlValidation3"
                                value={gridSettings.selfConsumption}
                                checked={gridSettings.selfConsumption}
                                onChange={handleOnGridFeatureChange}
                              />
                              <label
                                className="custom-control-label line-height-25"
                                htmlFor="customControlValidation3"
                              >
                                Self Consumption
                              </label>
                              <Tooltip
                                position="right"
                                iconColor="secondary"
                                iconSize="small"
                                information={
                                  informationToolTIP.selfConsumptionTooltipData
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {useCases?.useCases?.["Optimization Features"]?.includes(
                        "Limit Energy Import/Export"
                      ) && (
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input asCompatible"
                                name="exportOptimization"
                                id="customControlValidation4"
                                value={gridSettings.exportOptimization}
                                checked={gridSettings.exportOptimization}
                                onChange={handleOnGridFeatureChange}
                              />
                              <label
                                className="custom-control-label line-height-25"
                                htmlFor="customControlValidation4"
                              >
                                Limit Energy Import/Export
                              </label>
                              <Tooltip
                                position="right"
                                iconColor="secondary"
                                iconSize="small"
                                information={
                                  informationToolTIP.exportOptimizationTooltipData
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                       {false && useCases?.useCases?.["Optimization Features"]?.includes(
                        "Outage mode preparation"
                      ) && (
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input asCompatible"
                              name="stormPreparedness"
                              id="stormPreparedness1"
                              value={gridSettings.stormPreparedness}
                              checked={gridSettings.stormPreparedness}
                              onChange={handleOnGridFeatureChange}
                            />
                            <label
                              className="custom-control-label line-height-25"
                              htmlFor="stormPreparedness1"
                            >
                              Outage mode preparation
                            </label>
                            <Tooltip
                              position="right"
                              iconColor="secondary"
                              iconSize="small"
                              information={
                                informationToolTIP?.stormPreparednessTooltipData
                              }
                            />
                          </div>
                        </div>
                      </div>
                      )}
                      {useCases?.useCases?.["Optimization Features"]?.includes(
                        "Green Consumption"
                      ) && (
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input asCompatible"
                              name="greenConsumption"
                              id="greenConsumption1"
                              value={gridSettings.greenConsumption}
                              checked={gridSettings.greenConsumption}
                              onChange={handleOnGridFeatureChange}
                            />
                            <label
                              className="custom-control-label line-height-25"
                              htmlFor="greenConsumption1"
                            >
                              Green Consumption
                            </label>
                            <Tooltip
                              position="right"
                              iconColor="secondary"
                              iconSize="small"
                              information={
                                informationToolTIP?.greenConsumptionTooltipData
                              }
                            />
                          </div>
                        </div>
                      </div>
                      )}
                    </>
                  )}
                  {(optimizationEngine === "EMA" ||
                    (optimizationEngine === "OTHER" &&
                      siteType === embLabels.GRID_CONNECTED) ||
                    siteType === embLabels.ISLANDABLE) && (
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-1">
                        <span>Flexibility Services </span>
                      </div>
                    </div>
                  )}
                  {isOnGridFeaturesEnabled() &&
                    useCases?.useCases?.["Flexibility Services"]?.includes(
                      "Manual Demand Response"
                    ) && (
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input asCompatible"
                              name="manualDemandResponse"
                              id="customControlValidation41"
                              value={gridSettings?.manualDemandResponse}
                              checked={gridSettings?.manualDemandResponse}
                              onChange={handleOnGridFeatureChange}
                            />
                            <label
                              className="custom-control-label line-height-25"
                              htmlFor="customControlValidation41"
                            >
                              Manual Demand Response
                            </label>
                            <Tooltip
                              position="right"
                              iconColor="secondary"
                              iconSize="small"
                              information={
                                informationToolTIP?.manualDemandResponseTooltipData
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {false && useCases?.useCases?.["Flexibility Services"]?.includes(
                    "Net Metering"
                  ) && false && (
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input asCompatible"
                            name="netMetering"
                            id="netMetering1"
                            value={gridSettings.netMetering}
                            checked={gridSettings.netMetering}
                            onChange={handleOnGridFeatureChange}
                          />
                          <label
                            className="custom-control-label line-height-25"
                            htmlFor="netMetering1"
                          >
                            Net Metering
                          </label>
                          <Tooltip
                            position="right"
                            iconColor="secondary"
                            iconSize="small"
                            information={
                              informationToolTIP?.netMeteringTooltipData
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {false &&
                    (optimizationEngine === "EMA" ||
                      (optimizationEngine === "OTHER" &&
                        siteType === embLabels.GRID_CONNECTED) ||
                      siteType === embLabels.ISLANDABLE) && (
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-1">
                          <span>Resilience </span>
                        </div>
                      </div>
                    )}
                  {false && isOnGridFeaturesEnabled() && (
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input asCompatible"
                            name="offGridMode"
                            id="offGridMode1"
                            value={gridSettings?.offGridMode}
                            checked={gridSettings?.offGridMode}
                            onChange={handleOnGridFeatureChange}
                          />
                          <label
                            className="custom-control-label line-height-25"
                            htmlFor="offGridMode1"
                          >
                            Prepare for OffGrid Mode
                          </label>
                          <Tooltip
                            position="right"
                            iconColor="secondary"
                            iconSize="small"
                            information={
                              informationToolTIP?.offGridModeTooltipData
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {(optimizationEngine === "EMA" ||
                    (optimizationEngine === "OTHER" &&
                      siteType === embLabels.ISLANDABLE_GSX &&
                      architectureType === embLabels.PV_GENSET_ATS)) && (
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-1">
                        <span>Real Time Control </span>
                      </div>
                    </div>
                  )}
                  {(optimizationEngine === "EMA" ||
                    (optimizationEngine === "OTHER" &&
                      siteType === embLabels.ISLANDABLE_GSX &&
                      architectureType === embLabels.PV_GENSET_ATS)) && (
                    <>
                      {false && (
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input asCompatible"
                                name="noImport"
                                id="customControlValidation51"
                                value={gridSettings?.noImport}
                                checked={gridSettings?.noImport}
                                onChange={handleOnGridFeatureChange}
                              />
                              <label
                                className="custom-control-label line-height-25"
                                htmlFor="customControlValidation51"
                              >
                                Real Time Import Control (EMA Controller based)
                              </label>
                              <Tooltip
                                position="right"
                                iconColor="secondary"
                                iconSize="small"
                                information={
                                  informationToolTIP?.realTimeImportControlEMATooltipData
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {useCases?.useCases?.["Real Time Control"]?.includes(
                        "Real Time Export Control (EMO Controller based)"
                      ) && (
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input asCompatible"
                                name="noExport"
                                id="customControlValidation52"
                                value={gridSettings.noExport}
                                checked={gridSettings.noExport}
                                onChange={handleOnGridFeatureChange}
                              />
                              <label
                                className="custom-control-label line-height-25"
                                htmlFor="customControlValidation52"
                              >
                                Real Time Export Control (EMO Controller based)
                              </label>
                              <Tooltip
                                position="right"
                                iconColor="secondary"
                                iconSize="small"
                                information={
                                  informationToolTIP.realTimeExportControlEMOTooltipData
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {useCases?.useCases?.["Real Time Control"]?.includes(
                        "Controlled BESS Energy Exchanges with the grid"
                      ) && (
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input asCompatible"
                                name="bessEnergyExchanges"
                                id="bessEnergyExchanges1"
                                value={gridSettings?.bessEnergyExchanges}
                                checked={gridSettings?.bessEnergyExchanges}
                                onChange={handleOnGridFeatureChangeGSX}
                              />
                              <label
                                className="custom-control-label line-height-25"
                                htmlFor="bessEnergyExchanges1"
                              >
                                Controlled BESS Energy Exchanges with the grid
                              </label>
                              <Tooltip
                                position="right"
                                iconColor="secondary"
                                iconSize="small"
                                information="Allow to control Energy exchanges between the BESS and the Grid. If not selected, BESS will be allowed to charge and discharge from/to the grid (unrestricted exchanges)"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

              {[
                embLabels.STANDARD_MICROGRID,
                embLabels.GREENSTRUXURE_MICROGRID,
              ].includes(projectType) &&
                [
                  embLabels.ISLANDABLE,
                  embLabels.OFF_GRID,
                  embLabels.ISLANDABLE_GSX,
                ].includes(siteType) && (
                  <div
                    className={`col-sm-3 col-md-3 col-lg-3 col-xl-3 border-left ${
                      siteType === embLabels.OFF_GRID ||
                      disabledFeatures.disableOpertaions
                        ? "se-block-disabled"
                        : ""
                    }`}
                  >
                    <div className="row">
                      <div className="col-md-12 pr-0">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-1">
                            <span>
                              Asset Configuration{" "}
                              <span className="se-mandatory">*</span>{" "}
                            </span>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1 ">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="customRadio4"
                                name="mode"
                                className="custom-control-input islandableRadio asCompatible"
                                value="NO_FUEL"
                                checked={
                                  gridSettings.mode === "NO_FUEL" ? true : false
                                }
                                disabled={
                                  !useCases?.useCases?.["Asset Configuration"]?.includes("NO_FUEL")
                                }
                                //Enabled in dev env
                                // disabled={true}
                                // style={{ cursor: "not-allowed" }}
                                // disabled={
                                //  (( projectType ===
                                //     embLabels.GREENSTRUXURE_MICROGRID &&
                                //     siteType === embLabels.ISLANDABLE_GSX &&
                                //    ! ["MGaaS ANSI", "DaaS ANSI"].includes(projectTypeData?.projectTypeValue) &&
                                //     architectureType === embLabels.PV_GENSET_ATS)||(embLabels.STANDARD_MICROGRID &&
                                //       siteType === embLabels.ISLANDABLE && projectTypeData?.projectTypeValue==="MGaaS ANSI"))
                                //     ? true
                                //     : false
                                // }
                                // style={
                                // ((  projectType ===
                                //     embLabels.GREENSTRUXURE_MICROGRID &&
                                //     siteType === embLabels.ISLANDABLE_GSX &&
                                //     architectureType === embLabels.PV_GENSET_ATS)||(embLabels.STANDARD_MICROGRID &&
                                //       siteType === embLabels.ISLANDABLE&&projectTypeData?.projectTypeValue==="MGaaS ANSI"))
                                //     ? { cursor: "not-allowed" }
                                //     : { cursor: "pointer" }
                                // }
                                onChange={handleModeChange}
                              />
                              <label
                                className="custom-control-label line-height-25"
                                htmlFor="customRadio4"
                              >
                                BESS main, No Genset
                              </label>
                              <span className="se-block-enabled">
                                <se-tooltip
                                  action={isTouchDevice() ? "click" : "hover"}
                                  position="right"
                                >
                                  <se-icon
                                    option="raised"
                                    color="secondary"
                                    slot="tooltip"
                                    disabled={
                                      !useCases?.useCases?.["Asset Configuration"]?.includes("NO_FUEL")
                                    }
                                  >
                                    information_circle
                                  </se-icon>
                                  <se-tooltip-content>
                                    {informationToolTIP.noFuelTooltipData}
                                  </se-tooltip-content>
                                </se-tooltip>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="customRadio5"
                                name="mode"
                                className="custom-control-input islandableRadio asCompatible"
                                value="FUEL_ERASER"
                                checked={
                                  gridSettings.mode === "FUEL_ERASER"
                                    ? true
                                    : false
                                }
                                disabled={
                                  !useCases?.useCases?.["Asset Configuration"]?.includes("FUEL_ERASER")
                                }
                                // disabled={
                                //   (projectType ===
                                //     embLabels.GREENSTRUXURE_MICROGRID &&
                                //     siteType === embLabels.ISLANDABLE_GSX &&
                                //     architectureType ===
                                //       embLabels.PV_GENSET_ATS) ||
                                //   (embLabels.STANDARD_MICROGRID &&
                                //     siteType === embLabels.ISLANDABLE &&
                                //     projectTypeValue ===
                                //       "MGaaS ANSI")
                                //     ? true
                                //     : false
                                // }
                                // style={
                                //   (projectType ===
                                //     embLabels.GREENSTRUXURE_MICROGRID &&
                                //     siteType === embLabels.ISLANDABLE_GSX &&
                                //     architectureType ===
                                //       embLabels.PV_GENSET_ATS) ||
                                //   (embLabels.STANDARD_MICROGRID &&
                                //     siteType === embLabels.ISLANDABLE &&
                                //     projectTypeValue ===
                                //       "MGaaS ANSI")
                                //     ? { cursor: "not-allowed" }
                                //     : { cursor: "pointer" }
                                // }
                                onChange={handleModeChange}
                              />
                              <label
                                className="custom-control-label line-height-25"
                                htmlFor="customRadio5"
                              >
                                BESS main, Genset as backup
                              </label>
                              <span className="se-block-enabled">
                                <se-tooltip
                                  action={isTouchDevice() ? "click" : "hover"}
                                  position="right"
                                >
                                  <se-icon
                                    option="raised"
                                    color="secondary"
                                    slot="tooltip"
                                    disabled={
                                      !useCases?.useCases?.["Asset Configuration"]?.includes("FUEL_ERASER")
                                    }
                                  >
                                    information_circle
                                  </se-icon>
                                  <se-tooltip-content>
                                    {informationToolTIP.fuelEraserTooltipData}
                                  </se-tooltip-content>
                                </se-tooltip>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1 ">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="customRadio6"
                                name="mode"
                                className="custom-control-input islandableRadio asCompatible"
                                value="FUEL_SAVER"
                                checked={
                                  gridSettings.mode === "FUEL_SAVER"
                                    ? true
                                    : false
                                }
                                onChange={handleModeChange}
                                disabled={
                                  !useCases?.useCases?.["Asset Configuration"]?.includes("FUEL_SAVER")
                                }
                              />
                              <label
                                className="custom-control-label line-height-25"
                                htmlFor="customRadio6"
                              >
                                Genset main, BESS grid tied
                              </label>
                              <Tooltip
                                position="right"
                                iconColor="secondary"
                                iconSize="small"
                                information={
                                  informationToolTIP.fuelSaverTooltipData
                                }
                                disabled={
                                  !useCases?.useCases?.["Asset Configuration"]?.includes("FUEL_SAVER")
                                }
                              />
                            </div>
                          </div>
                        </div>

                        {(
                          <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1 ">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  id="customRadio7"
                                  name="mode"
                                  className="custom-control-input islandableRadio asCompatible"
                                  value="GENSET_MAIN_NO_BESS"
                                  checked={
                                    gridSettings.mode === "GENSET_MAIN_NO_BESS"
                                      ? false
                                      : projectType ===
                                          embLabels.GREENSTRUXURE_MICROGRID &&
                                        siteType === embLabels.ISLANDABLE_GSX &&
                                        architectureType ===
                                          embLabels.PV_GENSET_ATS
                                      ? false
                                      : embLabels.STANDARD_MICROGRID &&
                                        siteType === embLabels.ISLANDABLE
                                      ? false
                                      : true
                                  }
                                  disabled={
                                    !useCases?.useCases?.["Asset Configuration"]?.includes("GENSET_MAIN_NO_BESS")
                                  }
                                  style={
                                    (projectType ===
                                      embLabels.GREENSTRUXURE_MICROGRID &&
                                      siteType === embLabels.ISLANDABLE_GSX &&
                                      architectureType ===
                                        embLabels.PV_GENSET_ATS) ||
                                    (embLabels.STANDARD_MICROGRID &&
                                      siteType === embLabels.ISLANDABLE)
                                      ? { cursor: "not-allowed" }
                                      : { cursor: "pointer" }
                                  }
                                  onChange={handleModeChange}
                                />
                                <label
                                  className="custom-control-label line-height-25"
                                  htmlFor="customRadio7"
                                >
                                  Genset main, No BESS
                                </label>
                                <span className="se-block-enabled">
                                  <se-tooltip
                                    action={isTouchDevice() ? "click" : "hover"}
                                    position="right"
                                  >
                                    <se-icon
                                      option="raised"
                                      color="secondary"
                                      slot="tooltip"
                                      disabled={
                                        !useCases?.useCases?.["Asset Configuration"]?.includes("GENSET_MAIN_NO_BESS")
                                      }
                                    >
                                      information_circle
                                    </se-icon>
                                    <se-tooltip-content>
                                      {informationToolTIP.fuelNoBessToolTipData}
                                    </se-tooltip-content>
                                  </se-tooltip>
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

              {[
                embLabels.STANDARD_MICROGRID,
                embLabels.GREENSTRUXURE_MICROGRID,
              ].includes(projectType) &&
                [
                  embLabels.ISLANDABLE,
                  embLabels.OFF_GRID,
                  embLabels.ISLANDABLE_GSX,
                ].includes(siteType) && <TransitionType />}

              {siteType === embLabels.OFF_GRID && (
                <div className="col-sm-4 se-block-disabled mt-6">
                  <div className="blur mt-5">
                    <span>Coming Soon</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </MyErrorBoundary>
  );
};

export default SelectUseCase;
