import ACTIONS from "../Actions/projectKind.json";
import {
  LOAD_SIDE,
  LINE_SIDE,
  NEW_SWICTHBOARD,
  EXISTING_SWICTHBOARD,
  MGAAS_ANSI,
  MGaaS_A2E,
  MGaaS_IEC,
  DAAS_ANSI,
  DAAS_IEC,
  GRID_CONNECTED,
  GRID_CONNECTED_GSX,
  ISLANDABLE,
  ISLANDABLE_GSX,
  GREENSTRUXURE,
  PV_GENSET_ATS,
  PV_BESS_NR,
  PV_HYBRIDIZATION,
  GENSET_PVBESS_A2E,
  NO_FUEL,
  GENERIC,
  GREENSTRUXURE_MICROGRID,
  FUEL_SAVER,
  GENSET_MAIN_NO_BESS,
} from "../../../utils/application.constants";
import { getProjectTypeValue } from "../../../utils/project";
const initialTypes = {
  isMgaasAnsi: false,
  isDaasAnsi: false,
  isMgaasIec: false,
  isDaasIec: false,
  isMgaasAnsiGrid: false,
  isDaasAnsiGrid: false,
  isMgaasAnsiIsland: false,
  isDaasAnsiIsland: false,
  isMgaasIecGrid: false,
  isDaasIecGrid: false,
  isMgaasIecIsland: false,
  isGridConnected: false,
  isGridConnectedGSX: false,
  isIslandable: false,
  isIslandableGSX: false,
  isA2E: false,
  isLineSide: false,
  isExistingSwitchBoard: false,
  isLoadSide: false,
  isNewSwitchBoard: false,
  isGreenStruxure: false,
  isGeneric: false,
  isGreenStruxureMicrogrid: false,
  isPVGensetATS: false,
  isFuelSaver: false,
  isPVBessNR: false,
  isPVHybrid: false,
  isPVGensetA2E: false,
  isGensetMainNoBess: false,
  isDaasIecIsland: false,
  isNoFuel: false,
  gridSettings: {},
  defaultValues: {},
  defaults: {}
};

const projectKindReducer = (state, action) => {
  let {
    connectionType,
    siteType,
    switchboardType,
    clientType,
    architecture,
    gridSettings: { mode = "" } = {},
    gridSettings = {},
    defaultValues = {},
    defaults = {},
  } = action.payload;
  let projectTypeValue = getProjectTypeValue();
  switch (action.type) {
    case ACTIONS.UPDATE_PROJECT_KIND:
      return {
        ...state,
        isMgaasAnsi: projectTypeValue === MGAAS_ANSI || false,
        isDaasAnsi: projectTypeValue === DAAS_ANSI || false,
        isMgaasIec: projectTypeValue === MGaaS_IEC || false,
        isDaasIec: projectTypeValue === DAAS_IEC || false,
        isDaasIecIsland: (projectTypeValue === DAAS_IEC && siteType === ISLANDABLE_GSX )|| false,
        isMgaasAnsiGrid:
          (projectTypeValue === MGAAS_ANSI && siteType === GRID_CONNECTED) ||
          false,
        isDaasAnsiGrid:
          (projectTypeValue === DAAS_ANSI && siteType === GRID_CONNECTED_GSX) ||
          false,
        isMgaasAnsiIsland:
          (projectTypeValue === MGAAS_ANSI && siteType === ISLANDABLE) || false,
        isDaasAnsiIsland:
          (projectTypeValue === DAAS_ANSI && siteType === ISLANDABLE_GSX) ||
          false,
        isMgaasIecGrid:
          (projectTypeValue === MGaaS_IEC && siteType === GRID_CONNECTED) ||
          false,
        isDaasIecGrid:
          (projectTypeValue === DAAS_IEC && siteType === GRID_CONNECTED_GSX) ||
          false,
        isMgaasIecIsland:
          (projectTypeValue === MGaaS_IEC && siteType === ISLANDABLE) || false,
        isGridConnected: siteType === GRID_CONNECTED || false,
        isGridConnectedGSX: siteType === GRID_CONNECTED_GSX || false,
        isIslandable: siteType === ISLANDABLE || false,
        isIslandableGSX: siteType === ISLANDABLE_GSX || false,
        isA2E: projectTypeValue === MGaaS_A2E || false,
        isLineSide: connectionType === LINE_SIDE || false,
        isNewSwitchBoard: switchboardType === NEW_SWICTHBOARD || false,
        isLoadSide: connectionType === LOAD_SIDE || false,
        isExistingSwitchBoard:
          switchboardType === EXISTING_SWICTHBOARD || false,
      };
    case ACTIONS.RESET_PROJECT_KIND:
        return initialTypes
    case ACTIONS.UPDATE_DEFAULT_PROJECT_KIND:
        return {
            ...state,
            isGreenStruxure: clientType === GREENSTRUXURE || false,
            isGeneric: clientType === GENERIC || false,
            isGreenStruxureMicrogrid: clientType === GREENSTRUXURE_MICROGRID || false,
            isPVGensetATS: architecture === PV_GENSET_ATS || false,
            isFuelSaver: mode === FUEL_SAVER || false,
            isPVBessNR: architecture === PV_BESS_NR ||  false,
            isPVHybrid: siteType === PV_HYBRIDIZATION ||  false,
            isPVGensetA2E: siteType === GENSET_PVBESS_A2E || false,
            isGensetMainNoBess: mode === GENSET_MAIN_NO_BESS || false,
            isNoFuel: mode === NO_FUEL || false,
            gridSettings: gridSettings || {},
            defaultValues: defaultValues || {},
            defaults: defaults || {}
        }
    default:
      return state;
  }
};

export { initialTypes, projectKindReducer };
