const ProjectConst = {
  Create_Project: "Create Project",
  Please_Wait: "Please wait ... Loading Projects",
  steps: [
    {
      title: "Projects",
      description:
        "In Projects page user can click on Create Project to start a new project.",
    },
    {
      title: "Information",
      description:
        "User can enter the project details, select the use case, Site Information details and Address required for the project and ensure to validate the project to proceed to design page, to configure DER’s.",
    },
    {
      title: "Configure DER’s",
      description:
        "User can configure the DER’s based on the use case selected in the Information page, and user would view the pre populated SLD design on the basis of use case selected under information page while creating a project, and here you can design SLD as per your need,  Maximum 12 CB’s can be added and 15 PV’s can be distributed among 3 CB’s. Until and unless all DER’s are configured user will not be allowed to proceed further to configure the usecase.",
    },
    {
      title: "Configure Use Cases",
      description:
        "Here you can configure the usecase for on grid optimization & off grid management, these usecase listed here are on the basis of your selection done under information page while creating a project, any usecase which are not selected or not used will be part of disabled use cases, here user can modify the parameters of each usecase and need to validate the usecase so that he new parameters are applied accordingly, without validating Configure use cases user will not be able to proceed further.",
    },
    {
      title: "System",
      description:
        "System page consist of Circuit breaker Communication settings, DER  Communication settings, & Load & EV Communication settings, these settings are pre-configured by default user can modify as per his system/network settings.",
    },
    {
      title: "BOM",
      description:
        "BOM page consist of EMA and EMO-M license displayed on the basis of your use case selected.",
    },
    {
      title: "Deploy",
      description:
        "User need to enter EMA and EMO-M license and unlock deployment for using below mentioned services,",
      type: "form", // Have an embedded form
      fields: [
        {
          label: "EcoStruxure Microgrid Advisor cloud services",
          type: "hidden",
        },
        {
          label: "EcoStruxure Microgrid Advisor Controller (Harmony iPC)",
          type: "hidden",
        },
        {
          label:
            "EcoStruxure Microgrid Operation - Medium Controller (M251 PLC)",
          type: "hidden",
        },
        {
          label: "EcoStruxure Microgrid Operation - Medium HMI (Magelis HMI)",
          type: "hidden",
        },
      ],
    },
  ],
  defaultProject: {
    region: "ANSI",
    application: "Buildings",
    energyManagementSystem: "Autogrid Flex",
  },
  Not_Authorized: "Not Authorized",
  Error_copy: "Error in copying the project",
  Error_delete: "Error in deletion of project",
};
const informationConst ={
 IMG_SUCCESS:"Project image uploaded successfully",
 IMG_ERROR:"Error in uploading project image",
 IMG_DELETE_ASK:"Do you confirm to delete project image ?",
 IMG_DELETE_SUCCESS:"Project image deleted successfully",
 IMG_DELETE_ERROR:"Error in deletion project image",
 A2E_PROJECT:"A2E_PROJECT",
 PROJECT_CHANGE_ALERT:"Project change will affect asset's design and configuration !",
 USE_CASE_CHANGE_ALERT:"Use case change will affect asset's design and configuration !",
 USER_ERROR:"Error in loading user roles",
 USER_EXISTS_ERROR:"User is already added in the project",
 USER_INVITED:"User invited  to the project.",
 USER_INVITED_ERROR:"Error in inviting to the project.",
 USER_NOT_AUTHORIZED: "You are not authorized to invite any user, as you are not part of this project. Please contact Admin.",
 YOUR_NOT_AUTHORIZED:"You are not authorized to delete any user, as you are not part of this project. Please contact Admin.",
 USER_UPDATE:"User invited  to the project, Click validate to update poject.",
 USER_REMOVED:"User is removed from the project.",
 USER_REMOVED_ERROR:"Error in removing the user from the project",
 RE_INVITE:"Successfully  Re invited to the project.",
 RE_INVITE_ERROR:"Error in Re inviting to the project.",
} 
const shellLayoutConst ={
 PROJECT_ASSET_ALERT:`Please create project and then visit "Asset" page.`,
 PROJECT_USE_CASE_ALERT:`Please create project and then visit "Configure Use Cases" page.`,
 PROJECT_SYSTEM_ALERT:`Please create project and then visit "Systems" page.`,
 PROJECT_BOM_ALERT:`Please create project and then visit "BOM" page.`,
 PROJECT_DEPLOY_ALERT:`Please create project and then visit "Deploy" page.`,
 VALIDATE_USE_CASE_ALERT:"Please validate Configure Use Cases to proceed further.",
 VALIDATE_SYSTEMS_ALERT:"Please validate communication settings to proceed further.",
 VALIDATE_DER_ALERT:"Please configure the asset/assets to proceed further.",
 VALIDATE_TIMESYNC_ALERT:"Please save Time Synchronization settings to proceed further.",
}
export { ProjectConst,informationConst, shellLayoutConst };
