import React, { useState } from "react";
import PropTypes from "prop-types";

import AccordionSection from "./AccordionSection";
import "./Accordion.scss";
import MyErrorBoundary from "../../utils/ErrorBoundary";

const Accordion = props => {
  const [openSections, setOpenSections] = useState({});

  const onClickHandle = label => {
    const { allowMultipleOpen } = props;
    const isOpen = !!openSections[label];

    if (allowMultipleOpen) {
      setOpenSections(prevState => ({
        ...prevState,
        [label]: !isOpen,
      }));
    } else {
      setOpenSections({ [label]: !isOpen });
    }
  };

  return (
    <MyErrorBoundary>

    <div className="col-md-12 p-0">
      {props?.children?.map(child => (
        <AccordionSection
          key={child?.props?.label}
          isOpen={!!openSections[child?.props?.label]}
          label={child?.props?.label}
          icon={child?.props?.icon}
          displayStatus={child?.props?.displayStatus}
          status={child?.props?.status}
          onClick={onClickHandle}
          innerClick={child?.props?.enableTemplatePopUp}
        >
          {child?.props?.children? child?.props?.children:""}
        </AccordionSection>
      ))}
    </div>
    </MyErrorBoundary>
  );
};

export default Accordion;

Accordion.propTypes = {
  allowMultipleOpen: PropTypes.bool,
  children: PropTypes.instanceOf(Object),
};
