import React, { useContext } from "react";

import Accordion from "../../components/Accordions/Accordion";
import LoadShedding from "./LoadShedding";
import BessManagement from "./BessManagement";
import TransitionManagement from "./TransitionManagement";
import GensetOptimization from "./GensetOptimization";
import BessChargingLimitation from "./BessChargingLimitation";
import BESSHibernating from "./BESSHibernating";

import { DesignProgramContext } from "../CustomHooks/ContextApi";

import embLabels from "../../config/emb-labels-en.json";

const OffGridManagement = () => {
  const {
    siteType,
    configuredStatus,
    batteryDetails,
    gensetDetails,
    gridSettings,
    clientType,
    architectureType,
    siteCreated,
    projectStatusData,
    UserScope
  } = useContext(DesignProgramContext);

  return (
    <>
    {
     ( siteType === embLabels.ISLANDABLE ||
      siteType === embLabels.PV_HYBRIDIZATION ||
      siteType === embLabels.ISLANDABLE_GSX ||
      siteType === embLabels.GRID_CONNECTED_GSX ||
      siteType === embLabels.GENSET_PVBESS_A2E) &&
      <div className={`row se-white-background pb-2 ${!UserScope.access.saveProgram
          ? "se-block-disabled"
          : ""
      }`}>
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-2 ">
        <span className="se-font-14px-bold se-life-green ">
          Islanding
        </span>

        <div className="row ">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            {siteType === embLabels.ISLANDABLE ||
            siteType === embLabels.PV_HYBRIDIZATION ||
            siteType === embLabels.ISLANDABLE_GSX ||
            siteType === embLabels.GRID_CONNECTED_GSX ||
            siteType === embLabels.GENSET_PVBESS_A2E ? (
              <>
                {clientType === embLabels.GREENSTRUXURE_PROJECT &&
                siteType === embLabels.ISLANDABLE_GSX &&
                architectureType === embLabels.PV_GENSET_ATS &&
                gridSettings.mode === "FUEL_SAVER" ? (
                  <Accordion allowMultipleOpen>
                    <div
                      className="col-md-12"
                      label="Select off grid management"
                      status={configuredStatus.lsConfiguredStatus}
                      displayStatus={
                        [embLabels.GRID_CONNECTED_GSX].includes(siteType)
                          ? "1"
                          : "0"
                      }
                      icon="notification_error_full_stroke"
                    >
                     <div className={`${
                              siteCreated || projectStatusData?.projectStatus === "COMPLETED"
                                ? "se-block-disabled not-allowed"
                                : !UserScope.access.saveProgram
                                ? "se-block-disabled"
                                : ""
                            }`}>
                     <BessManagement />
                     </div>
                    </div>

                    <div
                      className="col-md-12"
                      label="Transition Management"
                      status={configuredStatus.tmConfiguredStatus}
                      displayStatus={
                        [
                          embLabels.ISLANDABLE_GSX,
                          embLabels.ISLANDABLE,
                        ].includes(siteType)
                          ? "1"
                          : "0"
                      }
                      icon="notification_error_full_stroke"
                    >
                      <div className={`${
                              siteCreated || projectStatusData?.projectStatus === "COMPLETED"
                                ? "se-block-disabled not-allowed"
                                : !UserScope.access.saveProgram
                                ? "se-block-disabled"
                                : ""
                            }`}>
                              <TransitionManagement />
                            </div>
                      
                    </div>

                    {gensetDetails.gensetAvailabilityStatus ? (
                      <div
                        className="col-md-12"
                        label="Genset Optimization & Backfeed Protection"
                        status={configuredStatus.gofpConfiguredStatus}
                        displayStatus="1"
                        icon="notification_error_full_stroke"
                      >
                        <div className={`${
                              siteCreated || projectStatusData?.projectStatus === "COMPLETED"
                                ? "se-block-disabled not-allowed"
                                : !UserScope.access.saveProgram
                                ? "se-block-disabled"
                                : ""
                            }`}>
                              <GensetOptimization />
                            </div>
                        
                      </div>
                    ) : (
                      <div
                        className="col-md-12"
                        label="dummy Genset optimization & backfeed protection"
                        status={1}
                        displayStatus="0"
                        icon="notification_error_full_stroke"
                      ></div>
                    )}

                    {batteryDetails.batteryBufferingStatus ||
                    gridSettings.mode === "FUEL_SAVER" ? (
                      <div
                        className="col-md-12"
                        label="BESS Charging Limitation"
                        status={configuredStatus.bclConfiguredStatus}
                        displayStatus={
                          [
                            embLabels.ISLANDABLE_GSX,
                            embLabels.ISLANDABLE,
                          ].includes(siteType)
                            ? "1"
                            : "0"
                        }
                        icon="notification_error_full_stroke"
                      >
                        <div className={`${
                              siteCreated || projectStatusData?.projectStatus === "COMPLETED"
                                ? "se-block-disabled not-allowed"
                                : !UserScope.access.saveProgram
                                ? "se-block-disabled"
                                : ""
                            }`}>
                              <BessChargingLimitation />
                            </div>
                        
                      </div>
                    ) : (
                      <div
                        className="col-md-12"
                        label="dummy BESS charging limitation"
                        status={1}
                        displayStatus="0"
                        icon="notification_error_full_stroke"
                      ></div>
                    )}

                    <div
                      className="col-md-12"
                      label="Load Shedding"
                      status={configuredStatus.lsConfiguredStatus}
                      displayStatus={
                        [
                          embLabels.ISLANDABLE,
                          embLabels.ISLANDABLE_GSX,
                        ].includes(siteType)
                          ? "1"
                          : "0"
                      }
                      icon="notification_error_full_stroke"
                    >
                      <se-divider></se-divider>
                      <div className={`${
                              siteCreated || projectStatusData?.projectStatus === "COMPLETED"
                                ? "se-block-disabled not-allowed"
                                : !UserScope.access.saveProgram
                                ? "se-block-disabled"
                                : ""
                            }`}>
                               <LoadShedding />
                            </div>
                    </div>

                    <div
                      className="col-md-12"
                      label="dummy offgrid"
                      status={1}
                      displayStatus="0"
                      icon="notification_error_full_stroke"
                    ></div>
                  </Accordion>
                ) : (
                  <>
                    <Accordion allowMultipleOpen>
                      <div
                        className="col-md-12"
                        label="Select off grid management"
                        status={configuredStatus.lsConfiguredStatus}
                        displayStatus={
                          [embLabels.GRID_CONNECTED_GSX].includes(siteType)
                            ? "1"
                            : "0"
                        }
                        icon="notification_error_full_stroke"
                      >
                        <div className={`${
                              siteCreated || projectStatusData?.projectStatus === "COMPLETED"
                                ? "se-block-disabled not-allowed"
                                : !UserScope.access.saveProgram
                                ? "se-block-disabled"
                                : ""
                            }`}>
                               <BessManagement />
                            </div>
                       
                      </div>
                      <div
                        className="col-md-12"
                        label="Load Shedding"
                        status={configuredStatus.lsConfiguredStatus}
                        displayStatus={
                          [embLabels.ISLANDABLE].includes(siteType) ? "1" : "0"
                        }
                        icon="notification_error_full_stroke"
                      >
                        <se-divider></se-divider>
                        <div className={`${
                              siteCreated || projectStatusData?.projectStatus === "COMPLETED"
                                ? "se-block-disabled not-allowed"
                                : !UserScope.access.saveProgram
                                ? "se-block-disabled"
                                : ""
                            }`}> <LoadShedding /></div>
                       
                      </div>

                      {batteryDetails.batteryBufferingStatus ? (
                        <div
                          className="col-md-12"
                          label="BESS Management"
                          status={configuredStatus.configuredBlocks}
                          displayStatus="0"
                          icon="notification_error_full_stroke"
                        >
                          <div className={`${
                              siteCreated || projectStatusData?.projectStatus === "COMPLETED"
                                ? "se-block-disabled not-allowed"
                                : !UserScope.access.saveProgram
                                ? "se-block-disabled"
                                : ""
                            }`}>
                               <BessManagement />
                            </div>
                         
                        </div>
                      ) : (
                        <div
                          className="col-md-12"
                          label="dummy bess management"
                          status={1}
                          displayStatus="0"
                          icon="notification_error_full_stroke"
                        ></div>
                      )}

                      <div
                        className="col-md-12"
                        label="Transition Management"
                        status={configuredStatus.tmConfiguredStatus}
                        displayStatus={
                          siteType === embLabels.ISLANDABLE ? "1" : "0"
                        }
                        icon="notification_error_full_stroke"
                      >
                        <div className={`${
                              siteCreated || projectStatusData?.projectStatus === "COMPLETED"
                                ? "se-block-disabled not-allowed"
                                : !UserScope.access.saveProgram
                                ? "se-block-disabled"
                                : ""
                            }`}>
                              <TransitionManagement />
                            </div>
                        
                      </div>

                      {gensetDetails.gensetAvailabilityStatus ? (
                        <div
                          className="col-md-12"
                          label="Genset Optimization & Backfeed Protection"
                          status={configuredStatus.gofpConfiguredStatus}
                          displayStatus="1"
                          icon="notification_error_full_stroke"
                        >
                           <div className={`${
                              siteCreated || projectStatusData?.projectStatus === "COMPLETED"
                                ? "se-block-disabled not-allowed"
                                : !UserScope.access.saveProgram
                                ? "se-block-disabled"
                                : ""
                            }`}>
                              <GensetOptimization />
                            </div>
                          
                        </div>
                      ) : (
                        <div
                          className="col-md-12"
                          label="dummy Genset optimization & backfeed protection"
                          status={1}
                          displayStatus="0"
                          icon="notification_error_full_stroke"
                        ></div>
                      )}

                      {batteryDetails.batteryBufferingStatus ? (
                        <div
                          className="col-md-12"
                          label="BESS Charging Limitation"
                          status={configuredStatus.bclConfiguredStatus}
                          displayStatus={
                            siteType === embLabels.ISLANDABLE ? "1" : "0"
                          }
                          icon="notification_error_full_stroke"
                        >
                          <div className={`${
                              siteCreated || projectStatusData?.projectStatus === "COMPLETED"
                                ? "se-block-disabled not-allowed"
                                : !UserScope.access.saveProgram
                                ? "se-block-disabled"
                                : ""
                            }`}>
                               <BessChargingLimitation />
                            </div>
                         
                        </div>
                      ) : (
                        <div
                          className="col-md-12"
                          label="dummy BESS charging limitation"
                          status={1}
                          displayStatus="0"
                          icon="notification_error_full_stroke"
                        ></div>
                      )}

                      {gridSettings.mode === "FUEL_ERASER" ||
                      gridSettings.mode === "NO_FUEL" ? (
                        <div
                          className="col-md-12"
                          label="BESS Hibernating"
                          status={configuredStatus.bhConfiguredStatus}
                          displayStatus={
                            siteType === embLabels.ISLANDABLE ? "1" : "0"
                          }
                          icon="notification_error_full_stroke"
                        >
                          <div className={`${
                              siteCreated || projectStatusData?.projectStatus === "COMPLETED"
                                ? "se-block-disabled not-allowed"
                                : !UserScope.access.saveProgram
                                ? "se-block-disabled"
                                : ""
                            }`}>
                              <BESSHibernating />
                            </div>
                          
                        </div>
                      ) : (
                        <div
                          className="col-md-12"
                          label="dummy"
                          status={1}
                          displayStatus="0"
                          icon="notification_error_full_stroke"
                        ></div>
                      )}

                      <div
                        className="col-md-12"
                        label="dummy offgrid"
                        status={1}
                        displayStatus="0"
                        icon="notification_error_full_stroke"
                      ></div>
                    </Accordion>
                  </>
                )}
              </>
            ) : siteType === embLabels.GRID_CONNECTED ||
              siteType === embLabels.GRID_CONNECTED_GSX ? (
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="row">
                  <label className="col-md-7 col-form-label pl-5">
                    No off-grid management usecase
                  </label>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
    }
    </>
    
  );
};

export default OffGridManagement;
