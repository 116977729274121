import React, { useContext } from "react";

import sld from "../../images/sld/Rectangle.svg";

import { DesignAssetContext } from "../CustomHooks/ContextApi";
import Ecc from "../../components/GensetPvBess/Ecc";
import Busbar from "../../components/GensetPvBess/Busbar";
import Linebar from "../../components/GensetPvBess/Linebar";
import Utility from "../../components/GensetPvBess/Utility";
import Battery from "../../components/GensetPvBess/Battery";
import PV1 from "../../components/GensetPvBess/PV1";
import Genset from "../../components/GensetPvBess/Genset";

import PV2 from "../../components/GensetPvBess/PV2";

import Load from "../../components/GensetPvBess/Load";
import embLabels from "../../config/emb-labels-en.json";
import PVExsisting from "../../components/GensetPvBess/PVExsisting";
import BatteryExsisting from "../../components/GensetPvBess/BatteryExsisting";
import PV3 from "../../components/GensetPvBess/PV3";
import Ev from "../../components/ISLANDABLE/Ev";
import { uniqueId } from "lodash";
import RemoteLoad from "../../components/GensetPvBess/RemoteLoad";
import LoadNA from "../../components/GensetPvBess/LoadNA";

const GensetPvBessSLD = () => {
  const {
    CBLoadList,
    CBAssetList,
    sldLoadCompleted,
    CBEVList,
    siteType,
    architectureType,
    switchboardType,
    loadSide,
    remoteMain,
    coordinates: { busbarCordinates },
  } = useContext(DesignAssetContext);
  let loadIndex = 0;
  let EccIndex = 0;
  const checkIndex = () => CBAssetList.findIndex(a =>  (a.assetType === "Inverter" && a.uiData.status === true));
  return (
    <div
      id="SLD-Image"
      className="col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0"
    >
      {["ISLANDABLE_GSX", "ISLANDABLE"].includes(siteType) &&
      (switchboardType === "" ||
        switchboardType === embLabels.EXISTING_SWITCHBOARD) ? (
        <svg viewBox="0 0 1400 900">
          <g>
            <svg
              x={loadSide === "LOAD_SIDE" ? busbarCordinates[`svgx`] : 0}
              y={loadSide === "LOAD_SIDE" ? busbarCordinates[`svgy`] : 0}
              viewBox="0 0 1400 900"
            >
              <g id="grid">
                <svg>
                  <image href={sld} x="0" y="0" />
                </svg>
              </g>

              <g>
                <svg viewBox="0 0 1400 900">
                  <g
                    id="components-sld"
                    style={sldLoadCompleted ? {} : { display: "none" }}
                  >
                    <Ecc />
                    <Busbar secondBusbar={true} />
                    <Linebar />
                    <svg x="0" y={"0"}>
                      <Utility />
                    </svg>
                    <svg x="0" y={loadSide === "LINE_SIDE" ? "0" : "50"}>
                      <Battery />
                      {CBAssetList[1].uiData.status && <Genset />}
                      {CBAssetList[3].uiData.status && <PV1 isFirst={checkIndex() === 3}/>}
                      {CBAssetList[4].uiData.status && <PV2 isFirst={checkIndex() === 4}/>}
                      {CBAssetList[5].uiData.status && <PV3 isFirst={checkIndex() === 5}/>}
                      {CBAssetList[3].uiData.existingPmRequired && (
                        <PVExsisting />
                      )}
                      {CBAssetList[2].uiData.existingPmRequired && (
                        <BatteryExsisting />
                      )}

                      {switchboardType === "" ||
                      switchboardType === embLabels.EXISTING_SWITCHBOARD ? (
                        <>
                          <svg className="Guo" x="0" y="0">
                            {CBLoadList.map((loadData, key) => {
                              loadData.uiData.isRemoteLoad && loadIndex++;
                              return (
                                loadData.uiData.isRemoteLoad && (
                                  <RemoteLoad
                                    key={uniqueId(loadData.configData.name)}
                                    load={loadData.uiData}
                                    loadName={loadData.configData.name}
                                    loadCbName={loadData.cbConfigData.name}
                                    index={key}
                                    loadIndex={loadIndex}
                                    remoteLoad={loadData.uiData.isRemoteLoad}
                                  />
                                )
                              );
                            })}
                          </svg>
                          {CBLoadList.map((loadData, key) => {
                              !loadData.uiData.isRemoteLoad && EccIndex++;
                              return (
                                !loadData.uiData.isRemoteLoad && (
                                  <LoadNA
                                    key={uniqueId(loadData.configData.name)}
                                    load={loadData.uiData}
                                    loadName={loadData.configData.name}
                                    loadCbName={loadData.cbConfigData.name}
                                    index={key}
                                    EccIndex={EccIndex}
                                    remoteLoad={loadData.uiData.isRemoteLoad}
                                  />
                                )
                              );
                            })}
                        </>
                      ) : (
                        <></>
                      )}

                      {CBEVList.map(
                        (cbData, key) =>
                          cbData?.uiData?.status && (
                            <Ev
                              key={cbData?.configData?.name}
                              index={key}
                              status={cbData?.uiData?.status}
                            />
                          )
                      )}
                    </svg>
                  </g>
                </svg>
              </g>
            </svg>
          </g>
        </svg>
      ) : (
        <svg viewBox="0 0 1300 800">
          <g>
            <svg x="0" y="0" viewBox="0 0 1300 800">
              <g id="grid">
                <svg>
                  <image href={sld} x="0" y="0" />
                </svg>
              </g>

              <g>
                <svg viewBox="-75 -10 1300 800">
                  <g
                    id="components-sld"
                    style={sldLoadCompleted ? {} : { display: "none" }}
                  >
                    <Ecc />
                    <Busbar secondBusbar={true} />

                    <Utility />
                    <Battery />
                    {CBAssetList[1].uiData.status && <Genset />}
                    {CBAssetList[3].uiData.status && <PV1 />}
                    {CBAssetList[4].uiData.status && <PV2 />}
                    {CBAssetList[5].uiData.status && <PV3 />}

                    {CBLoadList.map((loadData, key) => (
                      <Load
                      key={uniqueId(loadData.configData.name)}
                        load={loadData.uiData}
                        loadName={loadData.configData.name}
                        loadCbName={loadData.cbConfigData.name}
                        index={key}
                      />
                    ))}
                    {CBEVList.map(
                      (cbData, key) =>
                        cbData?.uiData?.status && (
                          <Ev
                            key={cbData?.configData?.name}
                            index={key}
                            status={cbData?.uiData?.status}
                          />
                        )
                    )}
                  </g>
                </svg>
              </g>
            </svg>
          </g>
        </svg>
      )}
    </div>
  );
};

export default GensetPvBessSLD;
