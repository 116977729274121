import { createSlice } from "@reduxjs/toolkit";
import { call, put, takeLatest } from 'redux-saga/effects'
const initialState = {
    projectType: "",
    projectName:'',
}
export const projectTypeInfoSlice = createSlice({
    name: 'projectType',
    initialState,
    reducers: {
        upDateProjectType: (state, action) => {
            return {
                ...state,
                projectType:action.payload?.projectType,
                projectName:action.payload?.projectName
            }
        },
        getProjectType: (state, action) => {
            state.projectType = action.payload;
        },

    }
});
export const {
    getProjectType,
    upDateProjectType,
} = projectTypeInfoSlice.actions
export function* addProjectTypeSaga(ptype) {
    yield put(projectTypeInfoSlice.actions.upDateProjectType(ptype))
}
export function* getProjectTypeSaga() {
    yield put(projectTypeInfoSlice.actions.getProjectType(localStorage.getItem("pType")))
}
export function* watchAddProjectType() {
    yield takeLatest(getProjectType.type, addProjectTypeSaga);
}
export function* watchGetProjectType() {
    yield takeLatest(getProjectType.type, getProjectTypeSaga)
}
export default projectTypeInfoSlice; 
