import React, { useState, useRef, useEffect, useCallback } from "react";
import ProjectsAccordion from "../../components/Accordions/ProjectsAccordion";
import ApiSession from "../../api/ApiSession";
import Snackbar from "../SE-Component/Snackbar";
import moment from "moment";

const apiSession = new ApiSession();
const UploadPages = ({ UserScope, documentList }) => {
  const [pageType, setPageType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedProjectType, setSelectedProjectType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const inputRefHmi = useRef(null);
  const [versionList, setVersionList] = useState([]);
  const [versions, setVersions] = useState("");
  const [snackBar, setSnackBar] = useState({ type: "", message: "" });
  const snackBarRef = useRef(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletingFile, setDeletingFile] = useState(null);

  const handleItem = (data, projectType) => {
    setOpenModal(true);
    setSelectedProjectType(projectType);
    setVersions(data?.version);
    setPageType(data?.page);
    setSelectedItem(data);
  };

  let projectTypeFormat = {
    "DaaS ANSI": [],
    "DaaS IEC": [],
    "MGaaS ANSI": [],
    "MGaaS IEC": [],
    "MGaaS AccessToEnergy": [],
  };
  const [docList, setDocList] = useState(projectTypeFormat);
  const normalizeDocList = (documentList) => {
    for (let x in projectTypeFormat) {
      documentList?.documentBlobInfo.forEach((p) => {
        if (x === p.projectType) {
          projectTypeFormat[x].push(p);
        }
      });
    }
    return projectTypeFormat;
  };
  const callSnackbar = useCallback((type, message) => {
    try {
      setSnackBar({ type, message });
      snackBarRef.current.open = true
    } catch (error) {
      console.log("error in snack bar")
    }
  }, []);
  useEffect(() => {
    if (documentList.documentBlobInfo?.length > 0) {
      const normalizedList = normalizeDocList(documentList);
      setDocList(normalizedList);
    }
    setVersionList(documentList?.versions || []);
  }, [documentList]);
  const handleAddItem = (pType) => {
    setSelectedItem({});
    setVersions("");
    setSelectedProjectType(pType);
    setOpenModal(true);
  };

  const closeModal = () => {
    setLoading(false);
    setOpenModal(false);
    setPageType("");
    setVersions("");
    setSelectedProjectType(null);
    setSelectedFile(null);
    inputRefHmi.current.value = null;
  };
  const handlePageChange = ({ target: { value } }) => {
    setPageType(value);
  };
  const handleVersionChange = ({ target: { value } }) => {
    setVersions(value);
  };
  const fileChangePageType = (event) => {
    const { files } = event.target;
    if (files.length) {
      setSelectedFile(files[0]);

      let reader = new FileReader();
      reader.onloadend = () => {};
      reader.readAsDataURL(files[0]);
    }
  };
  const handleDelete = () => {
    setLoading(true);
    try {
      apiSession
      .deleteDocumentPage(deletingFile?.id)
      .then((res) => {
        callSnackbar("success", "Document Deleted");
        getAllDocuments();
        setLoading(false);
        setDeletingFile(null);
        setDeleteModal(false);
      })
      .catch(() => {
        callSnackbar("error", "Document Failed to Delete");
        setLoading(false);
      });
    } catch (error) {
      console.log(error)
    }
  };
  const getAllDocuments = () => {
    setLoading(true);

    apiSession
      .listAllDocuments()
      .then((response) => {
        const normalizedList = normalizeDocList(response);
        setDocList(normalizedList);
        setOpenModal(false);
        setLoading(false);
        setSelectedFile(null);
        setPageType("");
        inputRefHmi.current.value = null;
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const updateDocument = () => {
    let fd = new FormData();
    fd.append("file", selectedFile);
    fd.append("documentName", selectedFile?.name);
    fd.append("version", versions);
    fd.append("page", pageType);
    fd.append("projectType", selectedProjectType);
    setLoading(true);
    apiSession
      .uploadPageDocument(fd)
      .then((res) => {
        getAllDocuments();
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          callSnackbar("error", error.response.data.message );
        } else {
          callSnackbar("error", "An error occurred while uploading the document.");
        }
       
        setLoading(false);
      });
  };
  const closeDeleteModal = () => {
    setDeletingFile(null);
    setDeleteModal(false);
  };
  return (
    <>
      <div className={loading ? "loader__center" : ""}>
        <se-loading loading={loading}></se-loading>
      </div>

      {
        <se-dialog
          id="dialog-complex-delete-confirm"
          open={deleteModal}
          can-backdrop="false"
        >
          <se-dialog-header>Confirmation</se-dialog-header>
          <se-dialog-content>
            <span className="se-font-14px">
              Do you confirm to delete selected Document ?
            </span>
          </se-dialog-content>
          <se-dialog-footer>
            <se-button disabled={loading} onClick={handleDelete}>
              Yes
            </se-button>
            <se-button onClick={closeDeleteModal}>No</se-button>
          </se-dialog-footer>
        </se-dialog>
      }

      <ProjectsAccordion label="Upload Support Documents">
        {Object.keys(projectTypeFormat)?.map((projectType, index) => {
          return (
            <ProjectsAccordion
              key={index}
              label={projectType}
              addItem
              handleAddItem={() => handleAddItem(projectType)}
            >
              {docList?.[projectType]?.length > 0 ? (
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th>Document Name</th>
                      <th>Modified On</th>
                      <th>Modified By</th>
                      <th>Page</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {docList?.[projectType]?.map((item) => (
                      <tr key={item.id}>
                        <td>{item.displayDocName}</td>
                        <td>
                          {moment(new Date(item.modifiedOn)).format(
                            "YYYY/MM/DD HH:mm:ss"
                          )}
                        </td>
                        <td>{item.createdBy}</td>
                        <td>{item.page}</td>
                        <td>
                          <span>&nbsp; &nbsp;</span>
                          {UserScope?.access?.saveNews === true ? (
                            <span className="pointer">
                              <se-icon
                                size="small"
                                title="Delete"
                                color="secondary"
                                onClick={() => {
                                  setDeleteModal(true);
                                  setDeletingFile(item);
                                }}
                              >
                                action_delete
                              </se-icon>
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="text-center">No Items Found</div>
              )}
            </ProjectsAccordion>
          );
        })}
        <Snackbar snackBar={snackBar} ref={snackBarRef} />
        <se-dialog
          id="dialog-complex-delete-confirm"
          open={openModal}
          can-backdrop="false"
        >
          <se-dialog-header>
            {selectedItem?.id ? "Edit" : "Add"} {selectedProjectType}
          </se-dialog-header>
          <se-dialog-content
            className="se-dialog-content "
            style={{ minHeight: "150px" }}
          >
            <>
              <div className="row mt-1">
                <div className="col-md-12">
                  <div className="form-group row">
                    <label className="col-md-4 my-auto col-form-label">
                      Page
                    </label>
                    <div className="col-md-8 my-auto">
                      <select
                        className="col-md-12 form-control form-control-sm"
                        name="page"
                        value={pageType}
                        onChange={(e) => handlePageChange(e)}
                      >
                        <option value="" disabled selected hidden>
                          Select the Page Type
                        </option>
                        {[
                          { name: "deploy", label: "Deploy" },
                          { name: "bom", label: "BOM" },
                        ].map((page_type) => (
                          <option key={page_type.name} value={page_type.name}>
                            {page_type.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 my-auto col-form-label">
                      Version
                    </label>
                    <div className="col-md-8 my-auto">
                      <select
                        className="col-md-12 form-control form-control-sm"
                        name="page"
                        value={versions}
                        onChange={(e) => handleVersionChange(e)}
                      >
                        <option value="" disabled selected hidden>
                          Select Version
                        </option>
                        {versionList.map((version) => (
                          <option key={version} value={version}>
                            {version}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </se-dialog-content>
          <se-dialog-footer className="position-relative ">
            <div className="ml-0 position-absolute">
              <input
                type="file"
                onChange={fileChangePageType}
                className="form-control-file form-control-sm fileNameDisplay"
                name="file"
                ref={inputRefHmi}
              />
            </div>

            <div className="ml-0 " style={{ marginRight: "0.3rem" }}>
              <se-button onClick={closeModal}>Cancel</se-button>
              {UserScope.access.uploadFile && (
                <se-button
                  option="flat"
                  color="primary"
                  disabled={
                    selectedFile === null || pageType === "" || versions === ""
                      ? true
                      : false
                  }
                  onClick={() => updateDocument()}
                >
                  UPLOAD
                </se-button>
              )}
            </div>
            <div className={loading ? "loader__center" : ""}>
              <se-loading loading={loading}></se-loading>
            </div>
          </se-dialog-footer>
        </se-dialog>
      </ProjectsAccordion>
    </>
  );
};

export default UploadPages;
