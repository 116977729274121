import React, { useCallback, useContext } from "react";
import { AssetBlockContext } from "../CustomHooks/ContextApi";

import cbImage from "../../images/CB.png";
import embLabels from "../../config/emb-labels-en.json";
import isTouchDevice from "../SE-Component/Tooltip";
import Tooltip from "../../utils/Tooltip";
import { useSelector } from "react-redux";
import CBContactorDetails from "./CBContactorDetails";
import { getProjectTypeValue } from "../../utils/project";

const EvCB = ({detailsBlock}) => {
  const {
    updateEvCbConfigData,
    configData,
    handleCBConfigChangeEv,
    assetEditStatusUpdateEvCb,
    selectedValues,
    setAssetSelection,
    assetDetailsDisplay,
    siteType,
    CBEVList,
  } = useContext(AssetBlockContext);
  const tooltipData = useSelector(state => state?.tooltipData?.tooltipData);
  const cbConfigChangeHandler = e => {
    handleCBConfigChangeEv(e);
    assetEditStatusUpdateEvCb(e, true, selectedValues.selectedEvCB);
  };
  const contactorChangeHandler = e => {
    handleCBConfigChangeEv(e);
    assetEditStatusUpdateEvCb(e, true, selectedValues.selectedEvCB);
  };
  const projectType = getProjectTypeValue()
  const checked = useCallback(() =>{
    if(CBEVList[selectedValues?.selectedEvCB]?.uiData?.contactor){
      return true;
    }else{
      return false;
    }
  },[CBEVList])
  const assetInfo = CBEVList[selectedValues?.selectedEvCB]?.cbConfigData
?.cbAssetDetails[0]?.assetInfo
  const setAssetHandler = () => setAssetSelection(embLabels.CIRCUIT_BREAKER);
  const isMotorisedDisbaled =
  (assetInfo === "Communicating" || assetInfo === "Serial");
  return (
    <div name="CB" className="col-md-12 p-0">
      <div className="col-md-12 pt-2 se-white-background">
        <div className="row">
          <div className="col-md-8">
            <img
              src={CBEVList?.[selectedValues?.selectedEvCB]?.cbConfigData
                .cbAssetDetails?.[0]?.imageLink??cbImage}
              className="asset-image-icon"
              alt="circuit breaker"
            />
            <span className="align-top line-height-30px bold">
              Circuit Breaker
            </span>
          </div>
          {/* <div className="col-md-4">
            <span className="float-right">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                disabled
              >
                Remove
              </button>
            </span>
          </div> */}
        </div>
      </div>
      <div className="col-md-12 col-sm-12 pt-2 se-grey-background se-border">
        <div className="row">
          <div className="col-md-3">
            <img
              src={CBEVList?.[selectedValues?.selectedEvCB]?.cbConfigData
                .cbAssetDetails?.[0]?.imageLink??cbImage}
              className="asset-image-display"
              alt="circuit breaker"
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12 bold">
                {CBEVList[selectedValues.selectedEvCB]?.cbConfigData
                        ?.cbAssetDetails[0]?.manufacturer || embLabels.SCHNEIDER_ELECTRIC}
              </div>
            </div>
            <div className="row">
              <div className="col-md-9">Circuit Breaker</div>
              <div className="col-md-3 text-right">
                <Tooltip
                  position="left"
                  iconColor="secondary"
                  information="Powerpact(UL)/ Compact NSX(IEC)"
                />
              </div>
              <div className="col-md-12">
                {assetDetailsDisplay?.communicationModel ||
                  CBEVList?.[selectedValues?.selectedEvCB]?.cbConfigData
                    .cbAssetDetails?.[0]?.communicationModel}
              </div>
              <div className="row">
                <div className=" col-md col-sm col-lg text-center">
                  <se-button
                    option="outline"
                    size="small"
                    onClick={setAssetHandler}
                  >
                    Asset Selection
                  </se-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 mt-1 se-white-background align-middle">
        <div className="row">
          <label className=" col-md-6 mt-2 bold se-font-14px pl-3">
            Asset Settings
          </label>

          <div className="col-md-6 mt-1 mb-1 text-right">
            <button
              type="button"
              className="btn btn-secondary btn-sm se-btn-primary"
              onClick={updateEvCbConfigData}
            >
              <se-icon size="nano">action_save</se-icon>
              <span>&nbsp;</span>Save
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-12 se-grey-background">
        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">
            Name <span className="se-mandatory">*</span>
          </label>
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput1"
            placeholder="Name"
            name="name"
            value={configData.cbConfigData.name}
            onChange={cbConfigChangeHandler}
          />
          <div
            className="invalid-feedback-custom"
            style={!configData.cbConfigData.name ? {} : { display: "none" }}
          >
            {embLabels.nameRequired}
          </div>
          <div
            className="invalid-feedback-custom"
            style={configData.cbConfigData.name!==""  && !/^[a-zA-Z0-9-_& ]+$/.test(configData.cbConfigData.name )? {} : { display: "none" }}
          >
            {embLabels.notallowedAlphanumericValues}
          </div>
        </div>
        <div className="form-group mb-1">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input asCompatible"
              name="motorized"
              id="customControlValidation4"
              value={configData.cbConfigData.motorized}
              checked={configData.cbConfigData.motorized}
              onChange={cbConfigChangeHandler}
              disabled={isMotorisedDisbaled}
            />
            <label
              className="custom-control-label line-height-25"
              htmlFor="customControlValidation4"
            >
               Motorized Circuit Breaker
            </label>
            <Tooltip
              position="left"
              iconColor="secondary"
              iconSize="small"
              information={tooltipData?.motorized?.data}
            />           
          </div>
          
        {!isMotorisedDisbaled&&
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input asCompatible"
              name="contactor"
              id="customControlValidation45"
              value={checked()}
              checked={checked()}
              onChange={contactorChangeHandler}
              // disabled={configData.cbConfigData.motorized}
            />
            <label
              className="custom-control-label line-height-25"
              htmlFor="customControlValidation45"
            >
              Circuit Breaker + Contactor
            </label>
            {/* <Tooltip
              position="bottom"
              iconColor="secondary"
              iconSize="small"
              information={tooltipData?.motorized?.data}
            />  */}
        </div>}
        </div>
        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">Description</label>
          <input
            type="text"
            autoComplete="none"
            className="form-control form-control-sm"
            id="exampleFormControlInput1"
            placeholder="Description"
            name="description"
            value={configData.cbConfigData.description}
            onChange={cbConfigChangeHandler}
          />
        </div>
      </div>
     {CBEVList[selectedValues?.selectedEvCB]?.uiData?.contactor&&!isMotorisedDisbaled&&<CBContactorDetails detailsBlock={detailsBlock}/>}
    </div>
  );
};

export default EvCB;
