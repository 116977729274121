/* istanbul ignore file */

import React, { useState, useCallback, useRef, useEffect, useReducer } from "react";
import moment from "moment";

//* Custom hooks 
import userScope from "./CustomHooks/userScope";
import Snackbar from "./SE-Component/Snackbar"; 
import Confirm from "./SE-Component/Confirm";
import Alert from "./SE-Component/Alert";
import useMountEffect from "./CustomHooks/useMountEffect";
import { InformationPageProvider } from "./CustomHooks/ContextApi";
import embLabels from "../config/emb-labels-en.json";

//* child import
import ProjectInformation from "./InformationPages/ProjectInformation";
import ProjectType from "./InformationPages/ProjectType";
import SiteInformation from "./InformationPages/SiteInformation";
import InviteUser from "../components/InviteUser";
import ProgressBarContainer from "../components/progressBar/progressBarContainer";
import _ from "lodash";
import LocationFinder from "mg-ui-location";
import { useDispatch } from "react-redux";
import { upDateProjectType } from "../redux/features/projectTypeSlice";
//*api call
import ApiSession from "../api/ApiSession";
import MyErrorBoundary from "../utils/ErrorBoundary";
import { getTooltipData } from "../redux/features/getTooltipDataSlice";
import { informationConst } from "../utils/constants";
import { getProjectTypeValue } from "../utils/project";
import { ANSI } from "../utils/application.constants";
//import ProgressBar from "../components/progressBar/progressBar";

const apiSession = new ApiSession();

const alphanumericRegWithHyphen = /^[-_0-9a-zA-Z]+$/;
const initialTooltipData ={
  standardToolTipData: "",
  packagedToolTipData: "",
  gridConnectedTooltipData: "",
  islandableTooltipData: "",
  offGridTooltipData: "",
  pvHybridizationToolTip: "",
  pvGensetAtsToolTip: "",
  pvBessNrToolTip: "",
  gensetPvBessA2EToolTip: "",
  demandChargeTooltipData: "",
  tariffManagementTooltipData: "",
  realTimeExportControlEMOTooltipData: "",
  exportOptimizationTooltipData: "",
  selfConsumptionTooltipData: "",
  fuelSaverTooltipData: "",
  noFuelTooltipData: "",
  fuelEraserTooltipData: "",
  stormPreparednessTooltipData : "",
  remoteMonitoringTooltipData: "",
  otherOptimizationTooltipData: "",
  q2C: "",
  useCase:"",
  addess:"",
  transitionType:"",
  fuelNoBessToolTipData:"",
  motorizedTooltip:"",
  manualDemandResponseTooltipData:"",
  offGridModeTooltipData:"",
  realTimeImportControlEMATooltipData:"",
  selectMicrogridTypeToolTipData:"",
  selectProjectTypeToolTipData:"",
  greenConsumptionTooltipData:"",
  netMeteringTooltipData:"",
}
const InfoTooltipReducer =(state, action)=>{
 switch (action.type) {
  case "SET_TOOLTIP":
    return {
      standardToolTipData: action.payload.standardToolTipData.data,
      packagedToolTipData: action.payload.packagedToolTipData.data,
      gridConnectedTooltipData: action.payload.gridConnected.data,
      islandableTooltipData: action.payload.islandable.data,
      offGridTooltipData: action.payload.offGrid.data,
      pvHybridizationToolTip: action.payload.pvHybridizationToolTip.data,
      pvGensetAtsToolTip: action.payload.pvGensetAtsToolTip.data,
      pvBessNrToolTip: action.payload.pvBessNrToolTip.data,
      gensetPvBessA2EToolTip: action.payload.gensetPvBessA2EToolTip.data,
      demandChargeTooltipData: action.payload.demandCharge.data,
      tariffManagementTooltipData: action.payload.tariffManagement.data,
      realTimeExportControlEMOTooltipData: action.payload.realTimeExportControlEMOTooltipData.data,
      exportOptimizationTooltipData: action.payload.exportOptimization.data,
      selfConsumptionTooltipData: action.payload.selfConsumption.data,
      fuelSaverTooltipData: action.payload.fuelSaver.data,
      fuelNoBessToolTipData:action.payload.fuelNoBess.data,
      noFuelTooltipData: action.payload.noFuel.data,
      fuelEraserTooltipData: action.payload.fuelEraser.data,
      stormPreparednessTooltipData: action.payload.stromPreparedness.data,
      remoteMonitoringDaasTooltipData: action.payload.remoteMonitoringDaas.data,
      remoteMonitoringMGassTooltipData: action.payload.remoteMonitoringMGaas.data,
      otherOptimizationTooltipData: action.payload.realTimeExportControlEMOTooltipData.data,
      q2C: action.payload.q2C.data,
      autogridflexTooltipData:action.payload.autogridflex.data,
      arrayMaxPowerTooltipData:action.payload.arrayMaxPower.data,
      useCase:action.payload.useCases.data,
      address:action.payload.address.data,
      transitionType:action.payload.transitionType.data,
      motorizedTooltip:action.payload.motorized.data,
      manualDemandResponseTooltipData:action.payload.manualDemandResponseTooltipData.data,
      offGridModeTooltipData:action.payload.offGridModeTooltipData.data,
      realTimeImportControlEMATooltipData:action.payload.realTimeImportControlEMATooltipData.data,
      selectMicrogridTypeToolTipData:action.payload.selectMicrogridType.data,
      selectProjectTypeToolTipData:action.payload.selectProjectType.data,
      greenConsumptionTooltipData:action?.payload?.greenConsumptionTooltip?.data,
      netMeteringTooltipData:action?.payload?.netMeteringTooltip?.data,
    }
   
 
  default:
   return state;
 }
}
const InformationHook = (props) => {
  //* State hooks
  const UserScope = userScope();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(true);
  const [mandatoryMsgDisplay, setMandatoryMsgDisplay] = useState(false);
  const [disableValidateProject, setDisableValidateProject] = useState(false);
  const [projectCreateStatus, setProjectCreateStatus] = useState(true);
  const [renderLocationComponent, setRenderLocationComponent] = useState(false);
  const [siteCreated, setSiteCreated] = useState(false);
  const [projectCreated] = useState(
    JSON.parse(sessionStorage.getItem("projectId")) ? true : false
  );
  const [autogridGSX, setAutogridGSX] = useState(false);
  const [createdOn, setCreatedOn] = useState(
    moment(new Date().toString()).format("YYYY/MM/DD HH:mm:ss")
  );
  const [projectType, setProjectType] = useState("");
  const [siteType, setSiteType] = useState("");
  const [switchboardType, setSwitchboardType] = useState("NEW_SWITCHBOARD");
  const [clientType, setClientType] = useState("");
  const [architectureType, setArchitectureType] = useState(null);
  const [optimizationEngine, setOptimizationEngine] = useState("");
  const [otherOptimizationStrategy, setOtherOptimizationStrategy] =
    useState("");

  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const [currencyList, setCurrencyList] = useState([]);
  const [users, setUsers] = useState([]);
  const [useCaseHMIData,setUseCaseHMIData] = useState({});
  const [projectInformation, setProjectInformation] = useState({
    projectType: "",
    projectName: "",
    clientName: "",
    siteName: "",
    currency: "Dollars",
    temperatureUnit: "Fahrenheit °F",
    description: "",
    externalId: "",
    projectId: "",
    projectTypeValue: "",
    projectDisplayName: "",
    projectTypeDisplayValue: "",
    q2cOrderNumber: ""
  });
  const [organization, setOrganization] = useState([]);
  const [selectedOrganization,setSelectedOrganization] = useState({});
  const [informationToolTIP, InfoTooltipDispatch] = useReducer(InfoTooltipReducer, initialTooltipData);
  
  const [disabledFeatures, setDisabledFeatures] = useState({
    disableCloud: false,
    disableOpertaions: false,
  });
  const [gridSettings, setGridSettings] = useState({
    demandCharge: false,
    tariffManagement: false,
    selfConsumption: false,
    exportOptimization: false,
    noExport: false,
    bessEnergyExchanges:false,
    noImport:false,
    minSocMgmt:false,
    manualDemandResponse:false,
    offGridMode:false,
    remoteMonitoringControl: false,
    greenConsumption:false,
    stormPreparedness:false,
    mode: "",
    gridTransitionOutage: "OPEN",
    manualSwitch: "OPEN",
    gridTransitionOnline: "OPEN",
    gensetBattery: "OPEN",
  });
  const [siteInformation, setSiteInformation] = useState({
    userInterface: "12",
    gridNominalFrequency: 60,
    gridNominalVoltage: 480,
  });
  const [locationData, setLocationData] = useState({
    zipCode: "",
    timezone: "",
    address: "",
    country: "",
    city: "",
    stateProvince: "",
    latitude: "",
    longitude: "",
  });
  const [userRoles, setUserRoles] = useState({
    type: "addUserToProject",
    addUserPopUpstatus: false,
    addUserPopUpLoading: false,
    addUserPopUpList: [],
    rolesList: [],
    rolesPermission: {},
  });
  const [deletingUserInfo, setDeletingUserInfo] = useState({
    deleteingRole: "",
    deleteingUserId: "",
  });
  const [previousValues, setPreviousValues] = useState({
    projectType: "",
    siteType: "",
    switchboardType: "",
    architectureType: "",
    useCaseChanged: false,
    projectChanged: false,
    demandCharge: false,
    tariffManagement: false,
    selfConsumption: false,
    exportOptimization: false,
    noExport: false,
    bessEnergyExchanges:false,
    noImport:false,
    minSocMgmt:false,
    manualDemandResponse:false,
    offGridMode:false,
    mode: "",
    gridTransitionOnline: "",
    gridTransitionOutage: "",
    manualSwitch: "",
    gensetBattery: "",
    userInterface: "",
    remoteMonitoringControl: {
      GRID_CONNECTED: true,
      ISLANDABLE: true,
      OFF_GRID: true,
      PV_HYBRIDIZATION: true,
      PV_GENSET_ATS: true,
      PV_BESS_NR: true,
    },
  });

  const [snackBar, setSnackBar] = useState({ type: "", message: "" });
  const [deleteImagePopUpStatus, setDeleteImagePopUpStatus] = useState({
    confirmMessage: "",
    confirmPopupStatus: false,
  });
  const [deleteInvitedUserPopUpStatus, setDeleteInvitedUserPopUpStatus] =
    useState({
      confirmMessage: "",
      confirmPopupStatus: false,
    });
  const [alert, setAlert] = useState({
    message: "",
    alertPopUpStatus: false,
    title: "",
    isRoute: false,
    routePath: "",
  });
  const [projectStatusData, setProjectStatusData] = useState();

  const snackBarRef = useRef(null);
  useEffect(() => {
    const projectVal = getProjectTypeValue();
    if (projectVal) {
      setLocationData((prevState) => ({
        ...prevState,
        latitude: projectVal?.includes(ANSI) ? 42.655232 : 45.1960737,
        longitude: projectVal?.includes(ANSI) ? -71.139571 : 5.7303777,
      }));
    }
  }, []);
  const getProjectDetailStatus = () => {
 try {
  
  apiSession
  .getProjectStatus(sessionStorage.getItem("projectId"))
  .then((response) => {
    if (response) {
      setLoading(true);
      setProjectStatusData(response);
    }
  })
  .catch((error) => {
    if (error.response !== undefined) {
      if (error.response.status === 412) {
        callSnackbar("error", `${error?.message}`);
      }
    }
  });
 } catch (error) {
  console.log(error)
 }

  };
  const getUseCasesAndHMIData = (projectTypeValue) => {
    apiSession.getUseCasesAndHMIData("EN",projectTypeValue)
    .then((json)=>{
      setUseCaseHMIData((previousValues)=>({
        ...previousValues,
        ...json
      }))
    })
  }
  const getExternalID = () => {
    const projectId = JSON.parse(sessionStorage.getItem("projectId"));
    try {
      
    if (projectId === null) {
      apiSession.getProjectExternalId()
        .then((response) => {
          setProjectInformation((prevState) => ({
            ...prevState,
            externalId: response["0"],
          }))
        })
        .catch((error) => {
          if (error.response !== undefined) {
            if (error.response) {
              callSnackbar("error", `${error?.message}`);
            }
          }
        });
    }
    } catch (error) {
      console.log(error);
    }

  }
  useEffect(() => {
    setSwitchboardType("NEW_SWITCHBOARD");
  }, [siteType])
  useEffect(() => {
    getProjectDetailStatus();
    getExternalID();
    const projectType = JSON.parse(sessionStorage.getItem("projectType"));
    const projectId = JSON.parse(sessionStorage.getItem("projectId"));
    if(projectType?.projectTypeValue!=undefined||projectType?.projectTypeValue!=null){
      getUseCasesAndHMIData(projectType?.projectTypeValue);
    }
    let { history } = props;
    if (!(projectType || projectId)) {
      history.push({
        pathname: "/project",
      });
    }

    if (projectType?.tag === embLabels.A2E_PROJECT) {
      setSiteInformation((prevState) => ({
        ...prevState,
        userInterface: "none",
        gridNominalFrequency: 50,
        gridNominalVoltage: 400,
      }));
      setProjectType("PACKAGED_MICROGRID");
      setSiteType("GENSET_PVBESS_A2E");
      setOptimizationEngine("EMA");
      if (
        sessionStorage.projectId === "null" &&
        projectType?.tag === embLabels.A2E_PROJECT
      ) {
        setGridSettings((prevState) => ({
          ...prevState,
          demandCharge: false,
          tariffManagement: false,
          selfConsumption: false,
          exportOptimization: false,
          noExport: false,
          remoteMonitoringControl: true,
          mode: "",
          gridTransitionOutage: "OPEN",
          manualSwitch: "OPEN",
          gridTransitionOnline: "OPEN",
          gensetBattery: "OPEN",
        }));
      }
    }
    if (projectType && projectType.tag && 
      projectType.tag === embLabels.GREENSTRUXURE_PROJECT
    ) {
            //setProjectType("STANDARD_MICROGRID");
            setSiteType("GRID_CONNECTED_GSX");
             setArchitectureType("PV_BESS_NR");
             setGridSettings((prevState) => ({
              ...prevState,
              remoteMonitoringControl: true,
              noExport: true,
            }));
            setOptimizationEngine("OTHER");
            // setAutogridGSX(true)
            // setOtherOptimizationStrategy("AUTOGRID")
    }

    if (projectType && projectType.tag && 
      projectType.tag === embLabels.GENERIC_PROJECT&&projectType.projectTypeValue==="MGaaS ANSI"
    ) {
            
            setSiteType("GRID_CONNECTED"); 
             setGridSettings((prevState) => ({
              ...prevState,
              remoteMonitoringControl: true,
              noExport: true,
              selfConsumption:true,
            }));
            setOptimizationEngine("OTHER");
            setSiteInformation((prevState) => ({
              ...prevState,
              userInterface: "none",
            }));
    }
    if (projectType && projectType.tag && 
      projectType.tag === embLabels.GENERIC_PROJECT&&projectType.projectTypeValue==="MGaaS IEC"
    ){
       setProjectType("STANDARD_MICROGRID");
       setGridSettings((prevState) => ({
        ...prevState,
        selfConsumption:true,
      }));
       setSiteInformation((prevState) => ({
        ...prevState,
        userInterface: "none",
        gridNominalFrequency: 50,
        gridNominalVoltage: 410,
      }));
    }
  }, []);

  //* Effect hook
useEffect(()=>{
  loadToolTip();
},[])
  useMountEffect(() => {
    setLoading(true);
    localStorage.setItem("currency", "$");
    localStorage.setItem("useCaseChanged", false);
    localStorage.setItem("projectChanged", false);
    localStorage.setItem("userCountry", "France");
    getCurrencyList();
   
    
    let projectDetails = JSON.parse(sessionStorage.getItem("projectType"))
    if (projectDetails && projectDetails?.projectTypeValue) {
      const selectCurrency = projectDetails?.projectType === "IEC"?"":"Dollars";
      const units = projectDetails?.projectType === "IEC"?"Celsius Degree °C":"Fahrenheit °F";
      setProjectInformation((prevState) => ({
        ...prevState,
        projectTypeValue: projectDetails?.projectTypeValue,
        projectDisplayName: projectDetails?.displayValue,
        projectTypeDisplayValue: projectDetails?.projectTypeDisplayValue,
        currency:selectCurrency,
        temperatureUnit:units ,
      }))
    }
    
  }, []);

  const getCurrencyList = () => {
    apiSession
      .getDefaultInfo()
      .then((json) => {
        json.sort((a, b) => a.status.localeCompare(b.status));
        setCurrencyList(json);
        let projectType = sessionStorage.getItem("projectType")
        if (sessionStorage.getItem("projectId") !== "null") {
          setProjectCreateStatus(false);
          fetchProject(json);
        } else {
          localStorage.setItem("pname", null);
          setLoadingImage(false);
          if (projectType && JSON.parse(projectType).projectTypeValue === "DaaS ANSI") {

            setOptimizationEngine("OTHER");
            setAutogridGSX(true)
            setOtherOptimizationStrategy("AUTOGRID")
          } else {

            if (JSON.parse(projectType).projectTypeValue === "DaaS IEC") {
              setOptimizationEngine("OTHER");
              setAutogridGSX(true)
            } else {
              setOptimizationEngine("EMA");

            }
            // siteType === embLabels.GRID_CONNECTED_GSX && architectureType === embLabels.PV_BESS_NR
          }
          setRenderLocationComponent(true);
          setLoading(false);
          let projectSelectedType = JSON.parse(
            sessionStorage.getItem("projectType")
          );
          if (
            projectSelectedType &&
            projectSelectedType.tag === embLabels.GREENSTRUXURE_PROJECT
          ) {
            setProjectType("GREENSTRUXURE_MICROGRID");
           // setProjectType("STANDARD_MICROGRID");
            setSiteType("GRID_CONNECTED_GSX");
          
          } else if (projectSelectedType && projectSelectedType.tag) {

            if((projectSelectedType.tag === embLabels.GENERIC_PROJECT && projectSelectedType.projectTypeValue==="MGaaS ANSI") ){
             
              setProjectType("STANDARD_MICROGRID");
              setSiteType("GRID_CONNECTED");
            }else if(projectSelectedType.tag !== embLabels.GENERIC_PROJECT){
              setProjectType("PACKAGED_MICROGRID");
            } else{
              
            }
          }
        }
      })
      .catch(() => { });
  };

  const loadToolTip = () => {
    setLoading(true);
    apiSession.getOrganization().then(json=>{
      setOrganization(json);
     // setSelectedOrganization(json[0]);
    });
    apiSession
      .getToolTip("information", "EN")
      .then((json) => {
        InfoTooltipDispatch({
          type: "SET_TOOLTIP",
          payload:json,
        })
        
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchProject = (currencies) => {
    let projectData = {};
    setLoading(true);
    apiSession
      .getProject(sessionStorage.getItem("projectId"))
      .then((json) => {
        downloadImage();
        setProjectDetails(currencies, json);
        setSelectedOrganization(json?.organization);
        dispatch(upDateProjectType({
          projectType:json.projectTypeDisplayValue,
          projectName:json.projectName,
        }))
        localStorage.setItem("pType", json.projectTypeDisplayValue);
        setSwitchboardType(json.switchboardType);
        projectData.projectTypeValue = json.projectType;
        sessionStorage.setItem("projectType", JSON.stringify(projectData));
        if(json.projectType === "MGaaS AccessToEnergy"){
          localStorage.setItem("timeSyncValidate",true);
        }else{
          localStorage.setItem("timeSyncValidate",json?.systemsTimeSyncPage);
        }
         
        if(json?.projectType!=undefined||json.projectType!=null) {
          getUseCasesAndHMIData(json.projectType)
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const downloadImage = (e) => {
    setLoadingImage(true);
    apiSession
      .getProjectImage(sessionStorage.getItem("projectId"))
      .then((response) => {
        let arrayBufferView = new Uint8Array(response);
        let blob = new Blob([arrayBufferView], { type: "image/jpeg" });
        let urlCreator = window.URL || window.webkitURL;
        let imageUrl = urlCreator.createObjectURL(blob);

        setImagePreviewUrl(imageUrl);
        setLoadingImage(false);
      })
      .catch(() => {
        setLoadingImage(false);
      });
  };

  const setProjectDetails = (currencies, projectDetails) => {
    localStorage.setItem("programPage", projectDetails.programPage);
    localStorage.setItem("deploymentReady", projectDetails.deploymentReady);
    localStorage.setItem("pname", projectDetails.projectName);
    localStorage.setItem("pType", projectDetails.projectType);

    localStorage.setItem("communicationValidated", projectDetails.systemsPage);
    // localStorage.getItem("timeSyncValidate",projectDetails?.systemsTimeSyncPage)

    let rawDate = new Date(projectDetails.createdOn + " UTC");
    let createdOnDate = rawDate.toString();
    createdOnDate = moment(createdOnDate).format("YYYY/MM/DD HH:mm:ss");
    setCreatedOn(createdOnDate);


    currencies.forEach((currencyData) => {
      if (projectDetails.projectInfo.currency === currencyData.name) {
        localStorage.setItem("currency", currencyData.symbol);
      }
    });

    setProjectInformation((prevState) => ({
      ...prevState,
      projectType: projectDetails.projectType,
      projectName: projectDetails.projectName,
      currency: projectDetails.projectInfo.currency,
      temperatureUnit: projectDetails.projectInfo.temperatureUnit,
      description: projectDetails.projectInfo.description,
      clientName: projectDetails.projectInfo.clientName,
      siteName: projectDetails.projectInfo.siteName,
      projectId: projectDetails.id,
      externalId: projectDetails.externalId,
      q2cOrderNumber: projectDetails.q2cOrderNumber,
      projectTypeDisplayValue: projectDetails.projectTypeDisplayValue,
      projectTypeValue:projectDetails.projectType,

    }));
    setProjectType(projectDetails.projectInfo.projectType);
    setSiteType(projectDetails.projectInfo.siteType);
    setSwitchboardType(projectDetails.switchboardType);
    setClientType(projectDetails.clientType);
    setArchitectureType(projectDetails.architecture);

    const disableCloud = projectDetails.licenses.indexOf("EXPERT");
    const disableOpertaions = projectDetails.licenses.indexOf("OPERATIONS");
    setDisabledFeatures((prevState) => ({
      ...prevState,
      disableOpertaions: disableOpertaions === -1 ? false : true,
      disableCloud: disableCloud === -1 ? false : true,
    }));
    const checkOptimization = projectDetails?.projectInfo?.siteType === embLabels.ISLANDABLE_GSX
    let optimizationValue = "EMA"
    if (checkOptimization) {
      optimizationValue = "OTHER"
    } else {
      optimizationValue = projectDetails.server === null ||
        projectDetails.server.optimizationType === "EMA"
        ? "EMA"
        : "OTHER"
    }


    setOptimizationEngine(
      optimizationValue
    );
    setOtherOptimizationStrategy(
      projectDetails.server.optimizationType === "AUTOGRID" ? "AUTOGRID" : ""
    );

    setAutogridGSX(
      projectDetails.server.optimizationType === "AUTOGRID" ? true : false
    );
    let jsonResp= projectDetails.projectInfo.gridSettings;
    setGridSettings((prevState) => ({
      ...prevState,
      demandCharge: jsonResp.demandCharge,
      tariffManagement: jsonResp.tariffManagement,
      selfConsumption:jsonResp. selfConsumption,
      exportOptimization: jsonResp.exportOptimization,
      noExport:jsonResp.noExport,
      noImport: jsonResp.noImport,
      minSocMgmt: jsonResp.minSocMgmt,
      manualDemandResponse: jsonResp.manualDemandResponse,
      greenConsumption:jsonResp.greenConsumption,
      stormPreparedness:jsonResp.stormPreparedness,
      bessEnergyExchanges:jsonResp.bessEnergyExchanges,
      // offGridMode: offGridMode,
      offGridMode: false,
      remoteMonitoringControl: jsonResp.remoteMonitoringControl,
      mode: jsonResp.mode,
      gridTransitionOnline: jsonResp.gridTransitionOnline !== null ? jsonResp.gridTransitionOnline : gridSettings.gridTransitionOnline,
      gridTransitionOutage: jsonResp.gridTransitionOutage !== null ? jsonResp.gridTransitionOutage : gridSettings.gridTransitionOutage,
      manualSwitch: jsonResp.manualSwitch !== null ? jsonResp.manualSwitch : gridSettings.manualSwitch,
      gensetBattery:jsonResp.gensetBattery !== null ? jsonResp.gensetBattery : gridSettings.gensetBattery,
    }));

    setSiteInformation((prevState) => ({
      ...prevState,
      userInterface: projectDetails.projectInfo.userInterface,
      gridNominalFrequency: projectDetails.projectInfo.gridNominalFrequency,
      gridNominalVoltage: projectDetails.projectInfo.gridNominalVoltage,
    }));

    setUsers(projectDetails.users);

    setPreviousValues((prevState) => ({
      ...prevState,
      projectType: projectDetails.projectInfo.projectType,
      siteType: projectDetails.projectInfo.siteType,
      architectureType: projectDetails.architecture,
      demandCharge: projectDetails.projectInfo.gridSettings.demandCharge,
      tariffManagement:
        projectDetails.projectInfo.gridSettings.tariffManagement,
      selfConsumption: projectDetails.projectInfo.gridSettings.selfConsumption,
      exportOptimization:
        projectDetails.projectInfo.gridSettings.exportOptimization,
      noExport: projectDetails.projectInfo.gridSettings.noExport,
      bessEnergyExchanges:projectDetails.projectInfo.gridSettings.bessEnergyExchanges,
      noImport: projectDetails.projectInfo.gridSettings.noImport,
      minSocMgmt: projectDetails.projectInfo.gridSettings.minSocMgmt,
      manualDemandResponse: projectDetails.projectInfo.gridSettings.manualDemandResponse,
      // offGridMode: projectDetails.projectInfo.gridSettings.offGridMode,
      offGridMode:false,
      mode: projectDetails.projectInfo.gridSettings.mode,
      gridTransitionOnline:
        projectDetails.projectInfo.gridSettings.gridTransitionOnline,
      gridTransitionOutage:
        projectDetails.projectInfo.gridSettings.gridTransitionOutage,
      manualSwitch: projectDetails.projectInfo.gridSettings.manualSwitch,
      gensetBattery: projectDetails.projectInfo.gridSettings.gensetBattery,
      userInterface: projectDetails.projectInfo.userInterface,
      remoteMonitoringControl: {
        ...previousValues.remoteMonitoringControl,
        [projectDetails.projectInfo.siteType]:
          projectDetails.projectInfo.gridSettings.remoteMonitoringControl,
        [projectDetails.architecture]:
          projectDetails.projectInfo.gridSettings.remoteMonitoringControl,
      },
    }));

    //* SETTING UP THE LOCATION

    localStorage.setItem("userCountry", projectDetails.location.country);

    setLocationData((prevState) => ({
      ...prevState,
      address: projectDetails.location.address,
      city: projectDetails.location.city,
      zipCode: projectDetails.location.zipCode,
      stateProvince: projectDetails.location.stateProvince,
      country: projectDetails.location.country,
      latitude: projectDetails.location.latitude,
      longitude: projectDetails.location.longitude,
      timezone: projectDetails.location.timezone,
    }));
    setRenderLocationComponent(true);

    setSiteCreated(projectDetails.siteCreated);

    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  //* Project infromation START

  const projectChangeHandler = (e) => {
    const value = e.target.value.replace(/ +(?= )/g, "");
    const name = e.target.name;
    let orderNumb = /^$|^[0-9]+$/;
    if (name === "externalId") {
      if (value.trim() !== "") {
        if (alphanumericRegWithHyphen.test(value)) {
          setProjectInformation((prevState) => ({
            ...prevState,
            [name]: value,
          }));
        }
      } else {
        setProjectInformation((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }else if(name === 'q2cOrderNumber'){
        if (orderNumb.test(e.target.value)) {
          setProjectInformation((prevState) => ({
            ...prevState,
            [name]: value,
          }));
        }
    } else {
      setProjectInformation((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const fileChangedHandler = (e) => {
    if (e.target.files.length) {
      if (e.target.files[0].size > 1000000) {
        callSnackbar(`error`, `Image size should be less than 1MB`);
        e.target.value = null;
      } else if (
        e.target.files[0].type !== "image/png" &&
        e.target.files[0].type !== "image/jpg" &&
        e.target.files[0].type !== "image/jpeg" &&
        e.target.files[0].type !== "image/gif" &&
        e.target.files[0].type !== "image/tiff"
      ) {
        callSnackbar(
          `error`,
          `Invalid file format, Please select .jpg/.jpeg/.png/.tiff/.gif file`
        );
        e.target.value = null;
      } else {
        setSelectedFile(e.target.files[0]);
        if (sessionStorage.getItem("projectId") === "null") {
          //* do nothing
        } else {
          uploadProjectImage(e.target.files[0]);
        }
        let reader = new FileReader();
        reader.onloadend = () => {
          setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  //* Before project is validated
  const uploadProjectImage = (uploadFile) => {
    let fd = new FormData();
    fd.append("file", uploadFile);
    fd.append("projectId", sessionStorage.getItem("projectId"));

    apiSession
      .uploadProjectImage(fd)
      .then(() => {
        callSnackbar("success",  informationConst.IMG_SUCCESS);
      })
      .catch(() => {
        callSnackbar("error", informationConst.IMG_ERROR);
      });
  };

  //* after project is validated

  const uploadImage = () => {
    let fd = new FormData();
    fd.append("file", selectedFile);
    fd.append("projectId", sessionStorage.getItem("projectId"));

    apiSession
      .uploadProjectImage(fd)
      .then(() => {
        setAlertMessage(
          `Project Status`,
          true,
          `assets`,
          `Project ${projectCreateStatus ? "Created" : "Updated"
          } Successfully. `
        );
      })
      .catch(() => { });
  };

  const removeProjectImagePopUp = () => {
    setDeleteImage(true, informationConst.IMG_DELETE_ASK);
  };

  const closeDeleteImagePopUp = () => {
    setDeleteImage(false, ``);
  };

  const removeProjectImage = () => {
    if (sessionStorage.getItem("projectId") === "null") {
      setSelectedFile(null);
      setImagePreviewUrl(null);
      setDeleteImage(false, ``);
    } else {
      setLoading(true);
      setDeleteImage(false, ``);

      apiSession
        .deleteProjectImage(sessionStorage.getItem("projectId"))
        .then((json) => {
          setSelectedFile(null);
          setImagePreviewUrl(null);
          callSnackbar("success", informationConst.IMG_DELETE_SUCCESS);
          setLoading(false);
        })
        .catch(() => {
          callSnackbar("error", informationConst.IMG_DELETE_ERROR);
          setLoading(false);
        });
    }
  };

  //* Project infromation END

  //* PROJECT TYPE START

  const handleProjectTypeChange = (e) => {
    const { value } = e.target;
    setProjectType(e.target.value);
    setSiteType(sessionStorage.projectId === "null" ? "" : siteType);


    setOptimizationEngine(
      e.target.value === embLabels.PACKAGED_MICROGRID
        ? "EMA"
        : optimizationEngine
    );


    if (
      sessionStorage.projectId === "null" &&
      e.target.value === embLabels.PACKAGED_MICROGRID
    ) {
      setGridSettings((prevState) => ({
        ...prevState,
        demandCharge: false,
        tariffManagement: false,
        selfConsumption: false,
        exportOptimization: false,
        noExport: true,
        remoteMonitoringControl: true,
        mode: "",
        gridTransitionOutage: "OPEN",
        manualSwitch: "OPEN",
        gridTransitionOnline: "OPEN",
        gensetBattery: "OPEN",
      }));


      const projectType = JSON.parse(sessionStorage.getItem("projectType"));

      if (projectType?.tag === informationConst.A2E_PROJECT || clientType === informationConst.A2E_PROJECT) {

        setSiteInformation((prevState) => ({
          ...prevState,
          userInterface: "none",
          gridNominalFrequency: 50,
          gridNominalVoltage: 400,
        }));
      } else {
        setSiteInformation((prevState) => ({
          ...prevState,
          userInterface: "none",
          gridNominalFrequency: 60,
          gridNominalVoltage: 480,
        }));
      }




    }

    if (
      sessionStorage.projectId === "null" &&
      e.target.value === embLabels.STANDARD_MICROGRID
    ) {
      setGridSettings((prevState) => ({
        ...prevState,
        demandCharge: false,
        tariffManagement: false,
        selfConsumption: false,
        exportOptimization: false,
        noExport: false,
        remoteMonitoringControl: true,
        mode: "",
        gridTransitionOutage: "OPEN",
        manualSwitch: "OPEN",
        gridTransitionOnline: "OPEN",
        gensetBattery: "OPEN",
      }));
      setSiteInformation((prevState) => ({
        ...prevState,
        userInterface: "12",
        gridNominalFrequency: 60,
        gridNominalVoltage: 480,
      }));
    }

    if (
      sessionStorage.projectId !== "null" &&
      previousValues.projectType !== e.target.value
    ) {
      setAlertMessage(
        `Project Change Alert`,
        false,
        "",
        informationConst.PROJECT_CHANGE_ALERT
      );
      setPreviousValues((prevState) => ({
        ...prevState,
        projectChanged: true,
        useCaseChanged: true,
      }));
      setSiteType("");
      setGridSettings((prevState) => ({
        ...prevState,
        demandCharge: false,
        tariffManagement: false,
        selfConsumption: false,
        exportOptimization: false,
        noExport: value === embLabels.PACKAGED_MICROGRID,
        remoteMonitoringControl: true,
        mode: "",
        gridTransitionOnline: "OPEN",
        gridTransitionOutage: "OPEN",
        manualSwitch: "OPEN",
        gensetBattery: "OPEN",
      }));

      setSiteInformation((prevState) => ({
        ...prevState,
        userInterface: value === embLabels.PACKAGED_MICROGRID ? "none" : "12",
      }));
    }
    if (
      sessionStorage.projectId !== "null" &&
      previousValues.projectType === value
    ) {
      setSiteType(previousValues.siteType);
      setPreviousValues((prevState) => ({
        ...prevState,
        projectChanged: false,
        useCaseChanged: false,
      }));
      setGridSettings((prevState) => ({
        ...prevState,
        demandCharge: previousValues.demandCharge,
        tariffManagement: previousValues.tariffManagement,
        selfConsumption: previousValues.selfConsumption,
        exportOptimization: previousValues.exportOptimization,
        noExport: previousValues.noExport,
        bessEnergyExchanges:previousValues.bessEnergyExchanges,
        remoteMonitoringControl: true,
        gridTransitionOutage: previousValues.gridTransitionOutage,
        mode: previousValues.mode,
        gridTransitionOnline: previousValues.gridTransitionOnline,
        manualSwitch: previousValues.manualSwitch,
        gensetBattery: previousValues.gensetBattery,
      }));

      setSiteInformation((prevState) => ({
        ...prevState,
        userInterface: previousValues.userInterface,
      }));
    }
  };

  const handleSiteTypeChange = (e) => {
    const { value } = e.target;
    setSiteType(e.target.value);
    if (e.target.value === embLabels.OFF_GRID) {
      setGridSettings((prevState) => ({
        ...prevState,
        mode: "",
      }));
    }


    if (sessionStorage.projectId === "null") {
      const remoteMonitoringControl =
        previousValues.remoteMonitoringControl[e.target.value];
      setGridSettings((prevState) => ({
        ...prevState,
        remoteMonitoringControl,
      }));
      setSiteInformation((prevState) => ({
        ...prevState,
        userInterface: value === embLabels.GRID_CONNECTED ? "none" : "12",
      }));
    }

    if (
      sessionStorage.projectId !== "null" &&
      projectType === previousValues.projectType &&
      previousValues.siteType !== value
    ) {
      setAlertMessage(
        `Use Case Change Alert`, 
        false,
        "",
        informationConst.USE_CASE_CHANGE_ALERT
      );
      setPreviousValues((prevState) => ({
        ...prevState,
        useCaseChanged: true,
      }));

      setGridSettings((prevState) => ({
        ...prevState,
        demandCharge: true,
        tariffManagement: false,
        selfConsumption: false,
        exportOptimization: false,
        noExport: false,
        mode: "",
        gridTransitionOnline: "OPEN",
        gridTransitionOutage: "OPEN",
        manualSwitch: "OPEN",
        gensetBattery: "OPEN",
        remoteMonitoringControl: true,
      }));
    }

    if (
      sessionStorage.projectId !== "null" &&
      previousValues.siteType === value
    ) {
      setPreviousValues((prevState) => ({
        ...prevState,
        useCaseChanged: false,
      }));

      setGridSettings((prevState) => ({
        ...prevState,
        demandCharge: previousValues.demandCharge,
        tariffManagement: previousValues.tariffManagement,
        selfConsumption: previousValues.selfConsumption,
        exportOptimization: previousValues.exportOptimization,
        noExport: previousValues.noExport,
        bessEnergyExchanges:previousValues.bessEnergyExchanges,
        remoteMonitoringControl: previousValues.remoteMonitoringControl[value],
        gridTransitionOutage: previousValues.gridTransitionOutage,
        mode: previousValues.mode,
        gridTransitionOnline: previousValues.gridTransitionOnline,
        manualSwitch: previousValues.manualSwitch,
        gensetBattery: previousValues.gensetBattery,
      }));
    }
  };

  const handlePackageMGSiteTypeChange = (e) => {
    setSiteType(e.target.value);
    if (e.target.value === embLabels.GENSET_PVBESS_A2E) {
      setGridSettings((prevState) => ({
        ...prevState,
        noExport: false,
      }));
    }
    if (
      sessionStorage.projectId !== "null" &&
      previousValues.siteType !== e.target.value
    ) {
      setPreviousValues((prevState) => ({
        ...prevState,
        useCaseChanged: true,
      }));
    }
    if (
      sessionStorage.projectId !== "null" &&
      previousValues.siteType === e.target.value
    ) {
      setPreviousValues((prevState) => ({
        ...prevState,
        useCaseChanged: false,
      }));
    }
  };

  const handleOptimizationChange = (e) => {
    setOptimizationEngine(e.target.value);

    if (e.target.value === "EMA") {
      setOtherOptimizationStrategy("");
    }
    if (e.target.value === "OTHER") {
      setOtherOptimizationStrategy("AUTOGRID");
      setGridSettings((prevState) => ({
        ...prevState,
        demandCharge: false,
        tariffManagement: false,
        selfConsumption: false,
        exportOptimization: false,
        noExport: false,
      }));
    }
  };

  const handleOtherOptimizationChange = (e) => {
    setOtherOptimizationStrategy(e.target.value);
  };

  const handleAutoGridGsxChange = () => {
    setAutogridGSX(!autogridGSX);
  };

  const handleOnGridFeatureChange = (e) => {
    const projectTypeValue = JSON.parse(sessionStorage.getItem("projectType"))?.projectTypeValue ;
    const { name, checked } = e.target;
    setGridSettings((prevState) => ({
      ...prevState,
      [name]: checked,
    }));

    if (
      gridSettings.demandCharge ||
      gridSettings.tariffManagement ||
      gridSettings.selfConsumption ||
      gridSettings.exportOptimization ||
      ([
        "demandCharge",
        "tariffManagement",
        "selfConsumption",
        "exportOptimization",
      ].includes(name) &&
        checked)
    ) {
      setGridSettings((prevState) => ({
        ...prevState,
        remoteMonitoringControl: true,
      }));
    }
    if (   name ===   "demandCharge" && projectTypeValue ==="MGaaS IEC") {
      if(checked){
        setGridSettings((prevState) => ({
          ...prevState,
          tariffManagement: true,
        }));
      }
    }
    if(name==="exportOptimization" &&  projectTypeValue ==="MGaaS IEC"){
      if(checked){
        setGridSettings((prevState) => ({
          ...prevState,
          tariffManagement: true,
        }));
      }
    }
    if(name==="tariffManagement" &&  projectTypeValue ==="MGaaS IEC"){
      if(!checked){
        setGridSettings((prevState) => ({
          ...prevState,
          exportOptimization: false,
          demandCharge: false,
        }));
      }
    }
    // if (name === "tariffManagement" && projectTypeValue ==="MGaaS IEC") {    
    //     setGridSettings((prevState) => ({
    //       ...prevState,
    //       demandCharge: false,
    //     }));      
    // }

    if (previousValues.siteType === siteType) {
      setPreviousValues((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    }
    
  };

  const handleModeChange = (e) => {
    const { name, value } = e.target;
    setGridSettings((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (
      sessionStorage.projectId !== "null" &&
      siteType === embLabels.ISLANDABLE &&
      value !== previousValues.mode &&
      previousValues.siteType !== embLabels.GRID_CONNECTED &&
      previousValues.projectType === projectType
    ) {
      setAlertMessage(
        `Use Case Change Alert`,
        false,
        "",
        informationConst.USE_CASE_CHANGE_ALERT
      );
      setPreviousValues((prevState) => ({
        ...prevState,
        useCaseChanged: true,
      }));
    }


    if (
      sessionStorage.projectId !== "null" &&
      siteType === embLabels.ISLANDABLE_GSX &&
      value !== previousValues.mode &&
      previousValues.siteType === embLabels.ISLANDABLE_GSX &&
      previousValues.projectType === projectType
    ) {
      setAlertMessage(
        `Use Case Change Alert`,
        false,
        "",
        informationConst.USE_CASE_CHANGE_ALERT
      );
      setPreviousValues((prevState) => ({
        ...prevState,
        useCaseChanged: true,
        projectChanged: true,
      }));
    }


  };

  const handleTransitionTypeChange = (e) => {
    try {
      let projectType = localStorage?.getItem("projectType");
      projectType = JSON.parse(projectType);
      let isDaaSANSI = projectType?.displayValue === "ANSI | Buildings | Autogrid Flex" || projectType?.projectTypeValue === "DaaS ANSI"
      const { name, value } = e.target;
      if (projectType && !isDaaSANSI && ["FAST", "CLOSE"].includes(value)) {
        setAlertMessage(
          `Alert`,
          false,
          "",
          "Option not available for configuration"
        );
      } else {
        setGridSettings((prevState) => ({
          ...prevState,
          [name]: value,
        }));

        setPreviousValues((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };


  const handleRemoteMonitoringChange = (e) => {

    const { name, checked } = e.target;

    if (
      gridSettings.demandCharge ||
      gridSettings.tariffManagement ||
      gridSettings.selfConsumption ||
      gridSettings.exportOptimization
    ) {
      setGridSettings((prevState) => ({
        ...prevState,
        remoteMonitoringControl: true,
      }));
      setPreviousValues((prevState) => ({
        ...prevState,
        remoteMonitoringControl: {
          ...prevState.remoteMonitoringControl,
          [siteType]: true,
        },
      }));
    } else {
     
      if(  [embLabels.ISLANDABLE_GSX, embLabels.GRID_CONNECTED_GSX].includes(siteType) &&
        [ embLabels.PV_BESS_NR,embLabels.PV_GENSET_ATS].includes(architectureType)){
          setOptimizationEngine("OTHER");
          setAutogridGSX(true);
        }
     
      setGridSettings((prevState) => ({ ...prevState, [name]: checked }));
      setPreviousValues((prevState) => ({
        ...prevState,
        remoteMonitoringControl: {
          ...prevState.remoteMonitoringControl,
          [siteType]: checked,
        },
      }));

      if (!checked) {
        if(  [embLabels.ISLANDABLE_GSX, embLabels.GRID_CONNECTED_GSX].includes(siteType) &&
        [ embLabels.PV_BESS_NR,embLabels.PV_GENSET_ATS].includes(architectureType)){
          setOptimizationEngine("OTHER");
          setAutogridGSX(true);
        }else{
            
        setOptimizationEngine("EMA");
        setOtherOptimizationStrategy("");
        }

       
      }
    }
  };
  const handleSwitchboardTypeChangeGSX = (e) => {
    const { value } = e.target;
    setSwitchboardType(value);
    setArchitectureType(projectCreated ? architectureType : null);
    if (
      !projectCreated &&
      [embLabels.Existing_Switchboard, embLabels.New_Switchboard].includes(value)
    ) {
      defaultGSXUsecaseValues();
    }

    // if (projectCreated && previousValues.switchboardType !== value) {
    //   setAlertMessage(
    //     `Switch board Change Alert`,
    //     false,
    //     "",
    //     `Switch board change will affect asset's design and configuration !`
    //   );
    //   setPreviousValues((prevState) => ({
    //     ...prevState,
    //     projectChanged: true,
    //     useCaseChanged: true,
    //   }));
    //   setArchitectureType(null);
    //   defaultGSXUsecaseValues();
    // }
  }
  const handleSiteTypeChangeGSX = (e) => {
    const { value } = e.target;
    setSiteType(value);
    setArchitectureType(projectCreated ? architectureType : null);
   
    if (
      !projectCreated &&
      [embLabels.ISLANDABLE_GSX, embLabels.GRID_CONNECTED_GSX].includes(value)
    ) {
      defaultGSXUsecaseValues();
    }

    if (projectCreated && previousValues.siteType !== value) {
      setAlertMessage(
        `Use Case Change Alert`,
        false,
        "",
        informationConst.USE_CASE_CHANGE_ALERT
      );
      setPreviousValues((prevState) => ({
        ...prevState,
        projectChanged: true,
        useCaseChanged: true,
      }));
      setArchitectureType(null);
      defaultGSXUsecaseValues();
    }
    if (projectCreated && previousValues.siteType === value) {
      setArchitectureType(previousValues.architectureType);
      setPreviousValues((prevState) => ({
        ...prevState,
        projectChanged: false,
        useCaseChanged: false,
      }));
      setGridSettings((prevState) => ({
        ...prevState,
        demandCharge: previousValues.demandCharge,
        tariffManagement: previousValues.tariffManagement,
        selfConsumption: previousValues.selfConsumption,
        exportOptimization: previousValues.exportOptimization,
        noExport: previousValues.noExport,
        bessEnergyExchanges:previousValues.bessEnergyExchanges,
        remoteMonitoringControl: true,
        gridTransitionOutage: previousValues.gridTransitionOutage,
        mode: "",
        gridTransitionOnline: previousValues.gridTransitionOnline,
        manualSwitch: previousValues.manualSwitch,
        gensetBattery: previousValues.gensetBattery,
      }));
      setSiteInformation((prevState) => ({
        ...prevState,
        userInterface: previousValues.userInterface,
      }));

    }
    if ([embLabels.GRID_CONNECTED_GSX].includes(value)) {
      // setProjectType("STANDARD_MICROGRID");
      setSiteType(value);
      setArchitectureType("PV_BESS_NR");
      setGridSettings((prevState) => ({
        ...prevState,
        remoteMonitoringControl: true,
        noExport: true,
      }));
      setOptimizationEngine("OTHER");
      setAutogridGSX(true);
      // setOtherOptimizationStrategy("AUTOGRID")
    }
    if ([embLabels.ISLANDABLE_GSX].includes(value)) {
      
       setSiteType(value);
      setArchitectureType("PV_GENSET_ATS");
      setAutogridGSX(true);
    }
  };

  const handleArchitectureTypeChangeGSX = (e) => {
    const { value } = e.target;
    setArchitectureType(value);
  };

  const defaultGSXUsecaseValues = () => {
    setGridSettings((prevState) => ({
      ...prevState,
      demandCharge: false,
      tariffManagement: false,
      selfConsumption: false,
      exportOptimization: false,
      noExport: true,
      remoteMonitoringControl: true,
      mode: "",
      gridTransitionOutage: "OPEN",
      manualSwitch: "OPEN",
      gridTransitionOnline: "OPEN",
      gensetBattery: "OPEN",
    }));
    setSiteInformation((prevState) => ({
      ...prevState,
      userInterface: "none",
      gridNominalFrequency: 60,
      gridNominalVoltage: 480,
    }));
  };

  const handleOnGridFeatureChangeGSX = (e) => {
    const { name, checked } = e.target;
    setGridSettings((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    if (previousValues.architectureType === architectureType) {
      setPreviousValues((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    }
  };

  //* PROJECT TYPE END

  //* SITE INFO START

  const handlSiteInformationChange = (e) => {
    const { name, value } = e.target;
    if (name === "gridNominalVoltage") {
      if (!isNaN(value)) {
        setSiteInformation((prevState) => ({ ...prevState, [name]: value }));
      }
    } else {
      setSiteInformation((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const locationCallback = (data) => {
    setLocationData(data);
  };

  const addUserProject = () => {
    setLoading(true);
    apiSession
      .getProjectRoles()
      .then((response) => {
        setUserRoles((prevState) => ({
          ...prevState,
          addUserPopUpstatus: true,
          rolesList: response.userRoles,
          rolesPermission: response.roleDetails,
        }));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        callSnackbar("error", informationConst.USER_ERROR);
      });
  };

  const addUserPopUp = (email, role) => {
    setUserRoles((prevState) => ({ ...prevState, addUserPopUpLoading: true }));
    const userData = [...userRoles.addUserPopUpList];
    let findIndex = -1;
    let findIndexUser = -1;
    if (userData.length) {
      findIndex = userData.findIndex(
        (obj) => obj.email.toLowerCase() === email.toLowerCase()
      );
    }
    if (findIndex === -1) {
      const usersList = [...users];
      if (usersList.length) {
        findIndexUser = usersList.findIndex(
          (obj) => obj.email.toLowerCase() === email.toLowerCase()
        );
      }
      if (findIndexUser === -1) {
        let popUpUsers = {};
        popUpUsers.email = email;
        popUpUsers.role = role;
        userData.push(popUpUsers);
      } else {
        callSnackbar("error", informationConst.USER_EXISTS_ERROR);
      }
    } else {
      callSnackbar("error", informationConst.USER_EXISTS_ERROR);
    }
    setUserRoles((prevState) => ({ ...prevState, addUserPopUpList: userData }));
    setUserRoles((prevState) => ({ ...prevState, addUserPopUpLoading: false }));
  };

  const deleteUserPopUp = (email) => {
    const userData = userRoles.addUserPopUpList.filter(
      (obj) => obj.email.toLowerCase() !== email.toLowerCase()
    );
    setUserRoles((prevState) => ({ ...prevState, addUserPopUpList: userData }));
  };

  const closeAddUser = () => {
    setUserRoles((prevState) => ({
      ...prevState,
      addUserPopUpstatus: false,
      addUserPopUpList: [],
    }));
  };

  const addUserToProjectList = () => {
    setUserRoles((prevState) => ({ ...prevState, addUserPopUpLoading: true }));

    const projectUsersList = [...users];
    let usersListToBeAdded = [];

    userRoles.addUserPopUpList.forEach((userData) => {
      usersListToBeAdded.push(userData);
    });

    if (usersListToBeAdded.length) {
      if (sessionStorage.getItem("projectId") !== "null") {
        apiSession
          .addUsersToProject({
            projectId: parseInt(sessionStorage.getItem("projectId")),
            users: usersListToBeAdded,
          })
          .then((response) => {
            let userData = response;
            // usersListToBeAdded.map(usrData => {
            usersListToBeAdded.forEach((usrData) => {
              var indexUsr = userData.findIndex(
                (obj) => obj.email.toLowerCase() === usrData.email.toLowerCase()
              );
              if (indexUsr !== -1) {
                userData[indexUsr].role = usrData.role;
                userData[indexUsr].status = "I";
                projectUsersList.push(userData[indexUsr]);
              }
              // return null;
            });
            setUserRoles((prevState) => ({
              ...prevState,
              addUserPopUpstatus: false,
              addUserPopUpLoading: false,
              addUserPopUpList: [],
            }));
            setUsers(projectUsersList);
            callSnackbar("success", informationConst.USER_INVITED); 
          })
          .catch((error) => {
            if (error.response !== undefined) {
              if (error.response.status === 404) {
                setAlertMessage(
                  `Alert`,
                  false,
                  "",
                 informationConst. USER_NOT_AUTHORIZED
                );
              } else {
                callSnackbar("error", informationConst.USER_INVITED_ERROR);
              }
            } else {
              callSnackbar("error", informationConst.USER_INVITED_ERROR);
            }
            setUserRoles((prevState) => ({
              ...prevState,
              addUserPopUpstatus: false,
              addUserPopUpLoading: false,
              addUserPopUpList: [],
            }));
          });
      } else {
        //usersListToBeAdded.map((usrData, index) => {
        usersListToBeAdded.forEach((usrData, index) => {
          let indexUsr = users.findIndex(
            (obj) => obj.email.toLowerCase() === usrData.email.toLowerCase()
          );
          if (indexUsr === -1) {
            usersListToBeAdded[index].firstName = "";
            usersListToBeAdded[index].lastName = "";
            usersListToBeAdded[index].phoneNumber = "";
            usersListToBeAdded[index].employer = "";
            usersListToBeAdded[index].userId = 0;
            usersListToBeAdded[index].status = "I";
            projectUsersList.push(usersListToBeAdded[index]);
          }
          // return null;
        });

        setUserRoles((prevState) => ({
          ...prevState,
          addUserPopUpstatus: false,
          addUserPopUpLoading: false,
          addUserPopUpList: [],
        }));
        setUsers(projectUsersList);
        callSnackbar(
          "success",
          informationConst.USER_UPDATE
        );
      }
    } else {
      setUserRoles((prevState) => ({
        ...prevState,
        addUserPopUpLoading: false,
        addUserPopUpList: [],
        addUserPopUpStatus: false,
      }));
    }
  };

  const removeInvitedUserPopUp = (userId, role) => {
    setDeleteInvitedUser(
      true,
      `Do you confirm to delete ${role} from the project ?`
    );
    setDeletingUserInfo({
      deleteingRole: role,
      deleteingUserId: userId,
    });
  };

  const closeDeleteInvitedUserPopUp = () => {
    setDeleteInvitedUser(false, ``);
  };

  const removeInvitedUser = () => {
    if (deletingUserInfo.deleteingUserId) {
      removeUserFromProject(deletingUserInfo.deleteingUserId);
    }
    closeDeleteInvitedUserPopUp();
  };

  const removeUserFromProject = (userId) => {
    if (userId === 0) {
      let userData = [...users];
      var index = userData.findIndex((obj) => obj.userId === userId);
      if (index !== -1) {
        delete userData[index];
        userData = userData.filter(function (dataElement) {
          return dataElement !== undefined;
        });
        setUsers(userData);
        callSnackbar(
          "success",
          "User is removed from the project, Please Click validate to update."
        );
      }
    } else {
      apiSession
        .removeUserFromProject({
          userId: userId,
          projectId: sessionStorage.getItem("projectId"),
        })
        .then(() => {
          let userData = [...users];
          let index = userData.findIndex((obj) => obj.userId === userId);
          if (index !== -1) {
            delete userData[index];
            userData = userData.filter(function (dataElement) {
              return dataElement !== undefined;
            });
            setUsers(userData);
            callSnackbar("success", informationConst.USER_REMOVED); 
          }
        })
        .catch((error) => {
          if (error.response !== undefined) {
            if (error.response.status === 404) {
              setAlertMessage(
                `Alert`,
                false,
                "",
                informationConst.YOUR_NOT_AUTHORIZED
              );
            } else {
              callSnackbar(
                "error",
                informationConst.USER_REMOVED_ERROR
              );
            }
          } else {
            callSnackbar(
              "error",
              informationConst.USER_REMOVED_ERROR
            );
          }
        });
    }
  };

  const reinviteToProject = (userData) => {
    let usersListToBeAdded = userData;
    setLoading(true);
    apiSession
      .reinviteToProject({
        projectId: parseInt(sessionStorage.getItem("projectId")),
        email: userData.email,
        role: userData.role,
      })
      .then(() => {
        updateReInvitedUsers(usersListToBeAdded);
        callSnackbar("success", informationConst.RE_INVITE);
        setLoading(false);
      })
      .catch(() => {
        callSnackbar("error", informationConst.RE_INVITE_ERROR);
        setLoading(false);
      });
  };

  const updateReInvitedUsers = (userReinvited) => {
    let projectUsersList = [...users];
    // projectUsersList.map((userData, index) => {
    projectUsersList.forEach((userData, index) => {
      if (userData.email === userReinvited.email) {
        projectUsersList[index].status = "I";
      }
      //return null;
    });
    setUsers(projectUsersList);
  };

  //* SITE INFO END

  //* Validation Start

  const onBackButtonClick = () => {
    let { history } = props;
    history.push({
      pathname: "/project",
    });
  };

  const onValidateClick = () => {
    const projectStatus = findProjectTypeHandler();
    setDisableValidateProject(true);
    if (UserScope.access.saveProjects) {
      setMandatoryMsgDisplay(true);

      let result = null;
      let locationDataValidate = _.cloneDeep(locationData);
      result = LocationFinder.validateLocation(locationDataValidate);
      const LANGUAGE = "en";
      const VALIDATION_LABELS =
        LocationFinder.getValidationLabelsForLanguage(LANGUAGE);
      //* project info
      if (!projectInformation.projectName) {
        callSnackbar(`error`, `Project name is mandatory`);
        enableValidateFunction();
      } else if (!projectInformation.clientName) {
        callSnackbar(`error`, `Client name is mandatory`);
        enableValidateFunction();
      } else if (!projectInformation.siteName) {
        callSnackbar(`error`, `Site name is mandatory`);
        enableValidateFunction();
      } else if (projectInformation.currency === "") {
        callSnackbar(`error`, `Currency is mandatory`);
        enableValidateFunction();
      } else if (projectInformation.temperatureUnit === "") {
        callSnackbar(`error`, `Temerature unit is mandatory`);
        enableValidateFunction();
      }

      //* project type
      else if (!projectType) {
        callSnackbar(`error`, `Project Type is mandatory`);
        enableValidateFunction();
      } else if (!siteType) {
        callSnackbar(`error`, `Site Type  selection is mandatory`);
        enableValidateFunction();
      } else if (siteType === embLabels.OFF_GRID) {
        callSnackbar(
          `error`,
          `Your have selected Coming soon Site Type, Please select  Always grid connected site or Islandable site`
        );
        enableValidateFunction();
      } else if (
        projectStatus === embLabels.GENERIC_PROJECT &&
        siteType === embLabels.GRID_CONNECTED &&
        !gridSettings.remoteMonitoringControl
      ) {
        callSnackbar(
          `error`,
          `Remote energy monitoring and forecast is mandatory for Always grid connected site usecase`
        );
        enableValidateFunction();
      } else if (
        (projectStatus === "GENERIC" || projectStatus === "GREENSTRUXURE") &&
        (siteType === "ISLANDABLE" || siteType === "ISLANDABLE_GSX") &&
        (gridSettings.mode === "" || !gridSettings.mode)
      ) {
        callSnackbar(
          `error`,
          `Islandable site Asset Configuration selection is mandatory`
        );
        enableValidateFunction();
      } else if (
        projectStatus === embLabels.GREENSTRUXURE_PROJECT &&
        architectureType === null
      ) {
        callSnackbar(`error`, `Architecture Type is mandatory`);
        enableValidateFunction();
      }
      //* site info
      else if (!siteInformation.gridNominalFrequency) {
        callSnackbar(`error`, `Grid nominal frequency is mandatory`);
        enableValidateFunction();
      } else if (!siteInformation.gridNominalVoltage) {
        callSnackbar(`error`, `Grid nominal voltage is mandatory`);
        enableValidateFunction();
      } else if (
        parseInt(siteInformation.gridNominalVoltage) < 100 ||
        parseInt(siteInformation.gridNominalVoltage) > 700
      ) {
        callSnackbar(
          `error`,
          `Grid nominal voltage should be in range (100-700 V)`
        );
        enableValidateFunction();
      } else if (result) {
        callSnackbar(`error`, VALIDATION_LABELS[result]);
        enableValidateFunction();
      } else {
        createProject();
      }
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
      enableValidateFunction();
    }
  };

  const enableValidateFunction = () => setDisableValidateProject(false);

  const createProject = () => {

    const projectStatus = findProjectTypeHandler();

    const usersList = [];
    users.forEach((userData) => {
      let data = {
        email: userData.email,
        role: userData.role,
      };
      usersList.push(data);
    });

    const mode = findProjectTypeHandler() === embLabels.GENERIC_PROJECT ? siteType === embLabels.ISLANDABLE ? gridSettings.mode : "NO_FUEL" : [embLabels.GREENSTRUXURE_PROJECT, embLabels.GREENSTRUXURE_MICROGRID].includes(findProjectTypeHandler()) ? !!(gridSettings.mode) ? gridSettings.mode : null : null
    const newProject = projectInformation.projectType === "" ? projectInformation.projectTypeValue : projectInformation.projectType;
    localStorage.setItem("pType", projectInformation.projectDisplayName);
    const projectId = sessionStorage.getItem("projectId");
    if (projectId !== null || projectId !== "") {
      if (previousValues.siteType === "GRID_CONNECTED" ||
        previousValues.siteType === "GENSET_PVBESS_A2E" ||
        (newProject === "MGaaS ANSI" && ["ISLANDABLE", ""].includes(previousValues.siteType) ||
          (newProject === "MGaaS IEC" && ["ISLANDABLE", ""].includes(previousValues.siteType)) ||
          previousValues.siteType === "PV_HYBRIDIZATION") ||
        previousValues.siteType === "" && newProject === "MGaaS AccessToEnergy") {
        setSwitchboardType("EXISTING_SWITCHBOARD");
      } else {
        setSwitchboardType("NEW_SWITCHBOARD");
      }
    }
    const jsonBody = {
      projectName: projectInformation.projectName,
      createdBy: "1",
      createdOn: createdOn,
      programPage: true,
      diagramChangeRequired:
        siteType === previousValues.siteType ? false : true,
      projectType: newProject,
      q2cOrderNumber: projectInformation.q2cOrderNumber || null,
      switchboardType: switchboardType,
      // organization:selectedOrganization,
      // organization:organization,
      // switchboardType:(previousValues.siteType === "GRID_CONNECTED" || 
      // previousValues.siteType === "GENSET_PVBESS_A2E" ||
      // (newProject ==="MGaaS ANSI" && ["ISLANDABLE",""].includes(previousValues.siteType ) || 
      // (newProject ==="MGaaS IEC" && ["ISLANDABLE",""].includes(previousValues.siteType ))||
      // previousValues.siteType === "PV_HYBRIDIZATION" )||
      // previousValues.siteType === "" && newProject === "MGaaS AccessToEnergy")?"EXISTING_SWITCHBOARD":"NEW_SWITCHBOARD",
      projectInfo: {
        projectType: projectType,
        siteType: siteType,


        gridSettings: {
          demandCharge: gridSettings.demandCharge,
          tariffManagement: gridSettings.tariffManagement,
          selfConsumption: gridSettings.selfConsumption,
          exportOptimization: gridSettings.exportOptimization,
          noExport: gridSettings.noExport,
          bessEnergyExchanges:gridSettings.bessEnergyExchanges,
          noImport:gridSettings.noImport,
          minSocMgmt:gridSettings.minSocMgmt,
          manualDemandResponse:gridSettings.manualDemandResponse,
          // offGridMode:gridSettings.offGridMode,
          offGridMode:false,
          remoteMonitoringControl: gridSettings.remoteMonitoringControl,
          mode: mode,
          greenConsumption:gridSettings.greenConsumption,
          stormPreparedness:gridSettings.stormPreparedness,
          // findProjectTypeHandler() === embLabels.GENERIC_PROJECT ? siteType === embLabels.ISLANDABLE ? gridSettings.mode : "NO_FUEL" : findProjectTypeHandler() === embLabels.GREENSTRUXURE_PROJECT ? gridSettings.mode === "FUEL_SAVER" ? "FUEL_SAVER" : null : null,
          gridTransitionOutage: gridSettings.gridTransitionOutage,
          manualSwitch: gridSettings.manualSwitch,
          gridTransitionOnline: gridSettings.gridTransitionOnline,

          isIslandable: siteType === embLabels.ISLANDABLE ? true : false,
        },
        clientName: projectInformation.clientName,
        siteName: projectInformation.siteName,
        currency: projectInformation.currency,
        temperatureUnit: projectInformation.temperatureUnit,
        description: projectInformation.description,


        userInterface: siteInformation.userInterface,
        gridNominalFrequency: siteInformation.gridNominalFrequency,
        gridNominalVoltage: siteInformation.gridNominalVoltage,
      },
      location: locationData,

      architecture: architectureType,
      clientType: findProjectTypeHandler(),
      externalId: projectInformation.externalId
        ? projectInformation.externalId
        : null,
    };
   if(["DaaS ANSI","DaaS IEC"].includes(projectInformation?.projectTypeValue)){
      jsonBody.organization = selectedOrganization
   }
   if(!gridSettings.remoteMonitoringControl){
    jsonBody.organization  = null;
   }
    dispatch(upDateProjectType(
      {
        projectType:projectInformation?.projectDisplayName,
        projectName:projectInformation?.projectName,
      }
    ))
   

    if (
      sessionStorage.projectId !== "null" &&
      siteType === embLabels.ISLANDABLE_GSX &&
      gridSettings.mode !== previousValues.mode &&
      previousValues.siteType === embLabels.ISLANDABLE_GSX &&
      previousValues.projectType === projectType
    ) {

      jsonBody.diagramChangeRequired = true
    }



    if (projectStatus === embLabels.GENERIC_PROJECT) {
      jsonBody.server = {
        optimizationType:
          optimizationEngine === "EMA"
            ? optimizationEngine
            : otherOptimizationStrategy,
      };
    }
    if (projectStatus === embLabels.GREENSTRUXURE_PROJECT) {
      jsonBody.server = {
        optimizationType: autogridGSX ? "AUTOGRID" : "EMA",
      };
    }
    if (projectStatus === embLabels.A2E_PROJECT) {
      jsonBody.server = {
        optimizationType: "EMA",
      };
    }

    currencyList.forEach((currencyData) => {
      if (currencyData.name === projectInformation.currency) {
        localStorage.setItem("currency", currencyData.symbol);
      }
    });

    if ([embLabels.ISLANDABLE, embLabels.OFF_GRID].includes(siteType)) {
      jsonBody.projectInfo.gridSettings.gridTransitionOutage =
        gridSettings.gridTransitionOutage;
      jsonBody.projectInfo.gridSettings.gridTransitionOnline =
        gridSettings.gridTransitionOnline;
      jsonBody.projectInfo.gridSettings.manualSwitch =
        gridSettings.manualSwitch;
      if (gridSettings.mode === "FUEL_ERASER") {
        jsonBody.projectInfo.gridSettings.gensetBattery =
          gridSettings.gensetBattery;
      }
    }

    localStorage.setItem("userCountry", locationData.country);

    if (sessionStorage.getItem("projectId")) {
      jsonBody.projectId = sessionStorage.getItem("projectId");
      jsonBody.lastModifiedBy = 1;
    }


    apiSession
      .updateProject(jsonBody)
      .then((json) => {
        localStorage.setItem("pname", projectInformation.projectName);
        sessionStorage.setItem("projectId", json.projectId);
        localStorage.setItem("programPage", true);
        localStorage.setItem("optimizationModified", true);
        localStorage.setItem("useCaseChanged", previousValues.useCaseChanged);
        localStorage.setItem("projectChanged", previousValues.projectChanged);

        if (json.projectId) {
          apiSession
            .addUsersToProject({ projectId: json.projectId, users: usersList })
            .then(() => { })
            .catch(() => { });
        }
        if (selectedFile) {
          uploadImage();
        } else {
          setAlertMessage(
            `Project Status`,
            true,
            `assets`,
            `Project ${projectCreateStatus ? "Created" : "Updated"
            } Successfully. `
          );
        }
        enableValidateFunction();

      })
      .catch((error) => {
        enableValidateFunction();
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            if(error.response.data.message === "Unsupported organisation.Please enter valid organization."){
              callSnackbar(`error`, `Unsupported organisation, Please enter valid organization.`);
            }else{
              callSnackbar(`error`, `${error.response.data.message}`);
            }
           
          } else {
            callSnackbar(`error`, `${error.response.data.message}`);
          }
        } else {
          callSnackbar(`error`, `Error in validation`);
        }
      });

  };

  //* Validation End

  const callSnackbar = useCallback((type, message) => {
    setSnackBar({ type, message });
    snackBarRef.current.open = true;
  }, []);

  const setDeleteImage = (type, message) => {
    setDeleteImagePopUpStatus({
      confirmPopupStatus: type,
      confirmMessage: message,
    });
  };

  const setDeleteInvitedUser = (type, message) => {
    setDeleteInvitedUserPopUpStatus({
      confirmPopupStatus: type,
      confirmMessage: message,
    });
  };

  const setAlertMessage = (title, isRoute, routePath, message) => {
    setAlert({
      title,
      isRoute,
      routePath,
      message,
      alertPopUpStatus: true,
    });
  };

  const closeAlertMessage = useCallback(() => {
    setAlert({
      message: "",
      alertPopUpStatus: false,
      title: "",
      isRoute: false,
      routePath: "",
    });
    if (alert.isRoute) {
      let { history } = props;
      history.push({
        pathname: `/${alert.routePath}`,
      });
    }
  }, [alert, props]);

  const findProjectTypeHandler = () => {
    let projectType = JSON.parse(sessionStorage.getItem("projectType"));
    const status = projectCreated
      ? clientType === embLabels.GENERIC_PROJECT
        ? embLabels.GENERIC_PROJECT
        : clientType === embLabels.GREENSTRUXURE_PROJECT
          ? embLabels.GREENSTRUXURE_PROJECT
          : clientType === embLabels.A2E_PROJECT
            ? embLabels.A2E_PROJECT
            : "none"
      : projectType?.tag === embLabels.GREENSTRUXURE_PROJECT
        ? embLabels.GREENSTRUXURE_PROJECT
        : projectType?.tag === embLabels.GENERIC_PROJECT
          ? embLabels.GENERIC_PROJECT
          : projectType?.tag === embLabels.A2E_PROJECT
            ? embLabels.A2E_PROJECT
            : "none";

    //let projectType = JSON.parse(sessionStorage.getItem("projectType"));

    // const status =
    //   projectType.tag === embLabels.GENERIC_PROJECT
    //     ? embLabels.GENERIC_PROJECT
    //     : projectType.tag === embLabels.GREENSTRUXURE_PROJECT
    //     ? embLabels.GREENSTRUXURE_PROJECT
    //     : projectType.tag === embLabels.A2E_PROJECT
    //     ? embLabels.A2E_PROJECT
    //     : "none";

    return status;
  };
  const handleOrganizationChange = (e)=>{
    const {value} =e.target;
    // const filterOrganization = organization?.filter(data=>data?.organization === value)[0];
    // setSelectedOrganization(filterOrganization);

    setSelectedOrganization({organizationKey:value})
   }

  //*JSX
  return (
    <MyErrorBoundary>
      <div className=" container-fluid h-100 container-margin">
        <se-loading loading={loading}></se-loading>
        {loading ? (
          <div className="stickyProgressBar">
            <div className="d-flex justify-content-between">
              <se-skeleton  height="20px" style={{width:"33%"}}></se-skeleton>
              <se-skeleton  height="20px" style={{width:"33%"}}></se-skeleton>
              <se-skeleton  height="20px" style={{width:"33%"}}></se-skeleton>
            </div>
          </div>
        ) : (
          sessionStorage.getItem("projectId") != "null" && (
            projectStatusData && <ProgressBarContainer projectStatusData={projectStatusData} />
          )
        )}

        {!UserScope.access.saveProjects && (
          <div className="col-sm-12 text-center mt-1">
            <span className="se-error-message-note text-center">
              {"Information: " +
                UserScope.role +
                " is not authorized to perform action on this page but he can invite new user to the Project to be a collaborator"}
            </span>
          </div>
        )}
        <div className="row w-100">
          {(projectStatusData?.projectStatus === "COMPLETED") && (
            <div className="col-sm-12 text-center mt-1">
              <span className="se-error-message-note text-center">
                {`Change the project state to in-progress to update the project`}
              </span>
            </div>
          )}
          {(siteCreated) && (
            <div className="col-sm-12 text-center mt-1">
              <span className="se-error-message-note text-center">
                {`Site already created for the project, no more modifications allowed`}
              </span>
            </div>
          )}
        </div>
        <se-container option="centered" >
          <div className={`"container mb-1"`}>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background pr-5 pl-5">


                {/* PROJECT INFO*/}

                <ProjectInformation
                  UserScope={UserScope}
                  projectInformation={projectInformation}
                  currencyList={currencyList}
                  loadingImage={loadingImage}
                  imagePreviewUrl={imagePreviewUrl}
                  mandatoryMsgDisplay={mandatoryMsgDisplay}
                  onProjectChangeHandler={projectChangeHandler}
                  onFileChangedHandler={fileChangedHandler}
                  onRemoveProjectImagePopUp={removeProjectImagePopUp}
                  onFindProjectTypeHandler={findProjectTypeHandler}
                  informationToolTIP={informationToolTIP}
                  organization={organization}
                  handleOrganizationChange={handleOrganizationChange}
                  selectedOrganization = {selectedOrganization}
                  projectStatusData={projectStatusData}
                  siteCreated={siteCreated}
                />

                {/*PROJECT TYPE*/}
                <div className="row">
                  <InformationPageProvider
                    value={{
                      UserScope,
                      useCaseHMIData,
                      projectType,
                      siteType,
                      switchboardType,
                      clientType,
                      informationToolTIP,
                      organization,
                      selectedOrganization,
                      handleOrganizationChange,
                      disabledFeatures,
                      optimizationEngine,
                      otherOptimizationStrategy,
                      gridSettings,
                      siteInformation,
                      locationData,
                      mandatoryMsgDisplay,
                      users,
                      disableValidateProject,
                      renderLocationComponent,
                      architectureType,
                      autogridGSX,
                      projectInformation,
                      handleProjectTypeChange,
                      handleSiteTypeChange,
                      handleSiteTypeChangeGSX,
                      handleSwitchboardTypeChangeGSX,
                      handleArchitectureTypeChangeGSX,
                      handleOnGridFeatureChangeGSX,
                      handleAutoGridGsxChange,
                      handlePackageMGSiteTypeChange,
                      handleOptimizationChange,
                      handleOtherOptimizationChange,
                      handleOnGridFeatureChange,
                      handleModeChange,
                      handleTransitionTypeChange,
                      handleRemoteMonitoringChange,
                      handlSiteInformationChange,
                      setSiteInformation,
                      locationCallback,
                      addUserProject,
                      removeInvitedUserPopUp,
                      reinviteToProject,
                      onBackButtonClick,
                      onValidateClick,
                      findProjectTypeHandler,
                    }}
                  >
                    <ProjectType  
                    projectStatusData={projectStatusData}
                  siteCreated={siteCreated}
                  />
                    <SiteInformation projectStatusData={projectStatusData}
                  siteCreated={siteCreated} />
                  </InformationPageProvider>
                </div>
              </div>
            </div>
          </div>
          <InviteUser
            userRoles={userRoles}
            addUserPopUp={addUserPopUp}
            deleteUserPopUp={deleteUserPopUp}
            closeAddUser={closeAddUser}
            addUserToProjectList={addUserToProjectList}
          />

          <Snackbar snackBar={snackBar} ref={snackBarRef} />

          <Alert alert={alert} onClose={closeAlertMessage} />

          {deleteImagePopUpStatus.confirmPopupStatus && (
            <Confirm
              confirm={deleteImagePopUpStatus}
              onOk={removeProjectImage}
              onClose={closeDeleteImagePopUp}
            />
          )}

          {deleteInvitedUserPopUpStatus.confirmPopupStatus && (
            <Confirm
              confirm={deleteInvitedUserPopUpStatus}
              onOk={removeInvitedUser}
              onClose={closeDeleteInvitedUserPopUp}
            />
          )}
        </se-container>
      </div>
    </MyErrorBoundary>

  );
};

export default InformationHook;