export function assignIpAddress(
  compontentsData,
  defaultIpAddress,
  CBAssetList = [],
  CBLoadList = [],
  CBEVList = []
) {
  let ipCount = 1;
  const indexes = groupCB(compontentsData, CBAssetList, CBLoadList, CBEVList);
  for (let data in indexes) {
      const value = indexes?.[data]?.cbValue;
      indexes?.[data]?.cbArray?.forEach((item, index) => {
      if (
        compontentsData[item]?.componentType === "Circuit Breaker" &&
        compontentsData[item].communications[0].ip != ""
      ) {
        compontentsData[item].communications[0].ip =
          defaultIpAddress[`ifeIpAddress${ipCount}`];
        if (((index + 1) % value == 0)||
        (indexes[data].cbArray?.length>0&&(index + 1 == indexes[data]?.cbArray?.length))){
             ipCount++;
        }
      }
    });
  }
  return compontentsData;
}

function groupCB(data = [], CBAssetList = [], CBLoadList = [], CBEVList = []) {
  const groupCB = {
    ife7: { cbValue: 7, cbArray: [] },
    ife6: { cbValue: 6, cbArray: [] },
    ife5: { cbValue: 5, cbArray: [] },
    ife4: { cbValue: 4, cbArray: [] },
    ife3: { cbValue: 3, cbArray: [] },
    ife2: { cbValue: 2, cbArray: [] },
    ife1: { cbValue: 1, cbArray: [] },
  };
  data.forEach((item, index) => {
    if (
      item?.componentType === "Circuit Breaker" &&
      item?.communications?.[0]?.ip != ""&&item
    ) {
      CBAssetList.length > 0 && CBAssetList?.forEach((cbData) => {
        if (cbData?.cbConfigData?.name == item?.componentName) {
          for (let i in groupCB) {
            if (
              cbData?.cbConfigData?.cbAssetDetails?.[0]?.assetAttributes?.[0]?.value == groupCB[i].cbValue
            ) {
              groupCB?.[i]?.cbArray.push(index);
            }
          }
        }
      });
      CBEVList?.forEach((evData) => {
        if (evData?.cbConfigData?.name == item?.componentName) {
          for (let i in groupCB) {
            if (
                evData?.cbConfigData?.cbAssetDetails?.[0]?.assetAttributes?.[0]?.value == groupCB[i].cbValue
            ) {
              groupCB?.[i]?.cbArray.push(index);
            }
          }
        }
      });
      CBLoadList?.forEach((loadData) => {
        if (loadData?.cbConfigData?.name == item?.componentName) {
          for (let i in groupCB) {
            if (
                loadData?.cbConfigData?.cbAssetDetails?.[0]?.assetAttributes?.[0]?.value == groupCB[i].cbValue
            ) {
              groupCB?.[i]?.cbArray.push(index);
            }
          }
        }
      });
    }
    const uiAttributes = JSON.parse(item.uiAttributes);
    if(uiAttributes?.contactor==false){
      delete uiAttributes.contactor;
      data[index].uiAttributes = JSON.stringify(uiAttributes);
    }
  });
  return groupCB;
}
