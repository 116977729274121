import React from "react";
import MyErrorBoundary from "../../utils/ErrorBoundary";

function renderTable(data, keysToSkip) {
  return (
    <table className="table table-bordered mb-0">
      <tbody>
        {data &&
          typeof data === "object" &&
          Object?.keys(data).map((key) => {
            if (!keysToSkip.includes(key)) {
              return (
                <tr key={key}>
                  <td>
                    {(key.charAt(0).toUpperCase() + key.slice(1)).replace(
                      /(?<!^)([A-Z])/g,
                      " $1"
                    )}
                  </td>
                  <td>
                    {typeof data[key] === "object"
                      ? data[key][0]?.type
                      : data[key]}
                  </td>
                </tr>
              );
            }
            return null;
          })}
      </tbody>
    </table>
  );
}

const viewSubscriptionDetails = ({
  viewSubscriptionDetails,
  dscPopupStatus,
  closeViewPopup,
}) => (
  <MyErrorBoundary>
    <se-dialog
      id="dialog-complex-view"
      size="large"
      open={dscPopupStatus}
      can-backdrop="false"
    >
      <se-dialog-header>Subscription Details</se-dialog-header>

      <se-dialog-content className="se-dialog-content">
        <div className="container ">
          <div className="col-md-12">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="table-responsive">
                  <table className="table table-bordered mb-0">
                    <tbody>
                      <tr>
                        <td>
                          <span className="se-life-green se-font-14px-bold ">
                            Billing Account Details
                          </span>
                          {renderTable(
                            viewSubscriptionDetails?.billingAccount,
                            [
                              "goldenId",
                              "crmAccountId",
                              "status",
                              "erpId",
                              "currency",
                              "shipToEmailCc",
                            ]
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span className="se-life-green se-font-14px-bold ">
                            Bill To Contact Details
                          </span>
                          {renderTable(viewSubscriptionDetails?.billToContact, [
                            "otherPhone",
                            "workPhone",
                          ])}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span className="se-life-green se-font-14px-bold ">
                            Subscription Details
                          </span>
                          {renderTable(viewSubscriptionDetails?.subscription, [
                            "id",
                            "keyOriginal",
                            "key",
                            "version",
                            "gateManagerId",
                            "entitlementId",
                            "provisioningDetails",
                            "appDirectProductId",
                            "deviceCentric",
                            "products",
                          ])}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </se-dialog-content>
      <se-dialog-footer>
        <se-button onClick={closeViewPopup}>Close</se-button>
      </se-dialog-footer>
    </se-dialog>
  </MyErrorBoundary>
);

export default viewSubscriptionDetails;
