import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";
import embLabels from "../../config/emb-labels-en.json";
import MyErrorBoundary from "../../utils/ErrorBoundary";
import { useSelector } from "react-redux";

const SLDEcc = () => {
  const {
    architectureType,
    clientType,
    rectangleWidth,
    CBLoadList,
    CBAssetList,
    count,
    switchboardType,
    siteType,
    loadSide
  } = useContext(DesignAssetContext);
  const {projectType} = useSelector(state => state.projectType);
  return (
    <MyErrorBoundary>
      
    <>
      {["ISLANDABLE_GSX","ISLANDABLE"].includes(siteType) &&
     ( switchboardType ==="" ||switchboardType === embLabels.EXISTING_SWITCHBOARD) ? (
        <svg>
          <g id="ECC1">
            <svg>
              <g
                id="ECC1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="ECC1" transform="translate(-532.000000, -514.000000)">
                  <g
                    id="SLD/Infos/Microgrid_Background"
                    transform="translate(532.000000, 514.000000)"
                  >
                    <rect
                      id="Rectangle-Copy-245"
                      stroke="#333333"
                      strokeWidth="3"
                      x="40"
                      y={loadSide==="LOAD_SIDE"?"200":"170"}
                      // width="570"
                      // width={CBLoadList.length <= 3 ? "420" : CBLoadList.length === 4 ? "450" : "570"}
                      width={loadSide==="LOAD_SIDE"?"455":"420"}
                      height={loadSide==="LOAD_SIDE"?"230":"205.3"}
                      rx="16"
                      // height="205.3"

                    />
                    <text
                      id="AAA"
                      fontFamily="Nunito-Regular, Nunito"
                      fontSize="18"
                      fontWeight="normal"
                      fill="#333333"
                    >
                      <tspan x="280" y={loadSide==="LOAD_SIDE"?"420":"360"}>
                        Existing Switchboard
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </svg>
          </g>
          <g id="ECC2">
            <svg>
              <g
                id="ECC2"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="ECC2" transform="translate(-532.000000, -514.000000)">
                  <g
                    id="SLD/Infos/Microgrid_Background"
                    transform="translate(532.000000, 514.000000)"
                  >
                    <rect
                      id="Rectangle-Copy-246"
                      stroke="#333333"
                      strokeWidth="3"
                      x="480"
                      y={loadSide==="LOAD_SIDE"?"440":"332"}
                      width ={rectangleWidth}
                      height={loadSide==="LOAD_SIDE"?"200":"200.3"}
                      rx="16"
                    />
                    <text
                      id="AAA"
                      fontFamily="Nunito-Regular, Nunito"
                      fontSize="18"
                      fontWeight="normal"
                      fill="#333333"
                    >
                      <tspan
                        x={
                          CBAssetList[4].uiData.status === true &&
                          CBAssetList[3].uiData.status === true
                            ? "900"
                            : projectType === "ANSI | Buildings | Autogrid Flex"?"900":"730"
                        }
                        y={loadSide==="LOAD_SIDE"?"580":"520"}
                      >
                        Energy Control Center
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </svg>
          </g>
        </svg>
      ) : (
        <svg >
          <g id="ECC2">
            <svg>
              <g
                id="ECC2"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="ECC2" transform="translate(-532.000000, -514.000000)">
                  <g
                    id="SLD/Infos/Microgrid_Background"
                    transform="translate(532.000000, 514.000000)"
                  >
                    <rect
                      id="Rectangle-Copy-247"
                      stroke="#333333"
                      strokeWidth="3"
                      x="45"
                      y="198.9"
                      // width={
                      //   architectureType === embLabels.PV_GENSET_ATS ? "310" : clientType === "GREENSTRUXURE" ? "550" : "400"
                      // }
                      // width={
                      //   (CBAssetList[4].uiData.status === true ||
                      //     count.loadCount >= 5) &&
                      //   CBAssetList[3].uiData.status === true
                      //     ? CBAssetList[5].uiData.status === true ?"915":count.derCount ===4?"660":"785"
                      //     : (CBAssetList[4].uiData.status === true && CBAssetList[5].uiData.status === true) ||
                      //     (CBAssetList[3].uiData.status === true && CBAssetList[5].uiData.status === true)
                      //     ? "750":                         
                      //     "650"
                      // }
                      width={
                        rectangleWidth
                      }
                      height="201.5"
                      rx="16"
                    />
                    <text
                      id="AAA"
                      fontFamily="Nunito-Regular, Nunito"
                      fontSize="18"
                      fontWeight="normal"
                      fill="#333333"
                    >
                      <tspan
                        x={
                          CBAssetList[4].uiData.status === true &&
                          CBAssetList[3].uiData.status === true
                            ? "500"
                            : "410"
                        }
                        y="380"
                      >
                        Energy Control Center
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </svg>
          </g>
        </svg>
      )}
    </>
    </MyErrorBoundary>
  );
};

export default SLDEcc;
