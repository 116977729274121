import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";

import projectStatusInfoSlice from "./features/projectStatusInfoSlice";
import rootSaga from "./sagas";
import projectTypeInfoSlice from "./features/projectTypeSlice";
import secureArchitectureDataSlice from "./features/secureArchitectureDataSlice";
import getTooltipDataSlice from "./features/getTooltipDataSlice";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    projectInfo: projectStatusInfoSlice.reducer,
    projectType: projectTypeInfoSlice.reducer,
    secureArchitectureData: secureArchitectureDataSlice.reducer,
    tooltipData: getTooltipDataSlice.reducer,
  },
  middleware: [...getDefaultMiddleware(), sagaMiddleware],
});
sagaMiddleware.run(rootSaga);
export default store;
