import React from "react";
import isTouchDevice from "../SE-Component/Tooltip";

import embLabels from "../../config/emb-labels-en.json";
import Tooltip from "../../utils/Tooltip";

const Alarming = ({
  siteType,
  architectureType,
  selectedAlaram,
  UserScope,
  emailUsers,
  alaramChangeHanlder,
  addEmailToAlaram,
  removeUserFromAlarmStatus,
  projectStatusData,
  siteCreated
}) => {
  return (
    <>
      <div className={`row mb-2 ${
            siteCreated || projectStatusData?.projectStatus === "COMPLETED"
              ? "se-block-disabled"
              : !UserScope.access.saveProgram
              ? "se-block-disabled"
              : ""
          }`}>
        <div className="col-md-12">
          <span className="se-font-14px-bold se-life-green ">
            Alarming Use cases
          </span>

          <div className="custom-control custom-radio mt-1">
            <input
              type="radio"
              id="noAlaram"
              className="custom-control-input islandableRadio  asCompatible"
              value="NO_ALARM"
              name="selectedAlaram"
              checked={selectedAlaram === "NO_ALARM"}
              onChange={alaramChangeHanlder}
            />
            <label
              className="custom-control-label line-height-25"
              htmlFor="noAlaram"
            >
              No alarm
            </label>
          </div>

          <div className="custom-control custom-radio d-none">
            <input
              type="radio"
              id="basicAlaram"
              className="custom-control-input islandableRadio  asCompatible"
              value="BASIC_ALARM"
              name="selectedAlaram"
              checked={selectedAlaram === "BASIC_ALARM"}
              onChange={alaramChangeHanlder}
            />
            <label
              className="custom-control-label line-height-25"
              htmlFor="basicAlaram"
            >
              Basic alarming
            </label>
            <Tooltip
                  position="right"
                  iconColor="secondary"
                  iconSize="small"
                  information=" Default configuration, allows recipients configuration only"
                />
         
          </div>

          <div
            className={` custom-control custom-radio 
          ${
            siteType === embLabels.ISLANDABLE_GSX &&
            architectureType === embLabels.PV_GENSET_ATS
              ? "se-block-disabled"
              : ""
          }`}
          >
            <input
              type="radio"
              id="advancedAlaram"
              value="ADVANCED_ALARM"
              name="selectedAlaram"
              className="custom-control-input islandableRadio  asCompatible"
              checked={selectedAlaram === "ADVANCED_ALARM"}
              onChange={alaramChangeHanlder}
            />
            <label
              className="custom-control-label line-height-25"
              htmlFor="advancedAlaram"
            >
              Advanced alarming
            </label>
            <Tooltip
                  position="right"
                  iconColor="secondary"
                  iconSize="small"
                  information="Enables custom alarm configuration in EcoStruxure Microgrid Advisor."
                />
         
          </div>
        </div>
      </div>
      {["BASIC_ALARM"].includes(selectedAlaram) && (
        <div>
          <span className="se-font-14px-bold se-life-green ">
            Email addresses
          </span>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th> Email Address</th>
                <th width="10%" className="pt-0 pb-0 text-center">
                  <se-button
                    option="raised"
                    size="small"
                    color="primary"
                    icon="action_add_flat"
                    disabled={UserScope.access.saveProgram ? false : true}
                    onClick={addEmailToAlaram}
                  >
                    Add user
                  </se-button>
                </th>
              </tr>
            </thead>
            <tbody>
              {emailUsers.map(userData => (
                <tr key={userData}>
                  <td>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left pl-0 textEllipsis">
                      <span title={userData}> {userData} </span>
                    </div>
                  </td>

                  <td width="10%">
                    <span className="pointer">
                      <se-icon
                        size="small"
                        title="Delete"
                        color="primary"
                        onClick={() => removeUserFromAlarmStatus(userData)}
                      >
                        action_delete
                      </se-icon>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default Alarming;
