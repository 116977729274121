import React, { useContext,useCallback } from "react";

import { AssetBlockContext } from "../CustomHooks/ContextApi";

import PowerMeterDetails from "./PowerMeterDetails";
import CBConfigDetails from "./CBConfigDetails";

import cbImage from "../../images/CB.png";
import powerMeter from "../../images/power-meter-image.png";

import embLabels from "../../config/emb-labels-en.json";

import isTouchDevice from "../SE-Component/Tooltip";
import Tooltip from "../../utils/Tooltip";
import CBContactorDetails from "./CBContactorDetails";
import { getProjectTypeValue } from "../../utils/project";
const CbPmDetailsGrid = ({ detailsBlock }) => {
  const { CBAssetList, CBEVList, CBLoadList, selectedValues, setAssetSelection ,clientType,siteType,configData} =
    useContext(AssetBlockContext);
  const isAssetSelected = () => {
    let isAsset; 
    let assetInfo = "";
    let assetModel = "";
    if ( 
      selectedValues.selectedAssetPM !== "" ||
      selectedValues.selectedAssetCB !== "" ||
      selectedValues.selectedEvPM !== ""
    ) {
      isAsset = detailsBlock.pmAssetDetailsBlockGrid
        ? CBAssetList[selectedValues.selectedAssetPM]?.powerMeterData?.pmAsset
          ?.assetId ?? CBEVList[selectedValues.selectedEvPM]?.powerMeterData?.pmAsset?.assetId
        : CBAssetList[selectedValues.selectedAssetCB]?.cbConfigData
          ?.cbAssetDetails[0]?.assetId;

      assetInfo = detailsBlock.pmAssetDetailsBlockGrid
        ? CBAssetList[selectedValues.selectedAssetPM]?.powerMeterData?.pmAsset
          ?.assetInfo??CBEVList[selectedValues.selectedEvPM]?.powerMeterData?.pmAsset?.assetInfo
        : CBAssetList[selectedValues.selectedAssetCB]?.cbConfigData
          ?.cbAssetDetails[0]?.assetInfo;

      assetModel = detailsBlock.pmAssetDetailsBlockGrid
        ? CBAssetList[selectedValues.selectedAssetPM]?.powerMeterData?.pmAsset
          ?.communicationModel??CBEVList[selectedValues.selectedEvPM]?.powerMeterData?.pmAsset?.communicationModel
        : CBAssetList[selectedValues.selectedAssetCB]?.cbConfigData
          ?.cbAssetDetails[0]?.communicationModel;
    }
    if (selectedValues.selectedEvPM !== "") {
      isAsset = detailsBlock.pmAssetDetailsBlockGrid
        ? CBEVList[selectedValues.selectedEvPM]?.powerMeterData?.pmAsset
          ?.assetId
        : CBEVList[selectedValues.selectedEvCB]?.cbConfigData
          ?.cbAssetDetails[0]?.assetId;

      assetInfo = detailsBlock.pmAssetDetailsBlockGrid
        ? CBEVList[selectedValues.selectedEvPM]?.powerMeterData?.pmAsset
          ?.assetInfo
        : CBEVList[selectedValues.selectedEvCB]?.cbConfigData
          ?.cbAssetDetails[0]?.assetInfo;

      assetModel = detailsBlock.pmAssetDetailsBlockGrid
        ? CBEVList[selectedValues.selectedEvPM]?.powerMeterData?.pmAsset
          ?.communicationModel
        : CBEVList[selectedValues.selectedEvCB]?.cbConfigData
          ?.cbAssetDetails[0]?.communicationModel;
    }else if(selectedValues.selectedLoad!==""){
      isAsset = detailsBlock.pmAssetDetailsBlockGrid
        ? CBLoadList[selectedValues.selectedLoad]?.powerMeterData?.pmAsset
          ?.assetId
        : CBLoadList[selectedValues.selectedLoad]?.cbConfigData
          ?.cbAssetDetails[0]?.assetId;

      assetInfo = detailsBlock.pmAssetDetailsBlockGrid
        ? CBLoadList[selectedValues.selectedLoad]?.powerMeterData?.pmAsset
          ?.assetInfo
        : CBLoadList[selectedValues.selectedLoad]?.cbConfigData
          ?.cbAssetDetails[0]?.assetInfo;

      assetModel = detailsBlock.pmAssetDetailsBlockGrid
        ? CBLoadList[selectedValues.selectedLoad]?.powerMeterData?.pmAsset
          ?.communicationModel
        : CBLoadList[selectedValues.selectedLoad]?.cbConfigData
          ?.cbAssetDetails[0]?.communicationModel;
    }

    return [isAsset, assetInfo, assetModel];
  };

  const getImage = useCallback(() => {
      if (
        CBAssetList[selectedValues.selectedAssetPM]?.powerMeterData?.pmAsset?.imageLink
      ) {
        return CBAssetList[selectedValues?.selectedAssetPM]?.powerMeterData?.pmAsset?.imageLink;
      } else if (
        CBEVList[selectedValues.selectedEvPM]?.powerMeterData?.pmAsset?.imageLink
      ) {
        return CBEVList[selectedValues.selectedEvPM]?.powerMeterData?.pmAsset?.imageLink;
      }else if(
        CBLoadList[selectedValues.selectedLoad]?.powerMeterData?.pmAsset?.imageLink
      ) {
        return CBLoadList[selectedValues.selectedLoad]?.powerMeterData?.pmAsset?.imageLink;
      }
    }, [CBAssetList,CBEVList,CBLoadList]);

  const setAssetHandler = () => {
    if (detailsBlock.pmAssetDetailsBlockGrid) {
      setAssetSelection(embLabels.POWER_METER);
    }
    if (detailsBlock.cbAssetDetailsBlockGrid) {
      setAssetSelection(embLabels.CIRCUIT_BREAKER);
    }
  };

const projectTypeValue = getProjectTypeValue();
  const [isAsset, assetInfo, assetModel] = isAssetSelected();
const isMotorisedDisbaled =
  siteType === embLabels.GRID_CONNECTED &&
  projectTypeValue === "MGaaS IEC" &&
  (assetInfo === "Hardwired");
  return (
    <div name="CB" className="col-md-12 p-0">
      <div className="col-md-12 pt-2 se-white-background">
        <div className="row">
          <div className="col-md-8">
            <img
            src={getImage()??cbImage}
              // src={
              //   detailsBlock.pmAssetDetailsBlockGrid
              //     ? !CBAssetList[selectedValues?.selectedAssetPM]
              //         ?.powerMeterData?.pmAsset?.imageLink
              //       ? CBEVList[selectedValues.selectedEvPM]?.powerMeterData
              //           ?.pmAsset?.imageLink ?? powerMeter
              //       : CBAssetList[selectedValues?.selectedAssetPM]
              //           ?.powerMeterData?.pmAsset?.imageLink
              //     : CBAssetList[selectedValues?.selectedAssetCB]?.cbConfigData
              //         .cbAssetDetails[0]?.imageLink === null
              //     ? cbImage
              //     : CBAssetList[selectedValues?.selectedAssetCB]?.cbConfigData
              //         .cbAssetDetails[0]?.imageLink
              // }
              className="asset-image-icon"
              alt={
                detailsBlock.pmAssetDetailsBlockGrid
                  ? "Power Meter"
                  : "circuit breaker"
              }
              onError={(e) => {
                e.currentTarget.src = detailsBlock.pmAssetDetailsBlockA2E
                  ? powerMeter
                  : cbImage;
              }}
            />

            <span className="align-top line-height-30px bold">
              {detailsBlock.pmAssetDetailsBlockGrid
                ? "Power Meter"
                : "Circuit Breaker"}
            </span>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-sm-12 pt-2 se-grey-background se-border">
        <div className="row" style={!isAsset ? { display: "none" } : {}}>
          <div className="col-md-3">
            <img
            src={getImage()??cbImage}
              // src={
              //   detailsBlock.pmAssetDetailsBlockGrid
              //     ? !CBAssetList[selectedValues?.selectedAssetPM]
              //         ?.powerMeterData?.pmAsset?.imageLink
              //       ? CBEVList[selectedValues.selectedEvPM]?.powerMeterData
              //           ?.pmAsset?.imageLink ?? powerMeter
              //       : CBAssetList[selectedValues?.selectedAssetPM]
              //           ?.powerMeterData?.pmAsset?.imageLink
              //     : CBAssetList[selectedValues?.selectedAssetCB]?.cbConfigData
              //         .cbAssetDetails[0]?.imageLink === null
              //     ? cbImage
              //     : CBAssetList[selectedValues?.selectedAssetCB]?.cbConfigData
              //         .cbAssetDetails[0]?.imageLink
              // }
              className="asset-image-display"
              alt={
                detailsBlock.pmAssetDetailsBlockGrid
                  ? "Power Meter"
                  : "circuit breaker"
              }
              onError={(e) => {
                e.currentTarget.src = detailsBlock.pmAssetDetailsBlockA2E
                  ? powerMeter
                  : cbImage;
              }}
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12 bold text-uppercase">
                {selectedValues.selectedEvPM !== ""
                  ? detailsBlock.pmAssetDetailsBlockGrid
                    ? CBEVList[selectedValues.selectedEvPM]?.powerMeterData
                        ?.pmAsset?.manufacturer
                    : CBEVList[selectedValues.selectedEvCB]?.cbConfigData
                        ?.cbAssetDetails[0]?.manufacturer
                  : selectedValues.selectedLoad!==""?
                    CBLoadList[selectedValues.selectedLoad]?.cbConfigData?.cbAssetDetails[0]?.manufacturer
                  : CBAssetList[selectedValues.selectedAssetCB]?.cbConfigData
                      ?.cbAssetDetails[0]
                  ? CBAssetList[selectedValues.selectedAssetCB]?.cbConfigData
                      ?.cbAssetDetails[0]?.manufacturer
                  : CBAssetList[selectedValues.selectedAssetPM]?.powerMeterData
                      ?.pmAsset?.manufacturer}
              </div>
            </div>
            <div className="row">
              <div className="col-md-9">
                {detailsBlock.pmAssetDetailsBlockGrid
                  ? "Power Meter"
                  : "Circuit Breaker"}
              </div>
              <div className="col-md-3 text-right">
                <Tooltip
                  position="left"
                  iconColor="secondary"
                  information={assetInfo}
                />
              </div>
              <div className="col-md-12"> {assetModel}</div>
            </div>
          </div>
        </div>
        <div className="row" style={isAsset ? { display: "none" } : {}}>
          <div className="ml-2 mb-2 asset-selection"> No assets selected</div>
        </div>
        <div className="row">
          <div className=" col-md col-sm col-lg text-center">
            <se-button option="outline" size="small" onClick={setAssetHandler}>
              Asset Selection
            </se-button>
          </div>
        </div>
      </div>
      {isAsset && detailsBlock.pmAssetDetailsBlockGrid && <PowerMeterDetails />}

      {isAsset && detailsBlock.cbAssetDetailsBlockGrid && <CBConfigDetails />}

    </div>
  );
};

export default CbPmDetailsGrid;