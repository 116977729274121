import React, { useContext } from "react";
import sld from "../../images/sld/Rectangle.svg";
import Ecc from "../../components/ISLANDABLE/Ecc";
import Busbar from "../../components/ISLANDABLE/Busbar";
import Utility from "../../components/ISLANDABLE/Utility";
import Genset from "../../components/ISLANDABLE/Genset";
import Battery from "../../components/ISLANDABLE/Battery";
import PV1 from "../../components/ISLANDABLE/PV1";
import PV2 from "../../components/ISLANDABLE/PV2";
import PV3 from "../../components/ISLANDABLE/PV3";
import CHP from "../../components/ISLANDABLE/CHP";
import Load from "../../components/ISLANDABLE/Load";
import Ev from "../../components/ISLANDABLE/Ev";
import Linebar from "../../components/GRIDCONNECTED/Linebar";
import embLabels from "../../config/emb-labels-en.json";

import { DesignAssetContext } from "../CustomHooks/ContextApi"; 
import { uniqueId } from "lodash";

const IslandableSLD = () => {
  const {
    CBAssetList,
    CBEVList,
    CBLoadList,
    sldLoadCompleted,
    projectKind,
    switchboardType,
    coordinates,
    count
  } = useContext(DesignAssetContext);
 
  return (
    <div
      id="SLD-Image"
      className="col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0"
    >
      <svg viewBox="0 0 1300 800">
        <g>
          <svg x="0" y="0" viewBox="0 0 1300 800">
            <g id="grid">
              <svg>
                <image href={sld} x="0" y="0" />
              </svg>
            </g>

            <g
              id="components-sld"
              style={sldLoadCompleted ? {} : { display: "none" }}
            >
              <Ecc />
              <Busbar secondBusbar={true} />
              {projectKind.isMgaasIecIsland &&
                switchboardType === embLabels?.EXISTING_SWITCHBOARD && (
                  <Linebar key={uniqueId()} />
                )}
              <Utility />
              {CBAssetList[1].uiData.status && <Genset />}
              {CBAssetList[2].uiData.status && <Battery />}
              {CBAssetList[3].uiData.status && <PV1 />}
              {CBAssetList[4].uiData.status && <PV2 />}
              {CBAssetList[5].uiData.status && <PV3 />}
              {CBAssetList[6].uiData.status && <CHP />}
              <svg
                id="load pointer"
                x={
                  count.evCount &&
                  switchboardType === embLabels?.EXISTING_SWITCHBOARD
                    ? count.loadCount 
                      ? "250"
                      : "95"
                    : switchboardType === embLabels?.EXISTING_SWITCHBOARD ? "95" : "0"
                }
                y="0"
              >
                {CBLoadList?.map((loadData, key) => (
                  <Load
                    key={loadData.uiData.xAxis}
                    load={loadData.uiData}
                    loadName={loadData.configData.name}
                    loadCbName={loadData.cbConfigData.name}
                    index={key}
                    powerMeterData={loadData.powerMeterData}
                  />
                ))}
              </svg>

              {CBEVList.map(
                (cbData, key) =>
                  cbData.uiData.status && (
                    <Ev key={cbData.configData.name} index={key} />
                  )
              )}
            </g>
          </svg>
        </g>
      </svg>
    </div>
  );
};

export default IslandableSLD;
