import React, { useState, useEffect } from "react";

import APISESSION from "../../api/ApiSession";
import MyErrorBoundary from "../../utils/ErrorBoundary";
const apiSession = new APISESSION();

const AdminRoles = ({ closeAddSubrole, currentRole, addSubRole }) => {
  const [selectedSubRole, setSelectedSubRole] = useState("");
  const [subRolesList, setSubRolesList] = useState([]);

  useEffect(() => {
   try {
    apiSession
    .getRoles()
    .then(roles => {
      const userRoles = roles.userRoles;
      const userSubroles = userRoles.filter(
        subRole => subRole.role !== currentRole
      );
      setSubRolesList(userSubroles);
    })
    .catch(() => {});
   } catch (error) {
    console.log(error)
   }
  }, [currentRole]);

  const handleSubRoleChange = e => setSelectedSubRole(e.target.value);

  const confirmAddSubRole = () => addSubRole(selectedSubRole);

  return (
    <MyErrorBoundary>

    <se-dialog
      id="dialog-complex"
      size="small"
      open={true}
      can-backdrop="false"
    >
      <se-dialog-header>Subrole</se-dialog-header>

      <se-dialog-content className="se-dialog-content">
        <se-loading loading={subRolesList.length ? false : true}></se-loading>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group ">
              <div className="col-sm-12">
                <select
                  className="form-control form-control-sm "
                  name="subRole"
                  id="subRoleSelection"
                  value={selectedSubRole}
                  onChange={handleSubRoleChange}
                >
                  <option value="0" hidden defaultValue>
                    Select the role
                  </option>
                  {subRolesList.map(role => (
                    <option key={role.id} value={role.tag}>
                      {role.role}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </se-dialog-content>
      <se-dialog-footer>
        <se-button option="outline" onClick={closeAddSubrole}>
          Cancel
        </se-button>

        <se-button
          option="flat"
          disabled={selectedSubRole === "" ? true : false}
          onClick={confirmAddSubRole}
        >
          Add subrole
        </se-button>
      </se-dialog-footer>
    </se-dialog>
    </MyErrorBoundary>
  );
};

export default AdminRoles;
