import React, { useContext } from "react";
import useCBMouserOver from "../../pages/CustomHooks/useCBMouserOver";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";
import embLabels from "../../config/emb-labels-en.json";
import MyErrorBoundary from "../../utils/ErrorBoundary";

const Battery = () => {
  const { 
    CBAssetList: [, , { uiData, configData, cbConfigData,powerMeterData }],
    siteType,
    assetDetailsReset,
    UserScope,
    callSnackbar,
    CBAssetListDispatch,
    selectedValuesHandler,
    selectedAssetHandler,
    cbConfigDataHandler,
    assetConfigDataHandler,
    switchboardType,
    pmConfigDataHandler,
    projectTypeValue,
    projectKind,
  } = useContext(DesignAssetContext);

  const [mouseOverData, CBMouseHandler] = useCBMouserOver();

  const batterySelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) { 
      let assetBlock = "assetBatteryDetailsBlock";

      if(siteType === embLabels.GRID_CONNECTED&&projectTypeValue==="MGaaS IEC"){
        assetBlock="assetBessHybridDetailsBlock"
      }
      const selectedAssetValue = "selectedAsset";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_SELECT,
        payload: {
          index: 2,
        },
      });
      selectedValuesHandler(2, selectedAssetValue, assetBlock);
      selectedAssetHandler(embLabels.ASSETS, 2);
      assetConfigDataHandler(2);
    } else {
      callSnackbar(
        "error",
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const batteryCBSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock =
        siteType === embLabels.GRID_CONNECTED
          ? "cbAssetDetailsBlockGrid"
          : "cbAssetDetailsBlock";
      const selectedAssetValue = "selectedAssetCB";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_CB_SELECT,
        payload: {
          index: 2,
        },
      });
      selectedValuesHandler(2, selectedAssetValue, assetBlock);
      cbConfigDataHandler(2);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const pmSelectHandler = () => {

    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      // const assetBlock = "newSBCbAssetDetailsBlock";

      const assetBlock = "pmAssetDetailsBlockGrid";
      const selectedAssetValue = "selectedAssetPM";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_PM_SELECT,
        payload: {
          index: 2,
        },
      });
      selectedValuesHandler(2, selectedAssetValue, assetBlock);
      // newSBCbConfigDataHandler(2);
      pmConfigDataHandler(2);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  return (
    <MyErrorBoundary>
  
    <svg className="pointer">
      <g id="battery">
        <svg
          // x={switchboardType === "NEW_SWITCHBOARD"?uiData.xAxis+110:uiData.xAxis}
          // y={switchboardType === "NEW_SWITCHBOARD"?uiData.yAxis+102:uiData.yAxis}
          x={uiData.xAxis}
          y={uiData.yAxis}
          width="195px"
          height="130px"
          viewBox="0 0 10 149"
          onClick={batterySelectHandler}
        >
          <title>{configData.name}</title>
          <g id="Design" fill="none" fillRule="evenodd">
            <g
              id="Design/Assets-_-00"
              transform="translate(-892.000000, -372.000000)"
            >
              <g
                id="SLD/Production/Uniline/Tobedefine"
                transform="translate(892.000000, 372.000000)"
              >
                <g id="Group-7">
                  <rect
                    id="Rectangle"
                    x="0"
                    y="0"
                    width="96"
                    height="144"
                  ></rect>
                  <line
                    x1="48.5"
                    y1="96"
                    x2="48.5"
                    y2="144"
                    id="Line-2"
                    strokeWidth="4"
                    strokeLinecap="round"
                    stroke={uiData.configured ? "#333333" : "#E47F00"}
                    strokeLinejoin="round"
                  ></line>
                  <circle
                    id="Oval"
                    stroke="#42B4E6"
                    strokeWidth="3"
                    cx="48"
                    cy="56"
                    r="55"
                    style={uiData.selected ? {} : { display: "none" }}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="rgba(0, 135, 205, 0.2)"
                  ></circle>
                  <circle
                    id="Oval"
                    strokeWidth="3"
                    fill="#FFFFFF"
                    cx="48"
                    cy="55"
                    r="46.5"
                    stroke={uiData.configured ? "#333333" : "#E47F00"}
                  ></circle>
                  <text
                    id="segment_energy_infra"
                    fontFamily="se-icon"
                    stroke="#333333"
                    fontSize="52"
                    fontWeight="normal"
                    fill="#0F0F0F"
                  >
                    <tspan x="22" y="76">
                      battery2_general
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </g>

{[embLabels.GRID_CONNECTED,embLabels.ISLANDABLE].includes(siteType) ? (
  <>
  {uiData.cbRequired&& <g id="CB3">
        <svg
          width="75px"
          height="100px"
          viewBox="0 0 10 149"
          x = {uiData.xAxisCB}
          y = {uiData.yAxisCB}
          onClick={batteryCBSelectHandler}
        >
          <title>{cbConfigData.name}</title>
          <g
            id="Circuit Breaker"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Circuit Breaker 1"
              transform="translate(-651.000000, -512.000000)"
            >
              <g
                id="Circuit Breaker - closed"
                transform="translate(604.000000, 514.000000)"
                onMouseOver={CBMouseHandler}
                onMouseOut={CBMouseHandler}
              >
                <g
                  id="Group-15"
                  strokeWidth="4"
                  fillRule="evenodd"
                  fill={
                    uiData.CBSelected
                      ? "rgba(65, 181, 230, 0.35)"
                      : mouseOverData
                      ? "rgba(65, 181, 230, 0.35)"
                      : "none"
                  }
                  stroke={uiData.CBSelected ? "#42B4E6" : "none"}
                >
                  <rect
                    id="Rectangle-Copy-20"
                    x="0"
                    y="12"
                    width="96"
                    height="122"
                  ></rect>
                  <g
                    id="Group-2"
                    transform="translate(48.000000, 0.000000)"
                    stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="4"
                  >
                    <line
                      x1="0.5"
                      y1="0"
                      x2="0.5"
                      y2="47.8298611"
                      id="Line-2"
                    ></line>
                  </g>
                </g>
                <line
                  x1="48.5"
                  y1="97.1701389"
                  x2="48.501111"
                  y2="145"
                  id="Line-2-Copy-15"
                  stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <path
                  d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                  id="Path-11"
                  strokeWidth="4"
                  strokeLinecap="round"
                  stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                ></path>
              </g>
            </g>
          </g>
        </svg>


      
      </g>}



      {(!uiData.cbRequired)&&  <g id="CB3">
        <line
          x1={switchboardType === "EXISTING_SWITCHBOARD"?projectKind.isMgaasIecIsland?Number(uiData.xAxisCB) + 35:"735.5":projectKind.isMgaasIecIsland?Number(uiData.xAxisCB)+35:"625.5"}
          y1={switchboardType === "EXISTING_SWITCHBOARD"?"468":"368"}
          x2={switchboardType === "EXISTING_SWITCHBOARD"?projectKind.isMgaasIecIsland?Number(uiData.xAxisCB) + 35:"735.5":projectKind.isMgaasIecIsland?Number(uiData.xAxisCB)+35:"625.5"}
          y2={switchboardType === "EXISTING_SWITCHBOARD"?"369":"269"}
          id="Line-2"
          strokeWidth="3"
          strokeDasharray="4 8"
          strokeLinecap="round"
          stroke="#333333"
        // strokeLinejoin="round"
        ></line>
      </g>}

  </>
):(
  <g id="CB3">
  <svg
    width="75px"
    height="100px"
    viewBox="0 0 10 149"
    x={uiData.xAxisCB}
    y={uiData.yAxisCB}
    onClick={batteryCBSelectHandler}
  >
    <title>{cbConfigData.name}</title>
    <g
      id="Circuit Breaker"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Circuit Breaker 1"
        transform="translate(-651.000000, -512.000000)"
      >
        <g
          id="Circuit Breaker - closed"
          transform="translate(604.000000, 514.000000)"
          onMouseOver={CBMouseHandler}
          onMouseOut={CBMouseHandler}
        >
          <g
            id="Group-15"
            strokeWidth="4"
            fillRule="evenodd"
            fill={
              uiData.CBSelected
                ? "rgba(65, 181, 230, 0.35)"
                : mouseOverData
                ? "rgba(65, 181, 230, 0.35)"
                : "none"
            }
            stroke={uiData.CBSelected ? "#42B4E6" : "none"}
          >
            <rect
              id="Rectangle-Copy-20"
              x="0"
              y="12"
              width="96"
              height="122"
            ></rect>
            <g
              id="Group-2"
              transform="translate(48.000000, 0.000000)"
              stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
            >
              <line
                x1="0.5"
                y1="0"
                x2="0.5"
                y2="47.8298611"
                id="Line-2"
              ></line>
            </g>
          </g>
          <line
            x1="48.5"
            y1="97.1701389"
            x2="48.501111"
            y2="145"
            id="Line-2-Copy-15"
            stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></line>
          <path
            d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
            id="Path-11"
            strokeWidth="4"
            strokeLinecap="round"
            stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
          ></path>
        </g>
      </g>
    </g>
  </svg>



</g>
)}
    

    
    {(siteType===embLabels.GRID_CONNECTED||projectKind.isMgaasIecIsland)&& <g id="power meter" style={uiData.pmRequired ? {} : { display: "none"}}>
        <svg
          x={uiData.xAxisPM}
          y={uiData.yAxisPM}
          width="300px"
          height="140px"
          viewBox="0 0 100 160"
          onClick={pmSelectHandler}
        >
          <title>{powerMeterData.name}</title>
          <line
            x1="30"
            y1="127"
            x2="80"
            y2="127"
            stroke={uiData.pmConfiguredGrid ? "#333" : "#E47F00"}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            id="Pm outer"
            stroke="#42B4E6"
            strokeWidth="3"
            cx="100"
            cy="127"
            r="26"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="rgba(0, 135, 205, 0.2)"
            style={uiData.pmSelected ? {} : { display: "none" }}
          ></circle>
          <circle
            id="pm inner"
            stroke={uiData.pmConfiguredGrid ? "#333" : "#E47F00"}
            strokeWidth="3"
            cx="100"
            cy="127"
            r="18.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="#FFFFFF"
          ></circle>
          <text
            id="PM"
            fontSize="16"
            fontWeight="normal"
            stroke="#333"
            fontFamily="Nunito-Regular, Nunito"
            fill="#333"
          >
            <tspan x="88" y="132">
              PM
            </tspan>
          </text>
        </svg>
      </g>}
    </svg>
</MyErrorBoundary>
  );
};

export default Battery;
