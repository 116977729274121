import React, { useCallback, useEffect, useRef, useState } from "react";
import ApiSession from "../../api/ApiSession";
import { getProjectId } from "../../utils/project";
import Snackbar from "../SE-Component/Snackbar";
import { withRouter } from "react-router-dom";
const apiSession = new ApiSession();
const TimeSync = (props) => {
  const [timeServer, setTimeServer] = useState({
    primaryTimeServer:"",
    secondaryTimeServer:""
  });
  const timeSnackBarRef = useRef(null);
  const [snackBar, setSnackBar] = useState({ type: "", message: "" });
  const [loading, setLoading] = useState(false);
  const callSnackbar = useCallback((type, message) => {
    try {
      setSnackBar({ type, message });
      timeSnackBarRef.current.open = true
    } catch (error) {
      console.log("error in snack bar")
    }
  }, []);
  const saveNTPTimeServer = async () => {
    let projectId = getProjectId();
    let { history } = props || {};
    let jsonBody = {
      projectId: projectId,
    primaryNTPTimeServer: timeServer.primaryTimeServer,
    secondaryNTPTimeServer: timeServer.secondaryTimeServer,
    }

   try {
    setLoading(true);
    let json = await apiSession.updateTimeServer(jsonBody)
    
    if(json){
      setLoading(false);
     localStorage.setItem("timeSyncValidate",true)
     setTimeServer((prevState) => ({
       ...prevState,
       primaryNTPTimeServer: json?.primaryNTPTimeServer,
        secondaryNTPTimeServer: json?.secondaryNTPTimeServer,
     }));
     props?.setTimeSyncData(json);
     document.getElementById("dialog-timeSync-status").open = false;
     callSnackbar("success", "Time Server Updated Successfully");
    //  localStorage.setItem("timeSyncValidate", false); 
    if(localStorage.getItem("communicationValidated") === "false"){
       history.push({
      pathname: "/bom",
    });
    }else{
      history.push({
        pathname: "/systems",
      });
    }
    
    }
   } catch (error) {
    console.log(error)
   }
   
  }
  const handleNTPTimeServer = (e)=>{
   let name = e.target.name;
   let value = e.target.value;
    setTimeServer((prevState) => ({
      ...prevState,
      [name] : value
    }));
  }
  const getTimeServer =()=>{
   try {
    apiSession.getTimeServer(getProjectId())
    .then((response) => {
      if (response) {
        if(response.timeServerEntity === null){
          localStorage.setItem("timeSyncValidate",false);
          setTimeServer((prevState)=>(
            {
              ...prevState,
              primaryTimeServer:response?.defaultNTPServerData?.primaryNTPTimeServer,
              secondaryTimeServer:response?.defaultNTPServerData?.secondaryNTPTimeServer
             }
           ));
         props?.setTimeSyncData(response);
        }else{
          localStorage.setItem("timeSyncValidate", true);
          setTimeServer((prevState)=>(
            {
              ...prevState,
              primaryTimeServer:response?.timeServerEntity?.primaryNTPTimeServer,
              secondaryTimeServer:response?.timeServerEntity?.secondaryNTPTimeServer
             }
           ));
        }
       
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        if (error.response.status === 412) {
         console.log(error)
        }
      }
    });
   } catch (error) {
    console.log(error)
   }
  };
  useEffect(()=>{
    if(props?.siteType !== "GENSET_PVBESS_A2E"){
      getTimeServer();
    }
    
  },[]);
  return (
    <>
    <se-loading loading={loading} full-page="true"></se-loading>
     <div  className={
      props?.siteCreated || props?.projectStatusData?.projectStatus === "COMPLETED"
        ? "se-block-disabled row"
        : "row"
    }>
      <div className="col-md-12">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-2 ml-2">
          <span className="ml-2 se-font-14px-bold se-life-green">
            Time Server Settings
          </span>
          <div className="dropdown-divider"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-2 ml-2">
          <div className="form-group row mb-2">
            <label
              htmlFor="primaryNTP"
              className="col-sm-3 col-form-label col-form-label-sm se-font-14px mt-2 text-right"
            >
              <span className="se-font-14px-bold">
                Primary NTP Time Server{" "}
              </span>
              {/* <span className="se-mandatory">*</span> */}
            </label>
            <div className="col-sm-4 mt-2">
              <input
                type="text"
                autoComplete="none"
                className="form-control form-control-sm"
                name="primaryTimeServer"
                placeholder="Primary NTP Time Server"
                maxLength="50"
                value={timeServer?.primaryTimeServer}
                onChange={handleNTPTimeServer}
              />
              {/* {primaryNTP === "" && (
                <div className="invalid-feedback-custom">
                  Primary NTP Time Server is required.
                </div>
              )} */}
            </div>
          </div>
          <div className="form-group row mb-2">
            <label
              htmlFor="secondaryNTP"
              className="col-sm-3 col-form-label col-form-label-sm se-font-14px mt-2 text-right"
            >
              <span className="se-font-14px-bold">
                Secondary NTP Time Server{" "}
              </span>
              {/* <span className="se-mandatory">*</span> */}
            </label>
            <div className="col-sm-4 mt-2">
              <input
                type="text"
                autoComplete="none"
                className="form-control form-control-sm"
                name="secondaryTimeServer"
                placeholder="Secondary NTP Time Server"
                maxLength="50"
                value={timeServer?.secondaryTimeServer}
                onChange={handleNTPTimeServer}
              />
              {/* {primaryNTP === "" && (
                <div className="invalid-feedback-custom">
                  Primary NTP Time Server is required.
                </div>
              )} */}
            </div>
          </div>
          <div className=" form-group row mb-2 text-right">
            <div className="col-sm-7 p-0">
              <se-button
                option="flat"
                color="primary"
                padding="large"
                onClick={saveNTPTimeServer}
              >
                Save
              </se-button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar snackBar={snackBar} ref={timeSnackBarRef} />
      </div>
    </div>
    </>
   
  );
};

export default withRouter(TimeSync);
