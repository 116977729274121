import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";
import useCBMouserOver from "../../pages/CustomHooks/useCBMouserOver";
import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";
import MyErrorBoundary from "../../utils/ErrorBoundary";

const Linebar = () => {
  const {
    CBAssetList: [{ uiData: utilityUiData }, , , { uiData }],
    assetDetailsReset,
    UserScope,
    callSnackbar,
    selectedValuesHandler,
    detailsBlock,
    loadSide,
    CBAssetListDispatch,
    newSBCbConfigDataHandler,
    remoteMain,
    count,
  } = useContext(DesignAssetContext);
  const [mouseOverData, CBMouseHandler] = useCBMouserOver();
  const utilityCBSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      // const assetBlock = "cbAssetDetailsBlock";
      const assetBlock = "newSBCbAssetDetailsBlock";
      const selectedAssetValue = "selectedAssetCB";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_CB_SELECT,
        payload: {
          index: 0,
        },
      });
      selectedValuesHandler(0, selectedAssetValue, assetBlock);
      // cbConfigDataHandler(4);
      newSBCbConfigDataHandler(0);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };
  return (
    <MyErrorBoundary>
      <>
        {loadSide === "LOAD_SIDE" ? (
          <svg>
            <g id="linebar">
              <g id="lines">
                {!remoteMain && (
                  <line
                    x1={count.remoteLoadCount < 4 ?"390":"440"}
                    y1="360"
                    x2={count.remoteLoadCount < 4 ?"390":"440"}
                    y2="300"
                    stroke="#333333"
                    strokeWidth="3"
                  />
                )}

                <line
                  x1={count.remoteLoadCount === 4?remoteMain ?"428.5":"438.5" :remoteMain ? "388.5":"388.5"}
                  y1={remoteMain ? "402.5" : "359"}
                  x2={"525"}
                  y2={remoteMain ? "402.5" : "359"}
                  stroke="#333333"
                  strokeWidth="3"
                />
                <line
                  x1="525"
                  y1={remoteMain ? "540" : "440"}
                  x2="525"
                  y2={remoteMain ? "401" : "358"}
                  stroke="#333333"
                  strokeWidth="3"
                />

                <g id="CB31">
                  <svg
                    width="75px"
                    height="100px"
                    viewBox="0 0 10 149"
                    x={remoteMain ? count.remoteLoadCount < 4 ?"355": "395" : "490"}
                    y={remoteMain ? "301.5" : "440"}
                    onClick={utilityCBSelectHandler}
                    className="pointer"
                  >
                    <g id="Circuit Breaker" fill="none" fillRule="evenodd">
                      <g
                        id="Circuit Breaker 1"
                        transform="translate(-651.000000, -512.000000)"
                        onMouseOver={CBMouseHandler}
                        onMouseOut={CBMouseHandler}
                      >
                        <g
                          id="Circuit Breaker - closed"
                          transform="translate(604.000000, 514.000000)"
                          fill={
                            utilityUiData.CBSelected
                              ? "rgba(65, 181, 230, 0.35)"
                              : mouseOverData
                              ? "rgba(65, 181, 230, 0.35)"
                              : "none"
                          }
                          stroke={utilityUiData.CBSelected ? "#42B4E6" : "none"}
                        >
                          <g id="Group-15" strokeWidth="4" fillRule="evenodd">
                            <rect
                              id="Rectangle-Copy-20"
                              x="0"
                              y="12"
                              width="96"
                              height="122"
                            ></rect>
                            <g
                              id="Group-2"
                              transform="translate(48.000000, 0.000000)"
                              stroke={
                                utilityUiData.CBConfigured
                                  ? "#333333"
                                  : "#E47F00"
                              }
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="4"
                            >
                              <line
                                x1="0.5"
                                y1="0"
                                x2="0.5"
                                y2="47.8298611"
                                id="Line-2"
                              ></line>
                            </g>
                          </g>
                          <line
                            x1="48.5"
                            y1="97.1701389"
                            x2="48.501111"
                            y2="145"
                            id="Line-2-Copy-15"
                            stroke={
                              utilityUiData.CBConfigured ? "#333333" : "#E47F00"
                            }
                            strokeWidth="4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></line>
                          <path
                            d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                            id="Path-11"
                            stroke={
                              utilityUiData.CBConfigured ? "#333333" : "#E47F00"
                            }
                            strokeWidth="4"
                            strokeLinecap="round"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </g>
              </g>
            </g>
          
          </svg>
        ) : (
          <svg>
            <g id="linebar">
              <g id="lines">
                <line
                  x1={uiData.existingPmRequired ? "384" : "250"}
                  y1={"212"}
                  x2="521"
                  y2={"212"}
                  stroke="#333333"
                  strokeWidth="3"
                />
                <line
                  x1="520"
                  y1={"210.5"}
                  x2="520"
                  y2={"331"}
                  stroke="#333333"
                  strokeWidth="3"
                />

                
                  <g id="CB32">
                    <svg
                      width="75px"
                      height="100px"
                      viewBox="0 0 10 149"
                      x={"485"}
                      y={"330"}
                      onClick={remoteMain ? undefined: utilityCBSelectHandler}
                      className="pointer"
                    >
                      <g id="Circuit Breaker" fill="none" fillRule="evenodd">
                        <g
                          id="Circuit Breaker 1"
                          transform="translate(-651.000000, -512.000000)"
                          onMouseOver={remoteMain ? undefined:CBMouseHandler}
                          onMouseOut={remoteMain ? undefined:CBMouseHandler}
                        >
                          <g
                            id="Circuit Breaker - closed"
                            transform="translate(604.000000, 514.000000)"
                            fill={remoteMain ? "none":
                              utilityUiData.CBSelected
                                ? "rgba(65, 181, 230, 0.35)"
                                : mouseOverData
                                ? "rgba(65, 181, 230, 0.35)"
                                : "none"
                            }
                            stroke={remoteMain ? "none":
                              utilityUiData.CBSelected ? "#42B4E6" : "none"
                            }
                          >
                            <g id="Group-15" strokeWidth="4" fillRule="evenodd">
                              <rect
                                id="Rectangle-Copy-20"
                                x="0"
                                y="12"
                                width="96"
                                height="122"
                              ></rect>
                              <g
                                id="Group-2"
                                transform="translate(48.000000, 0.000000)"
                                stroke={remoteMain ? "#333333":
                                  utilityUiData.CBConfigured
                                    ? "#333333"
                                    : "#E47F00"
                                }
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="4"
                              >
                                <line
                                  x1="0.5"
                                  y1="0"
                                  x2="0.5"
                                  y2="47.8298611"
                                  id="Line-2"
                                ></line>
                              </g>
                            </g>
                            <line
                              x1="48.5"
                              y1="97.1701389"
                              x2="48.501111"
                              y2="145"
                              id="Line-2-Copy-15"
                              stroke={remoteMain ? "#333333":
                                utilityUiData.CBConfigured
                                  ? "#333333"
                                  : "#E47F00"
                              }
                              strokeWidth="4"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></line>
                            <path
                              d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                              id="Path-11"
                              stroke={remoteMain ? "#333333":
                                utilityUiData.CBConfigured
                                  ? "#333333"
                                  : "#E47F00"
                              }
                              strokeWidth="4"
                              strokeLinecap="round"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </g>
                
              </g>
            </g>
          </svg>
        )}
        {
           loadSide === "LOAD_SIDE" ? (
            <g
              id="embedded meter"
              style={utilityUiData.emSelected ? {} : { display: "none" }}
            >
              <svg
                x={remoteMain?count.remoteLoadCount < 4 ?Number(utilityUiData.xAxisEM ):277 :"390"}
                y={remoteMain?utilityUiData.yAxisEM:"378"}
                width="300px"
                height="140px"
                viewBox="0 0 100 160"

                //onClick={gensetPMSelectHandler}
              >
                {/* <title>{powerMeterData.name}</title> */}
                <title>{"Embedded Metering"}</title>
                <line
                  x1={remoteMain?"59":"39"}
                  y1="127"
                  x2="80"
                  y2="127"
                  stroke={utilityUiData.EMConfigured ? "#333" : "#E47F00"}
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle
                  id="em outer"
                  stroke="none"
                  strokeWidth="3"
                  cx="100"
                  cy="127"
                  r="26"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  style={utilityUiData.emSelected ? {} : { display: "none" }}
                ></circle>
                <circle
                  id="em inner"
                  stroke={utilityUiData.EMConfigured ? "#333" : "#E47F00"}
                  strokeWidth="3"
                  cx="100"
                  cy="127"
                  r="18.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="#FFFFFF"
                ></circle>
                <text
                  id="EM"
                  fontSize="16"
                  fontWeight="normal"
                  stroke="#333"
                  fontFamily="Nunito-Regular, Nunito"
                  fill="#333"
                >
                  <tspan x="88" y="132">
                    EM
                  </tspan>
                </text>
              </svg>
            </g>
          ) : (
            <g
              id="embedded meter"
              style={utilityUiData.emSelected ? {} : { display: "none" }}
            >
              <svg
                x={remoteMain?loadSide === "LINE_SIDE"?"123":utilityUiData.xAxisEM:"395"}
                y={remoteMain?loadSide === "LINE_SIDE"?"130":utilityUiData.yAxisEM:"268"}
                width="300px"
                height="140px"
                viewBox="0 0 100 160"
                //onClick={gensetPMSelectHandler}
              >
                {/* <title>{powerMeterData.name}</title> */}
                <title>{"Embedded Metering"}</title>
                <line
                  x1="29"
                  y1="127"
                  x2="80"
                  y2="127"
                  stroke={utilityUiData.EMConfigured ? "#333" : "#E47F00"}
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle
                  id="em outer"
                  stroke="none"
                  strokeWidth="3"
                  cx="100"
                  cy="127"
                  r="26"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  style={utilityUiData.emSelected ? {} : { display: "none" }}
                ></circle>
                <circle
                  id="em inner"
                  stroke={utilityUiData.EMConfigured ? "#333" : "#E47F00"}
                  strokeWidth="3"
                  cx="100"
                  cy="127"
                  r="18.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="#FFFFFF"
                ></circle>
                <text
                  id="EM"
                  fontSize="16"
                  fontWeight="normal"
                  stroke="#333"
                  fontFamily="Nunito-Regular, Nunito"
                  fill="#333"
                >
                  <tspan x="88" y="132">
                    EM
                  </tspan>
                </text>
              </svg>
            </g>
          )
        }
      </>
    </MyErrorBoundary>
  );
};

export default Linebar;
