import React, {
  useState,
  useEffect,
  useReducer,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react";
import _ from "lodash";
import embLabels from "../config/emb-labels-en.json";
import ACTIONS from "./DesignAssets/Design_Asset_Actions.json";
import PROJECT_ACTION from "./DesignAssets/Actions/projectKind.json"
import { upDateProjectType } from "../redux/features/projectTypeSlice";
//* reducer import

import {
  initialCBAssetList,
  CBAssetListReducer,
} from "./DesignAssets/Reducers/CBAssetListReducer";
import {
  initialCBEVList,
  CBEVListReducer,
} from "./DesignAssets/Reducers/CBEVListReducer";
import {
  initialCBLoadList,
  CBLoadListReducer,
} from "./DesignAssets/CBLoadListReducer";
import {
  initialPVDetails,
  PVDetailsReducer,
} from "./DesignAssets/Reducers/PVDetailsReducer";

import {
  initialCount,
  countReducer,
} from "./DesignAssets/Reducers/CountReducer";

import {
  initialTypes,
  projectKindReducer,
} from './DesignAssets/Reducers/projectKindReducer'

//* custom hook

import useMountEffect from "./CustomHooks/useMountEffect";
import userScope from "./CustomHooks/userScope";

//* context api

import {
  DesignAssetProvider,
  AssetBlockContextProvider,
} from "./CustomHooks/ContextApi";
import Snackbar from "./SE-Component/Snackbar";
import AlertIcon from "./SE-Component/AlertIcon";
import Confirm from "./SE-Component/Confirm";

//* child import

import IslandableSLD from "./DesignAssets/IslandableSLD";
import GridConnectedRosette from "./DesignAssets/GridConnectedRosette";
import PVHybridizationSLD from "./DesignAssets/PVHybridizationSLD";
import PvGensetAtsSLD from "./DesignAssets/PvGensetAtsSLD";
import PvBessNrSLD from "./DesignAssets/PvBessNrSLD";
import A2ESLD from "./DesignAssets/A2ESLD";
import AddEquipment from "./DesignAssets/AddEquipment";
import DesignAssetDescription from "./DesignAssets/DesignAssetDescription";
import UtilityAsset from "./DesignAssets/UtilityAsset";
import GensetAsset from "./DesignAssets/GensetAsset";
import GensetAssetPvHyb from "./DesignAssets/GensetAssetPvHyb";
import BatteryAsset from "./DesignAssets/BatteryAsset";
import PVAsset from "./DesignAssets/PVAsset";
import CHPAsset from "./DesignAssets/CHPAsset";
import HybridAsset from "./DesignAssets/HybridAsset";
import CircuitBreakerDetails from "./DesignAssets/CircuitBreakerDetails";
import GsxNewSBCbDetails from "./DesignAssets/GsxNewSBCbDetails";
import CbPmDetailsA2E from "./DesignAssets/CbPmDetailsA2E";
import CbPmDetailsGrid from "./DesignAssets/CbPmDetailsGrid";
import LoadAsset from "./DesignAssets/LoadAsset";
import LoadCB from "./DesignAssets/LoadCB";
import EvCluster from "./DesignAssets/EvCluster";
import EvCB from "./DesignAssets/EvCB";
import NoAssetSelected from "./DesignAssets/NoAssetSelected";
import AssetSelectionDER from "../components/AssetSelectionDer";
import AssetSelectionPV from "../components/AssetSelectionPV";
import AddPvModals from "../components/AddPvModals";

//*api call
import ApiSession from "../api/ApiSession";
import ProgressBarContainer from "../components/progressBar/progressBarContainer";
import GensetPvBessSLD from "./DesignAssets/GensetPvBessSLD";
import HardWiredSBCbDetails from "./DesignAssets/HardWiredSBCbDetails";
import { useDispatch, useSelector } from "react-redux";
import ExistingPmDetailsGrid from "./DesignAssets/ExistingPmDetailsGrid";
import MyErrorBoundary from "../utils/ErrorBoundary";
import BessHybridAsset from "./DesignAssets/BessHybridAsset";
import { coOrdinateValidator } from "../utils/coOrdinateValidator";
import { getTooltipData } from "../redux/features/getTooltipDataSlice";
import "./designAssethook.scss";
import { assignIpAddress } from "../utils/assignIPAddress";
import { generateRandom } from "../utils/randomGenerator";
import { getProject, getProjectId, getProjectTypeValue } from "../utils/project";

const apiSession = new ApiSession();

const alphanumericRegWithHyphen = /^[-_0-9a-zA-Z]+$/;
const DesignAsset = forwardRef((props, ref) => {
  //*state hooks
  const dispatch = useDispatch();
  const UserScope = userScope();
  const [loading, setLoading] = useState(false);
  const [progressbarLoading, setProgressbarLoading] = useState(false);
  const [displayPage, setDisplayPage] = useState(false);
  const [sldLoadCompleted, setSldLoadCompleted] = useState(false);
  const [useCaseValidGenset, setUseCaseValidGenset] = useState(false);
  const [deleteOneDER, setDeleteOneDER] = useState(false);
  const [defaultConfigurationStatus, setDefaultConfigurationStatus] =
    useState(false);
  const [siteCreated, setSiteCreated] = useState(false);

  const [siteType, setSiteType] = useState("");
  const [switchboardType, setSwitchboardType] = useState("");
  const [clientType, setClientType] = useState("");
  const [architectureType, setArchitectureType] = useState(null);
  const [nominalVoltage, setNominalVoltage] = useState("");

  const [defaultPreFillData, setDefaultPreFillData] = useState([]);
  const [availableDer, setAvailableDer] = useState([]);
  const [useCases, setUseCases] = useState([]);
  // const projectType = localStorage.getItem('pType');
  const { projectType } = useSelector((state) => state.projectType);

  const [batteryCommunicationData, setBatteryCommunicationData] = useState({
    ipaddress: "",
    subnetmask: "",
    defaultGateway: "",
    modbusSlaveId: "",
  });
  const [evCommunicationData, setEvCommunicationData] = useState({
    ipaddress: "",
    subnetmask: "",
    defaultGateway: "",
    modbusSlaveId: "",
  });

  const [powerMeter, setPowerMeter] = useState({
    assetId: "",
    assetType: "Power Meter",
    manufacturer: "",
    communicationModel: "PM3250",
    imageLink: null,
    assetInfo: "",
    assetEnabled: false,
    assetProperties: [],
    assetAttributes: [],
    comments: null,
  });
  const [communicationState, setCommunicationState] = useState({
    ifeIpAddress1: "",
    ifeIpAddress2: "",
    ifeIpAddress3: "",
    ifeIpAddress4: "",
    ifeIpAddress5: "",
    ifeIpAddress6: "",
    ifeIpAddress7: "",
    ifeIpAddress8: "",
    ifeIpAddress9: "",
    ifeIpAddress10: "",
    ifeIpAddress11: "",
    ifeIpAddress12: "",
    ifeIpAddress13: "",
    ifeIpAddress14: "",
    ifeIpAddress15: "",
    ifeIpAddress16: "",
    ifeIpAddress17: "",
    ifeIpAddress18: "",
    ifeIpAddress19: "",
    ifeIpAddress20: "",
    ifeIpAddress21: "",
    ifeIpAddress22: "",
    ifeIpAddress23: "",
    ifeIpAddress24: "",
    ifeSubnetMask: "",
    ifeSubnetMask2: "",
    ifeSubnetMask3: "",
    ifeSubnetMask4: "",
    ifeGateway: "",
    ifeGateway2: "",
    ifeGateway3: "",
    ifeGateway4: "",
  });
  const [cbCommunicationValues, setCbCommunicationValues] = useState({
    allIps: [],
    availableIps: [],
    usedIps: [],
    ifeIpSets: {},
  });
  const [detailsBlock, setDetailsBlock] = useState({
    cbAssetDetailsBlock: false,
    cbLoadDetailsBlock: false,
    cbEvDetailsBlock: false,
    loadDetailsBlock: false,
    evDetailsBlock: false,
    assetUtilityDetailsBlock: false,
    assetGensetDetailsBlock: false,
    assetBatteryDetailsBlock: false,
    assetPvDetailsBlock: false,
    assetCHPDetailsBlock: false,
    assetHybridDetailsBlock: false,
    newSBCbAssetDetailsBlock: false,
    cbAssetDetailsBlockA2E: false,
    cbAssetDetailsBlockGrid: false,
    pmAssetDetailsBlockA2E: false,
    pmAssetDetailsBlockGrid: false,
    existingPmAssetDetailsBlockGrid: false,
    hardWiredCbDetailsBlockGrid: false,
    assetBessHybridDetailsBlock: false,
  });
  const [selectedValues, setSelectedValues] = useState({
    selectedAsset: "",
    selectedAssetCB: "",
    selectedAssetPM: "",
    selectedLoad: "",
    selectedLoadCB: "",
    selectedEv: "",
    selectedEvCB: "",
    selectedEvPM: "",
    selectedPv: "",
    selectSubPv: "",
  });

  const [configData, setConfigData] = useState({
    utilityConfigData: {
      name: "",
      description: "",
      utilityCO2: "",
      maximum_available_power: 10,
    },
    gensetConfigData: {
      name: "",
      description: "",
      min_power: "",
      max_power: "",
      gensetCO2: "",
      starting_timer: "",
      cooling_timer: "",
      minimum_running_period: "",
    },
    batteryConfigData: {
      name: "",
      description: "",
      min_power: "",
      max_power: "",
      capacity: 0,
      soc_max: 90,
      soc_min: 10,
      soc_nominal_power: 60,
      starting_timer: "",
    },
    loadConfigData: {
      name: "",
      description: "",
      maximum_available_power: 0,
    },
    evConfigData: {
      name: "",
      description: "",
      maximum_available_power: 0,
    },
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      contactor: false,
      loadCategory: 0,
      loadPriority: 0,
    },
    contactorConfigData: {
      name: "",
      description: "",
      loadCategory: 0,
      loadPriority: 0,
    },
    sampleContactorConfigData: {
      name: "",
      description: "",
      loadCategory: 0,
      loadPriority: 0,
    },
    cbConfigDataSample: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
    },
    sampleCbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
    },
    sampleConfigData: {
      name: "",
      description: "",
      min_power: "",
      max_power: "",
    },
    configData: {
      name: "",
      description: "",
      min_power: "",
      max_power: 62.5,
    },
    powerMeterData: {
      name: "",
      description: "",
      powerMeterModbusId: "",
    },
    chpConfigData: {
      name: "",
      description: "",
      nominal_power: "",
    },
    hybridConfigData: {
      name: "",
      description: "",
      pcu_ac_rated_power: "",
      storage_capcity_hr: [],
      corresponding_name_plate_capacity: [],
      corresponding_name_plate_capacity_value: "",
      max_capacity: "",
      capacity: 0,
      max_solar_power: "",
      max_inverter_output_power: "",
    },
  });

  const [gsxExternalId, setGsxExternalId] = useState({
    utilityExternalId: "",
    gensetExternalId: "",
    pvAssetExternalId: "",
    batteryExternalId: "",
  });

  const [assetDetailsDisplay, setAssetDetailsDisplay] = useState({
    assetId: "",
    assetName: "",
    assetType: "",
    manufacturer: "",
    assetInfo: "",
    communicationModel: "",
    imageLink: "",
    assetProperties: [],
  });

  const [assetInfo, setAssetInfo] = useState({
    assetSelected: false,
    manufacturerLoading: false,
    assets: [],
    assetManufacturer: [],
    assetModelType: [],
    selectedManufacturer: "",
    selectdModelType: "",
  });
  const [CBValues, setCBValues] = useState({});
  const [defaultsMinMax, setDefaultsMinMax] = useState({});
  const [coordinates, setCoordinates] = useState({
    xDer: 8,
    xDerGrid: 370,
    yDerGrid: 141,
    xDerGridPM: 495,
    yDerGridPM: 127.5,
    xDerCBDiff: 590,
    yDerCBDiff: 269,
    xDerCB: 108,
    derDiff: 110,
    derDiffGrid: 120,
    xDerPvHyb: 380,
    xDerCBPvHyb: 480,
    derDiffPvHyb: 120,
    xLoad: 10,
    xLoadCB: 55,
    xLoadEM: 446,
    xLoadNew: 65,
    xLoadCBNew: 110,
    xLoadPM: 345,
    xLoadPMNew: 345,
    loadDiff: 130,
    xAxisLoadCBDiff: 55,
    cbEV: {
      xDerEV: 529.5,
      yDerEV: 488,
      xDerPM: 480,
      yDerPM: 407.5,
      x1DerEVCB: 555,
      y1DerEVCB: 463,
      x2DerEVCB: 555,
      y2DerEVCB: 367,
    },

    ecc: {
      width: 690,
    },
    busbar: {
      x2: 755,
    },
    eccwidth: 208,
    busbarx2: 208,
    CX: 600,
    CY: 350,
    radius: 46,
    angle: 0,
    busbarCordinates: {
      x11: 168,
      y11: 335,
      x21: 408,
      y21: 335,
      x12: 650,
      y12: 425,
      x22: 890,
      y22: 425,
    },
  });
  const [rectangleWidth, setRectangleWidth] = useState(null);
  const [snackBar, setSnackBar] = useState({ type: "", message: "" });
  const [alert, setAlert] = useState({
    message: "",
    alertPopUpStatus: false,
    title: "",
    isRoute: false,
    routePath: "",
  });
  const [confirm, setConfirm] = useState({
    confirmMessage: "",
    confirmPopupStatus: false,
  });

  const [remoteConfirm, setRemoteConfirm] = useState({
    confirmMessage: "",
    confirmPopupStatus: false,
  });

  const [duplicateUpdate, setDuplicateUpdate] = useState({
    der: "",
    load: "",
    pv: "",
    ev: "",
  });

  const [runCalculateCoordinates, setRunCalculateCoordinates] = useState({
    componentPush: false,
  });
  const [runGenerateComponentsBlock, setRunGenerateComponentsBlock] = useState({
    generateComponentsBlock: false,
  });
  const [projectStatusData, setProjectStatusData] = useState();

  const [batteryRack, setBatteryRack] = useState("1");
  const [namePlateEnergy, setNamePlateEnergy] = useState("1.1");

  const [loadSide, setLoadSide] = useState("LINE_SIDE");

  const [defaultCbData, setDefaultCbData] = useState({});
  const [defaultPmData, setDefaultPmData] = useState({});
  const [defaultDummyCbData, setDefaultDummyCbData] = useState({});
  const [gensetAssetData, setGensetAssetData] = useState({});
  const [evContactorData, setEVContactorData] = useState({});
  const [evDefaultData, setEVDefaultData] = useState({});
  const [evPMData, setEVPMData] = useState({});
  const [jsonData,setJsonData] = useState({});
  const [isContactorEnabled,setIsContactorEnabled] = useState(false);
  //* reducer hooks

  const [CBAssetList, CBAssetListDispatch] = useReducer(
    CBAssetListReducer,
    initialCBAssetList
  );
  const [CBEVList, CBEVListDispatch] = useReducer(
    CBEVListReducer,
    initialCBEVList
  );
  const [CBLoadList, CBLoadListDispatch] = useReducer(
    CBLoadListReducer,
    initialCBLoadList
  );

  const [count, countDispatch] = useReducer(countReducer, initialCount);

  const [projectKind, projectKindDispatch] = useReducer(projectKindReducer, initialTypes)

  const [PVDetails, PVDetailsDispatch] = useReducer(
    PVDetailsReducer,
    initialPVDetails
  );

  //* ref hook

  const initialRenderCoordinates = useRef(true);
  const initialRenderPvConfigured = useRef(true);
  const snackBarRef = useRef(null);
  const {
    isMgaasAnsi,
    isDaasAnsi,
    isMgaasIec,
    isDaasIec,
    isMgaasAnsiGrid,
    isDaasAnsiGrid,
    isMgaasAnsiIsland,
    isDaasAnsiIsland,
    isMgaasIecGrid,
    isDaasIecGrid,
    isMgaasIecIsland,
    isGridConnected,
    isGridConnectedGSX,
    isIslandable,
    isIslandableGSX,
    isA2E,
    isLineSide,
    isExistingSwitchBoard,
    isLoadSide,
    isNewSwitchBoard,
    isGreenStruxure,
    isGeneric,
    isGreenStruxureMicrogrid,
    isPVGensetATS,
    isFuelSaver,
    isPVBessNR,
    isPVHybrid,
    isPVGensetA2E,
    isGensetMainNoBess,
    isNoFuel,
  } = projectKind;

  const [remoteMain, setRemoteMain] = useState(false);
  //* effect hook

  const getProjectDetailStatus = () => {
  try {
    apiSession
    .getProjectStatus(getProjectId())
    .then((response) => {
      if (response) {
        dispatch(
          upDateProjectType({
            projectType: response.projectType,
            projectName: response.projectName,
          })
        );
        setProgressbarLoading(true);
        setProjectStatusData(response);
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        if (error.response.status === 412) {
          callSnackbar("error", `${error?.message}`);
        }
      }
    });
  } catch (error) {
    console.log(error)
  }
    };
    useEffect(() => {
      if(document.getElementsByClassName("stickyProgressBar")[1]){
        document.getElementsByClassName("stickyProgressBar")[1].style.display ="none";
      }
    }, [document.getElementsByClassName("stickyProgressBar")[1]]);
  useEffect(() => {
    const projectTypeValue = getProjectTypeValue();

    if (isGridConnected) {
      if (isMgaasAnsi) {

        CBAssetListDispatch({
          type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVBESS_NR,
          payload: {
            switchType: switchboardType,
            mode: "",
            clientType:"",
            loadSide:loadSide
          }
        });
        CBEVListDispatch({
          type: ACTIONS.UPDATE_CBEVLIST_COORDINATES_GRIDCONNECTED,
          payload: { coordinates: coordinates },
        });
        
      } else {
        CBAssetListDispatch({
          type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
          payload: { coordinates: coordinates },
        });
        CBLoadListDispatch({
          type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_GRIDCONNECTED,
        });
        CBEVListDispatch({
          type: ACTIONS.UPDATE_CBEVLIST_COORDINATES_GRIDCONNECTED,
          payload: { coordinates: coordinates },
        });
      }
    }else if(isIslandable &&
      isMgaasAnsi&&
      isNoFuel){
        CBAssetListDispatch({
          type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVBESS_NR,
          payload: {
            switchType: switchboardType,
            mode: defaultPreFillData[1]?.mode,
            clientType: "GENERIC",
            loadSide:loadSide
          },
        });
        CBAssetListDispatch({
          type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVGENSET_ATS_SETTING_MODE,
          payload: {
            switchType: switchboardType,
            mode: defaultPreFillData[1]?.mode,
            clientType: "GENERIC",
            loadSide:loadSide,
          },
        });
      }else{
      CBEVListDispatch({
        type: ACTIONS.UPDATE_CBEVLIST_COORDINATES_GRIDCONNECTED,
        payload: { coordinates: coordinates },
      });
    }

  }, [coordinates]);
  
  useEffect(() => {
    getProjectDetailStatus();
    localStorage.setItem("externalId", null);
   
  }, []);
  useEffect(() => {
    dispatch(getTooltipData(null));
  }, []);
  useMountEffect(() => {
    if (getProjectId() !== "null") {
      setDisplayPage(true);
      getExternalID();
      fetchDefaultDataValues();
    
    } else {
      // setAlertMessage(
      //   `Asset Page`,
      //   true,
      //   `information`,
      //   `Please create project and then visit asset page.`
      // );
    }
    localStorage.setItem(
      "derStatus",
      JSON.stringify([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ])
    );
    localStorage.setItem(
      "derHistory",
      JSON.stringify({
        Utility: {},
        Genset: {},
        BESS: {},
        EV: {},
        CHP: {},
        Hybrid: {},
      })
    );
    localStorage.setItem(
      "pvHistory",
      JSON.stringify({ pv1: [], pv2: [], pv3: [] })
    );
    localStorage.setItem("cbEvHistory", []);
    localStorage.setItem("cbLoadHistory", []);
    localStorage.setItem("cbAssetHistory", []);
    localStorage.setItem("pmAssetHistory", []);
    localStorage.setItem("pmEvHistory", []);
    localStorage.setItem("loadHistory", []);
    localStorage.setItem("assetEditStatus", "");
    localStorage.setItem(
      "pvGroupPower",
      JSON.stringify({ pv1: "", pv2: "", pv3: "" })
    );
  }, []);

  //* To calculate coordinates

  useMountEffect(() => {
    if (initialRenderCoordinates.current) {
      initialRenderCoordinates.current = false;
    } else {
      calculateCoOridinates(runCalculateCoordinates.componentPush);
    }
  }, [runCalculateCoordinates,switchboardType]);

  useMountEffect(() => {
    if (runGenerateComponentsBlock.generateComponentsBlock) {
      generateComponentsBlock();
    }
  }, [runGenerateComponentsBlock]);

  useEffect(() => {
    if (initialRenderPvConfigured.current) {
      initialRenderPvConfigured.current = false;
    } else {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_PV_CONFIRGURED,
        payload: { PVDetails },
      });
    }
  }, [PVDetails]);

  useMountEffect(() => {
    if (availableDer?.length) {
      checkUseCaseChangeByUser();
    }
  }, [availableDer]);
 
  //* functions
  const getExternalID = async () => {
   try {
    await apiSession
    .getProjectExternalId()
    .then((response) => {
      setGsxExternalId((prevState) => ({
        ...prevState,
        utilityExternalId: response["1"],
        gensetExternalId: response["2"],
        pvAssetExternalId: response,
        batteryExternalId: response["3"],
      }));

      localStorage.setItem("externalId", JSON.stringify(response));
    })
    .catch((error) => {
      if (error.response !== undefined) {
        if (error.response) {
          callSnackbar("error", `${error?.message}`);
        }
      }
    });
   } catch (error) {
    console.log(error);
   }
  };
  const fetchDefaultDataValues = () => {
    setLoading(true);
    try {
      apiSession
      .getAssetDefaultValues(getProjectId())
      .then(async (json) => {
        await projectKindDispatch({
          type: PROJECT_ACTION.UPDATE_DEFAULT_PROJECT_KIND,
          payload: json,
        });
        assignDefaultValues(
          json.defaultValues,
          json.gridSettings,
          json.defaults,
          json.siteType,
          json.switchboardType,
          json.genset,
          json.powerMeter,
          json.architecture,
          json.clientType,
          json.pm_Utility,
          json.pm_Genset,
          json.pm_PV,
          json.nominalVoltage,
          json.bess,
          json.hybrid,
          json.cb,
          json.pm_Bess,
          json.contactor,
          json.dummyCB,
          json.ev,
          json.pm_EV,
          json.remoteMain,
        );
      })
      .catch(() => callSnackbar("error", embLabels.errorInLoadingData));
    } catch (error) {
      console.log(error)
    }
  };

  const assignDefaultValues = (
    defaultValues,
    gridSettings,
    defaults,
    type,
    switchboardType,
    genset,
    powerMeter,
    architecture,
    clientType,
    pmUtility,
    pmGenset,
    pmPv,
    nominalVoltage,
    bess,
    hybrid,
    cb,
    pmBess,
    contactor,
    dummyCB,ev,
    pmEV,
    remoteMain,
  ) => {
    const dataPreFill = [];
    dataPreFill[0] = defaultValues;
    dataPreFill[1] = gridSettings;
    dataPreFill[2] = defaults;
    setDefaultPreFillData(dataPreFill);
    CBAssetListDispatch({
      type: ACTIONS.ASSIGN_DEFAULT_VALUES,
      payload: {
        defaultValues,
        defaults,
        type,
        architecture,
        genset,
        defaults,
        pmUtility,
        pmGenset,
        pmPv,
        bess,
        hybrid,
        cb,
        pmBess,
        gridSettings,
        powerMeter,
        contactor,
        clientType,
        mode: defaultPreFillData[1]?.mode
      },
    });
    setDefaultCbData({ ...cb });
    setDefaultPmData({ ...powerMeter });
    setDefaultDummyCbData({ ...dummyCB });
    setEVContactorData({...contactor})
    setGensetAssetData({...genset});
    setEVPMData({...pmEV})
    setEVDefaultData({...ev})
    setBatteryCommunicationData({
      ipaddress: defaultValues.BESS.ipAddress,
      subnetmask: defaultValues.BESS.subnetMask,
      defaultGateway: defaultValues.BESS.defaultGateway,
      modbusSlaveId: defaultValues.BESS.modbusId,
    });
    if ([embLabels.PV_HYBRIDIZATION, embLabels.GRID_CONNECTED, embLabels.ISLANDABLE].includes(type)) {
      setPowerMeter({
        assetAttributes: powerMeter.assetAttributes,
        assetEnabled: powerMeter.assetEnabled,
        assetId: powerMeter.assetId,
        assetInfo: powerMeter.assetInfo,
        assetProperties: powerMeter.assetProperties,
        assetType: powerMeter.assetType,
        comments: powerMeter.comments,
        communicationModel: powerMeter.communicationModel,
        imageLink: powerMeter.imageLink,
        manufacturer: powerMeter.manufacturer,
      });
    }

    CBEVListDispatch({
      type: ACTIONS.ASSIGN_DEFAULT_VALUES,
      payload: {
        defaultValues,
        cb,
        contactor:contactor,
        ev,
        pmEV,
      },
    });
    setEvCommunicationData({
      ipaddress: defaultValues.EV_charging_station.ipAddress,
      subnetmask: defaultValues.EV_charging_station.subnetMask,
      defaultGateway: defaultValues.EV_charging_station.defaultGateway,
      modbusSlaveId: defaultValues.EV_charging_station.modbusId,
    });
    CBLoadListDispatch({
      type: ACTIONS.ASSIGN_DEFAULT_VALUES,
      payload: {
        defaultValues,
        type,
        defaults,
        cb,
        dummyCB,
        pmAssetData: powerMeter,
        contactor: contactor,
      },
    });

    PVDetailsDispatch({
      type: ACTIONS.ASSIGN_DEFAULT_VALUES,
      payload: {
        defaultValues,
        gsxExternalId,
      },
    });

    setCommunicationState({
      ...communicationState,
      ifeIpAddress1: defaultValues["Circuit Breaker"].ipAddress1,
      ifeIpAddress2: defaultValues["Circuit Breaker"].ipAddress2,
      ifeIpAddress3: defaultValues["Circuit Breaker"].ipAddress3,
      ifeIpAddress4: defaultValues["Circuit Breaker"].ipAddress4,
      ifeIpAddress5: defaultValues["Circuit Breaker"].ipAddress5,
      ifeIpAddress6: defaultValues["Circuit Breaker"].ipAddress6,
      ifeIpAddress7: defaultValues["Circuit Breaker"].ipAddress7,
      ifeIpAddress8: defaultValues["Circuit Breaker"].ipAddress8,
      ifeIpAddress9: defaultValues["Circuit Breaker"].ipAddress9,
      ifeIpAddress10: defaultValues["Circuit Breaker"].ipAddress10,
      ifeIpAddress11: defaultValues["Circuit Breaker"].ipAddress11,
      ifeIpAddress12: defaultValues["Circuit Breaker"].ipAddress12,
      ifeIpAddress13: defaultValues["Circuit Breaker"].ipAddress13,
      ifeIpAddress14: defaultValues["Circuit Breaker"].ipAddress14,
      ifeIpAddress15: defaultValues["Circuit Breaker"].ipAddress15,
      ifeIpAddress16: defaultValues["Circuit Breaker"].ipAddress16,
      ifeIpAddress17: defaultValues["Circuit Breaker"].ipAddress17,
      ifeIpAddress18: defaultValues["Circuit Breaker"].ipAddress18,
      ifeIpAddress19: defaultValues["Circuit Breaker"].ipAddress19,
      ifeIpAddress20: defaultValues["Circuit Breaker"].ipAddress20,
      ifeIpAddress21: defaultValues["Circuit Breaker"].ipAddress21,
      ifeIpAddress22: defaultValues["Circuit Breaker"].ipAddress22,
      ifeIpAddress23: defaultValues["Circuit Breaker"].ipAddress23,
      ifeIpAddress24: defaultValues["Circuit Breaker"].ipAddress24,
      ifeSubnetMask: defaultValues["Circuit Breaker"].subnetMask,
      ifeSubnetMask2: defaultValues["Circuit Breaker"].subnetMask2,
      ifeSubnetMask3: defaultValues["Circuit Breaker"].subnetMask3,
      ifeSubnetMask4: defaultValues["Circuit Breaker"].subnetMask4,
      ifeGateway: defaultValues["Circuit Breaker"].defaultGateway,
      ifeGateway2: defaultValues["Circuit Breaker"].defaultGateway2,
      ifeGateway3: defaultValues["Circuit Breaker"].defaultGateway3,
      ifeGateway4: defaultValues["Circuit Breaker"].defaultGateway4,
    });

    const allIps = [];
    for (let i = 0; i < 24; i++) {
      const value = `ipAddress${i + 1}`;
      allIps.push(defaultValues["Circuit Breaker"][value]);
    }
    setCbCommunicationValues((prevState) => ({
      ...prevState,
      allIps,
      availableIps: allIps,
    }));

    setNominalVoltage(nominalVoltage);

    const defaultsData = {};

    for (let defaultData of defaults) {
      defaultsData[defaultData["param"]] = defaultData["value"];
    }
    setDefaultsMinMax(defaultsData);
    fetchComponentsExisted(defaultValues,contactor);
    setSiteType(type);
    setSwitchboardType(switchboardType);
    setRemoteMain(remoteMain);
    setClientType(clientType);
    setArchitectureType(architecture);
  };
  //fetch the all component list step 3
  const fetchComponentsExisted = (defaultValues,contactor) => {
    apiSession
      .getComponents(getProjectId())
      .then((json) => {
        setJsonData(json.components)
        projectKindDispatch({
          type: PROJECT_ACTION.UPDATE_PROJECT_KIND,
          payload: json,
        });
        localStorage.setItem("programPage", json.programPage);
        localStorage.setItem("communicationValidated", json.systemsPage);
        setSiteCreated(json.siteCreated);
        setSwitchboardType(json.switchboardType);
        setRemoteMain(json.remoteMain);
        setSiteType(json.siteType);
        
        if (
          (json.components.length > 0 &&
            json.siteType === "GENSET_PVBESS_A2E") ||
          (json.components.length > 0 && json.siteType === "GRID_CONNECTED")
        ) {
          setNamePlateEnergy(
            JSON.parse(
              json?.components.filter((item) =>
                ["Hybrid", "BESS"].includes(item["componentType"])
              )[0].attributes
            ).storage_capcity_hr_value
          );
        }
        if (
          json.components.length > 0 &&
          json.diagramChangeRequired === false
        ) {
          getCBDetails(json.components);
          assignComponents(json,contactor);
          const defaultConfigurationStatusNew = false;
          fetchUseCase(
            defaultConfigurationStatusNew,
            defaultValues,
            json.siteType
          );

          setLoadSide(json.connectionType);
         
          
        } else {
          const defaultConfigurationStatusNew = true;
          setDefaultConfigurationStatus(defaultConfigurationStatusNew);
          fetchUseCase(
            defaultConfigurationStatusNew,
            defaultValues,
            json.siteType
          );
        }
      })
      .catch(() => {
        setLoading(false);
        setSldLoadCompleted(false);
        callSnackbar("error", embLabels.errorInLoadingData);
      });
  };

  const fetchUseCase = (
    defaultConfigurationStatusNew,
    defaultValues,
    siteType
  ) => {
    apiSession
      .fetchUseCase(getProjectId())
      .then((json) => {
        setSldLoadCompleted(true);
        setUseCases(json);
        if (defaultConfigurationStatusNew) {
          loadUseCase(
            json,
            defaultConfigurationStatusNew,
            defaultValues,
            siteType
          );
        } else {
          setAvailableDer(json);
          checkUseCase(json);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const loadUseCase = (
    usecase,
    defaultConfigurationStatusNew,
    defaultValues,
    siteType
  ) => {
    usecase.forEach((usecaseData) => {
      switch (usecaseData) {
        case embLabels.UTILITY:
          CBAssetListDispatch({
            type: ACTIONS.ASSIGN_USECASE_UTILITY,
            payload: { defaultValues },
          });
          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });
          break;

        case embLabels.GENERATOR:
          const projectTypeA2E = getProjectTypeValue() ?? getProject()?.projectType
          CBAssetListDispatch({
            type: ACTIONS.ASSIGN_USECASE_GENSET,
            payload: { defaultValues, projectTypeA2E },
          });
          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });
          setUseCaseValidGenset(true);
          break;

        case embLabels.BESS:
          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });
          CBAssetListDispatch({
            type: ACTIONS.ASSIGN_USECASE_BESS,
            payload: { defaultValues },
          });
          break;

        case embLabels.PV_INVERTER:
          CBAssetListDispatch({
            type: ACTIONS.ASSIGN_USECASE_PV,
            payload: { defaultValues },
          });

          //* assign pv to pvset1

          const externalIds = JSON.parse(localStorage.getItem("externalId"));

          PVDetailsDispatch({
            type: ACTIONS.ASSIGN_USECASE_PVSET1,
            payload: {
              count: 3,
              externalIds: externalIds,
            },
          });

          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });

          break;

        case embLabels.HYBRID:
          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });
          CBAssetListDispatch({
            type: ACTIONS.ASSIGN_USECASE_HYBRID,
            payload: { defaultValues },
          });
          break;

        case embLabels.LOAD:
          CBLoadListDispatch({
            type: ACTIONS.ASSIGN_USECASE_LOAD,
            payload: {
              defaultValues,
              defaultCbData,
              siteType,
              defaultDummyCbData,
            },
          });
          countDispatch({
            type: ACTIONS.UPDATE_LOAD_COUNT,
            payload: { count: 1 },
          });
          break;
        default:
          break;
      }
    });

    if (defaultConfigurationStatusNew) {
      const runCalculateCoordinatesNew = { componentPush: true };
      setRunCalculateCoordinates(runCalculateCoordinatesNew);
    } else {
      const runCalculateCoordinatesNew = { componentPush: false };
      setRunCalculateCoordinates(runCalculateCoordinatesNew);
    }

    setLoading(false);
  };
  const checkUseCaseChangeByUser = () => {
    const foundGenerator = availableDer.indexOf(embLabels.GENERATOR);
    const foundBESS = availableDer.indexOf(embLabels.BESS);

    if (foundGenerator === -1 && siteType === embLabels.ISLANDABLE) {
      if (CBAssetList[1].uiData.status) {
        removeGenset();
      }
    } else if (foundGenerator !== -1) {
      if (
        !CBAssetList[1].uiData.status &&
        localStorage.getItem("useCaseChanged") === "true"
      ) {
        checkGensetDeleteOneDER(true);
      }
    }

    if (foundBESS !== -1) {
      if (
        !CBAssetList[2].uiData.status &&
        localStorage.getItem("useCaseChanged") === "true"
      ) {
        if (checkSLDCount()) {
          setLoading(true);
          setTimeout(() => {
            if (UserScope.access.saveSLD === true) {
              if (siteType === embLabels.ISLANDABLE) {
                addBattery();
              }
            }
            setLoading(false);
          }, 800);
        } else {
          setAlertMessage(`Alert`, false, ``, embLabels.deleteOneDERToAddGeset);
        }
      }
    }
    localStorage.setItem("useCaseChanged", false);
  };

  const checkGensetDeleteOneDER = (existedInUsecase) => {
    if (existedInUsecase) {
      if (checkSLDCount()) {
        setDeleteOneDER(false);
        setLoading(false);
        setTimeout(() => {
          if (siteType === embLabels.ISLANDABLE) {
            addGenset();
          }
        }, 800);
      } else {
        setAlertMessage(`Alert`, false, ``, embLabels.deleteOneDERToAddGeset);
        localStorage.setItem("deploymentReady", false);
        setDeleteOneDER(true);
      }
    }
  };

  const checkUseCase = (usecase) => {
    const hasGenerator = usecase.includes(embLabels.GENERATOR);
    if (hasGenerator) setUseCaseValidGenset(true);
  };

  const calculateCoOridinates = (componentPush) => {
    const projectTypeValue = getProjectTypeValue()

    if (
      isIslandable &&
      !isMgaasAnsi
    ) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES,
        payload: { coordinates: coordinates },
      });
      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES,
        payload: { coordinates: coordinates, count: count ,siteType:siteType, projectKind: projectKind,switchboardType},
      });
      CBEVListDispatch({
        type: ACTIONS.UPDATE_CBEVLIST_COORDINATES,
        payload: { coordinates: coordinates, count: count },
      });

      let ecc = { ...coordinates.ecc };
      let busbar = { ...coordinates.busbar };
      const totalLoads = count.loadCount + count.evCount;

      if (count.derCount > count.loadCount + count.evCount) {
        if (totalLoads >= 10) {
          ecc.width = 1250;
          busbar.x2 = 1255;
        } else {
          ecc.width = coordinates.eccwidth + (count.derCount - 1) * 110;
          busbar.x2 = coordinates.busbarx2 + (count.derCount - 1) * 110;
        }
      } else {
        if (totalLoads >= 11) {
          ecc.width = 1250;
          busbar.x2 = 1255;
        } else {
          ecc.width = coordinates.eccwidth + (totalLoads - 1) * 110;
          busbar.x2 = coordinates.busbarx2 + (totalLoads - 1) * 110;
        }
      }

      setCoordinates((prevCoordinates) => ({
        ...prevCoordinates,
        ecc,
        busbar,
      }));

      if (componentPush) {
        generateComponentsBlock();
      }
      checkAssetStatus();
    }

    if (
      isGridConnected &&
      !isMgaasAnsi
    ) {
      let x21 =
        count.derCount === 2
          ? "695"
          : count.derCount === 3
          ? "870"
          : count.derCount === 4
          ? "985"
          : count.derCount === 5
          ? switchboardType === embLabels.EXISTING_SWITCHBOARD
            ? "1145"
            : "1045"
          : "795";

      if (switchboardType === embLabels.EXISTING_SWITCHBOARD) {
        setCoordinates((prevCoordinates) => ({
          ...prevCoordinates,
          xDerGrid: "480",
          xDerCBDiff: "700",
          yDerGrid: "243",
          yDerCBDiff: "370",
          xDerGridPM: "605",
          yDerGridPM: "227.5",
          cbEV: {
            xDerEV: 597.5,
            yDerEV: 492,
            xDerPM: 545,
            yDerPM: 487.5,
            x1DerEVCB: 675,
            y1DerEVCB: 570,
            x2DerEVCB: 675,
            y2DerEVCB: 464,
          },
          busbarCordinates: {
            ...prevCoordinates.busbarCordinates,
            x11: "126",
            y11: "280",
            x21: "345",
            y21: "280",
            x12: "620",
            y12: "470",
            x22: x21,
            y22: "470",
          },
        }));
      } else {
        let x21 =
          count.derCount === 2
            ? "695"
            : count.derCount === 3
            ? "810"
            : count.derCount === 4
            ? "915"
            : count.derCount === 5
            ? "1045"
            : "795";

        setCoordinates((prevCoordinates) => ({
          ...prevCoordinates,
          xDerGrid: "370",
          xDerCBDiff: "590",
          yDerGrid: "141",
          yDerCBDiff: "269",
          xDerGridPM: "495",
          yDerGridPM: "127.5",
          cbEV: {
            xDerEV: 707.5,
            yDerEV: 553,
            xDerPM: 655,
            yDerPM: 555.5,
            x1DerEVCB: 555,
            y1DerEVCB: 463,
            x2DerEVCB: 555,
            y2DerEVCB: 367,
          },
          busbarCordinates: {
            ...prevCoordinates.busbarCordinates,
            x11: "426",
            y11: "370",
            x21: x21,
            y21: "370",
          },
        }));
      }

      // CBAssetListDispatch({
      //   type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
      //   payload: { coordinates: coordinates },
      // });
      // CBLoadListDispatch({
      //   type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_GRIDCONNECTED,
      // });
      // CBEVListDispatch({
      //   type: ACTIONS.UPDATE_CBEVLIST_COORDINATES_GRIDCONNECTED,
      // });

      if (componentPush) {
        generateComponentsBlock();
      }
      checkAssetStatus();
    }

    if (isMgaasAnsiGrid) {
      if(isExistingSwitchBoard){
      setCoordinates((prevCoordinates) => ({
        ...prevCoordinates,
        cbEV: {
          xDerEV: 530,
          yDerEV: 490,
          xDerPM: 475,
          yDerPM: 490,
          x1DerEVCB: 608,
          y1DerEVCB: 570,
          x2DerEVCB: 608,
          y2DerEVCB: 470,
        },
              
      }));
    }else{
      setCoordinates((prevCoordinates) => ({
        ...prevCoordinates,
        cbEV: {
          xDerEV: 300,
          yDerEV: 320,
          xDerPM: 245,
          yDerPM: 320,
          x1DerEVCB: 377.5,
          y1DerEVCB: 398.5,
          x2DerEVCB: 378.5,
          y2DerEVCB: 300,
        },  
           
      }));
    }
    }else if(isMgaasAnsiIsland){
      if(isExistingSwitchBoard){
        setCoordinates((prevCoordinates) => ({
          ...prevCoordinates,
          cbEV: {
            xDerEV: 635,
            yDerEV: 450,
            xDerPM: 475,
            yDerPM: 490,
            x1DerEVCB: 608,
            y1DerEVCB: 570,
            x2DerEVCB: 608,
            y2DerEVCB: 470,
          },
                
        }));
      }else{
        setCoordinates((prevCoordinates) => ({
          ...prevCoordinates,
          cbEV: {
            xDerEV: 80,
            yDerEV: 320,
            xDerPM: 25,
            yDerPM: 320,
            x1DerEVCB: 377.5,
            y1DerEVCB: 398.5,
            x2DerEVCB: 378.5,
            y2DerEVCB: 300,
          },  
             
        }));
      }
    }

    if (siteType === embLabels.PV_HYBRIDIZATION) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVHYBRIDIZATION,
        payload: { coordinates: coordinates },
      });
      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVHYBRIDIZATION,
      });
      setCoordinates((prevCoordinates) => ({
        ...prevCoordinates,
        busbarCordinates: {
          ...prevCoordinates.busbarCordinates,
          x11: "426",
          y11: "360",
          x21: "815",
          y21: "360",
        },
      }));
      if (componentPush) {
        generateComponentsBlock();
      }
      checkAssetStatus();
    }

    if (
      siteType === embLabels.ISLANDABLE_GSX &&
      clientType === embLabels.GREENSTRUXURE_PROJECT &&
      architectureType === embLabels.PV_GENSET_ATS &&
      defaultPreFillData[1].mode !== "FUEL_SAVER"
    ) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVGENSET_ATS,
      });
      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVGENSET_ATS,
      });
      setCoordinates((prevCoordinates) => ({
        ...prevCoordinates,
        busbarCordinates: {
          ...prevCoordinates.busbarCordinates,
          x11: "168",
          y11: "290",
          x21: "408",
          y21: "290",
          x12: "670",
          y12: "430",
          x22: "840",
          y22: "430",
        },
      }));
      if (componentPush) {
        generateComponentsBlock();
      }
      checkAssetStatus();
    }

    if (
      (siteType === embLabels.ISLANDABLE_GSX &&
        clientType === embLabels.GREENSTRUXURE_PROJECT &&
        architectureType === embLabels.PV_GENSET_ATS &&
        defaultPreFillData[1].mode === "FUEL_SAVER") ||
      (siteType === embLabels.ISLANDABLE && projectTypeValue === "MGaaS ANSI")
    ) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVGENSET_ATS_SETTING_MODE,
        payload: {
          switchType:switchboardType,
          clientType,
          mode: defaultPreFillData[1]?.mode,
          loadSide:loadSide,
        }
      });
      // CBLoadListDispatch({
      //   type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVGENSET_ATS,
      // });

      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_GENSET_LOAD,
        payload: { coordinates: coordinates, count: count, projectKind: projectKind, switchboardType: switchboardType },
      });
      if(switchboardType===embLabels.EXISTING_SWITCHBOARD&&(isMgaasAnsiIsland||isDaasAnsiIsland)){
        CBLoadListDispatch({
          type: ACTIONS.UPDATE_REMOTE_LOAD_COORDINATES,
          payload: { coordinates: coordinates,loadSide:loadSide, count: count ,siteType:siteType, projectKind: projectKind,switchboardType},
        });
      }

      let x22;
      if (count.derCount <= 3 && count.loadCount <= 4) {
        x22 = "1095";
      } else if (count.derCount <= 4 && count.loadCount <= 4) {
        siteType === "GRID_CONNECTED_GSX" ||
        (["ISLANDABLE_GSX", "ISLANDABLE"].includes(siteType) &&
          (switchboardType === "" ||
            switchboardType === embLabels.EXISTING_SWITCHBOARD))
          ? (x22 = "1095")
          : (x22 = "510");
      } else if (count.derCount <= 4 && count.loadCount === 5) {
        switchboardType === embLabels.NEW_SWITCHBOARD &&
        ["ISLANDABLE_GSX", "ISLANDABLE"].includes(siteType)
          ? (x22 = 570)
          : (x22 = "1170");
      } else if (count.loadCount === 10) {
        switchboardType === embLabels.NEW_SWITCHBOARD &&
        ["ISLANDABLE_GSX", "ISLANDABLE"].includes(siteType)
          ? (x22 = 650)
          : (x22 = "1250");
      } else {
        {
          ["ISLANDABLE_GSX", "ISLANDABLE"].includes(siteType) &&
          switchboardType === embLabels.NEW_SWITCHBOARD
            ? (x22 = "620")
            : (x22 = "1170");
        }
      }

      // x21: "594",

      setCoordinates((prevCoordinates) => ({
        ...prevCoordinates,
        busbarCordinates: {
          ...prevCoordinates.busbarCordinates,
          x11: "158",
          y11: "285",
          x21: "330",
          y21: "285",
          x12: "630",
          y12: "430",
          x22: x22,
          y22: "430",
        },
      }));
      if (componentPush) {
        generateComponentsBlock();
      }
      checkAssetStatus();
    }

    if (
      (siteType === embLabels.GRID_CONNECTED_GSX &&
        clientType === embLabels.GREENSTRUXURE_PROJECT &&
        architectureType === embLabels.PV_BESS_NR) ||
      (siteType === embLabels.GRID_CONNECTED &&
        projectTypeValue === "MGaaS ANSI")
    ) {
      const x21 = count.derCount === 2 ? "815" : "715";
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVBESS_NR,
        payload: {
          switchType: switchboardType,
          mode: "",
          clientType:"",
          loadSide:loadSide
        }
      });
      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVBESS_NR,
        payload: switchboardType,
      });
      let x22;
      if (count.derCount > 3) {
        setRectangleWidth(530);
        if (
          ["GRID_CONNECTED_GSX", "GRID_CONNECTED"].includes(siteType) &&
          (switchboardType === "" ||
            switchboardType === embLabels.EXISTING_SWITCHBOARD)
        ) {
          x22 = 1065;
        } else {
          ["GRID_CONNECTED_GSX", "GRID_CONNECTED"].includes(siteType) &&
          switchboardType === embLabels.NEW_SWITCHBOARD
            ? (x22 = 690)
            : (x22 = 640);
          setRectangleWidth(730);
        }
      } else {
        if (
          !["GRID_CONNECTED_GSX", "GRID_CONNECTED"].includes(siteType) ||
          (siteType !== "ISLANDABLE_GSX" &&
            (switchboardType === "" ||
              switchboardType === embLabels.EXISTING_SWITCHBOARD))
        ) {
          setRectangleWidth(420);
          x22 = 965;
        } else {
          setRectangleWidth(580);
          !["GRID_CONNECTED_GSX", "GRID_CONNECTED"].includes(siteType) ||
          (siteType !== "ISLANDABLE_GSX" &&
            (switchboardType !== "" ||
              switchboardType !== embLabels.EXISTING_SWITCHBOARD))
            ? (x22 = 510)
            : (x22 = 965);
        }
      }
      setCoordinates((prevCoordinates) => ({
        ...prevCoordinates,
        busbarCordinates: {
          ...prevCoordinates.busbarCordinates,
          x11: "168",
          y11: "283",
          x21: "408",
          y21: "283",
          x12: "630",
          y12: "430",
          x22,
          y22: "430",
        },
      }));

      if (componentPush) {
        generateComponentsBlock();
      }
      checkAssetStatus();
    }
    if(siteType === embLabels.ISLANDABLE && projectTypeValue === "MGaaS ANSI"){
      if(switchboardType === embLabels.EXISTING_SWITCHBOARD){
        setCoordinates((prevCoordinates) => ({
          ...prevCoordinates,
          cbEV: {
            xDerEV: 250,
            yDerEV: 290,
            xDerPM: 195,
            yDerPM: 330,
            x1DerEVCB: 608,
            y1DerEVCB: 570,
            x2DerEVCB: 608,
            y2DerEVCB: 470,
          },
                
        }));
      }else{
        setCoordinates((prevCoordinates) => ({
          ...prevCoordinates,
          cbEV: {
            xDerEV: 80,
            yDerEV: 320,
            xDerPM: 25,
            yDerPM: 320,
            x1DerEVCB: 377.5,
            y1DerEVCB: 398.5,
            x2DerEVCB: 378.5,
            y2DerEVCB: 300,
          },  
             
        }));
      }
    }
    if(projectKind.isMgaasIecIsland){
      if(switchboardType === embLabels.EXISTING_SWITCHBOARD){
        setCoordinates((prevCoordinates) => ({
          ...prevCoordinates,
          cbEV: {
            xDerEV: 310,
            yDerEV: 475,
            xDerPM: 255,
            yDerPM: 515,
            x1DerEVCB: 355,
            y1DerEVCB: 470,
            x2DerEVCB: 355,
            y2DerEVCB: 470,
          },
                
        }));
      }else{
        setCoordinates((prevCoordinates) => ({
          ...prevCoordinates,
          cbEV: {
            xDerEV: 300,
            yDerEV: 365,
            xDerPM: 245,
            yDerPM: 370,
            x1DerEVCB: 345,
            y1DerEVCB: 370,
            x2DerEVCB: 378.5,
            y2DerEVCB: 300,
          },  
             
        }));
      }
    }
    CBEVListDispatch({
      type: ACTIONS.UPDATE_CBEVLIST_COORDINATES_GRIDCONNECTED,
      payload: { coordinates: coordinates, count: count },
    }); 
    if (
      siteType === embLabels.GENSET_PVBESS_A2E &&
      clientType === embLabels.A2E_PROJECT
    ) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GENSET_PVBESS_A2E,
        payload: { coordinates: coordinates },
      });
      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVHYBRIDIZATION,
      });
      setCoordinates((prevCoordinates) => ({
        ...prevCoordinates,
        busbarCordinates: {
          ...prevCoordinates.busbarCordinates,
          x11: "426",
          y11: "360",
          x21: "815",
          y21: "360",
        },
      }));
      checkAssetStatus();

    }
    if(isMgaasIecIsland){
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_ISLANDABLE,
        payload: {
          switchType: switchboardType,
          mode: defaultPreFillData[1]?.mode,
          projectKind: projectKind,
        },
      });
    }
    if(switchboardType===embLabels.EXISTING_SWITCHBOARD&&(isMgaasAnsiIsland||isDaasAnsiIsland)){
      CBLoadListDispatch({
        type: ACTIONS.UPDATE_REMOTE_LOAD_COORDINATES,
        payload: { coordinates: coordinates,loadSide:loadSide, count: count ,siteType:siteType, projectKind: projectKind,switchboardType},
      });
    }
    let x11, y11, x21, y21, x12, y12, x22, y22;
    let coOrdinates = { x11, y11, x21, y21, x12, y12, x22, y22 };
    const coOrdinateLists = coOrdinateValidator(
      coOrdinates,
      switchboardType,
      siteType,
      projectType,
      count,
      rectangleWidth,
      loadSide,
      clientType,
      defaultPreFillData[1]?.mode,
      projectKind,
    );
    setRectangleWidth(coOrdinateLists.rectangleWidth);
    setCoordinates((prevCoordinates) => ({
      ...prevCoordinates,
      busbarCordinates: {
        ...prevCoordinates.busbarCordinates,
        ...coOrdinateLists.cooridinates,
      },
    }));
  };

  const checkAssetStatus = () => {
    let componentConfigStatus = true;
    CBAssetList.forEach((componentData, index) => {
      if (componentData.uiData.status && [0, 1, 2, 6, 7].includes(index)) {
        if (!componentData.uiData.configured) {
          componentConfigStatus = false;
        }
      }
      if (componentData.uiData.status) {
        if ( 
          !componentData?.uiData?.CBConfigured &&
          componentData?.uiData?.cbRequired
        ) {
          componentConfigStatus = false;
        }
        // if (
        //   !componentData?.uiData?.pmRequired &&
        //   !(componentData?.uiData?.pmConfiguredGrid === undefined||componentData?.uiData?.pmConfiguredGrid)
        // ) {
        //   componentConfigStatus = false;
        // }
        if (
          componentData?.uiData?.pmRequired &&
          !componentData?.uiData?.pmConfiguredGrid&&['DaaS ANSI','DaaS IEC','MGaaS ANSI','MGaaS IEC'].includes(projectTypeValue )
        ) {
          componentConfigStatus = false;
        }
      }
    });
    CBEVList.forEach((componentData) => {
      if (componentData.uiData.status) {
        if (
          !componentData.uiData.configured ||
          (componentData.uiData.cbRequired && !componentData.uiData.CBConfigured) ||
          (componentData.uiData.pmRequired &&
            !componentData.uiData.pmConfiguredGrid)
        ) {
          componentConfigStatus = false;
        }
      }
    });
    if (
      [
        embLabels.ISLANDABLE,
        embLabels.GRID_CONNECTED,
        embLabels.GENSET_PVBESS_A2E,
      ].includes(siteType) ||
      (siteType === embLabels.ISLANDABLE_GSX &&
        defaultPreFillData[1].mode === "FUEL_SAVER")
    ) {
      CBLoadList.forEach((componentData) => {
        if (componentData?.uiData?.status) {
          if (
            !componentData?.uiData?.configured&&
            !(isMgaasAnsi || isA2E)
            && !isGridConnected
          ) {
            componentConfigStatus = false;
          }
          if(isMgaasIecIsland && (componentData.uiData.pmRequired &&
            !componentData.uiData.pmConfiguredGrid)){
            componentConfigStatus = false;

          }
          if(!componentData?.uiData?.CBConfigured && !(isMgaasIecIsland ||isMgaasIecGrid || isA2E) && !isGridConnected){
            componentConfigStatus = false;
          }else if(isMgaasIecIsland&&componentData?.uiData?.cbRequired&&!componentData?.uiData?.CBConfigured){
            componentConfigStatus = false;
          }
        }
      });
    }

    PVDetails.pvset1Data.forEach((pvData) => {
      if (!pvData.status) {
        componentConfigStatus = false;
      }
    });
    PVDetails.pvset2Data.forEach((pvData) => {
      if (!pvData.status) {
        componentConfigStatus = false;
      }
    });
    PVDetails.pvset3Data.forEach((pvData) => {
      if (!pvData.status) {
        componentConfigStatus = false;
      }
    });
    localStorage.setItem("deploymentReady", componentConfigStatus);
    return componentConfigStatus;
  };

  const checkExportOptimizationCondition = () => {
    if (
      defaultPreFillData[1]?.exportOptimization &&
      !CBAssetList[2]?.uiData?.status
    ) {
      if (UserScope.access.saveSLD) {
        setTimeout(() => {
          if (siteType !== embLabels.GRID_CONNECTED) {
            addBattery();
          }
        }, 300);
      }
    }
  };

  const padNumberFormat = (number) => (number < 10 ? "0" : "") + number;

  const generateComponentsBlock = () => {
    const componentJson = {
      projectId: "",
      deploymentReady: true,
      programPage: true,
      components: [],
      switchboardType: switchboardType,
      remoteMain:remoteMain,
      connectionType: null,
    };

    if (
      siteType === embLabels.GRID_CONNECTED_GSX ||
      siteType === embLabels.ISLANDABLE ||siteType === embLabels.ISLANDABLE_GSX ||
      siteType === embLabels.GRID_CONNECTED
    ) {
      componentJson.connectionType = loadSide;
    }
    componentJson.projectId = getProjectId()
      ? getProjectId()
      : "1";
    componentJson.userId = 1;
    let arrayComponent = [];
    let assetCB = 10;
    let assetI = 1;
    //* assets
    const CBAssetListNew = _.cloneDeep(CBAssetList);
    const projectTypeValue = getProjectTypeValue()
    //cb conncetion done here
    CBAssetListNew.map((cbData, key) => {
      if (cbData.uiData.status) {
        let CBComponentIdx = "CB_10";
        let CRComponentIdx = "Contactor_10";
        let PMComponentIdx = "PM_10";
        switch (cbData.assetType) {
          case embLabels.UTILITY:
          case embLabels.GENSET:
          case embLabels.BESS:

          case embLabels.CHP:
          case embLabels.HYBRID:
            let asset = {};
            if (cbData.assetType === embLabels.UTILITY) {
              asset.assetId = 1;
              CBComponentIdx = "CB_10";
              CRComponentIdx = "Contactor_10";
              PMComponentIdx = "PM_10";
              asset.componentType = "Utility";
              asset.externalId = gsxExternalId?.utilityExternalId
                ? gsxExternalId?.utilityExternalId
                : null;
            }
            if (cbData.assetType === embLabels.GENSET) {
              if (cbData.assetDetails.length) {
                asset.assetId = cbData.assetDetails[0]?.assetId;
              } else {
                asset.assetId = 2;
              }
              CBComponentIdx = "CB_20";
              CRComponentIdx = "Contactor_20";
              PMComponentIdx = "PM_20";
              asset.componentType = "Genset";
              asset.externalId = gsxExternalId?.gensetExternalId
                ? gsxExternalId.gensetExternalId
                : null;
            }
            if (cbData.assetType === embLabels.BESS) {
              if (cbData.assetDetails.length) {
                asset.assetId = cbData.assetDetails[0]?.assetId;
              } else {
                asset.assetId = 3;
              }
              CBComponentIdx = "CB_30";
              CRComponentIdx = "Contactor_30";
              PMComponentIdx = "PM_30";
              asset.componentType = "BESS";
              asset.externalId = gsxExternalId?.utilityExternalId
                ? gsxExternalId.batteryExternalId
                : null;
            }

            //* CHP

            if (cbData.assetType === embLabels.CHP) {
              if (cbData.assetDetails.length) {
                asset.assetId = cbData.assetDetails[0]?.assetId;
              } else {
                asset.assetId = 3;
              }
              CBComponentIdx = "CB_80";
              PMComponentIdx = "PM_80";
              asset.componentType = "CHP";
            }

            if (cbData.assetType === embLabels.HYBRID) {
              if (cbData.assetDetails.length) {
                asset.assetId = cbData.assetDetails[0]?.assetId;
              } else {
                asset.assetId = 3;
              }
              CBComponentIdx = "CB_90";
              PMComponentIdx = "PM_90";
              asset.componentType = "Hybrid";
            }
            asset.userId = 1;
            asset.componentIdx = "ASSET_" + padNumberFormat(assetI);
            asset.componentName = cbData.configData.name;
            if(projectTypeValue === "DaaS ANSI"){
              asset.deviceName = cbData?.configData?.deviceName;
            }
            componentJson.deploymentReady =
              componentJson?.deploymentReady && cbData?.uiData?.configured
                ? true
                : false;
            asset.connections = [];
            asset.communications = [];
            asset.uiAttributes = JSON.stringify(cbData.uiData);

            let assetConnections = {
              componentIdx: CBComponentIdx,
              endpointIdX: "1",
              connectionType: "electrical_connection",
            };
            let assetAttributes = {};
            if(cbData.configData.max_power!=undefined&&cbData.configData.max_power!=""){
              let values = JSON.parse(cbData.configData.max_power)||""
              if(Array.isArray(values)){
                const configDataNew = {...cbData.configData}
                configDataNew.max_power = values[0];
                cbData.configData = configDataNew
              }
            }
            assetAttributes = cbData.configData;
            if (cbData.assetType === embLabels.GENSET) {
              delete assetAttributes.min_power;
            }
            asset.attributes = JSON.stringify(assetAttributes);
            if (
              [
                embLabels.ISLANDABLE,
                embLabels.PV_HYBRIDIZATION,
                embLabels.GENSET_PVBESS_A2E,
                embLabels.GRID_CONNECTED_GSX,
                embLabels.GRID_CONNECTED,
              ].includes(siteType) ||
              architectureType === embLabels.PV_GENSET_ATS
            ) {
              asset.connections.push(assetConnections);
            }

            if (cbData.assetType === embLabels.GENSET) {
              if (
                cbData?.assetDetails?.length && cbData?.assetDetails?.[0] && 
                cbData.assetDetails[0].assetInfo === "Hardwired"
              ) {
                //*do nothing
              }
            }
            if (cbData.assetType === embLabels.HYBRID) {
              asset.communications = [
                {
                  ip: cbData?.communicationDataBess?.ipaddress,
                  subnetmask: cbData?.communicationDataBess?.subnetmask,
                  defaultGateway: cbData?.communicationDataBess?.defaultGateway,
                  modbusSlaveId: cbData?.communicationDataBess?.modbusSlaveId,
                  type: "BESS",
                },
                {
                  ip: cbData?.communicationDataPv?.ipaddress,
                  subnetmask: cbData?.communicationDataPv?.subnetmask,
                  defaultGateway: cbData?.communicationDataPv?.defaultGateway,
                  modbusSlaveId: cbData?.communicationDataPv?.modbusSlaveId,
                  type: "PV",
                },
              ];
            } else if (
              projectTypeValue === "MGaaS IEC" &&
              // siteType === embLabels.GRID_CONNECTED &&
              cbData.assetType === embLabels.BESS
            ) {
              asset.communications = [];
              if (projectTypeValue === "MGaaS IEC") {
                if (
                  cbData?.assetDetails[0]?.communicationModel ===
                  "AC Coupled Power Conversion Unit"
                  ) {
                  let assetCommunications = {
                    ip: defaultPreFillData?.[0]?.["BESS"]?.pcs_ipAddress,
                    subnetmask: defaultPreFillData?.[0]?.["BESS"]?.subnetMask,
                    defaultGateway: defaultPreFillData?.[0]?.["BESS"]?.defaultGateway,
                    modbusSlaveId:
                      defaultPreFillData?.[0]?.["BESS"]?.pcs_slaveid ??
                      cbData?.communicationDataPv?.modbusSlaveId,
                    type: "PCS",
                  };
                  asset.communications.push(assetCommunications);
                  let assetCommunications1 = {
                    ip: defaultPreFillData?.[0]?.["BESS"]?.mbms_ipAddress,
                    subnetmask:defaultPreFillData?.[0]?.["BESS"]?.subnetMask,
                    defaultGateway:defaultPreFillData?.[0]?.["BESS"]?.defaultGateway,
                    modbusSlaveId:
                      defaultPreFillData?.[0]?.["BESS"]?.mbms_slaveid ??
                      cbData?.communicationDataBess?.modbusSlaveId,
                    type: "MBMS",
                  };
                  asset.communications.push(assetCommunications1);
                } else if (cbData?.assetDetails?.[0]?.assetInfo === "Hardwired") {
                  asset.communications = [];
                } else {
                  let assetCommunications = {
                    ip: cbData?.communicationDataBess?.ipaddress??defaultPreFillData?.[0]?.["BESS"]?.ipAddress,
                    subnetmask: cbData?.communicationDataBess?.subnetMask??defaultPreFillData?.[0]?.["BESS"]?.subnetMask,
                    defaultGateway:
                      cbData?.communicationDataBess?.defaultGateway??defaultPreFillData?.[0]?.["BESS"]?.defaultGateway,
                    modbusSlaveId: cbData?.communicationDataBess?.modbusSlaveId??defaultPreFillData?.[0]?.["BESS"]?.modbusSlaveId,
                    type: "BESS",
                  };
                  asset.communications.push(assetCommunications);
                }
              }
            } else {
              if (cbData.communicationData.modbusSlaveId) {
                let assetCommunications = {
                  ip: cbData?.communicationData?.ipaddress,
                  subnetmask: cbData?.communicationData?.subnetmask,
                  defaultGateway: cbData?.communicationData?.defaultGateway,
                  modbusSlaveId: cbData?.communicationData?.modbusSlaveId,
                  type: cbData.assetType,
                };

                asset.communications.push(assetCommunications);

                if (cbData.assetType === embLabels.GENSET || embLabels.BESS) {
                  if (cbData.assetDetails.length) {
                    if (cbData.assetDetails?.[0].assetInfo === "Hardwired") {
                      asset.communications = [];
                    }
                  }
                }
              }
            }

            arrayComponent.push(asset);
            if (
              (siteType === embLabels.ISLANDABLE||siteType === embLabels.ISLANDABLE_GSX) ||
              (siteType === embLabels.GRID_CONNECTED &&
                cbData.assetType === embLabels.BESS || cbData.assetType === embLabels.UTILITY
                // &&cbData.uiData.cbRequired
                ) ||
              (projectKind.isMgaasAnsiGrid &&cbData.assetType === embLabels.UTILITY) ||
              (siteType === embLabels.GRID_CONNECTED_GSX &&
                cbData.assetType === embLabels.UTILITY 
                // &&cbData.uiData.cbRequired
                ) ||
              (siteType === embLabels.ISLANDABLE_GSX &&
                defaultPreFillData[1]?.mode === "FUEL_SAVER") ||
              (siteType === embLabels.ISLANDABLE_GSX &&
                defaultPreFillData[1]?.mode === "GENSET_MAIN_NO_BESS" &&
                cbData.assetType === embLabels.UTILITY) ||
              (siteType === embLabels.PV_HYBRIDIZATION &&
                cbData.assetType === embLabels.UTILITY) ||
              (siteType === embLabels.PV_HYBRIDIZATION &&
                cbData.assetType === embLabels.GENSET)
            ) {
              let cb = {};
              cb.assetId = cbData?.cbConfigData?.cbAssetDetails?.[0]?.assetId ?? 7;
              cb.userId = 1;
              cb.componentIdx = CBComponentIdx;
              cb.componentName = cbData?.cbConfigData?.name;
              cb.componentType = "Circuit Breaker";
              cb.connections = [];
              cb.communications = [];
              cb.uiAttributes = JSON.stringify(cbData.uiData);
              let connections = {
                componentIdx: "ASSET_" + padNumberFormat(assetI),
                endpointIdX: "1",
                connectionType: "electrical_connection",
              };

              let attributes = {
                name: cbData?.cbConfigData?.name,
                description: cbData?.cbConfigData?.description,
                motorized: cbData?.cbConfigData?.motorized ? 1 : 0,
                contactor: cbData?.cbConfigData?.contactor ? 1 : 0,
                loadCategory: cbData?.cbConfigData?.loadCategory,
                loadPriority: cbData?.cbConfigData?.loadPriority,
                Contactor_healthy_status_available: 0,
              };

              if (
                siteType === embLabels.GRID_CONNECTED &&
                cbData.assetType === embLabels.BESS &&
                !cbData?.uiData?.cbRequired
              ) {
                attributes.hardwired = cbData?.cbConfigData?.hardwired;
              }

             
              if (
                // siteType === embLabels.GRID_CONNECTED &&
                // cbData?.cbConfigData?.motorized &&
                cbData?.cbConfigData?.cbAssetDetails?.[0]?.assetInfo ===
                  "Hardwired"
              ) {
                attributes.type = "12";
              }
              if (
                // siteType === embLabels.GRID_CONNECTED &&
                cbData.assetType === embLabels.BESS &&
                cbData?.uiData?.contactor
              ) {
                attributes.type = "20";
                attributes.Contactor_healthy_status_available = cbData?.contactorConfigData?.cbAssetDetails?.[0]?.assetAttributes?.[0]?.value;
              }
              if(!cbData?.cbConfigData?.motorized&&!cbData?.uiData?.contactor){
                attributes.type = "40"
              }
              if (
                // siteType === embLabels.GRID_CONNECTED &&
                cbData.assetType === embLabels.BESS &&
                !cbData?.uiData?.cbRequired
              ) {
                attributes.type = "40";
              }
              if(isMgaasIecIsland){
                if(cbData?.cbConfigData?.cbAssetDetails?.[0]?.assetInfo === "Hardwired"){
                  attributes.type = "12";
                }

              
                      if(!cbData.uiData?.contactor&&!cbData?.cbConfigData?.motorized){
          attributes.type = "40"
        }
        if (!(cbData.assetType === embLabels.GENSET || cbData.assetType === embLabels.UTILITY) && !cbData?.uiData?.cbRequired) {
          attributes.type = "40";
        }
        if(cbData?.uiData.CBConfigured&&cbData?.cbConfigData?.cbAssetDetails?.[0]?.assetInfo==="Communicating"){
            delete attributes.type;
        }

        if (cbData?.uiData?.contactor) {
          attributes.type = "20";
          attributes.Contactor_healthy_status_available = cbData?.contactorConfigData?.cbAssetDetails?.[0]?.assetAttributes?.[0]?.value;
        }
            }
              cb.attributes = JSON.stringify(attributes);
              //cb values
              let communications = {};

              if (cbData.assetType === embLabels.UTILITY) {
                communications = {
                  ip: cbData?.cbConfigData?.cbCommunicationData?.ipaddress,
                  subnetmask:
                    cbData?.cbConfigData?.cbCommunicationData?.subnetmask,
                  defaultGateway:
                    cbData?.cbConfigData?.cbCommunicationData?.defaultGateway,
                  modbusSlaveId:
                    cbData?.cbConfigData?.cbCommunicationData?.modbusSlaveId,
                  type: "Circuit Breaker",
                };
              }
              if (cbData.assetType === embLabels.GENSET) {
                communications = {
                  ip: cbData?.cbConfigData?.cbCommunicationData?.ipaddress,
                  subnetmask:
                    cbData?.cbConfigData?.cbCommunicationData?.subnetmask,
                  defaultGateway:
                    cbData?.cbConfigData?.cbCommunicationData?.defaultGateway,
                  modbusSlaveId:
                    cbData?.cbConfigData?.cbCommunicationData?.modbusSlaveId,
                  type: "Circuit Breaker",
                };
              }
              if (cbData.assetType === embLabels.BESS) {
                // her ethe code goes add pcu and mbms if()

                communications = {
                  ip: cbData?.cbConfigData?.cbCommunicationData?.ipaddress,
                  subnetmask:
                    cbData?.cbConfigData?.cbCommunicationData?.subnetmask,
                  defaultGateway:
                    cbData?.cbConfigData?.cbCommunicationData?.defaultGateway,
                  modbusSlaveId:
                    cbData?.cbConfigData?.cbCommunicationData?.modbusSlaveId,
                  type: "Circuit Breaker",
                };
              }
              cb.connections.push(connections);
              cb.communications.push(communications);

              if (
                clientType === embLabels.A2E_PROJECT &&
                siteType === embLabels.GENSET_PVBESS_A2E &&
                (cbData.assetType === embLabels.GENSET ||
                  cbData.assetType === embLabels.HYBRID)
              ) {
                cb.communications = [];
              }
              arrayComponent.push(cb);
              assetCB = assetCB + 10;
            }

            if (
              [embLabels.ISLANDABLE,embLabels.GRID_CONNECTED].includes(siteType) &&
              cbData.assetType === embLabels.BESS &&
              cbData?.uiData?.contactor&&!cbData.cbConfigData.motorized
            ) {
              let cb = {};
              cb.assetId =
                cbData?.contactorConfigData?.cbAssetDetails?.[0]?.assetId ?? 7;
              cb.userId = 1;
              cb.componentIdx = CRComponentIdx;
              cb.componentName = cbData?.contactorConfigData?.name;
              cb.componentType = "Contactor";
              cb.connections = [];
              cb.communications = [];
              cb.uiAttributes = JSON.stringify(cbData.uiData);
              let connections = {
                componentIdx: CBComponentIdx,
                endpointIdX: "1",
                connectionType: "electrical_connection",
              };

              let attributes = {
                name: cbData?.contactorConfigData?.name,
                description: cbData?.contactorConfigData?.description,
                loadCategory: cbData?.contactorConfigData?.loadCategory,
                loadPriority: cbData?.contactorConfigData?.loadPriority,
              };

              cb.attributes = JSON.stringify(attributes);

              //cb values
              let communications = {};

              cb.connections.push(connections);
              cb.communications.push(communications);
              arrayComponent.push(cb);
              assetCB = assetCB + 10;
            }

            //* for pvhybridization

            if (
              // siteType === embLabels.PV_HYBRIDIZATION ||
              architectureType === embLabels.PV_GENSET_ATS
            ) {
              let cb = {};
              cb.assetId = powerMeter?.assetId
                ? powerMeter?.assetId
                : cbData?.powerMeterData?.pmAsset?.assetId;
              cb.userId = 1;
              cb.componentIdx = PMComponentIdx;
              cb.componentName = cbData?.powerMeterData?.name??"PV Hybrid";
              cb.componentType = "Power Meter";
              cb.connections = [];
              cb.communications = [];
              cb.uiAttributes = JSON.stringify(cbData.uiData);
              let connections = {
                componentIdx: "ASSET_" + padNumberFormat(assetI),
                endpointIdX: "1",
                connectionType: "electrical_connection",
              };
              let attributes = {
                name: cbData?.powerMeterData?.name,
                description: cbData?.powerMeterData?.description,
                powerMeterModbusId: cbData?.powerMeterData?.powerMeterModbusId,
              };
              cb.attributes = JSON.stringify(attributes);

              //*cb values
              let communications = {};
              communications = {
                ip: cbData?.powerMeterData?.ipAddress,
                subnetmask: cbData?.powerMeterData?.mask,
                defaultGateway: cbData?.powerMeterData?.gatewayAddress,
                modbusSlaveId: cbData?.powerMeterData?.powerMeterModbusId,
                type: "Circuit Breaker",
              };
              cb.connections.push(connections);
              cb.communications.push(communications);
              arrayComponent.push(cb);
              assetCB = assetCB + 10;
            }

            if (
              architectureType === embLabels.PV_GENSET_ATS &&
              cbData.uiData.existingPmRequired
            ) {
              let cb = {};
              cb.assetId = powerMeter?.assetId
                ? powerMeter?.assetId
                : cbData?.existingPowerMeterData?.pmAsset?.assetId;
              cb.userId = 1;
              cb.componentIdx = "PM_50";
              cb.componentName = cbData?.existingPowerMeterData?.name;
              cb.componentType = "Power Meter";
              cb.connections = [];
              cb.communications = [];
              cb.uiAttributes = JSON.stringify(cbData.uiData);
              let connections = {
                componentIdx: "ASSET_" + padNumberFormat(assetI),
                endpointIdX: "1",
                connectionType: "electrical_connection",
              };
              let attributes = {
                name: cbData?.existingPowerMeterData?.name,
                description: cbData?.existingPowerMeterData?.description,
                powerMeterModbusId:
                  cbData?.existingPowerMeterData?.powerMeterModbusId,
              };
              cb.attributes = JSON.stringify(attributes);

              //*cb values
              let communications = {};
              communications = {
                ip: cbData?.existingPowerMeterData?.ipAddress,
                subnetmask: cbData?.existingPowerMeterData?.mask,
                defaultGateway: cbData?.existingPowerMeterData?.gatewayAddress,
                modbusSlaveId:
                  cbData?.existingPowerMeterData?.powerMeterModbusId,
                type: "Circuit Breaker",
              };
              cb.connections.push(connections);
              cb.communications.push(communications);
              arrayComponent.push(cb);
              assetCB = assetCB + 10;
            }

            //* pv+bess(nr)
            if (
              siteType === embLabels.GRID_CONNECTED_GSX &&
              cbData.assetType === embLabels.UTILITY
            ) {
              // do nothing
            }

            if (
              (siteType === embLabels.GRID_CONNECTED_GSX &&
                architectureType === embLabels.PV_BESS_NR &&
                cbData.assetType === embLabels.UTILITY &&
                cbData.uiData.pmRequired) ||
              ((projectKind.isMgaasAnsiIsland && cbData.assetType === embLabels.UTILITY )||( projectKind.isMgaasIecIsland &&
                cbData.assetType === embLabels.UTILITY))
            ) {
              let cb = {};
              cb.assetId = powerMeter?.assetId
                ? powerMeter?.assetId
                : cbData?.powerMeterData?.pmAsset?.assetId;
              cb.userId = 1;
              cb.componentIdx = PMComponentIdx;
              cb.componentName = cbData.powerMeterData.name??"PM_Utility";
              cb.componentType = "Power Meter";
              cb.connections = [];
              cb.communications = [];
              cb.uiAttributes = JSON.stringify(cbData.uiData);
              let connections = {
                componentIdx: "ASSET_" + padNumberFormat(assetI),
                endpointIdX: "1",
                connectionType: "electrical_connection",
              };
              let attributes = {
                name: cbData?.powerMeterData?.name,
                description: cbData?.powerMeterData?.description,
                powerMeterModbusId: cbData?.powerMeterData?.powerMeterModbusId,
              };
              cb.attributes = JSON.stringify(attributes);

              //*cb values

              let communications = {};
              communications = {
                ip: cbData?.powerMeterData?.ipAddress,
                subnetmask: cbData?.powerMeterData?.mask,
                defaultGateway: cbData?.powerMeterData?.gatewayAddress,
                modbusSlaveId: cbData?.powerMeterData?.powerMeterModbusId,
                type: "Power Meter",
              };
              cb.connections.push(connections);
              cb.communications.push(communications);
              arrayComponent.push(cb);
              assetCB = assetCB + 10;
            }

            if (
              siteType === embLabels.GRID_CONNECTED_GSX &&
              architectureType === embLabels.PV_BESS_NR &&
              cbData.assetType === embLabels.BESS 
              // &&cbData.uiData.cbRequired
            ) {
              let cb = {};
              cb.assetId =
                cbData?.cbConfigData?.cbAssetDetails?.[0]?.assetId ?? 7;
              cb.userId = 1;
              cb.componentIdx = CBComponentIdx;
              cb.componentName = cbData?.cbConfigData?.name;
              cb.componentType = "Circuit Breaker";
              cb.connections = [];
              cb.communications = [];
              cb.uiAttributes = JSON.stringify(cbData.uiData);
              let connections = {
                componentIdx: "ASSET_" + padNumberFormat(assetI),
                endpointIdX: "1",
                connectionType: "electrical_connection",
              };

              let attributes = {
                name: cbData?.cbConfigData?.name,
                description: cbData?.cbConfigData?.description,
                motorized: cbData.cbConfigData.motorized? 1 : 0,
                contactor: cbData.cbConfigData.contactor ? 1 : 0,
                loadCategory: cbData?.cbConfigData?.loadCategory,
                loadPriority: cbData?.cbConfigData?.loadPriority,
              };
              if (
                // siteType === embLabels.GRID_CONNECTED &&
                // cbData?.cbConfigData?.motorized &&
                cbData?.cbConfigData?.cbAssetDetails?.[0]?.assetInfo ===
                  "Hardwired"
              ) {
                attributes.type = "12";
              }
              cb.attributes = JSON.stringify(attributes);

              //cb values
              let communications = {
                ip: cbData?.cbConfigData?.cbCommunicationData?.ipaddress,
                subnetmask:
                  cbData?.cbConfigData?.cbCommunicationData?.subnetmask,
                defaultGateway:
                  cbData?.cbConfigData?.cbCommunicationData?.defaultGateway,
                modbusSlaveId:
                  cbData?.cbConfigData?.cbCommunicationData?.modbusSlaveId,
                type: "Circuit Breaker",
              };

              cb.connections.push(connections);
              cb.communications.push(communications);
              arrayComponent.push(cb);
              assetCB = assetCB + 10;
            }

            if (
              (siteType === embLabels.GRID_CONNECTED_GSX &&
                architectureType === embLabels.PV_BESS_NR &&
                cbData.assetType === embLabels.BESS &&
                cbData.uiData.existingPmRequired) ||
               (projectKind.isMgaasAnsiGrid&&
                cbData.uiData.existingPmRequired)
            ) {
              let cb = {};
              cb.assetId = powerMeter?.assetId
                ? powerMeter?.assetId
                : cbData?.existingPowerMeterData?.pmAsset?.assetId;
              cb.userId = 1;
              cb.componentIdx = "PM_50";
              cb.componentName = cbData?.existingPowerMeterData?.name;
              cb.componentType = "Power Meter";
              cb.connections = [];
              cb.communications = [];
              cb.uiAttributes = JSON.stringify(cbData.uiData);
              let connections = {
                componentIdx: "ASSET_" + padNumberFormat(assetI),
                endpointIdX: "1",
                connectionType: "electrical_connection",
              };
              let attributes = {
                name: cbData?.existingPowerMeterData?.name,
                description: cbData?.existingPowerMeterData?.description,
                powerMeterModbusId:
                  cbData?.existingPowerMeterData?.powerMeterModbusId,
              };
              cb.attributes = JSON.stringify(attributes);

              //*cb values
              let communications = {};
              communications = {
                ip: cbData?.existingPowerMeterData?.ipAddress,
                subnetmask: cbData?.existingPowerMeterData?.mask,
                defaultGateway: cbData?.existingPowerMeterData?.gatewayAddress,
                modbusSlaveId:
                  cbData?.existingPowerMeterData?.powerMeterModbusId,
                type: "Circuit Breaker",
              };
              cb.connections.push(connections);
              cb.communications.push(communications);
              arrayComponent.push(cb);
              assetCB = assetCB + 10;
            }

            if (
              (siteType === embLabels.GRID_CONNECTED_GSX &&
                architectureType === embLabels.PV_BESS_NR &&
                cbData.assetType === embLabels.BESS &&
                cbData.uiData.pmRequired) ||
             (projectKind.isMgaasAnsiIsland &&
                cbData.assetType === embLabels.BESS&&
              cbData.uiData.pmRequired)||
              (projectKind.isMgaasIecIsland&&
                cbData.assetType === embLabels.BESS&&cbData.uiData.pmRequired)
            ) {
              let cb = {};
              cb.assetId = powerMeter?.assetId
                ? powerMeter?.assetId
                : cbData?.powerMeterData?.pmAsset?.assetId
                ? cbData?.powerMeterData?.pmAsset?.assetId
                : 153;

              // cb.assetId = 153
              cb.userId = 1;
              cb.componentIdx = PMComponentIdx;
              cb.componentName = "PM_BESS";
              cb.componentType = "Power Meter";
              cb.connections = [];
              cb.communications = [];
              cb.uiAttributes = JSON.stringify(cbData.uiData);
              let connections = {
                componentIdx: "ASSET_" + padNumberFormat(assetI),
                endpointIdX: "1",
                connectionType: "electrical_connection",
              };
              let attributes = {
                name: "PM_BESS",
                description: cbData?.powerMeterData?.description,
                powerMeterModbusId: "255",
              };
              cb.attributes = JSON.stringify(attributes);

              //*cb values

              let communications = {};
              communications = {
                ip: cbData?.powerMeterData?.ipAddress,
                modbusSlaveId: cbData?.powerMeterData?.powerMeterModbusId,
                subnetmask: cbData?.powerMeterData?.mask,
                defaultGateway: cbData?.powerMeterData?.gatewayAddress,
                type: "Power Meter",
              };
              cb.connections.push(connections);
              cb.communications.push(communications);

              arrayComponent.push(cb);
              assetCB = assetCB + 10;
            }

            //*for grid_connected
            if (
              (siteType === embLabels.GRID_CONNECTED &&
                cbData.assetType === embLabels.UTILITY) ||
              ((siteType === embLabels.GRID_CONNECTED||((siteType===embLabels.ISLANDABLE && ! isMgaasAnsiIsland) 
                && !isMgaasIecIsland)) && cbData.uiData.pmRequired)
            ) {
            
              let cb = {};
              cb.assetId =
                cbData?.powerMeterData?.pmAsset?.assetId ?? powerMeter?.assetId;
              cb.userId = 1;
              cb.componentIdx = PMComponentIdx;
              cb.componentName = cbData?.powerMeterData?.name;
              cb.componentType = "Power Meter";
              cb.connections = [];
              cb.communications = [];
              cb.uiAttributes = JSON.stringify(cbData.uiData);
              let connections = {
                componentIdx: "ASSET_" + padNumberFormat(assetI),
                endpointIdX: "1",
                connectionType: "electrical_connection",
              };
              let attributes = {
                name: cbData?.powerMeterData?.name || `PM_${cbData.assetType}`,
                description: cbData?.powerMeterData?.description || "",
                powerMeterModbusId:
                  cbData?.powerMeterData?.powerMeterModbusId || "",
              };
              cb.attributes = JSON.stringify(attributes);

              //*cb values

              let communications = {};

              communications = {
                ip: cbData?.powerMeterData?.ipAddress??defaultPreFillData?.[0]?.["Power Meter"]?.[`ipAddress_PM_${cbData.assetType}`],
                subnetmask: cbData?.powerMeterData?.mask??defaultPreFillData?.[0]?.["Power Meter"]?.subnetMask,
                defaultGateway: cbData?.powerMeterData?.gatewayAddress??defaultPreFillData?.[0]?.["Power Meter"]?.defaultGateway,
                modbusSlaveId: cbData?.powerMeterData?.powerMeterModbusId??defaultPreFillData?.[0]?.["Power Meter"]?.[`modbusId_PM_${cbData.assetType}`],
                type: "Power Meter",
              };
              cb.connections.push(connections);
              cb.communications.push(communications);
              arrayComponent.push(cb);
              assetCB = assetCB + 10;

              //*cb values utility
            }
            // if((siteType === embLabels.GRID_CONNECTED &&
            //   cbData.assetType === embLabels.UTILITY&&projectTypeValue==="MGaaS IEC")){
            //     let cb = {};
            //   cb.assetId =
            //     cbData?.cbConfigData?.cbAssetDetails[0]?.assetId;
            //   cb.userId = 1;
            //   cb.componentIdx = CBComponentIdx;
            //   cb.componentName = "CB Utility";
            //   cb.componentType = cbData?.cbConfigData?.cbAssetDetails?.[0]?.assetType;
            //   let attributes = {
            //     name: cbData?.cbConfigData?.name,
            //     description: cbData?.cbConfigData?.description,
            //     motorized: cbData?.cbConfigData?.motorized? 1:0,
            //     loadCategory: cbData?.cbConfigData?.loadCategory,
            //     loadPriority: cbData?.cbConfigData?.loadPriority,
            //   };
            //   cb.attributes = JSON.stringify(attributes);
            //   cb.connections = [];
            //   cb.communications = [];
            //   cb.uiAttributes = JSON.stringify(cbData.uiData);
            //   let connections = {
            //     componentIdx: "ASSET_" + padNumberFormat(assetI),
            //     endpointIdX: "1",
            //     connectionType: "electrical_connection",
            //   };
            //   let communications = {}
            //   communications = {
            //     ip: cbData?.cbConfigData?.cbCommunicationData?.ipaddress,
            //     subnetmask: cbData?.cbConfigData?.cbCommunicationData?.subnetmask,
            //     defaultGateway: cbData?.cbConfigData?.cbCommunicationData?.defaultGateway,
            //     modbusSlaveId: cbData?.cbConfigData?.cbCommunicationData?.modbusSlaveId || "",
            //     type: cbData?.cbConfigData?.cbAssetDetails[0]?.assetType,
            //   };
            //   cb.connections.push(connections);
            //   cb.communications.push(communications);
            //   arrayComponent.push(cb);
            //   assetCB = assetCB + 10;
            // }
            if (
              (clientType === embLabels.A2E_PROJECT &&
                siteType === embLabels.GENSET_PVBESS_A2E &&
                cbData.assetType === embLabels.GENSET) ||
              (projectKind.isMgaasAnsiIsland &&
                cbData.assetType === embLabels.GENSET&&
              cbData.uiData.pmRequired)||
              (cbData.assetType === embLabels.GENSET&&isMgaasIecIsland&&cbData.uiData.pmRequired)
            ) {
              let cbNew = {};
              cbNew.assetId = cbData?.powerMeterData?.pmAsset?.assetId;
              cbNew.userId = 1;
              cbNew.componentIdx = PMComponentIdx;
              cbNew.componentName = cbData?.powerMeterData?.name!==""?cbData?.powerMeterData?.name:defaultPreFillData?.[0]?.["Power Meter"]?.nameGenset;
              cbNew.componentType = "Power Meter";
              cbNew.communications = [];
              cbNew.connections = [];
              cbNew.uiAttributes = JSON.stringify(cbData.uiData);
              let attributesnew = {
                name: cbData?.powerMeterData?.name,
                description: cbData?.powerMeterData?.description,
                powerMeterModbusId: cbData?.powerMeterData?.powerMeterModbusId,
              };

              cbNew.attributes = JSON.stringify(attributesnew);
              let newConnection = {
                componentIdx: "ASSET_" + padNumberFormat(assetI),
                endpointIdX: "1",
                connectionType: "electrical_connection",
              };

              //* cb values

              let communicationsnew = {
                ip: cbData?.powerMeterData?.ipAddress,
                subnetmask: cbData?.powerMeterData?.mask,
                defaultGateway: cbData?.powerMeterData?.gatewayAddress,
                modbusSlaveId: cbData?.powerMeterData?.powerMeterModbusId,
                type: "Power Meter",
              };

              cbNew.communications.push(communicationsnew);
              cbNew.connections.push(newConnection);
              arrayComponent.push(cbNew);
            }

            assetI = assetI + 1;

            break;
          case embLabels.INVERTER:
            let connectionList = [];

            if (cbData.pv === 1) {
              CBComponentIdx = "CB_40";
              CRComponentIdx = "Contactor_40";
              PMComponentIdx = "PM_40";
              PVDetails.pvset1Data.map((data, key) => {
                let asset = {};
                if (cbData.assetType === embLabels.INVERTER) {
                  asset.componentType = "Inverter";
                }
                asset.userId = 1;
                asset.componentIdx = `ASSET_${padNumberFormat(assetI)}`;
                asset.componentName = PVDetails?.pvset1Data[key]?.name;
                asset.externalId = PVDetails?.pvset1Data[key]?.pvGsxExternalId
                  ? PVDetails?.pvset1Data[key]?.pvGsxExternalId
                  : gsxExternalId?.utilityExternalId;
                if (PVDetails.pvset1Data[key].assetDetails?.[0] !== undefined) {
                  asset.assetId =
                    PVDetails?.pvset1Data[key]?.assetDetails?.[0]?.assetId;
                } else {
                  asset.assetId = 4;
                }

                asset.connections = [];
                asset.communications = [];
                cbData.uiData.pv = 1;
                cbData.uiData.groupName = PVDetails?.pvset1Data[key]?.groupName;
                cbData.uiData.configured = PVDetails?.pvset1Data[key]?.status;
                asset.uiAttributes = JSON.stringify(cbData.uiData);
                componentJson.deploymentReady =
                  componentJson?.deploymentReady && cbData?.uiData?.configured
                    ? true
                    : false;
                let assetConnections = {
                  componentIdx: CBComponentIdx,
                  endpointIdX: "1",
                  connectionType: "electrical_connection",
                };
                let assetAttributes = {
                  name: PVDetails?.pvset1Data[key]?.name,
                  description: PVDetails?.pvset1Data[key]?.description,
                  max_power: PVDetails?.pvset1Data[key]?.max_power,
                  groupName: PVDetails?.pvset1Data[key]?.groupName,
                  group_max_power: PVDetails?.pvset1Data[key]?.group_max_power,
                };
                asset.attributes = JSON.stringify(assetAttributes);

                if (
                  [
                    embLabels.ISLANDABLE,
                    embLabels.PV_HYBRIDIZATION,
                    embLabels.GRID_CONNECTED_GSX,
                    embLabels.GRID_CONNECTED,
                  ].includes(siteType) ||
                  architectureType === embLabels.PV_GENSET_ATS
                ) {
                  asset.connections.push(assetConnections);
                }

                //* no communication for hardwired

                let assetCommunications;

                if (
                  PVDetails.pvset1Data[key].assetDetails.length &&
                  PVDetails.pvset1Data[key].assetDetails?.[0].assetInfo ===
                    "Hardwired"
                ) {
                  assetCommunications = {};
                } else {
                  assetCommunications = {
                    ip: PVDetails.pvset1Data[key].ipaddress,
                    subnetmask: PVDetails.pvset1Data[key].subnetmask,
                    defaultGateway: PVDetails.pvset1Data[key].defaultGateway,
                    modbusSlaveId: PVDetails.pvset1Data[key].modbusSlaveId,
                    type: "INVERTER",
                  };
                }
                asset.communications.push(assetCommunications);
                arrayComponent.push(asset);
                let connectionNew = {
                  componentIdx: `ASSET_${padNumberFormat(assetI)}`,
                  endpointIdX: "1",
                  connectionType: "electrical_connection",
                };
                connectionList.push(connectionNew);
                assetI = assetI + 1;
                return null;
              });

              if (
                siteType === embLabels.ISLANDABLE ||
                (siteType === embLabels.GRID_CONNECTED 
                   && cbData?.uiData?.cbRequired
                  )  ||
                (siteType === embLabels.GRID_CONNECTED_GSX &&
                  architectureType === embLabels.PV_BESS_NR 
                  // &&cbData.uiData.cbRequired &&cbData?.uiData?.cbRequired
                  ) ||
                (siteType === embLabels.ISLANDABLE_GSX &&
                  architectureType === embLabels.PV_GENSET_ATS 
                  // &&cbData?.uiData?.cbRequired
                  ) ||
                siteType === embLabels.PV_HYBRIDIZATION
              ) {
                let cbNew = {};

                cbNew.assetId =
                  cbData?.cbConfigData?.cbAssetDetails?.[0]?.assetId ?? 7;
                cbNew.userId = 1;
                cbNew.componentIdx = CBComponentIdx;
                cbNew.componentName = cbData?.cbConfigData?.name;
                cbNew.componentType = "Circuit Breaker";
                cbNew.connections = connectionList;
                cbNew.communications = [];
                cbNew.uiAttributes = JSON.stringify(cbData.uiData);

                let attributesnew = {
                  name: cbData?.cbConfigData?.name,
                  description: cbData?.cbConfigData?.description,
                  motorized: cbData.cbConfigData.motorized ? 1 : 0,
                  contactor: cbData.cbConfigData.contactor ? 1 : 0,
                  loadCategory: cbData?.cbConfigData?.loadCategory,
                  loadPriority: cbData?.cbConfigData?.loadPriority,
                  Contactor_healthy_status_available: 0,
                };
                if (
                  siteType === embLabels.GRID_CONNECTED &&
                  !cbData.uiData.cbRequired
                ) {
                  attributesnew.hardwired = cbData?.cbConfigData?.hardwired;
                }
                

                if (
                  // siteType === embLabels.GRID_CONNECTED &&
                  // cbData?.cbConfigData?.motorized &&
                  cbData?.cbConfigData?.cbAssetDetails?.[0]?.assetInfo ===
                    "Hardwired"
                ) {
                  attributesnew.type = "12";
                }
                if (
                  // siteType === embLabels.GRID_CONNECTED &&
                  cbData?.uiData?.contactor
                ) {
                  attributesnew.type = "20";
                  attributesnew.Contactor_healthy_status_available=cbData?.contactorConfigData?.cbAssetDetails?.[0]?.assetAttributes?.[0]?.value
                }
                if(!cbData?.cbConfigData?.motorized&&!cbData?.uiData?.contactor){
                  attributesnew.type = "40"
                }
                if (!(cbData.assetType === embLabels.GENSET || cbData.assetType === embLabels.UTILITY) && !cbData?.uiData?.cbRequired) {
                  attributesnew.type = "40";
                }
                if(isMgaasIecIsland){
                if(cbData?.cbConfigData?.cbAssetDetails?.[0]?.assetInfo === "Hardwired"){
                  attributesnew.type = "12";
                }
                if (cbData.uiData?.contactor) {
                  attributesnew.type = "20";
                  attributesnew.Contactor_healthy_status_available = cbData?.contactorConfigData?.cbAssetDetails?.[0]?.assetAttributes?.[0]?.value
                }
                if(!cbData.uiData?.contactor&&!cbData?.cbConfigData?.motorized){
                  attributesnew.type = "40"
                }
                if (!(cbData.assetType === embLabels.GENSET || cbData.assetType === embLabels.UTILITY) && !cbData?.uiData?.cbRequired) {
                  attributesnew.type = "40";
                }
                }
                cbNew.attributes = JSON.stringify(attributesnew);
                // const communicationsnew = generateIfeValues("CB_40", cbData);
                let communicationsnew = {
                  ip: cbData?.cbConfigData?.cbCommunicationData?.ipaddress,
                  subnetmask:
                    cbData?.cbConfigData?.cbCommunicationData?.subnetmask,
                  defaultGateway:
                    cbData?.cbConfigData?.cbCommunicationData?.defaultGateway,
                  modbusSlaveId:
                    cbData?.cbConfigData?.cbCommunicationData?.modbusSlaveId,
                  type: "Circuit Breaker",
                };

                cbNew.communications.push(communicationsnew);
                arrayComponent.push(cbNew);
              }

              if (
                [embLabels.ISLANDABLE,embLabels.GRID_CONNECTED].includes(siteType)  &&
                cbData?.uiData?.contactor&&!cbData.cbConfigData.motorized
              ) {
                let cbNew = {};

                cbNew.assetId =
                  cbData.contactorConfigData.cbAssetDetails?.[0]?.assetId ?? 7;
                cbNew.userId = 1;
                cbNew.componentIdx = CRComponentIdx;
                cbNew.componentName = cbData?.contactorConfigData?.name;
                cbNew.componentType = "Contactor";
                cbNew.connections = [];
                cbNew.communications = [];
                cbNew.uiAttributes = JSON.stringify(cbData.uiData);

                let connections = {
                  componentIdx: CBComponentIdx,
                  endpointIdX: "1",
                  connectionType: "electrical_connection",
                };
                let attributes = {
                  name: cbData?.contactorConfigData?.name,
                  description: cbData?.contactorConfigData?.description,
                  loadCategory: cbData?.contactorConfigData?.loadCategory,
                  loadPriority: cbData?.contactorConfigData?.loadPriority,
                };

                cbNew.attributes = JSON.stringify(attributes);

                cbNew.connections.push(connections);
                cbNew.communications.push({});

                arrayComponent.push(cbNew);
              }

              if (
                (siteType === embLabels.ISLANDABLE_GSX &&
                  architectureType === embLabels.PV_GENSET_ATS) ||
                (siteType === embLabels.GRID_CONNECTED_GSX &&
                  architectureType === embLabels.PV_BESS_NR &&
                  cbData.uiData.pmRequired) ||
                (siteType === embLabels.GRID_CONNECTED &&
                  cbData.uiData.pmRequired) ||
                (siteType === embLabels.GRID_CONNECTED &&
                  projectTypeValue === "MGaaS ANSI" && cbData.uiData.pmRequired) ||
                (siteType === embLabels.ISLANDABLE &&
                  projectTypeValue === "MGaaS ANSI" &&  cbData.uiData.pmRequired) ||
                  (projectKind.isMgaasIecIsland&&cbData.uiData.pmRequired)
              ) {
                let cbNew = {};
                cbNew.assetId =
                  cbData?.powerMeterData?.pmAsset?.assetId ??
                  powerMeter?.assetId;
                cbNew.userId = 1;
                cbNew.componentIdx = PMComponentIdx;
                cbNew.componentName = cbData?.powerMeterData?.name??defaultPreFillData?.[0]?.["Power Meter"]?.namePV;
                cbNew.componentType = "Power Meter";
                cbNew.connections = connectionList;
                cbNew.communications = [];
                // cbNew.connections = [];
                cbNew.uiAttributes = JSON.stringify(cbData.uiData);
                let attributesnew = {
                  name: cbData?.powerMeterData?.name,
                  description: cbData?.powerMeterData?.description,
                  powerMeterModbusId:
                    cbData?.powerMeterData?.powerMeterModbusId,
                };

                cbNew.attributes = JSON.stringify(attributesnew);

                //* cb values

                let communicationsnew = {
                  ip: cbData?.powerMeterData?.ipAddress,
                  subnetmask: cbData?.powerMeterData?.mask,
                  defaultGateway: cbData?.powerMeterData?.gatewayAddress,
                  modbusSlaveId: cbData?.powerMeterData?.powerMeterModbusId,
                  type: "Power Meter",
                };
                let connections = {
                  componentIdx: "ASSET_" + padNumberFormat(assetI),
                  endpointIdX: "1",
                  connectionType: "electrical_connection",
                };
                // cbNew.connections.push(connections)
                cbNew.communications.push(communicationsnew);
                arrayComponent.push(cbNew);
              }

              if (
                (siteType === embLabels.GRID_CONNECTED_GSX &&
                  architectureType === embLabels.PV_BESS_NR &&
                  cbData?.uiData?.existingPmRequired) ||
                (siteType === embLabels.GRID_CONNECTED &&
                  projectTypeValue === "MGaaS ANSI" &&
                  cbData?.uiData?.existingPmRequired)||
                  (projectKind.isMgaasIecIsland&&cbData.uiData.existingPmRequired)
              ) {
                let cbNew = {};
                cbNew.assetId =
                  cbData?.existingPowerMeterData?.pmAsset?.assetId ??
                  powerMeter?.assetId;
                cbNew.userId = 1;
                cbNew.componentIdx = "PM_51";
                cbNew.componentName = cbData?.existingPowerMeterData?.name;
                cbNew.componentType = "Power Meter";
                cbNew.connections = connectionList;
                cbNew.communications = [];
                cbNew.uiAttributes = JSON.stringify(cbData.uiData);
                let attributesnew = {
                  name: cbData?.existingPowerMeterData?.name,
                  description: cbData?.existingPowerMeterData?.description,
                  powerMeterModbusId:
                    cbData?.existingPowerMeterData?.powerMeterModbusId,
                };

                cbNew.attributes = JSON.stringify(attributesnew);

                //* cb values

                let communicationsnew = {
                  ip: cbData?.existingPowerMeterData?.ipAddress,
                  subnetmask: cbData?.existingPowerMeterData?.mask,
                  defaultGateway:
                    cbData?.existingPowerMeterData?.gatewayAddress,
                  modbusSlaveId:
                    cbData?.existingPowerMeterData?.powerMeterModbusId,
                  type: "Power Meter",
                };
                cbNew.communications.push(communicationsnew);
                arrayComponent.push(cbNew);
              }

              if (
                siteType === embLabels.ISLANDABLE_GSX &&
                architectureType === embLabels.PV_GENSET_ATS &&
                cbData?.uiData?.existingPmRequired
              ) {
                let cbNew = {};
                cbNew.assetId =
                  cbData?.existingPowerMeterData?.pmAsset?.assetId ??
                  powerMeter?.assetId;
                cbNew.userId = 1;
                cbNew.componentIdx = "PM_51";
                cbNew.componentName = cbData?.existingPowerMeterData?.name;
                cbNew.componentType = "Power Meter";
                cbNew.connections = connectionList;
                cbNew.communications = [];
                cbNew.uiAttributes = JSON.stringify(cbData.uiData);
                let attributesnew = {
                  name: cbData?.existingPowerMeterData?.name,
                  description: cbData?.existingPowerMeterData?.description,
                  powerMeterModbusId:
                    cbData?.existingPowerMeterData?.powerMeterModbusId,
                };

                cbNew.attributes = JSON.stringify(attributesnew);

                //* cb values

                let communicationsnew = {
                  ip: cbData?.existingPowerMeterData?.ipAddress,
                  subnetmask: cbData?.existingPowerMeterData?.mask,
                  defaultGateway:
                    cbData?.existingPowerMeterData?.gatewayAddress,
                  modbusSlaveId:
                    cbData?.existingPowerMeterData?.powerMeterModbusId,
                  type: "Power Meter",
                };
                cbNew.communications.push(communicationsnew);
                arrayComponent.push(cbNew);
              }
            } else if (cbData.pv === 2) {
              CBComponentIdx = "CB_41";
              CRComponentIdx = "Contactor_41";
              PMComponentIdx = "PM_41";
              PVDetails.pvset2Data.map((data, key) => {
                let asset = {};
                if (cbData.assetType === embLabels.INVERTER) {
                  asset.componentType = "Inverter";
                }
                asset.userId = 1;
                asset.componentIdx = "ASSET_" + padNumberFormat(assetI);
                asset.componentName = PVDetails?.pvset2Data[key]?.name;
                asset.externalId = PVDetails?.pvset2Data[key]?.pvGsxExternalId
                  ? PVDetails?.pvset2Data[key]?.pvGsxExternalId
                  : gsxExternalId?.utilityExternalId;
                if (PVDetails.pvset2Data[key].assetDetails?.[0] !== undefined) {
                  asset.assetId =
                    PVDetails?.pvset2Data[key]?.assetDetails?.[0]?.assetId;
                } else {
                  asset.assetId = 4;
                }
                asset.connections = [];
                asset.communications = [];
                cbData.uiData.pv = 2;
                cbData.uiData.groupName = PVDetails?.pvset2Data[key]?.groupName;
                cbData.uiData.configured = PVDetails?.pvset2Data[key]?.status;
                asset.uiAttributes = JSON.stringify(cbData.uiData);
                componentJson.deploymentReady =
                  componentJson.deploymentReady && cbData?.uiData?.configured
                    ? true
                    : false;
                let assetConnections = {
                  componentIdx: CBComponentIdx,
                  endpointIdX: "1",
                  connectionType: "electrical_connection",
                };
                let assetAttributes = {
                  name: PVDetails?.pvset2Data[key]?.name,
                  description: PVDetails?.pvset2Data[key]?.description,
                  max_power: PVDetails?.pvset2Data[key]?.max_power,
                  groupName: PVDetails?.pvset2Data[key]?.groupName,
                  group_max_power: PVDetails?.pvset2Data[key]?.group_max_power,
                };
                asset.attributes = JSON.stringify(assetAttributes);
                if (
                  siteType === embLabels.ISLANDABLE ||
                  siteType === embLabels.GRID_CONNECTED_GSX ||
                  siteType === embLabels.GRID_CONNECTED ||
                  (siteType === embLabels.ISLANDABLE_GSX &&
                    architectureType === embLabels.PV_GENSET_ATS &&
                    defaultPreFillData[1].mode === "FUEL_SAVER")
                ) {
                  asset.connections.push(assetConnections);
                }

                //* no communication for hardwired

                let assetCommunications;

                if (
                  PVDetails.pvset2Data[key].assetDetails.length &&
                  PVDetails.pvset2Data[key].assetDetails?.[0].assetInfo ===
                    "Hardwired"
                ) {
                  assetCommunications = {};
                } else {
                  assetCommunications = {
                    ip: PVDetails?.pvset2Data[key]?.ipaddress,
                    subnetmask: PVDetails?.pvset2Data[key]?.subnetmask,
                    defaultGateway: PVDetails?.pvset2Data[key]?.defaultGateway,
                    modbusSlaveId: PVDetails?.pvset2Data[key]?.modbusSlaveId,
                    type: "INVERTER",
                  };
                }

                asset.communications.push(assetCommunications);
                arrayComponent.push(asset);
                let connectionNew = {
                  componentIdx: "ASSET_" + padNumberFormat(assetI),
                  endpointIdX: "1",
                  connectionType: "electrical_connection",
                };
                connectionList.push(connectionNew);
                assetI = assetI + 1;
                return null;
              });
              //conncetion made for daas ansi

              if (
                siteType === embLabels.ISLANDABLE ||
                (siteType === embLabels.GRID_CONNECTED &&
                  projectTypeValue !== "MGaaS ANSI") ||
                (siteType === embLabels.GRID_CONNECTED_GSX 
                  // &&cbData.uiData.cbRequired
                  ) ||
                (siteType === embLabels.ISLANDABLE_GSX &&
                  architectureType === embLabels.PV_GENSET_ATS &&
                  defaultPreFillData[1]?.mode === "FUEL_SAVER") ||
                (siteType === embLabels.GRID_CONNECTED &&
                  projectTypeValue === "MGaaS ANSI")
              ) {
                let cbNew = {};
                cbNew.assetId =
                  cbData?.cbConfigData?.cbAssetDetails[0]?.assetId ?? 7;
                cbNew.userId = 1;
                cbNew.componentIdx = CBComponentIdx;
                cbNew.componentName = cbData?.cbConfigData?.name;
                cbNew.componentType = "Circuit Breaker";
                cbNew.connections = connectionList;
                cbNew.communications = [];
                cbNew.uiAttributes = JSON.stringify(cbData.uiData);
                let attributesnew = {
                  name: cbData?.cbConfigData?.name,
                  description: cbData?.cbConfigData?.description,
                  motorized: cbData.cbConfigData.motorized ? 1 : 0,
                  contactor: cbData.cbConfigData.contactor ? 1 : 0,
                  loadCategory: cbData?.cbConfigData?.loadCategory,
                  loadPriority: cbData?.cbConfigData?.loadPriority,
                  Contactor_healthy_status_available: 0,
                };
                if (
                  siteType === embLabels.GRID_CONNECTED &&
                  !cbData.uiData.cbRequired
                ) {
                  attributesnew.hardwired = cbData?.cbConfigData?.hardwired;
                }
                
                if (
                  // siteType === embLabels.GRID_CONNECTED &&
                  // cbData?.cbConfigData?.motorized &&
                  cbData?.cbConfigData?.cbAssetDetails[0]?.assetInfo ===
                    "Hardwired"
                ) {
                  attributesnew.type = "12";
                }
                if (
                  // siteType === embLabels.GRID_CONNECTED &&
                  cbData?.uiData?.contactor
                ) {
                  attributesnew.type = "20";
                  attributesnew.Contactor_healthy_status_available=cbData?.contactorConfigData?.cbAssetDetails?.[0]?.assetAttributes?.[0]?.value
                }
                if(!cbData?.cbConfigData?.motorized&&!cbData?.uiData?.contactor){
                  attributesnew.type = "40"
                }
                if (!(cbData.assetType === embLabels.GENSET || cbData.assetType === embLabels.UTILITY) && !cbData?.uiData?.cbRequired) {
                  attributesnew.type = "40";
                }
                if(isMgaasIecIsland){
                  if(cbData?.cbConfigData?.cbAssetDetails[0]?.assetInfo === "Hardwired"){
                    attributesnew.type = "12";
                  }
                  if (cbData.uiData?.contactor) {
                    attributesnew.type = "20";
                    attributesnew.Contactor_healthy_status_available = cbData?.contactorConfigData?.cbAssetDetails?.[0]?.assetAttributes?.[0]?.value
                  }
                  if(!cbData.uiData?.contactor&&!cbData?.cbConfigData?.motorized){
                    attributesnew.type = "40"
                  }
                  if (!(cbData.assetType === embLabels.GENSET || cbData.assetType === embLabels.UTILITY) && !cbData?.uiData?.cbRequired) {
                    attributesnew.type = "40";
                  }
                  }
                cbNew.attributes = JSON.stringify(attributesnew);

                let communicationsnew = {
                  ip: cbData?.cbConfigData?.cbCommunicationData?.ipaddress,
                  subnetmask:
                    cbData?.cbConfigData?.cbCommunicationData?.subnetmask,
                  defaultGateway:
                    cbData?.cbConfigData?.cbCommunicationData?.defaultGateway,
                  modbusSlaveId:
                    cbData?.cbConfigData?.cbCommunicationData?.modbusSlaveId,
                  type: "Circuit Breaker",
                };

                cbNew.communications.push(communicationsnew);
                arrayComponent.push(cbNew);
              }

              if (
                [embLabels.ISLANDABLE,embLabels.GRID_CONNECTED].includes(siteType)  &&
                cbData?.uiData?.contactor&&!cbData.cbConfigData.motorized
              ) {
                let cbNew = {};
                cbNew.assetId =
                  cbData.contactorConfigData.cbAssetDetails[0]?.assetId ?? 7;
                cbNew.userId = 1;
                cbNew.componentIdx = CRComponentIdx;
                cbNew.componentName = cbData?.contactorConfigData?.name;
                cbNew.componentType = "Contactor";
                cbNew.connections = [];
                cbNew.communications = [];
                cbNew.uiAttributes = JSON.stringify(cbData.uiData);
                let connections = {
                  componentIdx: CBComponentIdx,
                  endpointIdX: "1",
                  connectionType: "electrical_connection",
                };

                let attributes = {
                  name: cbData?.contactorConfigData?.name,
                  description: cbData?.contactorConfigData?.description,
                  loadCategory: cbData?.contactorConfigData?.loadCategory,
                  loadPriority: cbData?.contactorConfigData?.loadPriority,
                };

                cbNew.attributes = JSON.stringify(attributes);

                //cbNew values
                let communications = {};

                cbNew.connections.push(connections);
                cbNew.communications.push(communications);
                arrayComponent.push(cbNew);
              }

              if (
                (siteType === embLabels.GRID_CONNECTED_GSX &&
                  architectureType === embLabels.PV_BESS_NR &&
                  cbData.uiData.pmRequired) ||
                (siteType === embLabels.ISLANDABLE_GSX &&
                  architectureType === embLabels.PV_GENSET_ATS &&
                  defaultPreFillData[1]?.mode === "FUEL_SAVER") ||
                (siteType === embLabels.GRID_CONNECTED &&
                  cbData.uiData.pmRequired) ||
                (siteType === embLabels.GRID_CONNECTED &&
                  projectTypeValue === "MGaaS ANSI" &&  cbData.uiData.pmRequired) ||
                (siteType === embLabels.ISLANDABLE &&
                  projectTypeValue === "MGaaS ANSI" &&  cbData.uiData.pmRequired) ||
                  (projectKind.isMgaasIecIsland&&cbData.uiData.pmRequired)
              ) {
                let cbNew = {};
                cbNew.assetId =
                  cbData?.powerMeterData?.pmAsset?.assetId ??
                  powerMeter?.assetId;
                cbNew.userId = 1;
                cbNew.componentIdx = PMComponentIdx;
                cbNew.componentName = cbData?.powerMeterData?.name??defaultPreFillData?.[0]?.["Power Meter"]?.namePV;
                cbNew.componentType = "Power Meter";
                cbNew.connections = connectionList;
                cbNew.communications = [];
                cbNew.uiAttributes = JSON.stringify(cbData.uiData);
                let attributesnew = {
                  name: cbData?.powerMeterData?.name,
                  description: cbData?.powerMeterData?.description,
                  powerMeterModbusId:
                    cbData?.powerMeterData?.powerMeterModbusId,
                };

                cbNew.attributes = JSON.stringify(attributesnew);

                //* cb values

                let communicationsnew = {
                  ip: cbData.powerMeterData.ipAddress,
                  subnetmask: cbData.powerMeterData.mask,
                  defaultGateway: cbData.powerMeterData.gatewayAddress,
                  modbusSlaveId: cbData.powerMeterData.powerMeterModbusId,
                  type: "Power Meter",
                };
                cbNew.communications.push(communicationsnew);
                arrayComponent.push(cbNew);
              }
            } else if (cbData.pv === 3) {
              CBComponentIdx = "CB_42";
              CRComponentIdx = "Contactor_42";
              PMComponentIdx = "PM_42";
              PVDetails.pvset3Data.map((data, key) => {
                let asset = {};
                if (cbData.assetType === embLabels.INVERTER) {
                  asset.componentType = "Inverter";
                }
                asset.userId = 1;
                asset.componentIdx = "ASSET_" + padNumberFormat(assetI);
                asset.componentName = PVDetails.pvset3Data[key].name;
                asset.externalId = PVDetails?.pvset3Data[key]?.pvGsxExternalId
                  ? PVDetails?.pvset3Data[key]?.pvGsxExternalId
                  : gsxExternalId?.utilityExternalId;
                if (PVDetails.pvset3Data[key].assetDetails[0] !== undefined) {
                  asset.assetId =
                    PVDetails?.pvset3Data[key]?.assetDetails[0]?.assetId;
                } else {
                  asset.assetId = 4;
                }
                asset.connections = [];
                asset.communications = [];
                cbData.uiData.pv = 3;
                cbData.uiData.groupName = PVDetails?.pvset3Data[key]?.groupName;
                cbData.uiData.configured = PVDetails?.pvset3Data[key]?.status;
                asset.uiAttributes = JSON.stringify(cbData.uiData);
                componentJson.deploymentReady =
                  componentJson.deploymentReady && cbData.uiData.configured
                    ? true
                    : false;
                let assetConnections = {
                  componentIdx: CBComponentIdx,
                  endpointIdX: "1",
                  connectionType: "electrical_connection",
                };
                let assetAttributes = {
                  name: PVDetails.pvset3Data[key].name,
                  description: PVDetails.pvset3Data[key].description,
                  max_power: PVDetails.pvset3Data[key].max_power,
                  groupName: PVDetails.pvset3Data[key].groupName,
                  group_max_power: PVDetails.pvset3Data[key].group_max_power,
                };
                asset.attributes = JSON.stringify(assetAttributes);

                // if (
                //   siteType === embLabels.ISLANDABLE ||
                //   siteType === embLabels.GRID_CONNECTED_GSX
                // ) {
                //   asset.connections.push(assetConnections);
                // }

                if (
                  siteType === embLabels.ISLANDABLE ||
                  siteType === embLabels.GRID_CONNECTED_GSX ||
                  siteType === embLabels.GRID_CONNECTED ||
                  (siteType === embLabels.ISLANDABLE_GSX &&
                    architectureType === embLabels.PV_GENSET_ATS &&
                    defaultPreFillData[1].mode === "FUEL_SAVER")
                ) {
                  asset.connections.push(assetConnections);
                }
                //* no communication for hardwired

                let assetCommunications;

                if (
                  PVDetails.pvset3Data[key].assetDetails.length &&
                  PVDetails.pvset3Data[key].assetDetails[0].assetInfo ===
                    "Hardwired"
                ) {
                  assetCommunications = {};
                } else {
                  assetCommunications = {
                    ip: PVDetails.pvset3Data[key].ipaddress,
                    subnetmask: PVDetails.pvset3Data[key].subnetmask,
                    defaultGateway: PVDetails.pvset3Data[key].defaultGateway,
                    modbusSlaveId: PVDetails.pvset3Data[key].modbusSlaveId,
                    type: "INVERTER",
                  };
                }
                asset.communications.push(assetCommunications);
                arrayComponent.push(asset);
                let connectionNew = {
                  componentIdx: "ASSET_" + padNumberFormat(assetI),
                  endpointIdX: "1",
                  connectionType: "electrical_connection",
                };
                connectionList.push(connectionNew);
                assetI = assetI + 1;
                return null;
              });

              // if (
              //   siteType === embLabels.ISLANDABLE ||
              //   (siteType === embLabels.GRID_CONNECTED &&
              //     cbData.uiData.cbRequired)
              // ) {
              //   let cbNew = {};
              //   cbNew.assetId =
              //     cbData.cbConfigData.cbAssetDetails[0]?.assetId ?? 7;
              //   cbNew.userId = 1;
              //   cbNew.componentIdx = CBComponentIdx;
              //   cbNew.componentName = cbData.cbConfigData.name;
              //   cbNew.componentType = "Circuit Breaker";
              //   cbNew.connections = connectionList;
              //   cbNew.communications = [];
              //   cbNew.uiAttributes = JSON.stringify(cbData.uiData);

              //   let attributesnew = {
              //     name: cbData.cbConfigData.name,
              //     description: cbData.cbConfigData.description,
              //     motorized: cbData.cbConfigData.motorized ? 1 : 0,
              //     loadCategory: cbData.cbConfigData.loadCategory,
              //     loadPriority: cbData.cbConfigData.loadPriority,
              //   };
              //   if (
              //     siteType === embLabels.GRID_CONNECTED &&
              //     cbData.uiData.cbRequired
              //   ) {
              //     attributesnew.hardwired = cbData?.cbConfigData?.hardwired;
              //   }
              //   if (
              //     siteType === embLabels.GRID_CONNECTED &&
              //     !cbData?.cbConfigData?.motorized
              //   ) {
              //     attributesnew.type = "20";
              //   }
              //   if (
              //     siteType === embLabels.GRID_CONNECTED &&
              //     cbData?.cbConfigData?.motorized &&
              //     cbData?.cbConfigData?.cbAssetDetails[0]?.assetInfo ===
              //       "Hardwired"
              //   ) {
              //     attributesnew.type = "12";
              //   }
              //   if (
              //     siteType === embLabels.GRID_CONNECTED &&
              //     !cbData?.uiData?.cbRequired
              //   ) {
              //     attributesnew.type = "40";
              //   }
              //   cbNew.attributes = JSON.stringify(attributesnew);

              //   let communicationsnew = {
              //     ip: cbData.cbConfigData.cbCommunicationData.ipaddress,
              //     subnetmask:
              //       cbData.cbConfigData.cbCommunicationData.subnetmask,
              //     defaultGateway:
              //       cbData.cbConfigData.cbCommunicationData.defaultGateway,
              //     modbusSlaveId:
              //       cbData.cbConfigData.cbCommunicationData.modbusSlaveId,
              //     type: "Circuit Breaker",
              //   };
              //   cbNew.communications.push(communicationsnew);
              //   arrayComponent.push(cbNew);
              // }

              // if (
              //   siteType === embLabels.GRID_CONNECTED &&
              //   cbData.uiData.pmRequired
              // ) {
              //   let cbNew = {};
              //   cbNew.assetId =
              //     cbData?.powerMeterData?.pmAsset?.assetId ??
              //     powerMeter?.assetId;
              //   cbNew.userId = 1;
              //   cbNew.componentIdx = PMComponentIdx;
              //   cbNew.componentName = cbData?.powerMeterData?.name;
              //   cbNew.componentType = "Power Meter";
              //   cbNew.connections = connectionList;
              //   cbNew.communications = [];
              //   cbNew.uiAttributes = JSON.stringify(cbData.uiData);
              //   let attributesnew = {
              //     name: cbData.powerMeterData.name,
              //     description: cbData.powerMeterData.description,
              //     powerMeterModbusId: cbData.powerMeterData.powerMeterModbusId,
              //   };

              //   cbNew.attributes = JSON.stringify(attributesnew);

              //   //* cb values

              //   let communicationsnew = {
              //     ip: cbData.powerMeterData.ipAddress,
              //     subnetmask: cbData.powerMeterData.mask,
              //     defaultGateway: cbData.powerMeterData.gatewayAddress,
              //     modbusSlaveId: cbData.powerMeterData.powerMeterModbusId,
              //     type: "Power Meter",
              //   };
              //   cbNew.communications.push(communicationsnew);
              //   arrayComponent.push(cbNew);
              // }

              // if (
              //   (siteType === embLabels.GRID_CONNECTED_GSX &&
              //     architectureType === embLabels.PV_BESS_NR &&
              //     cbData.uiData.pmRequired) ||
              //   (siteType === embLabels.ISLANDABLE_GSX &&
              //     architectureType === embLabels.PV_GENSET_ATS &&
              //     defaultPreFillData[1]?.mode === "FUEL_SAVER") ||
              //   (siteType === embLabels.GRID_CONNECTED &&
              //     cbData.uiData.pmRequired) ||
              //   (siteType === embLabels.GRID_CONNECTED &&
              //     projectTypeValue === "MGaaS ANSI") ||
              //   (siteType === embLabels.ISLANDABLE &&
              //     projectTypeValue === "MGaaS ANSI")
              // ) {
              //   let cbNew = {};
              //   cbNew.assetId =
              //     cbData.contactorConfigData.cbAssetDetails[0]?.assetId ?? 7;
              //   cbNew.userId = 1;
              //   cbNew.componentIdx = CRComponentIdx;
              //   cbNew.componentName = cbData?.contactorConfigData?.name;
              //   cbNew.componentType = "Contactor";
              //   cbNew.connections = [];
              //   cbNew.communications = [];
              //   cbNew.uiAttributes = JSON.stringify(cbData.uiData);
              //   let connections = {
              //     componentIdx: CBComponentIdx,
              //     endpointIdX: "1",
              //     connectionType: "electrical_connection",
              //   };

              //   let attributes = {
              //     name: cbData?.contactorConfigData?.name,
              //     description: cbData?.contactorConfigData?.description,
              //     loadCategory: cbData?.contactorConfigData?.loadCategory,
              //     loadPriority: cbData?.contactorConfigData?.loadPriority,
              //   };

              //   cbNew.attributes = JSON.stringify(attributes);

              //   //cbNew values
              //   let communications = {};

              //   cbNew.connections.push(connections);
              //   cbNew.communications.push(communications);
              //   arrayComponent.push(cbNew);
              // }
              if (
                siteType === embLabels.ISLANDABLE ||
                (siteType === embLabels.GRID_CONNECTED &&
                  projectTypeValue !== "MGaaS ANSI") ||
                (siteType === embLabels.GRID_CONNECTED_GSX 
                  // &&cbData.uiData.cbRequired
                  ) ||
                (siteType === embLabels.ISLANDABLE_GSX &&
                  architectureType === embLabels.PV_GENSET_ATS &&
                  defaultPreFillData[1]?.mode === "FUEL_SAVER") ||
                (siteType === embLabels.GRID_CONNECTED &&
                  projectTypeValue === "MGaaS ANSI")
              ) {
                let cbNew = {};
                cbNew.assetId =
                  cbData?.cbConfigData?.cbAssetDetails[0]?.assetId ?? 7;
                cbNew.userId = 1;
                cbNew.componentIdx = CBComponentIdx;
                cbNew.componentName = cbData?.cbConfigData?.name;
                cbNew.componentType = "Circuit Breaker";
                cbNew.connections = connectionList;
                cbNew.communications = [];
                cbNew.uiAttributes = JSON.stringify(cbData.uiData);
                let attributesnew = {
                  name: cbData?.cbConfigData?.name,
                  description: cbData?.cbConfigData?.description,
                  motorized: cbData.cbConfigData.motorized ? 1 : 0,
                  contactor: cbData.cbConfigData.contactor ? 1 : 0,
                  loadCategory: cbData?.cbConfigData?.loadCategory,
                  loadPriority: cbData?.cbConfigData?.loadPriority,
                  Contactor_healthy_status_available: 0
                };
                if (
                  siteType === embLabels.GRID_CONNECTED &&
                  !cbData.uiData.cbRequired
                ) {
                  attributesnew.hardwired = cbData?.cbConfigData?.hardwired;
                }
               
                if (
                  // siteType === embLabels.GRID_CONNECTED &&
                  // cbData?.cbConfigData?.motorized &&
                  cbData?.cbConfigData?.cbAssetDetails[0]?.assetInfo ===
                    "Hardwired"
                ) {
                  attributesnew.type = "12";
                }
                if (
                  // siteType === embLabels.GRID_CONNECTED &&
                  cbData?.uiData?.contactor
                ) {
                  attributesnew.type = "20";
                  attributesnew.Contactor_healthy_status_available = cbData?.contactorConfigData?.cbAssetDetails?.[0]?.assetAttributes?.[0]?.value
                }
                if(!cbData?.cbConfigData?.motorized&&!cbData?.uiData?.contactor){
                  attributesnew.type = "40"
                }
                if (!(cbData.assetType === embLabels.GENSET || cbData.assetType === embLabels.UTILITY) && !cbData?.uiData?.cbRequired) {
                  attributesnew.type = "40";
                }
                if(isMgaasIecIsland){
                  if(cbData?.cbConfigData?.cbAssetDetails[0]?.assetInfo === "Hardwired"){
                    attributesnew.type = "12";
                  }
                  if (cbData.uiData?.contactor) {
                    attributesnew.type = "20";
                    attributesnew.Contactor_healthy_status_available = cbData?.contactorConfigData?.cbAssetDetails?.[0]?.assetAttributes?.[0]?.value
                  }
                  if(!cbData.uiData?.contactor&&!cbData?.cbConfigData?.motorized){
                    attributesnew.type = "40"
                  }
                  if (!(cbData.assetType === embLabels.GENSET || cbData.assetType === embLabels.UTILITY) && !cbData?.uiData?.cbRequired) {
                    attributesnew.type = "40";
                  }
                  }
                cbNew.attributes = JSON.stringify(attributesnew);

                let communicationsnew = {
                  ip: cbData?.cbConfigData?.cbCommunicationData?.ipaddress,
                  subnetmask:
                    cbData?.cbConfigData?.cbCommunicationData?.subnetmask,
                  defaultGateway:
                    cbData?.cbConfigData?.cbCommunicationData?.defaultGateway,
                  modbusSlaveId:
                    cbData?.cbConfigData?.cbCommunicationData?.modbusSlaveId,
                  type: "Circuit Breaker",
                };

                cbNew.communications.push(communicationsnew);
                arrayComponent.push(cbNew);
              }
              if (
                [embLabels.ISLANDABLE,embLabels.GRID_CONNECTED].includes(siteType)  &&
                cbData?.uiData?.contactor&&!cbData.cbConfigData.motorized
              ) {
                let cbNew = {};
                cbNew.assetId =
                  cbData.contactorConfigData.cbAssetDetails[0]?.assetId ?? 7;
                cbNew.userId = 1;
                cbNew.componentIdx = CRComponentIdx;
                cbNew.componentName = cbData?.contactorConfigData?.name;
                cbNew.componentType = "Contactor";
                cbNew.connections = [];
                cbNew.communications = [];
                cbNew.uiAttributes = JSON.stringify(cbData.uiData);
                let connections = {
                  componentIdx: CBComponentIdx,
                  endpointIdX: "1",
                  connectionType: "electrical_connection",
                };

                let attributes = {
                  name: cbData?.contactorConfigData?.name,
                  description: cbData?.contactorConfigData?.description,
                  loadCategory: cbData?.contactorConfigData?.loadCategory,
                  loadPriority: cbData?.contactorConfigData?.loadPriority,
                };

                cbNew.attributes = JSON.stringify(attributes);

                //cbNew values
                let communications = {};

                cbNew.connections.push(connections);
                cbNew.communications.push(communications);
                arrayComponent.push(cbNew);
              }

              if (
                (siteType === embLabels.GRID_CONNECTED_GSX &&
                  architectureType === embLabels.PV_BESS_NR &&
                  cbData.uiData.pmRequired) ||
                (siteType === embLabels.ISLANDABLE_GSX &&
                  architectureType === embLabels.PV_GENSET_ATS &&
                  defaultPreFillData[1]?.mode === "FUEL_SAVER") ||
                (siteType === embLabels.GRID_CONNECTED &&
                  cbData.uiData.pmRequired) ||
                (siteType === embLabels.GRID_CONNECTED &&
                  projectTypeValue === "MGaaS ANSI" &&  cbData.uiData.pmRequired) ||
                (siteType === embLabels.ISLANDABLE &&
                  projectTypeValue === "MGaaS ANSI" &&  cbData.uiData.pmRequired) ||
                  (projectKind.isMgaasIecIsland&&cbData.uiData.pmRequired)
              ) {
                let cbNew = {};
                cbNew.assetId =
                  cbData?.powerMeterData?.pmAsset?.assetId ??
                  powerMeter?.assetId;
                cbNew.userId = 1;
                cbNew.componentIdx = PMComponentIdx;
                cbNew.componentName = cbData?.powerMeterData?.name??defaultPreFillData?.[0]?.["Power Meter"]?.namePV;
                cbNew.componentType = "Power Meter";
                cbNew.connections = connectionList;
                cbNew.communications = [];
                cbNew.uiAttributes = JSON.stringify(cbData.uiData);
                let attributesnew = {
                  name: cbData?.powerMeterData?.name,
                  description: cbData?.powerMeterData?.description,
                  powerMeterModbusId:
                    cbData?.powerMeterData?.powerMeterModbusId,
                };

                cbNew.attributes = JSON.stringify(attributesnew);

                //* cb values

                let communicationsnew = {
                  ip: cbData.powerMeterData.ipAddress,
                  subnetmask: cbData.powerMeterData.mask,
                  defaultGateway: cbData.powerMeterData.gatewayAddress,
                  modbusSlaveId: cbData.powerMeterData.powerMeterModbusId,
                  type: "Power Meter",
                };
                cbNew.communications.push(communicationsnew);
                arrayComponent.push(cbNew);
              }
            }

            break;

          default:
            break;
        }
      }
      return null;
    });

    //* load

    let loadCBI = 50;
    CBLoadList.map((cbData, key) => {
      let load = {};
      load.assetId = 6;
      load.userId = 1;
      let CRComponentIdx = `Contactor_${loadCBI}`
      load.componentIdx = `ASSET_${padNumberFormat(assetI)}`;
      load.componentName = cbData?.configData?.name;
      load.componentType = "Load";
      load.connections = [];
      load.communications = [];
      load.uiAttributes = JSON.stringify(cbData.uiData);
      if (
        [
          embLabels.ISLANDABLE,
          embLabels.GRID_CONNECTED,
          embLabels.OFF_GRID,
          embLabels.GENSET_PVBESS_A2E,
        ].includes(siteType)
      ) {
        componentJson.deploymentReady =
          componentJson.deploymentReady && cbData.uiData.configured
            ? true
            : false;
      }
      if (
        siteType === embLabels.PV_HYBRIDIZATION ||
        architectureType === embLabels.PV_GENSET_ATS
      ) {
        componentJson.deploymentReady =
          componentJson.deploymentReady === true ? true : false;
      }

      let connectionsLoad = {
        componentIdx: "CB_" + loadCBI,
        endpointIdX: "1",
        connectionType: "electrical_connection",
      };
      let loadattributes = {
        name: cbData.configData.name,
        description: cbData.configData.description,
        maximum_available_power: cbData.configData.maximum_available_power,
      };
      load.attributes = JSON.stringify(loadattributes);

      if (
        siteType === embLabels.GRID_CONNECTED_GSX||
        siteType === embLabels.ISLANDABLE ||
        siteType === embLabels.GENSET_PVBESS_A2E ||
        siteType === embLabels.GRID_CONNECTED ||
        (siteType === embLabels.ISLANDABLE_GSX  &&
          defaultPreFillData[1].mode === "FUEL_SAVER")
      ) {
        load.connections.push(connectionsLoad);
      }

      arrayComponent.push(load);
      let projectTypeValue = getProjectTypeValue()

      if((siteType === embLabels.ISLANDABLE&& (projectTypeValue==="MGaaS IEC"))&&cbData.uiData.contactor){
        let cbNew = {};
                cbNew.assetId =
                  cbData.contactorConfigData?.cbAssetDetails[0]?.assetId ?? 7;
                cbNew.userId = 1;
                cbNew.componentIdx = CRComponentIdx;
                cbNew.componentName = cbData?.contactorConfigData?.name;
                cbNew.componentType = "Contactor";
                cbNew.connections = [];
                cbNew.communications = [];
                cbNew.uiAttributes = JSON.stringify(cbData.uiData);
                let connections = {
                  componentIdx: "CB_" + loadCBI,
                  endpointIdX: "1",
                  connectionType: "electrical_connection",
                };

                let attributes = {
                  name: cbData?.contactorConfigData?.name,
                  description: cbData?.contactorConfigData?.description,
                  loadCategory: cbData?.contactorConfigData?.loadCategory,
                  loadPriority: cbData?.contactorConfigData?.loadPriority,
                };

                cbNew.attributes = JSON.stringify(attributes);

                //cbNew values
                let communications = {};

                cbNew.connections.push(connections);
                cbNew.communications.push(communications);
                arrayComponent.push(cbNew);
      }
      if (
        (siteType === embLabels.GRID_CONNECTED_GSX &&
          projectTypeValue === "DaaS ANSI") ||
        siteType === embLabels.ISLANDABLE ||
        (siteType === embLabels.ISLANDABLE_GSX &&
          defaultPreFillData[1].mode === "FUEL_SAVER") ||
        (siteType === embLabels.GRID_CONNECTED &&
          projectTypeValue === "MGaaS ANSI") ||
        (siteType === embLabels.GRID_CONNECTED &&
          projectTypeValue === "MGaaS IEC") || isDaasIecGrid
      ) {
        let cb = {};

        cb.assetId = cbData?.cbConfigData?.cbAssetDetails?.[0]?.assetId ?? 7;
        cb.userId = 1;
        cb.componentIdx = `CB_${loadCBI}`;
        cb.componentName = cbData?.cbConfigData?.name;
        cb.componentType = "Circuit Breaker";
        cb.connections = [];
        cb.communications = [];
        cb.uiAttributes = JSON.stringify(cbData?.uiData);
        let connectionsCb = {
          componentIdx: "ASSET_" + padNumberFormat(assetI),
          endpointIdX: "1",
          connectionType: "electrical_connection",
        };

        //* copying cb values

        // let communications = {
        //   ip: cbData.cbConfigData?.cbCommunicationData?.ipaddress,
        //   subnetmask: cbData.cbConfigData?.cbCommunicationData?.subnetmask,
        //   defaultGateway:
        //     cbData.cbConfigData?.cbCommunicationData?.defaultGateway,
        //   modbusSlaveId:
        //     cbData.cbConfigData?.cbCommunicationData?.modbusSlaveId,
        //   type: "Circuit Breaker",
        // };
        let communications = {}
        if(isMgaasIecGrid || isDaasAnsiGrid || isMgaasAnsiGrid || isDaasIecGrid){
          communications = {
            ip: "",
            subnetmask: "",
            defaultGateway:"",
            modbusSlaveId:"",
            type: "Circuit Breaker",
          };
        }else{
          if(isMgaasIecIsland && cbData.uiData.cbRequired==false){
            communications = {
              ip: "",
              subnetmask: "",
              defaultGateway:"",
              modbusSlaveId:"",
              type: "Circuit Breaker",
            };
          }else{
          communications = {
            ip: cbData.cbConfigData?.cbCommunicationData?.ipaddress,
            subnetmask: cbData.cbConfigData?.cbCommunicationData?.subnetmask,
            defaultGateway:
              cbData.cbConfigData?.cbCommunicationData?.defaultGateway,
            modbusSlaveId:
              cbData.cbConfigData?.cbCommunicationData?.modbusSlaveId,
            type: "Circuit Breaker",
            };
          }
        }
        let attributes = {
          name: cbData.cbConfigData?.name,
          description: cbData.cbConfigData?.description,
          motorized: cbData.cbConfigData?.motorized ? 1 : 0,
          loadCategory: cbData.cbConfigData?.loadCategory,
          loadPriority: cbData.cbConfigData?.loadPriority,
        };
        if(cbData?.cbConfigData?.cbAssetDetails?.[0]?.assetInfo === "Hardwired"){
          attributes.type = "12";
        }
        if (cbData.uiData?.contactor) {
          attributes.type = "20";
          attributes.Contactor_healthy_status_available = cbData?.contactorConfigData?.cbAssetDetails?.[0]?.assetAttributes?.[0]?.value
        }
        if(!cbData.uiData?.contactor&&!cbData?.cbConfigData?.motorized){
          attributes.type = "40"
        }
        if (
          (!cbData?.uiData?.CBConfigured) || (isDaasAnsiGrid || isMgaasAnsiGrid || isDaasIecGrid)
        ) {
          attributes.type = "40";
        }
        if(isMgaasIecIsland && !cbData?.uiData?.cbRequired){
          attributes.type = "40";

        }
        cb.attributes = JSON.stringify(attributes);

        cb.connections.push(connectionsCb);
        cb.communications.push(communications);
        if(projectKind.isMgaasIecIsland&&cbData.uiData.pmRequired){
          let connectionList = [{
            componentIdx: `ASSET_${padNumberFormat(assetI)}`,
            endpointIdX: "1",
            connectionType: "electrical_connection",
          }];
          let PMComponentIdx = `PM_${loadCBI}`
          let cbNew = {};
                cbNew.assetId = cbData?.powerMeterData?.pmAsset?.assetId ?? powerMeter?.assetId;
                cbNew.userId = 1;
                cbNew.componentIdx = PMComponentIdx;
                cbNew.componentName = cbData?.powerMeterData?.name;
                cbNew.componentType = "Power Meter";
                cbNew.connections = connectionList;
                cbNew.communications = [];
                cbNew.uiAttributes = JSON.stringify(cbData.uiData);
                let attributesnew = {
                  name: cbData?.powerMeterData?.name,
                  description: cbData?.powerMeterData?.description,
                  powerMeterModbusId:
                    cbData?.powerMeterData?.powerMeterModbusId,
                };

                cbNew.attributes = JSON.stringify(attributesnew);

                //* cb values

                let communicationsnew = {
                  ip: cbData?.powerMeterData?.ipAddress,
                  subnetmask: cbData?.powerMeterData?.mask,
                  defaultGateway: cbData?.powerMeterData?.gatewayAddress,
                  modbusSlaveId: cbData?.powerMeterData?.powerMeterModbusId,
                  type: "Power Meter",
                };
                cbNew.communications.push(communicationsnew);
                arrayComponent.push(cbNew);
        }
        arrayComponent.push(cb);

        loadCBI = loadCBI + 1;

        //* skip 60 for ev
        if (loadCBI === 60) loadCBI = 61;
      }
      assetI = assetI + 1;

      return null;
    });

    //* ev

    let evCBI = 60;
    CBEVList.map((cbData, key) => {
      if (cbData.uiData.status) {
        let ev = {};
        if (cbData.assetDetails.length) {
          ev.assetId = cbData.assetDetails[0].assetId;
        } else {
          ev.assetId = 5;
        }
        ev.userId = 1;
        ev.componentIdx = "ASSET_" + padNumberFormat(assetI);
        ev.componentName = cbData?.configData?.name;
        ev.componentType = "EV_charging_station";
        ev.connections = [];
        ev.communications = [];
        ev.uiAttributes = JSON.stringify(cbData.uiData);
        componentJson.deploymentReady =
          componentJson.deploymentReady && cbData.uiData.configured
            ? true
            : false;

        let connectionsEv = {
          componentIdx: "CB_" + evCBI,
          endpointIdX: "1",
          connectionType: "electrical_connection",
        };
        let evattributes = {
          name: cbData.configData.name,
          description: cbData.configData.description,
          maximum_available_power: cbData.configData.maximum_available_power,
        };
        ev.attributes = JSON.stringify(evattributes);

        if (siteType === embLabels.ISLANDABLE ||siteType === embLabels.GRID_CONNECTED) {
          ev.connections.push(connectionsEv);
        }

        if (cbData.communicationData.ipaddress) {
          let evCommunications = {
            ip: cbData.communicationData.ipaddress,
            subnetmask: cbData.communicationData.subnetmask,
            defaultGateway: cbData.communicationData.defaultGateway,
            modbusSlaveId: cbData.communicationData.modbusSlaveId,
            type: "EV_charging_station",
          };
          ev.communications.push(evCommunications);
        }
        arrayComponent.push(ev);
        if (siteType === embLabels.ISLANDABLE||siteType===embLabels.GRID_CONNECTED) {
          let cb = {};
          cb.assetId = cbData?.cbConfigData?.cbAssetDetails?.[0]?.assetId ?? 7;
          cb.userId = 1;
          if(cbData?.uiData?.cbRequired){
            cb.componentIdx = "CB_" + evCBI;
            cb.componentName = cbData.cbConfigData.name;
            cb.componentType = "Circuit Breaker";
            cb.connections = [];
            cb.communications = [];
            cb.uiAttributes = JSON.stringify(cbData.uiData);
            let connectionsCb = {
              componentIdx: "ASSET_" + padNumberFormat(assetI),
              endpointIdX: "1",
              connectionType: "electrical_connection",
            };
            let communications = {}
            if(cbData?.uiData?.cbRequired&&cbData?.cbConfigData?.cbAssetDetails?.[0]?.assetInfo!=="Hardwired"){
               communications = {
                ip: cbData.cbConfigData.cbCommunicationData.ipaddress,
                subnetmask: cbData.cbConfigData.cbCommunicationData.subnetmask,
                defaultGateway:
                  cbData.cbConfigData.cbCommunicationData.defaultGateway,
                modbusSlaveId:
                  cbData.cbConfigData.cbCommunicationData.modbusSlaveId,
                type: "Circuit Breaker",
              };
            }else{
              communications = {
                ip: '',
                subnetmask: '',
                defaultGateway:'',
                modbusSlaveId:'',
                type: "Circuit Breaker",
              };
            }
            let attributes = {
              name: cbData.cbConfigData.name,
              description: cbData.cbConfigData.description,
              motorized: cbData.cbConfigData.motorized ? 1 : 0,
              loadCategory: cbData.cbConfigData.loadCategory,
              loadPriority: cbData.cbConfigData.loadPriority,
              Contactor_healthy_status_available: 0
            };
           
            if(cbData?.cbConfigData?.motorized&&cbData?.cbConfigData?.cbAssetDetails?.[0]?.assetInfo === "Hardwired"){
              attributes.type = "12";
            }
            if (cbData?.uiData?.contactor) {
              attributes.type = "20";
              attributes.Contactor_healthy_status_available = cbData?.contactorConfigData?.cbAssetDetails?.[0]?.assetAttributes?.[0]?.value;
            }
           if(!cbData?.cbConfigData?.motorized&&!cbData?.uiData?.contactor){
              attributes.type = "40"
            }
            cb.attributes = JSON.stringify(attributes);
  
            cb.connections.push(connectionsCb);
            cb.communications.push(communications);
            arrayComponent.push(cb);
          }
        }

        if (
          (siteType === embLabels.GRID_CONNECTED||siteType===embLabels.ISLANDABLE) &&
          cbData?.uiData?.pmRequired
        ) {
          let cb = {};
          cb.assetId =
            cbData?.powerMeterData?.pmAsset?.assetId ?? powerMeter?.assetId;
          cb.userId = 1;
          cb.componentIdx = "PM_60";
          cb.componentName = cbData?.powerMeterData?.name;
          cb.componentType = "Power Meter";
          cb.connections = [];
          cb.communications = [];
          cb.uiAttributes = JSON.stringify(cbData.uiData);
          let connections = {
            componentIdx: "ASSET_" + padNumberFormat(assetI),
            endpointIdX: "1",
            connectionType: "electrical_connection",
          };
          let attributes = {
            name: cbData.powerMeterData.name || "PM_EV",
            description: cbData.powerMeterData.description || "",
            powerMeterModbusId:
              cbData.powerMeterData.powerMeterModbusId || "103",
          };
          cb.attributes = JSON.stringify(attributes);

          //*cb values

          let communications = {};
          communications = {
            ip: cbData.powerMeterData.ipAddress,
            subnetmask: cbData.powerMeterData.subnetMask,
            defaultGateway: cbData.powerMeterData.defaultGateway,
            modbusSlaveId: cbData.powerMeterData.powerMeterModbusId || "103",
            type: "Power Meter",
          };
          cb.connections.push(connections);
          cb.communications.push(communications);
          arrayComponent.push(cb);
          assetCB = assetCB + 10;
        }
      }
      if (
        (siteType === embLabels.GRID_CONNECTED||siteType===embLabels.ISLANDABLE) &&
        cbData?.uiData?.contactor&&!cbData.cbConfigData.motorized
      ) {
        let cbNew = {};
        cbNew.assetId =
          cbData.contactorConfigData.cbAssetDetails[0]?.assetId ?? 7;
        cbNew.userId = 1;
        cbNew.componentIdx = "Contactor_60";
        cbNew.componentName = cbData?.contactorConfigData?.name;
        cbNew.componentType = "Contactor";
        cbNew.connections = [];
        cbNew.communications = [];
        cbNew.uiAttributes = JSON.stringify(cbData.uiData);
        let connections = {
          componentIdx: "CB_60",
          endpointIdX: "1",
          connectionType: "electrical_connection",
        };
        let attributes = {
          name: cbData?.contactorConfigData?.name??"EV Contactor",
          description: cbData?.contactorConfigData?.description??"",
          loadCategory: cbData?.contactorConfigData?.loadCategory??0,
          loadPriority: cbData?.contactorConfigData?.loadPriority??0,
        };

        cbNew.attributes = JSON.stringify(attributes);

        //cbNew values
        let communications = {};

        cbNew.connections.push(connections);
        cbNew.communications.push(communications);
        arrayComponent.push(cbNew);
      }

      return null;
    });

    componentJson.components = assignIpAddress(arrayComponent,communicationState,CBAssetList,CBLoadList,CBEVList)
    componentJson.diagramChangeRequired = false;
    if (UserScope.access.saveSLD === true) {
    const deploymentReadyState =   checkAssetStatus();
    componentJson.deploymentReady = deploymentReadyState;
      updateComponentRemote(componentJson);
      checkExportOptimizationCondition();
      
    }
  };

  const updateComponentRemote = (componentJson) => {
    setLoading(true);
    apiSession
      .updateComponents(componentJson)
      .then((json) => {
        setJsonData(json.components)
        getCBDetails(json.components);
        setSwitchboardType(json.switchboardType);
        setRemoteMain(json.remoteMain);
        setLoadSide(json.connectionType);

        projectKindDispatch({
          type: PROJECT_ACTION.UPDATE_PROJECT_KIND,
          payload: json,
        });
        //* update pv communication

        PVDetailsDispatch({
          type: ACTIONS.UPDATE_PV_COMMUNICATION_COMPONENT,
          payload: {
            components: json.components,
            externalId: gsxExternalId.utilityExternalId,
          },
        });

        setLoading(false);
        localStorage.setItem("programPage", true);
        localStorage.setItem("communicationValidated", json.systemsPage);
        setDuplicateUpdate({
          der: "",
          load: "",
          pv: "",
          ev: "",
        });
        if (defaultConfigurationStatus) {
          callSnackbar("success", embLabels.defaultConfigurationCreated);
          const defaultConfigurationStatusNew = false;
          setDefaultConfigurationStatus(defaultConfigurationStatusNew);
        } else {

          callSnackbar("success", embLabels.configurationUpdatedSuccess);
        }
        if (deleteOneDER) {
          localStorage.setItem("deploymentReady", false);
          checkGensetDeleteOneDER(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 400) {
            if (duplicateUpdate.der !== "") {
              CBAssetListDispatch({
                type: ACTIONS.DUPLICATE_DER_UPDATE,
                payload: {
                  selectedAsset: duplicateUpdate.der,
                },
              });
            }
            if (duplicateUpdate.load !== "") {
              CBLoadListDispatch({
                type: ACTIONS.DUPLICATE_LOAD_UPDATE,
                payload: {
                  selectedLoad: duplicateUpdate.load,
                },
              });
            }
            if (duplicateUpdate.ev !== "") {
              CBEVListDispatch({
                type: ACTIONS.DUPLICATE_EV_UPDATE,
                payload: {
                  selectedEv: duplicateUpdate.ev,
                },
              });
            }
            if (duplicateUpdate.pv !== "") {
              PVDetailsDispatch({
                type: ACTIONS.DUPLICATE_PV_UPDATE,
              });
              PVDetailsDispatch({
                type: ACTIONS.PV_CONFIGURED_STATUS,
              });
            }
          }
          callSnackbar("error", error.response.data.message);
        } else {
          callSnackbar("error", embLabels.errorInUpdatingConfiguration);
        }
        if (deleteOneDER) {
          localStorage.setItem("deploymentReady", false);
          checkGensetDeleteOneDER(true);
        }
      });
  };

  const getCBDetails = (components) => {
    const CBIFEDETAILS = components.filter(
      (component) =>
        component.communications.length &&
        component.componentType === "Circuit Breaker"
    );
    const CBDETAILS = CBIFEDETAILS.map((component) => ({
      [component.componentIdx]: {
        ip: component.communications[0].ip,
        gateway: component.communications[0].defaultGateway,
        mask: component.communications[0].subnetmask,
        modbus: component.communications[0].modbusSlaveId,
        assetId: component.asset.assetId,
        numberOfDevicesIFE: component.asset.assetAttributes[0].value,
      },
    }));

    const CBVALUES = Object.assign({}, ...CBDETAILS);
    setCBValues(CBVALUES);
    const usedIps = CBIFEDETAILS.map(
      (cbdetail) => cbdetail.communications[0].ip
    );
    const usedIfeSet = CBIFEDETAILS.map((cbdetail) => [
      cbdetail.communications[0].ip,
      cbdetail.asset.assetAttributes[0].value,
    ]);
    const ifeIpSets = {};
    for (let [usedIp, maxValue] of usedIfeSet) {
      if (ifeIpSets[usedIp]) {
        ifeIpSets[usedIp][0] = ifeIpSets[usedIp][0] + 1;
      } else {
        ifeIpSets[usedIp] = [1, +maxValue];
      }
    }
    setCbCommunicationValues((prevState) => ({
      ...prevState,
      usedIps,
      ifeIpSets,
      availableIps: prevState.allIps.filter(
        (ifeIp) => !usedIps.includes(ifeIp)
      ),
    }));
  };

  const assignComponents = (component,contactor) => {
    let components = component.components;
    let loadCBIndex = 0;
    let loadIndex = 0;
    let pvNo1 = 0;
    let pvNo2 = 0;
    let pvNo3 = 0;

    components.sort((a, b) => a.componentIdx.localeCompare(b.componentIdx));

    //* update pv communication
    PVDetailsDispatch({
      type: ACTIONS.UPDATE_EMPTY_PVCOMMUNICATION,
      payload: {
        componentsData: components,
      },
    });
    const loadPMIDArray = ["PM_50","PM_51","PM_52","PM_53","PM_54","PM_55","PM_56","PM_57","PM_58","PM_59"];
    const loadContactorIDArray = ["Contactor_50","Contactor_51","Contactor_52","Contactor_53","Contactor_54","Contactor_55","Contactor_56","Contactor_57","Contactor_58","Contactor_59"];
    const LoadEMArray = ["CB_50","CB_51","CB_52","CB_53","CB_54","CB_55","CB_56","CB_57","CB_58","CB_59"]
    components.map((componentsData) => {
      switch (componentsData.componentType) {
        case embLabels.UTILITY:
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_UTILITY,
            payload: { componentsData: componentsData,siteType:component.siteType  },
          });
          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });
          if (
            component.siteType === embLabels.ISLANDABLE_GSX ||
            component.siteType === embLabels.GRID_CONNECTED_GSX
          ) {
            setGsxExternalId((prevState) => ({
              ...prevState,
              utilityExternalId: componentsData.externalId
                ? componentsData.externalId
                : "",
            }));
          }
          break;

        case embLabels.GENSET:
          //default value for genset power, site is Access to Energy
          const assetAttributesNew = JSON.parse(componentsData.attributes);
          if (
            component.siteType === "GENSET_PVBESS_A2E" &&
            assetAttributesNew.max_power === "1200"
          ) {
            assetAttributesNew.max_power = "130";
            componentsData.attributes = JSON.stringify(assetAttributesNew);
          }

          CBAssetListDispatch({
            type: ACTIONS.UPDATE_GENSET,
            payload: { componentsData: componentsData ,switchboardType:component?.switchboardType},
          });
          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });
          if (component.siteType === embLabels.ISLANDABLE_GSX) {
            setGsxExternalId((prevState) => ({
              ...prevState,
              gensetExternalId: componentsData.externalId
                ? componentsData.externalId
                : "",
            }));
          }
          break;

        case embLabels.BESS:
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_BESS,
            payload: {
              componentsData: componentsData,
              batteryCommunicationData,
              setBatteryCommunicationData,
              contactor,
            },
          });
          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });

          if (
            component.siteType === embLabels.GRID_CONNECTED_GSX ||
            component.siteType === embLabels.ISLANDABLE_GSX
          ) {
            setGsxExternalId((prevState) => ({
              ...prevState,
              batteryExternalId: componentsData.externalId
                ? componentsData.externalId
                : "",
            }));
          }
          break;

        case embLabels.INVERTER:
          const uiData = JSON.parse(componentsData.uiAttributes);

          PVDetailsDispatch({
            type: ACTIONS.UPDATE_PV_COMMUNICATION,
            payload: {
              componentsData: componentsData,
            },
          });

          if (uiData.pv === 1) {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_PV,
              payload: {
                componentsData,
                pvArray: 3,
              },
            });

            if (pvNo1 === 0) {
              countDispatch({
                type: ACTIONS.UPDATE_DER_COUNT,
                payload: { count: 1 },
              });
            }

            PVDetailsDispatch({
              type: ACTIONS.UPDATE_PVSET,
              payload: {
                componentsData,
                pvArray: 3,
              },
            });

            pvNo1++;
          } else if (uiData.pv === 2) {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_PV,
              payload: {
                componentsData,
                pvArray: 4,
              },
            });

            if (pvNo2 === 0) {
              countDispatch({
                type: ACTIONS.UPDATE_DER_COUNT,
                payload: { count: 1 },
              });
            }

            PVDetailsDispatch({
              type: ACTIONS.UPDATE_PVSET,
              payload: {
                componentsData,
                pvArray: 4,
              },
            });
            pvNo2++;
          } else if (uiData.pv === 3) {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_PV,
              payload: {
                componentsData,
                pvArray: 5,
              },
            });
            if (pvNo3 === 0) {
              countDispatch({
                type: ACTIONS.UPDATE_DER_COUNT,
                payload: { count: 1 },
              });
            }

            PVDetailsDispatch({
              type: ACTIONS.UPDATE_PVSET,
              payload: {
                componentsData,
                pvArray: 5,
              },
            });

            pvNo3++;
          }

          break;

        case embLabels.CHP:
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CHP,
            payload: {
              componentsData: componentsData,
            },
          });

          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });
          break;

        case embLabels.HYBRID:
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_HYBRID,
            payload: {
              componentsData: componentsData,
            },
          });

          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });
          break;

        case embLabels.LOAD:
          const uiAttributes = JSON.parse(componentsData.uiAttributes);
          if(uiAttributes.isRemoteLoad){
            countDispatch({
              type: ACTIONS.UPDATE_REMOTE_LOAD_COUNT,
              payload: { count: 1 },
            });
          }else{
            countDispatch({
              type: ACTIONS.UPDATE_LOAD_COUNT,
              payload: { count: 1 },
            });
          }
          CBLoadListDispatch({
            type: ACTIONS.UPDATE_LOAD,
            payload: { componentsData, loadIndex,siteType:component.siteType,projectTypeValue },
          });

          //   CBLoadListUpdated = newLoadData;
          loadIndex++;

          break;

        case embLabels.EV_CHARGING_STATION:
          countDispatch({
            type: ACTIONS.UPDATE_EV_COUNT,
            payload: { count: 1 },
          });
          CBEVListDispatch({
            type: ACTIONS.UPDATE_EV,
            payload: {
              componentsData,
              evCommunicationData,
              setEvCommunicationData,
            },
          });

          break;

        case embLabels.CIRCUIT_BREAKER:
          if (componentsData.componentIdx === "CB_10") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_CB,
              payload: {
                componentsData,
                value: 0,
              },
            });
          } else if (componentsData.componentIdx === "CB_20") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_CB,
              payload: {
                componentsData,
                value: 1,
              },
            });
          } else if (componentsData.componentIdx === "CB_30") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_CB,
              payload: {
                componentsData,
                value: 2,
              },
            });
          } else if (componentsData.componentIdx === "CB_80") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_CB,
              payload: {
                componentsData,
                value: 6,
              },
            });
          } else if (componentsData.componentIdx === "CB_90") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_CB,
              payload: {
                componentsData,
                value: 7,
              },
            });
          } else if (componentsData.componentIdx === "CB_40") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_CB,
              payload: {
                componentsData,
                value: 3,
              },
            });
          } else if (componentsData.componentIdx === "CB_41") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_CB,
              payload: {
                componentsData,
                value: 4,
              },
            });
          } else if (componentsData.componentIdx === "CB_42") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_CB,
              payload: {
                componentsData,
                value: 5,
              },
            });
          } else if (componentsData.componentIdx === "CB_60") {
            CBEVListDispatch({
              type: ACTIONS.UPDATE_EV_CB,
              payload: { componentsData },
            });
          } 
          else if(LoadEMArray.includes(componentsData.componentIdx)){
            LoadEMArray.forEach((loadData,index)=>{
              if(loadData === componentsData.componentIdx){
                CBLoadListDispatch({
                  type: ACTIONS.UPDATE_EM_LOAD,
                  payload: {
                    componentsData,
                    value: index,
                  },
                });
                CBLoadListDispatch({
                  type: ACTIONS.UPDATE_LOAD_CB,
                  payload: { componentsData, loadCBIndex:index,siteType:component.siteType,projectTypeValue, contactor: contactor },
                });
              }
            })
          }
          else {
            CBLoadListDispatch({
              type: ACTIONS.UPDATE_LOAD_CB,
              payload: { componentsData, loadCBIndex,siteType:component.siteType,projectTypeValue, contactor: contactor },
            });
            loadCBIndex++;
          }
          break;

        case embLabels.CONTACTOR:
          if (componentsData.componentIdx === "Contactor_30") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_CB_CONTACTOR,
              payload: {
                componentsData,
                value: 2,
              },
            });
          }
          if (componentsData.componentIdx === "Contactor_40") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_CB_CONTACTOR,
              payload: {
                componentsData,
                value: 3,
              },
            });
          }
          if (componentsData.componentIdx === "Contactor_41") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_CB_CONTACTOR,
              payload: {
                componentsData,
                value: 4,
              },
            });
          }
          if (componentsData.componentIdx === "Contactor_42") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_CB_CONTACTOR,
              payload: {
                componentsData,
                value: 5,
              },
            });
          }
          if (componentsData.componentIdx === "Contactor_60") {
            CBEVListDispatch({
              type: ACTIONS.UPDATE_DER_CB_CONTACTOR,
              payload: {
                componentsData,
                value: 0,
              },
            });
          }
          if(loadContactorIDArray.includes(componentsData.componentIdx)){
            loadContactorIDArray.forEach((loadData,index)=>{
              if(loadData === componentsData.componentIdx){
                CBLoadListDispatch({
                  type: ACTIONS.UPDATE_DER_CB_CONTACTOR,
                  payload: {
                    componentsData,
                    value: index,
                  },
                });
              }
            })
          }


          break;

        case embLabels.POWER_METER:
          if (["PM_10", "CB_10"].includes(componentsData.componentIdx)) {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_PM,
              payload: {
                componentsData,
                value: 0,
              },
            });
          } else if (componentsData.componentIdx === "PM_20") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_PM,
              payload: {
                componentsData,
                value: 1,
              },
            });
          } else if (componentsData.componentIdx === "PM_40") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_PM,
              payload: {
                componentsData,
                value: 3,
              },
            });
          } else if (componentsData.componentIdx === "PM_41") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_PM,
              payload: {
                componentsData,
                value: 4,
              },
            });
          } else if (componentsData.componentIdx === "PM_42") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_PM,
              payload: {
                componentsData,
                value: 5,
              },
            });
          } else if (componentsData.componentIdx === "PM_30") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_PM,
              payload: {
                componentsData,
                value: 2,
              },
            });
          } else if (componentsData.componentIdx === "PM_80") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_PM,
              payload: {
                componentsData,
                value: 6,
              },
            });
          } else if (componentsData.componentIdx === "PM_60") {
            CBEVListDispatch({
              type: ACTIONS.UPDATE_EV_PM,
              payload: { componentsData },
            });
         } else if (componentsData.componentIdx === "PM_50"&&!(component.siteType===embLabels.ISLANDABLE&&projectTypeValue==="MGaaS IEC")) {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_EXISTING_PM,
              payload: {
                componentsData,
                value: 2,
              },
            });
          } else if (["PM_50","PM_51","PM_52","PM_53","PM_54","PM_55","PM_56","PM_57","PM_58","PM_59"].includes(componentsData.componentIdx)&&(component.siteType===embLabels.ISLANDABLE&&projectTypeValue==="MGaaS IEC")) {
            let foundPM = false;
            for (let index = 0; index < loadPMIDArray.length; index++) {
              const item = loadPMIDArray[index];
              if (item === componentsData.componentIdx) {
                CBLoadListDispatch({
                  type: ACTIONS.UPDATE_DER_EXISTING_PM,
                  payload: {
                    componentsData,
                    value: index,
                  },
                });
                foundPM = true;
                break; // Exit the loop once the condition is met
              }
            }
          }else if (componentsData.componentIdx === "PM_51") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_EXISTING_PM,
              payload: {
                componentsData,
                value: 3,
              },
            });
          }  else {
            // do nothing
          }

          break;

        default:
          break;
      }
      return null;
    });

    const runCalculateCoordinatesNew = { componentPush: false };
    setRunCalculateCoordinates(runCalculateCoordinatesNew);
    setSldLoadCompleted(true);
    setLoading(false);

    // let CBAssetList = _.clone(this.state.CBAssetList);
    // let pvStatus1 = true;
    // this.state.pvset1Data.map(data => {
    //   if (pvStatus1 === true && data.status === true) {
    //     // nothing
    //   } else {
    //     pvStatus1 = false;
    //   }
    //   return null;
    // });
    // newPvData[3].uiData.configured = pvStatus1;
    // let pvStatus2 = true;
    // this.state.pvset2Data.map(data => {
    //   if (pvStatus2 === true && data.status === true) {
    //     // nothing
    //   } else {
    //     pvStatus2 = false;
    //   }
    //   return null;
    // });
    // newPvData[4].uiData.configured = pvStatus2;

    // let pvStatus3 = true;
    // this.state.pvset3Data.map(data => {
    //   if (pvStatus3 === true && data.status === true) {
    //     pvStatus3 = true;
    //   } else {
    //     pvStatus3 = false;
    //   }
    //   return null;
    // });
    // newPvData[5].uiData.configured = pvStatus3;

    // this.setState({
    //   CBAssetList: newPvData,
    // });
  };

  const setAssetSelection = (assetName) => {
    PVDetailsDispatch({
      type: ACTIONS.CLOSE_PVDISPLAY_CONTENT,
      payload: {
        selectSubPv: PVDetails?.selectSubPv,
        selectedPv: PVDetails?.selectedPv,
      }
    });

    const newAssetInfo = { ...assetInfo, assetSelected: true };
    setAssetInfo(newAssetInfo);
    getAssets(assetName);
  };

  const getAssets = (type) => {
    setAssetInfo((prevState) => ({ ...prevState, manufacturerLoading: true }));
    apiSession
      .getAssetsList(type, getProjectId())
      .then((json) => {
        const manufacturer = [];
        let standardTemplate = "";
        json.assetCatalogue[0].assetList.forEach((data) => {
          if (type === embLabels.GENERATOR) {
            if (data.manufacturer === "STANDARD GENSET TEMPLATE") {
              standardTemplate = data.manufacturer;
            } else {
              manufacturer.push(data.manufacturer);
            }
          } else if (type === embLabels.BESS) {
            if (data.manufacturer === "STANDARD BESS TEMPLATE") {
              standardTemplate = data.manufacturer;
            } else {
              manufacturer.push(data.manufacturer);
            }
          } else if (type === embLabels.PV_INVERTER) {
            if (data.manufacturer === "STANDARD PV INVERTER SUNSPEC TEMPLATE") {
              standardTemplate = data.manufacturer;
            } else {
              manufacturer.push(data.manufacturer);
            }
          } else {
            manufacturer.push(data.manufacturer);
          }
        });
        manufacturer.sort();

        if (standardTemplate !== "") {
          manufacturer.splice(0, 0, standardTemplate);
        }

        const modelType = [];
        json.assetCatalogue[0].assetList.forEach((data) => {
          if (modelType[data.manufacturer]) {
            modelType[data.manufacturer].push(data.communicationModel);
          } else {
            modelType[data.manufacturer] = [];
            modelType[data.manufacturer].push(data.communicationModel);
          }
          modelType[data.manufacturer].sort();
        });

        const uniqueManufactures = [...new Set(manufacturer)];

        const newAssetInfo = {
          ...assetInfo,
          assets: json.assetCatalogue[0].assetList,
          assetManufacturer: uniqueManufactures,
          assetModelType: modelType,
          manufacturerLoading: false,
          assetSelected: true,
        };
        setAssetInfo(newAssetInfo);
      })
      .catch(() => {
        setAssetInfo((prevState) => ({
          ...prevState,
          manufacturerLoading: false,
        }));
      });
  };

  const selectManufacturer = (e) => {
    const newAssetInfo = { ...assetInfo, selectedManufacturer: e.target.value };
    setAssetInfo(newAssetInfo);
  };

  const closeAssetSelectionBlock = () => {
    setAssetInfo((prevState) => ({
      ...prevState,
      assetSelected: false,
      selectedManufacturer: "",
    }));
  };

  const selectAssetFromList = (data) => {
    let selectedAsset = {};
    const assetDetailsData = { ...assetDetailsDisplay };
    const projectTypeValue = getProjectTypeValue()

    assetInfo.assets.forEach((assetData) => {
      if (parseInt(assetData.assetId) === parseInt(data)) {
        assetDetailsData.assetId = assetData.assetId;
        assetDetailsData.manufacturer = assetData.manufacturer;
        assetDetailsData.communicationModel = assetData.communicationModel;
        assetDetailsData.assetInfo = assetData.assetInfo;
        assetDetailsData.imageLink = assetData.imageLink;
        assetDetailsData.assetName = assetData.assetType;
        selectedAsset = assetData;
      }
    });
    CBAssetListDispatch({
      type: ACTIONS.SELECTED_ASSET,
      payload: {
        selectedAsset: selectedAsset,
      },
    });
    //updating storage_capcity_hr_value and corresponding_name_plate_capacity_value
    // const configDataUpdate = { ...configData.configData };
    // configDataUpdate["storage_capcity_hr_value"] =JSON.parse(configDataUpdate.storage_capcity_hr)[0];
    // configDataUpdate["corresponding_name_plate_capacity_value"] =  JSON.parse(configDataUpdate.corresponding_name_plate_capacity)['1.1'];
    // setConfigData((prevState) => ({ ...prevState, configData: configDataUpdate }));

    setAssetDetailsDisplay(assetDetailsData);

    setAssetInfo((prevState) => ({
      ...prevState,
      assetSelected: false,
      selectedManufacturer: "",
    }));
    const configDataNew = { ...configData.configData };
    const preFillDefault = defaultPreFillData[0];
    switch (selectedAsset.assetType) {
      //* GENSET

      case embLabels.GENERATOR:
        CBAssetListDispatch({
          type: ACTIONS.SELECT_DER_ASSET,
          payload: {
            index: 1,
            selectedAsset,
          },
        });
        if (selectedAsset.assetAttributes.length) {
          selectedAsset.assetAttributes.forEach((data, index) => {
            switch (selectedAsset.assetAttributes[index].type) {
              case "max_power":
                configDataNew.max_power =
                  selectedAsset.assetAttributes[index].value;
                break;
              case "starting_timer":
                configDataNew.starting_timer =
                  selectedAsset.assetAttributes[index].value;
                break;
              case "cooling_timer":
                configDataNew.cooling_timer =
                  selectedAsset.assetAttributes[index].value;
                break;
              case "minimum_running_period":
                configDataNew.minimum_running_period =
                  selectedAsset.assetAttributes[index].value;
                break;
              default:
                break;
            }
          });
          // CBAssetListDispatch({
          //   type:ACTIONS.ASSET_SELECTED_CONFIG,
          //   payload: {
          //     selectedAsset: selectedValues.selectedAsset,
          //     configData: configDataNew,
          //   },
          // });
        } else {
          configDataNew.max_power = defaultsMinMax?.maxPowerGensetDefault;
          configDataNew.min_power = "";
          configDataNew.gensetCO2 = defaultsMinMax?.gensetCO2Default;
          configDataNew.starting_timer = defaultsMinMax?.startUpGensetDefault;
          configDataNew.cooling_timer =
            defaultsMinMax?.coolingDurationGensetDefault;
          configDataNew.minimum_running_period =
            defaultsMinMax?.minRunningDurationGensetDefault;
        }
        if (selectedAsset.assetInfo === embLabels.SERIAL) {
          const newModbusId =
            preFillDefault?.Generator?.ipAddress?.split(".")[3];
          CBAssetListDispatch({
            type: ACTIONS.SELECT_SERIAL_ASSET,
            payload: {
              index: 1,
              modbusSlaveId: newModbusId,
            },
          });
        } else if (
          CBAssetList[1].communicationData.ipaddress === "" &&
          selectedAsset.assetInfo === embLabels.COMMUNICATING
        ) {
          CBAssetListDispatch({
            type: ACTIONS.SELECT_COMMUNICATING_ASSET,
            payload: {
              index: 1,
              preFillDefault: preFillDefault.Generator,
            },
          });
        }
        break;

      //* BESS

      case embLabels.BESS:
        CBAssetListDispatch({
          type: ACTIONS.SELECT_DER_ASSET,
          payload: {
            index: 2,
            selectedAsset,
          },
        });

        if (selectedAsset.assetAttributes.length) {
          selectedAsset.assetAttributes.forEach((data, index) => {
            switch (selectedAsset.assetAttributes[index].type) {
              case "max_power":
                if (
                  selectedAsset.assetAttributes?.[index]?.value.includes("[")
                ) {
                  try {
                    configDataNew.max_power = JSON.parse(
                      selectedAsset.assetAttributes?.[index]?.value
                    )?.[0];
                  } catch (error) {
                    configDataNew.max_power = "";
                  }
                } else {
                  configDataNew.max_power =
                    selectedAsset.assetAttributes[index].value;
                }

                break;
              case "starting_timer":
                configDataNew.starting_timer =
                  selectedAsset.assetAttributes[index].value;
                break;
              case "soc_max":
                configDataNew.soc_max =
                  selectedAsset.assetAttributes[index].value;
                break;
              case "capacity":
                configDataNew.capacity =
                  selectedAsset.assetAttributes[index].value;
                break;
              case "soc_min":
                configDataNew.soc_min =
                  selectedAsset.assetAttributes[index].value;
                break;
              case "soc_nominal_power":
                configDataNew.soc_nominal_power =
                  selectedAsset.assetAttributes[index].value;
                break;
              case "usableStorageCapacityHr":
                configDataNew.storage_capcity_hr =
                  selectedAsset?.assetAttributes[index]?.value;

                break;
              case "usableStorageCapacity":
                configDataNew.corresponding_name_plate_capacity =
                  selectedAsset?.assetAttributes[index]?.value;
                break;
              case "max_solar_power":
                configDataNew.max_solar_power =
                  selectedAsset?.assetAttributes[index]?.value;
                break;
              case "max_inverter_output_power":
                configDataNew.max_inverter_output_power =
                  selectedAsset?.assetAttributes[index]?.value;
              default:
                break;
            }
          });

          // CBAssetListDispatch({
          //   type:ACTIONS.ASSET_SELECTED_CONFIG,
          //   payload: {
          //     selectedAsset: selectedValues.selectedAsset,
          //     configData: configDataNew,
          //   },
          // });
        } else {
          configDataNew.max_power = defaultsMinMax?.maxPowerBessDefault;
          configDataNew.starting_timer = "";
          configDataNew.capacity = 0;
          configDataNew.soc_max = defaultsMinMax?.socMaxDefault;
          configDataNew.soc_min = defaultsMinMax?.socMinDefault;
          configDataNew.soc_nominal_power = 60;
        }

        if (selectedAsset.assetInfo === embLabels.SERIAL) {
          const newModbusId = preFillDefault?.BESS?.ipAddress.split(".")[3];
          CBAssetListDispatch({
            type: ACTIONS.SELECT_SERIAL_ASSET,
            payload: {
              index: 2,
              modbusSlaveId: newModbusId,
            },
          });
        } else if (CBAssetList[2].communicationData.ipaddress === "") {
          CBAssetListDispatch({
            type: ACTIONS.SELECT_COMMUNICATING_ASSET,
            payload: {
              index: 2,
              preFillDefault: preFillDefault.BESS,
            },
          });
        }
        break;

      //* CHP

      case embLabels.CHP:
        CBAssetListDispatch({
          type: ACTIONS.SELECT_DER_ASSET,
          payload: {
            index: 6,
            selectedAsset,
          },
        });

        if (selectedAsset.assetAttributes.length) {
          selectedAsset.assetAttributes.forEach((data, index) => {
            switch (selectedAsset.assetAttributes[index].type) {
              case "nominalPower":
                configDataNew.nominal_power =
                  selectedAsset.assetAttributes[index].value;
                break;
              default:
                break;
            }
          });
        } else {
          configDataNew.nominal_power = defaultsMinMax?.chpNominalPowerMax;
        }

        break;

      //* Hybrid

      case embLabels.HYBRID:
        CBAssetListDispatch({
          type: ACTIONS.SELECT_DER_ASSET,
          payload: {
            index: 7,
            selectedAsset,
          },
        });

        if (selectedAsset.assetAttributes.length) {
          selectedAsset.assetAttributes.forEach((_, index) => {
            switch (selectedAsset.assetAttributes[index].type) {
              case "max_capacity":
                configDataNew.max_capacity =
                  selectedAsset?.assetAttributes[index]?.value;
                if (selectedAsset.assetAttributes[index].value === "240") {
                  setBatteryRack("2");
                } else {
                  setBatteryRack("1");
                }
                break;
              case "capacity":
                configDataNew.capacity =
                  selectedAsset?.assetAttributes[index]?.value;
                break;
              case "max_solar_power":
                configDataNew.max_solar_power =
                  selectedAsset?.assetAttributes[index]?.value;
                break;
              case "max_inverter_output_power":
                configDataNew.max_inverter_output_power =
                  selectedAsset?.assetAttributes[index]?.value;
              case "usableStorageCapacityHr":
                configDataNew.storage_capcity_hr =
                  selectedAsset?.assetAttributes[index]?.value;

                break;
              case "usableStorageCapacity":
                configDataNew.corresponding_name_plate_capacity =
                  selectedAsset?.assetAttributes[index]?.value;
                // let configDataNew1 = { ...configData.configData };

                // configDataNew1["corresponding_name_plate_capacity_value"] = JSON.parse(selectedAsset.assetAttributes[index].value)['1.1'];
                // setConfigData((prevState) => ({ ...prevState, configData: configDataNew1 }));

                break;

              default:
                break;
            }
          });
        } else {
          configDataNew.max_capacity = 0;
          configDataNew.capacity = 0;
          configDataNew.max_solar_power = 0;
          configDataNew.max_inverter_output_power = 0;
          configDataNew.storage_capcity_hr = [];
          configDataNew.corresponding_name_plate_capacity = [];
        }

        if (
          +CBLoadList[0].configData.maximum_available_power >
          +configDataNew.max_inverter_output_power
        ) {
          CBLoadListDispatch({
            type: ACTIONS.DUPLICATE_LOAD_UPDATE,
            payload: {
              selectedLoad: 0,
            },
          });
        }

        CBAssetListDispatch({
          type: ACTIONS.DUPLICATE_DER_UPDATE,
          payload: {
            selectedAsset: 1,
          },
        });

        // if(CBLoadList[1].configData.
        //   max_power <
        //   ){
        //   CBAssetListDispatch({
        //     type: ACTIONS.DUPLICATE_DER_UPDATE,
        //     payload: {
        //       selectedAsset: 1,
        //     },
        //   });
        // }

        break;

      //* PV

      case embLabels.PV_inverter_assettype:
        // CBAssetListDispatch({
        //   type: ACTIONS.INDIVIDUAL_PV_ASSET_SELECT,
        //   payload: {
        //     selectedPv: PVDetails.selectedPv,
        //   },
        // });

        if (PVDetails.selectedPv === 3) {
          PVDetailsDispatch({
            type: ACTIONS.UPDATE_PV_CONFIG,
            payload: {
              selectedAsset,
              selectedPv: 3,
              defaultsMinMax: defaultsMinMax,
            },
          });
        }
        if (PVDetails.selectedPv === 4) {
          PVDetailsDispatch({
            type: ACTIONS.UPDATE_PV_CONFIG,
            payload: {
              selectedAsset,
              selectedPv: 4,
              defaultsMinMax: defaultsMinMax,
            },
          });
        }
        if (PVDetails.selectedPv === 5) {
          PVDetailsDispatch({
            type: ACTIONS.UPDATE_PV_CONFIG,
            payload: {
              selectedAsset,
              selectedPv: 5,
              defaultsMinMax: defaultsMinMax,
            },
          });
        }

        //     this.onChangePvSubSelection(this.state.selectSubPv);
        break;

      //* ev

      case embLabels.EV_CHARGING_STATION:
        CBEVListDispatch({
          type: ACTIONS.SELECT_EV_ASSET,
          payload: {
            index: selectedValues.selectedEv,
            selectedAsset,
          },
        });

        if (selectedAsset.assetAttributes.length) {
          selectedAsset.assetAttributes.forEach((attribute) => {
            switch (attribute.type) {
              case "max_power":
                configDataNew.maximum_available_power = attribute.value;
                break;
              default:
                break;
            }
          });
        } else {
          configDataNew.maximum_available_power = 10;
        }

        if (selectedAsset.assetInfo === embLabels.SERIAL) {
          const newModbusId =
            preFillDefault?.EV_charging_station?.ipAddress?.split(".")[3];

          CBEVListDispatch({
            type: ACTIONS.SELECT_SERIAL_ASSET,
            payload: {
              index: selectedValues.selectedEv,
              modbusSlaveId: newModbusId,
            },
          });
        } else if (
          CBEVList[selectedValues.selectedEv].communicationData.ipaddress === ""
        ) {
          CBAssetListDispatch({
            type: ACTIONS.SELECT_COMMUNICATING_ASSET,
            payload: {
              index: selectedValues.selectedEv,
              preFillDefault: preFillDefault.EV_charging_station,
            },
          });
        }

        break;

      case embLabels.CIRCUIT_BREAKER:
        if (CBAssetList.some((cbasset) => cbasset.uiData.CBSelected)) {
          CBAssetListDispatch({
            type: ACTIONS.SELECT_DER_CBASSET,
            payload: {
              index: selectedValues.selectedAssetCB,
              selectedAsset,
              cbCommunicationValues,
              CBValues,
              defaultValues: defaultPreFillData[0],
              count,
              switchboardType,
            },
          });
        }

        if (CBLoadList.some((cbload) => cbload.uiData.CBSelected)) {
          CBLoadListDispatch({
            type: ACTIONS.SELECT_LOAD_CBASSET,
            payload: {
              index: selectedValues.selectedLoadCB,
              selectedAsset,
              cbCommunicationValues,
              defaultValues: defaultPreFillData[0],
              count
            },
          });
        }
        if (CBEVList.some((cbev) => cbev.uiData.CBSelected)) {
          CBEVListDispatch({
            type: ACTIONS.SELECT_EV_CBASSET,
            payload: {
              index: selectedValues.selectedEvCB,
              selectedAsset,
              cbCommunicationValues,
            },
          });
        }
        break;

      case embLabels.CONTACTOR:
        if (CBAssetList.some((cbasset) => cbasset.uiData.CBSelected)) {
          CBAssetListDispatch({
            type: ACTIONS.SELECT_DER_CB_CONTACTOR_ASSET,
            payload: {
              index: selectedValues.selectedAssetCB,
              selectedAsset,
            },
          });
        }
        if (CBEVList.some((cbev) => cbev.uiData.CBSelected)) {
          CBEVListDispatch({
            type: ACTIONS.UPDATE_CB_CONTACTOR_CONFIG,
            payload: {
              index: selectedValues.selectedEvCB,
              selectedAsset: selectedAsset,
            },
          });
        }
        if(CBLoadList.some((cbload) => cbload.uiData.CBSelected)){
          CBLoadListDispatch({
            type: ACTIONS.UPDATE_CB_CONTACTOR_CONFIG,
            payload: {
              index: selectedValues.selectedLoadCB,
              selectedAsset: selectedAsset,
            },
          });
        }

        break;

      case embLabels.POWER_METER:
        if (
          CBAssetList.some(
            (cbasset) =>
              cbasset.uiData.pmSelected || cbasset.uiData.existingPmSelected
          )
        ) {
          if (detailsBlock.existingPmAssetDetailsBlockGrid) {
            CBAssetListDispatch({
              type: ACTIONS.SELECT_DER_EXISTING_PMASSET,
              payload: {
                index: selectedValues.selectedAssetPM,
                selectedAsset,
                defaultValues: defaultPreFillData[0],
                existingPMBlock: detailsBlock.existingPmAssetDetailsBlockGrid,
              },
            });
          } else {
            CBAssetListDispatch({
              type: ACTIONS.SELECT_DER_PMASSET,
              payload: {
                index: selectedValues.selectedAssetPM,
                selectedAsset,
                defaultValues: defaultPreFillData[0],
              },
            });
          }
        }
        if (CBEVList.some((cbev) => cbev.uiData.pmSelected)) {
          CBEVListDispatch({
            type: ACTIONS.SELECT_EV_PMASSET,
            payload: {
              index: selectedValues.selectedEvPM,
              selectedAsset,
              preFillDefault: preFillDefault["Power Meter"],
            },
          });
        }
        if(CBLoadList.some((cbLoad) => cbLoad.uiData.pmSelected)){
          CBLoadListDispatch({
            type: ACTIONS.SELECT_LOAD_PMASSET,
            payload: {
              index: selectedValues.selectedLoad,
              selectedAsset,
              preFillDefault: preFillDefault["Power Meter"],
            },
          })
        }
        break;
      default:
        break;
    }

    const cbCofigDataNew = {
      ...configData.cbConfigData,
    };
    if (
      [embLabels.GRID_CONNECTED,embLabels.ISLANDABLE].includes(siteType) &&
      clientType === embLabels.GENERIC_PROJECT &&
      selectedAsset.assetType === embLabels.CIRCUIT_BREAKER &&
      projectTypeValue === "MGaaS IEC" &&
      (selectedAsset?.assetInfo === "Serial" ||
        selectedAsset?.assetInfo === "Communicating")
    ) {
      cbCofigDataNew.motorized = true;
    }

    setConfigData((prevState) => ({
      ...prevState,
      configData: configDataNew,
      cbConfigData: cbCofigDataNew,
    }));
    if (siteType === "GENSET_PVBESS_A2E") {
      // updating storage_capcity_hr_value and corresponding_name_plate_capacity_value

      setNamePlateEnergy(JSON.parse(configDataNew.storage_capcity_hr)[0]);
      configDataNew["corresponding_name_plate_capacity_value"] = JSON.parse(
        configDataNew.corresponding_name_plate_capacity
      )["1.1"];
      setConfigData((prevState) => ({
        ...prevState,
        configData: configDataNew,
      }));
    }

    localStorage.setItem("deploymentReady", false);
  };

  const assetDetailsReset = () => {
    CBAssetListDispatch({
      type: ACTIONS.CLOSEDETAILS_ASSET,
    });
    CBLoadListDispatch({
      type: ACTIONS.CLOSEDETAILS_LOAD,
    });
    CBEVListDispatch({
      type: ACTIONS.CLOSEDETAILS_EV,
    });
    const newAssetDetails = { ...detailsBlock };
    Object.keys(newAssetDetails).forEach(
      (block) => (newAssetDetails[block] = false)
    );
    const configData = {
      name: "",
      description: "",
      min_power: "",
      max_power: "",
    };
    const cbConfigData = {
      name: "",
      description: "",
    };
    const contactorConfigData = {
      name: "",
      description: "",
    };
    const newAssetDetailsDisplay = { ...assetDetailsDisplay };
    Object.keys(newAssetDetailsDisplay).forEach(
      (details) => (newAssetDetailsDisplay[details] = "")
    );
    setDetailsBlock(newAssetDetails);
    setConfigData((prevState) => ({
      ...prevState,
      configData,
      cbConfigData,
      contactorConfigData,
    }));
    setAssetDetailsDisplay(newAssetDetailsDisplay);
  };

  const selectedValuesHandler = (
    index,
    selectedAssetValue,
    selectedAssetBlcok
  ) => {
    const newSelectedValuess = { ...selectedValues };
    Object.keys(newSelectedValuess).forEach(
      (block) => (newSelectedValuess[block] = "")
    );

    if (selectedAssetBlcok !== "hardWiredCbDetailsBlockGrid") {
      setSelectedValues({
        ...newSelectedValuess,
        [selectedAssetValue]: index,
      });
    }
    setDetailsBlock((prevState) => ({
      ...prevState,
      [selectedAssetBlcok]: true,
    }));
  };

  const selectedSubPvHandler = (index) =>
    setSelectedValues((prevState) => ({
      ...prevState,
      selectSubPv: index,
    }));

  const selectedAssetHandler = (type, index) => {
    let assetArrayIn;
    switch (type) {
      case embLabels.ASSETS:
        assetArrayIn = [...CBAssetList];
        break;
      case embLabels.EV_CHARGING_STATION:
        assetArrayIn = [...CBEVList];
        break;
      default:
        break;
    }

    const assetDetailsData = { ...assetDetailsDisplay };
    if (assetArrayIn[index].assetDetails !== undefined) {
      if (assetArrayIn[index].assetDetails.length) {
        assetDetailsData.assetId =
          assetArrayIn[index]?.assetDetails[0]?.assetId;
        assetDetailsData.manufacturer =
          assetArrayIn[index]?.assetDetails[0]?.manufacturer;
        assetDetailsData.communicationModel =
          assetArrayIn[index]?.assetDetails[0]?.communicationModel;
        assetDetailsData.assetInfo =
          assetArrayIn[index]?.assetDetails[0]?.assetInfo;
        assetDetailsData.assetName =
          assetArrayIn[index]?.assetDetails[0]?.assetName;
        assetDetailsData.imageLink =
          assetArrayIn[index]?.assetDetails[0]?.imageLink;
        setAssetDetailsDisplay(assetDetailsData);
      } else {
        const newAssetDetailsDisplay = { ...assetDetailsDisplay };
        Object.keys(newAssetDetailsDisplay).forEach(
          (details) => (newAssetDetailsDisplay[details] = "")
        );
        setAssetDetailsDisplay(newAssetDetailsDisplay);
      }
    }
  };

  const selectedAssetHandlerPv = (selectedPv, selectSubPv) => {
    let indexValue = parseInt(selectSubPv - 1);
    let newPvSubData = [];

    switch (selectedPv) {
      case 3:
        newPvSubData = [...PVDetails.pvset1Data];
        break;
      case 4:
        newPvSubData = [...PVDetails.pvset2Data];
        break;
      case 5:
        newPvSubData = [...PVDetails.pvset3Data];
        break;
      default:
        break;
    }

    const assetDetailsData = { ...assetDetailsDisplay };

    if (newPvSubData[indexValue].assetDetails !== undefined) {
      if (newPvSubData[indexValue].assetDetails.length) {
        assetDetailsData.assetId =
          newPvSubData[indexValue]?.assetDetails[0]?.assetId;
        assetDetailsData.manufacturer =
          newPvSubData[indexValue]?.assetDetails[0]?.manufacturer;
        assetDetailsData.communicationModel =
          newPvSubData[indexValue]?.assetDetails[0]?.communicationModel;
        assetDetailsData.assetInfo =
          newPvSubData[indexValue]?.assetDetails[0]?.assetInfo;
        assetDetailsData.assetName =
          newPvSubData[indexValue]?.assetDetails[0]?.assetName;
        assetDetailsData.imageLink =
          newPvSubData[indexValue]?.assetDetails[0]?.imageLink;
        setAssetDetailsDisplay(assetDetailsData);
      } else {
        const newAssetDetailsDisplay = { ...assetDetailsDisplay };
        Object.keys(newAssetDetailsDisplay).forEach(
          (details) => (newAssetDetailsDisplay[details] = "")
        );
        setAssetDetailsDisplay(newAssetDetailsDisplay);
      }
    }
  };

  //* GET CONFIG DATA

  const assetConfigDataHandler = (index) => {
    const CBASSETLISTNEW = _.cloneDeep(CBAssetList);
    const defaultsData = {};
    for (let defaultData of defaultPreFillData[2]) {
      defaultsData[defaultData["param"]] = defaultData["value"];
    }

    if (CBASSETLISTNEW[index].configData.name) {
      let assetConfigDataNew = {};
      if (CBASSETLISTNEW[index].assetType === embLabels.UTILITY) {
        assetConfigDataNew = Object.assign({}, configData.utilityConfigData);
        assetConfigDataHandler.maximum_available_power = 300;
      } else if (CBASSETLISTNEW[index].assetType === embLabels.GENSET) {
        assetConfigDataNew = Object.assign({}, configData.gensetConfigData);
        // assetConfigDataNew.max_power = defaultsData.maxPowerGensetDefault;
      } else if (CBASSETLISTNEW[index].assetType === embLabels.BESS) {
        assetConfigDataNew = Object.assign({}, configData.batteryConfigData);
        assetConfigDataNew.soc_max = defaultPreFillData[0]?.BESS?.soc_max;
        assetConfigDataNew.soc_min = defaultPreFillData[0]?.BESS?.soc_min;
        assetConfigDataNew.soc_nominal_power =
          defaultPreFillData[0]?.BESS?.soc_nominal_power;
      } else if (CBASSETLISTNEW[index].assetType === embLabels.CHP) {
        assetConfigDataNew = Object.assign({}, configData.chpConfigData);
      } else if (CBASSETLISTNEW[index].assetType === embLabels.HYBRID) {
        assetConfigDataNew = Object.assign({}, configData.hybridConfigData);
      } else {
        assetConfigDataNew = Object.assign({}, configData.sampleConfigData);
      }

      assetConfigDataNew = CBASSETLISTNEW[index]?.configData;
      assetConfigDataNew.name = CBASSETLISTNEW[index]?.configData?.name;
      assetConfigDataNew.description =
        CBASSETLISTNEW[index]?.configData?.description;
      // assetConfigDataNew.max_power=CBASSETLISTNEW[1].
      if (CBASSETLISTNEW[index].assetType === embLabels.GENSET) {
        CBASSETLISTNEW[1].selectedAssetData.assetAttributes &&
          CBASSETLISTNEW[1].selectedAssetData.assetAttributes.forEach(
            (assetAttribute) => {
              if (assetAttribute.type === "gensetPower") {
                assetConfigDataNew.max_power = assetAttribute.value;
              }
            }
          );
        // assetConfigDataNew.max_power = defaultsData.maxPowerGensetDefault;
      }
      // assetConfigDataNew.max_power =
      //   CBASSETLISTNEW[index].configData.maximum_available_power ||
      //   CBASSETLISTNEW[index].configData.max_power;
      // if (CBASSETLISTNEW[index].assetType === embLabels.BESS){
      //   assetConfigDataNew.capacity = CBAssetList[index].assetDetails[0]?.assetAttributes.find(
      //     attribute => attribute.type === "capacity"
      //   )?.value;
      //   assetConfigDataNew.max_power =  CBAssetList[index].assetDetails[0]?.assetAttributes.find(
      //     attribute => attribute.type === "power_max"
      //   )?.value;
      // }
      // if (CBASSETLISTNEW[index].assetType === embLabels.GENSET){
      //   assetConfigDataNew.max_power =  CBAssetList[index].assetDetails[0]?.assetAttributes.find(
      //     attribute => attribute.type === "min_power"
      //   )?.value;
      // }
      // if(siteType === 'GENSET_PVBESS_A2E'){
      //   assetConfigDataNew.max_power = CBAssetList[7].assetDetails[0]?.assetAttributes.find(
      //     attribute => attribute.type === "minGensetPower"
      //   )?.value;
      // }
      //  assetConfigDataNew.max_power = defaultsData.max_power;

      const derHistory = JSON.parse(localStorage.getItem("derHistory"));

      if (index === 0) {
        Object.entries(derHistory[embLabels.UTILITY]).forEach(
          ([key, value]) => {
            assetConfigDataNew[key] = value;
          }
        );
      } else if (index === 1) {
        Object.entries(derHistory[embLabels.GENSET]).forEach(([key, value]) => {
          assetConfigDataNew[key] = value;
        });
      } else if (index === 2) {
        Object.entries(derHistory[embLabels.BESS]).forEach(([key, value]) => {
          assetConfigDataNew[key] = value;
        });
      } else if (index === 6) {
        Object.entries(derHistory[embLabels.CHP]).forEach(([key, value]) => {
          assetConfigDataNew[key] = value;
        });
      } else if (index === 7) {
        Object.entries(derHistory[embLabels.HYBRID]).forEach(([key, value]) => {
          assetConfigDataNew[key] = value;
        });
      }

      setConfigData((prevState) => ({
        ...prevState,
        configData: assetConfigDataNew,
      }));
    } else {
      const assetConfigDataNew = { ...configData.sampleConfigData };
      assetConfigDataNew.name = "";
      assetConfigDataNew.description = "";
      setConfigData((prevState) => ({
        ...prevState,
        configData: assetConfigDataNew,
      }));
    }
  };

  const loadConfigDataHandler = (index) => {
    const loadArray = [...CBLoadList];
    const CBASSETLISTNEW = _.cloneDeep(CBAssetList);
    if (loadArray[index].configData.name) {
      let cbConfigDataNew = { ...configData.loadConfigData };
      cbConfigDataNew.name = loadArray[index]?.configData?.name;
      cbConfigDataNew.description = loadArray[index]?.configData?.description;
      cbConfigDataNew.description = loadArray[index]?.configData?.description;
      cbConfigDataNew.maximum_available_power =
        loadArray[index].configData.maximum_available_power;
      CBASSETLISTNEW[1].selectedAssetData.assetAttributes &&
        CBASSETLISTNEW[1].selectedAssetData.assetAttributes.forEach(
          (assetAttribute) => {
            if (assetAttribute.type === "loadPower"&&assetAttribute.value!=undefined) {
              cbConfigDataNew.maximum_available_power = assetAttribute.value;
              CBLoadListDispatch({
                type: ACTIONS.UPDATE_MAXIMUM_LOAD_POWER,
                payload: {
                  index: 0,
                  loadPower: assetAttribute.value,
                },
              });
            }
          }
        );
      // cbConfigDataNew.maximum_available_power =
      //   loadArray[index].configData.maximum_available_power;
      //   if(siteType === 'GENSET_PVBESS_A2E'){
      //      cbConfigDataNew.maximum_available_power =  CBAssetList[7].assetDetails[0]?.assetAttributes.find(
      //   attribute => attribute.type === "loadPower"
      // )?.value;
      //   }

      let derStatus = JSON.parse(localStorage.getItem("derStatus"));
      let loadHistory = [];
      if (derStatus[4] === true) {
        loadHistory = JSON.parse(localStorage.getItem("loadHistory"));
        if (loadHistory[index] !== undefined && loadHistory[index] !== null) {
          Object.entries(loadHistory[index]).forEach(([key, value]) => {
            cbConfigDataNew[key] = value;
          });
        }
      }

      setConfigData((prevState) => ({
        ...prevState,
        configData: cbConfigDataNew,
      }));
    } else {
      let cbConfigDataNew = { ...configData.loadConfigData };
      cbConfigDataNew.name = "";
      cbConfigDataNew.description = "";
      cbConfigDataNew.maximum_available_power = 0;
      setConfigData((prevState) => ({
        ...prevState,
        configData: cbConfigDataNew,
      }));
    }
  };

  const evConfigDataHandler = (index) => {
    if (CBEVList[index].configData.name) {
      let evConfigDataNew = { ...configData.evConfigData };
      evConfigDataNew = CBEVList[index]?.configData;
      evConfigDataNew.name = CBEVList[index]?.configData?.name;
      evConfigDataNew.description = CBEVList[index]?.configData?.description;
      evConfigDataNew.maximum_available_power =
        CBEVList[index]?.configData?.maximum_available_power;

      let derHistory = JSON.parse(localStorage.getItem("derHistory"));

      Object.entries(derHistory["EV"]).forEach(([key, value]) => {
        evConfigDataNew[key] = value;
      });

      setConfigData((prevState) => ({
        ...prevState,
        configData: evConfigDataNew,
      }));
    } else {
      const evConfigDataNew = { ...configData.evConfigData };
      evConfigDataNew.name = "";
      evConfigDataNew.description = "";
      setConfigData((prevState) => ({
        ...prevState,
        configData: evConfigDataNew,
      }));
    }
  };

  //* GET CB CONFIG DATA

  const cbConfigDataHandler = (index) => {
    const CBASSETLISTNEW = _.cloneDeep(CBAssetList);
    if (CBASSETLISTNEW[index]) {
      const cbConfigDataNew = { ...configData.sampleCbConfigData };
      if (siteType === embLabels.PV_HYBRIDIZATION) {
        cbConfigDataNew.name = CBASSETLISTNEW[index]?.powerMeterData?.name;
        cbConfigDataNew.description =
          CBASSETLISTNEW[index]?.powerMeterData?.description;
      } else if (
        [
          embLabels.ISLANDABLE,
          embLabels.GRID_CONNECTED,
          embLabels.OFF_GRID,
          embLabels.GENSET_PVBESS_A2E,
        ].includes(siteType)
      ) {
        cbConfigDataNew.name = CBASSETLISTNEW[index]?.cbConfigData?.name;
        cbConfigDataNew.description =
          CBASSETLISTNEW[index]?.cbConfigData?.description;
        cbConfigDataNew.motorized =
          CBASSETLISTNEW[index]?.cbConfigData?.motorized;
          cbConfigDataNew.contactor =
          CBASSETLISTNEW[index]?.cbConfigData?.contactor;
      } else {
        //* do nothing
      }

      const derStatus = JSON.parse(localStorage.getItem("derStatus"));
      let cbAssetHistory = [];
      if (derStatus[6]) {
        if (localStorage.getItem("cbAssetHistory") === "") {
          cbAssetHistory = [];
        } else {
          cbAssetHistory = JSON.parse(localStorage.getItem("cbAssetHistory"));
        }
        if (
          cbAssetHistory[index] !== undefined &&
          cbAssetHistory[index] !== null
        ) {
          Object.entries(cbAssetHistory[index]).forEach(([key, value]) => {
            cbConfigDataNew[key] = value;
          });
        }
      }
      const newcontactorConfigData = {
        ...configData.sampleContactorConfigData,
      };
      if ([embLabels.GRID_CONNECTED,embLabels.ISLANDABLE].includes(siteType)) {
        newcontactorConfigData.name =
          CBASSETLISTNEW[index]?.contactorConfigData?.name;
        newcontactorConfigData.description =
          CBASSETLISTNEW[index]?.contactorConfigData?.description;
      }

      setConfigData((prevState) => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
        contactorConfigData: newcontactorConfigData,
      }));
    } else {
      const cbConfigDataNew = { ...configData.sampleCbConfigData };
      if (siteType === embLabels.PV_HYBRIDIZATION) {
        cbConfigDataNew.name = "";
        cbConfigDataNew.description = "";
      } else if (
        [
          embLabels.ISLANDABLE,
          embLabels.GRID_CONNECTED,
          embLabels.OFF_GRID,
        ].includes(siteType)
      ) {
        cbConfigDataNew.name = "";
        cbConfigDataNew.description = "";
        cbConfigDataNew.motorized = true;
        cbConfigDataNew.contactor = false;
      } else {
        //* do nothing
      }
      setConfigData((prevState) => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    }
  };

  const newSBCbConfigDataHandler = (index) => {
    const CBASSETLISTNEW = _.cloneDeep(CBAssetList);
    if (CBASSETLISTNEW[index]) {
      const cbConfigDataNew = { ...configData.sampleCbConfigData };

      cbConfigDataNew.name = CBASSETLISTNEW[index]?.cbConfigData?.name;
      cbConfigDataNew.description =
        CBASSETLISTNEW[index]?.cbConfigData?.description;
      cbConfigDataNew.motorized =
        CBASSETLISTNEW[index]?.cbConfigData?.motorized;
      cbConfigDataNew.contactor =
        CBASSETLISTNEW[index]?.cbConfigData?.contactor;

      const derStatus = JSON.parse(localStorage.getItem("derStatus"));
      let cbAssetHistory = [];
      if (derStatus[6]) {
        if (localStorage.getItem("cbAssetHistory") === "") {
          cbAssetHistory = [];
        } else {
          cbAssetHistory = JSON.parse(localStorage.getItem("cbAssetHistory"));
        }
        if (
          cbAssetHistory[index] !== undefined &&
          cbAssetHistory[index] !== null
        ) {
          Object.entries(cbAssetHistory[index]).forEach(([key, value]) => {
            cbConfigDataNew[key] = value;
          });
        }
      }

      setConfigData((prevState) => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    } else {
      const cbConfigDataNew = { ...configData.sampleCbConfigData };

      cbConfigDataNew.name = "";
      cbConfigDataNew.description = "";
      cbConfigDataNew.motorized = true;
      cbConfigDataNew.contactor = false;

      setConfigData((prevState) => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    }
  };

  const loadCbConfigDataHandler = (index) => {
    const loadArray = [...CBLoadList];
    const assetDetailsData = { ...assetDetailsDisplay };
    if (loadArray[index]) {
      let cbConfigDataNew = { ...configData.cbConfigData };
      cbConfigDataNew.name = loadArray[index]?.cbConfigData?.name;
      cbConfigDataNew.description = loadArray[index]?.cbConfigData?.description;
      cbConfigDataNew.motorized = loadArray[index]?.cbConfigData?.motorized;

      let derStatus = JSON.parse(localStorage.getItem("derStatus"));
      let cbLoadHistory = [];
      if (derStatus[6] === true) {
        if (localStorage.getItem("cbLoadHistory") === "") {
          cbLoadHistory = [];
        } else {
          cbLoadHistory = JSON.parse(localStorage.getItem("cbLoadHistory"));
        }

        if (
          cbLoadHistory[index] !== undefined &&
          cbLoadHistory[index] !== null
        ) {
          Object.entries(cbLoadHistory[index]).forEach(([key, value]) => {
            cbConfigDataNew[key] = value;
          });
        }
      }

      setAssetDetailsDisplay({
        ...assetDetailsData,
        communicationModel:
          CBLoadList[index]?.cbConfigData?.cbAssetDetails[0]
            ?.communicationModel,
      });

      setConfigData((prevState) => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
        contactorConfigData: CBLoadList?.[index]?.contactorConfigData,
      }));
    } else {
      let cbConfigDataNew = { ...configData.cbConfigData };
      cbConfigDataNew.name = "";
      cbConfigDataNew.description = "";
      cbConfigDataNew.motorized = true;
      setConfigData((prevState) => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    }
  };

  const evCbConfigDataHandler = (index) => {
    const cbArray = [...CBEVList];
    for(let item of jsonData){
      if(projectTypeValue=="MGaaS IEC"){
      if(item?.componentIdx=="Contactor_60"&&cbArray[index]?.assetType=="EV_charging_station"){
        setIsContactorEnabled(true);
        const configDataNew = {...configData}
        configDataNew.cbConfigData.contactor = true;
        setConfigData((prevState) => ({ ...prevState, configData: configDataNew }))
        }
      }
    }
    if (cbArray[index]) {
      let cbConfigDataNew = { ...configData.sampleCbConfigData };
      cbConfigDataNew.name = cbArray[index]?.cbConfigData?.name;
      cbConfigDataNew.description = cbArray[index]?.cbConfigData?.description;
      cbConfigDataNew.motorized = cbArray[index]?.cbConfigData?.motorized;
      cbConfigDataNew.contactor = cbArray[index]?.cbConfigData?.contactor;

      let derStatus = JSON.parse(localStorage.getItem("derStatus"));
      let cbEvHistory = [];
      if (derStatus[6]) {
        if (localStorage.getItem("cbEvHistory") === "") {
          cbEvHistory = [];
        } else {
          cbEvHistory = JSON.parse(localStorage.getItem("cbEvHistory"));
        }

        if (cbEvHistory[index] !== undefined && cbEvHistory[index] !== null) {
          Object.entries(cbEvHistory[index]).forEach(([key, value]) => {
            cbConfigDataNew[key] = value;
          });
        }
      }
      setConfigData((prevState) => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
        contactorConfigData: CBEVList?.[0]?.contactorConfigData,
      }));
    } else {
      let cbConfigDataNew = { ...configData.sampleCbConfigData };
      cbConfigDataNew.name = "";
      cbConfigDataNew.description = "";
      cbConfigDataNew.motorized = true;
      setConfigData((prevState) => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    }
  };

  const pmConfigDataHandler = (index) => {
    const CBASSETLISTNEW = _.cloneDeep(CBAssetList);
    if (CBASSETLISTNEW[index]) {
      const cbConfigDataNew = { ...configData.powerMeterData };
      cbConfigDataNew.name = CBASSETLISTNEW[index]?.powerMeterData?.name;
      cbConfigDataNew.description =
        CBASSETLISTNEW[index]?.powerMeterData?.description;

      const derStatus = JSON.parse(localStorage.getItem("derStatus"));
      let pmAssetHistory = [];
      if (derStatus[8]) {
        if (localStorage.getItem("pmAssetHistory") === "") {
          pmAssetHistory = [];
        } else {
          pmAssetHistory = JSON.parse(localStorage.getItem("pmAssetHistory"));
        }
        if (
          pmAssetHistory[index] !== undefined &&
          pmAssetHistory[index] !== null
        ) {
          Object.entries(pmAssetHistory[index]).forEach(([key, value]) => {
            cbConfigDataNew[key] = value;
          });
        }
      }

      setConfigData((prevState) => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    } else {
      const cbConfigDataNew = { ...configData.sampleCbConfigData };
      cbConfigDataNew.name = "";
      cbConfigDataNew.description = "";
      setConfigData((prevState) => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    }
  };

  const existingPmConfigDataHandler = (index) => {
    const CBASSETLISTNEW = _.cloneDeep(CBAssetList);
    if (CBASSETLISTNEW[index]) {
      const cbConfigDataNew = { ...configData.existingPowerMeterData };
      cbConfigDataNew.name =
        CBASSETLISTNEW[index]?.existingPowerMeterData?.name;
      cbConfigDataNew.description =
        CBASSETLISTNEW[index]?.existingPowerMeterData?.description;

      const derStatus = JSON.parse(localStorage.getItem("derStatus"));
      let pmAssetHistory = [];
      if (derStatus[8]) {
        if (localStorage.getItem("pmAssetHistory") === "") {
          pmAssetHistory = [];
        } else {
          pmAssetHistory = JSON.parse(localStorage.getItem("pmAssetHistory"));
        }
        if (
          pmAssetHistory[index] !== undefined &&
          pmAssetHistory[index] !== null
        ) {
          Object.entries(pmAssetHistory[index]).forEach(([key, value]) => {
            cbConfigDataNew[key] = value;
          });
        }
      }

      setConfigData((prevState) => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    } else {
      const cbConfigDataNew = { ...configData.sampleCbConfigData };
      cbConfigDataNew.name = "";
      cbConfigDataNew.description = "";
      setConfigData((prevState) => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    }
  };

  const loadpmConfigDataHandler = (index) => {
    const CBLoadListNew = _.cloneDeep(CBLoadList);
    if (CBLoadListNew[index]) {
      const cbConfigDataNew = { ...configData.powerMeterData };
      cbConfigDataNew.name = CBLoadListNew[index]?.powerMeterData?.name;
      cbConfigDataNew.description =
        CBLoadListNew[index]?.powerMeterData?.description;

      const derStatus = JSON.parse(localStorage.getItem("derStatus"));
      let pmEvHistory = [];
      if (derStatus[8]) {
        if (localStorage.getItem("pmEvHistory") === "") {
          pmEvHistory = [];
        } else {
          pmEvHistory = JSON.parse(localStorage.getItem("pmEvHistory"));
        }
        if (pmEvHistory[index] !== undefined && pmEvHistory[index] !== null) {
          Object.entries(pmEvHistory[index]).forEach(([key, value]) => {
            cbConfigDataNew[key] = value;
          });
        }
      }

      setConfigData((prevState) => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    } else {
      const cbConfigDataNew = { ...configData.sampleCbConfigData };
      cbConfigDataNew.name = "";
      cbConfigDataNew.description = "";
      setConfigData((prevState) => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    }
  }

  const evpmConfigDataHandler = (index) => {
    const CBEVListNEW = _.cloneDeep(CBEVList);
    if (CBEVListNEW[index]) {
      const cbConfigDataNew = { ...configData.powerMeterData };
      cbConfigDataNew.name = CBEVListNEW[index]?.powerMeterData?.name;
      cbConfigDataNew.description =
        CBEVListNEW[index]?.powerMeterData?.description;

      const derStatus = JSON.parse(localStorage.getItem("derStatus"));
      let pmEvHistory = [];
      if (derStatus[8]) {
        if (localStorage.getItem("pmEvHistory") === "") {
          pmEvHistory = [];
        } else {
          pmEvHistory = JSON.parse(localStorage.getItem("pmEvHistory"));
        }
        if (pmEvHistory[index] !== undefined && pmEvHistory[index] !== null) {
          Object.entries(pmEvHistory[index]).forEach(([key, value]) => {
            cbConfigDataNew[key] = value;
          });
        }
      }

      setConfigData((prevState) => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    } else {
      const cbConfigDataNew = { ...configData.sampleCbConfigData };
      cbConfigDataNew.name = "";
      cbConfigDataNew.description = "";
      setConfigData((prevState) => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    }
  };

  //* change handlers

  const handleUtilityChange = (e) => {
    const dataConfig = { ...configData.configData };
   const onlyNumber = /^(\d+(\.\d+)?|)$/;
    switch (e.target.name) {
      case "utilityCO2":
        if (!isNaN(e.target.value) && e.target.value >= 0) {
          dataConfig[e.target.name] = e.target.value;
          setConfigData((prevState) => ({
            ...prevState,
            configData: dataConfig,
          }));

          assetEditStatusUpdateDER(e, embLabels.UTILITY, true);
        }
        break;
      case "maximum_available_power":
        if (onlyNumber.test(e.target.value)) {
          dataConfig[e.target.name] = e.target.value;
          setConfigData((prevState) => ({
            ...prevState,
            configData: dataConfig,
          }));

          assetEditStatusUpdateDER(e, embLabels.UTILITY, true);
        }
        break;
      case "externalId":
        const { value } = e.target;
        if (value.trim() !== "") {
          if (alphanumericRegWithHyphen.test(value)) {
            setGsxExternalId((prevState) => ({
              ...prevState,
              utilityExternalId: value,
            }));
          }
          assetEditStatusUpdateDER(e, embLabels.UTILITY, true);
        } else {
          setGsxExternalId((prevState) => ({
            ...prevState,
            utilityExternalId: "",
          }));
        }

        break;
      default:
        dataConfig[e.target.name] = e.target.value;
        if (isValidName(e.target.value)) {
          setConfigData((prevState) => ({
            ...prevState,
            configData: dataConfig,
          }));

          assetEditStatusUpdateDER(e, embLabels.UTILITY, true);
        } else {
          setConfigData((prevState) => ({
            ...prevState,
            configData: dataConfig,
          }));
        }
    }
  };

  const handleGensetChange = (e) => {
    const dataConfig = { ...configData.configData };
   const onlyNumber =/^(\d+(\.\d+)?|)$/;
    if (e.target.name === "gensetCO2") {
      if (!isNaN(e.target.value) && e.target.value >= 0) {
        dataConfig[e.target.name] = e.target.value;
        setConfigData((prevState) => ({
          ...prevState,
          configData: dataConfig,
        }));
        assetEditStatusUpdateDER(e, embLabels.GENSET, true);
      }
    } else if (
      [
        "starting_timer",
        "cooling_timer",
        "minimum_running_period",
        "max_power",
      ].includes(e.target.name)
    ) {
      if (onlyNumber.test(e.target.value)) {
        if (parseInt(e.target.value) === 0) {
          dataConfig[e.target.name] = e.target.value;
        } else {
          dataConfig[e.target.name] = parseInt(e.target.value)
            ? parseInt(e.target.value)
            : "";
        }
        setConfigData((prevState) => ({
          ...prevState,
          configData: dataConfig,
        }));
        assetEditStatusUpdateDER(e, embLabels.GENSET, true);
      }
    } else if (e.target.name === "externalId") {
      const { value } = e.target;
      if (value.trim() !== "") {
        if (alphanumericRegWithHyphen.test(value)) {
          setGsxExternalId((prevState) => ({
            ...prevState,
            gensetExternalId: value,
          }));
        }
        assetEditStatusUpdateDER(e, embLabels.GENSET, true);
      } else {
        setGsxExternalId((prevState) => ({
          ...prevState,
          gensetExternalId: "",
        }));
      }
    } else {
      dataConfig[e.target.name] = e.target.value;

      setConfigData((prevState) => ({
        ...prevState,
        configData: dataConfig,
      }));
      assetEditStatusUpdateDER(e, embLabels.GENSET, true);
    }
  };

  const handleBatteryChange = (e) => {
    const dataConfig = { ...configData.configData };
    const onlyNumber =/^(\d+(\.\d+)?|)$/;
    if (
      [
        "max_power",
        "capacity",
        "soc_max",
        "soc_min",
        "soc_nominal_power",
      ].includes(e.target.name)
    ) {
      if (onlyNumber.test(e.target.value)) {
        dataConfig[e.target.name] = e.target.value;
        assetEditStatusUpdateDER(e, embLabels.BESS, true);
      } else {
         dataConfig[e.target.name] = e.target.value;
      }
      setConfigData((prevState) => ({ ...prevState, configData: dataConfig }));
    } else if (e.target.name === "externalId") {
      const { value } = e.target;
      if (value.trim() !== "") {
        if (alphanumericRegWithHyphen.test(value)) {
          setGsxExternalId((prevState) => ({
            ...prevState,
            batteryExternalId: value,
          }));
        }
        assetEditStatusUpdateDER(e, embLabels.BESS, true);
      } else {
        setGsxExternalId((prevState) => ({
          ...prevState,
          batteryExternalId: "",
        }));
      }
    }else if(e.target.name === "deviceName"){
      dataConfig[e.target.name] = e.target.value.toLowerCase();

      setConfigData((prevState) => ({
        ...prevState,
        configData: dataConfig,
      }));

      assetEditStatusUpdateDER(e, embLabels.BESS, true);
    }
     else {
      dataConfig[e.target.name] = e.target.value;

      setConfigData((prevState) => ({
        ...prevState,
        configData: dataConfig,
      }));

      assetEditStatusUpdateDER(e, embLabels.BESS, true);
    }
  };

  const handleChpChange = (e) => {
    let dataConfig = { ...configData.configData };
    const onlyNumber =/^(\d+(\.\d+)?|)$/;
    switch (e.target.name) {
      case "nominal_power":
        if (onlyNumber.test(e.target.value)) {
          dataConfig[e.target.name] = e.target.value;

          assetEditStatusUpdateDER(e, embLabels.CHP, true);
        } else {
          dataConfig[e.target.name] = configData?.configData?.nominal_power;
        }
        setConfigData((prevState) => ({
          ...prevState,
          configData: dataConfig,
        }));
        break;

      default:
        dataConfig[e.target.name] = e.target.value;
        // if (isValidName(e.target.value)) {
          setConfigData((prevState) => ({
            ...prevState,
            configData: dataConfig,
          }));
          assetEditStatusUpdateDER(e, embLabels.CHP, true);
        // }
    }
  };

  const handleHybridChange = (e) => {
    const dataConfig = { ...configData.configData };
    const onlyNumber =/^(\d+(\.\d+)?|)$/;
    const { name, value } = e.target;
    if (
      [
        "max_capacity",
        "capacity",
        "max_solar_power",
        "max_inverter_output_power",
        "usableStorageCapacityHr",
        "usableStorageCapacity",
      ].includes(name)
    ) {
      if (onlyNumber.test(value)) {
        dataConfig[name] = value;
        assetEditStatusUpdateDER(e, embLabels.HYBRID, true);
      } else {
        dataConfig[e.target.name] = e.target.name;
      }
      setConfigData((prevState) => ({ ...prevState, configData: dataConfig }));
    } else {
      dataConfig[name] = value;
      // if (isValidName(value)) {
        setConfigData((prevState) => ({
          ...prevState,
          configData: dataConfig,
        }));
      // }
      assetEditStatusUpdateDER(e, embLabels.HYBRID, true);
    }
  };

  const handlePvChange = (e) => {
    // if (isValidName(e.target.value)) {
      PVDetailsDispatch({
        type: ACTIONS.HANDLE_PV_CHANGE,
        payload: {
          data: e.target,
        },
      });
    // }
  };

  const handleLoadChange = (e) => {
    let newConfigData = { ...configData.configData };
    const onlyNumber =  /^(\d+(\.\d+)?|)$/;
    switch (e.target.name) {
      case "maximum_available_power":
        if (onlyNumber.test(e.target.value)) {
          newConfigData[e.target.name] = e.target.value;
          assetEditStatusUpdateLoad(e, true, selectedValues.selectedLoad);
        } else {
          newConfigData[e.target.name] =
            configData?.configData?.maximum_available_power;
        }
        setConfigData((prevState) => ({
          ...prevState,
          configData: newConfigData,
        }));

        break;
      default:
        newConfigData[e.target.name] = e.target.value;
        if (isValidName(e.target.value)) {
          setConfigData((prevState) => ({
            ...prevState,
            configData: newConfigData,
          }));
          assetEditStatusUpdateLoad(e, true, selectedValues.selectedLoad);
        } else {
          setConfigData((prevState) => ({
            ...prevState,
            configData: newConfigData,
          }));
          // callSnackbar("error", "only alphanumeric charecters are allowed")
        }
    }
     
  };

  const handleEvChange = (e) => {
    let dataConfig = { ...configData.configData };
    const onlyNumber =  /^(\d+(\.\d+)?|)$/;
    switch (e.target.name) {
      case "maximum_available_power":
        if (onlyNumber.test(e.target.value)) {
          dataConfig[e.target.name] = e.target.value;
          assetEditStatusUpdateDER(e, embLabels.EV, true);
        } else {
          dataConfig[e.target.name] =
            configData.configData.maximum_available_power;
        }
        setConfigData((prevState) => ({
          ...prevState,
          configData: dataConfig,
          evConfigData: dataConfig,
        }));
        break;
      default:
        dataConfig[e.target.name] = e.target.value;
        // if (isValidName(e.target.value)) {
          setConfigData((prevState) => ({
            ...prevState,
            configData: dataConfig,
            evConfigData: dataConfig,
          }));
          assetEditStatusUpdateDER(e, embLabels.EV, true);
        // }
    }
  };

  const handleCBConfigChange = (e) => {
    const newCbConfigData = { ...configData.cbConfigData };
    const projectTypeValue = getProjectTypeValue();
    if (e.target.name === "motorized") {
      if (
        [embLabels.GRID_CONNECTED,embLabels.ISLANDABLE].includes(siteType) &&
        clientType === embLabels.GENERIC_PROJECT &&
        projectTypeValue === "MGaaS IEC"
      ) {
        if(e.target.checked){
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CB_CONTACTOR_CONFIG,
            payload: {
              selectedAssetCB: selectedValues.selectedAssetCB,
              cbConfigData: configData.contactorConfigData,
              checked: false,
            },
          })
            newCbConfigData.motorized = true;
            setConfigData((prevState)=>({
              ...prevState,
              cbConfigData: newCbConfigData,
            }))
        }else{
          newCbConfigData[e.target.name] = e.target.checked;
        }
      } else {
        newCbConfigData[e.target.name] = true;
      }
    }else if(e.target.name==="contactor"){
      if (
        [embLabels.GRID_CONNECTED,embLabels.ISLANDABLE].includes(siteType)  &&
        clientType === embLabels.GENERIC_PROJECT &&
        projectTypeValue === "MGaaS IEC"
      ) {
         if(e.target.checked){
          if(configData.cbConfigData.motorized){
            const newConfigData = {...configData}
            newConfigData.cbConfigData.motorized = false
            setConfigData((prevState)=>({
              ...prevState,
              cbConfigData: newConfigData,
            }))
          }
        }
      } else {
        newCbConfigData[e.target.name] = true;
      }
    } else {
      newCbConfigData[e.target.name] = e.target.value;
    }
    // if (isValidName(e.target.value)) {
      setConfigData((prevState) => ({
        ...prevState,
        cbConfigData: newCbConfigData,
      }));
    // }
  };

  const handleCBContactorConfigChange = (e) => {
    const newCbContactorConfigData = { ...configData.contactorConfigData };
    newCbContactorConfigData[e.target.name] = e.target.value;

    // if (isValidName(e.target.value)) {
      setConfigData((prevState) => ({
        ...prevState,
        contactorConfigData: newCbContactorConfigData,
      }));
    // }
  };

  const handleCBConfigChangeLoad = (e) => {
    const newCbConfigData = { ...configData.cbConfigData };
    if (e.target.name === "motorized") {
      if(e.target.checked){
       CBLoadListDispatch({
         type: ACTIONS.UPDATE_CB_CONTACTOR_CONFIG_DATA,
         payload: {
           index: selectedValues.selectedLoadCB,
           CBContactor: configData.contactorConfigData,
           checked: false,
         },
       })
         newCbConfigData[e.target.name] = e.target.checked;
         newCbConfigData.loadCategory = 0;
         newCbConfigData.loadCategory = 0;
         setConfigData((prevState)=>({
           ...prevState,
           cbConfigData: newCbConfigData,
         }))
     }else{
       newCbConfigData[e.target.name] = e.target.checked;
     }
     newCbConfigData[e.target.name] = e.target.checked;
     newCbConfigData.loadCategory = 0;
     newCbConfigData.loadCategory = 0;
   }else if(e.target.name==='contactor'){
     if(e.target.checked){
       if(newCbConfigData.motorized){
        CBLoadListDispatch({
           type: ACTIONS.UPDATE_CB_CONTACTOR_CONFIG_DATA,
           payload: {
             index: selectedValues.selectedLoadCB,
             CBContactor: configData.contactorConfigData,
             checked: true,
           },
         })
         newCbConfigData.motorized = false;
         }else{
          CBLoadListDispatch({
             type: ACTIONS.UPDATE_CB_CONTACTOR_CONFIG_DATA,
             payload: {
               index: selectedValues.selectedLoadCB,
               CBContactor: configData.contactorConfigData,
               checked: true,
             },
           })
       }
     }else{
      CBLoadListDispatch({
         type: ACTIONS.UPDATE_CB_CONTACTOR_CONFIG_DATA,
         payload: {
           index: selectedValues.selectedLoadCB,
           CBContactor: configData.contactorConfigData,
           checked: false,
         },
       })
     }
   } else {
     newCbConfigData[e.target.name] = e.target.value;
   }

    // if (isValidCBName(e.target.value)) {
      setConfigData((prevState) => ({
        ...prevState,
        cbConfigData: newCbConfigData,
      }));
    // }
  };

  const handleCBConfigChangeEv = (e) => {
    const newCbConfigData = { ...configData.cbConfigData };
    if (e.target.name === "motorized") {
       if(e.target.checked){
        CBEVListDispatch({
          type: ACTIONS.UPDATE_CB_CONTACTOR_CONFIG_DATA,
          payload: {
            index: selectedValues.selectedEvCB,
            CBContactor: configData.contactorConfigData,
            checked: false,
          },
        })
          newCbConfigData.motorized = true;
          setConfigData((prevState)=>({
            ...prevState,
            cbConfigData: newCbConfigData,
          }))
      }else{
        newCbConfigData[e.target.name] = e.target.checked;
      }
      newCbConfigData[e.target.name] = e.target.checked;
      newCbConfigData.loadCategory = 0;
      newCbConfigData.loadCategory = 0;
    }else if(e.target.name==='contactor'){
      if(e.target.checked){
        if(newCbConfigData.motorized){
          CBEVListDispatch({
            type: ACTIONS.UPDATE_CB_CONTACTOR_CONFIG_DATA,
            payload: {
              index: selectedValues.selectedEvCB,
              CBContactor: configData.contactorConfigData,
              checked: true,
            },
          })
          newCbConfigData.motorized = false;
          }else{
            CBEVListDispatch({
              type: ACTIONS.UPDATE_CB_CONTACTOR_CONFIG_DATA,
              payload: {
                index: selectedValues.selectedEvCB,
                CBContactor: configData.contactorConfigData,
                checked: true,
              },
            })
        }
      }else{
        CBEVListDispatch({
          type: ACTIONS.UPDATE_CB_CONTACTOR_CONFIG_DATA,
          payload: {
            index: selectedValues.selectedEvCB,
            CBContactor: configData.contactorConfigData,
            checked: false,
          },
        })
      }
    } else {
      newCbConfigData[e.target.name] = e.target.value;
    }

    // if (isValidName(e.target.value)) {
      setConfigData((prevState) => ({
        ...prevState,
        cbConfigData: newCbConfigData,
      }));
    // }
  };

  //* Update config data's

  const isBetween = (num, a, b) => (num - a) * (num - b) <= 0;

  const calculateMaxAvailableUtilityPower = () => {
    const nominalCurrentMin =
      CBAssetList[0]?.cbConfigData?.cbAssetDetails[0]?.assetAttributes?.filter(
        (assetAttribute) => assetAttribute.type === "nominalCurrentMin"
      )[0]?.value;
    const nominalCurrentMax =
      CBAssetList[0]?.cbConfigData?.cbAssetDetails[0]?.assetAttributes?.filter(
        (assetAttribute) => assetAttribute.type === "nominalCurrentMax"
      )[0]?.value;
    const nominalPowerMin = nominalCurrentMin
      ? nominalCurrentMin * nominalVoltage
      : +defaultsMinMax.maxAvailablePowerUtilityMin;
    const nominalPowerMax = nominalCurrentMax
      ? nominalCurrentMax * nominalVoltage
      : +defaultsMinMax.maxAvailablePowerUtilityMax;

    const powerIsBetween = isBetween(
      +configData.configData.maximum_available_power,
      nominalPowerMin,
      nominalPowerMax
    );

    return [powerIsBetween, nominalPowerMin, nominalPowerMax];
  };
  const calculateMaxAvailableLoadPower = () => {
    const nominalCurrentMin = CBLoadList?.[
      selectedValues.selectedLoad
    ]?.cbConfigData?.cbAssetDetails?.[0]?.assetAttributes?.filter(
      (assetAttribute) => assetAttribute.type === "nominalCurrentMin"
    )[0]?.value;
    const nominalCurrentMax = CBLoadList?.[
      selectedValues.selectedLoad
    ]?.cbConfigData?.cbAssetDetails?.[0]?.assetAttributes.filter(
      (assetAttribute) => assetAttribute.type === "nominalCurrentMax"
    )[0]?.value;

    let nominalPowerMin = nominalCurrentMin
      ? nominalCurrentMin * nominalVoltage
      : +defaultsMinMax.maxPowerLoadMin;
    let nominalPowerMax = nominalCurrentMax
      ? nominalCurrentMax * nominalVoltage
      : +defaultsMinMax.maxPowerLoadMax;

    if (siteType === embLabels.GENSET_PVBESS_A2E) {
      nominalPowerMin =
        +CBAssetList[7].assetDetails[0].assetAttributes.find(
          (attribute) => attribute.type === "minLoadPower"
        ).value ?? "0";
      nominalPowerMax =
        +CBAssetList[7].assetDetails[0].assetAttributes.find(
          (attribute) => attribute.type === "maxLoadPower"
        ).value ?? "200";
    }

    const powerIsBetween = isBetween(
      +configData.configData.maximum_available_power,
      nominalPowerMin,
      nominalPowerMax
    );

    return [powerIsBetween, nominalPowerMin, nominalPowerMax];
  };

  const calculateMaxAvailableGensetPower = () => {
    const nominalPowerMin = Number(
      CBAssetList[7]?.assetDetails[0]?.assetAttributes?.find(
        (attribute) => attribute.type === "minGensetPower"
      )?.value ?? defaultsMinMax.maxPowerGensetMin
    );

    const nominalPowerMax = Number(
      CBAssetList[7].assetDetails[0]?.assetAttributes?.find(
        (attribute) => attribute.type === "maxGensetPower"
      )?.value ?? defaultsMinMax.maxPowerGensetMax
    );

    const powerIsBetween = isBetween(
      +configData.configData.max_power,
      +nominalPowerMin,
      +nominalPowerMax
    );

    return [powerIsBetween, nominalPowerMin, nominalPowerMax];
  };

  const updateUtilitytConfig = () => {
    const [powerIsBetween, nominalPowerMin, nominalPowerMax] =
      calculateMaxAvailableUtilityPower();
    if (
      checkForDuplicateName(
        configData.configData.name,
        CBAssetList[0].configData.name
      )
    ) {
      callSnackbar(
        `error`,
        `"${configData.configData.name}" already exists, please provide an unique name`
      );
    } else {
      if (
        /^[a-zA-Z0-9-_& ]+$/.test(configData?.configData?.name) &&
        // isValidName(configData?.configData?.name) &&
        parseInt(configData.configData.utilityCO2) >=
          defaultsMinMax.utilityCO2Min &&
        parseInt(configData.configData.utilityCO2) <=
          defaultsMinMax.utilityCO2Max &&
        powerIsBetween
      ) {
        CBAssetListDispatch({
          type: ACTIONS.UPDATE_ASSET_CONFIG,
          payload: {
            selectedAsset: selectedValues.selectedAsset,
            configData: configData.configData,
          },
        });
        if (duplicateUpdate.der === "") {
          setDuplicateUpdate({
            der: 0,
            load: "",
            pv: "",
            ev: "",
          });
        }
        updateDerHistory(0);
        updateDerStatus(0);
        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
      } else if (
        configData.configData.name === "" ||
        configData.configData.maximum_available_power.toString() === "" ||
        configData.configData.utilityCO2.toString() === ""
      ) {
        callSnackbar("error", embLabels.mandatoryFields);
      // } else if (!isValidName(configData?.configData?.name)) {
      //   callSnackbar("error", embLabels.notallowedAlphanumericValues);
      } else if (
        parseInt(configData.configData.utilityCO2) <
          defaultsMinMax.utilityCO2Min ||
        parseInt(configData.configData.utilityCO2) >
          defaultsMinMax.utilityCO2Max
      ) {
        callSnackbar(
          `error`,
          `Utility CO2 Emission should be in range(${defaultsMinMax.utilityCO2Min}-${defaultsMinMax.utilityCO2Max} g/kWh)`
        );
      } else if (!powerIsBetween) {
        callSnackbar(
          `error`,
          `Maximum Available Power should be in range
          (${nominalPowerMin}-${nominalPowerMax} kW)`
        );
      } else {
        callSnackbar("error", embLabels.mandatoryFields);
      }
    }
  };

  const updateGensetConfig = () => {
    const deviceNameCheck = typeof configData?.configData?.deviceName === 'string' && /^[a-z0-9]+([-_][a-z0-9]+)*$/.test(configData?.configData?.deviceName);
    const [powerIsBetween, nominalPowerMin, nominalPowerMax] =
      calculateMaxAvailableGensetPower();
    if (
      checkForDuplicateName(
        configData.configData.name,
        CBAssetList[1].configData.name
      )
    ) {
      callSnackbar(
        "error",
        `"${configData.configData.name}" already exists, please provide an unique name`
      );
    } else {
      if (
        /^[a-zA-Z0-9-_& ]+$/.test(configData?.configData?.name ) &&
        // isValidName(configData.configData.name) &&
        parseInt(configData.configData.gensetCO2) >=
          defaultsMinMax.gensetCO2Min &&
        parseInt(configData.configData.gensetCO2) <=
          defaultsMinMax.gensetCO2Max &&
        parseInt(configData.configData.starting_timer) >=
          defaultsMinMax.startUpGensetMin &&
        parseInt(configData.configData.starting_timer) <=
          defaultsMinMax.startUpGensetMax &&
        parseInt(configData.configData.cooling_timer) >=
          defaultsMinMax.coolingDurationGensetMin &&
        parseInt(configData.configData.cooling_timer) <=
          defaultsMinMax.coolingDurationGensetMax &&
        parseInt(configData.configData.minimum_running_period) >=
          defaultsMinMax.minRunningDurationGensetMin &&
        parseInt(configData.configData.minimum_running_period) <=
          defaultsMinMax.minRunningDurationGensetMax &&
        powerIsBetween
      ) {
        if(!deviceNameCheck && projectTypeValue === "DaaS ANSI"){
          callSnackbar(`error`, embLabels.mandatoryFields);
        }else{
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_ASSET_CONFIG,
            payload: {
              selectedAsset: selectedValues.selectedAsset,
              configData: configData.configData,
            },
          });
          if (duplicateUpdate.der === "") {
            setDuplicateUpdate({
              der: 1,
              load: "",
              pv: "",
              ev: "",
            });
          }
  
          // newGensetData[this.state.selectedAsset].communicationData =
          //   Object.assign({}, this.state.communicationData);
  
          updateDerHistory(1);
          updateDerStatus(1);
  
          const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
          setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
        }
      } else if (
        configData.configData.name.toString() === "" ||
        configData.configData.max_power?.toString() === "" ||
        configData.configData.gensetCO2?.toString() === "" ||
        configData.configData.starting_timer?.toString() === "" ||
        configData.configData.cooling_timer?.toString() === "" ||
        configData.configData.minimum_running_period.toString() === ""
      ) {
        callSnackbar("error", embLabels.mandatoryFields);
      // } else if (!isValidName(configData?.configData?.name)) {
      //   callSnackbar("error", embLabels.notallowedAlphanumericValues);
      } else if (!powerIsBetween) {
        callSnackbar(
          `error`,
          `Max Power should be in range
          (${nominalPowerMin}-${nominalPowerMax} kW)`
        );
      } else if (
        parseInt(configData.configData.gensetCO2) <
          defaultsMinMax.gensetCO2Min ||
        parseInt(configData.configData.gensetCO2) > defaultsMinMax.gensetCO2Max
      ) {
        callSnackbar(
          `error`,
          `Genset CO2 should be in range (${defaultsMinMax.gensetCO2Min}-${defaultsMinMax.gensetCO2Max} kg/MWh)`
        );
      } else if (
        parseInt(configData.configData.starting_timer) <
          defaultsMinMax.startUpGensetMin ||
        parseInt(configData.configData.starting_timer) >
          defaultsMinMax.startUpGensetMax
      ) {
        callSnackbar(
          `error`,
          `Start-up duration should be in range (${defaultsMinMax.startUpGensetMin}-${defaultsMinMax.startUpGensetMax} s)`
        );
      } else if (
        parseInt(configData.configData.cooling_timer) <
          defaultsMinMax.coolingDurationGensetMin ||
        parseInt(configData.configData.cooling_timer) >
          defaultsMinMax.coolingDurationGensetMax
      ) {
        callSnackbar(
          `error`,
          `Cooling Duration should be in range (${defaultsMinMax.coolingDurationGensetMin}-${defaultsMinMax.coolingDurationGensetMax} s)`
        );
      } else if (
        parseInt(configData.configData.minimum_running_period) <
          defaultsMinMax.minRunningDurationGensetMin ||
        parseInt(configData.configData.minimum_running_period) >
          defaultsMinMax.minRunningDurationGensetMax
      ) {
        callSnackbar(
          `error`,
          `Minimum running duration should be in range (${defaultsMinMax.minRunningDurationGensetMin}-${defaultsMinMax.minRunningDurationGensetMax} s)`
        );
      } else {
        callSnackbar("error", embLabels.mandatoryFields);
      }
    }
  };

  const updateBatteryConfig = () => {
    const deviceNameCheck = typeof configData?.configData?.deviceName === 'string' && /^[a-z0-9]+([-_][a-z0-9]+)*$/.test(configData?.configData?.deviceName);
    const projectTypeValue = getProjectTypeValue()
    if(configData.configData.max_power!=undefined&&configData.configData.max_power!=""){
    let values = JSON.parse(configData.configData.max_power)
      if(Array.isArray(values)){
        const configDataNew = {...configData.configData}
        configDataNew.max_power = values[0];
        setConfigData((prevState)=>({...prevState,configData:configDataNew}))
      }
    }
    if (
      checkForDuplicateName(
        configData.configData.name,
        CBAssetList[2].configData.name
      )
    ) {
      callSnackbar(
        `error`,
        `"${configData.configData.name}" already exists, please provide an unique name"`
      );
    } else {
      if (
        /^[a-zA-Z0-9-_& ]+$/.test(configData?.configData?.name)  &&
       
        // isValidName(configData?.configData?.name) &&
        ( 
          (parseInt(configData?.configData?.max_power) >=
            defaultsMinMax.maxPowerBessMin &&
            parseInt(configData?.configData?.max_power) <=
              defaultsMinMax.maxPowerBessMax)) &&
        parseInt(configData?.configData?.capacity) >=
          defaultsMinMax.capacityBessMin &&
        parseInt(configData?.configData?.capacity) <=
          defaultsMinMax.capacityBessMax &&
        parseInt(configData?.configData?.soc_max) >= defaultsMinMax?.socMaxMin &&
        parseInt(configData?.configData?.soc_max) <= defaultsMinMax?.socMaxMax &&
        parseInt(configData?.configData?.soc_min) >= defaultsMinMax?.socMinMin &&
        parseInt(configData?.configData?.soc_min) <= defaultsMinMax?.socMinMax &&
        parseInt(configData?.configData?.soc_nominal_power) >=
          defaultsMinMax.socNominalDefault
      ) {
        if( !deviceNameCheck && projectTypeValue === "DaaS ANSI"){
          callSnackbar(`error`, embLabels.mandatoryFields);
        }else{
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_ASSET_CONFIG,
            payload: {
              selectedAsset: selectedValues.selectedAsset,
              configData: configData.configData,
            },
          });
  
          // newBatteryData[this.state.selectedAsset].communicationData =
          // Object.assign({}, this.state.batteryCommunicationData);
          if (duplicateUpdate.der === "") {
            setDuplicateUpdate({
              der: 2,
              load: "",
              pv: "",
              ev: "",
            });
          }
  
          updateDerHistory(2);
          updateDerStatus(2);
  
          const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
          setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
        }
       
      } else if (
        [
          configData?.configData?.name,
          configData?.configData?.max_power?.toString(),
          configData?.configData?.capacity?.toString(),
          configData?.configData?.soc_max?.toString(),
          configData?.configData?.soc_min?.toString(),
          configData?.configData?.soc_nominal_power?.toString(),
        ].includes("")
      ) {
        callSnackbar(`error`, embLabels.mandatoryFields);
      // } else if (!isValidName(configData?.configData?.name)) {
      //   callSnackbar("error", embLabels.notallowedAlphanumericValues);
      } else if (
        parseInt(configData?.configData?.max_power) <
          defaultsMinMax?.maxPowerBessMin ||
        parseInt(configData.configData.max_power) >
          defaultsMinMax?.maxPowerBessMax
      ) {
        callSnackbar(
          `error`,
          `${embLabels.maxPowerRange} ${embLabels.shouldBeInRangeOpen}${defaultsMinMax.maxPowerBessMin}-${defaultsMinMax.maxPowerBessMax}
           ${embLabels.kiloWatt}${embLabels.shouldBeInRangeClose}`
        );
      } else if (
        parseInt(configData.configData.capacity) <
          defaultsMinMax.capacityBessMin ||
        parseInt(configData.configData.capacity) >
          defaultsMinMax.capacityBessMax
      ) {
        callSnackbar(
          `error`,
          `${embLabels.capacity} ${embLabels.shouldBeInRangeOpen}${defaultsMinMax.capacityBessMin}-${defaultsMinMax.capacityBessMax}
           ${embLabels.kiloWattHour}${embLabels.shouldBeInRangeClose}`
        );
      } else if (
        parseInt(configData.configData.soc_max) < defaultsMinMax.socMaxMin ||
        parseInt(configData.configData.soc_max) > defaultsMinMax.socMaxMax
      ) {
        callSnackbar(
          `error`,
          `${embLabels.socMax} ${embLabels.shouldBeInRangeOpen}${defaultsMinMax.socMaxMin}-${defaultsMinMax.socMaxMax}
           ${embLabels.perentageOfMaxpower}${embLabels.shouldBeInRangeClose}`
        );
      } else if (
        parseInt(configData.configData.soc_min) < defaultsMinMax.socMinMin ||
        parseInt(configData.configData.soc_min) > defaultsMinMax.socMinMax
      ) {
        callSnackbar(
          `error`,
          `${embLabels.socMin} ${embLabels.shouldBeInRangeOpen}${defaultsMinMax.socMinMin}-${defaultsMinMax.socMinMax}
           ${embLabels.perentageOfMaxpower}${embLabels.shouldBeInRangeClose}`
        );
      } else {
        callSnackbar("error", embLabels.mandatoryFields);
      }
    }
  };

  const updateChpConfig = () => {
    if (
      checkForDuplicateName(
        configData.configData.name,
        CBAssetList[6].configData.name
      )
    ) {
      callSnackbar(
        `error`,
        `"${configData.configData.name}" already exists, please provide an unique name"`
      );
    } else {
      if (
        /^[a-zA-Z0-9-_& ]+$/.test(configData?.configData?.name)  &&
        parseInt(configData.configData?.nominal_power) >=
          defaultsMinMax.chpNominalPowerMin &&
        parseInt(configData.configData.nominal_power) <=
          defaultsMinMax.chpNominalPowerMax
      ) {
        CBAssetListDispatch({
          type: ACTIONS.UPDATE_ASSET_CONFIG,
          payload: {
            selectedAsset: selectedValues.selectedAsset,
            configData: configData.configData,
          },
        });
        if (duplicateUpdate.der === "") {
          setDuplicateUpdate({
            der: 6,
            load: "",
            pv: "",
            ev: "",
          });
        }

        updateDerHistory(6);
        updateDerStatus(7);

        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
      } else if (
        configData.configData.name === "" ||
        configData.configData.nominal_power.toString() === ""
      ) {
        callSnackbar("error", embLabels.mandatoryFields);
      // } else if (!isValidName(configData?.configData?.name)) {
      //   callSnackbar("error", embLabels.notallowedAlphanumericValues);
      } else if (
        parseInt(configData.configData.nominal_power) <
          defaultsMinMax.chpNominalPowerMin ||
        parseInt(configData.configData.nominal_power) >
          defaultsMinMax.chpNominalPowerMax
      ) {
        callSnackbar(
          `error`,
          `${embLabels.nominalPowerRange}${embLabels.shouldBeInRangeOpen}${defaultsMinMax.chpNominalPowerMin}-${defaultsMinMax.chpNominalPowerMax}${embLabels.kiloWatt}${embLabels.shouldBeInRangeClose}
      `
        );
      } else {
        callSnackbar("error", embLabels.mandatoryFields);
      }
    }
  };

  const updateHybridConfig = () => {
    if (
      checkForDuplicateName(
        configData.configData.name,
        CBAssetList[7].configData.name
      )
    ) {
      callSnackbar(
        "error",
        `"${configData.configData.name}" already exists, please provide an unique name`
      );
    } else {
      if (
        /^[a-zA-Z0-9-_& ]+$/.test(configData?.configData?.name) &&
        parseInt(configData.configData.max_capacity) >=
          defaultsMinMax.hybridMaxCapacityMin &&
        parseInt(configData.configData.max_capacity) <=
          defaultsMinMax.hybridMaxCapacityMax &&
        parseInt(configData.configData.capacity) >=
          defaultsMinMax.hybridCapacityMin &&
        parseInt(configData.configData.capacity) <=
          defaultsMinMax.hybridCapacityMax &&
        parseInt(configData.configData.max_solar_power) >=
          defaultsMinMax.hybridMaxSolarPowerMin &&
        parseInt(configData.configData.max_solar_power) <=
          defaultsMinMax.hybridMaxSolarPowerMax &&
        parseInt(configData.configData.max_inverter_output_power) >=
          defaultsMinMax.hybridInverterPowerMin &&
        parseInt(configData.configData.max_inverter_output_power) <=
          defaultsMinMax.hybridInverterPowerMax
      ) {
        //A2E getset configuration
        const gesetAssetConfigDataNew = Object.assign(
          {},
          CBAssetList[1].configData
        );
        if (CBAssetList[1].assetType === embLabels.GENSET) {
          CBAssetList[1]?.selectedAssetData?.assetAttributes &&
            CBAssetList[1]?.selectedAssetData?.assetAttributes.forEach(
              (assetAttribute) => {
                if (assetAttribute.type === "gensetPower") {
                  gesetAssetConfigDataNew.max_power = assetAttribute.value;
                }
              }
            );
        }
        CBAssetListDispatch({
          type: ACTIONS.UPDATE_ASSET_CONFIG,
          payload: {
            selectedAsset: 1,
            configData: gesetAssetConfigDataNew,
          },
        });
        CBAssetListDispatch({
          type: ACTIONS.UPDATE_ASSET_CONFIG,
          payload: {
            selectedAsset: selectedValues.selectedAsset,
            configData: configData.configData,
          },
        });
        if (duplicateUpdate.der === "") {
          setDuplicateUpdate({
            der: 1,
            load: "",
            pv: "",
            ev: "",
          });
        }

        updateDerHistory(9);
        updateDerStatus(9);

        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
      } else if (
        configData.configData.name.toString() === "" ||
        configData.configData.max_capacity?.toString() === "" ||
        configData.configData.capacity?.toString() === "" ||
        configData.configData.max_solar_power?.toString() === "" ||
        configData.configData.max_inverter_output_power.toString() === ""
      ) {
        callSnackbar("error", embLabels.mandatoryFields);
      // } else if (!isValidName(configData?.configData?.name)) {
      //   callSnackbar("error", embLabels.notallowedAlphanumericValues);
      } else if (
        parseInt(configData.configData.max_capacity) <
          defaultsMinMax.maxPowerGensetMin ||
        parseInt(configData.configData.max_capacity) >
          defaultsMinMax.hybridMaxCapacityMax
      ) {
        callSnackbar(
          `error`,
          `Max battery capacity should be in range (${defaultsMinMax.hybridMaxCapacityMin}-${defaultsMinMax.hybridMaxCapacityMax} kW DC)`
        );
      } else if (
        parseInt(configData.configData.capacity) <
          defaultsMinMax.hybridCapacityMin ||
        parseInt(configData.configData.capacity) >
          defaultsMinMax.hybridCapacityMax
      ) {
        callSnackbar(
          `error`,
          `Capacity should be in range (${defaultsMinMax.hybridCapacityMin}-${defaultsMinMax.hybridCapacityMax} KWh)`
        );
      } else if (
        parseInt(configData.configData.max_solar_power) <
          defaultsMinMax.hybridMaxSolarPowerMin ||
        parseInt(configData.configData.max_solar_power) >
          defaultsMinMax.hybridMaxSolarPowerMax
      ) {
        callSnackbar(
          `error`,
          `Max Inverter output power (${defaultsMinMax.hybridMaxSolarPowerMin}-${defaultsMinMax.hybridMaxSolarPowerMax} kw AC)`
        );
      } else if (
        parseInt(configData.configData.max_inverter_output_power) <
          defaultsMinMax.hybridInverterPowerMin ||
        parseInt(configData.configData.max_inverter_output_power) >
          defaultsMinMax.hybridInverterPowerMax
      ) {
        callSnackbar(
          `error`,
          `Max Inverter output power (${defaultsMinMax.hybridInverterPowerMin}-${defaultsMinMax.hybridInverterPowerMax} kw AC)`
        );
      } else {
        callSnackbar("error", embLabels.mandatoryFields);
      }
    }
  };

  const arrayMaxPowerFinder = () => {
    switch (parseInt(selectedValues.selectedPv)) {
      case 3:
        return PVDetails.pvset1Data.reduce(
          (prev, cur) => prev + +cur.max_power,
          0
        );
      case 4:
        return PVDetails.pvset2Data.reduce(
          (prev, cur) => prev + +cur.max_power,
          0
        );
      case 5:
        return PVDetails.pvset3Data.reduce(
          (prev, cur) => prev + +cur.max_power,
          0
        );
      default:
        return 0;
    }
  };

  const updatePvArrayConfig = () => {
    const index = PVDetails.selectSubPv - 1;
    const selectedPv = PVDetails.selectedPv;
    if (
      /^[a-zA-Z0-9-_& ]+$/.test(PVDetails.pvConfig.groupName) &&
      arrayMaxPowerFinder() < parseInt(PVDetails.pvConfig.group_max_power)
    ) {
      callSnackbar(
        "error",
        `Array Max Power should not exceed sum of all pv inverter max power.`
      );
    } else if (
      PVDetails?.pvConfig?.groupName &&
      parseInt(PVDetails.pvConfig.group_max_power) >= 0
    ) {
      // if (isValidName(PVDetails?.pvConfig?.groupName)) {
        PVDetailsDispatch({
          type: ACTIONS.UPDATE_PV_ARRAY,
        });

        updatePvHistory(index, selectedPv);
        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
      // } else {
      //   callSnackbar("error", embLabels.notallowedAlphanumericValues);
      // }
    } else {
      callSnackbar("error", embLabels.mandatoryFields);
    }
  };

  const updatePvConfig = () => {
    const pvNumber = PVDetails?.selectedPv;
    let whichSubPv = PVDetails?.selectSubPv - 1;
    let removeName;

    switch (pvNumber) {
      case 3:
        removeName = PVDetails?.pvset1Data[whichSubPv]?.name;
        break;
      case 4:
        removeName = PVDetails?.pvset2Data[whichSubPv]?.name;
        break;
      case 5:
        removeName = PVDetails?.pvset3Data[whichSubPv]?.name;
        break;
      default:
        break;
    }

    if (checkForDuplicateName(PVDetails?.pvConfig?.name, removeName)) {
      callSnackbar(
        `error`,
        `"${PVDetails.pvConfig.name}" already exists, please provide an unique name`
      );
    } else {
      if (
        PVDetails.pvConfig.name &&
        parseInt(PVDetails.pvConfig.max_power) >=
          defaultsMinMax.maxPowerPvMin &&
        parseInt(PVDetails.pvConfig.max_power) <= defaultsMinMax.maxPowerPvMax
      ) {
        setDuplicateUpdate({
          der: "",
          load: "",
          pv: "pv",
          ev: "",
        });
        PVDetailsDispatch({
          type: ACTIONS.UPDATE_PV_CONFIG_SAVE,
        });
        PVDetailsDispatch({
          type: ACTIONS.PV_CONFIGURED_STATUS,
        });

        updatePvHistory(PVDetails.selectSubPv - 1, PVDetails.selectedPv);
        updatePvHistoryGroupPower(PVDetails.selectedPv);

        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
      } else if (
        PVDetails.pvConfig.name.toString() === "" ||
        PVDetails.pvConfig.max_power.toString() === ""
      ) {
        callSnackbar("error", embLabels.mandatoryFields);
      // } else if (!isValidName(PVDetails.pvConfig.name)) {
      //   callSnackbar("error", embLabels.notallowedAlphanumericValues);
      } else if (
        parseInt(PVDetails.pvConfig.max_power) < defaultsMinMax.maxPowerPvMin ||
        parseInt(PVDetails.pvConfig.max_power) > defaultsMinMax.maxPowerPvMax
      ) {
        callSnackbar(
          `error`,
          `Max Power should be in range(${defaultsMinMax.maxPowerPvMin}-${defaultsMinMax.maxPowerPvMax} kW)`
        );
      } else {
        callSnackbar("error", embLabels.mandatoryFields);
      }
    }
  };

  const updateLoadConfig = () => {
    const [powerIsBetween, nominalPowerMin, nominalPowerMax] =
      calculateMaxAvailableLoadPower();

    if (
      checkForDuplicateName(
        configData.configData.name,
        CBLoadList[selectedValues.selectedLoad].configData.name
      )
    ) {
      callSnackbar(
        `error`,
        `"${configData.configData.name}" already exists, please provide an unique name"`
      );
    } else {
      if (
        /^[a-zA-Z0-9-_& ]+$/.test(configData?.configData?.name) &&
        configData?.configData?.maximum_available_power?.toString().trim() &&
        powerIsBetween
      ) {
        CBLoadListDispatch({
          type: ACTIONS.UPDATE_LOAD_CONFIG,
          payload: {
            selectedLoad: selectedValues.selectedLoad,
            configData: configData.configData,
          },
        });

        setDuplicateUpdate({
          der: "",
          load: selectedValues.selectedLoad,
          pv: "",
          ev: "",
        });

        updateLoadHistory(selectedValues.selectedLoad);

        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
      } else if (
        configData?.configData?.name === "" ||
        configData?.configData?.maximum_available_power?.toString() === ""
      ) {
        callSnackbar("error", embLabels.mandatoryFields);
      // } else if (!isValidName(configData?.configData?.name)) {
      //   callSnackbar("error", embLabels.notallowedAlphanumericValues);
      } else if (!powerIsBetween) {
        callSnackbar(
          `error`,
          `Maximum Available Power should be in range (${nominalPowerMin}-${nominalPowerMax} kW)`
        );
      } else {
        callSnackbar("error", embLabels.mandatoryFields);
      }
    }
    checkAssetStatus();
  };

  const updateEvConfig = () => {
    if (
      checkForDuplicateName(
        configData.configData.name,
        CBEVList[0].configData.name
      )
    ) {
      callSnackbar(
        `error`,
        `"${configData.configData.name}" already exists, please provide an unique name`
      );
    } else {
      if (
        /^[a-zA-Z0-9-_& ]+$/.test(configData?.configData?.name) &&
        parseInt(configData.configData.maximum_available_power) >=
          defaultsMinMax.maxPowerEvMin &&
        parseInt(configData.configData.maximum_available_power) <=
          defaultsMinMax.maxPowerEvMax
      ) {
        CBEVListDispatch({
          type: ACTIONS.UPDATE_EV_CONFIG,
          payload: {
            selectedEv: selectedValues.selectedEv,
            configData: configData.configData,
          },
        });
        setDuplicateUpdate({
          der: "",
          load: "",
          pv: "",
          ev: selectedValues.selectedEv,
        });
        updateDerHistory(5);
        updateDerStatus(5);

        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
      } else if (
        configData.configData.name === "" ||
        configData.configData.maximum_available_power.toString() === ""
      ) {
        callSnackbar("error", embLabels.mandatoryFields);
      // } else if (!isValidName(configData?.configData?.name)) {
      //   callSnackbar("error", embLabels.notallowedAlphanumericValues);
      } else if (
        parseInt(configData.configData.maximum_available_power) <
          defaultsMinMax.maxPowerEvMin ||
        parseInt(configData.configData.maximum_available_power) >
          defaultsMinMax.maxPowerEvMax
      ) {
        callSnackbar(
          `error`,
          `Maximum Available Power should be in range (${defaultsMinMax.maxPowerEvMin}-${defaultsMinMax.maxPowerEvMax} kW)`
        );
      } else {
        callSnackbar("error", embLabels.mandatoryFields);
      }
    }
  };

  const updateCbConfigData = () => {
    if (/^[a-zA-Z0-9-_& ]+$/.test(configData?.cbConfigData?.name)) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CB_CONFIG,
        payload: {
          selectedAssetCB: selectedValues.selectedAssetCB,
          cbConfigData: configData.cbConfigData,
        },
      });
      if (
        configData.contactorConfigData.name &&
        [embLabels.GRID_CONNECTED,embLabels.ISLANDABLE].includes(siteType) &&
        clientType === embLabels.GENERIC_PROJECT&&
        CBAssetList[selectedValues?.selectedAssetCB]?.uiData?.contactor
      ) {
        CBAssetListDispatch({
          type: ACTIONS.UPDATE_CB_CONTACTOR_CONFIG,
          payload: {
            selectedAssetCB: selectedValues.selectedAssetCB,
            cbConfigData: configData.contactorConfigData,
            checked: CBAssetList[selectedValues?.selectedAssetCB]?.uiData?.contactor
          },
        });
      }
      if(configData.cbConfigData.contactor&&configData.contactorConfigData.name&&configData.cbConfigData.name){
        updateAssetCbHistory(selectedValues.selectedAssetCB);
        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
      }else if(configData.cbConfigData.motorized&&configData.cbConfigData.name){
        updateAssetCbHistory(selectedValues.selectedAssetCB);
        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
      }else if(configData.cbConfigData.name){
        updateAssetCbHistory(selectedValues.selectedAssetCB);
        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
      }else{
        callSnackbar(`error`,embLabels.mandatoryFields);
      }
    } else {
      callSnackbar("error", embLabels.mandatoryFields);
    }
  };

  const updateCbContactorConfigData = () => {
    if (configData.contactorConfigData.name) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CB_CONTACTOR_CONFIG,
        payload: {
          selectedAssetCB: selectedValues.selectedAssetCB,
          cbConfigData: configData.contactorConfigData,
        },
      });

      updateAssetCbHistory(selectedValues.selectedAssetCB);

      updateCbConfigData();
    } else {
      callSnackbar("error", embLabels.mandatoryFields);
    }
  };

  const updateEvCbConfigData = () => {
    if (/^[a-zA-Z0-9-_& ]+$/.test(configData?.cbConfigData?.name)) {
      CBEVListDispatch({
        type: ACTIONS.UPDATE_EV_CB_CONFIG,
        payload: {
          selectedEvCB: selectedValues.selectedEvCB,
          cbConfigData: configData.cbConfigData,
        },
      });
      if (
        configData.contactorConfigData.name 
      ) {
        CBEVListDispatch({
          type: ACTIONS.UPDATE_CB_CONTACTOR_CONFIG_DATA,
          payload: {
            index: selectedValues.selectedEvCB,
            CBContactor: configData.contactorConfigData,
            checked: CBEVList[selectedValues.selectedEvCB].uiData.contactor,
          },
        });
      }
      if(configData.cbConfigData.contactor&&configData.contactorConfigData.name&&configData.cbConfigData.name){
        updateAssetCbHistory(selectedValues.selectedAssetCB);
        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
      }else if(configData.cbConfigData.motorized&&configData.cbConfigData.name){
        updateAssetCbHistory(selectedValues.selectedAssetCB);
        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
      }else if(configData.cbConfigData.name){
        updateAssetCbHistory(selectedValues.selectedAssetCB);
        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
      }else{
        callSnackbar(`error`,embLabels.mandatoryFields);
      }
    } else {
      callSnackbar("error", embLabels.mandatoryFields);
    }
  };

  const updateLoadCbConfigData = () => {
    if ( /^[a-zA-Z0-9-_& ]+$/.test(configData?.cbConfigData?.name)) {
      CBLoadListDispatch({
        type: ACTIONS.UPDATE_LOAD_CB_CONFIG,
        payload: {
          selectedLoadCB: selectedValues.selectedLoadCB,
          cbConfigData: configData.cbConfigData,
        },
      });

      updateLoadCbHistory(selectedValues.selectedLoadCB);

      const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
      setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
    } else {
      callSnackbar("error", embLabels.mandatoryFields);
    }
  };

  const updatePowerMeterData = () => {
    if ( /^[a-zA-Z0-9-_ ]+$/.test(configData?.cbConfigData?.name)) {
      if (selectedValues.selectedAssetPM !== "") {
        if (detailsBlock.existingPmAssetDetailsBlockGrid) {
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_EXISTING_POWERMETER_CONFIG,
            payload: {
              selectedAssetPM: selectedValues.selectedAssetPM,
              cbConfigData: configData.cbConfigData,
            },
          });
        } else {
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_POWERMETER_CONFIG,
            payload: {
              selectedAssetPM: selectedValues.selectedAssetPM,
              cbConfigData: configData.cbConfigData,
            },
          });
        }

        updateAssetPmHistory(selectedValues.selectedAssetPM);
      }
      if (selectedValues.selectedEvPM !== "") {
        CBEVListDispatch({
          type: ACTIONS.UPDATE_EV_POWERMETER_CONFIG,
          payload: {
            selectedAssetPM: selectedValues.selectedEvPM,
            cbConfigData: configData.cbConfigData,
          },
        });

        updateEvPmHistory(selectedValues.selectedEvPM);
      }

      if(selectedValues.selectedLoad!==""){
        CBLoadListDispatch({
          type: ACTIONS.UPDATE_LOAD_POWERMETER_CONFIG,
          payload: {
            selectedAssetPM: selectedValues.selectedLoad,
            cbConfigData: configData.cbConfigData,
          },
        });
      }

      const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
      setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
    } else {
      callSnackbar("error", embLabels.mandatoryFields);
    }
  };

  const pv1SelectHandler = () => {
    if (UserScope.access.saveSLD) {
      assetDetailsReset();
      const assetBlock = "assetPvDetailsBlock";
      const selectedAssetValue = "selectedPv";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_SELECT,
        payload: {
          index: 3,
        },
      });
      selectedValuesHandler(3, selectedAssetValue, assetBlock);
      selectedSubPvHandler(1);
      PVDetailsDispatch({
        type: ACTIONS.PV_SELECT,
        payload: {
          selectedPv: 3,
          selectSubPv: 1,
          externalId: gsxExternalId?.pvAssetExternalId["4"],
        },
      });
      selectedAssetHandlerPv(3, 1);
      PVDetailsDispatch({
        type: ACTIONS.PV_CONFIGURED_STATUS,
      });
      PVDetailsDispatch({
        type: ACTIONS.PV_CONFIG_DATA,
      });
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const pv2SelectHandler = () => {
    if (UserScope.access.saveSLD) {
      assetDetailsReset();
      const assetBlock = "assetPvDetailsBlock";
      const selectedAssetValue = "selectedPv";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_SELECT,
        payload: {
          index: 4,
        },
      });
      selectedValuesHandler(4, selectedAssetValue, assetBlock);
      selectedSubPvHandler(1);
      PVDetailsDispatch({
        type: ACTIONS.PV_SELECT,
        payload: {
          selectedPv: 4,
          selectSubPv: 1,
        },
      });
      selectedAssetHandlerPv(4, 1);
      PVDetailsDispatch({
        type: ACTIONS.PV_CONFIGURED_STATUS,
      });
      PVDetailsDispatch({
        type: ACTIONS.PV_CONFIG_DATA,
      });
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const pv3SelectHandler = () => {
    if (UserScope.access.saveSLD) {
      assetDetailsReset();
      const assetBlock = "assetPvDetailsBlock";
      const selectedAssetValue = "selectedPv";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_SELECT,
        payload: {
          index: 5,
        },
      });
      selectedValuesHandler(5, selectedAssetValue, assetBlock);
      selectedSubPvHandler(1);
      PVDetailsDispatch({
        type: ACTIONS.PV_SELECT,
        payload: {
          selectedPv: 5,
          selectSubPv: 1,
        },
      });
      selectedAssetHandlerPv(5, 1);
      PVDetailsDispatch({
        type: ACTIONS.PV_CONFIGURED_STATUS,
      });
      PVDetailsDispatch({
        type: ACTIONS.PV_CONFIG_DATA,
      });
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const checkForDuplicateName = (name, currentName) => {
    const ALLNAMES = getAllNames();
    const OTHERNAMES = ALLNAMES.filter((name) => name !== currentName);

    if (OTHERNAMES.indexOf(name.trim()) !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const getAllNames = () => {
    const ASSETNAMES = CBAssetList.map(
      (assetName) => assetName.configData.name
    ).filter((name) => name !== "");
    const LOADNAME = CBLoadList.map((loadNames) => loadNames.configData.name);
    const EVNAMES = CBEVList.filter((ev) => ev.uiData.status).map(
      (ev) => ev.configData.name
    );
    const PV1NAME = PVDetails.pvset1Data.map((pv1names) => pv1names.name);
    const PV2NAME = PVDetails.pvset2Data.map((pv2names) => pv2names.name);
    const PV3NAME = PVDetails.pvset3Data.map((pv3names) => pv3names.name);
    return [
      ...ASSETNAMES,
      ...PV1NAME,
      ...PV2NAME,
      ...PV3NAME,
      ...LOADNAME,
      ...EVNAMES,
    ];
  };

  //* add assets start

  const addGensetVerify = () => {
    if (siteType === embLabels.GRID_CONNECTED) {
      callSnackbar(
        `error`,
        `'Always Grid connected' usecase doesn't need Genset system.`
      );
    } else {
      if (useCaseValidGenset) {
        setLoading(true);
        setTimeout(() => {
          addGenset();
        }, 800);
      } else {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `'BESS Main No Genset' usecase doesn't need Genset system.`
        );
      }
    }
  };

  const addGenset = () => {
    if (UserScope.access.saveSLD) {
      if (checkSLDCount()) {
        if (!CBAssetList[1].uiData.status) {
          assetDetailsReset();
          CBAssetListDispatch({
            type: ACTIONS.ADD_GENSET,
            payload: {
              defaultPreFill: defaultPreFillData[0],
              configData: configData,
              count: count,
              cbCommunicationValues: cbCommunicationValues,
              defaultCbData: defaultCbData,
              defaultPmData: defaultPmData,
              gensetData: gensetAssetData,
            },
          });
          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });

          const runCalculateCoordinatesNew = { componentPush: true };

          setTimeout(() => {
            setRunCalculateCoordinates(runCalculateCoordinatesNew);
          }, 100);
        } else {
          setLoading(false);
          callSnackbar(`error`, `Max one genset is allowed`);
        }
      } else {
        setLoading(false);
        callSnackbar(`error`, `Max 24 circuit breakers are allowed`);
      }
    } else {
      setLoading(false);
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const addBattery = () => {
    if (UserScope.access.saveSLD) {
      if (checkSLDCount()) {
        if (!CBAssetList[2].uiData.status) {
          assetDetailsReset();

          CBAssetListDispatch({
            type: ACTIONS.ADD_BESS,
            payload: {
              defaultPreFill: defaultPreFillData[0],
              configData: configData,
              count: count,
              cbCommunicationValues: cbCommunicationValues,
              defaultCbData: defaultCbData,
            },
          });
          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });

          const runCalculateCoordinatesNew = { componentPush: true };

          setTimeout(() => {
            setRunCalculateCoordinates(runCalculateCoordinatesNew);
          }, 100);

          // this.setState({
          //   batteryCommunicationData: newBatteryData[2].communicationData,
          // });
        } else {
          callSnackbar(`error`, `Max one battery is allowed`);
        }
      } else {
        callSnackbar(`error`, `Max 24 circuit breakers are allowed`);
      }
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const addPv = () => {
    if (UserScope.access.saveSLD) {
      PVDetailsDispatch({
        type: ACTIONS.ADD_PV_EQUIPMENT,
        payload: {
          CBAssetList: CBAssetList,
        },
      });
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const addPVSetWithCB = () => {
    const projectType = getProjectTypeValue()
    if (PVDetails.pvCount) {
      let overLoadAsset = 0;
      let overLoadPvCB = 1;
      let selectedPv = 3;

      if (PVDetails.existOrNew === "new") {
        if (checkSLDCount()) {
          overLoadAsset = 1;

          if (!CBAssetList[3]?.uiData?.status) {
            selectedPv = 3;
          } else if (!CBAssetList[4]?.uiData?.status) {
            selectedPv = 4;
          } else if (
            !CBAssetList[5]?.uiData?.status &&
            projectType !== "DaaS IEC"
          ) {
            selectedPv = 5;
          } else {
            overLoadPvCB = 0;
          }
        } else {
          overLoadAsset = 0;
        }
      } else if (PVDetails.existOrNew === "exist") {
        overLoadAsset = 1;
        selectedPv = parseInt(PVDetails.branchName);
      }

      //* selected pv is  1
      if (selectedPv === 3) {
        assetDetailsReset();
        if (overLoadAsset && overLoadPvCB) {
          CBAssetListDispatch({
            type: ACTIONS.ADD_PV_TO_CBASSET_LIST,
            payload: {
              selectedPv: selectedPv,
              PVDetails: PVDetails,
              configData: configData,
              preFillDefault: defaultPreFillData[0],
              count: count,
              cbCommunicationValues: cbCommunicationValues,
              defaultCbData: defaultCbData,
            },
          });
          if (PVDetails.existOrNew === "new") {
            countDispatch({
              type: ACTIONS.UPDATE_DER_COUNT,
              payload: { count: 1 },
            });
          }

          const externalIds = JSON.parse(localStorage.getItem("externalId"));
          PVDetailsDispatch({
            type: ACTIONS.CONFIRM_ADD_PV_WITH_CB,
            payload: {
              selectedPv: selectedPv,
              externalId:
                externalIds[
                  Math.floor(generateRandom()* (externalIds.length - 11) + 11)
                ],
            },
          });

          const runCalculateCoordinatesNew = { componentPush: true };

          setTimeout(() => {
            setRunCalculateCoordinates(runCalculateCoordinatesNew);
          }, 100);
        } else {
          if (clientType === "GREENSTRUXURE" || clientType === "GENERIC") {
            if (projectType === "DaaS IEC") {
              callSnackbar(
                "error",
                `Max 2 circuit breakers are allowed for photo voltaic`
              );
            } else {
              callSnackbar(
                "error",
                `Max 3 circuit breakers are allowed for photo voltaic`
              );
            }
          } else {
            callSnackbar("error", "Max 24 circuit breakers are allowed");
          }
          PVDetailsDispatch({
            type: ACTIONS.RESET_PV_DETAILS,
          });
        }
      }

      //* selected pv is 2
      else if (selectedPv === 4) {
        assetDetailsReset();
        if (overLoadAsset && overLoadPvCB) {
          CBAssetListDispatch({
            type: ACTIONS.ADD_PV_TO_CBASSET_LIST,
            payload: {
              selectedPv: selectedPv,
              PVDetails: PVDetails,
              configData: configData,
              preFillDefault: defaultPreFillData[0],
              count: count,
              cbCommunicationValues: cbCommunicationValues,
              defaultCbData: defaultCbData,
            },
          });
          if (PVDetails.existOrNew === "new") {
            countDispatch({
              type: ACTIONS.UPDATE_DER_COUNT,
              payload: { count: 1 },
            });
          }
          const externalIds = JSON.parse(localStorage.getItem("externalId"));
          PVDetailsDispatch({
            type: ACTIONS.CONFIRM_ADD_PV_WITH_CB,
            payload: {
              selectedPv: selectedPv,
              externalId:
                externalIds[
                  Math.floor(generateRandom() * (externalIds.length - 11) + 11)
                ],
            },
          });
          const runCalculateCoordinatesNew = { componentPush: true };

          setTimeout(() => {
            setRunCalculateCoordinates(runCalculateCoordinatesNew);
          }, 100);
        } else {
          if (!overLoadPvCB) {
            callSnackbar(
              "error",
              `Max 3 circuit breakers are allowed for photo voltaic`
            );
          } else {
            callSnackbar("error", "Max 24 circuit breakers are allowed");
          }
          PVDetailsDispatch({
            type: ACTIONS.RESET_PV_DETAILS,
          });
        }
      }

      //* selected pv is 3
      else if (selectedPv === 5) {
        assetDetailsReset();
        if (overLoadAsset && overLoadPvCB) {
          CBAssetListDispatch({
            type: ACTIONS.ADD_PV_TO_CBASSET_LIST,
            payload: {
              selectedPv: selectedPv,
              PVDetails: PVDetails,
              configData: configData,
              preFillDefault: defaultPreFillData[0],
              count: count,
              cbCommunicationValues: cbCommunicationValues,
              defaultCbData: defaultCbData,
            },
          });
          if (PVDetails.existOrNew === "new") {
            countDispatch({
              type: ACTIONS.UPDATE_DER_COUNT,
              payload: { count: 1 },
            });
          }
          const externalIds = JSON.parse(localStorage.getItem("externalId"));
          PVDetailsDispatch({
            type: ACTIONS.CONFIRM_ADD_PV_WITH_CB,
            payload: {
              selectedPv: selectedPv,
              externalId:
              externalIds[
                Math.floor(generateRandom() * (externalIds.length - 11) + 11)
              ],
            },
          });

          const runCalculateCoordinatesNew = { componentPush: true };

          setTimeout(() => {
            setRunCalculateCoordinates(runCalculateCoordinatesNew);
          }, 100);
        } else {
          if (!overLoadPvCB) {
            callSnackbar(
              "error",
              `Max 3 circuit breakers are allowed for photo voltaic`
            );
          } else {
            callSnackbar("error", "Max 24 circuit breakers are allowed");
          }
          PVDetailsDispatch({
            type: ACTIONS.RESET_PV_DETAILS,
          });
        }
      }
    }
  };

  const addCHPVerify = () => {
    if (!defaultPreFillData[1].remoteMonitoringControl) {
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `Remote monitoring control is required for CHP.`
      );
    } else {
      addCHP();
    }
  };

  const addCHP = () => {
    if (UserScope.access.saveSLD) {
      if (checkSLDCount()) {
        if (!CBAssetList[6]?.uiData?.status) {
          assetDetailsReset();

          CBAssetListDispatch({
            type: ACTIONS.ADD_CHP,
            payload: {
              defaultPreFill: defaultPreFillData[0],
              configData: configData,
              siteType,
            },
          });
          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });

          const runCalculateCoordinatesNew = { componentPush: true };

          setTimeout(() => {
            setRunCalculateCoordinates(runCalculateCoordinatesNew);
          }, 100);
        } else {
          callSnackbar(`error`, `Max one CHP is allowed`);
        }
      } else {
        callSnackbar(`error`, `Max 24 circuit breakers are allowed`);
      }
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const addLoad = () => {
    if (UserScope.access.saveSLD) {
      if (siteType === embLabels.GRID_CONNECTED) {
        callSnackbar(
          `error`,
          `More than one Load is not allowed in Grid Connected use case.`
        );
      } else if (count.loadCount + count.evCount >= 22) {
        callSnackbar("error", "Max 22 loads are allowed");
      } else if (
        clientType === embLabels.GREENSTRUXURE_PROJECT &&
        siteType === embLabels.ISLANDABLE_GSX &&
        architectureType === embLabels.PV_GENSET_ATS &&
        defaultPreFillData[1]?.mode === "FUEL_SAVER" &&
        count.loadCount + count.evCount >= 10
      ) {
        callSnackbar("error", "Max 10 loads are allowed");
      } else {
        if (checkSLDCount()) {
          if (count.loadCount + count.evCount < 22) {
            countDispatch({
              type: ACTIONS.UPDATE_LOAD_COUNT,
              payload: { count: 1 },
            });
            CBLoadListDispatch({
              type: ACTIONS.ADD_LOAD,
              payload: {
                defaultPreData: defaultPreFillData[0],
                count: count,
                cbCommunicationValues: cbCommunicationValues,
                defaultCbData: defaultCbData,
                siteType: siteType,
                pmAssetData: defaultPmData,
                contactor: evContactorData,
                defaults: projectKind.defaults,
                projectTypeValue: projectTypeValue,
              },
            });
            CBLoadListDispatch({
              type: ACTIONS.UPDATE_REMOTE_LOAD_COORDINATES,
              payload: {
                coordinates: coordinates,
                loadSide:loadSide,
                count: count,
                siteType: siteType,
                defaultCbData: defaultCbData,
                projectKind: projectKind,
                switchboardType: switchboardType,
              },
            });

            const runCalculateCoordinatesNew = { componentPush: true };

            setTimeout(() => {
              setRunCalculateCoordinates(runCalculateCoordinatesNew);
            }, 100);
          }
        } else {
          callSnackbar(`error`, `Max 24 circuit breakers are allowed`);
        }
      }
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const addRemoteLoad = () => {
    if (UserScope.access.saveSLD) {
        if (checkSLDCount()) {
          if (count.remoteLoadCount<=4) {
            countDispatch({
              type: ACTIONS.UPDATE_REMOTE_LOAD_COUNT,
              payload: { count: 1 },
            });
            CBLoadListDispatch({
              type: ACTIONS.ADD_REMOTE_LOAD,
              payload: {
                defaultPreData: defaultPreFillData[0],
                count: count,
                cbCommunicationValues: cbCommunicationValues,
                defaultCbData: defaultCbData,
                siteType: siteType,
                pmAssetData: defaultPmData,
              },
            });
            CBLoadListDispatch({
              type: ACTIONS.UPDATE_REMOTE_LOAD_COORDINATES,
              payload: {
                coordinates: coordinates,
                loadSide:loadSide,
                count: count,
                siteType: siteType,
                defaultCbData: defaultCbData,
                projectKind: projectKind,
                switchboardType: switchboardType,
              },
            });

            const runCalculateCoordinatesNew = { componentPush: true };

            setTimeout(() => {
              setRunCalculateCoordinates(runCalculateCoordinatesNew);
            }, 100);
        } else {
          callSnackbar(`error`, `Max 24 circuit breakers are allowed`);
        }
      }
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  }


  const addEv = () => {
    if (UserScope.access.saveSLD) {
      if (count.loadCount + count.evCount >= 22) {
        callSnackbar("error", "Max 22 loads are allowed");
      } else if(count.loadCount==10&&projectKind.isMgaasIecIsland){
        callSnackbar("error", "Please remove one load to add EV");
      }else {
        if (checkSLDCount()) {
          if (!CBEVList[0].uiData.status) {
            assetDetailsReset();

            CBEVListDispatch({
              type: ACTIONS.ADD_EV,
              payload: {
                defaultPreFill: defaultPreFillData[0],
                configData: configData,
                count: count,
                cbCommunicationValues: cbCommunicationValues,
                defaultCbData,
                contactor : evContactorData,
                ev: evDefaultData,
                pmEV: evPMData,
                defaults: projectKind.defaults,
              },
            });

            countDispatch({
              type: ACTIONS.UPDATE_EV_COUNT,
              payload: { count: 1 },
            });

            const runCalculateCoordinatesNew = { componentPush: true };

            setTimeout(() => {
              setRunCalculateCoordinates(runCalculateCoordinatesNew);
            }, 100);
          } else {
            callSnackbar("error", "Max one EV is allowed");
          }
        } else {
          callSnackbar(`error`, `Max 24 circuit breakers are allowed`);
        }
      }
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const addTransferSwitch = () =>
    setAlertMessage(
      `Alert`,
      false,
      ``,
      `Contact SE representative in order to integrate external transfer switch.`
    );

  //* add assets end

  //* remove assets start

  const checkGensetRemoveCondition = () => {
    if (defaultPreFillData[1].mode === "FUEL_SAVER") {
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `'Genset main, BESS grid tied' usecase requires a generator.`
      );
    } else {
      removeGenset();
    }
  };

  const removeGenset = () => {
    CBAssetListDispatch({
      type: ACTIONS.REMOVE_ASSET_DER,
      payload: {
        index: 1,
        configData,
      },
    });
    setDetailsBlock((prevState) => ({
      ...prevState,
      assetGensetDetailsBlock: false,
    }));
    countDispatch({
      type: ACTIONS.UPDATE_DER_COUNT,
      payload: { count: -1 },
    });

    updateDerHistory(1);
    updateDerStatus(1);
    updateAssetCbHistory(1);

    const runCalculateCoordinatesNew = { componentPush: true };

    setTimeout(() => {
      setRunCalculateCoordinates(runCalculateCoordinatesNew);
    }, 100);
  };

  const checkBatteryRemoveCondition = () => {
    if (siteType === embLabels.GRID_CONNECTED) {
      // if (!defaultPreFillData[1].exportOptimization) {
      //   removeBattery();
      // } else {
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `Export management predictive optimization requires a battery system.`
      );
      // }
    } else {
      if (defaultPreFillData[1].mode === "NO_FUEL") {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `'BESS main, No Genset' usease requires a battery system. `
        );
      } else if (defaultPreFillData[1].mode === "FUEL_ERASER") {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `'BESS main, Genset as backup' usease requires a battery system.`
        );
      } else {
        if (!defaultPreFillData[1].exportOptimization) {
          removeBattery();
        } else {
          setAlertMessage(
            `Alert`,
            false,
            ``,
            `Export management predictive optimization requires a battery system.`
          );
        }
      }
    }
  };

  const removeBattery = () => {
    CBAssetListDispatch({
      type: ACTIONS.REMOVE_ASSET_DER,
      payload: {
        index: 2,
        configData,
      },
    });

    setDetailsBlock((prevState) => ({
      ...prevState,
      assetBatteryDetailsBlock: false,
    }));

    countDispatch({
      type: ACTIONS.UPDATE_DER_COUNT,
      payload: { count: -1 },
    });

    updateDerHistory(2);
    updateDerStatus(2);
    updateAssetCbHistory(2);

    const runCalculateCoordinatesNew = { componentPush: true };

    setTimeout(() => {
      setRunCalculateCoordinates(runCalculateCoordinatesNew);
    }, 100);
  };

  const removePvWithAlert = (subPv) => {
    if (
      siteType === embLabels.ISLANDABLE_GSX &&
      PVDetails.pvset1Data.length === 1
    ) {
      if (architectureType === embLabels.PV_GENSET_ATS) {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `'‌PV + backup Genset with ATS' usecase requires minimum one PV`
        );
      }
    }
    if (
      siteType === embLabels.GRID_CONNECTED_GSX &&
      PVDetails.pvset1Data.length === 1
    ) {
      if (architectureType === embLabels.PV_BESS_NR) {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `'‌PV + BESS (NR)' usecase requires minimum one PV`
        );
      }
    } else if (
      siteType === embLabels.PV_HYBRIDIZATION &&
      PVDetails.pvset1Data.length === 1
    ) {
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `'Genset/PV Hybridization' usecase requires minimum one PV`
      );
    } else {
      setSelectedValues((prevState) => ({ ...prevState, selectSubPv: subPv }));

      setConfirmMessage(`Do you confirm to delete pv from  pv set ?`);
    }
  };

  const checkPVCount = () => {
    const isArray1Empty = PVDetails?.pvset1Data.length === 0;
    const isArray2Empty = PVDetails?.pvset2Data.length === 0;
    const isArray3Empty = PVDetails?.pvset3Data.length === 0;
    if(((isArray1Empty&&isArray2Empty&&!isArray3Empty)||
    (isArray1Empty&&!isArray2Empty&&isArray3Empty)||
    (!isArray1Empty&&isArray2Empty&&isArray3Empty))){
      return true;
    }else{
      return false;
    }
  }

  const removePv = () => {
    const isPVEmpty = checkPVCount();
    if (
      siteType === embLabels.ISLANDABLE_GSX &&
      architectureType === embLabels.PV_GENSET_ATS &&
      defaultPreFillData[1]?.mode === "FUEL_SAVER" &&
      isPVEmpty
    ) {
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `'‌PV + backup Genset with ATS' usecase requires minimum one PV`
      );
    } else if (
      siteType === embLabels.ISLANDABLE_GSX &&
      architectureType === embLabels.PV_GENSET_ATS &&
      defaultPreFillData[1]?.mode !== "FUEL_SAVER"
    ) {
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `'‌PV + backup Genset with ATS' usecase requires minimum one PV`
      );
    }else if(siteType===embLabels.ISLANDABLE&&
      defaultPreFillData[1]?.mode === "FUEL_ERASER"&&
      isPVEmpty
    ){
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `'‌BESS Main Genset as backup' usecase requires minimum one PV`
      );
    } else if (
      siteType === embLabels.GRID_CONNECTED_GSX &&
      isPVEmpty
    ) {
      if (architectureType === embLabels.PV_BESS_NR) {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `'‌PV + BESS (NR)' usecase requires minimum one PV1`
        );
      }
    } else if (
      siteType === embLabels.GRID_CONNECTED &&
      isPVEmpty
    ) {
      setAlertMessage(`Alert`, false, ``, ` usecase requires minimum one PV1`);
    } else if (
      siteType === embLabels.GRID_CONNECTED_GSX &&
      isPVEmpty
    ) {
      if (architectureType === embLabels.PV_BESS_NR) {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `'‌PV + BESS (NR)' usecase requires minimum one PV1`
        );
      }
    } else if (siteType === embLabels.PV_HYBRIDIZATION) {
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `'Genset/PV Hybridization' usecase requires minimum one PV`
      );
    }else if(siteType===embLabels.ISLANDABLE&&isPVEmpty){
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `'No Genset' usecase requires minimum one PV`
      );
    } else {
      CBAssetListDispatch({
        type: ACTIONS.REMOVE_ASSET_DER_PV,
        payload: {
          selectedPv: PVDetails.selectedPv,
        },
      });
      setDetailsBlock((prevState) => ({
        ...prevState,
        assetPvDetailsBlock: false,
      }));

      countDispatch({
        type: ACTIONS.UPDATE_DER_COUNT,
        payload: { count: -1 },
      });

      let removedPvSet;
      switch (PVDetails.selectedPv) {
        case 3:
          removedPvSet = "pvset1Data";
          break;
        case 4:
          removedPvSet = "pvset2Data";

          break;
        case 5:
          removedPvSet = "pvset3Data";

          break;
        default:
          break;
      }
      PVDetailsDispatch({
        type: ACTIONS.REMOVE_ALL_PV_FROM_SET,
        payload: {
          removedPvSet: removedPvSet,
        },
      });

      updatePvHistoryFull(PVDetails.selectedPv);
      updateAssetCbHistory(PVDetails.selectedPv);
      updatePvHistoryGroupPower(PVDetails.selectedPv);

      const runCalculateCoordinatesNew = { componentPush: true };

      setTimeout(() => {
        setRunCalculateCoordinates(runCalculateCoordinatesNew);
      }, 100);
    }
  };

  const removeCHP = () => {
    CBAssetListDispatch({
      type: ACTIONS.REMOVE_ASSET_DER,
      payload: {
        index: 6,
        configData,
      },
    });
    setDetailsBlock((prevState) => ({
      ...prevState,
      assetCHPDetailsBlock: false,
    }));
    countDispatch({
      type: ACTIONS.UPDATE_DER_COUNT,
      payload: { count: -1 },
    });

    updateDerHistory(6);
    updateDerStatus(7);
    updateAssetCbHistory(6);

    const runCalculateCoordinatesNew = { componentPush: true };

    setTimeout(() => {
      setRunCalculateCoordinates(runCalculateCoordinatesNew);
    }, 100);
  };

  const removeLoad = () => {
    if (siteType === embLabels.GRID_CONNECTED) {
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `Asset configuration needs minimum one load.`
      );
    } else if ((parseInt(count.loadCount)+parseInt(count.remoteLoadCount) === 1)) {
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `Asset configuration needs minimum one load.`
      );
    } else {
      CBLoadListDispatch({
        type: ACTIONS.REMOVE_LOAD,
        payload: {
          selectedLoad: selectedValues.selectedLoad,
        },
      });

      
      if(CBLoadList[selectedValues.selectedLoad].uiData.isRemoteLoad){
        countDispatch({
          type: ACTIONS.UPDATE_REMOTE_LOAD_COUNT,
          payload: { count: -1 },
        });
      }else{
        countDispatch({
          type: ACTIONS.UPDATE_LOAD_COUNT,
          payload: { count: -1 },
        });
      }

      setDetailsBlock((prevState) => ({
        ...prevState,
        loadDetailsBlock: false,
      }));

      updateLoadHistory(selectedValues.selectedLoad);
      updateLoadCbHistory(selectedValues.selectedLoad);

      const runCalculateCoordinatesNew = { componentPush: true };

      setTimeout(() => {
        setRunCalculateCoordinates(runCalculateCoordinatesNew);
      }, 100);
    }
  };

  const removeEv = () => {
    CBEVListDispatch({
      type: ACTIONS.REMOVE_ASSET_EV,
      payload: {
        index: selectedValues.selectedEv,
        configData,
      },
    });
    setDetailsBlock((prevState) => ({
      ...prevState,
      evDetailsBlock: false,
    }));

    countDispatch({
      type: ACTIONS.UPDATE_EV_COUNT,
      payload: { count: -1 },
    });

    updateDerHistory(5);
    updateDerStatus(5);
    updateAssetCbHistory(6);

    const runCalculateCoordinatesNew = { componentPush: true };

    setTimeout(() => {
      setRunCalculateCoordinates(runCalculateCoordinatesNew);
    }, 100);
  };

  //* remove assets end

  const isNoAssetSelected = () =>
    Object.keys(detailsBlock).every((block) => !detailsBlock[block]);

  const checkSLDCount = () =>
    count.derCount + count.loadCount + count.evCount + count.remoteLoadCount  >= 24 ? false : true;

  const isValidCBName = (value) =>
    /^[a-zA-Z0-9_-\s]*$/.test(value) ? true : false;
  const isValidName = (value) => /^[a-zA-Z0-9 ]+$/.test(value)? true: false;
  const callSnackbar = useCallback((type, message) => {
    try {
      setSnackBar({ type, message });
      snackBarRef.current.open = true
    } catch (error) {
      console.log("error in snack bar")
    }
  }, []);

  const setAlertMessage = (title, isRoute, routePath, message) => {
    setAlert({
      title,
      isRoute,
      routePath,
      message,
      alertPopUpStatus: true,
    });
  };

  const closeAlertMessage = useCallback(() => {
    setAlert({
      message: "",
      alertPopUpStatus: false,
      title: "",
      isRoute: false,
      routePath: "",
    });
    if (alert.isRoute) {
      let { history } = props;
      history.push({
        pathname: `/${alert.routePath}`,
      });
    }
  }, [alert, props]);

  const setConfirmMessage = (message) => {
    setConfirm({
      confirmPopupStatus: true,
      confirmMessage: message,
    });
  };

  const closeConfirmMessage = () => {
    setConfirm({
      confirmPopupStatus: false,
      confirmMessage: "",
    });
  };
  const closeConfirmMessageRemote = () => {
    setRemoteConfirm({
      confirmPopupStatus: false,
      confirmMessage: "",
    });
  }
  const okConfirmMessageRemote = () => {
    if((["MGaaS ANSI","DaaS ANSI"].includes(projectTypeValue)&&[embLabels.ISLANDABLE,embLabels.ISLANDABLE_GSX].includes(siteType))&&switchboardType===embLabels.EXISTING_SWITCHBOARD){
      handleSwitchboardTypeChangeGSX("NEW_SWITCHBOARD");
  }
  closeConfirmMessageRemote();
  }

  const okConfirmMessage = (e, isConfirmed) => {
    if (PVDetails.selectedPv === 3) {
      if (PVDetails.pvset1Data.length > 1) {
        let pvConStatusOne = true;

        PVDetails.pvset1Data.forEach((pv1, index) => {
          if (index !== selectedValues.selectSubPv - 1 && !pv1.status) {
            pvConStatusOne = false;
          }
        });

        CBAssetListDispatch({
          type: ACTIONS.REDUCE_PV_COUNT_FROM_CB,
          payload: {
            selectedPv: 3,
            pvConStatusOne: pvConStatusOne,
          },
        });

        PVDetailsDispatch({
          type: ACTIONS.REMOVE_PV_FROM_SET,
          payload: {
            selectedPv: selectedValues.selectedPv,
            subPv: selectedValues.selectSubPv,
          },
        });

        if (!PVDetails.pvset1Data[0].assetDetails.length) {
          const newAssetDetailsDisplay = { ...assetDetailsDisplay };
          Object.keys(newAssetDetailsDisplay).forEach(
            (details) => (newAssetDetailsDisplay[details] = "")
          );
          setAssetDetailsDisplay(newAssetDetailsDisplay);
        }

        //this.calculatePvStatusConfigured(newPvData1Set);

        let subPv = selectedValues.selectSubPv - 1;

        updatePvHistory(subPv, 3);

        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);

        pv1SelectHandler();
      } else {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          "Unable to delete. Minimum one Pv should be is present in PV set"
        );
      }
    }
    if (PVDetails.selectedPv === 4) {
      if (PVDetails.pvset2Data.length > 1) {
        let pvConStatusOne = true;

        PVDetails.pvset2Data.forEach((pv2, index) => {
          if (index !== selectedValues.selectSubPv - 1 && !pv2.status) {
            pvConStatusOne = false;
          }
        });

        CBAssetListDispatch({
          type: ACTIONS.REDUCE_PV_COUNT_FROM_CB,
          payload: {
            selectedPv: 4,
            pvConStatusOne: pvConStatusOne,
          },
        });

        PVDetailsDispatch({
          type: ACTIONS.REMOVE_PV_FROM_SET,
          payload: {
            selectedPv: selectedValues.selectedPv,
            subPv: selectedValues.selectSubPv,
          },
        });

        if (!PVDetails.pvset2Data[0].assetDetails.length) {
          const newAssetDetailsDisplay = { ...assetDetailsDisplay };
          Object.keys(newAssetDetailsDisplay).forEach(
            (details) => (newAssetDetailsDisplay[details] = "")
          );

          setAssetDetailsDisplay(newAssetDetailsDisplay);
        }

        //this.calculatePvStatusConfigured(newPvData1Set);

        let subPv = selectedValues.selectSubPv - 1;

        updatePvHistory(subPv, 4);

        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);

        pv2SelectHandler();
      } else {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          "Unable to delete. Minimum one Pv should be is present in PV set"
        );
      }
    }
    if (PVDetails.selectedPv === 5) {
      if (PVDetails.pvset3Data.length > 1) {
        let pvConStatusOne = true;

        PVDetails.pvset3Data.forEach((pv3, index) => {
          if (index !== selectedValues.selectSubPv - 1 && !pv3.status) {
            pvConStatusOne = false;
          }
        });

        CBAssetListDispatch({
          type: ACTIONS.REDUCE_PV_COUNT_FROM_CB,
          payload: {
            selectedPv: 5,
            pvConStatusOne: pvConStatusOne,
          },
        });

        PVDetailsDispatch({
          type: ACTIONS.REMOVE_PV_FROM_SET,
          payload: {
            selectedPv: selectedValues.selectedPv,
            subPv: selectedValues.selectSubPv,
          },
        });

        if (!PVDetails.pvset3Data[0].assetDetails.length) {
          const newAssetDetailsDisplay = { ...assetDetailsDisplay };
          Object.keys(newAssetDetailsDisplay).forEach(
            (details) => (newAssetDetailsDisplay[details] = "")
          );

          setAssetDetailsDisplay(newAssetDetailsDisplay);
        }

        //this.calculatePvStatusConfigured(newPvData1Set);

        let subPv = selectedValues.selectSubPv - 1;

        updatePvHistory(subPv, 5);

        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);

        pv3SelectHandler();
      } else {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          "Unable to delete. Minimum one Pv should be is present in PV set"
        );
      }
    }
    closeConfirmMessage();
  };

  const closePvDetailsBlock = () => {
    PVDetailsDispatch({
      type: ACTIONS.CLOSE_PVDISPLAY_CONTENT,
      payload: {
        selectSubPv: PVDetails?.selectSubPv,
        selectedPv: PVDetails?.selectedPv,
      }
    });
  };

  const isCopyAllButtonDisabled = (selectedPv) => {
    switch (selectedPv) {
      case 3:
        const configured1pv = PVDetails.pvset1Data.filter((pv1) => pv1.status);
        const configured1pvSameAsset = configured1pv.every(
          (pv1) =>
            pv1?.assetDetails[0]?.assetId ===
            configured1pv[0]?.assetDetails[0]?.assetId
        );
        if (PVDetails.pvset1Data.every((pv1) => pv1.status)) return false;
        else return configured1pvSameAsset && configured1pv.length;
      case 4:
        const configured2pv = PVDetails.pvset2Data.filter((pv2) => pv2.status);
        const configured2pvSameAsset = configured2pv.every(
          (pv2) =>
            pv2?.assetDetails[0]?.assetId ===
            configured2pv[0]?.assetDetails[0]?.assetId
        );
        if (PVDetails.pvset2Data.every((pv2) => pv2.status)) return false;
        else return configured2pvSameAsset && configured2pv.length;
      case 5:
        const configured3pv = PVDetails.pvset3Data.filter((pv3) => pv3.status);
        const configured3pvSameAsset = configured3pv.every(
          (pv3) =>
            pv3?.assetDetails[0]?.assetId ===
            configured3pv[0]?.assetDetails[0]?.assetId
        );
        if (PVDetails.pvset3Data.every((pv3) => pv3.status)) return false;
        else return configured3pvSameAsset && configured3pv.length;
      default:
        break;
    }
  };

  const copyAllPvs = () => {
    PVDetailsDispatch({
      type: ACTIONS.COPY_ALL_PV,
    });
    PVDetailsDispatch({
      type: ACTIONS.PV_CONFIGURED_STATUS,
    });

    // this.updatePvHistory(index, selectedPV);
    const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
    setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
  };

  //* LOCAL STORAGE

  const assetEditStatusUpdateDER = (e, type, status) => {
    let derHistory = JSON.parse(localStorage.getItem("derHistory"));
    switch (type) {
      case embLabels.BESS:
        derHistory.BESS[e.target.name] = e.target.value;
        checkUpdatedStatus(2, status);
        break;

      case embLabels.GENSET:
        derHistory.Genset[e.target.name] = e.target.value;
        checkUpdatedStatus(1, status);
        break;

      case embLabels.UTILITY:
        derHistory.Utility[e.target.name] = e.target.value;
        checkUpdatedStatus(0, status);
        break;

      case embLabels.EV:
        derHistory.EV[e.target.name] = e.target.value;
        checkUpdatedStatus(5, status);
        break;

      case embLabels.CHP:
        derHistory.CHP[e.target.name] = e.target.value;
        checkUpdatedStatus(7, status);
        break;

      case embLabels.HYBRID:
        derHistory.Hybrid[e.target.name] = e.target.value;
        checkUpdatedStatus(9, status);
        break;

      default:
        break;
    }
    localStorage.setItem("derHistory", JSON.stringify(derHistory));
  };

  const assetEditStatusUpdateLoad = (e, status, selectedLoad) => {
    let loadHistory = [];
    if (localStorage.getItem("loadHistory") !== "") {
      loadHistory = JSON.parse(localStorage.getItem("loadHistory"));
    }

    if (loadHistory[selectedLoad] === undefined) {
      loadHistory[selectedLoad] = {};
    }
    if (loadHistory[selectedLoad] === null) {
      loadHistory[selectedLoad] = {};
    }
    let loadHistoryRef = loadHistory[selectedLoad];
    loadHistoryRef[e.target.name] = e.target.value;
    loadHistory[selectedLoad] = loadHistoryRef;
    localStorage.setItem("loadHistory", JSON.stringify(loadHistory));
    checkUpdatedStatus(4, status);
  };

  const assetEditStatusUpdateAssetCB = (e, status, selectedAssetCb) => {
    const dataConfig = { ...configData.cbConfigData };
    let cbAssetHistory = [];
    if (localStorage.getItem("cbAssetHistory") !== "") {
      cbAssetHistory = JSON.parse(localStorage.getItem("cbAssetHistory"));
    }

    if (cbAssetHistory[selectedAssetCb] === undefined) {
      cbAssetHistory[selectedAssetCb] = {};
    }
    if (cbAssetHistory[selectedAssetCb] === null) {
      cbAssetHistory[selectedAssetCb] = {};
    }

    let cbAssetHistoryRef = cbAssetHistory[selectedAssetCb];
    const projectTypeValue = getProjectTypeValue()
    if (e.target.name === "motorized") {
      if (
        [embLabels.GRID_CONNECTED,embLabels.ISLANDABLE].includes(siteType) &&
        clientType === embLabels.GENERIC_PROJECT &&
        projectTypeValue === "MGaaS IEC"
      ) {
        if(e.target.checked){
          if(cbAssetHistoryRef?.contactor){
            cbAssetHistoryRef[e.target.name] = e.target.checked;
            cbAssetHistoryRef.contactor = !e.target.checked;
            dataConfig[e.target.name] = e.target.checked;
            dataConfig.contactor = !e.target.checked;
          }else{
            cbAssetHistoryRef[e.target.name] = e.target.checked;
            dataConfig[e.target.name] = e.target.checked;
          }
        }else{
          cbAssetHistoryRef[e.target.name] = e.target.checked;
          dataConfig[e.target.name] = e.target.checked;
        }
      } else {
        cbAssetHistoryRef[e.target.name] = true;
      }
    } else if(e.target.name === "contactor"){
      if (
        siteType === embLabels.GRID_CONNECTED &&
        clientType === embLabels.GENERIC_PROJECT &&
        projectTypeValue === "MGaaS IEC"
      ) {
        if(e.target.checked){
          if(cbAssetHistoryRef?.motorized){
            cbAssetHistoryRef[e.target.name] = e.target.checked;
            cbAssetHistoryRef.motorized = !e.target.checked;
            dataConfig[e.target.name] = e.target.checked;
            dataConfig.motorized = !e.target.checked;
          }else{
            cbAssetHistoryRef[e.target.name] = e.target.checked;
            dataConfig[e.target.name] = e.target.checked;
          }
        }else{
          cbAssetHistoryRef[e.target.name] = e.target.checked;
          dataConfig[e.target.name] = e.target.checked;
        }
    } else {
        cbAssetHistoryRef[e.target.name] = true;
      }
    }else {
      cbAssetHistoryRef[e.target.name] = e.target.value;
      dataConfig[e.target.name] = e.target.value;
    }
    cbAssetHistory[selectedAssetCb] = cbAssetHistoryRef;
    setConfigData((prevState) => ({ ...prevState, cbConfigData: dataConfig }));
    localStorage.setItem("cbAssetHistory", JSON.stringify(cbAssetHistory));
    checkUpdatedStatus(6, status);
  };

  const assetEditStatusUpdateLoadCb = (e, status, selectedLoadCb) => {
    let cbLoadHistory = [];
    if (localStorage.getItem("cbLoadHistory") !== "") {
      cbLoadHistory = JSON.parse(localStorage.getItem("cbLoadHistory"));
    }

    if (cbLoadHistory[selectedLoadCb] === undefined) {
      cbLoadHistory[selectedLoadCb] = {};
    }

    if (cbLoadHistory[selectedLoadCb] === null) {
      cbLoadHistory[selectedLoadCb] = {};
    }

    let cbLoadHistoryRef = cbLoadHistory[selectedLoadCb];
    if (e.target.name === "motorized") {
      cbLoadHistoryRef[e.target.name] = e.target.checked;
    }else if(e.target.name === "contactor") {
      cbLoadHistoryRef[e.target.name] = e.target.checked;
    } else {
      cbLoadHistoryRef[e.target.name] = e.target.value;
    }
    cbLoadHistory[selectedLoadCb] = cbLoadHistoryRef;
    localStorage.setItem("cbLoadHistory", JSON.stringify(cbLoadHistory));
    checkUpdatedStatus(6, status);
  };

  const assetEditStatusUpdateEvCb = (e, status, selectedEvCb) => {
    let cbEvHistory = [];
    if (localStorage.getItem("cbEvHistory") !== "") {
      cbEvHistory = JSON.parse(localStorage.getItem("cbEvHistory"));
    }

    if (cbEvHistory[selectedEvCb] === undefined) {
      cbEvHistory[selectedEvCb] = {};
    }

    if (cbEvHistory[selectedEvCb] === null) {
      cbEvHistory[selectedEvCb] = {};
    }

    let cbEvHistoryRef = cbEvHistory[selectedEvCb];
    if (e.target.name === "motorized") {
      cbEvHistoryRef[e.target.name] = e.target.checked;
    } else {
      cbEvHistoryRef[e.target.name] = e.target.value;
    }

    cbEvHistory[selectedEvCb] = cbEvHistoryRef;
    localStorage.setItem("cbEvHistory", JSON.stringify(cbEvHistory));
    checkUpdatedStatus(6, status);
  };

  const assetEditStatusUpdatePv = (e, status, selectedPv, selectedSubPvRaw) => {
    let selectedSubPv = selectedSubPvRaw - 1;
    const onlyNumber = /^(\d+(\.\d+)?|)$/;
    let pvHistory = JSON.parse(localStorage.getItem("pvHistory"));
    if (selectedPv === 3) {
      if (pvHistory.pv1[selectedSubPv] === undefined) {
        pvHistory.pv1[selectedSubPv] = {};
      }
      if (pvHistory.pv1[selectedSubPv] === null) {
        pvHistory.pv1[selectedSubPv] = {};
      }
      let pvHistoryRef = pvHistory.pv1[selectedSubPv];

      switch (e.target.name) {
        case "groupName":
          pvHistoryRef[e.target.name] = e.target.value;
          break;
        case "group_max_power":
          if (onlyNumber.test(e.target.value) && e.target.value <= 99999) {
            pvHistoryRef[e.target.name] = e.target.value;
          }
          break;
        case "max_power":
          if (!isNaN(e.target.value)) {
            pvHistoryRef[e.target.name] = e.target.value;
          }
          break;
        default:
          pvHistoryRef[e.target.name] = e.target.value;
          break;
      }

      pvHistory.pv1[selectedSubPv] = pvHistoryRef;
    } else if (selectedPv === 4) {
      if (pvHistory.pv2[selectedSubPv] === undefined) {
        pvHistory.pv2[selectedSubPv] = {};
      }
      if (pvHistory.pv2[selectedSubPv] === null) {
        pvHistory.pv2[selectedSubPv] = {};
      }
      let pvHistoryRef = pvHistory.pv2[selectedSubPv];
      switch (e.target.name) {
        case "groupName":
          pvHistoryRef[e.target.name] = e.target.value;
          break;
        case "group_max_power":
          if (onlyNumber.test(e.target.value) && e.target.value <= 99999) {
            pvHistoryRef[e.target.name] = e.target.value;
          }
          break;
        case "max_power":
          if (!isNaN(e.target.value)) {
            pvHistoryRef[e.target.name] = e.target.value;
          }
          break;
        default:
          pvHistoryRef[e.target.name] = e.target.value;
          break;
      }
      pvHistory.pv2[selectedSubPv] = pvHistoryRef;
    } else if (selectedPv === 5) {
      if (pvHistory.pv3[selectedSubPv] === undefined) {
        pvHistory.pv3[selectedSubPv] = {};
      }
      if (pvHistory.pv3[selectedSubPv] === null) {
        pvHistory.pv3[selectedSubPv] = {};
      }
      let pvHistoryRef = pvHistory.pv3[selectedSubPv];
      switch (e.target.name) {
        case "groupName":
          pvHistoryRef[e.target.name] = e.target.value;
          break;
        case "group_max_power":
          if (onlyNumber.test(e.target.value) && e.target.value <= 99999) {
            pvHistoryRef[e.target.name] = e.target.value;
          }
          break;
        case "max_power":
          if (!isNaN(e.target.value)) {
            pvHistoryRef[e.target.name] = e.target.value;
          }
          break;
        default:
          pvHistoryRef[e.target.name] = e.target.value;
          break;
      }
      pvHistory.pv3[selectedSubPv] = pvHistoryRef;
    }
    localStorage.setItem("pvHistory", JSON.stringify(pvHistory));
    checkUpdatedStatus(3, status);
  };

  const assetEditStatusUpdateAssetPM = (e, status, selectedAssetPm) => {
    let pmAssetHistory = [];
    if (localStorage.getItem("pmAssetHistory") !== "") {
      pmAssetHistory = JSON.parse(localStorage.getItem("pmAssetHistory"));
    }

    if (pmAssetHistory[selectedAssetPm] === undefined) {
      pmAssetHistory[selectedAssetPm] = {};
    }
    if (pmAssetHistory[selectedAssetPm] === null) {
      pmAssetHistory[selectedAssetPm] = {};
    }

    let pmAssetHistoryRef = pmAssetHistory[selectedAssetPm];
    pmAssetHistoryRef[e.target.name] = e.target.value;
    pmAssetHistory[selectedAssetPm] = pmAssetHistoryRef;
    localStorage.setItem("pmAssetHistory", JSON.stringify(pmAssetHistory));
    checkUpdatedStatus(8, status);
  };

  const assetEditStatusUpdateEvPM = (e, status, selectedAssetPm) => {
    let pmEvHistory = [];
    if (localStorage.getItem("pmEvHistory") !== "") {
      pmEvHistory = JSON.parse(localStorage.getItem("pmEvHistory"));
    }

    if (pmEvHistory[selectedAssetPm] === undefined) {
      pmEvHistory[selectedAssetPm] = {};
    }
    if (pmEvHistory[selectedAssetPm] === null) {
      pmEvHistory[selectedAssetPm] = {};
    }

    let pmAssetHistoryRef = pmEvHistory[selectedAssetPm];
    pmAssetHistoryRef[e.target.name] = e.target.value;
    pmEvHistory[selectedAssetPm] = pmAssetHistoryRef;
    localStorage.setItem("pmEvHistory", JSON.stringify(pmEvHistory));
    checkUpdatedStatus(8, status);
  };

  const checkUpdatedStatus = (type, status) => {
    let derStatus = [];
    if (localStorage.getItem("derStatus") !== "") {
      derStatus = JSON.parse(localStorage.getItem("derStatus"));
    }

    derStatus[type] = status;

    localStorage.setItem("derStatus", JSON.stringify(derStatus));
    detailsModifiedStataus();
  };

  const detailsModifiedStataus = () => {
    let derStatus = [];
    if (localStorage.getItem("derStatus") !== "") {
      derStatus = JSON.parse(localStorage.getItem("derStatus"));
    }
    localStorage.setItem("assetEditStatus", "");
    let assetEditStatus = [];
    for (let i = 0; i < derStatus.length; i++) {
      if (derStatus[i] === true) {
        switch (i) {
          case 0:
            assetEditStatus.push("Utility");
            assetEditStatus.push(true);
            break;
          case 1:
            assetEditStatus.push("Genset");
            assetEditStatus.push(true);
            break;
          case 2:
            assetEditStatus.push("BESS");
            assetEditStatus.push(true);
            break;
          case 3:
            assetEditStatus.push("PV");
            assetEditStatus.push(true);
            break;
          case 4:
            assetEditStatus.push("Load");
            assetEditStatus.push(true);
            break;
          case 5:
            assetEditStatus.push("EV");
            assetEditStatus.push(true);
            break;
          case 6:
            assetEditStatus.push("CB");
            assetEditStatus.push(true);
            break;
          case 7:
            assetEditStatus.push("CHP");
            assetEditStatus.push(true);
            break;
          case 8:
            assetEditStatus.push("Power meter");
            assetEditStatus.push(true);
            break;
          case 9:
            assetEditStatus.push("Hybrid");
            assetEditStatus.push(true);
            break;
          default:
            break;
        }
        localStorage.setItem("assetEditStatus", assetEditStatus);
        break;
      }
    }
  };

  const updateDerHistory = (index) => {
    let derHistory = JSON.parse(localStorage.getItem("derHistory"));
    if (index === 0) {
      derHistory.Utility = {};
    } else if (index === 1) {
      derHistory.Genset = {};
    } else if (index === 2) {
      derHistory.BESS = {};
    } else if (index === 5) {
      derHistory.EV = {};
    } else if (index === 7) {
      derHistory.CHP = {};
    } else if (index === 9) {
      derHistory.Hybrid = {};
    }

    localStorage.setItem("derHistory", JSON.stringify(derHistory));
  };

  const updateLoadHistory = (index) => {
    let derStatus = JSON.parse(localStorage.getItem("derStatus"));
    let loadHistory = [];
    if (derStatus[4] === true) {
      loadHistory = JSON.parse(localStorage.getItem("loadHistory"));
    }

    if (loadHistory.length) {
      delete loadHistory[index];
    }
    localStorage.setItem("loadHistory", JSON.stringify(loadHistory));

    if (loadHistory.length) {
      let status = false;
      for (let i = 0; i < loadHistory.length; i++) {
        if (loadHistory[i] === null) {
          status = status === false ? false : true;
        } else if (loadHistory[i] === undefined) {
          status = status === false ? false : true;
        } else {
          status = true;
        }
      }
      derStatus[4] = status;
    } else {
      derStatus[4] = false;
    }
    localStorage.setItem("derStatus", JSON.stringify(derStatus));
    detailsModifiedStataus();
  };

  const updateEvCbHistory = (index) => {
    let derStatus = JSON.parse(localStorage.getItem("derStatus"));
    let cbEvHistory = [];
    if (derStatus[6] === true) {
      cbEvHistory = JSON.parse(localStorage.getItem("cbEvHistory"));
    }

    if (cbEvHistory.length) {
      delete cbEvHistory[index];
    }

    localStorage.setItem("cbEvHistory", JSON.stringify(cbEvHistory));
    checkCBStatus();
  };

  const updateLoadCbHistory = (index) => {
    if(localStorage.getItem("derStatus")){
    let derStatus = JSON.parse(localStorage.getItem("derStatus"));
    let cbLoadHistory = [];
    if (derStatus[6] === true && localStorage.getItem("cbLoadHistory")) {
      cbLoadHistory = JSON.parse(localStorage.getItem("cbLoadHistory"));
    }

    if (cbLoadHistory.length) {
      delete cbLoadHistory[index];
    }
    localStorage.setItem("cbLoadHistory", JSON.stringify(cbLoadHistory));}
    checkCBStatus();
  };

  const updateAssetCbHistory = (index) => {
    if(localStorage.getItem("derStatus")){
    let derStatus = JSON.parse(localStorage.getItem("derStatus"));
    let cbAssetHistory = [];
    if (derStatus[6] === true) {
      cbAssetHistory = localStorage?.getItem("cbAssetHistory") &&JSON?.parse(localStorage?.getItem("cbAssetHistory"));
    }

    if (cbAssetHistory.length) {
      delete cbAssetHistory[index];
    }
  
    localStorage.setItem("cbAssetHistory", JSON.stringify(cbAssetHistory));
  }
    checkCBStatus();
  };

  const updateAssetPmHistory = (index) => {
    if(localStorage.getItem("derStatus")){

    let derStatus = JSON.parse(localStorage.getItem("derStatus"));
    let pmAssetHistory = [];
    if (derStatus[8] === true) {
      pmAssetHistory = JSON.parse(localStorage.getItem("pmAssetHistory"));
    }
  

    if (pmAssetHistory.length) {
      delete pmAssetHistory[index];
    }

    localStorage.setItem("pmAssetHistory", JSON.stringify(pmAssetHistory));
  }
    checkPmStatus();
  };

  const updateEvPmHistory = (index) => {
    if(localStorage.getItem("derStatus")){

    let derStatus = JSON.parse(localStorage.getItem("derStatus"));
    let pmEvHistory = [];
    if (derStatus[8] === true) {
      pmEvHistory = JSON.parse(localStorage.getItem("pmEvHistory"));
    }
  

    if (pmEvHistory.length) {
      delete pmEvHistory[index];
    }
    localStorage.setItem("pmEvHistory", JSON.stringify(pmEvHistory))}
    checkPmStatus();
  };

  const checkCBStatus = () => {
    if(localStorage.getItem("derStatus")){

    let derStatus = JSON.parse(localStorage.getItem("derStatus"));

    let cbLoadHistory = [];
    if (localStorage.getItem("cbLoadHistory") === "") {
      cbLoadHistory = [];
    } else {
      cbLoadHistory = JSON.parse(localStorage.getItem("cbLoadHistory"));
    }
    let status = false;
    if (cbLoadHistory.length) {
      for (let i = 0; i < cbLoadHistory.length; i++) {
        if (cbLoadHistory[i] === null) {
          status = status === false ? false : true;
        } else if (cbLoadHistory[i] === undefined) {
          status = status === false ? false : true;
        } else {
          status = true;
        }
      }
    } else {
      status = status === false ? false : true;
    }
  

    let cbAssetHistory = [];
    if (localStorage.getItem("cbAssetHistory") === "") {
      cbAssetHistory = [];
    } else {
      cbAssetHistory = JSON.parse(localStorage.getItem("cbAssetHistory"));
    }

    if (cbAssetHistory.length) {
      for (let i = 0; i < cbAssetHistory.length; i++) {
        if (cbAssetHistory[i] === null) {
          status = status === false ? false : true;
        } else if (cbAssetHistory[i] === undefined) {
          status = status === false ? false : true;
        } else {
          status = true;
        }
      }
    } else {
      status = status === false ? false : true;
    }

    let cbEvHistory = [];
    if (localStorage.getItem("cbEvHistory") === "") {
      cbEvHistory = [];
    } else {
      cbEvHistory = JSON.parse(localStorage.getItem("cbEvHistory"));
    }
    if (cbEvHistory.length) {
      for (let i = 0; i < cbEvHistory.length; i++) {
        if (cbEvHistory[i] === null) {
          status = status === false ? false : true;
        } else if (cbEvHistory[i] === undefined) {
          status = status === false ? false : true;
        } else {
          status = true;
        }
      }
    } else {
      status = status === false ? false : true;
    }

    derStatus[6] = status;
    localStorage.setItem("derStatus", JSON.stringify(derStatus));
  }
    detailsModifiedStataus();
  };

  const checkPmStatus = () => {
    if(localStorage.getItem("derStatus")){
    
    let derStatus = JSON.parse(localStorage.getItem("derStatus"));

    let pmAssetHistory = [];
    if (localStorage.getItem("pmAssetHistory") === "") {
      pmAssetHistory = [];
    } else {
      pmAssetHistory = JSON.parse(localStorage.getItem("pmAssetHistory"));
    }
    let status = false;
    if (pmAssetHistory.length) {
      for (let i = 0; i < pmAssetHistory.length; i++) {
        if (pmAssetHistory[i] === null) {
          status = status === false ? false : true;
        } else if (pmAssetHistory[i] === undefined) {
          status = status === false ? false : true;
        } else {
          status = true;
        }
      }
    } else {
      status = status === false ? false : true;
    }

    let pmEvHistory = [];
    if (localStorage.getItem("pmEvHistory") === "") {
      pmEvHistory = [];
    } else {
      pmEvHistory = JSON.parse(localStorage.getItem("pmEvHistory"));
    }

    if (pmEvHistory.length) {
      for (let i = 0; i < pmEvHistory.length; i++) {
        if (pmEvHistory[i] === null) {
          status = status === false ? false : true;
        } else if (pmEvHistory[i] === undefined) {
          status = status === false ? false : true;
        } else {
          status = true;
        }
      }
    } else {
      status = status === false ? false : true;
    }

    derStatus[8] = status;
    localStorage.setItem("derStatus", JSON.stringify(derStatus));
  }
    detailsModifiedStataus();
  };

  const updatePvHistoryFull = (mainIndex) => {
    if(localStorage.getItem("derStatus")){

    let derStatus = JSON.parse(localStorage.getItem("derStatus"));
    let pvHistory = JSON.parse(localStorage.getItem("pvHistory"));
    let status = false;
    if (mainIndex === 3) {
      pvHistory.pv1 = [];
      status = false;
    } else if (mainIndex === 4) {
      pvHistory.pv2 = [];
      status = false;
    } else if (mainIndex === 5) {
      pvHistory.pv3 = [];
      status = false;
    }

    derStatus[3] = status;
    localStorage.setItem("derStatus", JSON.stringify(derStatus));
    localStorage.setItem("pvHistory", JSON.stringify(pvHistory));
  }
    detailsModifiedStataus();
  };

  const updatePvHistory = (subIndex, mainIndex) => {
    if(localStorage.getItem("derStatus")){

    let derStatus = JSON.parse(localStorage.getItem("derStatus"));
    let pvHistory = JSON.parse(localStorage.getItem("pvHistory"));
    let status = false;
    if (mainIndex === 3) {
      if (pvHistory.pv1.length) {
        delete pvHistory.pv1[subIndex];
        for (let i = 0; i < pvHistory.pv1.length; i++) {
          if (pvHistory.pv1[i] === null) {
            status = status === false ? false : true;
          } else if (pvHistory.pv1[i] === undefined) {
            status = status === false ? false : true;
          } else {
            status = true;
          }
        }
      } else {
        status = false;
      }
    } else if (mainIndex === 4) {
      if (pvHistory.pv2.length) {
        delete pvHistory.pv2[subIndex];
        for (let i = 0; i < pvHistory.pv2.length; i++) {
          if (pvHistory.pv2[i] === null) {
            status = status === false ? false : true;
          } else if (pvHistory.pv2[i] === undefined) {
            status = status === false ? false : true;
          } else {
            status = true;
          }
        }
      } else {
        status = false;
      }
    } else if (mainIndex === 5) {
      if (pvHistory.pv3.length) {
        delete pvHistory.pv3[subIndex];
        for (let i = 0; i < pvHistory.pv2.length; i++) {
          if (pvHistory.pv2[i] === null) {
            status = status === false ? false : true;
          } else if (pvHistory.pv3[i] === undefined) {
            status = status === false ? false : true;
          } else {
            status = true;
          }
        }
      } else {
        status = false;
      }
    }

    derStatus[3] = status;
    localStorage.setItem("derStatus", JSON.stringify(derStatus));
    localStorage.setItem("pvHistory", JSON.stringify(pvHistory));
  }
    detailsModifiedStataus();
  };

  const updateDerStatus = (index) => {
    if(localStorage.getItem("derStatus")){

    let derStatus = JSON.parse(localStorage.getItem("derStatus"));
    derStatus[index] = false;
    localStorage.setItem("derStatus", JSON.stringify(derStatus));
    }
    detailsModifiedStataus();
  };

  const updatePvHistoryGroupPower = (selectedPv) => {
    let pvGroupPower = JSON.parse(localStorage.getItem("pvGroupPower"));
    if (selectedPv === 3) {
      pvGroupPower.pv1 = "";
    } else if (selectedPv === 4) {
      pvGroupPower.pv2 = "";
    } else if (selectedPv === 5) {
      pvGroupPower.pv3 = "";
    }
    localStorage.setItem("pvGroupPower", JSON.stringify(pvGroupPower));
  };

  const FindNode = () => document.getElementById("SLD-Image");

  useImperativeHandle(ref, () => ({
    FindNode,
  }));

  const getCorespondingPlateCapacity = (obj, key) => {
    const objNew = JSON.parse(obj);
    for (const objKey in objNew) {
      if (objKey === key) {
        return objNew[objKey];
      }
    }
    return null;
  };

  const handleChangeBatteryRack = (e) => {
    setNamePlateEnergy(e.target.value);
    const plateCapacityValue = getCorespondingPlateCapacity(
      configData.configData.corresponding_name_plate_capacity,
      e.target.value
    );
    CBAssetListDispatch({
      type: ACTIONS.UPDATE_BATTERY_CAPACITY_ON_BATTERY_RACK,
      payload: {
        index: 7,
        storage_capcity_hr_value: e.target.value,
        corresponding_name_plate_capacity_value: plateCapacityValue,
      },
    });
    let configDataNew = { ...configData.configData };
    configDataNew["storage_capcity_hr_value"] = e.target.value;
    configDataNew["corresponding_name_plate_capacity_value"] =
      plateCapacityValue;
    setConfigData((prevState) => ({ ...prevState, configData: configDataNew }));
  };
  const handleChangeBessHybridBatteryRack = (e, index) => {
    setNamePlateEnergy(e.target.value);
    const plateCapacityValue = getCorespondingPlateCapacity(
      configData.configData.corresponding_name_plate_capacity,
      e.target.value
    );
    CBAssetListDispatch({
      type: ACTIONS.UPDATE_BATTERY_CAPACITY_ON_BATTERY_RACK,
      payload: {
        index: 2,
        storage_capcity_hr_value: e.target.value,
        capacity: plateCapacityValue,
      },
    });
    let configDataNew = { ...configData.configData };
    configDataNew["storage_capcity_hr_value"] = e.target.value;
    configDataNew["capacity"] = plateCapacityValue;
    setConfigData((prevState) => ({ ...prevState, configData: configDataNew }));
  };

  const handleSwitchboardTypeChangeGSX = (switchType) => {
    setSwitchboardType(switchType);
    let x21, x22, x11, y11, y21, x12, y12, y22;
    if(switchType===embLabels.NEW_SWITCHBOARD){
      const removeRemoteLoad = [];
      const loadData = [...CBLoadList]
      loadData.forEach((load,index)=>{
        if(load.uiData.isRemoteLoad){
          removeRemoteLoad.push(index)
           countDispatch({
            type:  ACTIONS.UPDATE_REMOTE_LOAD_COUNT,
            payload: {count: -1}
          });
        }
      })
      if(removeRemoteLoad?.length>0){
        CBLoadListDispatch({
          type:  ACTIONS.REMOVE_REMOTE_LOAD,
          payload: {
            indexes: removeRemoteLoad
          }
        });
      }
    }
    assetDetailsReset();
    // if(siteType === embLabels.ISLANDABLE_GSX && projectTypeValue === "DaaS ANSI"){
    //   CBAssetListDispatch({
    //     type: ACTIONS.UPDATE_GENSET_EM,
    //     payload:{
    //       switchboardType:switchType,
    //     }
    //   })
    // }
    if (
      siteType === embLabels.ISLANDABLE &&
      projectTypeValue !== "MGaaS ANSI"
    ) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES,
        payload: { coordinates: coordinates },
      });
      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES,
        payload: { coordinates: coordinates, count: count, siteType:siteType, projectKind: projectKind, switchboardType },
      });
      CBEVListDispatch({
        type: ACTIONS.UPDATE_CBEVLIST_COORDINATES,
        payload: { coordinates: coordinates, count: count },
      });
    }

    if (siteType === embLabels.GRID_CONNECTED && clientType === "GENERIC") {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
        payload: { coordinates: coordinates },
      });

      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_GRIDCONNECTED,
      });
      CBEVListDispatch({
        type: ACTIONS.UPDATE_CBEVLIST_COORDINATES_GRIDCONNECTED,
        payload: { coordinates: coordinates },
      });
    }
    if (
      (siteType === embLabels.ISLANDABLE_GSX &&
        clientType === embLabels.GREENSTRUXURE_PROJECT &&
        architectureType === embLabels.PV_GENSET_ATS &&
        defaultPreFillData[1].mode === "FUEL_SAVER") ||
      (siteType === embLabels.ISLANDABLE && projectTypeValue === "MGaaS ANSI")
    ) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVGENSET_ATS_SETTING_MODE,
        payload: {
          switchType,
          clientType,
          mode: defaultPreFillData[1]?.mode,
          loadSide:loadSide,
        }
      });

      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_GENSET_LOAD,
        payload: { coordinates: coordinates, count: count, projectKind: projectKind, switchboardType: switchboardType },
      });
    }
    let coOrdinates = {
      x11,
      y11,
      x21,
      y21,
      x12,
      y12,
      x22,
      y22,
    };

    // --------------------

    if (
      siteType === embLabels.ISLANDABLE &&
      projectTypeValue !== "MGaaS ANSI"
    ) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES,
        payload: { coordinates: coordinates },
      });
      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES,
        payload: { coordinates: coordinates, count: count, siteType:siteType, projectKind: projectKind ,switchboardType },
      });
      CBEVListDispatch({
        type: ACTIONS.UPDATE_CBEVLIST_COORDINATES,
        payload: { coordinates: coordinates, count: count },
      });
    }
    if (siteType === embLabels.PV_HYBRIDIZATION) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVHYBRIDIZATION,
        payload: { coordinates: coordinates },
      });
      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVHYBRIDIZATION,
      });
      setCoordinates((prevCoordinates) => ({
        ...prevCoordinates,
        busbarCordinates: {
          ...prevCoordinates.busbarCordinates,
          x11: "426",
          y11: "360",
          x21: "815",
          y21: "360",
        },
      }));

      checkAssetStatus();
    }

    if (
      siteType === embLabels.ISLANDABLE_GSX &&
      clientType === embLabels.GREENSTRUXURE_PROJECT &&
      architectureType === embLabels.PV_GENSET_ATS &&
      defaultPreFillData[1].mode !== "FUEL_SAVER"
    ) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVGENSET_ATS,
      });
      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVGENSET_ATS_OP,
        payload: "switchType",
      });

      checkAssetStatus();
    }

    if (
      (siteType === embLabels.ISLANDABLE_GSX &&
        clientType === embLabels.GREENSTRUXURE_PROJECT &&
        architectureType === embLabels.PV_GENSET_ATS &&
        defaultPreFillData[1].mode === "FUEL_SAVER") ||
      (siteType === embLabels.ISLANDABLE && projectTypeValue === "MGaaS ANSI")
    ) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVGENSET_ATS_SETTING_MODE,
        payload: {
          switchType,
          clientType,
          mode: defaultPreFillData[1]?.mode,
          loadSide:loadSide,
        }
      });

      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_GENSET_LOAD,
        payload: { coordinates: coordinates, count: count, projectKind: projectKind, switchboardType: switchboardType },
      });

      checkAssetStatus();
    }

    if (
      (siteType === embLabels.GRID_CONNECTED_GSX &&
        clientType === embLabels.GREENSTRUXURE_PROJECT &&
        architectureType === embLabels.PV_BESS_NR) ||
      (siteType === embLabels.GRID_CONNECTED &&
        projectTypeValue === "MGaaS ANSI")
    ) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVBESS_NR,
        payload: {
          switchType: switchboardType,
          mode: "",
          clientType:"",
          loadSide:loadSide
        }
      });

      checkAssetStatus();
    }

    if (
      siteType === embLabels.GENSET_PVBESS_A2E &&
      clientType === embLabels.A2E_PROJECT
    ) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GENSET_PVBESS_A2E,
        payload: { coordinates: coordinates },
      });
      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVHYBRIDIZATION,
      });
      // setCoordinates((prevCoordinates) => ({
      //   ...prevCoordinates,
      //   busbarCordinates: {
      //     ...prevCoordinates.busbarCordinates,
      //     x11: "426",
      //     y11: "360",
      //     x21: "815",
      //     y21: "360",
      //   },
      // })
      // );

      checkAssetStatus();
    }

    // ---------------

    if (projectTypeValue === "DaaS IEC") {
      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVBESS_NR,
        payload: switchType,
      });
    }
    if(isMgaasIecIsland){
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_ISLANDABLE,
        payload: {
          switchType: switchboardType,
          mode: defaultPreFillData[1]?.mode,
          projectKind: projectKind,
        },
      });
    }
    const coOrdinateLists = coOrdinateValidator(
      coOrdinates,
      switchType,
      siteType,
      projectTypeValue,
      count,
      rectangleWidth,
      loadSide,
      clientType,
      defaultPreFillData[1]?.mode,
      projectKind,
    );
    setRectangleWidth(coOrdinateLists.rectangleWidth);
    setCoordinates((prevCoordinates) => ({
      ...prevCoordinates,
      busbarCordinates: {
        ...prevCoordinates.busbarCordinates,
        ...coOrdinateLists.cooridinates,
      },
    }));
    if (
      siteType === embLabels.GRID_CONNECTED_GSX &&
      projectTypeValue === "DaaS ANSI"
    ) {
      setLoadSide(coOrdinateLists.loadSide);
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVBESS_NR,
        payload: {
          switchType,  
          mode: "",
          clientType:"",
          loadSide:loadSide
        }
      });
    }
    if (
      siteType === embLabels.GRID_CONNECTED &&
      projectTypeValue === "MGaaS ANSI"
    ) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVBESS_NR,
        payload: {
          switchType,
          mode: "",
          clientType:"",
          loadSide:loadSide
        }
      });
      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVGENSET_ATS_OP,
        payload: "switchType",
      });
    }

    if (
      siteType === embLabels.GRID_CONNECTED_GSX &&
      projectTypeValue === "DaaS IEC"
    ) {
      setRectangleWidth(coOrdinateLists.rectangleWidth);
      setLoadSide(coOrdinateLists.loadSide);
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVBESS_NR,
        payload: {
          switchType,
          mode: "",
          clientType:"",
          loadSide:loadSide
        }
      });
    }

    if (
      siteType === embLabels.ISLANDABLE_GSX &&
      projectTypeValue === "DaaS IEC"
    ) {
      setRectangleWidth(coOrdinateLists.rectangleWidth);
      setLoadSide(coOrdinateLists.loadSide);
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVGENSET_ATS_SETTING_MODE,
        payload: {
          switchType,
          clientType:"",
          mode: "",
          loadSide:loadSide,
        }
      });

      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_GENSET_LOAD,
        payload: { coordinates: coordinates, count: count, projectKind: projectKind, switchboardType: switchboardType },
      });
    }

    //000000000000000000000000

    setSwitchboardType(switchType);
    if (siteType === embLabels.GRID_CONNECTED && clientType === "GENERIC") {
      let x21 =
        count.derCount === 2
          ? "695"
          : count.derCount === 3
          ? "910"
          : count.derCount === 4
          ? "1015"
          : count.derCount === 5
          ? switchType === embLabels.EXISTING_SWITCHBOARD
            ? "1145"
            : "1045"
          : "795";

      if (switchType === "EXISTING_SWITCHBOARD") {
        setCoordinates((prevCoordinates) => ({
          ...prevCoordinates,
          xDerGrid: "480",
          xDerCBDiff: "700",
          yDerGrid: "243",
          yDerCBDiff: "370",
          xDerGridPM: "605",
          yDerGridPM: "227.5",
          cbEV: {
            xDerEV: 597.5,
            yDerEV: 492,
            xDerPM: 545,
            yDerPM: 487.5,
            x1DerEVCB: 675,
            y1DerEVCB: 570,
            x2DerEVCB: 675,
            y2DerEVCB: 464,
          },
          busbarCordinates: {
            ...prevCoordinates.busbarCordinates,
            x11: "126",
            y11: "280",
            x21: "345",
            y21: "280",
            x12: "620",
            y12: "470",
            x22: x21,
            y22: "470",
          },
        }));
      } else {
        let x21 =
          count.derCount === 2
            ? "695"
            : count.derCount === 3
            ? "810"
            : count.derCount === 4
            ? "915"
            : count.derCount === 5
            ? "1045"
            : "795";

        setCoordinates((prevCoordinates) => ({
          ...prevCoordinates,
          xDerGrid: "370",
          xDerCBDiff: "590",
          yDerGrid: "141",
          yDerCBDiff: "269",
          xDerGridPM: "495",
          yDerGridPM: "127.5",
          cbEV: {
            xDerEV: 477.5,
            yDerEV: 553,
            xDerPM: 425,
            yDerPM: 617.5,
            x1DerEVCB: 555,
            y1DerEVCB: 463,
            x2DerEVCB: 555,
            y2DerEVCB: 367,
          },
          busbarCordinates: {
            ...prevCoordinates.busbarCordinates,
            x11: "426",
            y11: "370",
            x21: x21,
            y21: "370",
            x12: "700",
            y12: "470",
            x22: "900",
            y22: "470",
          },
        }));
      }

      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
        payload: { coordinates: coordinates },
      });

      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_GRIDCONNECTED,
      });
      CBEVListDispatch({
        type: ACTIONS.UPDATE_CBEVLIST_COORDINATES_GRIDCONNECTED,
        payload: { coordinates: coordinates },
      });
    }
    if (
      (siteType === embLabels.ISLANDABLE_GSX &&
        clientType === embLabels.GREENSTRUXURE_PROJECT &&
        architectureType === embLabels.PV_GENSET_ATS &&
        defaultPreFillData[1].mode === "FUEL_SAVER") ||
      (siteType === embLabels.ISLANDABLE && projectTypeValue === "MGaaS ANSI")
    ) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVGENSET_ATS_SETTING_MODE,
        payload: {
          switchType,
          clientType,
          mode: defaultPreFillData[1]?.mode,
          loadSide:loadSide,
        }
      });
      // CBLoadListDispatch({
      //   type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVGENSET_ATS,
      // });

      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_GENSET_LOAD,
        payload: { coordinates: coordinates, count: count, projectKind: projectKind, switchboardType: switchboardType },
      });

      let x22;
      if (count.derCount <= 3 && count.loadCount <= 4) {
        x22 = "1095";
      } else if (count.derCount <= 4 && count.loadCount <= 4) {
        siteType === "GRID_CONNECTED_GSX" ||
        (siteType === "ISLANDABLE_GSX" &&
          (switchType === "" || switchType === embLabels.EXISTING_SWITCHBOARD))
          ? (x22 = "1095")
          : (x22 = "510");
      } else if (count.derCount <= 4 && count.loadCount === 5) {
        switchType === embLabels.NEW_SWITCHBOARD &&
        siteType === "ISLANDABLE_GSX"
          ? (x22 = 570)
          : (x22 = "1170");
      } else if (count.loadCount === 10) {
        switchType === embLabels.NEW_SWITCHBOARD &&
        siteType === "ISLANDABLE_GSX"
          ? (x22 = 650)
          : (x22 = "1250");
      } else {
        {
          siteType === "ISLANDABLE_GSX" &&
          switchType === embLabels.NEW_SWITCHBOARD
            ? (x22 = "620")
            : (x22 = "1170");
        }
      }

      // x21: "594",

      setCoordinates((prevCoordinates) => ({
        ...prevCoordinates,
        busbarCordinates: {
          ...prevCoordinates.busbarCordinates,
          x11: "158",
          y11: "285",
          x21: "330",
          y21: "285",
          x12: "630",
          y12: "430",
          x22: x22,
          y22: "430",
        },
      }));
    }

    if (
      (siteType === embLabels.GRID_CONNECTED_GSX &&
        clientType === embLabels.GREENSTRUXURE_PROJECT &&
        architectureType === embLabels.PV_BESS_NR) ||
      (projectTypeValue === "MGaaS ANSI" &&
        siteType === embLabels.GRID_CONNECTED)
    ) {
      const x21 = count.derCount === 2 ? "815" : "715";
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVBESS_NR,
        payload: {
          switchType,
          mode: "",
          clientType:"",
          loadSide:loadSide
        }
      });
      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVBESS_NR,
        payload: switchType,
      });
      let x22;
      if (count.derCount > 3) {
        setRectangleWidth(530);
        if (
          ["GRID_CONNECTED_GSX", "GRID_CONNECTED"].includes(siteType) &&
          (switchType === "" || switchType === embLabels.EXISTING_SWITCHBOARD)
        ) {
          x22 = 1065;
        } else {
          siteType === "ISLANDABLE_GSX" &&
          switchType === embLabels.NEW_SWITCHBOARD
            ? (x22 = 690)
            : (x22 = 640);
          setRectangleWidth(730);
        }
      } else {
        if (
          !["GRID_CONNECTED_GSX", "GRID_CONNECTED"].includes(siteType) ||
          (siteType !== "ISLANDABLE_GSX" &&
            (switchType === "" ||
              switchType === embLabels.EXISTING_SWITCHBOARD))
        ) {
          setRectangleWidth(420);
          x22 = 965;
        } else {
          setRectangleWidth(580);
          !["GRID_CONNECTED_GSX", "GRID_CONNECTED"].includes(siteType) ||
          (siteType !== "ISLANDABLE_GSX" &&
            (switchType !== "" ||
              switchType !== embLabels.EXISTING_SWITCHBOARD))
            ? (x22 = 510)
            : (x22 = 965);
        }
      }
      setCoordinates((prevCoordinates) => ({
        ...prevCoordinates,
        busbarCordinates: {
          ...prevCoordinates.busbarCordinates,
          x11: "168",
          y11: "283",
          x21: "408",
          y21: "283",
          x12: "630",
          y12: "430",
          x22,
          y22: "430",
        },
      }));

      checkAssetStatus();
    }
    if(siteType === embLabels.ISLANDABLE && projectTypeValue === "MGaaS ANSI"){
      if(switchboardType === embLabels.EXISTING_SWITCHBOARD){
        setCoordinates((prevCoordinates) => ({
          ...prevCoordinates,
          cbEV: {
            xDerEV: 250,
            yDerEV: 290,
            xDerPM: 475,
            yDerPM: 490,
            x1DerEVCB: 608,
            y1DerEVCB: 570,
            x2DerEVCB: 608,
            y2DerEVCB: 470,
          },
                
        }));
      }else{
        setCoordinates((prevCoordinates) => ({
          ...prevCoordinates,
          cbEV: {
            xDerEV: 80,
            yDerEV: 320,
            xDerPM: 25,
            yDerPM: 320,
            x1DerEVCB: 377.5,
            y1DerEVCB: 398.5,
            x2DerEVCB: 378.5,
            y2DerEVCB: 300,
          },  
             
        }));
      }
    }
    if(switchboardType===embLabels.EXISTING_SWITCHBOARD&&(isMgaasAnsiIsland||isDaasAnsiIsland)){
      CBLoadListDispatch({
        type: ACTIONS.UPDATE_REMOTE_LOAD_COORDINATES,
        payload: { coordinates: coordinates,loadSide:loadSide, count: count ,siteType:siteType, projectKind: projectKind,switchboardType},
      });
    }
    CBEVListDispatch({
      type: ACTIONS.UPDATE_CBEVLIST_COORDINATES_GRIDCONNECTED,
      payload: { coordinates: coordinates, count: count },
    });

    setCoordinates((prevCoordinates) => ({
      ...prevCoordinates,
      busbarCordinates: {
        ...prevCoordinates.busbarCordinates,
        ...coOrdinateLists.cooridinates,
      },
    }));
    setRectangleWidth(coOrdinateLists.rectangleWidth);

    const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
    setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
    const runCalculateCoordinatesNew = { componentPush: false };
    setRunCalculateCoordinates(runCalculateCoordinatesNew);
  };
 
 
  const handleSetLoadSide = (e) => {
    setLoadSide(e.target.value);
    const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
   if(isMgaasAnsiIsland || isDaasAnsiIsland){
    CBAssetListDispatch({
      type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVGENSET_ATS_SETTING_MODE,
      payload: {
        switchType: switchboardType,
        mode: defaultPreFillData[1]?.mode,
        clientType: "GENERIC",
        loadSide:e.target.value,
      },
    });
   }
   
   if(isDaasAnsiGrid || isMgaasAnsiGrid){
    CBAssetListDispatch({
      type:ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVBESS_NR,
      payload: {
        switchType: switchboardType,
        mode: defaultPreFillData[1]?.mode,
        clientType: "GENERIC",
        loadSide:e.target.value,
      },
    });
   }
    setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);

  };

  const onAddPvExisiting = () => {
    CBAssetListDispatch({
      type: ACTIONS.ADD_PV_EXISTING,
    });
    const runCalculateCoordinatesNew = { componentPush: true };

    setTimeout(() => {
      setRunCalculateCoordinates(runCalculateCoordinatesNew);
    }, 100);
  };

  const onAddBatteryExisting = () => {
    CBAssetListDispatch({
      type: ACTIONS.ADD_BESS_EXISTIING,
    });
    const runCalculateCoordinatesNew = { componentPush: true };

    setTimeout(() => {
      setRunCalculateCoordinates(runCalculateCoordinatesNew);
    }, 100);
  };

  const removeExistingPm = () => {
    CBAssetListDispatch({
      type: ACTIONS.REMOVE_ASSET_EXISTING,
      payload: {
        selectedPv: selectedValues.selectedAssetPM,
      },
    });
    setDetailsBlock((prevState) => ({
      ...prevState,
      existingPmAssetDetailsBlockGrid: false,
    }));

    const runCalculateCoordinatesNew = { componentPush: true };

    setTimeout(() => {
      setRunCalculateCoordinates(runCalculateCoordinatesNew);
    }, 100);
  };

  const handleCbAssetRequired = (index) => {
    let dummyCbData = null;
    if (siteType === embLabels.GRID_CONNECTED) {
      dummyCbData = { ...defaultDummyCbData };
    }

    CBAssetListDispatch({
      type: ACTIONS.CB_ASSET_REQUIRED,
      payload: {
        index: index,
        defaultCbData: defaultCbData,
        defaultValues: defaultPreFillData[0],
        dummyCbData,
      },
    });
  };

  const handleLoadCBAssetRequired = (index) => {
    CBLoadListDispatch({
      type: ACTIONS.LOAD_CB_ASSET_REQUIRED,
      payload:{
        index: index,
        defaultCbData: defaultCbData,
        defaultValues: defaultPreFillData[0],
      }
    })
  }

  const handlePmAssetRequired = (index) => {
    CBAssetListDispatch({
      type: ACTIONS.PV_ASSET_PM_REQUIRED,
      payload: {
        index: index,
        defaultPmData: defaultPmData,
        defaultValues: defaultPreFillData[0],
      },
    });
  };

  const handleLoadPmAssetRequired = (index) => {
    CBLoadListDispatch({
      type: ACTIONS.LOAD_PM_ASSET_REQUIRED,
      payload:{
        index: index,
        defaultPmData: defaultPmData,
        defaultValues: defaultPreFillData[0],
      }
    })
  }

  const handleEmAssetRequired = (index) => {
    CBAssetListDispatch({
      type: ACTIONS.PV_ASSET_EM_REQUIRED,
      payload: {
        index: index,
        defaultPmData: defaultPmData,
        defaultValues: defaultPreFillData[0],
      },
    });
  };
  const handleRemoteMain = ()=>{
    setRemoteMain(prev => !prev);
    const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
    setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
    const runCalculateCoordinatesNew = { componentPush: false };
    setRunCalculateCoordinates(runCalculateCoordinatesNew);
  }
  //* LOCAL STORAGE
  const projectTypeValue = getProjectTypeValue()
  // JSX starts here
  return (
    <MyErrorBoundary>
      <div className={`container-fluid h-100 container-margin`}>
        <se-loading loading={loading}></se-loading>
        {!progressbarLoading ? (
          <div className="stickyProgressBar">
            <div className="d-flex justify-content-between">
              <se-skeleton width="493px" height="20px"></se-skeleton>
              <se-skeleton width="493px" height="20px"></se-skeleton>
              <se-skeleton width="493px" height="20px"></se-skeleton>
            </div>
          </div>
        ) : (
          projectStatusData && <ProgressBarContainer projectStatusData={projectStatusData} />
        )}

        <div className="row text-center mt-2">
          {projectStatusData?.projectStatus === "COMPLETED" && (
            <div className="col-sm-12 mt-1">
              <span className="se-error-message-note text-center">
                {`Change the project state to in-progress to update the project`}
              </span>
            </div>
          )}
          {siteCreated && (
            <div className="col-sm-12 mt-1">
              <span className="se-error-message-note text-center">
                {`Site already created for the project, no more modifications allowed`}
              </span>
            </div>
          )}
        </div>
        <div
          className={`${loading?"se-block-disabled":""} row h-100`}
          style={displayPage ? {} : { display: "none" }}
        >
          <AddEquipment
            loading= {loading}
            siteType={siteType}
            clientType={clientType}
            switchboardType={switchboardType}
            handleSwitchboardTypeChangeGSX={handleSwitchboardTypeChangeGSX}
            remoteMain = {remoteMain}
            setRemoteMain = {setRemoteMain}
            handleRemoteMain = {handleRemoteMain}
            architectureType={architectureType}
            onAddGensetVerify={addGensetVerify}
            onAddBattery={addBattery}
            onAddPv={addPv}
            onAddCHPVerify={addCHPVerify}
            onAddLoad={addLoad}
            onAddRemoteLoad={addRemoteLoad}
            onAddEv={addEv}
            onAddTransferSwitch={addTransferSwitch}
            gridSettingMode={defaultPreFillData[1]?.mode}
            projectStatus={projectStatusData?.projectStatus}
            siteCreated={siteCreated}
            count={count}
            PVDetails={PVDetails}
            availableDer={availableDer}
            useCases={useCases}
            handleSetLoadSide={handleSetLoadSide}
            loadSide={loadSide}
            onAddPvExisiting={onAddPvExisiting}
            onAddBatteryExisting={onAddBatteryExisting}
            allowSwitchBoard={
              CBAssetList[2].uiData.existingPmRequired ||
              CBAssetList[3].uiData.existingPmRequired
            }
            CBAssetList={CBAssetList}
            projectKind={projectKind}
            CBEVList={CBEVList}
            setRemoteConfirm={setRemoteConfirm}
            okConfirmMessageRemote={okConfirmMessageRemote}
            closeConfirmMessage={closeConfirmMessage}
            remoteConfirm={remoteConfirm}
            closeConfirmMessageRemote={closeConfirmMessageRemote}
          />
          <div className="col-sm-8 col-md-8 col-lg-8 custom-project-container background-SLD ">
            <DesignAssetDescription
              siteCreated={siteCreated}
              projectStatusData={projectStatusData}
            />
            <div className="row">
              <DesignAssetProvider
                value={{
                  clientType,
                  coordinates,
                  CBAssetList,
                  CBEVList,
                  CBLoadList,
                  PVDetails,
                  UserScope,
                  sldLoadCompleted,
                  count,
                  siteType,
                  switchboardType,
                  architectureType,
                  rectangleWidth,
                  pv1SelectHandler,
                  pv2SelectHandler,
                  pv3SelectHandler,
                  CBAssetListDispatch,
                  CBLoadListDispatch,
                  CBEVListDispatch,
                  PVDetailsDispatch,
                  assetDetailsReset,
                  callSnackbar,
                  selectedValuesHandler,
                  selectedSubPvHandler,
                  selectedValues,
                  selectedAssetHandler,
                  selectedAssetHandlerPv,
                  assetConfigDataHandler,
                  cbConfigDataHandler,
                  newSBCbConfigDataHandler,
                  loadConfigDataHandler,
                  loadCbConfigDataHandler,
                  pmConfigDataHandler,
                  existingPmConfigDataHandler,
                  loadpmConfigDataHandler,
                  evpmConfigDataHandler,
                  evConfigDataHandler,
                  evCbConfigDataHandler,
                  padNumberFormat,
                  detailsBlock,
                  loadSide,
                  projectTypeValue,
                  remoteMain,
                  projectKind,
                  projectStatusData
                }}
              >
                {clientType === embLabels.A2E_PROJECT &&
                  siteType === embLabels.GENSET_PVBESS_A2E && <A2ESLD />}
                {/* This calls mgaas iec islandable */}
                {clientType === embLabels.GENERIC_PROJECT &&
                  siteType === embLabels.ISLANDABLE &&
                  projectTypeValue !== "MGaaS ANSI" && <IslandableSLD />}
                {/* This calls mgaas iec grid connected */}
                {clientType === embLabels.GENERIC_PROJECT &&
                  siteType === embLabels.GRID_CONNECTED &&
                  projectTypeValue !== "MGaaS ANSI" && <GridConnectedRosette />}

                {clientType === embLabels.GENERIC_PROJECT &&
                  siteType === embLabels.PV_HYBRIDIZATION && (
                    <PVHybridizationSLD />
                  )}
                {/* for daas it never calls , IES never calls, Mgaas no call*/}
                {clientType === embLabels.GREENSTRUXURE_PROJECT &&
                  (siteType === embLabels.ISLANDABLE_GSX) &&
                  architectureType === embLabels.PV_GENSET_ATS &&
                  (defaultPreFillData[1].mode === "GENSET_MAIN_NO_BESS" || 
                    defaultPreFillData[1].mode === "NO_FUEL"
                  ) && (
                    <PvGensetAtsSLD />
                  )}
                {/* daas ansi grid connected / mgaas ansi islandable */}
                {((clientType === embLabels.GREENSTRUXURE_PROJECT &&
                  siteType === embLabels.ISLANDABLE_GSX &&
                  architectureType === embLabels.PV_GENSET_ATS &&
                  defaultPreFillData[1].mode === "FUEL_SAVER") ||
                  (clientType === embLabels.GENERIC_PROJECT &&
                    siteType === embLabels.ISLANDABLE &&
                    projectTypeValue === "MGaaS ANSI")) && <GensetPvBessSLD />}
                {/* Daas Ansi/ mgaas ansi gridconnected || Daas ansi Islandable*/}
                {((clientType === embLabels.GREENSTRUXURE_PROJECT &&
                  siteType === embLabels.GRID_CONNECTED_GSX &&
                  architectureType === embLabels.PV_BESS_NR) ||
                  (clientType === embLabels.GENERIC_PROJECT &&
                    siteType === embLabels.GRID_CONNECTED &&
                    projectTypeValue === "MGaaS ANSI")) && <PvBessNrSLD />}
              </DesignAssetProvider>
            </div>
          </div>

          {/* configuration*/}
          <div
            className={`col-sm-2 col-md-2 col-lg-2 custom-configuration-container border se-white-background ${
              siteCreated || projectStatusData?.projectStatus === "COMPLETED"
                ? "se-block-disabled"
                : ""
            } `}
          >
            <div className="row">
              <div className="col-md-12 pl-0 pr-0 border se-white-background">
                <div className="col-md-12 mt-2 se-life-green bold">
                  CONFIGURATION
                </div>
              </div>
              <AssetBlockContextProvider
                value={{
                  clientType,
                  configData,
                  defaultsMinMax,
                  assetDetailsDisplay,
                  projectTypeValue,
                  siteType,
                  projectTypeValue,
                  architectureType,
                  selectedValues,
                  PVDetails,
                  gsxExternalId,
                  CBAssetList,
                  CBEVList,
                  CBLoadList,
                  batteryRack,
                  namePlateEnergy,
                  jsonData,
                  isContactorEnabled,
                  CBAssetListDispatch,
                  CBEVListDispatch,
                  PVDetailsDispatch,
                  handleUtilityChange,
                  handleGensetChange,
                  handleBatteryChange,
                  handleChpChange,
                  handleHybridChange,
                  handleLoadChange,
                  handleEvChange,
                  handleCBConfigChange,
                  handleCBContactorConfigChange,
                  handleCBConfigChangeLoad,
                  handleCBConfigChangeEv,
                  updateUtilitytConfig,
                  updateGensetConfig,
                  updateBatteryConfig,
                  updateChpConfig,
                  updateHybridConfig,
                  updatePvArrayConfig,
                  updateLoadConfig,
                  updateEvConfig,
                  updatePowerMeterData,
                  updateCbConfigData,
                  updateCbContactorConfigData,
                  updateLoadCbConfigData,
                  updateEvCbConfigData,
                  setAssetSelection,
                  selectedAssetHandlerPv,
                  assetEditStatusUpdateDER,
                  assetEditStatusUpdateAssetCB,
                  assetEditStatusUpdateLoad,
                  assetEditStatusUpdateLoadCb,
                  assetEditStatusUpdateEvCb,
                  assetEditStatusUpdateAssetPM,
                  assetEditStatusUpdateEvPM,
                  assetEditStatusUpdatePv,
                  checkGensetRemoveCondition,
                  checkBatteryRemoveCondition,
                  removeCHP,
                  removePvWithAlert,
                  removePv,
                  removeLoad,
                  removeEv,
                  isCopyAllButtonDisabled,
                  copyAllPvs,
                  arrayMaxPowerFinder,
                  calculateMaxAvailableUtilityPower,
                  calculateMaxAvailableLoadPower,
                  calculateMaxAvailableGensetPower,
                  handleChangeBatteryRack,
                  handleChangeBessHybridBatteryRack,
                  gridSettingMode: defaultPreFillData[1]?.mode,
                  removeExistingPm,
                  defaultCbData,
                  handleCbAssetRequired,
                  handlePmAssetRequired,
                  handleEmAssetRequired,
                  projectType,
                  switchboardType,
                  projectKind,
                  handleLoadCBAssetRequired,
                  handleLoadPmAssetRequired,
                }}
              >
                {detailsBlock.assetUtilityDetailsBlock && <UtilityAsset />}
                {detailsBlock.assetGensetDetailsBlock &&
                  [embLabels.GRID_CONNECTED, embLabels.ISLANDABLE].includes(
                    siteType
                  ) && <GensetAsset />}
                {detailsBlock.assetGensetDetailsBlock &&
                  ([embLabels.PV_HYBRIDIZATION].includes(siteType) ||
                    ([embLabels.ISLANDABLE_GSX].includes(siteType) &&
                      architectureType === embLabels.PV_GENSET_ATS) ||
                    ([embLabels.GENSET_PVBESS_A2E].includes(siteType) &&
                      clientType === embLabels.A2E_PROJECT)) && (
                    <GensetAssetPvHyb />
                  )}
                {detailsBlock.assetBatteryDetailsBlock && <BatteryAsset />}
                {detailsBlock.assetPvDetailsBlock && <PVAsset />}
                {detailsBlock.assetCHPDetailsBlock && <CHPAsset />}
                {detailsBlock.assetHybridDetailsBlock && <HybridAsset />}
                {detailsBlock.assetBessHybridDetailsBlock && (
                  <BessHybridAsset />
                )}
                {detailsBlock.loadDetailsBlock && <LoadAsset />}
                {detailsBlock.cbLoadDetailsBlock && <LoadCB />}
                {detailsBlock.evDetailsBlock && <EvCluster />}
                {detailsBlock.cbEvDetailsBlock && <EvCB detailsBlock={detailsBlock} />}
                {detailsBlock.cbAssetDetailsBlock && (
                  <CircuitBreakerDetails
                    siteType={siteType}
                    architectureType={architectureType}
                    selectedValues={selectedValues}
                  />
                )}
                {detailsBlock.newSBCbAssetDetailsBlock && <GsxNewSBCbDetails />}
                {(detailsBlock.cbAssetDetailsBlockA2E ||
                  detailsBlock.pmAssetDetailsBlockA2E) && (
                  <CbPmDetailsA2E detailsBlock={detailsBlock} />
                )}
                {(detailsBlock.cbAssetDetailsBlockGrid ||
                  detailsBlock.pmAssetDetailsBlockGrid) && (
                  <CbPmDetailsGrid detailsBlock={detailsBlock} />
                )}
                {detailsBlock.existingPmAssetDetailsBlockGrid && (
                  <ExistingPmDetailsGrid detailsBlock={detailsBlock} />
                )}
                {detailsBlock.hardWiredCbDetailsBlockGrid && (
                  <HardWiredSBCbDetails />
                )}
                {isNoAssetSelected() && <NoAssetSelected />}
              </AssetBlockContextProvider>
            </div>
          </div>
        </div>

        <Snackbar snackBar={snackBar} ref={snackBarRef} />
        <AlertIcon alert={alert} onClose={closeAlertMessage} />
        <Confirm
          confirm={confirm}
          onClose={closeConfirmMessage}
          onOk={okConfirmMessage}
        />

        <AssetSelectionDER
          assetInfo={assetInfo}
          onSelectManufacturer={selectManufacturer}
          closeAssetSelectionBlock={closeAssetSelectionBlock}
          onSelectAssetInModal={selectAssetFromList}
        />

        <AssetSelectionPV
          siteType={siteType}
          architectureType={architectureType}
          assetDetailsDisplay={assetDetailsDisplay}
          PVDetails={PVDetails}
          gsxExternalId={gsxExternalId}
          onHandlePvChange={handlePvChange}
          onsetAssetSelection={setAssetSelection}
          assetEditStatusUpdatePv={assetEditStatusUpdatePv}
          defaultsMinMax={defaultsMinMax}
          onClosePvDetailsBlock={closePvDetailsBlock}
          onUpdatePvConfig={updatePvConfig}
        />

        <AddPvModals
          CBAssetList={CBAssetList}
          siteType={siteType}
          clientType={clientType}
          architectureType={architectureType}
          PVDetails={PVDetails}
          onPvDetailsDispatch={PVDetailsDispatch}
          onAddPVSetWithCB={addPVSetWithCB}
          gridSettingMode={defaultPreFillData[1]?.mode}
          projectKind={projectKind}
        />
      </div>
    </MyErrorBoundary>
  );
});

export default DesignAsset;
