import { useReducer } from "react";
import embLabels from "../config/emb-labels-en.json";

const getLineAndBusLength = (projectKind, switchType, count) => {
  let lineLength = 0;
  let busLength = 0;
  const { evCount, loadCount, derCount, remoteLoadCount } = count;

  const isExistingSwitchboard = switchType === embLabels.EXISTING_SWITCHBOARD;
  const isNewSwitchboard = switchType === embLabels.NEW_SWITCHBOARD;

  if (projectKind.isMgaasIecIsland) {
    if (isExistingSwitchboard) {
      if (evCount === 1 && loadCount === 1 && derCount === 2) {
        lineLength = 100;
        busLength = 400;
      } else if ((evCount === 1 && loadCount === 1 && derCount === 3) || (loadCount === 5 && derCount === 3)) {
        lineLength = 1100;
        busLength = 560;
      } else if ((loadCount >= 5 && evCount === 1 && derCount >= 3) ||( (derCount > 4 || loadCount >= 5) &&  evCount === 1)) {
        lineLength = 1140;
        busLength = 710;
      } 
      else if (evCount === 1 && (loadCount === 4) && derCount <= 4) {
        lineLength = 1120;
        busLength = 600;
      } else if (evCount === 1 && (loadCount > 2 && loadCount < 5) && derCount <= 3) {
        lineLength = 1250;
        busLength = 650;
      } else if (loadCount <= 2 && derCount <= 4) {
        lineLength = 965;
        busLength = 450;
      } else if (loadCount >= 5 && derCount <= 4 && evCount === 0) {
        lineLength = 1090;
        busLength = 560;
      } else if((loadCount+ evCount) <  5 && derCount <= 4){
        lineLength = 1000;
        busLength = 520;
      }
      if((evCount + loadCount)>=6 ){
        lineLength = evCount === 1  ?  1250 : 1150;
        busLength = evCount === 1  ? 710 : 650;
      }
      if(evCount === 0 && loadCount > 5){
        lineLength = 1150;
        busLength = 650;
      }
      if(loadCount === 1 && (derCount > 3 && derCount <6)){
        lineLength = 1150;
        busLength = 650;
      }
      if(derCount ===6 && loadCount >=1 && loadCount <5){
        lineLength = 1150;
        busLength = 650;
      }
      if(derCount ===5 && loadCount >=1 && loadCount <=4){
        lineLength =  evCount === 1 ? 1150 : 1100;
        busLength =  evCount === 1 ? 650 : 520;
      }

      if(derCount ===3 && loadCount >=1 && loadCount <=4){
        lineLength = 965;
        busLength = 450;
      }
      if(derCount ===4 && loadCount >=1 && loadCount <=3){
        lineLength = evCount === 1 ? 1000 :  965;
        busLength = evCount === 1 ? 500 :  450;
      }
      if(loadCount>=3 && loadCount<=4 && evCount===1){
        lineLength = 1150;
        busLength = 650;
      }
      
    } else if (isNewSwitchboard) {
      if ((evCount + loadCount >= 5 && derCount === 6) || derCount === 6) {
        lineLength = 1100;
        busLength = 790;
      } else if ((derCount === 5 && loadCount >= 5) || derCount === 5) {
        lineLength = 1050;
        busLength = 750;
      } else if (derCount === 4 && loadCount >= 5) {
        lineLength = loadCount === 10 ?  1060 : 1000;
        busLength = loadCount === 10 ? 730 : 710;
      }  if ((derCount < 3 && loadCount >= 5) || (derCount < 3 && loadCount >= 5)) {
        lineLength = 965;
        busLength = 670;
      }  if (loadCount < 5 && derCount === 4) {
        lineLength = 900;
        busLength = 630;
      }  if (loadCount < 5 && derCount < 4) {
        lineLength = 870;
        busLength = 610;
      }  if (loadCount <= 2 && derCount <= 2) {
        lineLength = 800;
        busLength = evCount === 1 ? 500 :470;
      }  if (loadCount <= 2 && derCount <= 3) {
        lineLength = 800;
        busLength = evCount === 1 ? 500 : 470;
      }  if (evCount === 1 && loadCount > 5) {
        lineLength = 1100;
        busLength = 790;
      } if(loadCount === 10 && evCount === 0){
        lineLength = 1100;
        busLength = 790;
      } if((loadCount >= 5 && loadCount <= 9) && evCount === 0 && derCount <=3){
        lineLength = 1000;
        busLength = 670;
      } if(loadCount === 5 && evCount === 1){
        lineLength = 1000;
        busLength = 690;
      }
    }
  }

  return { lineLength, busLength };
};
export const coOrdinateValidator = (
  coOrdinates,
  switchType,
  siteType,
  projectTypeValue,
  count,
  rectangleWidth,
  loadSide = "",
  clientType,
  mode="",
  projectKind,
) => {

  let {
    x11,
    y11,
    y21,
    x12,
    y22,
    y12,
    x21,
    x22,
    svgevx0,
    svgevy0,
    svgldx0,
    svgldy0,
    svgldx1,
    svgldy1,
    svgldx2,
    svgldy2,
    svgldx3, 
    svgldy3,
    svgldx4,
    svgldy4,
    svgldx5, 
    svgldy5,
    svgldx6,
    svgldy6,
    svgldx7, 
    svgldy7,
    svgldx8,
    svgldy8,
    svgldx9, 
    svgldy9,
    svgldx10,
    svgldy10,
    busStart,
    svgx,
    svgy,
    loadLine0,
    loadLine1,
    loadLine2,
    loadLine3,
    loadLine4,
    loadLine5,
    loadLine6,
    loadLine7,
    loadLine8,
    loadLine9,
  } = coOrdinates;
  if (
    siteType === embLabels.GRID_CONNECTED_GSX &&
    (projectTypeValue === "DaaS ANSI"||projectTypeValue === 'ANSI | Buildings | Autogrid Flex')
  ) {
    if (switchType === embLabels.NEW_SWITCHBOARD) {
      if (count.derCount === 2) {
        x11 = 158;
        y11 = 280;
        x21 = 400;
        y21 = 280;
        x12 = 800;
        y12 = 470;
        x22 = 520;
        y22 = 470;
      }
      if (count.derCount === 3) {
        x11 = 158;
        y11 = 285;
        x12 = 550;
        y12 = 300;
        x21 = 100;
        y21 = 300;
        x22 = 100;
        y22 = 300;
        rectangleWidth = 550;
      }
      if (count.derCount === 4) {
        x11 = 158;
        y11 = 285;
        x12 = 660;
        y12 = 300;
        x21 = 100;
        y21 = 300;
        x22 = 100;
        y22 = 300;
        rectangleWidth = 690;
      }
      if (count.derCount === 5) {
        x11 = 158;
        y11 = 285;
        x12 = 800;
        y12 = 300;
        x21 = 100;
        y21 = 300;
        x22 = 100;
        y22 = 300;
        rectangleWidth = 800;
      }
    } else if (switchType === "EXISTING_SWITCHBOARD") {
      if (count.derCount === 2) {
        x11 = 120;
        y11 = 285;
        x21 = 400;
        y21 = 285;
        x12 = 800;
        y12 = 430;
        x22 = 460;
        y22 = 430;
        rectangleWidth = 450;
      }
      if (count.derCount === 3) {
        x11 = 120;
        y11 = 285;
        x12 = 900;
        y12 = 428; //const
        x21 = 330;
        y21 = 285;
        x22 = 460;
        y22 = 428;
        rectangleWidth = 550;
      }
      if (count.derCount === 4) {
        x11 = 120;
        y11 = 285;
        x12 = 1000;
        y12 = 428;
        x21 = 330;
        y21 = 285;
        x22 = 460;
        y22 = 428; //existing sb
        rectangleWidth = 650;
      }

      if (count.derCount === 5) {
        x11 = 120;
        y11 = 285;
        x12 = 1100;
        y12 = 428;
        x21 = 330;
        y21 = 285;
        x22 = 460;
        y22 = 428;
        rectangleWidth = 800;
      }
      if(count.loadCount>=5){
        x11 = 120;
        y11 = 300;
        x21 = 750;
        y21 = 300;
        x12 = 650;
        y12 = 300;
        x22 = 100;
        y22 = 300;
      }
      if(count.loadCount===10){
        x11 = 700;
        y11 = 300;
        x21 = 120;
        y21 = 300;
        rectangleWidth = 700;
      }
    }
  } else if (
    siteType === embLabels.ISLANDABLE_GSX &&
    (projectTypeValue === "DaaS ANSI"||projectTypeValue === 'ANSI | Buildings | Autogrid Flex')
  ) {
    if (switchType === embLabels.NEW_SWITCHBOARD) {
      if (count.derCount === 3) {
        x11 = 650;
        y11 = 300;
        x21 = 110;
        y21 = 300;
        rectangleWidth = 650
        if(count.loadCount>=5){
          x11 = 700;
          y11 = 300;
          x21 = 110;
          y21 = 300;
          rectangleWidth = 720
        }
        if(count.loadCount===10){
          x11 = 700;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 720;
        }
      }
      if (count.derCount === 4) {
        x11 = 650;
        y11 = 300;
        x21 = 110;
        y21 = 300;
        rectangleWidth = 690;
        if(count.loadCount>=5){
          x11 = 750;
          y11 = 300;
          x21 = 110;
          y21 = 300;
          rectangleWidth = 750
        }
        if(count.loadCount===10){
          x11 = 800;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 800;
        }
      }
      if (count.derCount === 5) {
        x11 = 750;
        y11 = 300;
        x21 = 110;
        y21 = 300;
        rectangleWidth = 790;
        if(count.loadCount>=5){
          x11 = 750;
          y11 = 300;
          x21 = 110;
          y21 = 300;
          rectangleWidth = 790
        }
        if(count.loadCount===10){
          x11 = 800;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 800;
        }
      }
      if (count.derCount === 6) {
        x11 = 900;
        y11 = 300;
        x21 = 110;
        y21 = 300;
        rectangleWidth = 900;
        if(count.loadCount>=5){
          x11 = 900;
          y11 = 300;
          x21 = 110;
          y21 = 300;
          rectangleWidth = 900
        }
        if(count.loadCount===10){
          x11 = 900;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 900;
        }
      }
    } else if (switchType === "EXISTING_SWITCHBOARD") {            
      if (count.derCount === 3) {
        x11 = 70;
        y11 = 285;
        x12 = 1000;
        y12 = 428; //const
        x21 = 410;
        y21 = 285;
        x22 = count.loadCount>=5 ? 500 : 500;
        y22 = 428;
        rectangleWidth =  count.loadCount >=5 ? 670 : 500;
        if(count.derCount>=2&&count.loadCount>=5){
          x12 = 1100;
          y12 = 428;
          x22 = 570;
          y22 = 428;
          rectangleWidth = count.loadCount >=5 ? 670 : 650
        }
        if(count.loadCount===10){
          x12 = 1150;
          y12 = 428;
          x22 = 570;
          y22 = 428;
          rectangleWidth =  count.loadCount >=5 ? 670 : 650;
        }
      }
      if (count.derCount === 4) {
        x11 = 70;
        y11 = 285;
        x12 = 1020;
        y12 = 428;
        x21 = 410;
        y21 = 285;
        x22 = count.loadCount>=5 ? 500 :  500;
        y22 = 428; //existing sb
        rectangleWidth =count.loadCount >=5 ? 670 : 600;
        if(count.derCount>=2&&count.loadCount>=5){
          x12 = count.loadCount>5 ? 1250 : 1130;
          y12 = 428;
          x22 = count.loadCount>=5 ? 500 : 570;
          y22 = 428;
          rectangleWidth = count.loadCount >5 ? 800 : 710
        }
        if(count.loadCount>=6){
          rectangleWidth = 790;
        }
        if(count.loadCount===10){
          x12 = 1230;
          y12 = 428;
          x22 = count.loadCount>=5 ? 500 : 570;
          y22 = 428;
          rectangleWidth = 790;
        }
      }

      if (count.derCount === 5) {
        x11 = 70;
        y11 = 285;
        x12 = count.loadCount>=5 ? 1130 : 1100;
        y12 = 428;
        x21 = 400;
        y21 = 285;
        x22 = count.loadCount>=5 ? 500 :  500;
        y22 = 428;
        rectangleWidth = count.loadCount >=5 ? 670 : 650;
        if(count.derCount>=2&&count.loadCount>5){
          // x11 = 120;
          // y11 = 300;
          // x21 = 750;
          // y21 = 300;
          x12 = count.loadCount>=5 ?1250 : 1100;
          y12 = 428;
          x22 = count.loadCount>=5 ? 500 : 570;
          y22 = 428;
          rectangleWidth = count.loadCount >=5 ? 800 : 600
        }
        if(count.loadCount >=6){
          x12 = 1250;
          rectangleWidth = 790;
        }
        if(count.loadCount===10){
          x12 = 1250;
          y12 = 428;
          x22 = count.loadCount>=5 ? 500 :  570;
          y22 = 428;
          rectangleWidth = 790;
        }
      }
      if (count.derCount === 6) {
        x11 = count.remoteLoadCount ? 70 : 80;
        y11 = 285;
        x12 = 1250;
        y12 = 428;
        x21 = 400;
        y21 = 285;
        x22 = count.loadCount>=5 ? 500 :  500;
        y22 = 428;
        rectangleWidth = 800;
        if(count.loadCount>=5){
          x12 = 1250;
          y12 = 428;
          x22 = 500;
          y22 = 428;
          rectangleWidth = 800
        }
        if(count.loadCount===10){
          x12 = 1250;
          y12 = 428;
          x22 = 500;
          y22 = 428;
          rectangleWidth = 800;
        }
      }    
    }
  } else if (
    siteType === embLabels.GRID_CONNECTED_GSX &&
    (projectTypeValue === "DaaS IEC"||projectTypeValue === 'IEC | Buildings | Autogrid Flex')
  ) {
    if (switchType === embLabels.NEW_SWITCHBOARD) {
      if (count.derCount === 3) {
        x12 = 120;
        y12 = 300;
        x22 = 500;
        y22 = 300;
        rectangleWidth = 550;
      }
      if (count.derCount === 4) {
        x12 = 120;
        y12 = 300;
        x22 = 650;
        y22 = 300;
        rectangleWidth = 670;
      }
    } else if (switchType === "EXISTING_SWITCHBOARD") {
      if (count.derCount === 3) {
        x11 = 120;
        y11 = 285;
        x12 = 900;
        y12 = 428; //const
        x21 = 330;
        y21 = 285;
        x22 = 450;
        y22 = 428;
        rectangleWidth = 550;
      }
      if (count.derCount === 4) {
        x11 = 120;
        y11 = 285;
        x12 = 1000;
        y12 = 428;
        x21 = 330;
        y21 = 285;
        x22 = 450;
        y22 = 428;
        rectangleWidth = 650;
      }

      if (count.derCount === 5) {
        x11 = 120;
        y11 = 285;
        x12 = 1100;
        y12 = 428;
        x21 = 430;
        y21 = 285;
        x22 = 570;
        y22 = 428;
        rectangleWidth = 700;
      }
      if (count.derCount === 6) {
        x11 = 120;
        y11 = 285;
        x12 = 1200;
        y12 = 428;
        x21 = 430;
        y21 = 285;
        x22 = 570;
        y22 = 428;
        rectangleWidth =  800;
      }
    }
  } else if (
    siteType === embLabels.ISLANDABLE_GSX &&
    (projectTypeValue === "DaaS IEC"||projectTypeValue === 'IEC | Buildings | Autogrid Flex')
  ) {
    if (switchType === embLabels.NEW_SWITCHBOARD) {
      if (count.derCount === 3) {
        x11 = 120;
        y11 = 300;
        x21 = 650;
        y21 = 300;
        rectangleWidth = 550;
        if(count.loadCount>=5){
          x11 = 700;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 700
        }
        if(count.loadCount===10){
          x11 = 700;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 700;
        }
      }
      if (count.derCount === 4) {
        x11 = 120;        
        y11 = 300;
        x21 = 650;
        y21 = 300;
        rectangleWidth = 670;
        if(count.loadCount>=5){
          x11 = 750;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 750
        }
        if(count.loadCount===10){
          x11 = 800;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 800;
        }
      }
      if (count.derCount === 5) {
        x11 = 120;        
        y11 = 300;
        x21 = 740;
        y21 = 300;
        rectangleWidth = 750;
        if(count.loadCount>=5){
          x11 = 750;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 750
        }
        if(count.loadCount===10){
          x11 = 800;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 800;
        }
      }
    } else if (switchType === "EXISTING_SWITCHBOARD") {
      if (count.derCount === 3) {
        x11 = 120;
        y11 = 285;
        x12 = count.loadCount > 4 ?  count.loadCount > 5 ? 1250 : 1100 : 900;
        y12 = 428; //const
        x21 = 410;
        y21 = 285;
        x22 = 510;
        y22 = 428;
        rectangleWidth =  count.loadCount > 4 ?  count.loadCount > 5 ? 800 : 650 :450;
      }
      if (count.derCount === 4) {
        x11 = 120;
        y11 = 285;
        x12 =  count.loadCount > 4 ?  count.loadCount > 5 ? 1250 : 1100 : 1000;
        y12 = 428;
        x21 = 410;
        y21 = 285;
        x22 = 510;
        y22 = 428;
        rectangleWidth =count.loadCount > 4 ?  count.loadCount > 5 ? 800 : 650 : 550;
      }

      if (count.derCount === 5) {
        x11 = 120;
        y11 = 285;
        x12 =  count.loadCount > 4 ?  count.loadCount > 5 ? 1250 : 1100 : 1060;
        y12 = 428;
        x21 = 410;
        y21 = 285;
        x22 = 510;
        y22 = 428;
        rectangleWidth = count.loadCount > 4 ?  count.loadCount > 5 ? 800 : 650 : 650
      }
      if (count.derCount === 6) {
        x11 = 120;
        y11 = 285;
        x12 = 1250;
        y12 = 428;
        x21 = 410;
        y21 = 285;
        x22 = 510;
        y22 = 428;
        rectangleWidth = count.loadCount > 4 ?  count.loadCount > 5 ? 800 : 650  : 700;
      }
      if(count.derCount >=2&&count.loadCount===10){
        x12 = 1250;
        y12 = 428;
        x22 = 510;
        y22 = 428;
        rectangleWidth = count.loadCount > 4 ?  count.loadCount > 5 ? 800 : 650  : 700;
      }
    }
  } else if (
    siteType === embLabels.GRID_CONNECTED &&
    (projectTypeValue === "MGaaS IEC"||projectTypeValue === 'IEC | Buildings | Microgrid Advisor')
  ) {
    if (switchType === embLabels.NEW_SWITCHBOARD) {
      if (count.derCount === 2) {
        x11 = 400;
        y11 = 370;
        x21 = 700;
        y21 = 370;
        rectangleWidth = 380;
      }
      if (count.derCount === 3) {
        x11 = 400;
        y11 = 370;
        x21 = 800;
        y21 = 370;
        rectangleWidth = 500;
      }
      if (count.derCount === 4) {
        x11 = 400;
        y11 = 370;
        x21 = 930;
        y21 = 370;
        rectangleWidth = 620;
      }
      if (count.derCount === 5) {
        x11 = 400;
        y11 = 370;
        x21 = 1050;
        y21 = 370;
        rectangleWidth = 730;
      }
    } else if (switchType === "EXISTING_SWITCHBOARD") {
      if (count.derCount === 2) {
        x11 = 120;
        y11 = 285;
        x21 = 350;
        y21 = 285;
        x12 = 850;
        y12 = 470;
        x22 = 620;
        y22 = 470;
        rectangleWidth = 350;
      }
      if (count.derCount === 3) {
        x11 = 120;
        y11 = 285;
        x21 = 350;
        y21 = 285;
        x12 = 980;
        y12 = 470;
        x22 = 620;
        y22 = 470;
        rectangleWidth = 450;
      }
      if (count.derCount === 4) {
        x11 = 120;
        y11 = 285;
        x21 = 350;
        y21 = 285;
        x12 = 1050;
        y12 = 470;
        x22 = 620;
        y22 = 470;
        rectangleWidth = 500;
      }
      if (count.derCount === 5) {
        x11 = 120;
        y11 = 285;
        x21 = 350;
        y21 = 285;
        x12 = 1120;
        y12 = 470;
        x22 = 620;
        y22 = 470;
        rectangleWidth = 600;
      }
    }
  } else if (
    siteType === embLabels.GRID_CONNECTED &&
    (projectTypeValue === "MGaaS ANSI"||projectTypeValue === 'ANSI | Buildings | Microgrid Advisor')
  ) {
    if (switchType === embLabels.NEW_SWITCHBOARD) {
      if (count.derCount === 2) {
        x12 = 800;
        y12 = 470;
        x22 = 620;
        y22 = 470;
        rectangleWidth = 550;
      }
      if (count.derCount === 3) {
        x12 = 100;
        y12 = 300;
        x22 = 550;
        y22 = 300;
        rectangleWidth = 550;
      }
      if (count.derCount === 4) {
        x12 = 100;
        y12 = 300;
        x22 = 650;
        y22 = 300;
        rectangleWidth = 690;
      }
      if (count.derCount === 5) {
        x12 = 100;
        y12 = 300;
        x22 = 780;
        y22 = 300;
        rectangleWidth = 800;
      }
    } else if (switchType === "EXISTING_SWITCHBOARD") {
      if (count.derCount === 2) {
        x11 = 120;
        y11 = 285;
        x21 = 350;
        y21 = 285;
        x12 = 800;
        y12 = 470;
        x22 = 620;
        y22 = 470;
        rectangleWidth = 350;
      }
      if (count.derCount === 3) {
        x11 = 120;
        y11 = 285;
        x21 = 350;
        y21 = 285;
        x12 = 900;
        y12 = 430;
        x22 = 450;
        y22 = 430;
        rectangleWidth = 550;
      }
      if (count.derCount === 4) {
        x11 = 120;
        y11 = 285;
        x21 = 350;
        y21 = 285;
        x12 = 1000;
        y12 = 430;
        x22 = 450;
        y22 = 430;
        rectangleWidth = 650;
      }
      if (count.derCount === 5) {
        x11 = 120;
        y11 = 285;
        x21 = 350;
        y21 = 285;
        x12 = 1120;
        y12 = 430;
        x22 = 450;
        y22 = 430;
        rectangleWidth = 775;
      }
    }
  }else if(siteType === embLabels.ISLANDABLE&& 
    (projectTypeValue==="MGaaS ANSI"||projectTypeValue==='ANSI | Buildings | Microgrid Advisor')
    && clientType === "GENERIC" && mode === "NO_FUEL"){
      if (switchType === embLabels.NEW_SWITCHBOARD) {
        if (count.derCount === 2) {
          x11 = 100;
          y11 = 300;
          x21 = 650;
          y21 = 300;
          rectangleWidth = 650;
          if(count.loadCount>=5){
            x11 = 800;
            y11 = 300;
            x21 = 120;
            y21 = 300;
            rectangleWidth = 800
          }
          if(count.loadCount===10){
            x11 = 800;
            y11 = 300;
            x21 = 120;
            y21 = 300;
            rectangleWidth = 800;
          }
        }
        if (count.derCount === 3) {
          x11 = 100;
          y11 = 300;
          x21 = count.loadCount>=4 ?600:550;
          y21 = 300;
          rectangleWidth = count.loadCount>=4 ?600: 580;
          if(count.loadCount>=5){
            x11 = 800;
            y11 = 300;
            x21 = 120;
            y21 = 300;
            rectangleWidth = 800
          }
          if(count.loadCount===10){
            x11 = 800;
            y11 = 300;
            x21 = 120;
            y21 = 300;
            rectangleWidth = 800;
          }
        }
        if (count.derCount === 4) {
          x11 = 100;
          y11 = 300;
          x21 = 650;
          y21 = 300;
          rectangleWidth = 670;
          if((count.evCount+count.loadCount)>=6||count.loadCount>=5){
            x11 = 800;
            y11 = 300;
            x21 = 120;
            y21 = 300;
            rectangleWidth = 800
          }
          if((count.evCount+count.loadCount)===10||count.loadCount===10){
            x11 = 800;
            y11 = 300;
            x21 = 120;
            y21 = 300;
            rectangleWidth = 800;
          }
        }
        if (count.derCount === 5) {
          x11 = 100;
          y11 = 300;
          x21 = 780;
          y21 = 300;
          rectangleWidth = 800;
          if(count.loadCount>=5){
            x11 = 800;
            y11 = 300;
            x21 = 120;
            y21 = 300;
            rectangleWidth = 800
          }
          if(count.loadCount===10){
            x11 = 800;
            y11 = 300;
            x21 = 120;
            y21 = 300;
            rectangleWidth = 800;
          }
        }
        if (count.derCount === 6) {
          x11 = 100;
          y11 = 300;
          x21 = 880;
          y21 = 300;
          rectangleWidth = 900;
          if(count.loadCount>=5){
            x11 = 880;
            y11 = 300;
            x21 = 120;
            y21 = 300;
            rectangleWidth = 900
          }
          if(count.loadCount===10){
            x11 = 880;
            y11 = 300;
            x21 = 120;
            y21 = 300;
            rectangleWidth = 900;
          }
        }
      } else if (switchType === "EXISTING_SWITCHBOARD") {
        if (count.derCount === 2) {
          x11 = 70;
          y11 = 285;
          x21 = 400;
          y21 = 285;
          x12 = 980;
          y12 = 430;
          x22 = 500;
          y22 = 430;
          rectangleWidth = 450;
          if(count.loadCount>=5){
            x12 = 1100;
            y12 = 428;
            x22 = 500;
            y22 = 428;
            rectangleWidth = 600
          }
          if(count.loadCount===10){
            x12 = 1150;
            y12 = 428;
            x22 = 500;
            y22 = 428;
            rectangleWidth = 650;
          }
        }
        if (count.derCount === 3) {
          x11 = 70;
          y11 = 285;
          x21 = 400;
          y21 = 285;
          x12 = 900;
          y12 = 430;
          x22 = 500;
          y22 = 430;
          rectangleWidth = count.loadCount> 5 ? 670:  480;
          if(count.loadCount === 5){
            x12 = count.loadCount> 5 ?1250:1140;
            y12 = 428;
            x22 = 500;
            y22 = 428;
            rectangleWidth = count.loadCount>= 5 ? 700:  480;
          }
          if(count.loadCount>5){
            x12 = 1250;
            rectangleWidth = count.loadCount> 5 ? 800:  480;
          }
      
        }
        if (count.derCount === 4) {
          x11 = 70;
          y11 = 285;
          x21 = 400;
          y21 = 285;
          x12 = 1000;
          y12 = 430;
          x22 = 500;
          y22 = 430;
          rectangleWidth = count.loadCount> 5 ?  670 : 600;
          if(count.loadCount>=5){
            x12 = count.loadCount > 5 ?  1250 : 1150;
            y12 = 428;
            x22 = 500;
            y22 = 428;
            rectangleWidth = count.loadCount >5 ? 790 : 700
          }
          if(count.loadCount===10){
            x12 = 1250;
            y12 = 428;
            x22 = 500;
            y22 = 428;
            rectangleWidth = 790;
          }
        }
        if (count.derCount === 5) {
          x11 = 70;
          y11 = 285;
          x21 = 400;
          y21 = 285;
          x12 = count.loadCount>5?1250:1140;
          y12 = 430;
          x22 = 500;
          y22 = 430;
          rectangleWidth =count.loadCount>5? 800:700;
        }
        if (count.derCount === 6) {
          x11 = 90;
          y11 = 285;
          x21 = 400;
          y21 = 285;
          x12 = 1200;
          y12 = 430;
          x22 = 500;
          y22 = 430;
          rectangleWidth = 800;
        }
      }
  }else if(siteType === embLabels.ISLANDABLE&& 
    (projectTypeValue==="MGaaS ANSI"||projectTypeValue==='ANSI | Buildings | Microgrid Advisor')
    && clientType === "GENERIC" && mode === "FUEL_ERASER"){
      if (switchType === embLabels.NEW_SWITCHBOARD) {
        if (count.derCount === 2) {
          x11 = 800;
          y11 = 470;
          x21 = 620;
          y21 = 470;
          rectangleWidth = 550;
        }
        if (count.derCount === 3) {
          x11 = 100;
          y11 = 300;
          x21 = 550;
          y21 = 300;
          rectangleWidth = 550;
        }
        if (count.derCount === 4) {
          x11 = 100;
          y11 = 300;
          x21 = 650;
          y21 = 300;
          rectangleWidth = 670;
          if((count.evCount+count.loadCount)>=6||count.loadCount>=5){
            x11 = 800;
            y11 = 300;
            x21 = 120;
            y21 = 300;
            rectangleWidth = 800
          }
          if((count.evCount+count.loadCount)===10||count.loadCount===10){
            x11 = 800;
            y11 = 300;
            x21 = 120;
            y21 = 300;
            rectangleWidth = 800;
          }
        }
        if (count.derCount === 5) {
          x11 = 100;
          y11 = 300;
          x21 = 780;
          y21 = 300;
          rectangleWidth = 800;
          if(count.loadCount>=5){
            x11 = 800;
            y11 = 300;
            x21 = 120;
            y21 = 300;
            rectangleWidth = 800
          }
          if(count.loadCount===10){
            x11 = 800;
            y11 = 300;
            x21 = 120;
            y21 = 300;
            rectangleWidth = 800;
          }
        }
        if (count.derCount === 6) {
          x11 = 100;
          y11 = 300;
          x21 = 880;
          y21 = 300;
          rectangleWidth = 900;
          if(count.loadCount>=5){
            x11 = 880;
            y11 = 300;
            x21 = 120;
            y21 = 300;
            rectangleWidth = 900
          }
          if(count.loadCount===10){
            x11 = 880;
            y11 = 300;
            x21 = 120;
            y21 = 300;
            rectangleWidth = 900;
          }
        }
      } else if (switchType === "EXISTING_SWITCHBOARD") {
        if (count.derCount === 2) {
          x11 = 70;
          y11 = 285;
          x21 = 400;
          y21 = 285;
          x12 =count.loadCount >5 ? 1250 : 800;
          y12 = 470;
          x22 = 620;
          y22 = 470;
          rectangleWidth = 350;
        }
        if (count.derCount === 3) {
          x11 = 70;
          y11 = 285;
          x21 = 400;
          y21 = 285;
          x12 = count.loadCount >5 ? 1250 : 970;
          y12 = 430;
          x22 = 500;
          y22 = 430;
          svgx = 0;
          svgy = -30;
          svgldx0 = 0;
          svgldy0 = 0;
          rectangleWidth = 530;
          if(count.loadCount>=5){
            x12 = count.loadCount>= 6 ? 1250 : 1100;
            y12 = 428;
            x22 = 500;
            y22 = 428;
            rectangleWidth = count.loadCount >=5 ? 670 : 600
          }
          if(count.loadCount===10){
            x12 = count.loadCount >5 ? 1250 :1150;
            y12 = 428;
            x22 = 500;
            y22 = 428;
            rectangleWidth = count.loadCount >=5 ? 670 :  650;
          }
        }
        if (count.derCount === 4) {
          x11 = count.remoteLoadCount? 65:70;
          y11 = 285;
          x21 = 400;
          y21 = 285;
          x12 = count.loadCount >5 ? 1250 : 1000;
          y12 = 430;
          x22 = 500;
          y22 = 430;
          svgx = 0;
          svgy = -30;
          rectangleWidth =  count.loadCount >=5 ? 670 :600;
          if(count.loadCount === 5){
            x12 = count.loadCount>= 6 ? 1250 : 1100;
            y12 = 428;
            x22 = 500;
            y22 = 428;
            rectangleWidth = 670
          }
          if(count.loadCount >= 6){
            x12 =  1250 
            
            rectangleWidth = 800;
          }
          if(count.loadCount===10){
            x12 = count.loadCount >5 ? 1250 : 1150;
            y12 = 428;
            x22 = 500;
            y22 = 428;
            rectangleWidth = 650;
          }
        }
        if (count.derCount === 5) {
          x11 = 70;
          y11 = 285;
          x21 = 400;
          y21 = 285;
          x12 = count.loadCount >5 ? 1250 :  1120;
          y12 = 430;
          x22 = 500;
          y22 = 430;
          rectangleWidth = count.loadCount >5 ? 800 : 710;
          if(count.loadCount>=5){
            x12 = count.loadCount >= 6 ? 1250 : 1100;
            y12 = 428;
            x22 = 500;
            y22 = 428;
            rectangleWidth =  count.loadCount >5 ? 800 : 710
          }
          if(count.loadCount===10){
            x12 = count.loadCount >5 ? 1250 : 1150;
            y12 = 428;
            x22 = 500;
            y22 = 428;
            rectangleWidth =  count.loadCount >5 ? 800 :710;
          }
        }
        if (count.derCount === 6) {
          x11 = 70;
          y11 = 285;
          x21 = 400;
          y21 = 285;
          x12 = count.loadCount >5 ? 1250 : 1200;
          y12 = 430;
          x22 = 500;
          y22 = 430;
          rectangleWidth = 800;
        }
      }
    }else if (
    siteType === embLabels.ISLANDABLE &&
    (projectTypeValue === "MGaaS ANSI"||projectTypeValue === 'ANSI | Buildings | Microgrid Advisor')
  ) {
    if (switchType === embLabels.NEW_SWITCHBOARD) {
      if (count.derCount === 2) {
        x11 = 800;
        y11 = 470;
        x21 = 620;
        y21 = 470;
        rectangleWidth = 550;
      }
      if (count.derCount === 3) {
        x11 = 100;
        y11 = 300;
        x21 = 550;
        y21 = 300;
        rectangleWidth = 550;
      }
      if (count.derCount === 4) {
        x11 = 100;
        y11 = 300;
        x21 = 650;
        y21 = 300;
        rectangleWidth = 670;
        if((count.evCount+count.loadCount)>=6||count.loadCount>=5){
          x11 = 800;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 800
        }
        if((count.evCount+count.loadCount)===10||count.loadCount===10){
          x11 = 800;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 800;
        }
      }
      if (count.derCount === 5) {
        x11 = 100;
        y11 = 300;
        x21 = 780;
        y21 = 300;
        rectangleWidth = 800;
        if(count.loadCount>=5){
          x11 = 800;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 800
        }
        if(count.loadCount===10){
          x11 = 800;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 800;
        }
      }
      if (count.derCount === 6) {
        x11 = 100;
        y11 = 300;
        x21 = 880;
        y21 = 300;
        rectangleWidth = 900;
        if(count.loadCount>=5){
          x11 = 880;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 900
        }
        if(count.loadCount===10){
          x11 = 880;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 900;
        }
      }
    } else if (switchType === "EXISTING_SWITCHBOARD") {
      if (count.derCount === 2) {
        x11 = 60;
        y11 = 285;
        x21 = 400;
        y21 = 285;
        x12 = 800;
        y12 = 470;
        x22 = 620;
        y22 = 470;
        rectangleWidth = 350;
      }
      if (count.derCount === 3) {
        x11 = 120;
        y11 = 285;
        x21 = 400;
        y21 = 285;
        x12 =count.loadCount > 5?1250: 900;
        y12 = 470;
        x22 = 620;
        y22 = 470;
        rectangleWidth = 350;
      }
      if (count.derCount === 4) {
        x11 = 60;
        y11 = 285;
        x21 = 400;
        y21 = 285;
        x12 =count.loadCount > 5?1250: 1000;
        y12 = 430;
        x22 = 500;
        y22 = 430;
        rectangleWidth = 585;
        if(count.loadCount>=5){
          x12 = count.loadCount > 5?1250:1110;
          y12 = 428;
          x22 = 500;
          y22 = 428;
          rectangleWidth = count.loadCount > 5?800:670 
        }

        if(count.loadCount===10){
          x12 =count.loadCount > 5?1250: 1150;
          y12 = 428;
          x22 = 500;
          y22 = 428;
          rectangleWidth = 800;
        }
      }
      if (count.derCount === 5) {
        x11 = 70;
        y11 = 285;
        x21 = 400;
        y21 = 285;
        x12 = count.loadCount > 5?1250:1120;
        y12 = 430;
        x22 = 500;
        y22 = 430;
        rectangleWidth =count.loadCount > 5?800: 680;
      }
      if (count.derCount === 6) {
        x11 = 70;
        y11 = 285;
        x21 = 400;
        y21 = 285;
        x12 = count.loadCount > 5?1250:1200;
        y12 = 430;
        x22 = 500;
        y22 = 430;
        rectangleWidth = 800;
      }
    }
  }
   else if (siteType === embLabels.GENSET_PVBESS_A2E) {
    x11 = 400;
    y11 = 360;
    x21 = 850;
    y21 = 360;
    rectangleWidth = 530
  } else if(projectKind.isMgaasIecIsland){
    if (switchType === embLabels.NEW_SWITCHBOARD) {
        x11 = count.evCount ? 350 : 410;
        y11 = 370;
        x21 = getLineAndBusLength(projectKind, switchType, count)?.lineLength
        y21 = 370;
        rectangleWidth =getLineAndBusLength(projectKind, switchType, count)?.busLength
    } else if (switchType === "EXISTING_SWITCHBOARD") {

 
        x11 = 110;
        y11 = 270;
        x21 = count.evCount ? 350 : 380;
        y21 = 270;
        x12 =getLineAndBusLength(projectKind, switchType, count)?.lineLength
        y12 = 470;
        x22 = 580;
        y22 = 470;
        svgevx0 = 250
        svgevy0 = 0
        svgldx0 =250;
        svgldy0 = 0
        busStart = 550
        rectangleWidth = getLineAndBusLength(projectKind, switchType, count)?.busLength

    }
  }

  return {
    cooridinates: { x11, y11, x21, y21, x12, y12, x22, y22,    svgevx0,
      svgevy0,
      svgldx0,
      svgldy0,
      svgldx1,
      svgldy1,
      svgldx2,
      svgldy2,
      svgldx3, 
      svgldy3,
      svgldx4,
      svgldy4,
      svgldx5, 
      svgldy5,
      svgldx6,
      svgldy6,
      svgldx7, 
      svgldy7,
      svgldx8,
      svgldy8,
      svgldx9, 
      svgldy9,
      svgldx10,
      svgldy10,
      busStart,
      svgx,
      svgy,
      loadLine0,
      loadLine1,
      loadLine2,
      loadLine3,
      loadLine4,
      loadLine5,
      loadLine6,
      loadLine7,
      loadLine8,
      loadLine9,
    },
    rectangleWidth,
    loadSide,
  };
};