import React, { useCallback, useEffect, useRef, useState } from "react";
import { SeCheckbox } from "@se/web-ui-react";

import FileDownload from "js-file-download";
// const FileDownload = require("js-file-download");
import Services from "./DeployPages/Services";
import EmoController from "./DeployPages/EmoController";
import LicenceKeys from "./DeployPages/LicenceKeys";

import useMountEffect from "./CustomHooks/useMountEffect";
import userScope from "./CustomHooks/userScope";
import Snackbar from "./SE-Component/Snackbar";
import AlertIcon from "./SE-Component/AlertIcon";
//*api call
import ApiSession from "../api/ApiSession";
import ProgressBarContainer from "../components/progressBar/progressBarContainer";
import { useDispatch, useSelector } from "react-redux";
import { getProjectInfoRequest } from "../redux/features/projectStatusInfoSlice";

import "./deployHook.scss";
import { upDateProjectType } from "../redux/features/projectTypeSlice";
import SupportDocument from "./supportDocument";
import { getProjectId } from "../utils/project";

const apiSession = new ApiSession();

//* RegEx
const alphanumericReg = /^([a-zA-Z0-9]+)$/;
const serialNoRegex = /^[a-zA-Z0-9]{8}[0-9]{5}$/;

const Deploy = (props) => {
  const UserScope = userScope();
  const [loading, setLoading] = useState(false);

  const [unlockDeploy, setUnlockDeploy] = useState(false);
  const [emaSiteStatus, setEmaSiteStatus] = useState(false);
  const [emaSiteId, setEmaSiteId] = useState("");
  const [deployMessage, setDeployMessage] = useState("");
  const [resetPlcPopUpStatus, setResetPlcPopUpStatus] = useState(false);

  const [siteType, setSiteType] = useState("");
  const [clientType, setClientType] = useState("");
  const [architectureType, setArchitectureType] = useState("");
  const [emoSerialNumber, setEmoSerialNumber] = useState(null);

  const [open, setOpen] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [openSiteCreate, setOpenSiteCreate] = useState(false)

  const dialogHandler = () => {
    handleChangeProjectStatus("COMPLETED");
  };

  const [projectName] = useState(localStorage.getItem("pname"));

  const [keyExpert, setKeyExpert] = useState({
    expertKey: "",
    expertStatus: false,
    KeyErrorStatusExpert: false,
    expertKeyInvalid: false,
    expertExpired: false,
  });
  const [keyOperations, setKeyOperations] = useState({
    operationKey: "",
    operationsStatus: false,
    KeyErrorStatusOperations: false,
    operationKeyInvalid: false,
    operationExpired: false,
  });
  const [gridSetting, setGridSetting] = useState({
    userInterface: true,
    remoteMonitoringControl: true,
  });
  const [pagesValidated, setPagesValidated] = useState({
    programPage: true,
    systemsPage: true,
  });
  const [versions, setVersions] = useState({
    plcVersion: "",
    hmiVersion: "",
  });
  const [lockedStatus, setLockedStatus] = useState({
    unlockDeploy: false,
    plcLocked: false,
  });
  const [emoController, setEmoController] = useState({
    plcKeyPopUp: false,
    serialNo: "",
    KeyErrorStatusSerialNo: false,
  });
  const [snackBar, setSnackBar] = useState({ type: "", message: "" });
  const [alert, setAlert] = useState({
    message: "",
    alertPopUpStatus: false,
    title: "",
    isRoute: false,
    routePath: "",
  });
  const [confirm, setConfirm] = useState({
    deleteSiteAlertPopUp: false,
  });
  const [projectStateChanges, setProjectStateChanges] = useState();
  const snackBarRef = useRef(null);
  const dispatch = useDispatch();
  const projectStatusInfo = useSelector((state) => state.projectInfo);
  const [showSupportDocumentDialog, setShowSupportDocumentDialog] =
    useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deploymentStatusResponse, setDeploymentStatusResponse] =
    useState(null);
  useMountEffect(() => {
    if (getProjectId() === null) {
      setAlertMessage(
        `Deploy Page`,
        true,
        `information`,
        `Please create project and then visit deploy page.`
      );
    } else {
      if (UserScope?.access?.deploymentStatus) {
        setLoading(true);
        getDeploymentStatus();
      }
      getEMASite(false);
    }
    dispatch(getProjectInfoRequest());
    // if (hostUrl === IntegrationURL) {
    //   this.setState({ disableCloudConfiguration: true });
    // }
  }, [projectStateChanges]);

  useEffect(() => {
    dispatch(
      upDateProjectType({
        projectType: projectStatusInfo?.data?.projectType,
        projectName: projectStatusInfo?.data?.projectName,
      })
    );
    if (projectStatusInfo?.data?.projectStatus === "IN_PROGRESS") {
      setOpenStatusDialog(true);
    }
    getAllDocuments();
  }, [projectStatusInfo?.data?.projectStatus]);

  //* project status handles
  const handleChangeProjectStatus = (status) => {
    const projectId = getProjectId();
    apiSession
      .changeProjectStatus({
        projectId: projectId,
        projectStatus: status,
      })
      .then((response) => {
        setProjectStateChanges(status);
        if (status === "COMPLETED") {
          setOpen(!open);
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 412) {
            callSnackbar("error", `${error.response.data.message}`);
          }
        }
      });
    dispatch(getProjectInfoRequest());
    setOpen(false);
    // if(status === "COMPLETED"){
    //   setOpen(!open)
    // }
    // else{
    //   setUnlockDeploy(true)
    // }
  };

/* istanbul ignore next */ 
  const getDeploymentStatus = () => {
    apiSession
      .getDeploymentStatus(getProjectId())
      .then((response) => {
        setDeploymentStatusResponse(response);
        let licenses = [];
        licenses = response.licenses;
        setKeyExpert((prevState) => ({
          ...prevState,
          expertStatus: false,
          expertExpired: false,
        }));
        setKeyOperations((prevState) => ({
          ...prevState,
          operationsStatus: false,
          operationExpired: false,
        }));

        // if (licenses.length) {
        //   if (licenses.indexOf("EXPERT") > -1) {
        //     setKeyExpert((prevState) => ({ ...prevState, expertStatus: true }));
        //   }
        //   if (licenses.indexOf("OPERATIONS") > -1) {
        //     setKeyOperations((prevState) => ({
        //       ...prevState,
        //       operationsStatus: true,
        //     }));
        //   }
        //   if (licenses.indexOf("EXPERT_EXPIRED") > -1) {
        //     setKeyExpert((prevState) => ({
        //       ...prevState,
        //       expertExpired: true,
        //     }));
        //   }
        //   if (licenses.indexOf("OPERATIONS_EXPIRED") > -1) {
        //     setKeyOperations((prevState) => ({
        //       ...prevState,
        //       operationExpired: true,
        //     }));
        //   }
        // }

        setUnlockDeploy(response.deploymentStatus);
        setPagesValidated((prevState) => ({
          ...prevState,
          programPage: response.programPage,
          systemsPage: response.systemsPage,
        }));

        setVersions({
          ...versions,
          plcVersion: response.plcVersion,
          hmiVersion: response.hmiVersion,
        });

        const gridSettings = { ...gridSetting };
        gridSettings.userInterface = response?.hmiSelected;
        gridSettings.remoteMonitoringControl = response?.remoteMonitoring;
        setGridSetting(gridSettings);
        setLockedStatus((prevState) => ({
          ...prevState,
          unlockDeploy: response?.deploymentStatus,
          plcLocked: response?.plcLocked,
        }));
        setSiteType(response?.siteType);
        setClientType(response?.clientType);
        setArchitectureType(response?.architecture);
        setEmoSerialNumber(response?.serialNumber);
        if (response?.serialNumber) {
          setEmoController((prevState) => ({
            ...prevState,
            serialNo: response.serialNumber,
          }));
        }
        setLoading(false);
      })
      .catch(() => {
        setPagesValidated((prevState) => ({
          ...prevState,
          programPage: true,
          systemsPage: true,
        }));
        setKeyExpert((prevState) => ({
          ...prevState,
          expertStatus: false,
          expertExpired: false,
        }));
        setKeyOperations((prevState) => ({
          ...prevState,
          operationsStatus: false,
          operationExpired: false,
        }));
        setLockedStatus((prevState) => ({
          ...prevState,
          unlockDeploy: false,
          plcLocked: false,
        }));
        setLoading(false);
      });
  };

  //* Cloud servie
/* istanbul ignore next */ 
  const getEMASite = update => {
   try {
    apiSession
    .getEMASite(getProjectId())
    .then((response) => {
      if (update === true) {
        if (response.reConfigured === "true") {
          setAlertMessage(
            `Alert`,
            false,
            ``,
            `Download EMA controller configuration as EMA website has been updated.`
          );
        }
      }

      if (response.siteId) {
        setEmaSiteId(response.siteId);
      }
      setEmaSiteStatus(true);
    })
    .catch(() => {
      setEmaSiteStatus(false);

      setEmaSiteId("");
    });
   } catch (error) {
    console.log(error)
   }
  };
/* istanbul ignore next */ 
  const createSiteInEMA = () => {
    if (
      pagesValidated.programPage === false &&
      pagesValidated.systemsPage === false
    ) {
      setLoading(true);

      apiSession
        .createEMAsite(getProjectId())
        .then(() => {
          callSnackbar(`success`, `Site created successfully`);
          setLoading(false);
          getEMASite(true);
        })
        .catch((error) => {
          if (error.response !== undefined) {
            if (error.response.status === 403) {
              callSnackbar(
                `error`,
                `Unauthorised to perform this action, Contact administrator.`
              );
            } else if (error.response.status === 400) {
              callSnackbar(
                `error`,
                `${
                  error.response.data === null
                    ? "Error in site creation"
                    : error.response.data.message
                }`
              );
            } else {
              callSnackbar(
                `error`,
                `${
                  error.response.data === null
                    ? "Error in site creation"
                    : error.response.data.message
                }`
              );
            }
          }
          setLoading(false);
          getEMASite();
        });
    } else {
      if (pagesValidated.programPage) {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `Please validate use case to proceed further in Configure use cases.`
        );
      } else if (pagesValidated.systemsPage) {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `Please validate communication settings to proceed further.`
        );
      }
    }
  };
/* istanbul ignore next */ 
  const deleteSiteInEMA = () =>
    setConfirm((prevState) => ({ ...prevState, deleteSiteAlertPopUp: true }));
  const cancelDeleteSite = () =>
    setConfirm((prevState) => ({ ...prevState, deleteSiteAlertPopUp: false }));

  const confirmDeleteSite = () => {
    if (UserScope.access.deleteSite) {
      setConfirm((prevState) => ({
        ...prevState,
        deleteSiteAlertPopUp: false,
      }));
      setLoading(true);

      apiSession
        .deleteEMASite(getProjectId())
        .then(() => {
          setLoading(false);

          callSnackbar("success", "Cloud service deleted successfully");
          getEMASite(false);
        })
        .catch((error) => {
          setLoading(false);

          getEMASite(false);
          if (error.response !== undefined) {
            if (error.response.status === 400) {
              callSnackbar("error", error.response.data.message);
            }
          } else {
            callSnackbar("error", "Error in deletion of Cloud service");
          }
        });
    } else {
      setConfirm((prevState) => ({
        ...prevState,
        deleteSiteAlertPopUp: false,
      }));
      callSnackbar(
        "error",
        "User doesn't have permission to delete the cloud service"
      );
      getEMASite(false);
    }
  };

  //* IPC
/* istanbul ignore next */ 
  const downloadIpcFile = () => {
    if (!emaSiteStatus) {
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `Configure cloud services before downloading EMA controller configuration.`
      );
    } else {
      if (!pagesValidated.programPage) {
        setLoading(true);
        apiSession
          .downloadIPC(getProjectId())
          .then((response) => {
            FileDownload(response, `${projectName}_EMAController.zip`);
            callSnackbar("success", "Downloaded successfully");
            setLoading(false);
          })
          .catch((error) => {
            if (error.response !== undefined) {
              let enc = new TextDecoder("utf-8");
              let arr = new Uint8Array(error.response.data);
              let resultBuf = JSON.parse(enc.decode(arr));
              callSnackbar(
                "error",
                resultBuf.message !== undefined
                  ? resultBuf.message
                  : "Error in IPC Download"
              );
            } else {
              callSnackbar("error", "Error in IPC Download");
            }
            setLoading(false);
          });
      } else {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `Please validate use case to proceed further in Configure use cases.`
        );
      }
    }
  };
  const downloadOnLogicFile = () => {
    if (!emaSiteStatus) {
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `Configure cloud services before downloading EMA controller configuration.`
      );
    } else {
      if (!pagesValidated.programPage) {
        console.log("Downloaded")
      } else {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `Please validate use case to proceed further in Configure use cases.`
        );
      }
    }
  };
  //* PLC

  const handleSerialNoPopUp = () => {
    if (lockedStatus.plcLocked) {
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `Please contact admin for unlocking the deployment.`
      );
    } else {
      if (!pagesValidated.programPage && !pagesValidated.systemsPage) {
        setEmoController((prevState) => ({
          ...prevState,
          plcKeyPopUp: true,
          serialNo: emoSerialNumber ? emoSerialNumber : "",
          KeyErrorStatusSerialNo: false,
        }));
      } else {
        if (pagesValidated.programPage) {
          setAlertMessage(
            `Alert`,
            false,
            ``,
            `Please validate use case to proceed further in Configure use cases.`
          );
        } else if (pagesValidated.systemsPage) {
          setAlertMessage(
            `Alert`,
            false,
            ``,
            `Please validate communication settings to proceed further.`
          );
        }
      }
    }
  };
/* istanbul ignore next */ 
  const handleSerialNoChange = e => {
    const { value } = e.target;
    setEmoController((prevState) => ({
      ...prevState,
      KeyErrorStatusSerialNo: true,
    }));
    if (value !== "") {
      if (alphanumericReg.test(value)) {
        setEmoController((prevState) => ({
          ...prevState,
          serialNo: value,
        }));
      }
    } else {
      setEmoController((prevState) => ({
        ...prevState,
        serialNo: "",
      }));
    }
  };

  const handleSerialNoPopUpClose = () => {
    setEmoController((prevState) => ({
      ...prevState,
      plcKeyPopUp: false,
      serialNo: "",
      KeyErrorStatusSerialNo: false,
    }));
  };

  const handleSerialNoSave = () => {
    if (
      emoController.serialNo.length === 13 &&
      emoController.serialNo !== "" &&
      serialNoRegex.test(emoController.serialNo)
    ) {
      setEmoController((prevState) => ({
        ...prevState,
        plcKeyPopUp: false,
      }));
      generateProgram();
    } else {
      callSnackbar(
        `error`,
        `Please enter Controller serial number in the given format`
      );
    }
  };
/* istanbul ignore next */ 
  const generateProgram = () => {
    setLoading(true);
    setUnlockDeploy(false);
    apiSession
      .generatePLCProgram({
        projectId: getProjectId()
          ? getProjectId()
          : 29,
        serialNumber: emoController.serialNo,
      })
      .then(() => {
        downloadPlc();
      })

      .catch((error) => {
        callSnackbar("error", error.response.data);
        if (error.response !== undefined) {
          if (error.response.status === 412) {
            callSnackbar("error", error.response.data.message);
          } else if (error.response.status === 403) {
            callSnackbar(
              "error",
              "Unauthorised to perform this action, Contact administrator."
            );
          } else {
            callSnackbar("error", "Failed to download");
          }
        } else {
          callSnackbar("error", "Error calling download PLC");
        }
        setLoading(false);
      });
  };
/* istanbul ignore next */ 
  const downloadPlc = () => {
    let projectId = getProjectId()
      ? getProjectId()
      : 29;
    let serialNumber = emoController.serialNo;
    apiSession
      .downloadPLCProgram(projectId, serialNumber)
      .then((response) => {
        FileDownload(response, `${projectName}_EMOM.zip`);
        callSnackbar("success", "Downloaded successfully");
        setLoading(false);
        getDeploymentStatus();
        setUnlockDeploy(true);
      })
      .catch((error) => {
        setLoading(false);

        if (error.response !== undefined) {
          if (error.response.status === 423) {
            callSnackbar("error", error.response.data.message);
          }
        } else {
          callSnackbar("error", "Failed to download");
        }
      });
  };

  //* HMI
/* istanbul ignore next */ 
  const downloadHMI = () => {
    if (!pagesValidated.programPage && !pagesValidated.systemsPage) {
      setLoading(true);

      apiSession
        .downloadHMI(getProjectId())
        .then((response) => {
          FileDownload(response, `${projectName}_HMI.zip`);
          setLoading(false);
          callSnackbar("success", "Downloaded successfully");
        })
        .catch((error) => {
          setLoading(false);
          if (error.response !== undefined) {
            if (error.response.status === 403) {
              callSnackbar(
                "error",
                "Unauthorised to perform this action, Contact administrator"
              );
            } else {
              callSnackbar("error", "Failed to download");
            }
          } else {
            callSnackbar("error", "Failed to download");
          }
        });
    } else {
      if (pagesValidated.programPage) {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `Please validate use case to proceed further in Configure use cases.`
        );
      } else if (pagesValidated.systemsPage) {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `Please validate communication settings to proceed further.`
        );
      }
    }
  };
  //MOXA
  /* istanbul ignore next */ 
  const downloadMOXA = () => {
    if (!pagesValidated.programPage && !pagesValidated.systemsPage) {
      setLoading(true);
      setUnlockDeploy(false);
      apiSession
        .downloadMoxa(getProjectId())
        .then((response) => {
          FileDownload(response, `${projectName}_MOXA.zip`);
          setLoading(false);
          callSnackbar("success", "Downloaded successfully");
          setUnlockDeploy(true);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response !== undefined) {
            if (error.response.status === 403) {
              callSnackbar(
                "error",
                "Unauthorised to perform this action, Contact administrator"
              );
            } else if (error.response.status === 500) {
              callSnackbar("error", "Error in file generation");
            } else if (error.response.status === 404) {
              callSnackbar(
                "error",
                "Please save secured communication architecture page"
              );
            } else {
              callSnackbar("error", "Failed to download");
            }
          } else {
            callSnackbar("error", "Failed to download");
          }
        });
    } else {
      if (pagesValidated.programPage) {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `Please validate use case to proceed further in Configure use cases.`
        );
      } else if (pagesValidated.systemsPage) {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `Please validate communication settings to proceed further.`
        );
      }
    }
  };
  //Connexium
  /* istanbul ignore next */ 
  const downloadCONNEXIUM = () => {
    if (!pagesValidated.programPage && !pagesValidated.systemsPage) {
      setLoading(true);
      setUnlockDeploy(false);
      apiSession
        .downloadConnexium(getProjectId())
        .then((response) => {
          FileDownload(response, `${projectName}_CONNEXIUM.zip`);
          setLoading(false);
          callSnackbar("success", "Downloaded successfully");
          setUnlockDeploy(true);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response !== undefined) {
            if (error.response.status === 403) {
              callSnackbar(
                "error",
                "Unauthorised to perform this action, Contact administrator"
              );
            } else if (error.response.status === 500) {
              callSnackbar("error", "Error in file generation");
            } else if (error.response.status === 404) {
              callSnackbar(
                "error",
                "Please save secured communication architecture page"
              );
            } else {
              callSnackbar("error", "Failed to download");
            }
          } else {
            callSnackbar("error", "Failed to download");
          }
        });
    } else {
      if (pagesValidated.programPage) {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `Please validate use case to proceed further in Configure use cases.`
        );
      } else if (pagesValidated.systemsPage) {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `Please validate communication settings to proceed further.`
        );
      }
    }
  };
  //* Licence

  const handleLicenseChange = (e) => {
    const { name, value } = e.target;

    let input = value.replace(/-/g, "");

    // Remove non-hex chars
    input = value.replace(/[^\dA-F]/gi, "");

    // Check length and insert hyphens
    if (input.length > 8) {
      input = `${input.slice(0, 8)}-${input.slice(8)}`;
    }
    if (input.length > 13) {
      input = `${input.slice(0, 13)}-${input.slice(13)}`;
    }
    if (input.length > 18) {
      input = `${input.slice(0, 18)}-${input.slice(18)}`;
    }
    if (input.length > 23) {
      input = `${input.slice(0, 23)}-${input.slice(23)}`;
    }

    setKeyOperations((prevState) => ({
      ...prevState,
      [name]: input,
    }));
  };

  const validateExpertKey = () => {
    if (keyExpert.expertKey.trim() !== "") {
      saveExpertKey();
    } else {
      callSnackbar("error", "License Key is mandatory");
    }
  };

  const saveExpertKey = () => {
    setLoading(true);

    let jsonBody = {
      projectId: getProjectId(),
      activationKey: keyExpert.expertKey.toUpperCase(),
      type: "EXPERT",
    };

    setKeyExpert((prevState) => ({
      ...prevState,
      expertKeyInvalid: false,
    }));

    apiSession
      .activateLicenseKey(jsonBody)
      .then((response) => {
        setKeyExpert((prevState) => ({
          ...prevState,
          expertKey: "",
          expertStatus: true,
          KeyErrorStatusExpert: false,
        }));
        callSnackbar("success", response.message);
        getDeploymentStatus();
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            setKeyExpert((prevState) => ({
              ...prevState,
              expertKeyInvalid: true,
            }));
          }
          callSnackbar("error", error.response.data.message);
        } else {
          callSnackbar("error", "Error calling license API");
        }
        setLoading(false);

        setKeyExpert((prevState) => ({
          ...prevState,
          expertStatus: false,
        }));
      });
  };

  const validateOpertaionsKey = () => {
    if (keyOperations.operationKey.trim() !== "") {
      saveOperationsKey();
    } else {
      callSnackbar("error", "License Key is mandatory");
    }
  };

  const saveOperationsKey = () => {
    setLoading(true);

    let jsonBody = {
      projectId: getProjectId(),
      activationKey: keyOperations.operationKey.toUpperCase(),
      type: "OPERATIONS",
    };

    setKeyOperations((prevState) => ({
      ...prevState,
      operationKeyInvalid: false,
    }));

    apiSession
      .activateLicenseKey(jsonBody)
      .then((response) => {
        setKeyOperations((prevState) => ({
          ...prevState,
          operationKey: "",
          operationsStatus: true,
          KeyErrorStatusOperations: false,
        }));
        callSnackbar("success", response.message);
        getDeploymentStatus();
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            setKeyOperations((prevState) => ({
              ...prevState,
              operationKeyInvalid: true,
            }));
          }
          callSnackbar("error", error.response.data.message);
        } else {
          callSnackbar("error", "Error calling license API");
        }

        setLoading(false);
        setKeyOperations((prevState) => ({
          ...prevState,
          operationsStatus: false,
        }));
      });
  };

  useEffect(()=>{
    let allowUnlock = true;
    if (
      deploymentStatusResponse?.licenseMatched === "NOT_FOUND" &&
      !deploymentStatusResponse?.deploymentStatus
    ) {
      // setOpenDialog(true);
      
      // setDeployMessage(
      //   "License Not Found"
      // );
    } else if (
      deploymentStatusResponse?.licenseMatched === "NOT_MATCHED" &&
      !deploymentStatusResponse?.deploymentStatus
    ) {
      setOpenDialog(true);
      
      setDeployMessage(
        "Please contact the country front office team/MGCC team to order right bill of material"
      );
    } else if (
      deploymentStatusResponse?.licenseMatched === "EXPIRED" &&
      !deploymentStatusResponse?.deploymentStatus
    ) {
      setOpenDialog(true);
      setDeployMessage(
        "Please contact the country front office team/MGCC team to renew your subscription"
      );

      allowUnlock = false;
    } else {
      allowUnlock = true;
    }
  },[projectStateChanges,deploymentStatusResponse])
  //* unlock

  const unlockDeployHandler = (data) => {

    if (
      UserScope.access.unlock === true &&
      projectStatusInfo?.data?.projectStatus === "COMPLETED"
      
    ) {
      apiSession
        .unlockDeploy(
          getProjectId(),
          data?.detail?.selected
        )
        .then(async (response) => {

          setKeyExpert((prevState) => ({
            ...prevState,
            KeyErrorStatusExpert: false,
          }));
          setKeyOperations((prevState) => ({
            ...prevState,
            KeyErrorStatusOperations: false,
          }));

          setUnlockDeploy((prevState)=>!prevState);
          // setUnlockDeploy(data.detail.selected);
          callSnackbar("success", "Unlock updated successfully");
          setDeployMessage("")
          await getDeploymentStatus()
        })
        .catch((error) => {
          if (data.detail.selected === true) {
            setUnlockDeploy(false);
          }

          if (error?.response?.status === 412) {
            callSnackbar(
              "error",
              "Please ensure Assets Configuration is complete to deploy"
            );
          } else {
            callSnackbar("error", "Error in updating unlock");
          }
        });
    } else if(deploymentStatusResponse?.licenseMatched === "NOT_FOUND"){
      callSnackbar("error", "License Not Found");

    }
    
    else {
      callSnackbar("error", "Not Authorized");
    }
  };

  const confirmResetPlc = () => {
    setLoading(true);
    apiSession
      .resetPLC({ projectId: getProjectId() })
      .then(() => {
        setResetPlcPopUpStatus(false);
        getDeploymentStatus();
        setLoading(false);
        callSnackbar("success", "Cleared project PLC count successfully");
      })
      .catch(() => {
        setLoading(false);
        setResetPlcPopUpStatus(false);
        callSnackbar("error", "Error in clear PLC count");
      });
  };

  //* alerts
  const callSnackbar = useCallback((type, message) => {
    try {
      setSnackBar({ type, message });
      snackBarRef.current.open = true
    } catch (error) {
      console.log("error in snack bar")
    }
  }, []);

  const setAlertMessage = (title, isRoute, routePath, message) => {
    setAlert({
      title,
      isRoute,
      routePath,
      message,
      alertPopUpStatus: true,
    });
  };

  const getAllDocuments = () => {
   try {
    apiSession
    .listAllDeployDocuments(getProjectId(), "deploy")
    .then((response) => {
      setDocumentList(response.documentBlobInfo);
    });
   } catch (error) {
    console.log(error)
   }
  };

  const closeAlertMessage = useCallback(() => {
    setAlert({
      message: "",
      alertPopUpStatus: false,
      title: "",
      isRoute: false,
      routePath: "",
    });
    if (alert.isRoute) {
      let { history } = props;
      history.push({
        pathname: `/${alert.routePath}`,
      });
    }
  }, [alert, props]);

  return (
    <>
      <div
        className={`${
          openStatusDialog && UserScope?.role !== "Viewer"
            ? "main-container"
            : ""
        } container-fluid  container-margin`}
      >
        {projectStatusInfo.loading || projectStatusInfo.data === null ? (
          <div className="stickyProgressBar">
            <div className="d-flex justify-content-between">
            <se-skeleton  height="20px" style={{width:"33%"}}></se-skeleton>
            <se-skeleton  height="20px" style={{width:"33%"}}></se-skeleton>
            <se-skeleton  height="20px" style={{width:"33%"}}></se-skeleton>
            </div>
          </div>
        ) : (
          <ProgressBarContainer projectStatusData={projectStatusInfo.data} />
        )}

        <div className="d-flex justify-content-end align-items-end flex-column">
          {UserScope?.access?.saveProjects && (
            <se-dropdown alignment="right">
              {/* <se-button option="flat" color="primary">Generate Task</se-button> */}

              <se-button
                slot="trigger"
                option="flat"
                color="primary"
                icon-color="primary"
              >
                Update Status
              </se-button>
              <se-list option="dropdown">
                <se-divider></se-divider>
                <se-list-item
                  onClick={() => emaSiteStatus?setOpenSiteCreate(true) : handleChangeProjectStatus("IN_PROGRESS")}
                  item="In Progress"
                ></se-list-item>
                <se-divider></se-divider>
                <se-list-item
                  onClick={() => setOpen(true)}
                  item="Completed"
                ></se-list-item>
                <se-divider></se-divider>
              </se-list>
            </se-dropdown>
          )}
          <div
            className="flex-10 ml-2 se-font-14px-bold se-life-green mb-2 mt-2"
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
          {  (deploymentStatusResponse?.licenseMatched === "MATCHED" || unlockDeploy) && <a
              
              href=""
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setShowSupportDocumentDialog(true);
              }}
            >
              Support Document
            </a>}
          </div>
          {open && (
            <se-dialog
              id="dialog-simple-notitle"
              color="alternative"
              size="small"
              open={open}
            >
              <se-dialog-header color="primary">
                Update Status{" "}
              </se-dialog-header>
              <se-dialog-content icon="information_circle" color="primary">
                {" "}
                User allows the settings to be uploaded to the microgrid
                controls
              </se-dialog-content>
              <se-dialog-footer>
                <se-button onClick={() => setOpen(false)}>Cancel</se-button>
                <se-button color="primary" onClick={() => dialogHandler()}>
                  Allow
                </se-button>
              </se-dialog-footer>
            </se-dialog>
          )}
        </div>
        <se-loading loading={loading}></se-loading>
        {siteType && (
          <se-container option="centered">
            <div className="container mb-1 mt-1">
              <Services
                UserScope={UserScope}
                gridSetting={gridSetting}
                unlockDeploy={unlockDeploy}
                siteType={siteType}
                clientType={clientType}
                architectureType={architectureType}
                keyExpert={keyExpert}
                keyOperations={keyOperations}
                emaSiteStatus={emaSiteStatus}
                versions={versions}
                createSiteInEMA={createSiteInEMA}
                deleteSiteInEMA={deleteSiteInEMA}
                downloadIpcFile={downloadIpcFile}
                downloadOnLogicFile={downloadOnLogicFile}
                handleSerialNoPopUp={handleSerialNoPopUp}
                downloadHMI={downloadHMI}
                downloadMOXA={downloadMOXA}
                downloadCONNEXIUM={downloadCONNEXIUM}
                projectProgressStatus={projectStatusInfo.data}
                emaSiteId={emaSiteId}
                deploymentStatus={deploymentStatusResponse}
              />
              <LicenceKeys
                UserScope={UserScope}
                gridSetting={gridSetting}
                unlockDeploy={unlockDeploy}
                siteType={siteType}
                architectureType={architectureType}
                keyExpert={keyExpert}
                keyOperations={keyOperations}
                handleLicenseChange={handleLicenseChange}
                validateExpertKey={validateExpertKey}
                validateOpertaionsKey={validateOpertaionsKey}
                projectProgressStatus={projectStatusInfo.data}
              />

              {UserScope.access.unlock === true ? (
                <div className="row mt-2 d-flex justify-content-center">
                  <div
                    className={` d-flex justify-content-center col-md-3 tab-content-deploy ${
                      projectStatusInfo.data?.projectStatus === "IN_PROGRESS"
                        ? "se-block-disabled deploy-btn"
                        : ""
                    }`}
                  >
                    <div className="se-deploy-div">
                      {/* <label> Unlock Deployment</label>&nbsp;&nbsp;&nbsp;&nbsp; */}

                      <SeCheckbox
                        value="true"
                        disabled={
                          projectStatusInfo.data?.projectStatus ===
                            "IN_PROGRESS" || UserScope.access.unlock === true
                            ? false
                            : true
                        }
                        option="switch"
                        selected={unlockDeploy}
                        label="Unlock Deployment"
                        onDidChange={(e) => unlockDeployHandler(e)}
                      ></SeCheckbox>
                      {openDialog && projectStatusInfo.data?.projectStatus === "COMPLETED" && (
                        <se-dialog
                          id="dialog-simple-notitle"
                          color="alternative"
                          size="small"
                          open={openDialog}
                        >
                          <se-dialog-header color="primary">
                          License validation failed{" "}
                          </se-dialog-header>
                          <se-dialog-content
                            icon="information_circle"
                            color="primary"
                            style={{ textAlign: "start" }}
                          >
                            {deployMessage}
                            {deploymentStatusResponse?.licenseMatched ===
                              "NOT_MATCHED" &&
                              deploymentStatusResponse?.licenses &&
                              Object.keys(
                                deploymentStatusResponse?.licenses
                              ) && (
                                <>
                                  {Object.keys(
                                    deploymentStatusResponse?.licenses
                                  ).map((licence) => (
                                    <li>{licence}</li>
                                  ))}
                                </>
                              )}
                          </se-dialog-content>
                          <se-dialog-footer>
                            <se-button onClick={() => setOpenDialog(false)}>
                              OK
                            </se-button>
                          </se-dialog-footer>
                        </se-dialog>
                      )} 
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {((siteType === "GRID_CONNECTED"
                ? keyExpert.expertStatus
                : keyOperations.operationsStatus) ||
                unlockDeploy === true ||
                UserScope.access.unlockBypass === true) &&
              UserScope.access.resetPLC === true ? (
                <div className="row mt-2 justify-content-center">
                  <div className="col-md-4 p-2">
                    <div className="row">
                      <div className="col-md-6 mt-1 my-auto text-right">
                        <span className="bold">Reset PLC Count</span>
                      </div>
                      <div className="col-md-6 mt-1 my-auto">
                        <se-button
                          size="small"
                          title="Reset PLC Count"
                          onClick={() => setResetPlcPopUpStatus(true)}
                        >
                          <se-icon size="small">data_refresh_stroke</se-icon>
                        </se-button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            {emoController.plcKeyPopUp && (
              <EmoController
                emoController={emoController}
                emoSerialNumber={emoSerialNumber}
                handleSerialNo={handleSerialNoChange}
                handleSerialNoPopUpClose={handleSerialNoPopUpClose}
                handleSerialNoSave={handleSerialNoSave}
              />
            )}

            <Snackbar snackBar={snackBar} ref={snackBarRef} />
            <AlertIcon alert={alert} onClose={closeAlertMessage} />

            <se-dialog
              id="no-project-alert-popup"
              open={confirm.deleteSiteAlertPopUp}
              can-backdrop="false"
            >
              <se-dialog-header>Confirmation</se-dialog-header>
              <se-dialog-content className="se-header-font-size-16px ">
                <se-icon color="primary" size="small">
                  information_circle
                </se-icon>
                <span>
                  &nbsp; EMA website is running for this project, Do you want to
                  delete this EMA website ? If you delete, All site historical
                  data will be lost.
                </span>
              </se-dialog-content>
              <se-dialog-footer>
                <se-button onClick={cancelDeleteSite}>NO</se-button>
                <se-button onClick={confirmDeleteSite}>Yes</se-button>
              </se-dialog-footer>
            </se-dialog>

            <se-dialog
              id="no-project-alert-popup"
              open={resetPlcPopUpStatus}
              can-backdrop="false"
            >
              <se-dialog-header>Confirmation</se-dialog-header>
              <se-dialog-content>
                <span className="se-font-14px">
                  Do you confirm to clear count ?
                </span>
              </se-dialog-content>
              <se-dialog-footer>
                <se-button onClick={() => setResetPlcPopUpStatus(false)}>
                  NO
                </se-button>
                <se-button onClick={confirmResetPlc}>Yes</se-button>
              </se-dialog-footer>
            </se-dialog>
          </se-container>
        )}
        {openDialog  && (
                      <div className={openStatusDialog?"se-block-disabled":""}>
                          <se-dialog
                          id="dialog-simple-notitle"
                          color="alternative"
                          size="small"
                          open={openDialog}
                        >
                          <se-dialog-header color="primary">
                            License validation Failed{" "}
                          </se-dialog-header>
                          <se-dialog-content
                            icon="information_circle"
                            color="primary"
                            style={{ textAlign: "start" }}
                          >
                            {deployMessage}
                            {deploymentStatusResponse?.licenseMatched ===
                              "NOT_MATCHED" &&
                              deploymentStatusResponse?.licenses &&
                              Object.keys(
                                deploymentStatusResponse?.licenses
                              ) && (
                                <>
                                  {Object.keys(
                                    deploymentStatusResponse?.licenses
                                  ).map((licence) => (
                                    <li>{licence}</li>
                                  ))}
                                </>
                              )}
                          </se-dialog-content>
                          <se-dialog-footer>
                            <se-button onClick={() => setOpenDialog(false)}>
                              OK
                            </se-button>
                          </se-dialog-footer>
                        </se-dialog>
                      </div>
                      )}
        {openStatusDialog && UserScope?.role !== "Viewer" && (
          <div className="custom-dialog" color="alternative" size="small" id="no-project-alert-popup1">
            <div className="custom-dialog-content">
              <se-dialog-content class="font-block-header">
                Please update the status of the project to enable deployment.
                Configurations can be downloaded only when the status of the
                project is completed.
              </se-dialog-content>
              <se-dialog-footer>
                <se-button
                  color="secondary"
                  onClick={() => {
                    setOpenStatusDialog(false)
                  }}
                >
                  OK
                </se-button>
              </se-dialog-footer>
              <div className="arrow"></div>
            </div>
          </div>
        )}
         {openSiteCreate && (
            <se-dialog
              id="dialog-simple-site-created"
              color="alternative"
              size="small"
              open={openSiteCreate}
            >
              <se-dialog-header color="primary">
                Update Status{" "}
              </se-dialog-header>
              <se-dialog-content icon="information_circle" color="primary">
                {" "}
                The site has been created. Please delete the site from the cloud service and then update the project status.
              </se-dialog-content>
              <se-dialog-footer>
                {/* <se-button onClick={() => setOpenSiteCreate(false)}>Cancel</se-button> */}
                <se-button color="primary" onClick={() => setOpenSiteCreate(false)}>
                  Ok
                </se-button>
              </se-dialog-footer>
            </se-dialog>
          )}
      </div>
      <SupportDocument
            onClose={() => setShowSupportDocumentDialog(false)}
            showSupportDocumentDialog={showSupportDocumentDialog}
            documentList={documentList}
          />
    </>
  );
};

export default Deploy;
