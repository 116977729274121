import React, { useContext } from "react";
import { InformationPageContext } from "../CustomHooks/ContextApi";

const ProjectUsers = () => {
  const {
    UserScope,
    users,
    addUserProject,
    removeInvitedUserPopUp,
    reinviteToProject,
  } = useContext(InformationPageContext);
  return (
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background">
        <se-icon color="primary">user_group</se-icon>
        <span className="ml-2 se-font-14px-bold se-life-green">
          Project Execution - User Roles
        </span>

        <div className="dropdown-divider"></div>

        <div className="row mb-2">
          <div className="col-md-12">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th> Name</th>
                  <th> Role</th>
                  <th> Employer</th>
                  <th> Phone Number</th>
                  <th> Email Address</th>
                  <th> Status</th>
                  <th className="pt-0 pb-0 text-center">
                    <se-button
                      option="raised"
                      size="small"
                      color="primary"
                      icon="action_add_flat"
                      disabled={UserScope.access.addUser ? false : true}
                      onClick={addUserProject}
                      // style={{zIndex:100}}
                    >
                      Invite User
                    </se-button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map(userData => (
                  <tr key={userData.userId}>
                    <td>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left pl-0 textEllipsis">
                        <span
                          className="bold"
                          title={userData.firstName + " " + userData.lastName}
                        >
                          {userData.firstName} {userData.lastName}{" "}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left pl-0 textEllipsis">
                        <span title={userData.role}> {userData.role} </span>
                      </div>
                    </td>
                    <td>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left pl-0 textEllipsis">
                        <span title={userData.employer}>
                          {" "}
                          {userData.employer}{" "}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left pl-0 textEllipsis">
                        <span title={userData.phoneNumber}>
                          {" "}
                          {userData.phoneNumber}{" "}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left pl-0 textEllipsis">
                        <span title={userData.email}> {userData.email} </span>
                      </div>
                    </td>
                    <td>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left pl-0 textEllipsis ">
                        <span
                          title={
                            userData.status === "I"
                              ? "Invited"
                              : userData.status === "A"
                              ? "Active"
                              : ""
                          }
                        >
                          {userData.status === "I" ? "Invited" : ""}
                          {userData.status === "E"
                            ? "Invitation Link Expired"
                            : ""}
                          {userData.status === "A" ? "Active" : ""}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                        {UserScope.access.addUser && userData.status === "E" && (
                          <span className="pointer">
                            <se-icon
                              size="small"
                              title="Re-Invite"
                              color="primary"
                              onClick={() => reinviteToProject(userData)}
                            >
                              user_add
                            </se-icon>
                            &nbsp;{" "}
                          </span>
                        )}

                        {UserScope.access.deleteUserProject && (
                          <span className="pointer">
                            <se-icon
                              size="small"
                              title="Delete"
                              color="primary"
                              onClick={() =>
                                removeInvitedUserPopUp(
                                  userData.userId,
                                  userData.role
                                )
                              }
                            >
                              action_delete
                            </se-icon>
                          </span>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectUsers;
