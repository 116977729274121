import ACTIONS from "./Design_Asset_Actions.json";
import _ from "lodash";

import embLabels from "../../config/emb-labels-en.json";
import { getProjectTypeValue } from "../../utils/project";

const communicationDataSample = {
  ipaddress: "",
  subnetmask: "",
  defaultGateway: "",
  modbusSlaveId: "",
};

const initialCBLoadList = [
  {
    modbusSlaveId: "21",
    configData: {
      name: "",
      description: "",
      maximum_available_power: 0,
    },
    uiData: {
      status: false,
      configured: false,
      EMConfigured:false,
      emSelected:false,
      CBSelected: false,
      selected: false,
      CBConfigured: false,
      cbRequired: false,
      pmRequired: false,
      pmSelected: false,
      pmConfigured: false,
      pmConfiguredGrid: false,
      isRemoteLoad: false,
      xAxisCB: 205,
      yAxisCB: 290,
      xAxisEM: 446,
      yAxisEM: 370,
      xAxis: 160,
      yAxis: 292,
      xAxisPM: 345,
      yAxisPM: 370,
      detailsBlock: false,
    },
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: [],
      cbCommunicationData: {},
    },
    contactorConfigData:{
      name: "",
      description: "",
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: [],
      cbCommunicationData: {},
    },
    powerMeterData:{
      name: "",
      description: "",
      powerMeterModbusId: "",
      ipAddress: "",
      gatewayAddress: "",
      mask: "",
      pmAsset: {},
    }
  },
];
let projectTypeValue = JSON.parse(
  sessionStorage.getItem("projectType")
)?.projectTypeValue;

const CBLoadListReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.ASSIGN_DEFAULT_VALUES:
      return assignDefaultValuesCBLoadList(
        state,
        action.payload.defaultValues,
        action.payload.defaults,
        action.payload.type,
        action.payload.cb,
        action.payload.dummyCB,
        action.payload.pmAssetData,
        action.payload.contactor,
      );
    case ACTIONS.ASSIGN_USECASE_LOAD:
      return assignUseCaseLoad(
        state,
        action.payload.defaultValues,
        action.payload.defaultCbData,
        action.payload.siteType,
      );
    case ACTIONS.UPDATE_CBLOADLIST_COORDINATES:
      return updateCbLoadListCoordinates(
        state,
        action.payload.coordinates,
        action.payload.count,
        action.payload.siteType,
        action.payload.projectKind,
        action.payload.switchboardType,
      );
    case ACTIONS.UPDATE_CBLOADLIST_COORDINATES_GENSET_LOAD:
      return updateCbLoadListCoordinatesGensetLoad(
        state,
        action.payload.coordinates,
        action.payload.count,
        action.payload.projectKind,
        action.payload.switchboardType,
      );
    case ACTIONS.UPDATE_CBLOADLIST_COORDINATES_GRIDCONNECTED:
      return updateCbLoadListCoordinatesGridConnected(state);
    case ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVHYBRIDIZATION:
      return updateCbLoadListCoordinatesPvHybridization(state);
    case ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVGENSET_ATS_OP:
      return updateCbLoadListCoordinatesPvGensetAts(state, action.payload);
    case ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVBESS_NR:
      return updateCbLoadListCoordinatesPvBessNr(state, action.payload);
    case ACTIONS.UPDATE_REMOTE_LOAD_COORDINATES:
      return updateRemoteLoadCoordinates(
        state,
        action.payload.coordinates,
        action.payload.loadSide,
        action.payload.count,
        action.payload.siteType,
        action.payload.projectKind,
        action.payload.switchboardType,
      );
    case ACTIONS.UPDATE_LOAD:
      return updateLoad(
        state,
        action.payload.componentsData,
        action.payload.loadIndex,
        action.payload.siteType,
        action.payload.projectTypeValue,
      );
    case ACTIONS.UPDATE_LOAD_CB:
      return updateLoadCb(
        state,
        action.payload.componentsData,
        action.payload.loadCBIndex,
        action.payload.siteType,
        action.payload.projectTypeValue,
        action.payload.contactor,
      );
    case ACTIONS.CLOSEDETAILS_LOAD:
      return closeDetailsLoad(state);
    case ACTIONS.LOAD_SELECT:
      return loadSelect(state, action.payload.index);
    case ACTIONS.LOAD_CB_SELECT:
      return loadCbSelect(state, action.payload.index);
      case ACTIONS.ASSET_PM_SELECT:
        return assetPmSelect(state, action.payload.index);
    case ACTIONS.UPDATE_LOAD_CONFIG:
      return updateLoadConfig(
        state,
        action.payload.selectedLoad,
        action.payload.configData
      );
    case ACTIONS.DUPLICATE_LOAD_UPDATE:
      return duplicateLoadUpdate(state, action.payload.selectedLoad);
    case ACTIONS.UPDATE_LOAD_CB_CONFIG:
      return updateLoadCbConfig(
        state,
        action.payload.selectedLoadCB,
        action.payload.cbConfigData
      );
    case ACTIONS.ADD_LOAD:
      return addLoad(
        state,
        action.payload.defaultPreData,
        action.payload.count,
        action.payload.cbCommunicationValues,
        action.payload.defaultCbData,
        action.payload.siteType,
        action.payload.pmAssetData,
        action.payload.contactor,
        action.payload.defaults,
        action.payload.projectTypeValue,
      );
    case ACTIONS.LOAD_PM_ASSET_REQUIRED:
      return loadPMAssetRequired(
        state, 
        action.payload.index,
        action.payload.defaultPmData,
        action.payload.defaultValues,
      );
    case ACTIONS.ADD_REMOTE_LOAD:
      return addRemoteLoad(
        state,
        action.payload.defaultPreData,
        action.payload.count,
        action.payload.cbCommunicationValues,
        action.payload.defaultCbData,
        action.payload.siteType,
        action.payload.pmAssetData,
      );
    case ACTIONS.REMOVE_LOAD:
      return removeLoad(state, action.payload.selectedLoad);
    case ACTIONS.REMOVE_REMOTE_LOAD:
      return removeRemoteLoad(state, action.payload.indexes);
    case ACTIONS.SELECT_LOAD_CBASSET:
      return selectLoadCbAsset(
        state,
        action.payload.index,
        action.payload.selectedAsset,
        action.payload.cbCommunicationValues,
        action.payload.defaultValues,
        action.payload.count,
      );
    case ACTIONS.UPDATE_MAXIMUM_LOAD_POWER:
      return updateMaximumLoadPower(
        state,
        action.payload.index,
        action.payload.loadPower
      );
    case ACTIONS.LOAD_CB_ASSET_REQUIRED:
      return loadCbAssetRequired(
        state,
        action.payload.index,
        action.payload.defaultCbData,
        action.payload.defaultValues,
      )
    case ACTIONS.LOAD_ASSET_PM_SELECT:
      return loadPMAssetSelect(
        state,
        action.payload.index
      )
    case ACTIONS.SELECT_LOAD_PMASSET:
      return selectLoadPmAsset(
        state,
        action.payload.index,
        action.payload.selectedAsset,
        action.payload.preFillDefault,
      )
    case ACTIONS.UPDATE_LOAD_POWERMETER_CONFIG:
      return updateLoadPowerMeterConfig(
        state,
        action.payload.selectedAssetPM,
        action.payload.cbConfigData
      )
    case ACTIONS.UPDATE_CB_CONTACTOR_CONFIG_DATA:
      return updateLoadContactorConfigData(
        state,
        action.payload.index,
        action.payload.CBContactor,
        action.payload.checked,
      )
    case ACTIONS.UPDATE_CB_CONTACTOR_CONFIG:
      return selectloadCbContactorAsset(
        state,
        action.payload.index,
        action.payload.selectedAsset,
      )
    case ACTIONS.UPDATE_DER_EXISTING_PM:
      return updateLoadExistingPm(
        state,
        action.payload.componentsData,
        action.payload.value,
      )
    case ACTIONS.UPDATE_DER_CB_CONTACTOR:
      return updateLoadContactor(
        state,
        action.payload.componentsData,
        action.payload.value,
      )
      case ACTIONS.UPDATE_EM_LOAD:
        return updateEMLoad(
          state,
          action.payload.componentsData,
          action.payload.value,
        )
    default:
      return state;
  }
};
const configureEM = (CBLoadList, index)=>{
  let attributes = CBLoadList?.[index]?.cbConfigData?.cbAssetDetails?.[0]?.assetAttributes;
  if(!attributes){
    attributes = CBLoadList?.asset?.assetAttributes;
  }
  const attributesObj = {};
  if(attributes){
    for (let defaultData of attributes) {
      attributesObj[defaultData["type"]] = defaultData["value"];
      }
  }
 return attributesObj;
}


const assetPmSelect = (CBLoadList, index) => {
  const newCBLoadList = [...CBLoadList];
  newCBLoadList[index].uiData.pmSelected = true;
  return newCBLoadList;
};

const updateMaximumLoadPower = (CBLoadList, index, loadPower) => {
  const loadList = [...CBLoadList];
  if(CBLoadList){
  loadList[index].configData.maximum_available_power = loadPower;
  }
  //return new state
  return loadList;
};
const assignDefaultValuesCBLoadList = (
  CBLoadList,
  defaultValues,
  defaults,
  type,
  cb,
  dummyCB,
  pmAssetData,
  contactor,
) => {
  const projectTypeValue = getProjectTypeValue();
  const loadList = _.cloneDeep(CBLoadList);
  const defaultsData = {};
  for (let defaultData of defaults) {
    defaultsData[defaultData["param"]] = defaultData["value"];
  }
  const circuitBreakersModbusSlaveIdList = defaultValues["Circuit Breaker"];
  loadList[0].configData.name = `${defaultValues.Load.name} 1`;
  loadList[0].configData.maximum_available_power = defaultsData.maxPowerLoadMin;
  loadList[0].cbConfigData.name = `${defaultValues["Circuit Breaker"].name} ${defaultValues.Load.name} 1`;
  loadList[0].uiData.CBSelected = false;
  loadList[0].cbConfigData.motorized = true;
  loadList[0].modbusSlaveId =
    circuitBreakersModbusSlaveIdList["modbusId_CB_50"];
    if (type === embLabels.GRID_CONNECTED && projectTypeValue === "MGaaS ANSI"||
    (projectTypeValue === "DaaS ANSI"&&type === embLabels.GRID_CONNECTED_GSX)) {
      loadList[0].uiData.configured =true;
      loadList[0].uiData.CBConfigured =true;
    }
  // if (type === embLabels.GENSET_PVBESS_A2E || type=== embLabels.ISLANDABLE_GSX||type===embLabels.GRID_CONNECTED_GSX) {
  //   loadList[0].cbConfigData.cbAssetDetails[0] = cb;
  // }
 
  if (type !== embLabels.GRID_CONNECTED) {
    loadList[0].cbConfigData.cbAssetDetails[0] = { ...cb };
   
  }
  
  if( !["MGaaS IEC", "DaaS IEC", "MGaaS AccessToEnergy"].includes(projectTypeValue)  ){
    let attributesValue = configureEM(loadList, 0);
    loadList[0].uiData.EMConfigured = attributesValue?.embeddedMetering === "enabled";
    loadList[0].uiData.emSelected = attributesValue?.embeddedMetering === "enabled";
  }
  if (type === embLabels.GRID_CONNECTED && projectTypeValue === "MGaaS IEC") {
    loadList[0].cbConfigData.cbAssetDetails[0] = { ...dummyCB };
  }else if(type === embLabels.ISLANDABLE && projectTypeValue === "MGaaS IEC"){
    loadList[0].contactorConfigData.name = `Load_Contactor_1`;
    loadList[0].contactorConfigData.cbAssetDetails[0] = {...contactor};
    loadList.forEach((loadData,key)=>{
      loadData.uiData.cbRequired = false;
      loadData.uiData.pmRequired = false;
      loadData.powerMeterData.name = `PM_${defaultValues.Load.name}_${key+1}`;
      loadData.powerMeterData.powerMeterModbusId = defaultValues["Power Meter"].modbusId_PM_Load;
      loadData.powerMeterData.ipAddress = defaultValues["Power Meter"][`ipAddress_PM_Load${key+1}`];
      loadData.powerMeterData.gatewayAddress = defaultValues["Power Meter"].defaultGateway;
      loadData.powerMeterData.mask = defaultValues["Power Meter"].subnetMask;
      loadData.powerMeterData.pmAsset = {...pmAssetData};
      loadData.contactorConfigData.name = `Load_Contactor_${key+1}`;
      loadData.contactorConfigData.cbAssetDetails[0] = {...contactor};
    })
  }
  loadList[0].cbConfigData.cbCommunicationData = { ...communicationDataSample };
  let assetInfo =
    type === embLabels.GRID_CONNECTED && projectTypeValue === "MGaaS IEC"
      ? dummyCB?.assetInfo
      : cb?.assetInfo;
  const ipaddress =
    assetInfo === "Serial" || assetInfo === "Hardwired"
      ? ""
      : defaultValues["Circuit Breaker"].ipAddress1;
  const subnetmask =
    assetInfo === "Serial" || assetInfo === "Hardwired"
      ? ""
      : defaultValues["Circuit Breaker"]?.subnetMask;
  const defaultGateway =
    assetInfo === "Serial" || assetInfo === "Hardwired"
      ? ""
      : defaultValues["Circuit Breaker"]?.defaultGateway;
  const modbusSlaveId =
    assetInfo === "Hardwired"
      ? ""
      : assetInfo === "Serial"
      ? defaultValues["Circuit Breaker"].ipAddress7.split(".")[3]
      : defaultValues["Circuit Breaker"].modbusId_CB_50;
  loadList[0].cbConfigData.cbCommunicationData = {
    ipaddress,
    subnetmask,
    defaultGateway,
    modbusSlaveId,
  };
  //return new state
  return loadList;
};
const assignUseCaseLoad = (
  CBLoadList,
  defaultValues,
  defaultCbData,
  siteType,
  dummyCb
) => {
  const loadList = [...CBLoadList];
  loadList[0].uiData.status = true;
  loadList[0].uiData.configured = false;
  if (siteType === embLabels.GRID_CONNECTED && projectTypeValue === "MGaaS ANSI") {
    loadList[0].uiData.configured =true;
    loadList[0].uiData.CBConfigured =true;
  }else if(siteType === embLabels.ISLANDABLE && projectTypeValue === "MGaaS IEC") {
    loadList[0].uiData.cbRequired = false;
    loadList[0].uiData.pmRequired = false;
  }
  //return new state
  return loadList;
};

const updateCbLoadListCoordinates = (CBLoadList, coordinates, count, siteType, projectKind, switchboardType) => {
  let xLoad = coordinates.xLoad;
  let xLoadCB = coordinates.xLoadCB;
  let xLoadNew = coordinates.xLoadNew;
  let xLoadCBNew = coordinates.xLoadCBNew;
  let yLoad = 292;
  let yLoadCB = coordinates.yLoadCB
  let xAxisPM = coordinates.xLoadPM;
  let xAxisPMNew = coordinates.xLoadPMNew;
  let yAxisPM = 370;
  //  const totalLoads = count.loadCount + count.evCount;
  let newLoadData = [];
  let loadList = [...CBLoadList];
  if (siteType === embLabels.GRID_CONNECTED && projectTypeValue === "MGaaS ANSI") {
    loadList[0].uiData.configured =true;
    loadList[0].uiData.CBConfigured =true;
  }
  loadList.forEach((cbLoadData, key) => {
    if(projectKind.isMgaasIecIsland&&key==0){
      if(switchboardType==="NEW_SWITCHBOARD"){
        xLoad = 400;
        yLoad = 368;
        xLoadCB = 445;
        yLoadCB = 370;
        xAxisPM =347;
        yAxisPM =375;
      }else{
        xLoad = 430;
        yLoad = 470;
        xLoadCB = 475;
        yLoadCB = 470;
        xAxisPM =375;
        yAxisPM =475;
      }
    }else if(projectKind.isMgaasIecIsland&&key==5){
      if(switchboardType==="NEW_SWITCHBOARD"){
        xLoad = 455;
        yLoad = 460;
        xLoadCB = 500;
        yLoadCB = 370;
        xAxisPM =330;
        yAxisPM =490;
      }else{
        xLoad = 485;
        yLoad = 550;
        xLoadCB = 530;
        yLoadCB = 470;
        xAxisPM = 320;
        yAxisPM =560;
      }
    }
    if (key) {
      xLoad = coordinates.loadDiff + xLoad;
      xLoadCB = coordinates.loadDiff + xLoadCB;
      xAxisPM = coordinates.loadDiff + xAxisPM;
      if (key > 11) yLoad = 377;
      let loadCount = -1;
      if((projectKind.isDaasAnsiIsland)&&!cbLoadData.uiData.isRemoteLoad){
        loadCount++;
        // alignment modification for daas ansi not completed 
        // modify here for daas ansi
      }

      if ((key === 11 && xLoad !== 1110) || xLoad === 1220) {
        xLoad = xLoadNew;
        xLoadCB = xLoadCBNew;
        yLoad = 377;
      }else if(projectKind.isMgaasIecIsland&&key==5){
        if(switchboardType==="NEW_SWITCHBOARD"){
        xLoad = xLoad-70;
        xLoadCB = xLoadCB-70;
        yLoad = 460
        }else{
          xLoad = xLoad-70;
          xLoadCB = xLoadCB-70;
          xAxisPM = xAxisPM+40;
          yAxisPM = 588
          yLoad = 550
        }
      }else if(projectKind.isMgaasIecIsland&&key<5){
        if(switchboardType==="NEW_SWITCHBOARD"){
          xLoad = xLoad+40;
          xLoadCB = xLoadCB+40;
          xAxisPM = xAxisPM+40;
          yLoad = 370
        }else{
          xLoad = xLoad+40;
          xLoadCB = xLoadCB+40;
          xAxisPM = xAxisPM+40;
        }
      }else if(projectKind.isMgaasIecIsland&&key>5){
        if(switchboardType==="NEW_SWITCHBOARD"){
          xLoad = xLoad+38;
          xLoadCB = xLoadCB+38;
          xAxisPM = xAxisPM+38;
          yLoad = 460
          }else{
            xLoad = xLoad+38;
            xLoadCB = xLoadCB+38;
            xAxisPM = xAxisPM+38;
            yAxisPM = 588
            yLoad = 550
          }
      }
    }

    cbLoadData.uiData.xAxis = xLoad;
    cbLoadData.uiData.yAxis = yLoad;
    cbLoadData.uiData.xAxisCB = xLoadCB;
    cbLoadData.uiData.yAxisCB = 290;
    if(projectKind.isMgaasIecIsland){
      cbLoadData.uiData.xAxisPM = xAxisPM;
      cbLoadData.uiData.yAxisPM = yAxisPM;
      cbLoadData.uiData.yAxisCB = yLoadCB
    }
    newLoadData.push(cbLoadData);
    //return null;
  });

  return newLoadData;
};

const updateCbLoadListCoordinatesGensetLoad = (
  CBLoadList,
  coordinates,
  count,
  projectKind,
  switchboardType,
) => {
  let xLoad = 440;
  let xLoadCB = 485;
  let xLoadNew = 505;
  let xLoadCBNew = 650;
  let xLoadEM = 446;
  let yLoadEM = 370;
  let xLoadEMNew = 501;
  let yLoad = 450;
  //  const totalLoads = count.loadCount + count.evCount;
  let newLoadData = [];
  let loadList =  CBLoadList? [...CBLoadList]:[];
  let svgldx = []
  let svgldy = []
  let loadCount = -1;
  let remoteCount = 0;
  let svgline = [];
  let loadLine = [];
  let oldXvalue = []

  loadList.forEach((cbLoadData, key) => {
    if((projectKind.isMgaasAnsiIsland)&&!cbLoadData.uiData.isRemoteLoad&&switchboardType===embLabels.EXISTING_SWITCHBOARD){
      loadCount++;


      if(loadCount==0){
        yLoad = 415
        
      }else if(loadCount==1){
        xLoad = 585;
        xLoadCB = 585;
        svgline[key] = 200;
        loadLine[key] = 250;
        
      }else if(loadCount>1&&loadCount<5&&key<5){
        xLoad = xLoad+80;
        xLoadCB = xLoadCB+105;
      }else if(key==5){
        xLoadCB = 650
        yLoad = 480
      }else if(loadCount>4&&key>=5){
        xLoadCB = xLoadCB+100;
        
      }else if(loadCount>=5&&key>5){
        xLoadCB = xLoadCB+100;
        svgline[key] = 200;
        loadLine[key] = 250;
      }
    }else{
      if(cbLoadData.uiData.isRemoteLoad){
        if(remoteCount==0){
          svgldx[key] = -40;
          svgldy[key] = -95;
          svgline[key] = 200
          loadLine[key] = 250
          oldXvalue.push(svgldx[key])
        }
        if(remoteCount==1){
          svgldx[key] = oldXvalue[0]-40;
          svgldy[key] = -95;
          svgline[key] = 300
          loadLine[key] = 350
          oldXvalue[0] = svgldx[key]

        }
        if(remoteCount==2){
          svgldx[key] = oldXvalue[0]-40;
          svgldy[key] = -95;
          svgline[key] = 200
          loadLine[key] = 250
          oldXvalue[0] =svgldx[key]
        }
        if(remoteCount==3){
          svgldx[key] = oldXvalue[0]-40;
          svgldy[key] = -95;
          svgline[key] = 300
          loadLine[key] = 350

        }
        remoteCount++;
      }
      if (key) {
        xLoad = coordinates.loadDiff + xLoad;
        xLoadCB = coordinates.loadDiff + xLoadCB;
        if (key > 5) yLoad = 534;
  
        if ((key === 5 && xLoad !== 480) || xLoad === 495) {
          xLoad = xLoadNew;
          xLoadCB = xLoadCBNew;
          yLoad = 534;
        }
      }
    }

    cbLoadData.uiData.xAxis = xLoad;
    cbLoadData.uiData.yAxis = yLoad;
    cbLoadData.uiData.xAxisCB = xLoadCB;
    cbLoadData.uiData.yAxisCB = 430;
    cbLoadData.uiData.xAxisEM = xLoadEM;
    cbLoadData.uiData.yAxisEM = yLoadEM;

    
for (let i = 0; i < 10; i++) {
  cbLoadData.uiData[`svgldx${i}`] = svgldx[i];
  cbLoadData.uiData[`svgldy${i}`] = svgldy[i];
  cbLoadData.uiData[`svgline${i}`] = svgline[i];
  cbLoadData.uiData[`loadLine${i}`] = loadLine[i];


}


    newLoadData.push(cbLoadData);
    //return null;
  });

  return newLoadData;
};

const loadCbAssetRequired = (CBLoadList, index, defaultCbData, defaultValues) => {
  let loadList = [...CBLoadList];
  loadList[index].uiData.cbRequired = !loadList[index].uiData.cbRequired;
  loadList[index].uiData.CBConfigured = loadList[index].uiData.cbRequired?false:true;
  if (loadList[index]?.uiData?.cbRequired) {
    loadList[index].cbConfigData.cbAssetDetails =
      defaultCbData === null ? [] : [{ ...defaultCbData }];
    loadList[index].uiData.CBConfigured = false;
    let modbusArray = [
      "modbusId_CB_50",
      "modbusId_CB_51",
      "modbusId_CB_52",
      "modbusId_CB_53",
      "modbusId_CB_54",
      "modbusId_CB_55",
      "modbusId_CB_56",
      "modbusId_CB_57",
      "modbusId_CB_58",
      "modbusId_CB_59",
    ]
    loadList[index].cbConfigData.cbCommunicationData.ipaddress =
    defaultValues["Circuit Breaker"].ipAddress1;
    loadList[index].cbConfigData.cbCommunicationData.subnetmask =
    defaultValues["Circuit Breaker"].subnetMask2;
    loadList[index].cbConfigData.cbCommunicationData.defaultGateway =
    defaultValues["Circuit Breaker"].defaultGateway3;
    loadList[index].cbConfigData.cbCommunicationData.modbusSlaveId = defaultValues["Circuit Breaker"][modbusArray[index]];
  } else {
    loadList[index].cbConfigData.cbAssetDetails =
    defaultCbData === null ? [] : [{ ...defaultCbData }];
    loadList[index].uiData.CBConfigured = true;
    loadList[index].uiData.motorized = false;
    loadList[index].cbConfigData.cbCommunicationData.ipaddress = "";
    loadList[index].cbConfigData.cbCommunicationData.subnetmask = "";
    loadList[index].cbConfigData.cbCommunicationData.defaultGateway = "";
    loadList[index].cbConfigData.cbCommunicationData.modbusSlaveId = "";
  }
  return loadList;
}

const loadPMAssetSelect = (CBLoadList, index) => {
  let loadList = [...CBLoadList];
  loadList[index].uiData.pmSelected = true;
  loadList.forEach((load)=>{
      load.uiData.selected = false;
      load.uiData.CBSelected = false;
  })
  return loadList;
}

const selectLoadPmAsset = (CBLoadList, index,selectedAsset,preFillDefault) => {
  const loadList = _.cloneDeep(CBLoadList);
  loadList[index].powerMeterData.pmAsset = { ...selectedAsset };
  loadList[index].uiData.pmConfiguredGrid = false;
  loadList[index].powerMeterData.powerMeterModbusId = preFillDefault.modbusId_PM_Load
  if (selectedAsset.assetInfo === embLabels.COMMUNICATING) {
    loadList[index].powerMeterData.ipAddress = preFillDefault[`ipAddress_PM_Load${index+1}`];
    loadList[index].powerMeterData.gatewayAddress = preFillDefault[`defaultGateway`];
    loadList[index].powerMeterData.mask = preFillDefault[`subnetMask`];
  }
  if (selectedAsset.assetInfo === embLabels.SERIAL||selectedAsset.assetInfo === "Hardwired") {
    loadList[index].powerMeterData.ipAddress = "";
    loadList[index].powerMeterData.gatewayAddress = "";
    loadList[index].powerMeterData.mask = "";
  }

  return loadList;
}

const updateLoadPowerMeterConfig = (CBLoadList, selectedAssetPM,cbConfigData) => {
  const newPowerMeterData = _.cloneDeep(CBLoadList);
  newPowerMeterData[selectedAssetPM].uiData.pmConfigured = true;
  newPowerMeterData[selectedAssetPM].uiData.pmConfiguredGrid = true;
  newPowerMeterData[selectedAssetPM].powerMeterData.name = cbConfigData.name;
  newPowerMeterData[selectedAssetPM].powerMeterData.description =
    cbConfigData.description;

  return newPowerMeterData;
}
const updateLoadContactorConfigData = (CBLoadList, index, CBContactor, checked) => {
  const newCBLoadList = _.cloneDeep(CBLoadList);
  newCBLoadList[index].uiData.contactor = checked;
  newCBLoadList[index].contactorConfigData = {...CBContactor}
  return newCBLoadList;
}

const selectloadCbContactorAsset = (CBLoadList, index, selectedAsset) => {
  const newCBLoadList = _.cloneDeep(CBLoadList);
  newCBLoadList[index].contactorConfigData.cbAssetDetails[0] = selectedAsset;
  return newCBLoadList;
}

const updateLoadExistingPm = (CBLoadList, componentsData, value) => {
  let name = componentsData.componentName;

  let CBLoadListData = _.cloneDeep(CBLoadList);
  if(value===0){
    const attributes = JSON.parse(componentsData.attributes);
    CBLoadListData[value].powerMeterData.name = name;
    CBLoadListData[value].powerMeterData.powerMeterModbusId =
      componentsData.communications[0].modbusSlaveId;
    CBLoadListData[value].powerMeterData.ipAddress =
      componentsData.communications[0].ip;
    CBLoadListData[value].powerMeterData.gatewayAddress =
      componentsData.communications[0].defaultGateway;
    CBLoadListData[value].powerMeterData.mask =
      componentsData.communications[0].subnetmask;
    CBLoadListData[value].powerMeterData.description =
      attributes.description;
    CBLoadListData[value].powerMeterData.pmAsset = {
      ...componentsData.asset,
    };
  }else{
    let loadData = CBLoadListData[value]
    let pmLoadData ={
      powerMeterData:{
        name: "",
        description: "",
        powerMeterModbusId: "",
        ipAddress: "",
        gatewayAddress: "",
        mask: "",
        pmAsset: {},
      }
    };
    const attributes = JSON.parse(componentsData.attributes);
    pmLoadData.powerMeterData.name = name;
    pmLoadData.powerMeterData.powerMeterModbusId =
      componentsData.communications[0].modbusSlaveId;
    pmLoadData.powerMeterData.ipAddress =
      componentsData.communications[0].ip;
    pmLoadData.powerMeterData.gatewayAddress =
      componentsData.communications[0].defaultGateway;
    pmLoadData.powerMeterData.mask =
      componentsData.communications[0].subnetmask;
    pmLoadData.powerMeterData.description =
      attributes.description;
    pmLoadData.powerMeterData.pmAsset = {
      ...componentsData.asset,
    };
    loadData.powerMeterData = pmLoadData.powerMeterData;
    CBLoadListData[value] = loadData;
  }

  return CBLoadListData;
};


const updateLoadContactor = (CBLoadList, componentsData, value) => {

  let CBLoadListData = _.cloneDeep(CBLoadList);
  const attributes = JSON.parse(componentsData.attributes);
    let loadData = CBLoadListData[value]
    let contactorLoad ={
      cbAssetDetails: [],
      componentId: "",
      componentIdx: "",
      componentName: "",
      componentType: "",
      communications: [],
      attributes: "",
      connections: [],
      uiAttributes: "",
      asset: {},
  }
    contactorLoad.name =
    componentsData.componentName;
  contactorLoad.description = attributes.description;
  contactorLoad.loadCategory = parseInt(attributes.loadCategory);
  contactorLoad.loadPriority = parseInt(attributes.loadPriority);
  contactorLoad.cbAssetDetails[0] = componentsData.asset;
  if (componentsData.communications.length) {
    contactorLoad.communications.ipaddress = componentsData.communications[0].ip;
    contactorLoad.communications.subnetmask = componentsData.communications[0].subnetmask;
      contactorLoad.communications.defaultGateway = componentsData.communications[0].defaultGateway;
      contactorLoad.communications.modbusSlaveId = componentsData.communications[0].modbusSlaveId;
  }
    loadData.contactorConfigData = contactorLoad;
    CBLoadListData[value] = loadData;
  

  return CBLoadListData;
};

const updateEMLoad = (CBLoadList, componentsData, index)=>{
  const projectTypeValue = getProjectTypeValue();
  let CBLoadListData = _.cloneDeep(CBLoadList);
  let  attributesValue = configureEM(componentsData, index);
  if(projectTypeValue === "MGaaS ANSI" || projectTypeValue === "DaaS ANSI"){
    CBLoadListData[index].uiData.EMConfigured = attributesValue?.embeddedMetering === "enabled";
    CBLoadListData[index].uiData.emSelected = attributesValue?.embeddedMetering === "enabled";
    }
  
  return CBLoadListData;
}

const loadPMAssetRequired = (CBLoadList, index, defaultPmData, defaultValues) => {
  let loadList = [...CBLoadList];
  let loadData = {...loadList[index].powerMeterData,pmAsset:{},name:""};
  loadList[index].uiData.pmRequired = !loadList[index].uiData.pmRequired;
  loadList[index].uiData.pmConfiguredGrid = loadList[index].uiData.pmRequired?false:true;
  loadData.pmAsset = defaultPmData
  ? { ...defaultPmData }
  : {};
  loadData.name = `PM_Load_${index+1}`
  loadList[index].powerMeterData = loadData;
    let ipAddressName = [
      "ipAddress_PM_Load1",
      "ipAddress_PM_Load2",
      "ipAddress_PM_Load3",
      "ipAddress_PM_Load4",
      "ipAddress_PM_Load5",
      "ipAddress_PM_Load6",
      "ipAddress_PM_Load7",
      "ipAddress_PM_Load8",
      "ipAddress_PM_Load9",
      "ipAddress_PM_Load10",
    ];
  let ipAddress = ipAddressName[index];

  loadList[index].powerMeterData.powerMeterModbusId =
    defaultPmData.assetInfo === "Hardwired"
      ? ""
      : defaultPmData.assetInfo === "Serial"
      ? defaultValues["Power Meter"][ipAddress]?.split(".")[3]
      : defaultValues["Power Meter"]?.["modbusId_PM_Load"];

  if (defaultPmData.assetInfo === embLabels.COMMUNICATING) {
    loadList[index].powerMeterData.ipAddress =
      defaultValues["Power Meter"][ipAddress];
    loadList[index].powerMeterData.gatewayAddress =
      defaultValues["Power Meter"].defaultGateway;
    loadList[index].powerMeterData.mask =
      defaultValues["Power Meter"].subnetMask;
  }
  if (defaultPmData.assetInfo === "Hardwired") {
    loadList[index].powerMeterData.ipAddress = "";
    loadList[index].powerMeterData.gatewayAddress = "";
    loadList[index].powerMeterData.mask = "";
  }
  if (defaultPmData.assetInfo === embLabels.SERIAL) {
    loadList[index].powerMeterData.ipAddress = "";
    loadList[index].powerMeterData.gatewayAddress = "";
    loadList[index].powerMeterData.mask = "";
  }
  loadList[index].powerMeterData.name =
    loadList[index].powerMeterData.name ??
    `PM_${defaultValues.Load.name}_${index + 1}`;

  return loadList;
}

const updateCbLoadListCoordinatesGridConnected = (CBLoadList) => {
  let loadList = [...CBLoadList];
  loadList[0].uiData.xAxis = "377.5";
  loadList[0].uiData.yAxis = "385";

  return loadList;
};

const updateCbLoadListCoordinatesPvHybridization = (CBLoadList) => {
  let loadList = [...CBLoadList];

  loadList[0].uiData.xAxis = "398";
  loadList[0].uiData.yAxis = "366";
  loadList[0].uiData.xAxisCB = "442.5";
  loadList[0].uiData.yAxisCB = "360";

  return loadList;
};

const updateCbLoadListCoordinatesPvGensetAts = (CBLoadList, switchType) => {
  let loadList = [...CBLoadList];

  loadList[0].uiData.xAxis = "139";
  loadList[0].uiData.yAxis = "299";
  loadList[0].uiData.xAxisCB = "183.5";
  loadList[0].uiData.yAxisCB = switchType === embLabels.NEW_SWITCHBOARD ? 300 : 285.8956;

  return loadList;
};
const updateCbLoadListCoordinatesPvBessNr = (CBLoadList,switchType) => {
  let loadList = [...CBLoadList];
  loadList[0].uiData.xAxis = "99";
  loadList[0].uiData.yAxis = "300";
  loadList[0].uiData.xAxisCB = "144";
  loadList[0].uiData.yAxisCB =  switchType === embLabels.NEW_SWITCHBOARD ? 300 : 285;

  return loadList;
};

const updateLoad = (CBLoadList, componentsData, loadIndex,siteType,projectTypeValue) => {
  
  const attributes = JSON.parse(componentsData.attributes);
  let newLoadData = [...CBLoadList];
  if (loadIndex === 0) {
    newLoadData[0].uiData = JSON.parse(componentsData.uiAttributes);
    if(!["MGaaS IEC", "DaaS IEC", "MGaaS AccessToEnergy"].includes(projectTypeValue) ){
    let attributesValue = configureEM(newLoadData, 0);
    newLoadData[0].uiData.EMConfigured = attributesValue?.embeddedMetering === "enabled";
    newLoadData[0].uiData.emSelected = attributesValue?.embeddedMetering === "enabled";
  }
    newLoadData[0].uiData.selected = false;
    newLoadData[0].uiData.CBSelected = false;
    newLoadData[0].uiData.pmSelected = false;
    newLoadData[0].configData = {};
    newLoadData[0].cbConfigData = {};
    newLoadData[0].configData.name = componentsData.componentName;
    newLoadData[0].configData.description = attributes.description;
    newLoadData[0].configData.maximum_available_power =
      attributes.maximum_available_power;
    newLoadData[0].assetType = componentsData.componentType;
    if(siteType===embLabels.GRID_CONNECTED&&projectTypeValue==="MGaaS ANSI") newLoadData[0].uiData.configured = true;
  } else {
    let loads = {};
    loads.uiData = JSON.parse(componentsData.uiAttributes);
    loads.uiData.selected = false;
    loads.uiData.CBSelected = false;
    loads.uiData.pmSelected = false;
    loads.configData = {};
    loads.cbConfigData = {};
    loads.configData.name = componentsData.componentName;
    loads.configData.description = attributes.description;
    loads.configData.maximum_available_power =
      attributes.maximum_available_power;
    loads.assetType = componentsData.componentType;
    newLoadData.push(loads);
  }
  return newLoadData;
};

const updateLoadCb = (CBLoadList, componentsData, loadCBIndex,siteType,projectTypeValue,contactor) => {
  const CBLoadListUpdated = _.cloneDeep(CBLoadList);
  const attributes = JSON.parse(componentsData.attributes);
  if (componentsData?.communications?.[0] && CBLoadListUpdated?.[loadCBIndex]) {
    CBLoadListUpdated[loadCBIndex].modbusSlaveId =
      componentsData.communications[0].modbusSlaveId;
  }
  CBLoadListUpdated[loadCBIndex].cbConfigData.description =
    attributes.description;
  CBLoadListUpdated[loadCBIndex].cbConfigData.name =
    componentsData.componentName;
  CBLoadListUpdated[loadCBIndex].cbConfigData.motorized =
    parseInt(attributes.motorized) === 1 ? true : false;
  CBLoadListUpdated[loadCBIndex].cbConfigData.loadCategory = parseInt(
    attributes.loadCategory
  );
  CBLoadListUpdated[loadCBIndex].cbConfigData.loadPriority = parseInt(
    attributes.loadPriority
  );
  if(siteType===embLabels.GRID_CONNECTED&&projectTypeValue==="MGaaS ANSI") CBLoadListUpdated[loadCBIndex].uiData.CBConfigured = true;
  CBLoadListUpdated[loadCBIndex].cbConfigData.cbAssetDetails = [];
  CBLoadListUpdated[loadCBIndex].cbConfigData.cbAssetDetails[0] =
    componentsData.asset;
  CBLoadListUpdated[loadCBIndex].cbConfigData.cbCommunicationData = {
    ...communicationDataSample,
  };
  if(siteType===embLabels.ISLANDABLE&&projectTypeValue==="MGaaS IEC"&&loadCBIndex){
    let loadData = CBLoadListUpdated[loadCBIndex];
    loadData.contactorConfigData = {
      name: `Load_Contactor_${loadCBIndex+1}`,
      description:"",
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: [],
    }
    loadData.contactorConfigData.cbAssetDetails[0] = contactor;
    CBLoadListUpdated[loadCBIndex] = loadData;
  }

  if (componentsData?.communications?.length && CBLoadListUpdated?.[loadCBIndex]) {
    CBLoadListUpdated[loadCBIndex].cbConfigData.cbCommunicationData.ipaddress =
      componentsData.communications[0].ip;
    CBLoadListUpdated[loadCBIndex].cbConfigData.cbCommunicationData.subnetmask =
      componentsData.communications[0].subnetmask;
    CBLoadListUpdated[
      loadCBIndex
    ].cbConfigData.cbCommunicationData.defaultGateway =
      componentsData.communications[0].defaultGateway;
    CBLoadListUpdated[
      loadCBIndex
    ].cbConfigData.cbCommunicationData.modbusSlaveId =
      componentsData.communications[0].modbusSlaveId;
  }

  return CBLoadListUpdated;
};

const closeDetailsLoad = (CBLoadList) => {
  const newCBLoadList = [...CBLoadList];
  newCBLoadList.forEach((load) => {
    load.uiData.selected = false;
    load.uiData.CBSelected = false;
    load.uiData.pmSelected = false;
    load.uiData.detailsBlock = false;
  });
  return newCBLoadList;
};

const loadSelect = (CBLoadList, index) => {
  const newCBLoadList = [...CBLoadList];
  newCBLoadList[index].uiData.selected = true;
  newCBLoadList.forEach((load)=>{
      load.uiData.pmSelected = false;
      load.uiData.CBSelected = false;
  })
  return newCBLoadList;
};

const loadCbSelect = (CBLoadList, index) => {
  const newCBLoadList = [...CBLoadList];
  newCBLoadList[index].uiData.CBSelected = true;
  newCBLoadList.forEach((load)=>{
      load.uiData.pmSelected = false;
      load.uiData.selected = false;
  })
  return newCBLoadList;
};

const updateLoadConfig = (CBLoadList, selectedLoad, configData) => {
  const cbDataList = [...CBLoadList];
  cbDataList[selectedLoad].configData = configData;
  cbDataList[selectedLoad].uiData.configured = true;

  return CBLoadList;
};
const duplicateLoadUpdate = (CBLoadList, selectedLoad) => {
  const cbDataList = [...CBLoadList];
  cbDataList[selectedLoad].uiData.configured = false;

  return CBLoadList;
};

const updateLoadCbConfig = (CBLoadList, selectedLoadCB, cbConfigData) => {
  const cbDataList = [...CBLoadList];
  cbDataList[selectedLoadCB].cbConfigData = {
    ...cbDataList[selectedLoadCB].cbConfigData,
    ...cbConfigData,
  };
  cbDataList[selectedLoadCB].uiData.CBConfigured = true;

  return CBLoadList;
};

const addLoad = (
  CBLoadList,
  defaultPreData,
  count,
  cbCommunicationValues,
  defaultCbData,
  siteType,
  pmAssetData,
  contactor,
  defaults = {},
  projectTypeValue,
) => {
  const defaultsData = {};
  for (let defaultData of defaults) {
    defaultsData[defaultData["param"]] = defaultData["value"];
  }
  const newLoadData = _.cloneDeep(CBLoadList);
  const totalLoads = count.loadCount + count.derCount + count.evCount;
  let cBMBData = defaultPreData["Circuit Breaker"];
  let cbModbusIndex = 50 + CBLoadList.length;
  if (cbModbusIndex > 59) cbModbusIndex += 1;
  let ipaddressName;
  if((count.derCount+count.loadCount+count.evCount)<=7){
    ipaddressName = `ipAddress${1}`;
   }else if((count.derCount+count.loadCount+count.evCount)>=8&&(count.derCount+count.loadCount+count.evCount)<=14){
     ipaddressName = `ipAddress${2}`;
   }else{
     ipaddressName = `ipAddress${3}`;
   }
  const ipaddress =
    defaultCbData?.assetInfo === "Serial" ||
    defaultCbData?.assetInfo === "Hardwired"
      ? ""
      : cBMBData[ipaddressName];
  const subnetmask =
    defaultCbData?.assetInfo === "Serial" ||
    defaultCbData?.assetInfo === "Hardwired"
      ? ""
      : cBMBData?.subnetMask;
  const defaultGateway =
    defaultCbData?.assetInfo === "Serial" ||
    defaultCbData?.assetInfo === "Hardwired"
      ? ""
      : cBMBData?.defaultGateway;
  const modbusSlaveId =
    defaultCbData?.assetInfo === "Hardwired"
      ? ""
      : defaultCbData?.assetInfo === "Serial"
      ? cBMBData.ipAddress7.split(".")[3]
      : cBMBData["modbusId_CB_" + cbModbusIndex];

  const cbCommunicationData = {
    subnetmask,
    ipaddress,
    defaultGateway,
    modbusSlaveId,
  };

  let newLoad = {
    assetConnected: "Load",
    modbusSlaveId: "21",
    configData: {
      name: "",
      description: "",
      maximum_available_power: 0,
    },
    uiData: {
      status: true,
      EMConfigured:false,
      emSelected:false,
      CBSelected: false,
      configured: false,
      CBConfigured: false,
      cbRequired: false,
      pmRequired: false,
      isRemoteLoad: false,
      contactor: false,
      yAxisCB: 290,
      yAxis: 292,
    },
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: defaultCbData === null ? [] : [{ ...defaultCbData }],
      cbCommunicationData: {
        ...cbCommunicationData,
      },
    },
    contactorConfigData:{
      name: "",
      description: "",
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: [],
      cbCommunicationData: {},
    },
    powerMeterData:{
      name: "",
      description: "",
      powerMeterModbusId: "",
      ipAddress: "",
      gatewayAddress: "",
      mask: "",
      pmAsset: {...pmAssetData},
    }
  };
  newLoadData.push(newLoad);

  let cbIndex = 50;
  let loadIndex = 1;
  let cofigNameArray = newLoadData.map(
    (loadData) => loadData?.configData?.name
  );
  cofigNameArray = cofigNameArray.filter((loadName) => loadName !== "");
  let missingLoadNumber = findMissingLoadNumber(cofigNameArray);

  newLoadData.map((loadData, key) => {
    newLoadData[key].modbusSlaveId =
      totalLoads % 7 === 0 ? "255" : cBMBData["modbusId_CB_" + cbIndex];
    if (newLoadData?.[key]?.configData?.name === "") {
      if (missingLoadNumber) {
        newLoadData[
          key
        ].configData.name = `${defaultPreData.Load.name} ${missingLoadNumber}`;
      } else {
        newLoadData[
          key
        ].configData.name = `${defaultPreData.Load.name} ${loadIndex}`;
      }
      let pmIndex = missingLoadNumber ?? loadIndex
      if(siteType===embLabels.ISLANDABLE&&projectTypeValue==="MGaaS IEC") {
        newLoadData[
          key
        ].configData.maximum_available_power = defaultsData?.maxPowerLoadMin;
        newLoadData[key].uiData.cbRequired = false;
        newLoadData[key].uiData.pmRequired = false;
        newLoadData[key].powerMeterData.name = `PM_${defaultPreData.Load.name}_${pmIndex}`;
        newLoadData[key].powerMeterData.powerMeterModbusId = defaultPreData["Power Meter"].modbusId_PM_Load;
        newLoadData[key].powerMeterData.ipAddress = defaultPreData["Power Meter"][`ipAddress_PM_Load${pmIndex}`];
        newLoadData[key].powerMeterData.gatewayAddress = defaultPreData["Power Meter"].defaultGateway;
        newLoadData[key].powerMeterData.mask = defaultPreData["Power Meter"].subnetMask;
        newLoadData[key].powerMeterData.pmAsset = {...pmAssetData};
        newLoadData[key].contactorConfigData.name = `Load_Contactor_${pmIndex}`;
        newLoadData[key].contactorConfigData.cbAssetDetails[0] = {...contactor};
      }
      // load seection
    }
    if (newLoadData[key].cbConfigData.name === "") {
      if(missingLoadNumber){
        newLoadData[
          key
        ].cbConfigData.name = `${defaultPreData["Circuit Breaker"].name} ${defaultPreData.Load.name} ${missingLoadNumber}`;
      }else{
        newLoadData[
          key
        ].cbConfigData.name = `${defaultPreData["Circuit Breaker"].name} ${defaultPreData.Load.name} ${loadIndex}`;
      }
      
    }
    if(siteType===embLabels.ISLANDABLE&&projectTypeValue==="MGaaS IEC"){
      if (newLoadData[key].powerMeterData?.name === "") {
        if(missingLoadNumber){
          newLoadData[
            key
          ].powerMeterData.name = `PM_LOAD ${missingLoadNumber}`;
        }else{
          newLoadData[
            key
          ].powerMeterData.name = `PM_LOAD ${loadIndex}`;
        }
      }
    }
    cbIndex = cbIndex + 1;
    if (cbIndex === 60) {
      cbIndex = 61;
    }
    if (totalLoads % 7 === 0) {
      cbIndex = cbIndex + 1;
    }
    loadIndex = loadIndex + 1;
    return null;
  });
 
  let index = newLoadData.length - 1;
  newLoadData[index].uiData.status = true;
  newLoadData[index].uiData.configured = false;
  // newLoadData[index].uiData.CBConfigured = true;
  if(!["MGaaS IEC", "DaaS IEC", "MGaaS AccessToEnergy"].includes(projectTypeValue) ){
  let attributesValue = configureEM(newLoadData, index);
  newLoadData[index].uiData.EMConfigured = attributesValue?.embeddedMetering === "enabled";
  newLoadData[index].uiData.emSelected = attributesValue?.embeddedMetering === "enabled";
}
console.log("newLoadData",newLoadData)
  return newLoadData;
};

const updateRemoteLoadCoordinates = (CBLoadList,coordinates,loadSide,count,siteType,projectKind,switchboardType) => {
  let loadList = [...CBLoadList];
  let xLoad = coordinates.xLoad;
  let xLoadCB = coordinates.xLoadCB;
  let xLoadNew = coordinates.xLoadNew;
  let xLoadCBNew = coordinates.xLoadCBNew;
  let yLoad = 292;
  let yLoadCB = coordinates.yLoadCB
  let xAxisPM = coordinates.xLoadPM;
  let xAxisPMNew = coordinates.xLoadPMNew;
  let svgldx0 = 0;
  let svgldy0 = 0;
  let yAxisPM = 370;
  let newLoadData = [];
  let loadCount = -1;
  loadList.forEach((cbLoadData, key) => {
    if(cbLoadData.uiData.isRemoteLoad){
      loadCount++;
      if(loadCount==0){
        xLoad = 0;
        yLoad = loadSide==="LINE_SIDE"?190:290;
        xLoadCB = 75;
        yLoadCB = loadSide==="LINE_SIDE"?190:285;
        xAxisPM =300;
        yAxisPM =485;
        svgldx0 = 0;
        svgldy0 = -20;
      }
      
    if(loadCount){
      xLoad = xLoad+80;
      xLoadCB = xLoadCB+90;
      yLoad = loadSide==="LINE_SIDE"?190:290
    }
    cbLoadData.uiData.xAxis = xLoad;
    cbLoadData.uiData.yAxis = yLoad;
    cbLoadData.uiData.xAxisCB = xLoadCB;
    cbLoadData.uiData.yAxisCB = yLoadCB
    cbLoadData.uiData.xAxisPM = xAxisPM;
    cbLoadData.uiData.yAxisPM = yAxisPM;
    cbLoadData.uiData.svgldx0 = svgldx0;
    cbLoadData.uiData.svgldy0 = svgldy0;
    newLoadData.push(cbLoadData);
    }
  });

  return loadList;
};


const addRemoteLoad = (
  CBLoadList,
  defaultPreData,
  count,
  cbCommunicationValues,
  defaultCbData,
  siteType,
) => {
  const newLoadData = _.cloneDeep(CBLoadList);
  const totalLoads = count.loadCount + count.derCount + count.evCount;
  let cBMBData = defaultPreData["Circuit Breaker"];
  let cbModbusIndex = 50 + CBLoadList.length;
  if (cbModbusIndex > 59) cbModbusIndex += 1;
  let ipaddressName;
  if((count.derCount+count.loadCount+count.evCount)<=7){
    ipaddressName = `ipAddress${1}`;
   }else if((count.derCount+count.loadCount+count.evCount)>=8&&(count.derCount+count.loadCount+count.evCount)<=14){
     ipaddressName = `ipAddress${2}`;
   }else{
     ipaddressName = `ipAddress${3}`;
   }
  const ipaddress =
    defaultCbData?.assetInfo === "Serial" ||
    defaultCbData?.assetInfo === "Hardwired"
      ? ""
      : cBMBData[ipaddressName];
  const subnetmask =
    defaultCbData?.assetInfo === "Serial" ||
    defaultCbData?.assetInfo === "Hardwired"
      ? ""
      : cBMBData?.subnetMask;
  const defaultGateway =
    defaultCbData?.assetInfo === "Serial" ||
    defaultCbData?.assetInfo === "Hardwired"
      ? ""
      : cBMBData?.defaultGateway;
  const modbusSlaveId =
    defaultCbData?.assetInfo === "Hardwired"
      ? ""
      : defaultCbData?.assetInfo === "Serial"
      ? cBMBData.ipAddress7.split(".")[3]
      : cBMBData["modbusId_CB_" + cbModbusIndex];

  const cbCommunicationData = {
    subnetmask,
    ipaddress,
    defaultGateway,
    modbusSlaveId,
  };

  let newLoad = {
    assetConnected: "Load",
    modbusSlaveId: "21",
    configData: {
      name: "",
      description: "",
      maximum_available_power: 0,
    },
    uiData: {
      status: true,
      CBSelected: false,
      configured: false,
      CBConfigured: false,
      EMConfigured:false,
      emSelected:false,
      isRemoteLoad: true,
      cbRequired: true,
      pmRequired: true,
      yAxisCB: 290,
      yAxis: 292,
    },
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: defaultCbData === null ? [] : [{ ...defaultCbData }],
      cbCommunicationData: {
        ...cbCommunicationData,
      },
    },
  };
  const attributes = defaultCbData?.assetAttributes;
  const attributesObj = {};
  if(attributes){
    for (let defaultData of attributes) {
      attributesObj[defaultData["type"]] = defaultData["value"];
      }
  }
  if( !["MGaaS IEC", "DaaS IEC", "MGaaS AccessToEnergy"].includes(projectTypeValue)  ){
    
    newLoad.uiData.EMConfigured = attributesObj?.embeddedMetering === "enabled";
    newLoad.uiData.emSelected = attributesObj?.embeddedMetering === "enabled";
  }
  newLoadData.push(newLoad);

  let cbIndex = 50;
  let loadIndex = 1;
  let cofigNameArray = newLoadData.map(
    (loadData) => loadData?.configData?.name
  );
  cofigNameArray = cofigNameArray.filter((loadName) => loadName !== "");
  let missingLoadNumber = findMissingLoadNumber(cofigNameArray);

  newLoadData.map((loadData, key) => {
    newLoadData[key].modbusSlaveId =
      totalLoads % 7 === 0 ? "255" : cBMBData["modbusId_CB_" + cbIndex];
    if (newLoadData[key].configData.name === "") {
      if (missingLoadNumber) {
        newLoadData[
          key
        ].configData.name = `${defaultPreData.Load.name} ${missingLoadNumber}`;
      } else {
        newLoadData[
          key
        ].configData.name = `${defaultPreData.Load.name} ${loadIndex}`;
      }
      // load seection
    }
    if (newLoadData[key].cbConfigData.name === "") {
      if(missingLoadNumber){
        newLoadData[
          key
        ].cbConfigData.name = `${defaultPreData["Circuit Breaker"].name} ${defaultPreData.Load.name} ${missingLoadNumber}`;
      }else{
        newLoadData[
          key
        ].cbConfigData.name = `${defaultPreData["Circuit Breaker"].name} ${defaultPreData.Load.name} ${loadIndex}`;
      }
      
    }
    cbIndex = cbIndex + 1;
    if (cbIndex === 60) {
      cbIndex = 61;
    }
    if (totalLoads % 7 === 0) {
      cbIndex = cbIndex + 1;
    }
    loadIndex = loadIndex + 1;
    return null;
  });
  
 

  return newLoadData;
};



const findMissingLoadNumber = (array) => {
  function findMissingNumbers(arr) {
    // let min = Math.min(...arr);
    // let max = Math.max(...arr);
    // let fullArr = Array.from({ length: max - min + 1 }, (_, i) => i + min);
    // return fullArr.filter((num) => !arr.includes(num));
    let fullArr = Array.from({ length: Math.max(...arr) }, (_, i) => i + 1);
    let missingNumber = fullArr.find((num) => !arr.includes(num));
    return missingNumber;
  }

  const numbersArray = array.map((element) =>
    Number(element.match(/\d+/)?.[0])
  );
  let missingNums = findMissingNumbers(numbersArray.filter(Number));
 // const newElement = missingNums?.[0];

  return missingNums;
};
const removeLoad = (CBLoadList, selectedLoad) => {
  const removeLoadData = [...CBLoadList];

  const newLoadData = removeLoadData.filter(
    (load, index) => index !== selectedLoad 
  );

  // let modbusSlaveID = 20;
  // let count = 0;

  // newLoadData.forEach((loadData, key) => {
  //   count++;
  //   switch (true) {
  //     case count > 0 && count <= 10:
  //       modbusSlaveID = modbusSlaveID + 1;
  //       break;

  //     case count === 11:
  //       modbusSlaveID = 255;
  //       break;

  //     case count > 11 && count <= 16:
  //       modbusSlaveID = modbusSlaveID === 255 ? 1 : modbusSlaveID;
  //       modbusSlaveID = modbusSlaveID + 1;
  //       break;

  //     case count > 16 && count <= 22:
  //       modbusSlaveID = modbusSlaveID === 6 ? 20 : modbusSlaveID;
  //       modbusSlaveID = modbusSlaveID + 1;
  //       break;
  //     default:
  //       break;
  //   }
  //   newLoadData[key].modbusSlaveId = modbusSlaveID;
  // });
  return newLoadData;
};

const removeRemoteLoad = (CBLoadList, indexes) => {
  let removeLoadData = [...CBLoadList];
  removeLoadData.forEach((loadData)=>{
    loadData.uiData.selectedLoad = false;
    loadData.uiData.selectedLoadCB = false;
  })
  removeLoadData = removeLoadData.filter((load,key)=> !indexes.includes(key));
  return removeLoadData;
};

const selectLoadCbAsset = (
  CBLoadList,
  index,
  selectedAsset,
  cbCommunicationValues,
  defaultValues,
  count
) => {
  const projectTypeValue = getProjectTypeValue();
  const newCBLoadList = _.cloneDeep(CBLoadList);
  const newCBLoadListPrevious = CBLoadList[index].cbConfigData;
  newCBLoadList[index].cbConfigData.cbAssetDetails[0] = selectedAsset;
  newCBLoadList[index].uiData.CBConfigured = false;
  if(!["MGaaS IEC", "DaaS IEC", "MGaaS AccessToEnergy"].includes(projectTypeValue) ){
  let attributesValue = configureEM(newCBLoadList, index);
  newCBLoadList[index].uiData.EMConfigured = attributesValue?.embeddedMetering === "enabled";
  newCBLoadList[index].uiData.emSelected = attributesValue?.embeddedMetering === "enabled";
  }
  // newCBLoadList[index].uiData.configured = false;
let ipAddressName;
  if((count.derCount+count.loadCount+count.evCount)<=7){
   ipAddressName = `ipAddress${1}`;
  }else if((count.derCount+count.loadCount+count.evCount)>=8&&(count.derCount+count.loadCount+count.evCount)<=14){
    ipAddressName = `ipAddress${2}`;
  }else{
    ipAddressName = `ipAddress${3}`;
  }
  const modbusId = `modbusId_CB_5${index}`;
  const ipaddress =
    selectedAsset?.assetInfo === "Serial" ||
    selectedAsset?.assetInfo === "Hardwired"
      ? ""
      : defaultValues["Circuit Breaker"][ipAddressName];
  const subnetmask =
    selectedAsset?.assetInfo === "Serial" ||
    selectedAsset?.assetInfo === "Hardwired"
      ? ""
      : defaultValues["Circuit Breaker"]?.subnetMask;
  const defaultGateway =
    selectedAsset?.assetInfo === "Serial" ||
    selectedAsset?.assetInfo === "Hardwired"
      ? ""
      : defaultValues["Circuit Breaker"]?.defaultGateway;
  const modbusSlaveId =
    selectedAsset.assetInfo === "Hardwired"
      ? ""
      : selectedAsset.assetInfo === "Serial"
      ? defaultValues["Circuit Breaker"][ipAddressName].split(".")[3]
      : defaultValues["Circuit Breaker"][modbusId];

  // const cbCommunicationData = getCBifeValues(
  //   newCBLoadListPrevious,
  //   selectedAsset,
  //   cbCommunicationValues
  // );

  const cbCommunicationData = {
    ipaddress,
    subnetmask,
    defaultGateway,
    modbusSlaveId,
  };
  newCBLoadList[index].cbConfigData.cbCommunicationData = cbCommunicationData;

  return newCBLoadList;
};

const getLoadCbIp = (cbCommunicationValues) => {
  const ifeIp1 = cbCommunicationValues.allIps[0];
  const ifeIp2 = cbCommunicationValues.allIps[1];
  const ifeIp3 = cbCommunicationValues.allIps[2];
  const ifeIp4 = cbCommunicationValues.allIps[3];

  // const ifeIp1 = "192.168.50.21";
  // const ifeIp2 = "192.168.50.22";
  // const ifeIp3 = "192.168.50.23";
  // const ifeIp4 = "192.168.50.24";

  const ifeSet1 = cbCommunicationValues.ifeIpSets[ifeIp1]?.[0] ?? 0;
  const ifeSet2 = cbCommunicationValues.ifeIpSets[ifeIp2]?.[0] ?? 0;
  const ifeSet3 = cbCommunicationValues.ifeIpSets[ifeIp3]?.[0] ?? 0;
  const ifeSet4 = cbCommunicationValues.ifeIpSets[ifeIp4]?.[0] ?? 0;

  const ifeSet1Max = cbCommunicationValues.ifeIpSets[ifeIp1]?.[1] ?? 0;
  const ifeSet2Max = cbCommunicationValues.ifeIpSets[ifeIp2]?.[1] ?? 0;
  const ifeSet3Max = cbCommunicationValues.ifeIpSets[ifeIp3]?.[1] ?? 0;
  const ifeSet4Max = cbCommunicationValues.ifeIpSets[ifeIp4]?.[1] ?? 0;

  const ipaddress =
    ifeSet1 !== ifeSet1Max
      ? ifeIp1
      : ifeSet2 !== ifeSet2Max
      ? ifeIp2
      : ifeSet3 !== ifeSet3Max
      ? ifeIp3
      : ifeSet4 !== ifeSet4Max
      ? ifeIp4
      : cbCommunicationValues.availableIps[0];

  return ipaddress;
};

const getCBifeValues = (
  newCBLoadListPrevious,
  selectedAsset,
  cbCommunicationValues
) => {
  const numberOfDevicesIFEPrevious = parseInt(
    newCBLoadListPrevious.cbAssetDetails[0]?.assetAttributes?.find(
      (assetAttribute) => assetAttribute.type === "numberOfDevicesIFE"
    )?.value ?? 7
  );
  const numberOfDevicesIFE = parseInt(
    selectedAsset?.assetAttributes?.find(
      (assetAttribute) => assetAttribute.type === "numberOfDevicesIFE"
    )?.value ?? 7
  );

  if (numberOfDevicesIFEPrevious === numberOfDevicesIFE) {
    return { ...newCBLoadListPrevious.cbCommunicationData };
  }

  if (numberOfDevicesIFE === 1) {
    const ipaddress = cbCommunicationValues.availableIps[0];
    return {
      ...newCBLoadListPrevious.cbCommunicationData,
      ipaddress: ipaddress,
      modbusSlaveId: "255",
    };
  }

  if (numberOfDevicesIFE === 7) {
    return {
      ...newCBLoadListPrevious.cbCommunicationData,
      ipaddress: getLoadCbIp(cbCommunicationValues),
      modbusSlaveId: cbCommunicationValues.ifeIpSets[
        getLoadCbIp(cbCommunicationValues)
      ]
        ? newCBLoadListPrevious.cbCommunicationData.modbusSlaveId
        : "255",
    };
  }
};

export { initialCBLoadList, CBLoadListReducer };
