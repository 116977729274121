
import React, { useContext } from "react";
import useCBMouserOver from "../../pages/CustomHooks/useCBMouserOver";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";
import embLabels from "../../config/emb-labels-en.json"
import MyErrorBoundary from "../../utils/ErrorBoundary";
const RemoteLoad = ({ load, loadName, loadCbName, index, remoteLoad, loadIndex }) => {
  const {
    assetDetailsReset,
    UserScope,
    callSnackbar,
    CBLoadListDispatch,
    selectedValuesHandler,
    loadConfigDataHandler,
    loadCbConfigDataHandler,
    switchboardType, 
    siteType,
    loadSide,
    coordinates: {busbarCordinates},
  } = useContext(DesignAssetContext);

  const [mouseOverData, CBMouseHandler] = useCBMouserOver();

  const loadSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "loadDetailsBlock";
      const selectedAssetValue = "selectedLoad";
      CBLoadListDispatch({
        type: ACTIONS.LOAD_SELECT,
        payload: {
          index,
        },
      });
      selectedValuesHandler(index, selectedAssetValue, assetBlock);
      loadConfigDataHandler(index);
    } else {
      callSnackbar(
        "error",
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const loadCBSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "cbLoadDetailsBlock";
      const selectedAssetValue = "selectedLoadCB";
      CBLoadListDispatch({
        type: ACTIONS.LOAD_CB_SELECT,
        payload: {
          index,
        },
      });
      selectedValuesHandler(index, selectedAssetValue, assetBlock);
      loadCbConfigDataHandler(index);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };
  return (
    <MyErrorBoundary>
      <>
        {["ISLANDABLE_GSX", "ISLANDABLE"].includes(siteType) &&
        (switchboardType === "" ||
          switchboardType === embLabels.EXISTING_SWITCHBOARD) ? (
          <svg className="pointer13">
            <g id="CB1-load">
              <svg
                width="70px"
                height="108px"
                viewBox="0 0 10 149"
                x={45 + (loadIndex - 1) * 100}
                y={loadSide === "LOAD_SIDE" ? 251.5 : 285}
                onClick={loadCBSelectHandler}
              >
                <title>{loadCbName}</title>
                <g
                  id="Circuit Breaker"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Circuit Breaker 1"
                    transform="translate(-651.000000, -512.000000)"
                  >
                    <g
                      id="Circuit Breaker - closed"
                      transform="translate(604.000000, 514.000000)"
                      onMouseOver={CBMouseHandler}
                      onMouseOut={CBMouseHandler}
                    >
                      <g
                        id="Group-15"
                        strokeWidth="4"
                        fill={
                          load.CBSelected
                            ? "rgba(65, 181, 230, 0.35)"
                            : mouseOverData
                            ? "rgba(65, 181, 230, 0.35)"
                            : "none"
                        }
                        stroke={load.CBSelected ? "#42B4E6" : "none"}
                      >
                        <rect
                          id="Rectangle-Copy-20"
                          x="4"
                          y="8"
                          width="96"
                          height="124"
                        ></rect>
                        <g
                          id="Group-2"
                          transform="translate(48.000000, 0.000000)"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          stroke={load.CBConfigured ? "#333333" : "#E47F00"}
                          strokeWidth="4"
                        >
                          <line
                            x1="0.5"
                            y1="0"
                            x2="0.5"
                            y2="47.8298611"
                            id="Line-2"
                          ></line>
                        </g>
                      </g>
                      <line
                        x1="48.5"
                        y1="97.1701389"
                        x2="48.501111"
                        y2="145"
                        id="Line-2-Copy-15"
                        stroke={load.CBConfigured ? "#333333" : "#E47F00"}
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></line>
                      <path
                        d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                        id="Path-11"
                        stroke={load.CBConfigured ? "#333333" : "#E47F00"}
                        strokeWidth="4"
                        strokeLinecap="round"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
             
            </g>
            <g
                id={`embedded meter`}
                style={load.emSelected ? {} : { display: "none" }}
              >
                <svg
                  x={( -85 + (loadIndex -1)*100)}
                  y={loadSide === "LOAD_SIDE" ? 195 : 227.5}
                  width="300px"
                  height="140px"
                  viewBox="0 0 100 160"
                  //onClick={gensetPMSelectHandler}
                >
                  {/* <title>{powerMeterData.name}</title> */}
                  <title>{"Embedded Metering"}</title>
                  <line
                    x1="69"
                    y1="127"
                    x2="80"
                    y2="127"
                    stroke={load.EMConfigured ? "#333" : "#E47F00"}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <circle
                    id="em outer"
                    stroke="none"
                    strokeWidth="3"
                    cx="100"
                    cy="127"
                    r="26"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    style={load.emSelected ? {} : { display: "none" }}
                  ></circle>
                  <circle
                    id="em inner"
                    stroke={load.EMConfigured ? "#333" : "#E47F00"}
                    strokeWidth="3"
                    cx="100"
                    cy="127"
                    r="18.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="#FFFFFF"
                  ></circle>
                  <text
                    id="EM"
                    fontSize="16"
                    fontWeight="normal"
                    stroke="#333"
                    fontFamily="Nunito-Regular, Nunito"
                    fill="#333"
                  >
                    <tspan x="88" y="132">
                      EM
                    </tspan>
                  </text>
                </svg>
              </g>
            <g id="load1">
              <svg
                x={(loadIndex - 1) * 100}
                y={loadSide === "LOAD_SIDE" ? 290 : 300}
                width="149px"
                height="475px"
                viewBox="0 0 90 550"
                onClick={loadSelectHandler}
              >
                <title>{loadName}</title>
                <g
                  id="Design"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Design/Assets-_-00"
                    transform="translate(-604.000000, -372.000000)"
                  >
                    <g
                      id="SLD/Production/Uniline/Undefined"
                      transform="translate(604.000000, 372.000000)"
                    >
                      <g id="SLD/Production/Uniline/Tobedefine">
                        <g id="Group-7">
                          <rect
                            id="Rectangle"
                            x="0"
                            y="95"
                            width="80"
                            height="148"
                          ></rect>

                          <line
                            x1="48.5"
                            y1={"80"}
                            x2="48.5"
                            y2={busbarCordinates[`svgline${index}`] ?? "144"}
                            id="Line-2"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            stroke={load.configured ? "#333333" : "#E47F00"}
                          ></line>

                          <circle
                            id="Oval"
                            stroke="#42B4E6"
                            strokeWidth="3"
                            cx="48"
                            cy={busbarCordinates[`loadLine${index}`] ?? "193.5"}
                            r="55"
                            style={load.selected ? {} : { display: "none" }}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill="rgba(0, 135, 205, 0.2)"
                          ></circle>

                          <circle
                            id="Oval"
                            strokeWidth="3"
                            fill="#FFFFFF"
                            cx="48"
                            cy={busbarCordinates[`loadLine${index}`] ?? "193.5"}
                            r="46.5"
                            stroke={load.configured ? "#333333" : "#E47F00"}
                          ></circle>

                          <text
                            id="segment_energy_infra"
                            fontFamily="se-icon"
                            fontSize="56"
                            fontWeight="normal"
                            fill="#333333"
                          >
                            <tspan
                              x="20"
                              y={
                                busbarCordinates[`loadLine${index}`]
                                  ? busbarCordinates[`loadLine${index}`] + 20
                                  : "210"
                              }
                            >
                              segment_building_small
                            </tspan>
                          </text>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </g>
          </svg>
        ) : (
          <svg className="pointer">
            <g id="CB1-load">
              <svg
                width="70px"
                height="103px"
                viewBox="0 0 10 149"
                x={
                  index < 5
                    ? Number(load.xAxisCB) - 240
                    : Number(load.xAxisCB) - 352.5
                }
                y={Number(load.yAxisCB) - 130}
                onClick={loadCBSelectHandler}
              >
                <title>{loadCbName}</title>
                <g
                  id="Circuit Breaker"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Circuit Breaker 1"
                    transform="translate(-651.000000, -512.000000)"
                  >
                    <g
                      id="Circuit Breaker - closed"
                      transform="translate(604.000000, 514.000000)"
                      onMouseOver={CBMouseHandler}
                      onMouseOut={CBMouseHandler}
                    >
                      <g
                        id="Group-15"
                        strokeWidth="4"
                        fill={
                          load.CBSelected
                            ? "rgba(65, 181, 230, 0.35)"
                            : mouseOverData
                            ? "rgba(65, 181, 230, 0.35)"
                            : "none"
                        }
                        stroke={load.CBSelected ? "#42B4E6" : "none"}
                      >
                        <rect
                          id="Rectangle-Copy-20"
                          x="4"
                          y="8"
                          width="96"
                          height="124"
                        ></rect>
                        <g
                          id="Group-2"
                          transform="translate(48.000000, 0.000000)"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          stroke={load.CBConfigured ? "#333333" : "#E47F00"}
                          strokeWidth="4"
                        >
                          <line
                            x1="0.5"
                            y1="0"
                            x2="0.5"
                            y2="47.8298611"
                            id="Line-2"
                          ></line>
                        </g>
                      </g>
                      <line
                        x1="48.5"
                        y1="97.1701389"
                        x2="48.501111"
                        y2="145"
                        id="Line-2-Copy-15"
                        stroke={load.CBConfigured ? "#333333" : "#E47F00"}
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></line>
                      <path
                        d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                        id="Path-11"
                        stroke={load.CBConfigured ? "#333333" : "#E47F00"}
                        strokeWidth="4"
                        strokeLinecap="round"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </g>
            <g id="load1">
              <svg
                x={
                  index < 5
                    ? Number(load.xAxis) - 240
                    : Number(load.xAxis) - 252
                }
                y={Number(load.yAxis) - 132}
                width="149px"
                height="475px"
                viewBox="0 0 90 550"
                onClick={loadSelectHandler}
              >
                <title>{loadName}</title>
                <g
                  id="Design"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Design/Assets-_-00"
                    transform="translate(-604.000000, -372.000000)"
                  >
                    <g
                      id="SLD/Production/Uniline/Undefined"
                      transform="translate(604.000000, 372.000000)"
                    >
                      <g id="SLD/Production/Uniline/Tobedefine">
                        <g id="Group-7">
                          <rect
                            id="Rectangle"
                            x="0"
                            y="95"
                            width="96"
                            height="148"
                          ></rect>

                          <line
                            x1="48.5"
                            y1={"96"}
                            x2="48.5"
                            y2="144"
                            id="Line-2"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            stroke={load.configured ? "#333333" : "#E47F00"}
                          ></line>

                          <circle
                            id="Oval"
                            stroke="#42B4E6"
                            strokeWidth="3"
                            cx="48"
                            cy="193.5"
                            r="55"
                            style={load.selected ? {} : { display: "none" }}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill="rgba(0, 135, 205, 0.2)"
                          ></circle>

                          <circle
                            id="Oval"
                            strokeWidth="3"
                            fill="#FFFFFF"
                            cx="48"
                            cy="193.5"
                            r="46.5"
                            stroke={load.configured ? "#333333" : "#E47F00"}
                          ></circle>

                          <text
                            id="segment_energy_infra"
                            fontFamily="se-icon"
                            fontSize="56"
                            fontWeight="normal"
                            fill="#333333"
                          >
                            <tspan x="20" y="210">
                              segment_building_small
                            </tspan>
                          </text>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </g>
          </svg>
        )}
      </>
    </MyErrorBoundary>
  );
};

export default RemoteLoad;