import React, { useContext } from "react";

import isTouchDevice from "../SE-Component/Tooltip";
import embLabels from "../../config/emb-labels-en.json";
import { InformationPageContext } from "../CustomHooks/ContextApi";
import Tooltip from "../../utils/Tooltip";
import { getProjectTypeValue } from "../../utils/project";

const RemoteMonitoring = () => {
  const {
    siteType,
    informationToolTIP, 
    disabledFeatures,
    gridSettings,
    handleRemoteMonitoringChange,
    useCaseHMIData,
  } = useContext(InformationPageContext);

  const projectTypeValue = getProjectTypeValue();
  return (
    <>
      <div className="row mt-1">
        <div className="col-md-12 mt-1">
          {/* <span className="labelCircle mr-2 ">{siteType === "GRID_CONNECTED_GSX" ? 5 : ["GRID_CONNECTED","ISLANDABLE"].includes(siteType)?3:4}</span>{" "} */}
          <span className="labelCircle mr-2 ">
            {["GRID_CONNECTED_GSX","GRID_CONNECTED","ISLANDABLE"].includes(siteType) ?projectTypeValue === "MGaaS IEC"?3: 4 : 3}
          </span>{" "}
          <span>Select Use Cases</span>
          <Tooltip
            position="right"
            iconColor="secondary"
            iconSize="small"
            // information="Select grid-tied functions and/or offgrid functions depending on
            // your site selection."
            information ={informationToolTIP?.useCase}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div
          className={
            siteType === embLabels.ISLANDABLE ||
            siteType === embLabels.PV_HYBRIDIZATION ||
            siteType === embLabels.GENSET_PVBESS_A2E ||
            siteType === embLabels.ISLANDABLE_GSX ||
            siteType === embLabels.GRID_CONNECTED_GSX ||
            (siteType === embLabels.GRID_CONNECTED &&
              disabledFeatures.disableCloud === false)
              ? "col-md-12"
              : "col-md-12  se-block-disabled"
          }
        >
          {useCaseHMIData && Object.keys(useCaseHMIData)?.length&&
          useCaseHMIData?.useCases?.["Remote energy monitoring and forecast"]?.includes("Remote energy monitoring and forecast")
          &&<div className="col-md-12 border-radius-5px mt-2">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-2 ">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input asCompatible"
                    name="remoteMonitoringControl"
                    id="customControlValidation6"
                    value={gridSettings.remoteMonitoringControl}
                    checked={gridSettings.remoteMonitoringControl}
                    onChange={projectTypeValue ==="MGaaS IEC"?undefined:handleRemoteMonitoringChange}
                  />
                  <label
                    className="custom-control-label line-height-25"
                    htmlFor="customControlValidation6"
                  >
                    Remote energy monitoring and forecast
                  </label>
                  <Tooltip
                    position="right"
                    iconColor="secondary"
                    iconSize="small"
                    // information= {
                    //   siteType === embLabels.GRID_CONNECTED_GSX
                    //   ? informationToolTIP.otherOptimizationTooltipData
                    //   : informationToolTIP.remoteMonitoringTooltipData}
                    information ={
                      ["DaaS ANSI", "DaaS IEC"].includes(projectTypeValue)?
                      informationToolTIP.remoteMonitoringDaasTooltipData
                      :informationToolTIP.remoteMonitoringMGassTooltipData
                    }
                  />
                 
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </>
  );
};

export default RemoteMonitoring;