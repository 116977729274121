import ACTIONS from "../Design_Asset_Actions.json";
import _ from "lodash";

import embLabels from "../../../config/emb-labels-en.json";
import { generateRandom } from "../../../utils/randomGenerator";

const alphanumericRegWithHyphen = /^[-_0-9a-zA-Z]+$/;

const initialPVDetails = {
  defaultPVIpPreFill: [],
  defaultPVIpPreFillBackUp: [],
  defaultPvPreFillData: {},
  pvset1Data: [],
  pvset2Data: [],
  pvset3Data: [],
  usedPvIp: [],
  availablePvIp: [],
  usedPvSlaveId: [],
  availablePvSlaveId: [],
  defaultAvailablePvSlaveId: [],
  usedPvName: [],
  availablePvName: [
    "Photovoltaic1",
    "Photovoltaic2",
    "Photovoltaic3",
    "Photovoltaic4",
    "Photovoltaic5",
    "Photovoltaic6",
    "Photovoltaic7",
    "Photovoltaic8",
    "Photovoltaic9",
    "Photovoltaic10",
    "Photovoltaic11",
    "Photovoltaic12",
    "Photovoltaic13",
    "Photovoltaic14",
    "Photovoltaic15",
    "Photovoltaic16",
    "Photovoltaic17",
    "Photovoltaic18",
    "Photovoltaic19",
    "Photovoltaic20",
    "Photovoltaic21",
    "Photovoltaic22",
    "Photovoltaic23",
    "Photovoltaic24",
    "Photovoltaic25",
  ],
  defaultAvailablePvName: [
    "Photovoltaic1",
    "Photovoltaic2",
    "Photovoltaic3",
    "Photovoltaic4",
    "Photovoltaic5",
    "Photovoltaic6",
    "Photovoltaic7",
    "Photovoltaic8",
    "Photovoltaic9",
    "Photovoltaic10",
    "Photovoltaic11",
    "Photovoltaic12",
    "Photovoltaic13",
    "Photovoltaic14",
    "Photovoltaic15",
    "Photovoltaic16",
    "Photovoltaic17",
    "Photovoltaic18",
    "Photovoltaic19",
    "Photovoltaic20",
    "Photovoltaic21",
    "Photovoltaic22",
    "Photovoltaic23",
    "Photovoltaic24",
    "Photovoltaic25",
  ],
  selectedPv: "",
  selectSubPv: "",
  displayPvContent: false,
  pvCountInCB: 0,
  pvConfiguredCountInCB: 0,
  pvConfig: {
    name: "",
    groupName: "",
    group_max_power: 0,
    description: "",
    max_power: "",
    ipaddress: "",
    subnetmask: "",
    modbusSlaveId: "",
    defaultGateway: "",
    status: false,
    assetDetails: [],
    pvGsxExternalId: "",
  },
  pvCount: 0,
  showHideAddPvToBranch: false,
  addPvShowDialog: false,
  existOrNew: "new",
  branchName: 3,

};

const PVDetailsReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.ASSIGN_DEFAULT_VALUES:
      return assignDefaultValuesPVDetails(state, action.payload.defaultValues, action.payload.gsxExternalId);
    case ACTIONS.ASSIGN_USECASE_PVSET1:
      return assignUsecasePvset1(state, action.payload.count, action.payload.externalIds);
    case ACTIONS.UPDATE_PV_COMMUNICATION:
      return updatePvCommunication(state, action.payload.componentsData);
    case ACTIONS.UPDATE_PV_COMMUNICATION_COMPONENT:
      return updatePvCommunicationComponent(state, action.payload.components, action.payload.externalId);
    case ACTIONS.UPDATE_EMPTY_PVCOMMUNICATION:
      return updateEmptyPvCommunication(state, action.payload.componentsData);
    case ACTIONS.UPDATE_PVSET:
      return updatePvset(
        state,
        action.payload.componentsData,
        action.payload.pvArray
      );
    case ACTIONS.PV_SELECT:
      return pvSelect(
        state,
        action.payload.selectedPv,
        action.payload.selectSubPv,
        action.payload.externalId
      );
    case ACTIONS.PV_CONFIGURED_STATUS:
      return calculatePvStatusConfigured(state);
    case ACTIONS.PV_CONFIG_DATA:
      return updatePvConfigData(state);
    case ACTIONS.DUPLICATE_PV_UPDATE:
      return duplicatePvUpdate(state);
    case ACTIONS.CONFIGURE_PV_ARRAY:
      return updateConfigurePvArray(state, action.payload.data);
    case ACTIONS.UPDATE_PV_ARRAY:
      return updatePvArray(state);
    case ACTIONS.INDIVIDUAL_PV_SELECT:
      return individualPvSelect(
        state,
        action.payload.selectSubPv,
        action.payload.selectedPv,
        // action.payload.externalId
      );
    case ACTIONS.CLOSE_PVDISPLAY_CONTENT:
      return closePvDispalyContent(
        state,
        action.payload.selectSubPv,
        action.payload.selectedPv,
      );
    case ACTIONS.UPDATE_PV_CONFIG:
      return updatePvConfiguration(
        state,
        action.payload.selectedPv,
        action.payload.selectedAsset,
        action.payload.defaultsMinMax
      );
    case ACTIONS.HANDLE_PV_CHANGE:
      return handlePvChange(state, action.payload.data);
    case ACTIONS.UPDATE_PV_CONFIG_SAVE:
      return updatePvcOnfigSave(state);
    case ACTIONS.REMOVE_PV_FROM_SET:
      return removePvFromSet(
        state,
        action.payload.selectedPv,
        action.payload.subPv
      );
    case ACTIONS.REMOVE_ALL_PV_FROM_SET:
      return removeAllPvFromSet(state, action.payload.removedPvSet);
    case ACTIONS.ADD_PV_EQUIPMENT:
      return addPvEquipment(state, action.payload.CBAssetList);
    case ACTIONS.CLOSE_PV_MODAL:
      return closePvModal(state);
    case ACTIONS.EXIST_NEW_BRANCH_SELECT:
      return existOrNewBranchSelect(state, action.payload.data);
    case ACTIONS.EXISTING_BRANCH_CHANGE:
      return existingBranchChange(state, action.payload.data);
    case ACTIONS.ADD_PV_BY_ONE:
      return addPvByOne(
        state,
        action.payload.CBAssetList,
        action.payload.siteType,
        action.payload.architectureType,
        action.payload.projectType,
      );
    case ACTIONS.REDUCE_PV_BY_ONE:
      return reducePvByOne(state);
    case ACTIONS.CONFIRM_ADD_PV_WITH_CB:
      return addPVSetWithCB(state, action.payload.selectedPv, action.payload.externalId);
    case ACTIONS.RESET_PV_DETAILS:
      return resetPvDetails(state);
    case ACTIONS.COPY_ALL_PV:
      return copyAllPv(state);

    default:
      return state;
  }
};

const assignDefaultValuesPVDetails = (PVDetails, defaultValues, gsxExternalId) => {
  const defaultPvPreFillData = { ...defaultValues.PV_inverter };

  const ipAddressPv = [...PVDetails.defaultPVIpPreFill];
  const ipAddressPvBackup = [...PVDetails.defaultPVIpPreFillBackUp];
  const slaveIdPv = [...PVDetails.availablePvSlaveId];
  const PVNoList = 25;
  for (let i = 0; i < PVNoList; i++) {
    let seTest = `ipAddress_PV_${i + 1}`;
    ipAddressPv.push(defaultValues.PV_inverter[seTest]);
    ipAddressPvBackup.push(defaultValues.PV_inverter[seTest]);
    slaveIdPv.push(defaultValues.PV_inverter[seTest].split(".")[3]);
  }
  // return new state
  return {
    ...PVDetails,
    defaultPVIpPreFill: ipAddressPv,
    defaultPVIpPreFillBackUp: ipAddressPvBackup,
    defaultPvPreFillData: defaultPvPreFillData,
    availablePvSlaveId: slaveIdPv,
    defaultAvailablePvSlaveId: slaveIdPv,

  };
};

const assignUsecasePvset1 = (PVDetails, count, externalIds) => {
  const newPvSetData = [];
  Array(count)
    .fill(null)
    .forEach((value, index) => {
      newPvSetData.push({
        name: `${PVDetails.defaultPvPreFillData.name}${index + 1}`,
        groupName: `${PVDetails.defaultPvPreFillData.groupName} 1`,
        group_max_power: 0,
        description: "",
        max_power: "",
        ipaddress: PVDetails.defaultPVIpPreFill.shift(),
        subnetmask: PVDetails.defaultPvPreFillData.subnetMask,
        modbusSlaveId: PVDetails.defaultPvPreFillData.modbusId,
        defaultGateway: PVDetails.defaultPvPreFillData.defaultGateway,
        status: false,
        assetDetails: [],
        pvGsxExternalId: externalIds[Math.floor(generateRandom() * (externalIds.length - 10) + 10)],
      });
    });
  return { ...PVDetails, pvset1Data: newPvSetData };
};

const updatePvCommunication = (PVDetails, componentsData) => {
  const usedPvIp = [...PVDetails.usedPvIp, componentsData.communications[0].ip];
  const availablePvIp = PVDetails.defaultPVIpPreFillBackUp.filter(
    ip => !usedPvIp.includes(ip)
  );
  const usedPvSlaveId = [
    ...PVDetails.usedPvSlaveId,
    componentsData.communications[0].modbusSlaveId,
  ];
  const availablePvSlaveId = PVDetails.defaultAvailablePvSlaveId.filter(
    id => !usedPvSlaveId.includes(id)
  );
  const usedPvName = [...PVDetails.usedPvName, componentsData.componentName];
  const availablePvName = PVDetails.defaultAvailablePvName.filter(
    name => !usedPvName.includes(name)
  );


  return {
    ...PVDetails,
    usedPvIp,
    availablePvIp,
    usedPvSlaveId,
    availablePvSlaveId,
    usedPvName,
    availablePvName,
  };
};

const updatePvCommunicationComponent = (PVDetails, components,) => {
  const usedPvIp = components
    .filter(component => component.componentType === "Inverter")
    .map(component => component.communications[0].ip);
  let availableIp = PVDetails.defaultPVIpPreFillBackUp.filter(
    ip => !usedPvIp.includes(ip)
  );
  const usedPvSlaveId = components
    .filter(component => component.componentType === "Inverter")
    .map(component => component.communications[0].modbusSlaveId);
  const availablePvSlave = PVDetails.defaultAvailablePvSlaveId.filter(
    id => !usedPvSlaveId.includes(id)
  );
  const usedPvName = components
    .filter(component => component.componentType === "Inverter")
    .map(component => component.componentName);
  const availablePvName = PVDetails.defaultAvailablePvName.filter(
    name => !usedPvName.includes(name)
  );

  return {
    ...PVDetails,
    usedPvIp: usedPvIp,
    availablePvIp: availableIp,
    usedPvSlaveId: usedPvSlaveId,
    availablePvSlaveId: availablePvSlave,
    usedPvName: usedPvName,
    availablePvName: availablePvName,

  };
};

const updateEmptyPvCommunication = (PVDetails, components) => {
  const inverterComponent = components.filter(
    component => component.componentType === embLabels.INVERTER
  );
  if (inverterComponent.length) {
    return PVDetails;
  } else {
    const availableIp = [...PVDetails.defaultPVIpPreFillBackUp];
    const availablePvName = [...PVDetails.defaultAvailablePvName];
    const availablePvSlaveId = [...PVDetails.defaultAvailablePvSlaveId];
    return {
      ...PVDetails,
      availablePvIp: availableIp,
      availablePvName: availablePvName,
      availablePvSlaveId: availablePvSlaveId,
    };
  }
};

const updatePvset = (PVDetails, componentsData, pvArray) => {
  const attributes = JSON.parse(componentsData.attributes);
  const assetDetailsSample = [];
  if (componentsData.asset) {
    assetDetailsSample.push(componentsData.asset);
  }
  const pvConfigNew = {
    name: componentsData.componentName,
    description: attributes.description,
    groupName: JSON.parse(componentsData.uiAttributes).groupName,
    group_max_power: attributes.group_max_power,
    max_power: attributes.max_power,
    ipaddress: componentsData.communications[0]
      ? componentsData.communications[0].ip
      : "",
    subnetmask: componentsData.communications[0]
      ? componentsData.communications[0].subnetmask
      : "",
    modbusSlaveId: componentsData.communications[0]
      ? componentsData.communications[0].modbusSlaveId
      : "",
    defaultGateway: componentsData.communications[0]
      ? componentsData.communications[0].defaultGateway
      : "",
    status: JSON.parse(componentsData.uiAttributes).configured,
    assetDetails: assetDetailsSample,
    pvGsxExternalId: componentsData.externalId ? componentsData.externalId : "",
  };
  let PVDetailsNew;
  if (pvArray === 3) {
    const pvset1Data = [...PVDetails.pvset1Data, pvConfigNew];
    PVDetailsNew = {
      ...PVDetails,
      pvset1Data,
    };
  }
  if (pvArray === 4) {
    const pvset2Data = [...PVDetails.pvset2Data, pvConfigNew];
    PVDetailsNew = {
      ...PVDetails,
      pvset2Data,
    };
  }
  if (pvArray === 5) {
    const pvset3Data = [...PVDetails.pvset3Data, pvConfigNew];
    PVDetailsNew = {
      ...PVDetails,
      pvset3Data,
    };
  }

  // return updated state
  return PVDetailsNew;
};

const pvSelect = (PVDetails, selectedPv, selectSubPv, externalId) => {
  let newPvData = [];
  if (selectedPv === 3) {
    newPvData = [...PVDetails.pvset1Data];
  }
  if (selectedPv === 4) {
    newPvData = [...PVDetails.pvset2Data];
  }
  if (selectedPv === 5) {
    newPvData = [...PVDetails.pvset3Data];
  }
  if (newPvData[selectSubPv - 1] === undefined) {
    newPvData[selectSubPv - 1] = {
      name: "",
      description: "",
      groupName: "",
      group_max_power: 0,
      max_power: "",
      ipaddress: "",
      subnetmask: "",
      modbusSlaveId: "",
      defaultGateway: "",
      status: false,
      assetDetails: [],
      pvGsxExternalId: externalId,
    };
  }

  return {
    ...PVDetails,
    selectedPv,
    selectSubPv,
  };
};

const calculatePvStatusConfigured = PVDetails => {
  let pvCountInCBNew = 0;
  let pvConfiguredCountInCBNew = 0;
  let newPvDataSet;
  if (PVDetails.selectedPv === 3) {
    newPvDataSet = [...PVDetails.pvset1Data];
  } else if (PVDetails.selectedPv === 4) {
    newPvDataSet = [...PVDetails.pvset2Data];
  } else if (PVDetails.selectedPv === 5) {
    newPvDataSet = [...PVDetails.pvset3Data];
  }

  newPvDataSet.forEach(pvData => {
    if (pvData.status) {
      pvConfiguredCountInCBNew = pvConfiguredCountInCBNew + 1;
    }
    pvCountInCBNew = pvCountInCBNew + 1;
  });

  return {
    ...PVDetails,
    pvCountInCB: pvCountInCBNew,
    pvConfiguredCountInCB: pvConfiguredCountInCBNew,
  };
};
const duplicatePvUpdate = PVDetails => {
  let newPvDataSet;
  if (PVDetails.selectedPv === 3) {
    newPvDataSet = _.cloneDeep(PVDetails.pvset1Data);
    newPvDataSet[PVDetails.selectSubPv - 1].status = false;
    return {
      ...PVDetails,
      pvset1Data: newPvDataSet,
    };
  } else if (PVDetails.selectedPv === 4) {
    newPvDataSet = _.cloneDeep(PVDetails.pvset2Data);
    newPvDataSet[PVDetails.selectSubPv - 1].status = false;
    return {
      ...PVDetails,
      pvset2Data: newPvDataSet,
    };
  } else if (PVDetails.selectedPv === 5) {
    newPvDataSet = _.cloneDeep(PVDetails.pvset3Data);
    newPvDataSet[PVDetails.selectSubPv - 1].status = false;
    return {
      ...PVDetails,
      pvset3Data: newPvDataSet,
    };
  }
};

const updatePvConfigData = PVDetails => {
  const pvHistory = JSON.parse(localStorage.getItem("pvHistory"));
  let newData;
  if (PVDetails.selectedPv === 3) {
    newData = _.cloneDeep(PVDetails.pvset1Data[PVDetails.selectSubPv - 1]);
    if (pvHistory.pv1.length) {
      if (pvHistory.pv1[0] !== undefined && pvHistory.pv1[0] !== null) {
        Object.entries(pvHistory.pv1[0]).forEach(([key, value]) => {
          newData[key] = value;
        });
      }
    }
  }
  if (PVDetails.selectedPv === 4) {
    newData = _.cloneDeep(PVDetails.pvset2Data[PVDetails.selectSubPv - 1]);
    if (pvHistory.pv2.length) {
      if (pvHistory.pv2[0] !== undefined && pvHistory.pv2[0] !== null) {
        Object.entries(pvHistory.pv2[0]).forEach(([key, value]) => {
          newData[key] = value;
        });
      }
    }
  }
  if (PVDetails.selectedPv === 5) {
    newData = _.cloneDeep(PVDetails.pvset3Data[PVDetails.selectSubPv - 1]);
    if (pvHistory.pv3.length) {
      if (pvHistory.pv3[0] !== undefined && pvHistory.pv3[0] !== null) {
        Object.entries(pvHistory.pv3[0]).forEach(([key, value]) => {
          newData[key] = value;
        });
      }
    }
  }

  return {
    ...PVDetails,
    pvConfig: newData,
  };
};

const updateConfigurePvArray = (PVDetails, data) => {
  let pvConfigData = _.cloneDeep(PVDetails.pvConfig);
  const onlyNumber = /^(\d+(\.\d+)?|)$/;
  switch (data.name) {
    case "groupName":
      pvConfigData.groupName = data.value;
      break;
    case "group_max_power":
      if (onlyNumber.test(data.value) && data.value <= 99999) {
        pvConfigData.group_max_power = data.value;
      }
      break;
    default:
      break;
  }
  return {
    ...PVDetails,
    pvConfig: pvConfigData,
  };
};

const updatePvArray = PVDetails => {
  let PVDetailsNew;
  let newPvData = [];
  const index = PVDetails.selectSubPv - 1;
  switch (PVDetails.selectedPv) {
    case 3:
      newPvData = [...PVDetails.pvset1Data];
      break;
    case 4:
      newPvData = [...PVDetails.pvset2Data];
      break;
    case 5:
      newPvData = [...PVDetails.pvset3Data];
      break;
    default:
      break;
  }
  if (newPvData[index] === undefined) {
    newPvData[index] = {
      name: "",
      groupName: "",
      group_max_power: 0,
      description: "",
      max_power: "",
      ipaddress: "",
      subnetmask: "",
      modbusSlaveId: "",
      defaultGateway: "",
      status: false,
      assetDetails: [],
    };
  }
  newPvData[index].groupName = PVDetails.pvConfig.groupName;
  newPvData[index].group_max_power = PVDetails.pvConfig.group_max_power;

  newPvData.forEach(pvData => {
    pvData.groupName = PVDetails.pvConfig.groupName;
    pvData.group_max_power = PVDetails.pvConfig.group_max_power;
  });

  switch (PVDetails.selectedPv) {
    case 3:
      PVDetailsNew = {
        ...PVDetails,
        pvset1Data: newPvData,
      };
      break;
    case 4:
      PVDetailsNew = {
        ...PVDetails,
        pvset2Data: newPvData,
      };
      break;
    case 5:
      PVDetailsNew = {
        ...PVDetails,
        pvset3Data: newPvData,
      };
      break;
    default:
      break;
  }
  return PVDetailsNew;
};

const individualPvSelect = (PVDetails, selectSubPv, selectedPv) => {
  const displayPvContent = true;

  let indexValue = parseInt(selectSubPv - 1);
  let newPvSubData = [];

  switch (selectedPv) {
    case 3:
      newPvSubData = [...PVDetails.pvset1Data];
      break;
    case 4:
      newPvSubData = [...PVDetails.pvset2Data];
      break;
    case 5:
      newPvSubData = [...PVDetails.pvset3Data];
      break;
    default:
      break;
  }

  if (newPvSubData[indexValue] === undefined) {
    newPvSubData[indexValue] = {
      name: "",
      groupName: "",
      group_max_power: 0,
      description: "",
      max_power: "",
      ipaddress: "",
      subnetmask: "",
      modbusSlaveId: "",
      defaultGateway: "",
      status: false,
      assetDetails: [],
    };
  }
  let pvNewData = { ...PVDetails.pvConfig };
  pvNewData.name = newPvSubData[indexValue].name;
  pvNewData.groupName = newPvSubData[indexValue].groupName;
  pvNewData.group_max_power = newPvSubData[indexValue].group_max_power;
  pvNewData.description = newPvSubData[indexValue].description;
  pvNewData.max_power = newPvSubData[indexValue].max_power;
  pvNewData.ipaddress = newPvSubData[indexValue].ipaddress;
  pvNewData.subnetmask = newPvSubData[indexValue].subnetmask;
  pvNewData.modbusSlaveId = newPvSubData[indexValue].modbusSlaveId;
  pvNewData.defaultGateway = newPvSubData[indexValue].defaultGateway;
  pvNewData.pvGsxExternalId = newPvSubData[indexValue].pvGsxExternalId;
  // pvNewData.pvGsxExternalId = externalId;


  // let pvHistory = JSON.parse(localStorage.getItem("pvHistory"));
  // let pvGroupPower = JSON.parse(localStorage.getItem("pvGroupPower"));
  let newData = _.cloneDeep(pvNewData);
  // if (selectedPv === 3) {
  //   if (pvHistory.pv1.length) {
  //     if (
  //       pvHistory.pv1[indexValue] !== undefined &&
  //       pvHistory.pv1[indexValue] !== null
  //     ) {
  //       Object.entries(pvHistory.pv1[indexValue]).forEach(([key, value]) => {
  //         newData[key] = value;
  //       });
  //     }
  //   }
  //   if (pvGroupPower.pv1) {
  //     newData.group_max_power = pvGroupPower.pv1;
  //   }
  // } else if (selectedPv === 4) {
  //   if (pvHistory.pv2.length) {
  //     if (
  //       pvHistory.pv2[indexValue] !== undefined &&
  //       pvHistory.pv2[indexValue] !== null
  //     ) {
  //       Object.entries(pvHistory.pv2[indexValue]).forEach(([key, value]) => {
  //         newData[key] = value;
  //       });
  //     }
  //   }
  //   if (pvGroupPower.pv2) {
  //     newData.group_max_power = pvGroupPower.pv2;
  //   }
  // } else if (selectedPv === 5) {
  //   if (pvHistory.pv3.length) {
  //     if (
  //       pvHistory.pv3[indexValue] !== undefined &&
  //       pvHistory.pv3[indexValue] !== null
  //     ) {
  //       Object.entries(pvHistory.pv3[indexValue]).forEach(([key, value]) => {
  //         newData[key] = value;
  //       });
  //     }
  //   }
  //   if (pvGroupPower.pv3) {
  //     newData.group_max_power = pvGroupPower.pv3;
  //   }
  // }

  pvNewData = newData;

  return {
    ...PVDetails,
    displayPvContent,
    selectSubPv,
    pvConfig: pvNewData,
  };
};

const closePvDispalyContent = (PVDetails,selectSubPv,selectedPv) => {
  const displayPvContent = false;
  PVDetails.pvConfig.name = PVDetails?.[`pvset${selectedPv-2}Data`]?.[selectSubPv-1]?.name
  PVDetails.pvConfig.description = PVDetails?.[`pvset${selectedPv-2}Data`]?.[selectSubPv-1]?.description
  PVDetails.pvConfig.max_power = PVDetails?.[`pvset${selectedPv-2}Data`]?.[selectSubPv-1]?.max_power
  PVDetails.pvConfig.status = PVDetails?.[`pvset${selectedPv-2}Data`]?.[selectSubPv-1]?.status
  updatePvcOnfigSave(PVDetails,true)
  return {
    ...PVDetails,
    displayPvContent,
  };
};

const updatePvConfiguration = (
  PVDetails,
  selectedPv,
  selectedAsset,
  defaultsMinMax
) => {
  if (selectedPv === 3) {
    const displayPvContent = true;
    const pvSetData1 = [...PVDetails.pvset1Data];

    const groupMaxPower = pvSetData1.find(pvData => pvData.status);

    pvSetData1[PVDetails.selectSubPv - 1].assetDetails[0] = selectedAsset;
    pvSetData1[PVDetails.selectSubPv - 1].status = false;

    if (selectedAsset.assetAttributes.length) {
      if (selectedAsset.assetAttributes[0].type === "max_power") {
        pvSetData1[PVDetails.selectSubPv - 1].max_power =
          selectedAsset.assetAttributes[0].value;
        if (groupMaxPower) {
          pvSetData1[PVDetails.selectSubPv - 1].group_max_power =
            +groupMaxPower.group_max_power +
            +selectedAsset.assetAttributes[0].value;
        } else {
          pvSetData1[PVDetails.selectSubPv - 1].group_max_power =
            selectedAsset.assetAttributes[0].value;
        }
      }
    } else {
      pvSetData1[PVDetails.selectSubPv - 1].max_power =
       +defaultsMinMax.maxPowerPvDefault;
      if (groupMaxPower) {
        pvSetData1[PVDetails.selectSubPv - 1].group_max_power =
          +groupMaxPower.group_max_power + +defaultsMinMax.maxPowerPvDefault;
      }
    }

    //* update communication details for Serial device

    const serialModbusId = PVDetails.availablePvSlaveId[0];

    if (selectedAsset.assetInfo === embLabels.SERIAL) {
      pvSetData1[PVDetails.selectSubPv - 1].ipaddress = "";
      pvSetData1[PVDetails.selectSubPv - 1].defaultGateway = "";
      pvSetData1[PVDetails.selectSubPv - 1].subnetmask = "";
      pvSetData1[PVDetails.selectSubPv - 1].modbusSlaveId = serialModbusId;
    }

    //* update communication details for communicating device

    if (selectedAsset.assetInfo === embLabels.COMMUNICATING) {
      if (
        ["", null, undefined].includes(
          pvSetData1[PVDetails.selectSubPv - 1].ipaddress
        )
      ) {
        const selectedPv = PVDetails.selectSubPv - 1;

        const communicatingModbusId =
          PVDetails.defaultPVIpPreFillBackUp[selectedPv];
        pvSetData1[PVDetails.selectSubPv - 1].ipaddress = communicatingModbusId;
        pvSetData1[PVDetails.selectSubPv - 1].defaultGateway =
          PVDetails.defaultPvPreFillData.defaultGateway;
        pvSetData1[PVDetails.selectSubPv - 1].subnetmask =
          PVDetails.defaultPvPreFillData.subnetMask;
        pvSetData1[PVDetails.selectSubPv - 1].modbusSlaveId =
          PVDetails.defaultPvPreFillData.modbusId;
      }
    }

    return {
      ...PVDetails,
      displayPvContent,
      pvset1Data: pvSetData1,
      pvConfig: pvSetData1[PVDetails.selectSubPv - 1],
    };
  }

  if (selectedPv === 4) {
    const displayPvContent = true;
    const pvSetData2 = [...PVDetails.pvset2Data];

    const groupMaxPower = pvSetData2.find(pvData => pvData.status);

    pvSetData2[PVDetails.selectSubPv - 1].assetDetails[0] = selectedAsset;
    pvSetData2[PVDetails.selectSubPv - 1].status = false;

    if (selectedAsset.assetAttributes.length) {
      if (selectedAsset.assetAttributes[0].type === "max_power") {
        pvSetData2[PVDetails.selectSubPv - 1].max_power =
          selectedAsset.assetAttributes[0].value;
        if (groupMaxPower) {
          pvSetData2[PVDetails.selectSubPv - 1].group_max_power =
            +groupMaxPower.group_max_power +
            +selectedAsset.assetAttributes[0].value;
        } else {
          pvSetData2[PVDetails.selectSubPv - 1].group_max_power =
            selectedAsset.assetAttributes[0].value;
        }
      }
    } else {
      pvSetData2[PVDetails.selectSubPv - 1].max_power =
        defaultsMinMax.maxPowerPvDefault;
      if (groupMaxPower) {
        pvSetData2[PVDetails.selectSubPv - 1].group_max_power =
          +groupMaxPower.group_max_power + +defaultsMinMax.maxPowerPvDefault;
      }
    }

    //* update communication details for Serial device

    const serialModbusId = PVDetails.availablePvSlaveId[0];

    if (selectedAsset.assetInfo === embLabels.SERIAL) {
      pvSetData2[PVDetails.selectSubPv - 1].ipaddress = "";
      pvSetData2[PVDetails.selectSubPv - 1].defaultGateway = "";
      pvSetData2[PVDetails.selectSubPv - 1].subnetmask = "";
      pvSetData2[PVDetails.selectSubPv - 1].modbusSlaveId = serialModbusId;
    }

    //* update communication details for communicating device

    if (selectedAsset.assetInfo === embLabels.COMMUNICATING) {
      if (
        ["", null, undefined].includes(
          pvSetData2[PVDetails.selectSubPv - 1].ipaddress
        )
      ) {
        const selectedPv = PVDetails.selectSubPv - 1;

        const communicatingModbusId =
          PVDetails.defaultPVIpPreFillBackUp[selectedPv];
        pvSetData2[PVDetails.selectSubPv - 1].ipaddress = communicatingModbusId;
        pvSetData2[PVDetails.selectSubPv - 1].defaultGateway =
          PVDetails.defaultPvPreFillData.defaultGateway;
        pvSetData2[PVDetails.selectSubPv - 1].subnetmask =
          PVDetails.defaultPvPreFillData.subnetMask;
        pvSetData2[PVDetails.selectSubPv - 1].modbusSlaveId =
          PVDetails.defaultPvPreFillData.modbusId;
      }
    }

    return {
      ...PVDetails,
      displayPvContent,
      pvset2Data: pvSetData2,
      pvConfig: pvSetData2[PVDetails.selectSubPv - 1],
    };
  }

  if (selectedPv === 5) {
    const displayPvContent = true;
    const pvSetData3 = [...PVDetails.pvset3Data];

    const groupMaxPower = pvSetData3.find(pvData => pvData.status);

    pvSetData3[PVDetails.selectSubPv - 1].assetDetails[0] = selectedAsset;
    pvSetData3[PVDetails.selectSubPv - 1].status = false;

    if (selectedAsset.assetAttributes.length) {
      if (selectedAsset.assetAttributes[0].type === "max_power") {
        pvSetData3[PVDetails.selectSubPv - 1].max_power =
          selectedAsset.assetAttributes[0].value;
        if (groupMaxPower) {
          pvSetData3[PVDetails.selectSubPv - 1].group_max_power =
            +groupMaxPower.group_max_power +
            +selectedAsset.assetAttributes[0].value;
        } else {
          pvSetData3[PVDetails.selectSubPv - 1].group_max_power =
            selectedAsset.assetAttributes[0].value;
        }
      }
    } else {
      pvSetData3[PVDetails.selectSubPv - 1].max_power =
        defaultsMinMax.maxPowerPvDefault;
      if (groupMaxPower) {
        pvSetData3[PVDetails.selectSubPv - 1].group_max_power = +groupMaxPower.group_max_power 
          // +groupMaxPower.group_max_power +  +defaultsMinMax.maxPowerPvDefault;
      }
    }

    //* update communication details for Serial device

    const serialModbusId = PVDetails.availablePvSlaveId[0];

    if (selectedAsset.assetInfo === embLabels.SERIAL) {
      pvSetData3[PVDetails.selectSubPv - 1].ipaddress = "";
      pvSetData3[PVDetails.selectSubPv - 1].defaultGateway = "";
      pvSetData3[PVDetails.selectSubPv - 1].subnetmask = "";
      pvSetData3[PVDetails.selectSubPv - 1].modbusSlaveId = serialModbusId;
    }

    //* update communication details for communicating device

    if (selectedAsset.assetInfo === embLabels.COMMUNICATING) {
      if (
        ["", null, undefined].includes(
          pvSetData3[PVDetails.selectSubPv - 1].ipaddress
        )
      ) {
        const selectedPv = PVDetails.selectSubPv - 1;

        const communicatingModbusId =
          PVDetails.defaultPVIpPreFillBackUp[selectedPv];
        pvSetData3[PVDetails.selectSubPv - 1].ipaddress = communicatingModbusId;
        pvSetData3[PVDetails.selectSubPv - 1].defaultGateway =
          PVDetails.defaultPvPreFillData.defaultGateway;
        pvSetData3[PVDetails.selectSubPv - 1].subnetmask =
          PVDetails.defaultPvPreFillData.subnetMask;
        pvSetData3[PVDetails.selectSubPv - 1].modbusSlaveId =
          PVDetails.defaultPvPreFillData.modbusId;
      }
    }

    return {
      ...PVDetails,
      displayPvContent,
      pvset3Data: pvSetData3,
      pvConfig: pvSetData3[PVDetails.selectSubPv - 1],
    };
  }
};

const handlePvChange = (PVDetails, data) => {
  let groupMaxPower = "";
  let selectedInverterPower = "";
  const dataConfig = { ...PVDetails.pvConfig };

  switch (PVDetails.selectedPv) {
    case 3:
      groupMaxPower = updateArrayMaxPower(PVDetails.pvset1Data);
      selectedInverterPower =
        groupMaxPower -
        PVDetails.pvset1Data[PVDetails.selectSubPv - 1].max_power;
      break;
    case 4:
      groupMaxPower = updateArrayMaxPower(PVDetails.pvset2Data);
      selectedInverterPower =
        groupMaxPower -
        PVDetails.pvset2Data[PVDetails.selectSubPv - 1].max_power;
      break;
    case 5:
      groupMaxPower = updateArrayMaxPower(PVDetails.pvset3Data);
      selectedInverterPower =
        groupMaxPower -
        PVDetails.pvset3Data[PVDetails.selectSubPv - 1].max_power;
      break;
    default:
      break;
  }
  if (data.name === "max_power") {
    if (!isNaN(data.value)) {
      dataConfig[data.name] = data.value;
      dataConfig.group_max_power = selectedInverterPower + +data.value;
      pvHistoryGroupPowerUpdate(
        dataConfig.group_max_power,
        PVDetails.selectedPv
      );
    }
  } else if (data.name === "pvGsxExternalId") {
    const { name, value } = data;
    if (value.trim() !== "") {
      if (alphanumericRegWithHyphen.test(value)) {
        dataConfig[name] = value;
      }
    } else {
      dataConfig[name] = "";
    }
  } else {
    dataConfig[data.name] = data.value;
  }

  return { ...PVDetails, pvConfig: dataConfig };
};

const pvHistoryGroupPowerUpdate = (value, selectedPv) => {
  let pvGroupPower = JSON.parse(localStorage.getItem("pvGroupPower"));
  if (selectedPv === 3) {
    pvGroupPower.pv1 = value;
  } else if (selectedPv === 4) {
    pvGroupPower.pv2 = value;
  } else if (selectedPv === 5) {
    pvGroupPower.pv3 = value;
  }
  localStorage.setItem("pvGroupPower", JSON.stringify(pvGroupPower));
};

const updatePvcOnfigSave = (PVDetails,isClose=false) => {
  const index = PVDetails.selectSubPv - 1;
  const selectedPv = PVDetails.selectedPv;
  let newPvData = [];

  switch (selectedPv) {
    case 3:
      newPvData = [...PVDetails.pvset1Data];
      break;
    case 4:
      newPvData = [...PVDetails.pvset2Data];
      break;
    case 5:
      newPvData = [...PVDetails.pvset3Data];
      break;
    default:
      break;
  }

  if (newPvData[index] === undefined) {
    newPvData[index] = {
      name: "",
      groupName: "",
      group_max_power: 0,
      description: "",
      max_power: "",
      ipaddress: "",
      subnetmask: "",
      modbusSlaveId: "",
      defaultGateway: "",
      status: false,
      assetDetails: [],
    };
  }

  newPvData[index].name = PVDetails.pvConfig.name;
  newPvData[index].description = PVDetails.pvConfig.description;
  newPvData[index].max_power = PVDetails.pvConfig.max_power;
  newPvData[index].ipaddress = PVDetails.pvConfig.ipaddress;
  newPvData[index].subnetmask = PVDetails.pvConfig.subnetmask;
  newPvData[index].modbusSlaveId = PVDetails.pvConfig.modbusSlaveId;
  newPvData[index].defaultGateway = PVDetails.pvConfig.defaultGateway;
  newPvData[index].pvGsxExternalId = PVDetails.pvConfig.pvGsxExternalId;
  newPvData[index].status = (!isClose||PVDetails.pvConfig.status) ? true : false;
  newPvData.forEach(
    pvData => (pvData.group_max_power = updateArrayMaxPower(newPvData))
  );
  // newPvData[index].assetDetails.push(this.state.assetDetailsDisplay);
  switch (selectedPv) {
    case 3:
      return {
        ...PVDetails,
        pvset1Data: newPvData,
        displayPvContent: false,
      };

    case 4:
      return {
        ...PVDetails,
        pvset2Data: newPvData,
        displayPvContent: false,
      };

    case 5:
      return {
        ...PVDetails,
        pvset3Data: newPvData,
        displayPvContent: false,
      };

    default:
      break;
  }
};

const updateArrayMaxPower = newPvData => {
  const groupMaxPower = newPvData.reduce(
    (prev, cur) => prev + +cur.max_power,
    0
  );
  return groupMaxPower;
};

const removePvFromSet = (PVDetails, selectedPv, subPv) => {
  if (selectedPv === 3) {
    let newPvDataSetRemoved = [...PVDetails.pvset1Data];
    // const groupMaxPower = newPvDataSetRemoved.find(pvData => pvData.status);
    const configuredPVs =  newPvDataSetRemoved.filter(pvData => pvData.status);
    const groupMaxPower = updateArrayMaxPower(configuredPVs);
    let newGroupMaxPower = 0;

    // if (groupMaxPower) {
    //   newGroupMaxPower =
    //     groupMaxPower.group_max_power -
    //     newPvDataSetRemoved[subPv - 1].max_power;
    // }
    if (groupMaxPower && newPvDataSetRemoved[subPv - 1].status) {
      newGroupMaxPower = PVDetails?.pvConfig?.group_max_power - newPvDataSetRemoved[subPv - 1].max_power;
        // groupMaxPower.group_max_power -
        // newPvDataSetRemoved[subPv - 1].max_power;
    }else{
      newGroupMaxPower = groupMaxPower;
    }
    delete newPvDataSetRemoved[subPv - 1];
    newPvDataSetRemoved = newPvDataSetRemoved.filter(pv1 => pv1 !== undefined);

    let newPvData1Set = Object.assign([], newPvDataSetRemoved);

    newPvData1Set.forEach(
      pvData => (pvData.group_max_power = newGroupMaxPower)
    );

    if (newPvData1Set[0] === undefined) {
      newPvData1Set[0].name = "";
      newPvData1Set[0].groupName = "";
      newPvData1Set[0].group_max_power = 0;
      newPvData1Set[0].description = "";
      newPvData1Set[0].max_power = "";
      newPvData1Set[0].ipaddress = "";
      newPvData1Set[0].subnetmask = "";
      newPvData1Set[0].modbusSlaveId = "";
      newPvData1Set[0].defaultGateway = "";
      newPvData1Set[0].status = false;
      newPvData1Set[0].assetDetails = [];
    }

    let pvNewData = { ...PVDetails.pvConfig };

    pvNewData.name = newPvData1Set[0].name;
    pvNewData.groupName = newPvData1Set[0].groupName;
    pvNewData.group_max_power = newPvData1Set[0].group_max_power;
    pvNewData.description = newPvData1Set[0].description;
    pvNewData.max_power = newPvData1Set[0].max_power;
    pvNewData.ipaddress = newPvData1Set[0].ipaddress;
    pvNewData.subnetmask = newPvData1Set[0].subnetmask;
    pvNewData.modbusSlaveId = newPvData1Set[0].modbusSlaveId;
    pvNewData.defaultGateway = newPvData1Set[0].defaultGateway;
    pvNewData.status = newPvData1Set[0].status;
    pvNewData.assetDetails = newPvData1Set[0].assetDetails;

    return {
      ...PVDetails,
      selectSubPv: 1,
      pvConfig: pvNewData,
      pvset1Data: newPvData1Set,
    };
  }
  if (selectedPv === 4) {
    let newPvDataSetRemoved = [...PVDetails.pvset2Data];
    // const groupMaxPower = newPvDataSetRemoved.find(pvData => pvData.status);
    const configuredPVs =  newPvDataSetRemoved.filter(pvData => pvData.status);
    const groupMaxPower = updateArrayMaxPower(configuredPVs);
    let newGroupMaxPower = 0;

    if (groupMaxPower && newPvDataSetRemoved[subPv - 1].status) {
      newGroupMaxPower = PVDetails?.pvConfig?.group_max_power - newPvDataSetRemoved[subPv - 1].max_power;
        // groupMaxPower.group_max_power -
        // newPvDataSetRemoved[subPv - 1].max_power;
    }else{
      newGroupMaxPower = groupMaxPower;
    }

    delete newPvDataSetRemoved[subPv - 1];
    newPvDataSetRemoved = newPvDataSetRemoved.filter(pv2 => pv2 !== undefined);

    let newPvData2Set = Object.assign([], newPvDataSetRemoved);

    newPvData2Set.forEach(
      pvData => (pvData.group_max_power = newGroupMaxPower)
    );

    if (newPvData2Set[0] === undefined) {
      newPvData2Set[0].name = "";
      newPvData2Set[0].groupName = "";
      newPvData2Set[0].group_max_power = 0;
      newPvData2Set[0].description = "";
      newPvData2Set[0].max_power = "";
      newPvData2Set[0].ipaddress = "";
      newPvData2Set[0].subnetmask = "";
      newPvData2Set[0].modbusSlaveId = "";
      newPvData2Set[0].defaultGateway = "";
      newPvData2Set[0].status = false;
      newPvData2Set[0].assetDetails = [];
    }

    let pvNewData = { ...PVDetails.pvConfig };

    pvNewData.name = newPvData2Set[0].name;
    pvNewData.groupName = newPvData2Set[0].groupName;
    pvNewData.group_max_power = newPvData2Set[0].group_max_power;
    pvNewData.description = newPvData2Set[0].description;
    pvNewData.max_power = newPvData2Set[0].max_power;
    pvNewData.ipaddress = newPvData2Set[0].ipaddress;
    pvNewData.subnetmask = newPvData2Set[0].subnetmask;
    pvNewData.modbusSlaveId = newPvData2Set[0].modbusSlaveId;
    pvNewData.defaultGateway = newPvData2Set[0].defaultGateway;
    pvNewData.status = newPvData2Set[0].status;
    pvNewData.assetDetails = newPvData2Set[0].assetDetails;

    return {
      ...PVDetails,
      selectSubPv: 1,
      pvConfig: pvNewData,
      pvset2Data: newPvData2Set,
    };
  }

  if (selectedPv === 5) {
    let newPvDataSetRemoved = [...PVDetails.pvset3Data];
    // const groupMaxPower = newPvDataSetRemoved.find(pvData => pvData.status);
    const configuredPVs =  newPvDataSetRemoved.filter(pvData => pvData.status);
    const groupMaxPower = updateArrayMaxPower(configuredPVs);
    let newGroupMaxPower = 0;

    // if (groupMaxPower) {
    //   newGroupMaxPower =
    //     groupMaxPower.group_max_power -
    //     newPvDataSetRemoved[subPv - 1].max_power;
    // }
    if (groupMaxPower && newPvDataSetRemoved[subPv - 1].status) {
      newGroupMaxPower = PVDetails?.pvConfig?.group_max_power - newPvDataSetRemoved[subPv - 1].max_power;
        // groupMaxPower.group_max_power -
        // newPvDataSetRemoved[subPv - 1].max_power;
    }else{
      newGroupMaxPower = groupMaxPower;
    }
    delete newPvDataSetRemoved[subPv - 1];
    newPvDataSetRemoved = newPvDataSetRemoved.filter(pv3 => pv3 !== undefined);

    let newPvData3Set = Object.assign([], newPvDataSetRemoved);

    newPvData3Set.forEach(
      pvData => (pvData.group_max_power = newGroupMaxPower)
    );

    if (newPvData3Set[0] === undefined) {
      newPvData3Set[0].name = "";
      newPvData3Set[0].groupName = "";
      newPvData3Set[0].group_max_power = 0;
      newPvData3Set[0].description = "";
      newPvData3Set[0].max_power = "";
      newPvData3Set[0].ipaddress = "";
      newPvData3Set[0].subnetmask = "";
      newPvData3Set[0].modbusSlaveId = "";
      newPvData3Set[0].defaultGateway = "";
      newPvData3Set[0].status = false;
      newPvData3Set[0].assetDetails = [];
    }

    let pvNewData = { ...PVDetails.pvConfig };

    pvNewData.name = newPvData3Set[0].name;
    pvNewData.groupName = newPvData3Set[0].groupName;
    pvNewData.group_max_power = newPvData3Set[0].group_max_power;
    pvNewData.description = newPvData3Set[0].description;
    pvNewData.max_power = newPvData3Set[0].max_power;
    pvNewData.ipaddress = newPvData3Set[0].ipaddress;
    pvNewData.subnetmask = newPvData3Set[0].subnetmask;
    pvNewData.modbusSlaveId = newPvData3Set[0].modbusSlaveId;
    pvNewData.defaultGateway = newPvData3Set[0].defaultGateway;
    pvNewData.status = newPvData3Set[0].status;
    pvNewData.assetDetails = newPvData3Set[0].assetDetails;

    return {
      ...PVDetails,
      selectSubPv: 1,
      pvConfig: pvNewData,
      pvset3Data: newPvData3Set,
    };
  }
};

const removeAllPvFromSet = (PVDetails, removedPvSet) => {
  return {
    ...PVDetails,
    [removedPvSet]: [],
  };
};

const addPvEquipment = (PVDetails, CBAssetList) => {
  const addPvDetails = _.cloneDeep(PVDetails);
  addPvDetails.showHideAddPvToBranch = false;
  addPvDetails.addPvShowDialog = true;
  addPvDetails.pvCount = 0;

  if (
    CBAssetList[3].uiData.status ||
    CBAssetList[4].uiData.status ||
    CBAssetList[5].uiData.status
  ) {
    addPvDetails.existOrNew = "exist";
  } else {
    addPvDetails.existOrNew = "new";
  }

  if (CBAssetList[5].uiData.status) {
    addPvDetails.branchName = 5;
  }
  if (CBAssetList[4].uiData.status) {
    addPvDetails.branchName = 4;
  }
  if (CBAssetList[3].uiData.status) {
    addPvDetails.branchName = 3;
  }
  return addPvDetails;
};
const closePvModal = PVDetails => ({
  ...PVDetails,
  pvCount: 0,
  addPvShowDialog: false,
});

const existOrNewBranchSelect = (PVDetails, data) => {
  // if (
  //   this.state.siteType === "GRID_CONNECTED" ||
  //   (this.state.siteType === "PV_HYBRIDIZATION" &&
  //     this.state.CBAssetList[3].uiData.status === false)
  // ) {
  //   this.setState({ existOrNew: "new" });
  // } else if (
  //   this.state.siteType === "ISLANDABLE" &&
  //   this.state.CBAssetList[3].uiData.status === false &&
  //   this.state.CBAssetList[4].uiData.status === false &&
  //   this.state.CBAssetList[5].uiData.status === false
  // ) {
  //   this.setState({ existOrNew: "new" });
  // } else {
  //   this.setState({ existOrNew: data.target.value });
  // }
  return {
    ...PVDetails,
    existOrNew: data.value,
  };
};

const existingBranchChange = (PVDetails, data) => {
  return {
    ...PVDetails,
    branchName: data.value,
  };
};

const addPvByOne = (PVDetails, CBAssetList, siteType, architectureType,projectType) => {
  const addPvDetails = _.cloneDeep(PVDetails);

  addPvDetails.showHideAddPvToBranch = false;

  let pvCount = PVDetails.pvCount;

  if (CBAssetList[3].uiData.status) {
    pvCount = pvCount + CBAssetList[3].uiData.count;
  }
  if (CBAssetList[4].uiData.status) {
    pvCount = pvCount + CBAssetList[4].uiData.count;
  }
  if (CBAssetList[5].uiData.status) {
    pvCount = pvCount + CBAssetList[5].uiData.count;
  }
  if (
    siteType === embLabels.GRID_CONNECTED ||
    (siteType === embLabels.ISLANDABLE_GSX &&
      architectureType === embLabels.PV_GENSET_ATS) || (siteType === embLabels.GRID_CONNECTED_GSX &&
        architectureType === embLabels.PV_BESS_NR)
      || (siteType === embLabels.ISLANDABLE&&projectType==="ANSI | Buildings | Microgrid Advisor")
      || (siteType === embLabels.ISLANDABLE&&projectType==="IEC | Buildings | Microgrid Advisor")
  ) {
    if (pvCount < 15) {
      addPvDetails.pvCount = addPvDetails.pvCount + 1;
    } else {
      addPvDetails.showHideAddPvToBranch = true;
    }
  } else {
    if (pvCount < 25) {
      addPvDetails.pvCount = addPvDetails.pvCount + 1;
    } else {
      addPvDetails.showHideAddPvToBranch = true;
    }
  }

  return addPvDetails;
};

const reducePvByOne = PVDetails => {
  const reducePvDetails = _.cloneDeep(PVDetails);
  reducePvDetails.showHideAddPvToBranch = false;
  if (reducePvDetails.pvCount) {
    reducePvDetails.pvCount = reducePvDetails.pvCount - 1;
  }
  return reducePvDetails;
};

const addPVSetWithCB = (PVDetails, selectedPv, externalId) => {

  if (selectedPv === 3) {
    let newPvSetData = [...PVDetails.pvset1Data];

    const groupMaxPower = newPvSetData.find(pvData => pvData.status);


    Array(PVDetails.pvCount)
      .fill(null)
      .forEach((value, index) => {
        let indexPVIn1 = PVDetails.availablePvIp[index];

        newPvSetData.push({
          name: PVDetails.availablePvName.shift(),
          groupName: PVDetails.defaultPvPreFillData.groupName + " " + 1,
          group_max_power: groupMaxPower ? groupMaxPower.group_max_power : 0,
          description: "",
          max_power: "",
          ipaddress: indexPVIn1,
          subnetmask: PVDetails.defaultPvPreFillData.subnetMask,
          modbusSlaveId: PVDetails.defaultPvPreFillData.modbusId,
          defaultGateway: PVDetails.defaultPvPreFillData.defaultGateway,
          status: false,
          assetDetails: [],
          pvGsxExternalId: externalId

        });
      });

    return {
      ...PVDetails,
      addPvShowDialog: false,
      selectedPv: selectedPv,
      existOrNew: "exist",
      pvCount: 0,
      pvset1Data: newPvSetData,
    };
  }
  if (selectedPv === 4) {
    let newPvSetData = [...PVDetails.pvset2Data];

    const groupMaxPower = newPvSetData.find(pvData => pvData.status);

    Array(PVDetails.pvCount)
      .fill(null)
      .forEach((value, index) => {
        let indexPVIn1 = PVDetails.availablePvIp[index];
        newPvSetData.push({
          name: PVDetails.availablePvName.shift(),
          groupName: PVDetails.defaultPvPreFillData.groupName + " " + 2,
          group_max_power: groupMaxPower ? groupMaxPower.group_max_power : 0,
          description: "",
          max_power: "",
          ipaddress: indexPVIn1,
          subnetmask: PVDetails.defaultPvPreFillData.subnetMask,
          modbusSlaveId: PVDetails.defaultPvPreFillData.modbusId,
          defaultGateway: PVDetails.defaultPvPreFillData.defaultGateway,
          status: false,
          assetDetails: [],
          pvGsxExternalId: externalId
        });
      });

    return {
      ...PVDetails,
      addPvShowDialog: false,
      selectedPv: selectedPv,
      existOrNew: "exist",
      pvCount: 0,
      pvset2Data: newPvSetData,
    };
  }
  if (selectedPv === 5) {
    let newPvSetData = [...PVDetails.pvset3Data];

    const groupMaxPower = newPvSetData.find(pvData => pvData.status);

    Array(PVDetails.pvCount)
      .fill(null)
      .forEach((value, index) => {
        let indexPVIn1 = PVDetails.availablePvIp[index];
        newPvSetData.push({
          name: PVDetails.availablePvName.shift(),
          groupName: PVDetails.defaultPvPreFillData.groupName + " " + 3,
          group_max_power: groupMaxPower ? groupMaxPower.group_max_power : 0,
          description: "",
          max_power: "",
          ipaddress: indexPVIn1,
          subnetmask: PVDetails.defaultPvPreFillData.subnetMask,
          modbusSlaveId: PVDetails.defaultPvPreFillData.modbusId,
          defaultGateway: PVDetails.defaultPvPreFillData.defaultGateway,
          status: false,
          assetDetails: [],
          pvGsxExternalId: externalId
        });
      });

    return {
      ...PVDetails,
      addPvShowDialog: false,
      selectedPv: selectedPv,
      existOrNew: "exist",
      pvCount: 0,
      pvset3Data: newPvSetData,
    };
  }
};

const resetPvDetails = PVDetails => {
  return {
    ...PVDetails,
    pvCount: 0,
    selectedPv: 3,
    existOrNew: "exist",
    addPvShowDialog: false,
  };
};

const copyAllPv = PVDetails => {
  let communications = {};
  let configuredAsset = [];
  let assetType = "";
  let selectedAsset = [];
  let index;
  let groupMaxPower = "";
  let pvNewData = "";

  switch (PVDetails.selectedPv) {
    case 3:
      configuredAsset = PVDetails.pvset1Data.filter(pv1 => pv1.status);
      assetType = configuredAsset[0].assetDetails[0].assetInfo;
      selectedAsset = configuredAsset[0].assetDetails;

      const oldPv1Data = _.cloneDeep(PVDetails.pvset1Data);
      index = 0;

      oldPv1Data.forEach(pv1 => {
        if (!pv1.status) {
          if (assetType === embLabels.SERIAL) {
            communications = {
              ipaddress: "",
              subnetmask: "",
              defaultGateway: "",
              modbusSlaveId: PVDetails.availablePvSlaveId[index],
            };
            index++;
          } else {
            communications = {
              ipaddress: pv1.ipaddress,
              subnetmask: pv1.subnetmask,
              defaultGateway: pv1.defaultGateway,
              modbusSlaveId: 255,
            };
          }


          pv1.assetDetails = selectedAsset;
          pv1.status = true;
          pv1.max_power = configuredAsset[0].max_power + "";
          pv1.description = configuredAsset[0].description;
          pv1.ipaddress = communications.ipaddress;

          pv1.subnetmask = communications.subnetmask;
          pv1.defaultGateway = communications.defaultGateway;
          pv1.modbusSlaveId = communications.modbusSlaveId;
          // pv1.pvGsxExternalId = "";
          pv1.pvGsxExternalId = configuredAsset[0].pvGsxExternalId;

        }
      });

      groupMaxPower = updateArrayMaxPower(oldPv1Data);

      oldPv1Data.forEach(pv1 => (pv1.group_max_power = groupMaxPower));

      pvNewData = { ...PVDetails.pvConfig };
      pvNewData.group_max_power = groupMaxPower;

      return {
        ...PVDetails,
        pvset1Data: oldPv1Data,
        pvConfig: pvNewData,
      };

    case 4:
      configuredAsset = PVDetails.pvset2Data.filter(pv2 => pv2.status);
      assetType = configuredAsset[0].assetDetails[0].assetInfo;
      selectedAsset = configuredAsset[0].assetDetails;
      index = 0;

      const oldPv2Data = _.cloneDeep(PVDetails.pvset2Data);

      oldPv2Data.forEach(pv2 => {
        if (!pv2.status) {
          if (assetType === embLabels.SERIAL) {
            communications = {
              ipaddress: "",
              subnetmask: "",
              defaultGateway: "",
              modbusSlaveId: PVDetails.availablePvSlaveId[index],
            };
            index++;
          } else {
            communications = {
              ipaddress: pv2.ipaddress,
              subnetmask: pv2.subnetmask,
              defaultGateway: pv2.defaultGateway,
              modbusSlaveId: 255,
            };
          }
          pv2.assetDetails = selectedAsset;
          pv2.status = true;
          pv2.max_power = configuredAsset[0].max_power + "";
          pv2.description = configuredAsset[0].description;
          pv2.ipaddress = communications.ipaddress;
          pv2.subnetmask = communications.subnetmask;
          pv2.defaultGateway = communications.defaultGateway;
          pv2.modbusSlaveId = communications.modbusSlaveId;
        }
      });

      groupMaxPower = updateArrayMaxPower(oldPv2Data);

      oldPv2Data.forEach(pv2 => (pv2.group_max_power = groupMaxPower));

      pvNewData = { ...PVDetails.pvConfig };
      pvNewData.group_max_power = groupMaxPower;

      return {
        ...PVDetails,
        pvset2Data: oldPv2Data,
        pvConfig: pvNewData,
      };

    case 5:
      configuredAsset = PVDetails.pvset3Data.filter(pv3 => pv3.status);
      assetType = configuredAsset[0].assetDetails[0].assetInfo;
      selectedAsset = configuredAsset[0].assetDetails;
      index = 0;

      const oldPv3Data = _.cloneDeep(PVDetails.pvset3Data);

      oldPv3Data.forEach(pv3 => {
        if (!pv3.status) {
          if (assetType === embLabels.SERIAL) {
            communications = {
              ipaddress: "",
              subnetmask: "",
              defaultGateway: "",
              modbusSlaveId: PVDetails.availablePvSlaveId[index],
            };
            index++;
          } else {
            communications = {
              ipaddress: pv3.ipaddress,
              subnetmask: pv3.subnetmask,
              defaultGateway: pv3.defaultGateway,
              modbusSlaveId: 255,
            };
          }
          pv3.assetDetails = selectedAsset;
          pv3.status = true;
          pv3.max_power = configuredAsset[0].max_power + "";
          pv3.description = configuredAsset[0].description;
          pv3.ipaddress = communications.ipaddress;
          pv3.subnetmask = communications.subnetmask;
          pv3.defaultGateway = communications.defaultGateway;
          pv3.modbusSlaveId = communications.modbusSlaveId;
        }
      });

      groupMaxPower = updateArrayMaxPower(oldPv3Data);

      oldPv3Data.forEach(pv3 => (pv3.group_max_power = groupMaxPower));

      pvNewData = { ...PVDetails.pvConfig };
      pvNewData.group_max_power = groupMaxPower;

      return {
        ...PVDetails,
        pvset3Data: oldPv3Data,
        pvConfig: pvNewData,
      };

    default:
      break;
  }
};

export { initialPVDetails, PVDetailsReducer };
