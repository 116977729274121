import { PROJECT_ENUM } from "./application.constants";

const getProjectTypeValue = () => {
  try {
    if(sessionStorage.getItem("projectType") === 'null' || JSON.parse(sessionStorage.getItem("projectType"))?.projectTypeValue === undefined){
      return PROJECT_ENUM?.[sessionStorage.getItem("projectKindType")] 
    }
    return JSON.parse(sessionStorage.getItem("projectType"))?.projectTypeValue;
  } catch (error) {
    return "";
  }
};

const getProjectId = () => {
  try {
    return JSON.parse(sessionStorage.getItem("projectId"));
  } catch (error) {
    return "";
  }
};

const getProject = () => {
  try {
    return JSON.parse(sessionStorage.getItem("projectType"));
  } catch (error) {
    return {};
  }
};

export { getProjectTypeValue, getProject, getProjectId };
