export const MGAAS_ANSI = "MGaaS ANSI";
export const DAAS_ANSI = "DaaS ANSI";
export const DAAS_IEC = "DaaS IEC";
export const MGaaS_IEC = "MGaaS IEC";
export const MGaaS_A2E = "MGaaS AccessToEnergy"
export const GRID_CONNECTED = "GRID_CONNECTED";
export const GRID_CONNECTED_GSX = "GRID_CONNECTED_GSX";
export const ISLANDABLE = "ISLANDABLE";
export const ISLANDABLE_GSX = "ISLANDABLE_GSX";
export const LINE_SIDE = "LINE_SIDE";
export const LOAD_SIDE = "LOAD_SIDE";
export const NEW_SWICTHBOARD = "NEW_SWICTHBOARD";
export const EXISTING_SWICTHBOARD = "EXISTING_SWICTHBOARD";
export const NO_FUEL = "NO_FUEL";
export const PV_GENSET_ATS = "PV_GENSET_ATS";
export const PV_BESS_NR = "PV_BESS_NR";
export const GREENSTRUXURE = "GREENSTRUXURE";
export const A2E_PROJECT = "A2E_PROJECT";
export const PV_HYBRIDIZATION = "PV_HYBRIDIZATION";
export const GENERIC = "GENERIC"
export const GREENSTRUXURE_MICROGRID = "GREENSTRUXURE_MICROGRID";
export const FUEL_SAVER = "FUEL_SAVER";
export const GENSET_PVBESS_A2E = "GENSET_PVBESS_A2E";
export const GENSET_MAIN_NO_BESS = "GENSET_MAIN_NO_BESS";
export const IEC = "IEC";
export const ANSI  = "ANSI";
export const PROJECT_ENUM = {
    "ANSI | Buildings | Autogrid Flex": DAAS_ANSI,
    "ANSI | Buildings | Microgrid Advisor": MGAAS_ANSI,
    "IEC | AccessToEnergy | Microgrid Advisor": MGaaS_A2E,
    "IEC | Buildings | Microgrid Advisor": MGaaS_IEC,
    "IEC | Buildings | Autogrid Flex": DAAS_IEC,
}


