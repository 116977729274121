import React, { useCallback, useState } from "react";
import Snackbar from "../pages/SE-Component/Snackbar";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: "",
      snackBar: {
        type: "error",
        message: "",
      },
    };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      errorMessage: error.message,
      snackBar: {
        type: "error",
        message: error.message,
      },
    });
    document.addEventListener('DOMContentLoaded', (event) => {
      if(document.getElementById("snackbar-shell-1")){
      document.getElementById("snackbar-shell-1").open = true;
      document.getElementById("snackbar-shell-1").message =
      "Something Went Wrong.";}
    });
   
  }
  render() {
    const { type, message } = this.state.snackBar;
    if (this.state.hasError) {
      return <></>;
    }
    return [
      <se-snackbar
        id="snackbar-shell-1"
        type={type}
        icon="information_stroke"
        duration="3000"
      ></se-snackbar>,
      this.props.children,
    ];
  }
}

export default ErrorBoundary;
