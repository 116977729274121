import React, { useEffect, useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiSession from "../api/ApiSession";
import embLabels from "../config/emb-labels-en.json";
import SecureTableHeading from "./SecureArchitecture/SecureTableHeading";
import MoxaTableHeading from "./SecureArchitecture/MoxaTableHeading";
import SecureCommunicationTable from "./SecureArchitecture/SecureCommunicationTable";
import MoxaCommunicationTable from "./SecureArchitecture/MoxaCommunicationTable";
import { getProjectId } from "../utils/project";
const apiSession = new ApiSession();
// ACTIONS

export const ACTIONS = {
  FETCH_SUCCESS: "FETCH_SUCCESS",
  ENABLE_TEXTBOX: "ENABLE_TEXTBOX",
  ENABLE_TEXTBOX_SYSTEM: "ENABLE_TEXTBOX_SYSTEM",
  RESET_COMMUNICATION: "RESET_COMMUNICATION",
};

const SecureArchitectureHook = (props) => {
  let userSampleData = {
    name: "",
    role: "",
    access: "",
  };

  let userScope =
    JSON.parse(localStorage.getItem("scope")) !== null
      ? JSON.parse(localStorage.getItem("scope"))
      : userSampleData;
  const [UserScope] = useState(userScope);
  const [loading, setLoading] = useState(false);
  
  const [snackBar, setSnackBar] = useState({ type: "", message: "" });
  const dispatch = useDispatch();
  const projectStatusInfo = useSelector((state) => state?.projectInfo?.data);
  const secureArchitectureData = useSelector(
    (state) => state?.secureArchitectureData?.data
  );
  const [secureCommunicationData, setSecureCommunicationData] = useState();  
  useEffect(() => {
    setSecureCommunicationData(secureCommunicationData);
  }, [secureCommunicationData]);

  useEffect(() => {
    const projectId = getProjectId();
    if (!projectId) {
      let { history } = props;
      history?.push({
        pathname: "/information",
      });
    }
  }, []);

  const saveSecureCommunicationData = () => {
    apiSession
      .upDataSecureArchitectureData(secureCommunicationData)
      .then((response) => {
        callSnackbar(
          "success",
          "Secure communication settings updated successfully"
        );
      });
  };
  const handleSelectMemberType = (member, port, type) => {
    const updatedMoxaMemberTypeConfigurations = secureArchitectureData[
      type
    ]?.map((config) => {
      if (port === config.port) {
        // setSelectedTrunkMember(config);
        if (member === "HYBRID_TRUNK") {
          return {
            ...config,
            memberType: member,
          };
        } else {
          return {
            ...config,
            memberType: member,
            hybridMembers: [],
          };
        }
      }
      return config;
    });
    setSecureCommunicationData({
      ...secureArchitectureData,
      [type]: updatedMoxaMemberTypeConfigurations,
    });
  };
  /* istanbul ignore next */
  const handleAccessEnable = (e, port, type) => {
    const updatedMoxaEnableConfigurations = secureArchitectureData?.[type]?.map(
      (config) => {
        if (port === config.port) {
          return {
            ...config,
            enable: e,
          };
        }
        return config;
      }
    );

    setSecureCommunicationData({
      ...secureArchitectureData,
      [type]: updatedMoxaEnableConfigurations,
    });
  };
  /* istanbul ignore next */
  const handleSelectAccessMember = (event, port, type) => {
    const updatedAccessMemberConfigurations = secureArchitectureData[
      type
    ]?.map((config) => {
      let accessMember = { ...config.accessMembers };
      if (port === config.port) {
        let accessMem =
        secureArchitectureData?.securedCommunicationArchitecture;
        const filterMember = accessMem.filter(
          (data) => data.vlan === event.target.value
        );
        accessMember = filterMember[0];

        return {
          ...config,
          ["accessMembers"]: accessMember,
        };
      } else {
        return {
          ...config,
          ["accessMembers"]: accessMember,
        };
      }
    });
    setSecureCommunicationData({
      ...secureArchitectureData,
      [type]: updatedAccessMemberConfigurations,
    });
  };
  const getHybridMembers = (members) => {
    const updatedSecureArchitectureData = {
      ...secureArchitectureData,
      moxaVlanConfigurations: members?.moxaVlanConfigurations,
    };
    // setSecureArchitectureData(updatedSecureArchitectureData);
  };
  const dropdownValues = (selectedOption, portSelected, type) => {
    const updatedMoxaAccessMemberConfigurations = secureArchitectureData[
      type
    ]?.map((config) => {
      if (portSelected.port === config.port) {
        return {
          ...config,
          hybridMembers: selectedOption,
        };
      }
      return config;
    });
    setSecureCommunicationData({
      ...secureArchitectureData,
      [type]: updatedMoxaAccessMemberConfigurations,
    });
  };
  const callSnackbar = (type, message) => {
    setSnackBar({ type, message });
    document.getElementById("custom-securepage").open = true;
  };

  return (
    <>
     
      <div>
        {props.siteType ? (
          <div>
            <div
              className={
                props?.siteCreated ||
                props?.projectStatusData?.projectStatus === "COMPLETED"
                  ? "se-block-disabled row"
                  : !UserScope.access.saveSystemInfo
                  ? "se-block-disabled row"
                  : "row"
              }
            >
              <div
                className="col-md-12 mt-1"
                style={
                  props.siteType === embLabels.GENSET_PVBESS_A2E
                    ? { userSelect: "none", pointerEvents: "none" }
                    : {}
                }
              >
                {(props.siteType === embLabels.ISLANDABLE ||
                  props.siteType === embLabels.GRID_CONNECTED ||
                  (props.siteType === embLabels.ISLANDABLE_GSX &&
                    props.architectureType === embLabels.PV_GENSET_ATS ) ||
                  (props.siteType === embLabels.GRID_CONNECTED_GSX &&
                    props.architectureType === embLabels.PV_BESS_NR)) && (
                  <>
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <span className="se-font-14px-bold se-life-green">
                          {" "}
                          VLAN Subnet Definitions.
                        </span>

                        <div className="table-responsive mt-2">
                          <SecureTableHeading
                            data={
                              secureArchitectureData?.securedCommunicationArchitecture
                            }
                          />
                          <table className="table table-bordered ">
                            <tbody>
                              {secureArchitectureData?.securedCommunicationArchitecture.map(
                                (data) => (
                                  <SecureCommunicationTable
                                    key={data.id}
                                    data={data}
                                  />
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <span className="se-font-14px-bold se-life-green">
                          {" "}
                          Moxa Port to VLAN Configuration
                        </span>

                        <div className="table-responsive mt-2">
                          <MoxaTableHeading />
                          <table className="table table-bordered">
                            <tbody>
                              {secureArchitectureData?.moxaVlanConfigurations.map(
                                (data) => (
                                  <MoxaCommunicationTable
                                    key={data.id}
                                    data={data}
                                    communicationType="moxaVlanConfigurations"
                                    handleSelectMemberType={
                                      handleSelectMemberType
                                    }
                                    handleAccessEnable={handleAccessEnable}
                                    handleSelectAccessMember={
                                      handleSelectAccessMember
                                    }
                                    getHybridMembers={getHybridMembers}
                                    secureData={secureArchitectureData}
                                    dropdownValues={dropdownValues}
                                  />
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 ">
                        <span className="se-font-14px-bold se-life-green">
                          {" "}
                          Connexium VLAN Configurations
                        </span>

                        <div className="table-responsive mt-2">
                          <MoxaTableHeading />
                          <table className="table table-bordered">
                            <tbody>
                              {secureArchitectureData?.connexiumVlanConfigurations.map(
                                (data) => (
                                  <MoxaCommunicationTable
                                    key={data.id}
                                    data={data}
                                    communicationType="connexiumVlanConfigurations"
                                    handleSelectMemberType={
                                      handleSelectMemberType
                                    }
                                    handleAccessEnable={handleAccessEnable}
                                    handleSelectAccessMember={
                                      handleSelectAccessMember
                                    }
                                    getHybridMembers={getHybridMembers}
                                    secureData={secureArchitectureData}
                                    dropdownValues={dropdownValues}
                                  />
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
                        {/* <se-button
                            option="flat"
                            color="primary"
                            
                          >
                            Save
                          </se-button> */}
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm se-btn-primary"
                          onClick={saveSecureCommunicationData}
                        >
                          <se-icon size="nano">action_save</se-icon>
                          <span>&nbsp;</span>Save
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
                        <se-snackBar
                          id="custom-securepage"
                          type={snackBar.type}
                          message={snackBar.message}
                          icon="information_stroke"
                          duration="3000"
                        ></se-snackBar>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <se-loading loading={loading}></se-loading>
        )}
      </div>
    </>
  );
};

export default SecureArchitectureHook;
