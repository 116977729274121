import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";
import embLabels from "../../config/emb-labels-en.json";

const Busbar = (secondBusbar) => {
  const {
    coordinates: {
      busbar: { x2 },
      busbarCordinates,
    },
    projectKind,
    switchboardType,
    loadSide,
  } = useContext(DesignAssetContext);
  return (
    <>
      {projectKind.isMgaasIecIsland ? (
        <>
          {switchboardType === embLabels.EXISTING_SWITCHBOARD ? (
            <svg>
              <g id="busbar" className="a2elll">
                <line
                  x1={busbarCordinates.x11}
                  y1={busbarCordinates.y11}
                  x2={busbarCordinates.x21}
                  y2={busbarCordinates.y21}
                  stroke="#333333"
                  strokeWidth="3"
                />
                {secondBusbar && (
                  <line
                    x1={busbarCordinates.x12}
                    y1={loadSide === "LOAD_SIDE" ? "470" : busbarCordinates.y12}
                    x2={(busbarCordinates.x22)}
                    y2={loadSide === "LOAD_SIDE" ? "470" : busbarCordinates.y22}
                    stroke="#333333"
                    strokeWidth="3"
                  />
                )}
              </g>
            </svg>
          ) : (
            <svg>
              <g id="busbar" className="A2E">
                <line
                  x1={busbarCordinates.x11}
                  y1={busbarCordinates.y11}
                  x2={busbarCordinates.x21}
                  y2={busbarCordinates.y21}
                  stroke="#333333"
                  strokeWidth="3"
                />
              </g>
            </svg>
          )}
        </>
      ) : (
        <svg>
          <g id="busbar">
            <line
              x1="60"
              y1="290"
              x2={x2}
              y2="290"
              stroke="#333"
              strokeWidth="3"
            />
          </g>
        </svg>
      )}
    </>
  );
};

export default Busbar;
