import { createSlice } from "@reduxjs/toolkit";
import { call, put, takeLatest } from 'redux-saga/effects'
import ApiSession from "../../api/ApiSession";
const apiSession = new ApiSession();
const initialState = {
   tooltipData:{}
}
export const getTooltipDataSlice = createSlice({
    name: 'tooltipData',
    initialState,
    reducers: {
       
        getTooltipData: (state, action) => {
            state.tooltipData = action.payload;
        },

    }
});
export const {
    getTooltipData,
   
} = getTooltipDataSlice.actions

export function* getTooltipDataSaga(action) {
    try {
       if(action.payload === null){
        const data = yield call(apiSession.getToolTip,"information","EN");
        yield put(getTooltipData(data))
       }
      } catch (error) {
        console.log(error);
      }
}
export function* watchGetToolTipData() {
    yield takeLatest(getTooltipData.type, getTooltipDataSaga);
}
export default getTooltipDataSlice; 
