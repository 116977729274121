import React, { useContext } from "react";

import isTouchDevice from "../SE-Component/Tooltip";
import embLabels from "../../config/emb-labels-en.json";
import { InformationPageContext } from "../CustomHooks/ContextApi";
import Tooltip from "../../utils/Tooltip";

const GSXSiteType = () => {
  const { informationToolTIP, siteType,switchboardType, handleSiteTypeChangeGSX,handleSwitchboardTypeChangeGSX } = useContext(
    InformationPageContext
  );
  return (
    <>
      <div className="row">
        <div className="col-md-12 mt-1">
          <span className="labelCircle mr-2 ">2</span>
          <span>Select your microgrid type</span>
          <Tooltip
            position="right"
            iconColor="secondary"
            iconSize="small"
            information={informationToolTIP.selectMicrogridTypeToolTipData}
          />
         
        </div>
      </div>

      <div className="row is-flex mt-2">
        <div className="col-md-4">
          <div
            className={`col-md-12 ${
              siteType === embLabels.GRID_CONNECTED_GSX
                ? "border-green-radius-5px"
                : "border-radius-5px"
            }`}
          >
            <div className="row align-items-center">
              <div className="col-md-2">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadio1"
                      name="siteType"
                      className="custom-control-input islandableRadio asCompatible"
                      value="GRID_CONNECTED_GSX"
                      checked={
                        siteType === embLabels.GRID_CONNECTED_GSX ? true : false
                      }
                      onChange={handleSiteTypeChangeGSX}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadio1"
                    >
                      &zwnj;
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-10 p-2">
                <div className="row">
                  <div className="col-md-12 mt-1">
                    <span className="bold">Always grid tied</span>
                  </div>
                  <div className="col-md-12 mt-1">
                    <span>"{informationToolTIP.gridConnectedTooltipData}"</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div
            className={`col-md-12 ${
              siteType === embLabels.ISLANDABLE_GSX
                ? "border-green-radius-5px"
                : "border-radius-5px"
            }`}
          >
            <div className="row align-items-center">
              <div className="col-md-2">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadio2"
                      name="siteType"
                      className="custom-control-input islandableRadio asCompatible"
                      value="ISLANDABLE_GSX"
                      checked={
                        siteType === embLabels.ISLANDABLE_GSX ? true : false
                      }
                      onChange={handleSiteTypeChangeGSX}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadio2"
                    >
                      &zwnj;
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-10 p-2">
                <div className="row">
                  <div className="col-md-12 mt-1">
                    <span className="bold">Islandable site</span>
                  </div>
                  <div className="col-md-12 mt-1">
                    <span>"{informationToolTIP.islandableTooltipData}"</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GSXSiteType;
