import React from "react";

import seConfigure from "../../images/se-configure.png";
import seDeploy from "../../images/se-iPC.jpg";
import sePLC from "../../images/se-plc.png";
import seVijeo from "../../images/se-vijeo.png";
import SEVIJEO from "../../images/se_iPC2.jpg"
import MOXA from "../../images/Moxa_IMG.png"
import connexium from "../../images/connexium.png";
import ONLOGIC from "../../images/OnLogic.png";
import embLabels from "../../config/emb-labels-en.json";
import isTouchDevice from "../SE-Component/Tooltip";
import Tooltip from "../../utils/Tooltip";

const Services = ({
  UserScope,
  gridSetting,
  unlockDeploy,
  siteType,
  clientType,
  architectureType,
  keyExpert,
  keyOperations,
  emaSiteStatus,
  versions,
  createSiteInEMA,
  deleteSiteInEMA,
  downloadIpcFile,
  downloadOnLogicFile,
  handleSerialNoPopUp,
  downloadHMI,
  downloadMOXA,
  downloadCONNEXIUM,
  projectProgressStatus,
  emaSiteId,
  deploymentStatus
}) => {
  const enableButton = () => {
    const { generatePLC, ipc, createEMASite, downloadHMI,downloadPLC,downloadMOXA=true,
      downloadCONNEXIUM =true, } = UserScope.access
      const {licenseMatched} = deploymentStatus
    if (clientType === "A2E_PROJECT" && projectProgressStatus?.projectStatus === "COMPLETED" && (licenseMatched === "MATCHED" || unlockDeploy)) {
      if (generatePLC && ipc && createEMASite) {
        return false
      } else {
        return true
      }
    } else {
      if ( projectProgressStatus?.projectStatus === "COMPLETED" && generatePLC && ipc && createEMASite && downloadHMI && downloadPLC && (licenseMatched === "MATCHED" || unlockDeploy) &&
      downloadMOXA && downloadCONNEXIUM) {
        return false
      } else {
        return true
      }
    }
  }
  const {licenseMatched} = deploymentStatus;
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-sm-12 mb-2 se-life-green se-font-14px-bold mb-2">
          EcoStruxure Microgrid Configuration
        </div>

        <div className="col-sm-12">
          <div className="row justify-content-center">
            {gridSetting.remoteMonitoringControl && (
              <div className={`col-md-3`}>
                <div className="row deploy-border padding-1px se-white-background deploy-card-min-height ml-1 ">
                  <div className="col-md-12 labelCircle-contanier p-0">
                    <img
                      src={seConfigure}
                      className="img-fluid img-height-deploy"
                      alt="Configure EMA"
                    />
                    <span className="labelCircle-deploy">
                      {(licenseMatched === "MATCHED" || unlockDeploy) ||
                      ([
                        embLabels.ISLANDABLE,
                        embLabels.GRID_CONNECTED,
                        embLabels.GRID_CONNECTED_GSX,
                      ].includes(siteType)
                        ? licenseMatched === "MATCHED"
                        : [
                            embLabels.PV_HYBRIDIZATION,
                            embLabels.ISLANDABLE_GSX,
                            embLabels.GENSET_PVBESS_A2E,
                          ].includes(siteType)
                        ? licenseMatched === "MATCHED"
                        : false) ? (
                        <se-icon size="large">action_lock_off</se-icon>
                      ) : (
                        <se-icon size="large">action_lock_on</se-icon>
                      )}
                    </span>
                  </div>

                  <div
                    className={
                       "col-md-12 pt-1 min-height-100"
                    }
                  >
                    <span className="font-block-header">
                      {siteType === embLabels.PV_HYBRIDIZATION ||
                      siteType === embLabels.GENSET_PVBESS_A2E ||
                      (siteType === embLabels.ISLANDABLE_GSX &&
                        architectureType === embLabels.PV_GENSET_ATS)
                        ? "Remote monitoring  / cloud      services"
                        : "EcoStruxure Microgrid Advisor cloud services"}
                    </span>
                    <Tooltip 
                    position ="top"
                    iconOption = "raised"
                    iconColor ="secondary"
                    iconSlot = "tooltip"
                    information ="Click on configure cloud services"
                    />                   
                  </div>
                  <div className="col-md-12 deploy-version-min-height-36">
                  {emaSiteId && (
                    
                      <span className="row justify-content-start align-items-center">
                        <span className="col-md-3 pr-0">
                          <b>Site Id:</b>
                        </span>
                       <span className="col-md-9 p-0">
                       <input
                          style={{ fontSize: "9px" }}
                          type="text"
                          autoComplete="none"
                          className="form-control form-control-sm col-md-11 "
                          value={emaSiteId}
                          maxLength="10"
                          readOnly
                        />
                       </span>
                      </span>
                 
                  )}
                     </div>
                  
                  <div className="col-md-12 ">
                    <span
                      className={
                        [
                          embLabels.GRID_CONNECTED,
                          embLabels.ISLANDABLE,
                          embLabels.GRID_CONNECTED_GSX,
                        ].includes(siteType)
                          ? // ? licenseMatched === "MATCHED" ? "dot-green": (keyExpert.expertExpired ? "dot-red": "dot-red")
                            licenseMatched === "MATCHED"
                            ? "dot-green"
                            : "dot-red"
                          : [
                              embLabels.PV_HYBRIDIZATION,
                              embLabels.ISLANDABLE_GSX,
                              embLabels.GENSET_PVBESS_A2E,
                            ].includes(siteType)
                          ? // ? (licenseMatched === "MATCHED"? "dot-green": keyOperations.operationExpired? "dot-red": "dot-red"): "dot-red"
                            licenseMatched === "MATCHED"
                            ? "dot-green"
                            : "dot-red"
                          : "dot-red"
                      }
                    ></span>

                    <span>
                      {[
                        embLabels.GRID_CONNECTED,
                        embLabels.ISLANDABLE,
                        embLabels.GRID_CONNECTED_GSX,
                      ].includes(siteType)
                        ? licenseMatched === "MATCHED"
                          ? " License key has been validated"
                          : licenseMatched === "NOT_MATCHED"
                          ? " License key is invalid"
                          : licenseMatched === "EXPIRED"
                          ? " License key has expired"
                          : " License key missing"
                        : [
                            embLabels.PV_HYBRIDIZATION,
                            embLabels.ISLANDABLE_GSX,
                            embLabels.GENSET_PVBESS_A2E,
                          ].includes(siteType)
                        ? licenseMatched === "MATCHED"
                          ? " License key has been validated"
                          : licenseMatched === "NOT_MATCHED"
                          ? " License key is invalid"
                          : licenseMatched === "EXPIRED"
                          ? " License key has expired"
                          : " License key missing"
                        : " License key missing"}
                    </span>
                  </div>

                  <div
                    className="col-md-12 d-flex justify-content-center"
                    
                  >
                    {emaSiteStatus === false ? (
                      <se-button
                        size="small"
                        disabled={enableButton()}
                        onClick={createSiteInEMA}
                      >
                        Configure Cloud Services
                      </se-button>
                    ) : (
                      <se-button
                        size="small"
                        disabled={
                          projectProgressStatus?.projectStatus === "IN_PROGRESS"
                            ? true
                            : (licenseMatched === "MATCHED" || unlockDeploy) ||
                              UserScope.access.unlockBypass === true ||
                              ([
                                embLabels.GRID_CONNECTED,
                                embLabels.ISLANDABLE,
                                embLabels.GRID_CONNECTED_GSX,
                              ].includes(siteType)
                                ? licenseMatched === "MATCHED"
                                : [
                                    embLabels.PV_HYBRIDIZATION,
                                    embLabels.ISLANDABLE_GSX,
                                    embLabels.GENSET_PVBESS_A2E,
                                  ].includes(siteType)
                                ? licenseMatched === "MATCHED"
                                : false)
                            ? false
                            : true
                        }
                        onClick={deleteSiteInEMA}
                      >
                        Delete Cloud Services
                      </se-button>
                    )}
                  </div>
                  
                </div>
              </div>
            )}

            {gridSetting.remoteMonitoringControl && (
              <div className={`col-md-3 `}>
                <div className=" row deploy-border padding-1px se-white-background deploy-card-min-height ml-1">
                  <div className="col-md 12 labelCircle-contanier">
                    <img
                      src={SEVIJEO}
                      className="img-fluid img-height-deploy"
                      alt="Deploy"
                    />
                    <span className="labelCircle-deploy">
                      {(licenseMatched === "MATCHED" || unlockDeploy) ||
                      ([
                        embLabels.ISLANDABLE,
                        embLabels.GRID_CONNECTED,
                        embLabels.GRID_CONNECTED_GSX,
                      ].includes(siteType)
                        ? licenseMatched === "MATCHED"
                        : [
                            embLabels.PV_HYBRIDIZATION,
                            embLabels.ISLANDABLE_GSX,
                            embLabels.GENSET_PVBESS_A2E,
                          ].includes(siteType)
                        ? licenseMatched === "MATCHED"
                        : false) ? (
                        <se-icon size="large">action_lock_off</se-icon>
                      ) : (
                        <se-icon size="large">action_lock_on</se-icon>
                      )}
                    </span>
                  </div>

                  <div className="col-md-12 min-height-100">
                    <span className="font-block-header">
                      {siteType === embLabels.PV_HYBRIDIZATION ||
                      siteType === embLabels.GENSET_PVBESS_A2E ||
                      (siteType === embLabels.ISLANDABLE_GSX &&
                        architectureType === embLabels.PV_GENSET_ATS)
                        ? "Remote monitoring communication gateway / EMA controller"
                        : "EcoStruxure Microgrid Advisor Controller (Harmony iPC)"}
                    </span>
                    <span className="se-block-enabled">
                     <se-tooltip
                      action={isTouchDevice() ? "click" : "hover"}
                      position="top"
                    >
                      <se-icon
                        option="raised"
                        color="secondary"
                        size="small"
                        slot="tooltip"
                      >
                        information_circle
                      </se-icon>
                      <se-tooltip-content>
                        Download « .zip » configuration file on your computer &
                        import it into EMA controller Via EMA controller
                        interface.
                        <br></br>
                        <br></br>
                        For more details please refer to System Installation
                        Guide.
                      </se-tooltip-content>
                    </se-tooltip>
                    </span>
                  </div>
                  <div className="col-md-12 deploy-version-min-height-36"></div>
                  <div className="col-md-12  ">
                    <span
                      className={
                        [
                          embLabels.GRID_CONNECTED,
                          embLabels.ISLANDABLE,
                          embLabels.GRID_CONNECTED_GSX,
                        ].includes(siteType)
                          ? licenseMatched === "MATCHED"
                            ? "dot-green"
                            : "dot-red"
                          : // : keyExpert.expertExpired
                          // ? "dot-red"
                          // : "dot-red"
                          [
                              embLabels.PV_HYBRIDIZATION,
                              embLabels.ISLANDABLE_GSX,
                              embLabels.GENSET_PVBESS_A2E,
                            ].includes(siteType)
                          ? licenseMatched === "MATCHED"
                            ? "dot-green"
                            : "dot-red"
                          : // : keyOperations.operationExpired
                            // ? "dot-red"
                            // : "dot-red"
                            "dot-red"
                      }
                    ></span>
                    <span>
                      {[
                        embLabels.GRID_CONNECTED,
                        embLabels.ISLANDABLE,
                        embLabels.GRID_CONNECTED_GSX,
                      ].includes(siteType)
                        ? licenseMatched === "MATCHED"  
                          ? " License key has been validated"// matched
                          :licenseMatched === "NOT_MATCHED"
                          ? " License key is invalid" //not matched 
                          : licenseMatched === "EXPIRED"
                          ? " License key has expired" // 
                          : " License key missing"
                        : [
                            embLabels.PV_HYBRIDIZATION,
                            embLabels.ISLANDABLE_GSX,
                            embLabels.GENSET_PVBESS_A2E,
                          ].includes(siteType)
                        ?licenseMatched === "MATCHED"  
                          ? " License key has been validated"
                          : licenseMatched === "NOT_MATCHED"
                          ? " License key is invalid"
                          :licenseMatched === "EXPIRED"
                          ? " License key has expired"
                          : " License key missing"
                        : " License key missing"}
                    </span>
                  </div>
                  <div className="col-md-12 d-flex justify-content-center">
                  <div > 
                    <div className=" ">
                    <se-button
                      disabled={enableButton()}
                      size="small"
                      onClick={downloadIpcFile}
                    >
                      Download Configuration{" "}
                      <se-icon size="dowloadIcon">action_download</se-icon>
                    </se-button>
                  </div>
                  </div>
                  </div>
                </div>
              </div>
            )}

        { false && (
              <div className={`col-md-3 `}>
                <div className="deploy-border padding-1px se-white-background deploy-card-min-height">
                  <div className="labelCircle-contanier">
                    <img
                      src={ONLOGIC}
                      className="img-fluid img-height-deploy"
                      alt="On-logic"
                    />
                    <span className="labelCircle-deploy">
                      {(licenseMatched === "MATCHED" || unlockDeploy) ||
                      ([
                        embLabels.ISLANDABLE,
                        embLabels.GRID_CONNECTED,
                        embLabels.GRID_CONNECTED_GSX,
                      ].includes(siteType)
                        ? licenseMatched === "MATCHED"
                        : [
                            embLabels.PV_HYBRIDIZATION,
                            embLabels.ISLANDABLE_GSX,
                            // embLabels.GENSET_PVBESS_A2E,
                          ].includes(siteType)
                        ? licenseMatched === "MATCHED"
                        : false) ? (
                        <se-icon size="large">action_lock_off</se-icon>
                      ) : (
                        <se-icon size="large">action_lock_on</se-icon>
                      )}
                    </span>
                  </div>

                  <div className="col-md-12  min-height-88">
                    <span className="font-block-header">
                     {"OnLogic Factor 201 Raspberry Pi Industrial IoT Gateway"}
                    </span>
                    <span className="se-block-enabled">
                     <se-tooltip
                      action={isTouchDevice() ? "click" : "hover"}
                      position="top"
                    >
                      <se-icon
                        option="raised"
                        color="secondary"
                        size="small"
                        slot="tooltip"
                      >
                        information_circle
                      </se-icon>
                      <se-tooltip-content>
                        
                      </se-tooltip-content>
                    </se-tooltip>
                    </span>
                  </div>

                  <div className="col-md-12 deploy-license-min-height">
                    <span
                      className={
                        [
                          embLabels.GRID_CONNECTED,
                          embLabels.ISLANDABLE,
                          embLabels.GRID_CONNECTED_GSX,
                        ].includes(siteType)
                          ? licenseMatched === "MATCHED"
                            ? "dot-green"
                            : "dot-red"
                          : // : keyExpert.expertExpired
                          // ? "dot-red"
                          // : "dot-red"
                          [
                              embLabels.PV_HYBRIDIZATION,
                              embLabels.ISLANDABLE_GSX,
                              // embLabels.GENSET_PVBESS_A2E,
                            ].includes(siteType)
                          ? licenseMatched === "MATCHED"
                            ? "dot-green"
                            : "dot-red"
                          : // : keyOperations.operationExpired
                            // ? "dot-red"
                            // : "dot-red"
                            "dot-red"
                      }
                    ></span>
                    <span>
                      {[
                        embLabels.GRID_CONNECTED,
                        embLabels.ISLANDABLE,
                        embLabels.GRID_CONNECTED_GSX,
                      ].includes(siteType)
                        ? licenseMatched === "MATCHED"  
                          ? " License key has been validated"// matched
                          :licenseMatched === "NOT_MATCHED"
                          ? " License key is invalid" //not matched 
                          : licenseMatched === "EXPIRED"
                          ? " License key has expired" // 
                          : " License key missing"
                        : [
                            embLabels.PV_HYBRIDIZATION,
                            embLabels.ISLANDABLE_GSX,
                            // embLabels.GENSET_PVBESS_A2E,
                          ].includes(siteType)
                        ?licenseMatched === "MATCHED"  
                          ? " License key has been validated"
                          : licenseMatched === "NOT_MATCHED"
                          ? " License key is invalid"
                          :licenseMatched === "EXPIRED"
                          ? " License key has expired"
                          : " License key missing"
                        : " License key missing"}
                    </span>
                  </div>
                  
                  <div className="col-md-12 d-flex justify-content-center mt-2">
                   <div>
                   <se-button
                      disabled={enableButton()}
                      size="small"
                      onClick={downloadOnLogicFile}
                    >
                      Download Configuration{" "}
                      <se-icon size="dowloadIcon">action_download</se-icon>
                    </se-button>
                   </div>
                  </div>
                </div>
              </div>
            )}
            <div className={`col-md-3 `}>
              <div className="row deploy-border padding-1px se-white-background deploy-card-min-height ml-1">
                <div className="col-md-12 labelCircle-contanier">
                  <img
                    src={sePLC}
                    alt="PLC Project for Machine Expert"
                    className="img-fluid img-height-deploy"
                  />
                  <span className="labelCircle-deploy">
                    {(licenseMatched === "MATCHED" || unlockDeploy) ||
                    ([
                      embLabels.ISLANDABLE,
                      embLabels.PV_HYBRIDIZATION,
                      embLabels.ISLANDABLE_GSX,
                      embLabels.GENSET_PVBESS_A2E,
                    ].includes(siteType)
                      ? licenseMatched === "MATCHED"
                      : [
                          embLabels.GRID_CONNECTED,
                          embLabels.GRID_CONNECTED_GSX,
                        ].includes(siteType)
                      ? licenseMatched === "MATCHED"
                      : false) ? (
                      <se-icon size="large">action_lock_off</se-icon>
                    ) : (
                      <se-icon size="large">action_lock_on</se-icon>
                    )}
                  </span>
                </div>

                <div className="col-md-12  min-height-100">
                  {siteType === embLabels.GRID_CONNECTED ? (
                    <span className="font-block-header">
                      M251 PLC Communication Gateway
                    </span>
                  ) : (
                    <span className="font-block-header">
                      <span className="font-block-header">
                        EcoStruxure Microgrid Operation Medium Controller
                        (M251 PLC)
                      </span>
                    </span>
                  )}
                  <span className="se-block-enabled">
                  <se-tooltip
                    action={isTouchDevice() ? "click" : "hover"}
                    position="right"
                  >
                    <se-icon
                      option="raised"
                      color="secondary"
                      size="small"
                      slot="tooltip"
                    >
                      information_circle
                    </se-icon>
                    <se-tooltip-content>
                      <p>
                        1. Download ".zip" file on your computer
                        <br></br>
                        <br></br>
                        2. Unzip in a selected folder
                        <br></br>
                        <br></br>
                        3. Open EcoStruxure Machine Expert Tool
                        <br></br>
                        <br></br>
                        4. Open .py file :<br></br>
                        In Ecostuxure machine tool click on
                        "tools/scripting/execute script file"
                        <br></br>
                        Select ".py" file in the unziped folder
                        <br></br>
                        Execute
                        <br></br>⇒ the application automatically built
                        <br></br>
                        <br></br>
                        5. Build the program in EME by clicking on "Build/build"
                        <br></br>
                        <br></br>
                        6. Transfer the program to the controller with USB
                        interface. (use BMXCAUSB018 cable)
                        <br></br>
                        Click on "online/login". Select the controller detected.
                        <br></br>
                        <br></br>
                        For more details please refer to System Installation
                        Guide.
                      </p>
                    </se-tooltip-content>
                  </se-tooltip>
                  </span>
                </div>

                <div className="col-md-12 deploy-version-min-height-36">
                  <span>
                    EcoStruxure Machine Expert version {versions.plcVersion}
                  </span>
                </div>
                <div className="col-md-12">
                  <span
                    className={
                      [
                        embLabels.GRID_CONNECTED,
                        embLabels.GRID_CONNECTED_GSX,
                      ].includes(siteType)
                        ? licenseMatched === "MATCHED"
                          ? "dot-green"
                          : "dot-red"
                        : // : keyExpert.expertExpired
                        // ? "dot-red"
                        // : "dot-red"
                        [
                            embLabels.ISLANDABLE,
                            embLabels.PV_HYBRIDIZATION,
                            embLabels.ISLANDABLE_GSX,
                            embLabels.GENSET_PVBESS_A2E,
                          ].includes(siteType)
                        ? licenseMatched === "MATCHED"
                          ? "dot-green"
                          : "dot-red"
                        : // : keyOperations.operationExpired
                          // ? "dot-red"
                          // : "dot-red"
                          "dot-red"
                    }
                  ></span>
                  <span>
                    {[
                      embLabels.GRID_CONNECTED,
                      embLabels.GRID_CONNECTED_GSX,
                    ].includes(siteType)
                      ? licenseMatched === "MATCHED"
                        ? " License key has been validated"
                        : licenseMatched === "NOT_MATCHED"
                        ? " License key is invalid"
                        : licenseMatched === "EXPIRED"
                        ? " License key has expired"
                        : " License key missing"
                      : [
                          embLabels.PV_HYBRIDIZATION,
                          embLabels.ISLANDABLE,
                          embLabels.ISLANDABLE_GSX,
                          embLabels.GENSET_PVBESS_A2E,
                        ].includes(siteType)
                      ? licenseMatched === "MATCHED"
                        ? " License key has been validated"
                        : licenseMatched === "NOT_MATCHED"
                        ? " License key is invalid"
                        : licenseMatched === "EXPIRED"
                        ? " License key has expired"
                        : " License key missing"
                      : " License key missing"}
                  </span>
                </div>
                  <div className="col-md-12">
                
                <div className="col-md-12 d-flex justify-content-center ">
                <div >
                  <se-button
                    size="small"
                    disabled={enableButton()}
                    onClick={handleSerialNoPopUp}
                  >
                    Download Program{" "}
                    <se-icon size="dowloadIcon">action_download</se-icon>
                  </se-button>
                </div>
                </div>
               
                </div>
              </div>
            </div>

            {gridSetting.userInterface === false ||
            (clientType === embLabels.GENERIC_PROJECT &&
              (siteType === embLabels.GRID_CONNECTED &&
                gridSetting.userInterface === false)) ||
            (siteType === embLabels.GRID_CONNECTED_GSX &&
              architectureType === embLabels.PV_BESS_NR &&
              gridSetting.userInterface === false) ||
              (siteType === embLabels.ISLANDABLE_GSX &&
                architectureType === embLabels.PV_GENSET_ATS &&
                gridSetting.userInterface === false) ||
            (clientType === embLabels.A2E_PROJECT &&
              siteType === embLabels.GENSET_PVBESS_A2E &&
              !gridSetting.userInterface) 
          
              ? (
              ""
            ) : (
              <div className={`col-md-3`}>
                <div className="row deploy-border padding-1px se-white-background deploy-card-min-height ml-1">
                  <div className="col-md-12 labelCircle-contanier">
                    <img
                      src={seVijeo}
                      alt="Download"
                      className="img-fluid img-height-deploy"
                    />
                    <span className="labelCircle-deploy">
                      {(unlockDeploy || licenseMatched === "MATCHED") ? (
                        <se-icon size="large">action_lock_off</se-icon>
                      ) : (
                        <se-icon size="large">action_lock_on</se-icon>
                      )}
                    </span>
                  </div>

                  <div className="col-md-12  min-height-100">
                    <span className="font-block-header">
                      EcoStruxure Microgrid Operation Medium HMI (Magelis HMI)
                    </span>
                    <span className="se-block-enabled">
                    <se-tooltip
                      action={isTouchDevice() ? "click" : "hover"}
                      position="left"
                    >
                      <se-icon
                        option="raised"
                        color="secondary"
                        size="small"
                        slot="tooltip"
                      >
                        information_circle
                      </se-icon>
                      <se-tooltip-content>
                        Download « .zip » configuration folder file on your computer & unzip
                        the folder. Open EcoStruxure Operator Terminal Expert & Open .vxdz file in
                        the tool and download into the HMI.
                        <br></br>
                        <br></br>
                        For more details please refer to System Installation
                        Guide.
                      </se-tooltip-content>
                    </se-tooltip>
                    </span>
                  </div>

                  <div className="col-md-12 deploy-version-min-height-36 ">
                    {/* <span>Vijeo designer version {versions.hmiVersion}</span> */}
                    <span>EcoStruxure Operator Terminal Expert SP {versions.hmiVersion}</span>
                  </div>
                  <div className="col-md-12">
                    <span
                      className={
                        licenseMatched === "MATCHED" ? "dot-green" : "dot-red"
                        // : keyOperations.operationExpired
                        // ? "dot-red"
                        // : "dot-red"
                      }
                    ></span>
                    <span>
                      {licenseMatched === "MATCHED"
                        ? " License key has been validated"
                        : licenseMatched === "NOT_MATCHED"
                        ? " License key is invalid"
                        : licenseMatched === "EXPIRED"
                        ? " License key has expired"
                        : " License key missing"}
                    </span>
                  </div>
                      <div className="col-md-12">
                  

                  <div className="col-md-12 d-flex justify-content-center">
                    <se-button
                      size="small"
                      disabled={enableButton()}
                      onClick={downloadHMI}
                    >
                      Download Program{" "}
                      <se-icon size="dowloadIcon">action_download</se-icon>
                    </se-button>
                  </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {
      // siteType === embLabels.GRID_CONNECTED_GSX ||
      // siteType === embLabels.ISLANDABLE_GSX ||(siteType === embLabels.GRID_CONNECTED && JSON.parse(sessionStorage.getItem("projectType"))?.projectTypeValue ===  "MGaaS IEC")
      siteType !== "GENSET_PVBESS_A2E"
      ? (
        <div className="row mt-2">
          <div className="col-sm-12 mb-2 se-life-green se-font-14px-bold mt-2 mb-2">
            Secure Communication Configuration
          </div>

          <div className="col-sm-12">
            <div className="row justify-content-center">
              <div className="col-md-3 padding-left remove-padding-right mt-2 mb-2">
                <div className="deploy-border padding-1px se-white-background deploy-card-min-height-415">
                  <div className="labelCircle-contanier">
                    <img
                     // src={moxa}
                     src={MOXA}
                      alt="Moxa PLC Project for Machine Expert"
                      className="img-fluid img-height-deploy"
                    />
                    <span className="labelCircle-deploy">
                      {(licenseMatched === "MATCHED" || unlockDeploy) ||
                      ([
                        embLabels.ISLANDABLE,
                        embLabels.PV_HYBRIDIZATION,
                        embLabels.ISLANDABLE_GSX,
                        embLabels.GENSET_PVBESS_A2E,
                      ].includes(siteType)
                        ? licenseMatched === "MATCHED"
                        : [
                            embLabels.GRID_CONNECTED,
                            embLabels.GRID_CONNECTED_GSX,
                          ].includes(siteType)
                        ? licenseMatched === "MATCHED"
                        : false) ? (
                        <se-icon size="large">action_lock_off</se-icon>
                      ) : (
                        <se-icon size="large">action_lock_on</se-icon>
                      )}
                    </span>
                  </div>

                  <div className="col-md-12 min-height-66">
                    {true ? (
                      <span className="font-block-header">
                        Moxa Communication Gateway
                      </span>
                    ) : (
                      <span className="font-block-header">
                        <span className="font-block-header">
                          Moxa Secure Communication - Architecture
                        </span>
                      </span>
                    )}
                    <span className="se-block-enabled">
                    <se-tooltip
                      action={isTouchDevice() ? "click" : "hover"}
                      position="right"
                    >
                      <se-icon
                        option="raised"
                        color="secondary"
                        size="small"
                        slot="tooltip"
                      >
                        information_circle
                      </se-icon>
                      <se-tooltip-content>
                        {/* <p>
                          1. Download ".zip" file on your computer
                          <br></br>
                          <br></br>
                          2. Unzip in a selected folder
                          <br></br>
                          <br></br>
                          3. Open EcoStruxure Machine Expert Tool
                          <br></br>
                          <br></br>
                          4. Open .py file :<br></br>
                          In Ecostuxure machine tool click on
                          "tools/scripting/execute script file"
                          <br></br>
                          Select ".py" file in the unziped folder
                          <br></br>
                          Execute
                          <br></br>⇒ the application automatically built
                          <br></br>
                          <br></br>
                          5. Build the program in EME by clicking on
                          "Build/build"
                          <br></br>
                          <br></br>
                          6. Transfer the program to the controller with USB
                          interface. (use BMXCAUSB018 cable)
                          <br></br>
                          Click on "online/login". Select the controller
                          detected.
                          <br></br>
                          <br></br>
                          For more details please refer to System Installation
                          Guide.
                        </p> */}
                          <p>
                          1. Download “ini file” on the computer. Connect to MOXA GUI using a browser.
                          <br></br>
                          <br></br>
                          2. Click on System <span>&#8594;</span> System File Update <span>&#8594;</span> Local Import/Export use “Import text-based configuration file” option
                          <br></br>
                          <br></br>
                          3. Click Choose File to select the configuration file from the computer and click the Apply button
                          <br></br>
                          <br></br>
                          
                          For more details please refer to System Installation
                          Guide.
                        </p>
                      </se-tooltip-content>
                    </se-tooltip>
                    </span>
                  </div>

                  <div className="col-md-12 deploy-version-min-height">
                    <span className="d-none">
                      EcoStruxure Machine Expert version {versions.plcVersion}
                    </span>
                  </div>

                  <div className="col-md-12 ">
                    <span
                      className={
                        [
                          embLabels.GRID_CONNECTED,
                          embLabels.GRID_CONNECTED_GSX,
                        ].includes(siteType)
                          ? licenseMatched === "MATCHED"
                            ? "dot-green"
                            : "dot-red"
                          : // : keyExpert.expertExpired
                          // ? "dot-red"
                          // : "dot-red"
                          [
                              embLabels.ISLANDABLE,
                              embLabels.PV_HYBRIDIZATION,
                              embLabels.ISLANDABLE_GSX,
                              embLabels.GENSET_PVBESS_A2E,
                            ].includes(siteType)
                          ?licenseMatched === "MATCHED"
                            ? "dot-green"
                            : "dot-red"
                          : // : keyOperations.operationExpired
                            // ? "dot-red"
                            // : "dot-red"
                            "dot-red"
                      }
                    ></span>
                    <span>
                      {[
                        embLabels.GRID_CONNECTED,
                        embLabels.GRID_CONNECTED_GSX,
                      ].includes(siteType)
                        ? licenseMatched === "MATCHED"
                          ? " License key has been validated"
                          : licenseMatched === "NOT_MATCHED"
                          ? " License key is invalid"
                          :licenseMatched === "EXPIRED"
                          ? " License key has expired"
                          : " License key missing"
                        : [
                            embLabels.PV_HYBRIDIZATION,
                            embLabels.ISLANDABLE,
                            embLabels.ISLANDABLE_GSX,
                            embLabels.GENSET_PVBESS_A2E,
                          ].includes(siteType)
                        ? licenseMatched === "MATCHED"
                          ? " License key has been validated"
                          : licenseMatched === "NOT_MATCHED"
                          ? " License key is invalid"
                          :licenseMatched === "EXPIRED"
                          ? " License key has expired"
                          : " License key missing"
                        : " License key missing"}
                    </span>
                  </div>

                  <div className="col-md-12 d-flex justify-content-center ">
                    <se-button
                      size="small"
                      disabled={enableButton()}
                      onClick={downloadMOXA}
                    >
                      Download Program{" "}
                      <se-icon size="dowloadIcon">action_download</se-icon>
                    </se-button>
                  </div>
                </div>
              </div>
              <div className="col-md-3 padding-left remove-padding-right mt-2 ml-2">
                <div className="deploy-border padding-1px se-white-background deploy-card-min-height-415">
                  <div className="labelCircle-contanier">
                    <img
                      src={connexium}
                      alt="Moxa PLC Project for Machine Expert"
                      className="img-fluid img-height-deploy"
                    />
                    <span className="labelCircle-deploy">
                      {(licenseMatched === "MATCHED" || unlockDeploy) ||
                      ([
                        embLabels.ISLANDABLE,
                        embLabels.PV_HYBRIDIZATION,
                        embLabels.ISLANDABLE_GSX,
                        embLabels.GENSET_PVBESS_A2E,
                      ].includes(siteType)
                        ? licenseMatched === "MATCHED"
                        : [
                            embLabels.GRID_CONNECTED,
                            embLabels.GRID_CONNECTED_GSX,
                          ].includes(siteType)
                        ? licenseMatched === "MATCHED"
                        : false) ? (
                        <se-icon size="large">action_lock_off</se-icon>
                      ) : (
                        <se-icon size="large">action_lock_on</se-icon>
                      )}
                    </span>
                  </div>

                  <div className="col-md-12  min-height-66">
                    {siteType === embLabels.GRID_CONNECTED ? (
                      <span className="font-block-header">
                        ConneXium PLC Communication Gateway
                      </span>
                    ) : (
                      <span className="font-block-header">
                           <span className="font-block-header">
                       ConneXium Secure Communication Architecture for Modicon Managed Switch
                      </span>
                      </span>
                     
                    )}
                    <span className="se-block-enabled">
                    <se-tooltip
                      action={isTouchDevice() ? "click" : "hover"}
                      position="right"
                    >
                      <se-icon
                        option="raised"
                        color="secondary"
                        size="small"
                        slot="tooltip"
                      >
                        information_circle
                      </se-icon>
                      <se-tooltip-content>
                        {/* <p>
                          1. Download ".zip" file on your computer
                          <br></br>
                          <br></br>
                          2. Unzip in a selected folder
                          <br></br>
                          <br></br>
                          3. Open EcoStruxure Machine Expert Tool
                          <br></br>
                          <br></br>
                          4. Open .py file :<br></br>
                          In EcoStruxure machine tool click on
                          "tools/scripting/execute script file"
                          <br></br>
                          Select ".py" file in the unziped folder
                          <br></br>
                          Execute
                          <br></br>⇒ the application automatically built
                          <br></br>
                          <br></br>
                          5. Build the program in EME by clicking on
                          "Build/build"
                          <br></br>
                          <br></br>
                          6. Transfer the program to the controller with USB
                          interface. (use BMXCAUSB018 cable)
                          <br></br>
                          Click on "online/login". Select the controller
                          detected.
                          <br></br>
                          <br></br>
                          For more details please refer to System Installation
                          Guide.
                        </p> */}
                          <p>
                          1. Download “cli file” on the computer
                          <br></br>
                          <br></br>
                          2. Connect to Modicon GUI using a browser.  
                          <br></br>
                          <br></br>   
                          3. Load the file. 
                          <br></br>
                          <br></br>                    
                          For more details please refer to System Installation
                          Guide.
                        </p>
                      </se-tooltip-content>
                    </se-tooltip>
                    </span>
                  </div>

                  <div className="col-md-12 deploy-version-min-height">
                    <span className="d-none">
                      EcoStruxure Machine Expert version {versions.plcVersion}
                    </span>
                  </div>

                  <div className="col-md-12 ">
                    <span
                      className={
                        [
                          embLabels.GRID_CONNECTED,
                          embLabels.GRID_CONNECTED_GSX,
                        ].includes(siteType)
                          ? licenseMatched === "MATCHED"
                            ? "dot-green"
                            : "dot-red"
                          : // : keyExpert.expertExpired
                          // ? "dot-red"
                          // : "dot-red"
                          [
                              embLabels.ISLANDABLE,
                              embLabels.PV_HYBRIDIZATION,
                              embLabels.ISLANDABLE_GSX,
                              embLabels.GENSET_PVBESS_A2E,
                            ].includes(siteType)
                          ? licenseMatched === "MATCHED"
                            ? "dot-green"
                            : "dot-red"
                          : // : keyOperations.operationExpired
                            // ? "dot-red"
                            // : "dot-red"
                            "dot-red"
                      }
                    ></span>
                    <span>
                      {[
                        embLabels.GRID_CONNECTED,
                        embLabels.GRID_CONNECTED_GSX,
                      ].includes(siteType)
                        ? licenseMatched === "MATCHED"
                          ? " License key has been validated"
                          : licenseMatched === "NOT_MATCHED"
                          ? " License key is invalid"
                          :licenseMatched === "EXPIRED"
                          ? " License key has expired"
                          : " License key missing"
                        : [
                            embLabels.PV_HYBRIDIZATION,
                            embLabels.ISLANDABLE,
                            embLabels.ISLANDABLE_GSX,
                            embLabels.GENSET_PVBESS_A2E,
                          ].includes(siteType)
                        ? licenseMatched === "MATCHED"
                          ? " License key has been validated"
                          : licenseMatched === "NOT_MATCHED"
                          ? " License key is invalid"
                          :licenseMatched === "EXPIRED"
                          ? " License key has expired"
                          : " License key missing"
                        : " License key missing"}
                    </span>
                  </div>

                  <div className="col-md-12 d-flex justify-content-center ">
                    <se-button
                      size="small"
                      disabled={enableButton()}
                      onClick={downloadCONNEXIUM}
                    >
                      Download Program{" "}
                      <se-icon size="dowloadIcon">action_download</se-icon>
                    </se-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Services;
