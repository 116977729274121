const userScope = () => {
  const userSampleData = {
    name: "",
    role: "",
    access: {},
  };
  const scopeItem = localStorage.getItem("scope");

  const userScope = scopeItem !== null && scopeItem !== undefined
      ? JSON.parse(scopeItem)
      : userSampleData;

  return userScope;
};

export default userScope;
