import React, { useContext } from "react";

import isTouchDevice from "../SE-Component/Tooltip";
import embLabels from "../../config/emb-labels-en.json";
import { InformationPageContext } from "../CustomHooks/ContextApi";
import Tooltip from "../../utils/Tooltip";
import { getProjectTypeValue } from "../../utils/project";

const MicrogridType = ({ isDisabled }) => {
  const { projectType, informationToolTIP, handleProjectTypeChange } =
    useContext(InformationPageContext);
    const projectTypeValue = getProjectTypeValue()
    
  return (
    <>
      <div className="row">
        <div className="col-md-12 mt-1">
          <span className="labelCircle mr-2 ">1</span>{" "}
          <span>Select your project type</span>
          <Tooltip
            position="right"
            iconColor="secondary"
            iconSize="small"
            information={informationToolTIP?.selectProjectTypeToolTipData}
          />        
        </div>
      </div>
      <div className="row is-flex mt-2">
        <div className="col-md-4" style={isDisabled ? { display: "none" } : {}}>
          <div
            className={`col-md-12 ${
              projectType === embLabels.STANDARD_MICROGRID 
                ? "border-green-radius-5px"
                : "border-radius-5px"
            }`}
          >
            <div className="row align-items-center">
              <div className="col-md-2">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadio1"
                      name="projectType"
                      className="custom-control-input islandableRadio asCompatible"
                      value={projectType === "GREENSTRUXURE_MICROGRID"?"GREENSTRUXURE_MICROGRID":"STANDARD_MICROGRID"}
                      checked={
                       
                        [embLabels.STANDARD_MICROGRID,"GREENSTRUXURE_MICROGRID","STANDARD_MICROGRID"].includes(projectType)
                          ? true
                          : false
                      }
                      onChange={handleProjectTypeChange}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadio1"
                    >
                      &zwnj;
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-10 p-2">
                <div className="row">
                  <div className="col-md-12 mt-1">
                    <span className="bold">Design your own Microgrid</span>
                  </div>
                  <div className="col-md-12 mt-1">
                    <span>"{informationToolTIP.standardToolTipData}"</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     { ((projectType !== "GREENSTRUXURE_MICROGRID"&&projectType !== "STANDARD_MICROGRID"&& projectTypeValue!=="MGaaS ANSI" &&  projectTypeValue!=="MGaaS IEC"))  && <div className="col-md-4" >
          <div
            className={`col-md-12 ${
              projectType === embLabels.PACKAGED_MICROGRID
                ? "border-green-radius-5px"
                : "border-radius-5px"
            }`}
          > 
            <div className="row align-items-center">
              <div className="col-md-2 mt-3">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadio2"
                      name="projectType"
                      className="custom-control-input islandableRadio asCompatible"
                      value="PACKAGED_MICROGRID"
                      checked={
                        projectType === embLabels.PACKAGED_MICROGRID
                          ? true
                          : false
                      }
                      onChange={handleProjectTypeChange}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadio2"
                    >
                      &zwnj;
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-10 p-2">
                <div className="row">
                  <div className="col-md-12 mt-1">
                    <span className="bold">Use pre-configured solutions</span>
                  </div>
                  <div className="col-md-12 mt-1">
                    <span>"{informationToolTIP.packagedToolTipData}"</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
    </>
  );
};

export default MicrogridType;
